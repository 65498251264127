import {User} from '../model/User';
import BehaviorSubjectService from './common/BehaviorSubjectService';
import {UserResponse, UserRoleEntity} from './model/UserResponse';

interface ChatTeamMember {
  user_id: string;
  username: string;
}
export interface ChatTeamResponse {
  results: Array<ChatTeamMember>;
}

export function toChatTeamMember(resp: ChatTeamResponse): any {
  return {
    user_id: resp?.results?.map((member: ChatTeamMember) => member.user_id),
  };
}
export default class UserService extends BehaviorSubjectService<User> {
  private static INSTANCE = new UserService();

  static getInstance(): UserService {
    return UserService.INSTANCE;
  }

  toUser(resp: UserResponse): User {
    const user_id = resp.user.user_id;
    const username = resp.user.username;
    const password = resp.user.password;
    const email = resp.user.email;
    const phone_number = resp.user.phone_number;
    const first_name = resp.user.first_name;
    const last_name = resp.user.last_name;
    const company = resp.user.company;
    const sms_did = resp.user.sms_did;
    const extension = resp.user.extension;
    const call_enabled = resp.user.call_enabled;
    const sms_enabled = resp.user.sms_enabled;
    const email_enabled = resp.user.email_enabled;
    const status = resp.user.status;
    const disabled = resp.user.disabled;
    const roles = resp.user.roles?.map((role: UserRoleEntity) => role.value) ?? [];
    const campaigns = resp.user.campaigns;
    const teams = resp.user.teams;
    const operation_id = resp.user.operation_id;
    const user_type = resp.user.user_type;
    return {
      user_id,
      username,
      password,
      email,
      phone_number,
      first_name,
      last_name,
      company,
      sms_did,
      extension,
      call_enabled,
      sms_enabled,
      email_enabled,
      status,
      disabled,
      roles,
      campaigns,
      teams,
      operation_id,
      user_type,
    };
  }
}
