import {Component, ErrorInfo, ReactNode} from 'react';
import {Box, Button} from '@mui/material';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Box display={'flex'} justifyContent={'center'} mt={5}>
            <h1>Something went wrong.</h1>
          </Box>
          <Box display={'flex'} justifyContent={'center'} mt={2}>
            <a href="/dashboard">
              <Button id="back-to-dashboard-btn">Back to dashboard</Button>
            </a>
          </Box>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
