import {useTranslation} from 'react-i18next';
import {FormControl, FormGroup, FormLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {getDynamicTranslationKey} from '../../../../i18n/Utils';
import {TestAttributes} from '../../../TestAttributes';
import {useUserSettingsContext} from '../../../context/UserSettingsContext';
import {PreferredLanguage} from '../../../model/UserSettings';
import {useConfig} from '../../../context/ConfigContext';
import {useMemo} from 'react';

function LanguageMode() {
  const {t, i18n} = useTranslation();
  const {COMMA_SEPARATED_LANGUAGES} = useConfig();
  const {setPartialUserSettings} = useUserSettingsContext();

  // in case COMMA_SEPARATED_LANGUAGES is not defined, fallback to `en`
  const languages = useMemo(() => COMMA_SEPARATED_LANGUAGES?.split(',') ?? ['en'], [COMMA_SEPARATED_LANGUAGES]);

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setPartialUserSettings({preferred_language: event.target.value as PreferredLanguage});
  };

  return (
    <FormControl>
      <FormLabel>{t('language.title')}</FormLabel>
      <FormGroup>
        <Select
          {...{[TestAttributes.FIELD_NAME]: 'selected-language'}}
          id="select-language"
          value={i18n.language}
          onChange={handleChangeLanguage}
        >
          {languages.map((language: string) => (
            <MenuItem
              key={'language-' + language}
              id={'menu-item-language-' + language}
              value={language}
              {...{[TestAttributes.FIELD_OPTION_NAME]: language}}
            >
              {t(getDynamicTranslationKey('language', language))}
            </MenuItem>
          ))}
        </Select>
      </FormGroup>
    </FormControl>
  );
}

export default LanguageMode;
