import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {SpartaLinkInfo} from '../model/SpartaLinkInfo';

interface SpartaLinkProps {
  link: SpartaLinkInfo;
}

export function SpartaLink({link}: SpartaLinkProps) {
  const {t} = useTranslation();
  return <Link to={link.path}>{t(link.translation)}</Link>;
}
