import React, {useEffect, useState} from 'react';
import {Alert, CircularProgress, Grid, TextField} from '@mui/material';
import {useLoading} from '../../../context/LoadingContext';
import {useAuth0} from '@auth0/auth0-react';
import {Auth0User, Auth0UserPermission, getUserPermissions, hasUserPermission} from '../../../model/Auth0User';
import Button from '@mui/material/Button';
import SpannerResultsComponent from './SpannerResultsComponent';
import {Mode, Modes, SpannerResult, Template, TEMPLATES} from './model';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import {useConfig} from '../../../context/ConfigContext';

function modeToColor(mode: Mode): any {
  return mode === 'sql' ? 'primary' : mode === 'dml' ? 'warning' : mode === 'ddl' ? 'error' : undefined;
}

function isDev(databaseId: string) {
  return databaseId.startsWith('development_') || databaseId.endsWith('_dev') || databaseId === 'demo';
}

function isTest(databaseId: string) {
  return databaseId.startsWith('testing_');
}

function isStage(databaseId: string) {
  return databaseId.startsWith('staging_') || databaseId.startsWith('stage_') || databaseId.endsWith('_prod');
}

function isProd(databaseId: string) {
  return databaseId.includes('production');
}

export default function SpannerPageComponent() {
  const [mode, setMode] = useState<Mode>('sql');
  const [content, setContent] = useState<string>('');
  const {SPANNER_API_PATH} = useConfig();
  const {setLoading} = useLoading();
  const {user, getAccessTokenSilently} = useAuth0<Auth0User>();
  const canImpersonateAny = hasUserPermission(user, Auth0UserPermission.IMPERSONATE_ANY);
  const permissions = getUserPermissions(user);
  const canSpannerAny = permissions.some((p) => p === 'spanner:any');

  const [message, setMessage] = useState<string>();
  const [results, setResults] = useState<SpannerResult[] | undefined>();
  const [dbs, setDbs] = useState<string[] | undefined>();
  const [selectedDbs, setSelectedDbs] = useState<string[]>([]);

  useEffect(() => {
    if (!canImpersonateAny || !canSpannerAny) return;
    fetchWithAuth(`${SPANNER_API_PATH}/dbs`, {method: 'GET'})
      .then((response) => response.json())
      .then((data) => setDbs(data))
      .catch((error) =>
        NotificationService.getInstance().sendNotification(
          `cannot load databases: ${error.message}`,
          NotificationType.ERROR
        )
      );
  }, [canImpersonateAny]);

  useEffect(() => {
    setMessage(undefined);
    setResults(undefined);
  }, [content]);

  if (!canSpannerAny) {
    return <Alert severity={'warning'}>You are not allowed</Alert>;
  }

  async function fetchWithAuth(url: URL | string, init?: RequestInit): Promise<Response> {
    const token = await getAccessTokenSilently();
    const headers = new Headers(init?.headers);
    headers.append('authorization', `bearer ${token}`);
    return await fetch(url, {
      ...init,
      headers: headers,
    });
  }

  async function handleRun() {
    setLoading(true, 'SpannerPage');
    try {
      setMessage(undefined);
      setResults(undefined);
      const headers = new Headers();
      selectedDbs.forEach((db) => headers.append('x-sparta-db', db));
      const response = await fetchWithAuth(`${SPANNER_API_PATH}/${mode}`, {
        method: 'POST',
        body: content,
        headers,
      });
      if (response.ok) {
        const data = await response.json();
        setResults(data);
      } else {
        const text = await response.text();
        setMessage(text);
      }
    } catch (e) {
      setMessage(`${e}`);
    } finally {
      setLoading(false, 'SpannerPage');
    }
  }

  function setTemplate(template: Template) {
    setMode(template.mode);
    setContent(template.content);
  }

  function changeMode(mode: Mode) {
    setMode(mode);
    setContent('');
  }

  function handleSelectDb(db: string) {
    setSelectedDbs((prev) => {
      if (prev.includes(db)) {
        return prev.filter((d) => d !== db);
      } else {
        return [...prev, db];
      }
    });
  }

  const enableRun = content.trim().length && (selectedDbs.length || !canImpersonateAny);

  return (
    <>
      <Grid container spacing={2}>
        {!canImpersonateAny ? null : !dbs ? (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button fullWidth onClick={() => setSelectedDbs(dbs.filter((d) => isDev(d)))}>
                    development
                  </Button>
                </Grid>
                <Grid item>
                  <Button fullWidth onClick={() => setSelectedDbs(dbs.filter((d) => isTest(d)))}>
                    testing
                  </Button>
                </Grid>
                <Grid item>
                  <Button fullWidth color={'warning'} onClick={() => setSelectedDbs(dbs.filter((d) => isStage(d)))}>
                    staging
                  </Button>
                </Grid>
                <Grid item>
                  <Button fullWidth color={'error'} onClick={() => setSelectedDbs(dbs.filter((d) => isProd(d)))}>
                    production
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2} justifyContent={'flex-end'}>
                <Grid item>
                  <Button fullWidth onClick={() => setSelectedDbs(dbs)}>
                    select all
                  </Button>
                </Grid>
                <Grid item>
                  <Button fullWidth color={'warning'} onClick={() => setSelectedDbs([])}>
                    clear all
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{height: 20}}></Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {dbs?.map((db) => (
                  <Grid item>
                    <Button
                      color={'info'}
                      variant={selectedDbs.includes(db) ? 'contained' : 'outlined'}
                      onClick={() => handleSelectDb(db)}
                      sx={{height: 24}}
                    >
                      {db}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        )}
        <Grid item xs={12} sx={{height: 40}}></Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            {Modes.map((m) => (
              <Grid item xs={12 / Modes.length}>
                <Button
                  color={modeToColor(m)}
                  variant={m === mode ? 'contained' : 'outlined'}
                  onClick={() => changeMode(m)}
                  fullWidth
                >
                  {m}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Button disabled={!enableRun} color={modeToColor(mode)} variant={'contained'} onClick={handleRun} fullWidth>
            execute {mode}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth={true}
            placeholder={mode}
            onChange={(e) => setContent(e.target.value)}
            id={'content'}
            name={'content'}
            value={content}
            inputProps={{style: {fontFamily: 'monospace', fontSize: 20, padding: 20}}}
            InputProps={{
              type: 'text',
              multiline: true,
              minRows: 10,
              maxRows: 10,
            }}
          />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {TEMPLATES.map((t, index) => (
              <Grid item>
                <Button variant={'outlined'} color={modeToColor(t.mode)} onClick={(e) => setTemplate(t)}>
                  {t.name ?? `template ${index}`}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {results ? (
          <Grid item xs={12}>
            <SpannerResultsComponent results={results} />
          </Grid>
        ) : message ? (
          <Grid item xs={12}>
            <pre>{message}</pre>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}
