import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Box, Button, Container, Grid, Typography} from '@mui/material';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import {Skeleton} from '@mui/lab';
import PageHeadline from '../../components/PageHeadline';
import {PageTopActions} from '../PageTopActions';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {AutoActionListItem} from '../../model/AutoAction';
import {FeatureName} from '../../../paths';
import {PageStickyHeader} from '../PageStickyHeader';
import {formatDateTime} from '../../utils/DateUtils';
import {useTableColumns} from '../UseTableColumns';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import DataGridCustom from '../../components/DataGridCustom';
import {TestAttributes} from '../../TestAttributes';
import {useSettings} from '../UseSettings';
import SpartanSnackbar from '../../components/SpartanSnackbar';
import {NotificationType} from '../../services/NotificationService';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

function AutoActionListPage() {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const {setLoading} = useLoading();
  const {settings} = useSettings();
  const [autoActions, setAutoActions] = useState<Array<AutoActionListItem> | null>(null);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.AUTO_ACTIONS);
  const [scheduleTime, setScheduleTime] = useState<string>('');

  const tableColumns = useTableColumns({
    featureName: FeatureName.AUTO_ACTIONS,
    defaultActionColumn: true,
  });

  const [{response: getListResponse, loading: isEntityListLoading, error: hasEntityListError}, getAllAutoActions] =
    useAxiosBFF<ResponseListWrapper<AutoActionListItem>>(
      {
        url: `/${FeatureName.AUTO_ACTIONS}`,
        method: 'GET',
        params: {formatted: true},
      },
      {manual: true}
    );

  useEffect(() => {
    getAllAutoActions();
  }, []);
  useEffect(() => {
    setLoading(isEntityListLoading, 'AutoActionListPage');
  }, [isEntityListLoading]);

  useEffect(() => {
    if (getListResponse) {
      setAutoActions(getListResponse?.data?.results || []);
    }
  }, [getListResponse]);
  useEffect(() => {
    if (settings) {
      setScheduleTime(`${settings?.office_start_hour} - ${settings?.office_end_hour}`);
    }
  }, [settings]);
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {hasEntityListError && (
            <SpartanSnackbar type={NotificationType.ERROR} message={hasEntityListError.message}></SpartanSnackbar>
          )}
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('automatic-actions.headline')}</PageHeadline>
              </Grid>
              <Grid item xs={12} md={6}>
                <PageTopActions>
                  <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.CREATE]}>
                    <Link to={`/${FeatureName.AUTO_ACTIONS}/create`}>
                      <Button fullWidth color="secondary" id={'add-btn'}>
                        {t('globalActions.addButton')}
                      </Button>
                    </Link>
                  </AccessControl>
                </PageTopActions>
              </Grid>
            </Grid>
          </PageStickyHeader>
          <Grid item xs={12} sx={{display: 'flex'}}>
            <DateRangeRoundedIcon color="disabled" />
            <Typography variant={'h6'}>{`${t('settings.officeSchedule')}: ${scheduleTime}`}</Typography>
          </Grid>
          <Grid item xs={12} sx={{height: '500px'}} {...{[TestAttributes.TABLE_NAME]: 'table-results'}}>
            {isEntityListLoading ? (
              <Skeleton variant="rectangular" height={500} />
            ) : (
              <DataGridCustom
                tableColumns={tableColumns}
                rows={
                  autoActions?.map((autoAction: AutoActionListItem, index: number) => {
                    return {
                      ...autoAction,
                      created_at: formatDateTime(autoAction.created_at),
                      last_updated_at: formatDateTime(autoAction.last_updated_at),
                      actions: autoAction.auto_action_id,
                      id: index,
                    };
                  }) || []
                }
                userPermissions={userPermissions}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default AutoActionListPage;
