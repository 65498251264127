import {FormBaseEntity} from './FormBaseEntity';

export interface FormField extends FormBaseEntity {
  feature_id: string;
  section_id: string;
  field_id: string;
  field_name: string;
  field_type: string;
  label: string;
}

export function requiresFieldValues(field_type: string) {
  return field_type === 'select' || field_type === 'selectmultiple';
}
