import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {MetaFile} from '../../../model/MetaFiles';
import {ResponseFile} from '../../../model/Files';
import {useFiles} from '../../../UseFiles';
import CircularProgress from '@mui/material/CircularProgress';
interface ImageFileDialogProps {
  isOpen: boolean;
  handleClose: Function;
  imageMetaFile?: MetaFile;
}

function ImageFileDialog({isOpen, handleClose, imageMetaFile}: ImageFileDialogProps) {
  const {t} = useTranslation();
  const {getFile} = useFiles();
  const EMPTY = 'empty';
  const NULL = 'null';
  const [imageUrl, setImageUrl] = useState<string>(EMPTY);

  useEffect(() => {
    if (imageMetaFile) {
      getFile(imageMetaFile)
        .then((audioBuffer: ResponseFile) => {
          setImageUrl(audioBuffer.url);
        })
        .catch(() => setImageUrl(NULL));
    }
  }, [imageMetaFile]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="image-file-dialog-title"
      PaperProps={{
        sx: {
          minHeight: '98vh',
        },
      }}
    >
      <DialogTitle id="image-file-dialog-title">
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{p: 5}}>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Typography component={'div'} sx={{mb: 1, wordBreak: 'break-all'}}>
              <b>{t('file.name')}: </b>
              {imageMetaFile?.name}
            </Typography>
            {imageUrl?.length > EMPTY.length && (
              <Typography component={'div'} sx={{mb: 1, wordBreak: 'break-all'}}>
                <b>URL: </b>
                {imageUrl}
              </Typography>
            )}
          </Grid>
          <Grid container item sm={12} sx={{my: 2}}>
            {imageUrl?.length > EMPTY.length && <img src={imageUrl} alt={imageMetaFile?.name} />}
            {imageUrl === EMPTY && <CircularProgress color="inherit" />}
            {imageUrl === NULL && <Typography color={'red'}>{t('file.file-not-found')}</Typography>}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ImageFileDialog;
