import {createContext, PropsWithChildren, useContext} from 'react';
import {FormField} from '../model/FormField';

const Context = createContext<FieldContext | null>(null);

interface Props extends PropsWithChildren {
  field: FormField;
}

export const FieldProvider = ({field, children}: Props) => {
  return <Context.Provider value={{field}}>{children}</Context.Provider>;
};

export interface FieldContext {
  field: FormField;
}

export function useFieldContext(): FieldContext {
  const value = useContext(Context);
  if (!value) throw Error('invalid context');
  return value;
}
