export interface Support {
  contact_key: string;
  action: string;
  label: string;
  type: string; // TODO ENUM? is this used?
}
export enum SupportType {
  CHAT = 'chat',
  EMAIL = 'email',
  CALL = 'call',
}

export interface Settings {
  support: Array<Support>;
}
