import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Alert, Grid, StepButton} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {replaceScriptPlaceholders} from '../../services/CampaignService';
import {useEffect, useState} from 'react';
import {UserGuideStep} from './model/UserGuideStep';
import {UserGuideStepOption} from './model/UserGuideStepOption';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {UserGuideActionType} from './editor/UserGuideConsts';
import {UserGuideStepAction} from './model/UserGuideStepAction';
import {useLoading} from '../../context/LoadingContext';

interface ScriptStepperProps {
  steps: Array<UserGuideStep>;
  model?: any;
}

export default function UserGuideStepper({steps, model}: ScriptStepperProps) {
  const {t} = useTranslation();
  const {loading} = useLoading();
  const [activeStep, setActiveStep] = useState(0);
  const [previousSteps, setPreviousSteps] = useState<Array<number>>([]);

  useEffect(() => {
    steps[activeStep]?.actions?.map((value: UserGuideStepAction) => runAction(value));
  }, [activeStep, loading]);

  useEffect(() => {
    reset();
  }, [model, steps]);

  const runAction = (value?: UserGuideStepAction) => {
    if (value) {
      switch (value.type) {
        case UserGuideActionType.HIGHLIGHT:
          setTimeout(() => {
            highlightElement(`input[name=${value.target}]`);
          }, 500);
          break;
        case UserGuideActionType.CLICK:
          clickElement(`[id="${value.target}"]`);
          break;
      }
    }
  };

  const highlightElement = (selector: string) => {
    try {
      const element: HTMLElement | null = document?.querySelector(selector);
      if (element?.focus) {
        element.focus();
        element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
      }
    } catch (e) {
      NotificationService.getInstance().sendNotification(
        t('user-guide.stepper.highlight-error'),
        NotificationType.ERROR
      );
    }
  };

  const clickElement = (selector: string) => {
    try {
      const element: HTMLElement | null = document.querySelector(selector);
      if (element?.click) {
        element.click();
        element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
      }
    } catch (e) {
      NotificationService.getInstance().sendNotification(t('user-guide.stepper.click-error'), NotificationType.ERROR);
    }
  };
  function addPreviousStep(
    previousSteps: Array<number>,
    nextStepIndex: number,
    previousStepIndex: number
  ): Array<number> {
    previousSteps[nextStepIndex] = previousStepIndex;
    return previousSteps;
  }
  const handleGoTo = (targetIdentifier: string) => {
    const index = steps.findIndex(
      (step: UserGuideStep) => step?.identifier?.toString() === targetIdentifier.toString()
    );
    const _previousSteps: Array<number> = addPreviousStep(previousSteps, index, activeStep);
    setPreviousSteps(_previousSteps);
    setActiveStep(index);
  };

  useEffect(() => {
    highlightElement(`[id="stepper-step-${activeStep}"]`);
  }, [activeStep]);

  const back = () => {
    setActiveStep(previousSteps[activeStep] || 0);
  };

  function resetPreviousSteps(): void {
    const _previousSteps: Array<number> = [];
    for (let i = 0; i < steps.length; i++) {
      _previousSteps.push(0);
    }
    setPreviousSteps(_previousSteps);
  }
  const reset = () => {
    setActiveStep(0);
    resetPreviousSteps();
  };

  return (
    <Box>
      <Stepper nonLinear activeStep={activeStep} orientation="vertical" id={'call-script-stepper'}>
        {steps?.length === 0 && (
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Typography variant={'body1'}> {t('user-guide.editor.no-steps')} </Typography>
          </Grid>
        )}
        {steps?.map((step: UserGuideStep, index: number) => (
          <Step key={'step-' + index} className={`stepper-step`}>
            <StepButton
              id={`stepper-step-${index}`}
              color="inherit"
              onClick={() => handleGoTo(String(step?.identifier))}
            >
              {step.name}
            </StepButton>
            <StepContent>
              <Grid container spacing={1}>
                <Grid container item xs={9} lg={8} md={6} spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: replaceScriptPlaceholders(step.content, model),
                      }}
                      className={`step-content`}
                    ></Typography>
                  </Grid>
                  {step.hint && (
                    <Grid item xs={12}>
                      <Alert severity={'info'}>
                        <Typography className={`step-hint`}>{step.hint}</Typography>
                      </Alert>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button onClick={reset} sx={{mr: 1}} className={'reset-btn'}>
                      {t('user-guide.stepper.reset')}
                    </Button>
                    <Button disabled={index === 0} onClick={back} className={'back-btn'}>
                      {t('user-guide.stepper.back')}
                    </Button>
                  </Grid>
                </Grid>
                <Grid container item xs={3} lg={4} md={6} alignContent={'baseline'}>
                  {step.options?.map((option: UserGuideStepOption, optionIndex: number) => (
                    <Grid
                      item
                      xs={12}
                      key={`step-${index}-option-${optionIndex}`}
                      display={'flex'}
                      justifyContent={'flex-end'}
                    >
                      <Button
                        className={`step-option-btn`}
                        onClick={() => handleGoTo(option?.target)}
                        color={option?.color === 'primary' ? 'primary' : 'secondary'}
                        sx={{mt: 1, mr: 1}}
                      >
                        {option.name}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
