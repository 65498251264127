import {Grid, IconButton, Paper, styled, Typography, useMediaQuery, useTheme} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {Chat, ChatViewMessage, ChatViewMessageStatus} from '../../../model/SmsPortal';
import {ResponseListWrapper} from '../../../services/model/ResponseListWrapper';
import ArrowCircleUpSharpIcon from '@mui/icons-material/ArrowCircleUpSharp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import {formatDateTime} from '../../../utils/DateUtils';
import {paletteModeDark, useColors} from '../../../context/LightDarkThemeProvider';
import {FeatureName} from '../../../../paths';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import {useAxiosContext} from '../../../context/AxiosContext';
import {useLoading} from '../../../context/LoadingContext';

interface ChatViewProps {
  chat: Chat;
  newMessage?: ChatViewMessage;
}

export const ChatArrows = styled('div')(({theme}) => ({
  width: '50px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '-116px',
  float: 'right',
  opacity: 0.7,
}));

function ChatView({chat, newMessage}: ChatViewProps) {
  const {useAxiosBFF} = useAxiosContext();
  const [chatMessages, setChatMessages] = useState<Array<ChatViewMessage> | null>(null);
  const topRef = useRef<HTMLDivElement>(null);
  const endRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const colors = useColors();
  const {setLoading} = useLoading();
  const isMobileViewActive = useMediaQuery(theme.breakpoints.down('lg'));

  const [{response: getChatResponse, error: getChatError, loading: gatChatLoading}, getChatById] = useAxiosBFF<
    ResponseListWrapper<ChatViewMessage>
  >(
    {
      url: `${FeatureName.SMS_PORTAL_API_WORKAROUND_PATH}/${chat.lead_id}`,
      method: 'GET',
    },
    {manual: true}
  );

  useEffect(() => {
    if (getChatResponse) {
      if (getChatResponse?.status === 200) {
        setChatMessages(getChatResponse?.data?.results);
        scrollToEndRef();
      }
    }
  }, [getChatResponse]);

  useEffect(() => {
    setLoading(gatChatLoading, 'ChatView');
  }, [gatChatLoading]);

  useEffect(() => {
    if (getChatError) {
      NotificationService.getInstance().sendNotification(getChatError?.response?.data?.message, NotificationType.ERROR);
    }
  }, [getChatError]);

  useEffect(() => {
    setChatMessages(null);
    getChatById();
  }, [chat]);

  useEffect(() => {
    if (newMessage) {
      setChatMessages([...(chatMessages || []), newMessage]);
    }
  }, [newMessage]);

  const scrollToTopRef = () => {
    if (topRef) {
      topRef?.current?.scrollIntoView(false);
    }
  };
  const scrollToEndRef = () => {
    if (endRef) {
      endRef?.current?.scrollIntoView(false);
    }
  };
  const messageMarginSpacing = isMobileViewActive ? 2 : 6;

  const MessageContainer = styled(Grid)(({theme}) => ({
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  }));

  return (
    <>
      <Grid
        item
        container
        xs={12}
        sx={{
          height: '450px',
          overflow: 'auto',
          borderRadius: '3px',
          padding: '8px',
          background:
            theme.palette.mode === paletteModeDark
              ? colors.darkModeColors.darkModeSurfaceTransparency
              : theme.palette.secondary.main,
        }}
      >
        <div ref={topRef}></div>
        {chatMessages &&
          chatMessages?.map((chatMessage: ChatViewMessage, index: number) => {
            const messageTextColor =
              chatMessage.sms_status === ChatViewMessageStatus.RECEIVED
                ? theme.palette.mode === paletteModeDark
                  ? theme.palette.primary.contrastText
                  : theme.palette.secondary.contrastText
                : theme.palette.mode === paletteModeDark
                ? theme.palette.secondary.main
                : theme.palette.background.default;
            const messageBackgroundColor =
              chatMessage.sms_status === ChatViewMessageStatus.RECEIVED
                ? theme.palette.mode === paletteModeDark
                  ? theme.palette.secondary.main
                  : theme.palette.background.default
                : theme.palette.primary.main;

            return (
              <MessageContainer
                ref={endRef}
                item
                key={'chat-message-' + chatMessage.number + '-' + index}
                sm={12}
                display={'flex'}
                alignItems={'baseline'}
                justifyContent={chatMessage.sms_status === ChatViewMessageStatus.RECEIVED ? 'flex-end' : 'flex-start'}
              >
                <Paper
                  sx={{
                    textAlign: chatMessage.sms_status === ChatViewMessageStatus.SENT ? 'left' : 'right',
                    padding: (theme) => theme.spacing(2),
                    marginRight: (theme) =>
                      chatMessage.sms_status === ChatViewMessageStatus.RECEIVED
                        ? theme.spacing(messageMarginSpacing)
                        : 0,
                    marginLeft: (theme) =>
                      chatMessage.sms_status === ChatViewMessageStatus.RECEIVED
                        ? theme.spacing(0)
                        : messageMarginSpacing,
                    borderRadius: '3px',
                    marginBottom: (theme) => theme.spacing(2),
                    background: messageBackgroundColor,
                    color: messageTextColor,
                    position: 'relative',
                    maxWidth: '80%',
                    '&:after': {
                      content: "''",
                      position: 'absolute',
                      width: '0',
                      height: '0',
                      borderTop: `15px solid ${messageBackgroundColor}`,
                      borderLeft: '15px solid transparent',
                      borderRight: '15px solid transparent',
                      top: '0',
                      left: chatMessage.sms_status === ChatViewMessageStatus.SENT ? '-15px' : 'none',
                    },
                  }}
                >
                  <Typography variant={'h6'} sx={{fontWeight: 'bold', color: messageTextColor}}>
                    {chatMessage.sms_status === ChatViewMessageStatus.SENT
                      ? chatMessage.username
                      : `${chat?.first_name} ${chat?.last_name}`}
                  </Typography>
                  <Typography
                    variant={'body1'}
                    sx={{
                      color: messageTextColor,
                    }}
                  >
                    {chatMessage.message}
                  </Typography>
                  <Typography variant={'body2'} sx={{fontStyle: 'italic', color: messageTextColor}}>
                    {formatDateTime(chatMessage.created_at)}
                  </Typography>
                </Paper>
              </MessageContainer>
            );
          })}
      </Grid>
      {chatMessages && (
        <ChatArrows>
          <IconButton
            sx={{color: theme.palette.primary.main}}
            onClick={() => {
              scrollToTopRef();
            }}
            size="large"
          >
            <ArrowCircleUpSharpIcon></ArrowCircleUpSharpIcon>
          </IconButton>

          <IconButton
            sx={{color: theme.palette.primary.main}}
            onClick={() => {
              scrollToEndRef();
            }}
            size="large"
          >
            <ArrowCircleDownIcon></ArrowCircleDownIcon>
          </IconButton>
        </ChatArrows>
      )}
    </>
  );
}

export default ChatView;
