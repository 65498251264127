import {Divider, Grid, Typography, useTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Dropzone, {IDropzoneProps, IFileWithMeta} from 'react-dropzone-uploader';
import {FileExtensions} from '../../model/FileExtensions';
import {useAuth0} from '@auth0/auth0-react';
import {Auth0User, getOrgName, getUserEmail, getUserId} from '../../model/Auth0User';
import {useTranslation} from 'react-i18next';
import {HttpMethods} from '../../model/HttpMethods';
import {ResponseFile} from '../../model/Files';
import {ResourceTypes} from '../../model/ResourceTypes';
import {UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName} from '../../../paths';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {MetaFile} from '../../model/MetaFiles';

import {TestAttributes} from '../../TestAttributes';
import {useFiles} from '../../UseFiles';
import {AxiosResponse} from 'axios';
import useAwsBucket from '../../hooks/useAwsBucket';

interface DropzoneWrapperProps {
  maxFiles?: number;
  acceptedExtensions?: FileExtensions[];
  method?: HttpMethods;
  filesToShow?: MetaFile[];
  importFiles?: boolean;
  onChangeFunction?: Function;
  disableUploadButton?: boolean;
  footerLabel?: string;
  userPermissions?: UserPermissions[] | null;
  resourceType?: string;
  resourceURL?: string;
  resourceCallback?: Function;
  disableDragDrop?: boolean;
  disableUploader?: boolean;
  resetDropzone?: boolean;
  resetDropzoneFunction?: Function;
  notSupportedFileMsg?: string;
  isConfirmationNeeded?: boolean;
  handleOpenConfirmationDialog?: Function;
}

export function DropzoneWrapper({
  maxFiles,
  acceptedExtensions = [FileExtensions.TSV],
  method = HttpMethods.POST,
  filesToShow,
  importFiles = false,
  onChangeFunction,
  disableUploader,
  disableUploadButton,
  footerLabel,
  userPermissions,
  resourceType,
  resourceURL,
  resourceCallback,
  disableDragDrop,
  resetDropzone,
  resetDropzoneFunction,
  notSupportedFileMsg,
  isConfirmationNeeded,
  handleOpenConfirmationDialog,
}: DropzoneWrapperProps) {
  const {t} = useTranslation();
  const auth0 = useAuth0<Auth0User>();
  const [uploadedFiles, setUploadedFiles] = useState<IFileWithMeta[]>([]);
  const theme = useTheme();
  const ErrorMessage = notSupportedFileMsg ? notSupportedFileMsg : t('leads.file-not-supported');
  const {postFiles, postFilesAPIv2, executeImport, getFile} = useFiles();
  const awsBucket = useAwsBucket();

  function removeFiles(files: IFileWithMeta[]) {
    while (files.length >= 1 && files[0].meta.status !== 'removed') files[0].remove();
  }

  const handleLeadsSubmit: IDropzoneProps['onSubmit'] = (files, allFiles) => {
    if (!disableUploadButton) {
      files.map((f) => {
        let formData = new FormData();
        const newFile = new File([f.file], `${f.file.name.toLowerCase().trim()}`, {
          type: f.file.type,
        });
        formData.append('file1', newFile);
        formData.append('folder', `${FeatureName.IMPORTS}/${method}`);
        formData.append('email', getUserEmail(auth0.user) || '');
        formData.append('user_id', getUserId(auth0.user) || '');
        const params = {resource: resourceType};
        postFiles(formData).then((response: AxiosResponse<ResponseFile>) => {
          if (response?.status === 201 && importFiles) {
            const importData = {
              filename: `${awsBucket}/${FeatureName.IMPORTS}/${method}/${f?.file?.name.toLowerCase().trim()}`,
              email_notification: getUserEmail(auth0.user) || '',
              bucket: `sparta-${getOrgName(auth0.user) || ''}`,
            };
            executeImport(method, importData, params);
          }
        });

        return f;
      });
      removeFiles(allFiles);
    }
  };

  const handleApiV2Submit: IDropzoneProps['onSubmit'] = (files, allFiles) => {
    if (!disableUploadButton) {
      files.map((f) => {
        let formData = new FormData();
        formData.append('file', f.file);
        postFilesAPIv2(formData, resourceURL).then((response: AxiosResponse<ResponseFile>) => {
          if (response?.status === 200 && resourceCallback) {
            resourceCallback();
          }
        });
        return f;
      });
      removeFiles(allFiles);
    }
  };

  const handleSubmitDialog: IDropzoneProps['onSubmit'] = (files, allFiles) => {
    if (handleOpenConfirmationDialog) {
      handleOpenConfirmationDialog();
    }
  };

  /** 
    Leads import has a different import data flow, it uploads the file to imports and then saves its aws location on spanner
    this is why we have 2 separed submits.
    Once we refactor Leads backend we should use the same upload file method
  **/

  const handleSubmit = resourceType === ResourceTypes.LEADS ? handleLeadsSubmit : handleApiV2Submit;

  let canDownloadAttachments = false;
  if (userPermissions?.filter((permission) => permission === UserPermissions.DOWNLOAD_ATTACHMENTS)) {
    canDownloadAttachments = true;
  }

  useEffect(() => {
    if (resetDropzone && resetDropzoneFunction && uploadedFiles) {
      removeFiles(uploadedFiles);
      resetDropzoneFunction(false);
    }
  }, [resetDropzone, resetDropzoneFunction, uploadedFiles]);

  function onChangeStatus(file: IFileWithMeta, statusValue: string, allFiles: IFileWithMeta[]): void {
    if (onChangeFunction) {
      onChangeFunction(file, statusValue, allFiles);
      setUploadedFiles(allFiles);
    }
    if (statusValue === 'rejected_file_type') {
      NotificationService.getInstance().sendNotification(ErrorMessage, NotificationType.ERROR);
    }
  }

  function downloadFile(file: MetaFile) {
    getFile(file).then((_file: ResponseFile) => {
      window.open(_file?.url);
    });
  }

  return (
    <Grid container spacing={2}>
      {!disableUploader ? (
        <Grid {...{[TestAttributes.BUTTON_NAME]: 'dropzone'}} item xs={12} md={filesToShow?.length ? 7 : 12}>
          <Dropzone
            styles={{
              dropzone: {
                backgroundColor: theme.palette.primary.contrastText,
                overflowX: 'hidden',
                overflowY: 'hidden',
              },
              inputLabel: {padding: '0 0.5rem', color: theme.palette.primary.main},
              inputLabelWithFiles: {
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
                margin: '1rem',
              },
            }}
            disabled={disableDragDrop}
            onSubmit={disableUploadButton ? undefined : isConfirmationNeeded ? handleSubmitDialog : handleSubmit}
            onChangeStatus={(file, statusValue, allFiles) => onChangeStatus(file, statusValue, allFiles)}
            inputContent={t('uploader.upload-drag-and-drop-msg')}
            inputWithFilesContent={(files) =>
              maxFiles ? `${maxFiles - files.length} ${t('uploader.more-msg')}` : `${t('uploader.add-files-msg')}`
            }
            maxFiles={maxFiles}
            submitButtonContent={<p id={'choose-file-btn'}>{t('uploader.upload-file-btn')}</p>}
            accept={acceptedExtensions?.join(', ')}
          />
        </Grid>
      ) : null}
      {filesToShow?.length ? (
        <Grid item xs={12} md={disableUploader ? 12 : 5}>
          <Typography gutterBottom variant="h2" component="p">
            {t('uploader.files-title')}
          </Typography>
          <Divider />
          <li>
            {filesToShow?.map((file) => {
              return (
                <Typography
                  key={file.key}
                  sx={{wordWrap: 'break-word', cursor: canDownloadAttachments ? 'pointer' : 'none'}}
                  onClick={canDownloadAttachments ? () => downloadFile(file) : undefined}
                >
                  {file.name}
                </Typography>
              );
            })}
          </li>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Typography variant={'body1'}>{footerLabel}</Typography>
      </Grid>
    </Grid>
  );
}
