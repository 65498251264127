import React, {useEffect, useState} from 'react';
import {Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useAuth0} from '@auth0/auth0-react';
import ChatList from './ChatList';
import {Chat, ChatViewMessage} from '../../../model/SmsPortal';
import {ResponseListWrapper} from '../../../services/model/ResponseListWrapper';
import ChatView from './ChatView';
import ChatField from './ChatField';
import {Auth0User, getUserOrganizationRoles} from '../../../model/Auth0User';
import UserRoleService from '../../../services/UserRoleService';
import {OrganizationRoleName} from '../../../model/Organization';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import {FeatureName} from '../../../../paths';
import {useAxiosContext} from '../../../context/AxiosContext';
import {useLoading} from '../../../context/LoadingContext';

function SmsPortal() {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const auth0 = useAuth0<Auth0User>();
  const {setLoading} = useLoading();
  const [chats, setChats] = useState<Array<Chat>>([]);
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);
  const [newMessage, setNewMessage] = useState<ChatViewMessage>();

  const [{response: getChatsResponse, error: getChatsError, loading: gatChatsLoading}, getChats] = useAxiosBFF<
    ResponseListWrapper<Chat>
  >(
    {
      url: FeatureName.SMS_PORTAL_API_WORKAROUND_PATH,
      method: 'GET',
    },
    {manual: true}
  );

  useEffect(() => {
    if (getChatsResponse) {
      if (getChatsResponse?.status === 200) {
        setChats(getChatsResponse.data.results);
      }
    }
  }, [getChatsResponse]);

  useEffect(() => {
    setLoading(gatChatsLoading, 'SmsPortal');
  }, [gatChatsLoading]);

  useEffect(() => {
    if (getChatsError) {
      NotificationService.getInstance().sendNotification(
        getChatsError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [getChatsError]);
  useEffect(() => {
    const {user} = auth0;
    const isAdmin = getUserOrganizationRoles(user).some(
      (role: any) =>
        role.role_name === OrganizationRoleName.ADMIN &&
        role.role_id === UserRoleService.getInstance().getCurrentValue()
    );
    getChats({
      params: {
        admin: isAdmin,
      },
    });
  }, []);

  const handleOnChatClick = (chat: Chat) => {
    setSelectedChat(chat);
  };
  const handleOnArchive = () => {
    setSelectedChat(null);
  };

  return (
    <Grid container item spacing={3}>
      <Grid container item sm={4}>
        <ChatList chats={chats} onChatClick={handleOnChatClick} onArchive={handleOnArchive} />
      </Grid>
      <Grid container item xs={12} sm={8}>
        <Grid item xs={12} minHeight={'85%'}>
          {selectedChat ? (
            <ChatView chat={selectedChat} newMessage={newMessage}></ChatView>
          ) : (
            <Typography
              variant={'h4'}
              sx={{
                textAlign: 'center',
              }}
            >
              {t('sms-portal.no-messages')}
            </Typography>
          )}
        </Grid>
        <Grid container item xs={12}>
          <ChatField chat={selectedChat} setNewMessage={setNewMessage}></ChatField>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SmsPortal;
