import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid, Switch, Typography} from '@mui/material';
import {TestAttributes} from '../../TestAttributes';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

export interface paymentPermissionsProps {
  paymentPermissions: any;
}
function PaymentInformationSettings({paymentPermissions}: paymentPermissionsProps) {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const {setLoading} = useLoading();
  const SETTING_URL = 'leads/payment-clearance/settings';
  const [enable, setEnable] = useState<boolean>(false);
  const [roles, setRoles] = useState<Array<string>>([]);
  const [{error: patchError, response, loading: patchLoading}, saveSettings] = useAxiosBFF<boolean>(
    {
      url: `${SETTING_URL}`,
      method: 'PATCH',
    },
    {manual: true}
  );

  useEffect(() => {
    if (paymentPermissions) {
      setRoles(paymentPermissions.roles);
      setEnable(!paymentPermissions.permission_status);
    }
  }, [paymentPermissions]);

  useEffect(() => {
    if (patchError) {
      NotificationService.getInstance().sendNotification(patchError?.response?.data?.message, NotificationType.ERROR);
    }
  }, [patchError]);

  useEffect(() => {
    setLoading(patchLoading, 'SettingsPaymentInformation');
  }, [patchLoading]);

  useEffect(() => {
    if (response?.status === 200) {
      NotificationService.getInstance().sendNotification(response.data?.['message'], NotificationType.SUCCESS);
    }
  }, [response]);

  function save(disabled: boolean) {
    saveSettings({
      data: {permission_disabled: disabled},
    });
  }
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h2">{t('settings.payment-switch')}</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography>{t('settings.payment-info-header')}</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography>
          {`${t('settings.payment-info')}${roles
            ?.map((role) => role)
            .toString()
            .toUpperCase()
            .replaceAll(',', ', ')}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Switch
          color="primary"
          checked={enable}
          onChange={() => {
            save(enable);
            setEnable(!enable);
          }}
          {...{[TestAttributes.BUTTON_NAME]: 'toggle-payment-btn'}}
        />
      </Grid>
    </>
  );
}

export default PaymentInformationSettings;
