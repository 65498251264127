import {Button, Grid, TextField} from '@mui/material';
import React, {ChangeEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ChatViewMessage, ChatViewMessageStatus, InteractionRole} from '../../../model/CreativeAssistant';
import {paletteModeDark, useColors} from '../../../context/LightDarkThemeProvider';

interface ChatFieldProps {
  setNewMessage: (newMessage: ChatViewMessage) => void;
  copyChat: () => void;
  chatLimitReached: boolean;
}

function ChatField({setNewMessage, copyChat, chatLimitReached}: ChatFieldProps) {
  const {t} = useTranslation();
  const colors = useColors();
  const [message, setMessage] = useState<string | null>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.currentTarget.value);
  };

  const sendMessage = () => {
    if (message?.trim()) {
      const newMessage: ChatViewMessage = {
        message,
        status: ChatViewMessageStatus.SENT,
        owner: InteractionRole.USER,
      };

      setNewMessage(newMessage);
      setMessage('');
    }
  };

  return (
    <Grid container item spacing={3} direction={'column'}>
      <Grid item>
        <TextField
          fullWidth
          multiline
          minRows={3}
          maxRows={10}
          value={message}
          onChange={handleChange}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === paletteModeDark
                ? colors.darkModeColors.darkModeSurfaceTransparency
                : theme.palette.secondary.main,
          }}
        />
      </Grid>
      <Grid item container spacing={3}>
        <Grid item>
          <Button
            id={'send-msg-btn'}
            onClick={() => sendMessage()}
            sx={{
              mb: '1rem',
              color: (theme) => theme.palette.primary.contrastText,
            }}
            disabled={!message || message?.trim().length === 0 || chatLimitReached}
          >
            {t('creative-assistant.send-message-button')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            id={'copy-chat-btn'}
            onClick={() => copyChat()}
            sx={{
              mb: '1rem',
              color: (theme) => theme.palette.primary.contrastText,
            }}
          >
            {t('creative-assistant.copy-chat-button')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ChatField;
