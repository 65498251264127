import {Box, ListItem, ListItemText} from '@mui/material';
import React from 'react';
import {FixedSizeList, ListChildComponentProps} from 'react-window';
import {QueryFieldSchema} from '../../../model/Query';

interface VirtualFieldListProps {
  fields: Array<QueryFieldSchema>;
  handleOnClick: Function;
  disabled?: boolean;
  height?: number;
}

function VirtualFieldList({fields, handleOnClick, disabled, height = 400}: VirtualFieldListProps) {
  function renderRow(props: ListChildComponentProps) {
    const {index, style} = props;

    return (
      <ListItem
        id={`available_field-${fields[index].field_name}`}
        className={'available_field'}
        data-field-type={fields[index].field_type}
        disabled={disabled}
        divider
        button
        style={style}
        key={index}
        onClick={() => handleOnClick(fields[index])}
      >
        <ListItemText primary={`${fields[index].label}`} />
      </ListItem>
    );
  }
  return (
    <Box sx={{width: '100%', height: height}} id={'virtual-field-fixed-list'}>
      <FixedSizeList height={height} width={'100%'} itemSize={46} itemCount={fields.length} overscanCount={5}>
        {renderRow}
      </FixedSizeList>
    </Box>
  );
}

export default VirtualFieldList;
