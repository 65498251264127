import {FormControl, Switch} from '@mui/material';
import {useTranslation} from 'react-i18next';
import FormLabel from '@mui/material/FormLabel';
import {TestAttributes} from '../../../TestAttributes';
import {useUserSettingsContext} from '../../../context/UserSettingsContext';

export default function ChatMode() {
  const {t} = useTranslation();
  const {userSettings, setPartialUserSettings} = useUserSettingsContext();

  function toggleChatDisplay(): void {
    setPartialUserSettings({show_chat: !userSettings.show_chat});
  }

  return (
    <>
      <FormControl>
        <FormLabel>{t('lana-bot.chat')}</FormLabel>
        <Switch
          checked={userSettings.show_chat}
          onChange={toggleChatDisplay}
          {...{[TestAttributes.BUTTON_NAME]: 'toggle-chat-box-btn'}}
        />
      </FormControl>
    </>
  );
}
