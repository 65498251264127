import {deepCopy, deepEqual, intersectArrays, pushAllIfAbsent, pushIfAbsent} from '../utils';
import {
  cloneState,
  createEmptyState,
  extractFieldState,
  FieldError,
  FormState,
  FromFieldState,
  FromSectionState,
  hasChangedFields,
  isValidState,
  StringKeyObject,
} from './FormState';
import {collectDefaultValues, extractFieldOptionValues} from '../logic/utils';
import {BehaviorSubject} from 'rxjs';
import {OnRejectSubmitHandler, OnSubmitHandler} from '../model';
import {FormFieldOption} from '../logic/FormSchema';
import {ComputeContext} from '../logic/ComputeContext';
import * as _ from 'lodash';
import {FormHelper} from '../FormHelper';

interface RefreshOptions {
  // refresh only portion of state that depends on these changed fields
  changedFieldNames?: string[];
  // refresh entire state ignoring dependencies, usually executed after injecting new initialValues into the form
  refreshAll?: boolean;
  depth?: number;
}

export class FormStateHandler {
  private readonly parent: FormHelper;
  private mutator: FormStateMutator;

  $state: BehaviorSubject<FormState>;

  constructor(parent: FormHelper) {
    const state = createEmptyState();
    this.$state = new BehaviorSubject<FormState>(state);
    this.parent = parent;
    this.mutator = FormStateMutator.from(state);
  }

  withInitialValues(initialValues: any) {
    this.mutator.setInitialValues({...collectDefaultValues(this.parent.schemaHelper.schema), ...initialValues});
    this.__refreshStatePostChange({refreshAll: true});
    this.mutator.setInitialized(true);
    return this;
  }

  start(): FormStateHandler {
    return this.startFrom(this.mutator.getState());
  }

  // always call this method to start a new mutation
  startFrom(state: FormState | undefined): FormStateHandler {
    this.mutator = FormStateMutator.from(cloneState(state));
    return this;
  }

  next(): FormState {
    // Always summarize on next
    this.mutator.summarize();
    const prev = this.$state.getValue();
    if (this.mutator.isEqual(prev)) {
      return prev;
    }
    const next = this.mutator.getState();
    this.$state.next(next);
    // expose to global
    window['__STATE__'] = deepCopy(next);
    return next;
  }

  blur(name: string): FormStateHandler {
    this.mutator.setFieldTouched(name, true);
    return this;
  }

  reset(): FormStateHandler {
    const changedFieldNames = this.mutator.reset();
    this.__resetAllChanged();
    this.__refreshStatePostChange({changedFieldNames});
    return this;
  }

  submit(onSubmit: OnSubmitHandler | undefined, onRejectSubmit: OnRejectSubmitHandler | undefined): FormState {
    const state = this.mutator.getState();
    if (!state.valid) {
      this.__touchAllFields();
    }

    const next = this.next();
    if (next.valid) {
      if (onSubmit) {
        onSubmit(next.values, next);
      } else {
        console.warn('OnSubmitHandler is undefined');
      }
    } else {
      if (onRejectSubmit) {
        onRejectSubmit(next.errors, next);
      } else {
        console.warn('OnRejectSubmitHandler is undefined');
      }
    }
    return next;
  }

  change(name: string, value: any): FormStateHandler {
    this.mutator.setFieldValue(name, value);
    this.mutator.setFieldTouched(name, true); // onBlur should handle this, anyway, better be sure ;)
    return this.__refreshStatePostChange({changedFieldNames: [name]});
  }

  private __touchAllFields(): FormStateHandler {
    for (let fieldName of this.parent.schemaHelper.getFieldNames()) {
      this.mutator.setFieldTouched(fieldName, true);
    }
    return this;
  }

  private __resetAllChanged(): FormStateHandler {
    for (let fieldName of this.parent.schemaHelper.getFieldNames()) {
      this.mutator.setFieldChanged(fieldName, false);
    }
    return this;
  }

  private __refreshStatePostChange(options: RefreshOptions): FormStateHandler {
    const changedFields = options.changedFieldNames ? [...options.changedFieldNames] : [];
    const refreshAll = !!options.refreshAll;

    // shortcut
    if (!refreshAll && changedFields.length === 0) return this;

    const depth = options.depth || 5;

    if (depth <= 0) {
      console.warn(`Max number of side-effect reached. UI might be buggy...`, options);
      return this;
    }

    const helper = this.parent.schemaHelper;
    const state = this.mutator.getState();
    const values = state.values;
    const changedFields4NextIter = new Array<string>();

    const ctx = {first_render: !state.initialized};

    for (const [, trigger] of Array.from(helper.triggers.entries())) {
      if (refreshAll || intersectArrays(trigger.dependencies, changedFields).length) {
        const computedValues = trigger.compute(values, ctx);
        if (computedValues && Object.keys(computedValues).length) {
          const __changedFieldNames = this.mutator.setValues(computedValues);
          if (__changedFieldNames.length > 0) {
            pushAllIfAbsent(changedFields4NextIter, __changedFieldNames);
          }
        }
      }
    }

    // section
    for (const [sectionId, isSectionHidden] of Array.from(helper.isSectionHiddenFunctions.entries())) {
      if (refreshAll || intersectArrays(isSectionHidden.dependencies, changedFields).length) {
        const hidden = isSectionHidden.compute(values, ctx);
        this.mutator.setSectionHidden(sectionId, hidden);
      }
    }

    // field
    for (const [fieldName, isHidden] of Array.from(helper.isHiddenFunctions.entries())) {
      if (refreshAll || intersectArrays(isHidden.dependencies, changedFields).length) {
        const hidden = isHidden.compute(values, ctx);
        const changed = this.mutator.setFieldHidden(fieldName, hidden);
        if (changed) {
          pushIfAbsent(changedFields4NextIter, fieldName);
        }
      }
    }
    for (const [fieldName, isDisabled] of Array.from(helper.isDisabledFunctions.entries())) {
      if (extractFieldState(state, fieldName).hidden) continue;
      if (refreshAll || intersectArrays(isDisabled.dependencies, changedFields).length) {
        const disabled = isDisabled.compute(values, ctx);
        this.mutator.setFieldDisabled(fieldName, disabled);
      }
    }
    for (const [fieldName, isRequired] of Array.from(helper.isRequiredFunctions.entries())) {
      if (extractFieldState(state, fieldName).hidden) continue;
      if (refreshAll || intersectArrays(isRequired.dependencies, changedFields).length) {
        const required = isRequired.compute(values, ctx);
        this.mutator.setFieldRequired(fieldName, required);
      }
    }
    for (const [fieldName, validation] of Array.from(helper.validations.entries())) {
      if (extractFieldState(state, fieldName).hidden) continue;
      if (refreshAll || intersectArrays(validation.dependencies, changedFields).length) {
        const error = validation.compute(values, ctx);
        this.mutator.setFieldError(fieldName, error);
      }
    }
    for (const [fieldName, optionsFilter] of Array.from(helper.optionsFilters.entries())) {
      if (extractFieldState(state, fieldName).hidden) continue;
      if (refreshAll || intersectArrays(optionsFilter.dependencies, changedFields).length) {
        const options = helper.getFieldSchema(fieldName)?.options || [];
        const filteredOptions = optionsFilter.compute(options, values, ctx);
        const changed = this.mutator.setFieldOptions(fieldName, filteredOptions, ctx);
        if (changed) {
          pushIfAbsent(changedFields4NextIter, fieldName);
        }
      }
    }

    if (changedFields4NextIter.length > 0) {
      this.__refreshStatePostChange({changedFieldNames: changedFields4NextIter, depth: depth - 1});
    }

    return this;
  }
}

/**
 * Utility class used ONLY by FormStateHandler to mutate the state.
 */
class FormStateMutator {
  private readonly state: FormState;

  private constructor(state: FormState) {
    this.state = state;
  }

  /**
   * IMPORTANT: this causes side effects on the state in input.
   */
  static from(state: FormState) {
    return new FormStateMutator(state);
  }

  getState(): FormState {
    return this.state;
  }

  isEqual(other: FormState): boolean {
    return deepEqual(this.state, other);
  }

  setInitialized(initialized: boolean) {
    // initialized MUST be false during first load
    this.state.initialized = initialized;
  }

  summarize() {
    // remove errors entries with no error message
    this.state.errors = _.pickBy(this.state.errors, (error, field_name) => error !== undefined);
    // set overall flags
    this.state.valid = isValidState(this.state);
    this.state.changed = hasChangedFields(this.state);
  }

  setInitialValues(initialValues: StringKeyObject<any>): string[] {
    this.state.initialValues = initialValues;
    return this.setValues(initialValues);
  }

  setValues(values: StringKeyObject<any>): string[] {
    const changedFieldNames = [];
    for (const name in values) {
      if (values.hasOwnProperty(name)) {
        const changed = this.setFieldValue(name, values[name]);
        if (changed) changedFieldNames.push(name);
      }
    }
    return changedFieldNames;
  }

  reset(): string[] {
    const changedFieldNames: string[] = [];
    // find fields non-empty not included in initialValues, such fields need to be manually set to undefined
    const valuesFieldNames = Object.keys(this.state.values);
    const initialValuesFieldNames = Object.keys(this.state.initialValues);
    const fieldsToReset = valuesFieldNames.filter((name) => !initialValuesFieldNames.includes(name));
    for (const name of fieldsToReset) {
      const changed = this.setFieldValue(name, undefined);
      if (changed) changedFieldNames.push(name);
    }
    pushAllIfAbsent(changedFieldNames, this.setValues(this.state.initialValues));
    return changedFieldNames;
  }

  setFieldValue(name: string, value: any): boolean {
    if (this.state.values[name] !== value) {
      this.state.values[name] = value;
      if (this.state.initialized) {
        this.setFieldChanged(name, true);
      }
      return true;
    }
    return false;
  }

  setFieldError(name: string, error: FieldError): boolean {
    if (this.state.errors[name] !== error) {
      this.state.errors[name] = error;
      return true;
    }
    return false;
  }

  setFieldOptions(name: string, options: FormFieldOption[], ctx: ComputeContext | undefined) {
    if (this.state.options[name] !== options) {
      this.state.options[name] = options;

      // if not first render, reset/remove values not included in option-values
      if (!ctx?.first_render) {
        const optionValues = extractFieldOptionValues(options);
        const value = this.state.values[name];
        if (Array.isArray(value)) {
          const values = value.filter((i) => optionValues.includes(i));
          return this.setFieldValue(name, values);
        } else if (!optionValues.includes(value)) {
          // use `null` instead of `undefined` as ensure the value `null` is sent to BE (this is a workaround!!)
          // TODO: the issue needs to be addressed when building the payload
          //  for PATCH requests. See https://spartanapproach.atlassian.net/browse/FE-470
          return this.setFieldValue(name, null);
        }
      }
    }
    return false;
  }

  setFieldHidden(name: string, hidden: boolean): boolean {
    const temp = this._getOrCreateField(name);
    if (temp.hidden !== hidden) {
      temp.hidden = hidden;
      if (hidden) {
        this.setFieldError(name, undefined);
        this.setFieldValue(name, undefined);
      } else {
        const initialValue = this.state.initialValues[name];
        this.setFieldValue(name, initialValue);
      }
      return true;
    }
    return false;
  }

  setFieldRequired(name: string, required: boolean): boolean {
    const temp = this._getOrCreateField(name);
    if (temp.required !== required) {
      temp.required = required;
      return true;
    }
    return false;
  }

  setFieldDisabled(name: string, disabled: boolean): boolean {
    const temp = this._getOrCreateField(name);
    if (temp.disabled !== disabled) {
      temp.disabled = disabled;
      return true;
    }
    return false;
  }

  setFieldTouched(name: string, touched: boolean): boolean {
    const temp = this._getOrCreateField(name);
    if (temp.touched !== touched) {
      temp.touched = touched;
      return true;
    }
    return false;
  }

  setFieldChanged(name: string, changed: boolean): boolean {
    const temp = this._getOrCreateField(name);
    if (temp.changed !== changed) {
      temp.changed = changed;
      return true;
    }
    return false;
  }

  setSectionHidden(sectionId: string, hidden: boolean): boolean {
    const temp = this._getOrCreateSection(sectionId);
    if (temp.hidden !== hidden) {
      temp.hidden = hidden;
      return true;
    }
    return false;
  }

  private _getOrCreateField(name: string): FromFieldState {
    // initialize field if undefined
    if (this.state.fields[name] === undefined) {
      this.state.fields[name] = {};
    }
    return this.state.fields[name];
  }

  private _getOrCreateSection(name: string): FromSectionState {
    // initialize section if undefined
    if (this.state.sections[name] === undefined) {
      this.state.sections[name] = {};
    }
    return this.state.sections[name];
  }
}
