import NotificationService, {NotificationType} from '../services/NotificationService';

export function onRejectSubmit(errors: any) {
  const errorMessages = Object.values(errors).filter((error) => !!error);
  if (errorMessages.length) {
    console.warn('Submit rejected due to errors', errors);
    NotificationService.getInstance().sendNotification(
      'Missing fields: ' + errorMessages.length + ' required field(s) empty or contain(s) invalid data',
      NotificationType.ERROR
    );
  }
}
