import {BehaviorSubject, Observable} from 'rxjs';

export default class BehaviorSubjectService<T> {
  private subject = new BehaviorSubject<T | null>(null);

  set(value: T): void {
    this.subject.next(value);
  }

  get(): Observable<T | null> {
    return this.subject.asObservable();
  }

  getValue(): T | null {
    return this.subject.value;
  }

  isMissing(): boolean {
    return this.subject.value === null || this.subject.value === undefined;
  }
}
