import {useTranslation} from 'react-i18next';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Support} from '../../model/Settings';
import {useAuth0} from '@auth0/auth0-react';
import {Auth0User, getUserExtension, getUserId, getUsername, getUserPhoneNumber} from '../../model/Auth0User';
import OrganizationService from '../../services/OrganizationService';
import {HttpMethods} from '../../model/HttpMethods';
import {AxiosResponse} from 'axios';
import {useEffect} from 'react';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {useAxiosContext} from '../../context/AxiosContext';

interface TalkDialogProps {
  isOpen: boolean;
  handleClose: Function;
  support: Support;
}

function TalkDialog({isOpen, handleClose, support}: TalkDialogProps) {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const auth0 = useAuth0<Auth0User>();
  const organization = OrganizationService.getInstance().getCurrentValue();
  const [{error: hasMakeCallError}, makeCall] = useAxiosBFF(
    {
      method: HttpMethods.POST,
      url: `callclick/`,
    },
    {manual: true}
  );

  useEffect(() => {
    if (hasMakeCallError) {
      NotificationService.getInstance().sendNotification(hasMakeCallError?.message, NotificationType.ERROR);
    }
  }, [hasMakeCallError]);

  function handleTalkAction() {
    const data = {
      account: getUsername(auth0.user) ?? '',
      caller_id: getUserPhoneNumber(auth0.user) ?? '',
      extension: getUserExtension(auth0.user) ?? '',
      local_presence: organization?.local_presence ?? false,
      phone_number: support?.contact_key?.replace(/\D+/g, '') ?? '',
      press_one: organization?.press_one ?? false,
      user_id: getUserId(auth0.user) ?? '',
      lead_id: 'support',
      campaign_name: 'support',
      created_by: getUserId(auth0.user) ?? '',
    };

    makeCall({data: data}).then((response: AxiosResponse) => {
      if (response?.status === 200) {
        NotificationService.getInstance().sendNotification(response?.data?.message, NotificationType.SUCCESS);
        handleClose();
      }
    });
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={isOpen}
        onClose={() => handleClose()}
        aria-labelledby="talk-dialog-title"
        aria-describedby="talkNumber-dialog-description secondInfo-dialog-description"
        sx={{textAlign: 'center'}}
      >
        <HelpOutlineIcon color="primary" sx={{fontSize: 70, margin: 'auto'}} />
        <DialogTitle id="talk-dialog-title">{t('supportDialogs.headLine')}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="talkNumber-dialog-description"
            sx={{width: '100%', padding: (theme) => ` ${theme.spacing(2)}`}}
          >
            {t('supportDialogs.talkInfo') + support.contact_key}
          </DialogContentText>
          <DialogContentText id="secondInfo-dialog-description">{t('supportDialogs.secondInfo')}</DialogContentText>
          <DialogContentText id="thirdInfo-dialog-description">{t('supportDialogs.thirdInfo')}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center'}}>
          <Button onClick={() => handleClose()} id={'cancel-btn'}>
            {t('supportDialogs.cancelButton')}
          </Button>
          <Button onClick={() => handleTalkAction()} id={'chat-btn'}>
            {t('supportDialogs.talkButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TalkDialog;
