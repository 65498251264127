import {useRef} from 'react';
import {deepMerge} from '../utils';
import {BehaviorSubject} from 'rxjs';
import {FormFieldOption} from '../logic/FormSchema';

export function createEmptyState(): FormState {
  return {
    sections: {},
    fields: {},
    values: {},
    initialValues: {},
    errors: {},
    options: {},
  };
}

export function cloneState(source: FormState | undefined): FormState {
  return mergeStates(createEmptyState(), source);
}

export function mergeStates(target: FormState, source: FormState | undefined): FormState {
  if (source) {
    target.initialized = source.initialized;
    target.valid = source.valid;
    target.changed = source.changed;
    deepMerge(target.sections, source.sections);
    deepMerge(target.fields, source.fields);
    deepMerge(target.values, source.values);
    deepMerge(target.initialValues, source.initialValues);
    deepMerge(target.errors, source.errors);
    deepMerge(target.options, source.options);
  }
  return target;
}

// check if al least one error has non-undefined message
export function isValidState(state: FormState): boolean {
  const errorMessages = Object.values(state.errors);
  for (const error of errorMessages) {
    if (error !== undefined) {
      return false;
    }
  }
  return true;
}

export function hasChangedFields(state: FormState): boolean {
  for (const field of Object.values(state.fields)) {
    if (field.changed) return true;
  }
  return false;
}

export function extractChangedValues(state: FormState): StringKeyObject<any> {
  return __extractValues(state, (f) => f.changed ?? false);
}

export function extractTouchedValues(state: FormState): StringKeyObject<any> {
  return __extractValues(state, (f) => f.touched ?? false);
}

function __extractValues(state: FormState, filter: (field: FromFieldState) => boolean): StringKeyObject<any> {
  const values = {};
  for (const [fieldName, field] of Object.entries(state.fields)) {
    if (filter(field)) {
      values[fieldName] = state.values[fieldName];
    }
  }
  return values;
}

export function extractFieldState(state: FormState, name: string): FromFieldState {
  return state.fields[name] || {};
}

export function extractSectionState(state: FormState, name: string): FromSectionState {
  return state.sections[name] || {};
}

export interface FormState {
  sections: StringKeyObject<FromSectionState>;
  fields: StringKeyObject<FromFieldState>;
  initialValues: StringKeyObject<any>;
  values: StringKeyObject<any>;
  errors: FieldErrors;
  options: StringKeyObject<FormFieldOption[]>;
  /**
   * Helper flag for FromFieldState.changed.
   * When TRUE the state is ready to accept changes. If a value changes, the relative field is marked as "changed".
   * When FALSE, the initialization is still in progress, which means value changes are applied but the field is not
   * marked as "changed".
   * TL;DR: initialized MUST be false during process of initialValues
   */
  initialized?: boolean;
  valid?: boolean;
  changed?: boolean;
}

export interface StringKeyObject<T> {
  [key: string]: T;
}

export type FieldErrors = StringKeyObject<FieldError>;
export type FieldError = string | undefined;

export interface FromSectionState {
  hidden?: boolean;
}

export interface FromFieldState {
  required?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  touched?: boolean;
  /**
   * Mark for value changes on a field. It's different from touched in two cases:
   *  1. A field may be touched but not changed
   *  2. A field may be not touched and changed by a trigger
   */
  changed?: boolean;
}

export function useStateSubject(): BehaviorSubject<FormState> {
  return useRef(new BehaviorSubject(createEmptyState())).current;
}
