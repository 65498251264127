import {equal, isBlank, isBlankOrEmpty, testRegex} from '../utils';
import {evalExpression} from '../eval/Eval';
import {pushAllIfAbsent, pushIfAbsent} from '../../utils';
import {ComputeContext, isOutOfScope, WithComputeScope} from '../ComputeContext';
import {FormFieldSchema, FormSectionSchema} from '../FormSchema';
import {isDate, isDateTime, isTime, parseDate, parseDateTime} from '../../../utils/DateUtils';

export type FormFieldConditionOrBool = boolean | FormFieldCondition;

export interface FormFieldCondition extends WithComputeScope {
  type: FormFieldConditionType;
  reverse?: boolean;
}

export type FormFieldConditionType = 'multiple_condition' | 'single_condition';

export interface FormFieldMultipleCondition extends FormFieldCondition {
  type: 'multiple_condition';
  operator: FormFieldMultipleConditionOperator;
  value: FormFieldConditionOrBool[];
}

export const FormFieldMultipleConditionOperators = ['and', 'or'];

export type FormFieldMultipleConditionOperator = typeof FormFieldMultipleConditionOperators[number];

export const FormFieldSingleConditionOperators = [
  'eval',
  'regex',
  'equal',
  'equal_ignore_case',
  'is_blank',
  'is_not_blank',
  'is_valid_type',
  'max_length',
  'max_length_relative',
  'min_length',
  'min_length_relative',
  'greater_than',
  'greater_than_relative',
  'greater_than_or_equal',
  'greater_than_or_equal_relative',
  'less_than',
  'less_than_relative',
  'less_than_or_equal',
  'less_than_or_equal_relative',
];

export type FormFieldSingleConditionOperator = typeof FormFieldSingleConditionOperators[number];

// These operators depend on other values in the form, therefore "relative" (see FE-537).
// The following list is used to collect condition dependencies, see getConditionDependencies.
// No need to export, only used internally.
const FormFieldSingleConditionRelativeOperators = [
  'max_length_relative',
  'min_length_relative',
  'greater_than_relative',
  'greater_than_or_equal_relative',
  'less_than_relative',
  'less_than_or_equal_relative',
];

export interface FormFieldSingleCondition extends FormFieldCondition {
  type: 'single_condition';
  operator: FormFieldSingleConditionOperator;
  field_name: string;
  value: any;
}

export function isCondition(conditionOrBool: FormFieldConditionOrBool): boolean {
  return toCondition(conditionOrBool) !== undefined;
}

export function toCondition(conditionOrBool: FormFieldConditionOrBool): FormFieldCondition | undefined {
  return typeof conditionOrBool === 'boolean' ? undefined : conditionOrBool;
}

/**
 * @return all fields that the current condition depends on
 */
export function getConditionDependencies(conditionOrBool: FormFieldConditionOrBool): string[] {
  const dependencies: string[] = [];
  traverseConditions(conditionOrBool, (leaf) => {
    if ('eval' === leaf.operator) {
      // "eval" conditions are different other FormFieldSingleCondition(s).
      // They do not have a field_name.
      // Moreover, such conditions do not have explicit dependencies.
      // Therefore, we need to parse the to-be-evaluated expression in order to extract
      // the dependencies. This is risky! But also the only solution available :(
      const regex = /values\.(\w+)/g;
      let match = regex.exec(leaf.value);
      while (match != null && match.length > 1) {
        pushIfAbsent(dependencies, match[1]);
        match = regex.exec(leaf.value);
      }
    } else {
      pushIfAbsent(dependencies, leaf.field_name);
      if (FormFieldSingleConditionRelativeOperators.includes(leaf.operator)) {
        pushIfAbsent(dependencies, leaf.value);
      }
    }
  });
  // filter() assures that we do not return any invalid dependency (we want only defined strings)
  return dependencies.filter((d) => d !== undefined && d !== null);
}

export function traverseConditions(
  conditionOrBool: FormFieldConditionOrBool,
  consumer: (condition: FormFieldSingleCondition) => void
): void {
  if (typeof conditionOrBool === 'boolean') {
    // do nothing
  } else if (conditionOrBool.type === 'single_condition') {
    consumer(conditionOrBool as FormFieldSingleCondition);
  } else if (conditionOrBool.type === 'multiple_condition') {
    (conditionOrBool as FormFieldMultipleCondition).value.map((c) => traverseConditions(c, consumer));
  } else {
    throw Error(`Unsupported condition type ${conditionOrBool?.type}`);
  }
}

export function findCondition(
  conditionOrBool: FormFieldConditionOrBool,
  test: (condition: FormFieldSingleCondition | FormFieldMultipleCondition) => boolean
): FormFieldCondition | undefined {
  if (typeof conditionOrBool === 'boolean') {
    return undefined;
  } else if (test(conditionOrBool as FormFieldSingleCondition | FormFieldMultipleCondition)) {
    return conditionOrBool;
  } else if (conditionOrBool.type === 'multiple_condition') {
    for (let c of (conditionOrBool as FormFieldMultipleCondition).value) {
      const result = findCondition(c, test);
      if (result) return result;
    }
  }
}

export function evalCondition(conditionOrBool: FormFieldConditionOrBool, values: any, ctx?: ComputeContext): boolean {
  if (typeof conditionOrBool === 'boolean') {
    return conditionOrBool;
  } else if (conditionOrBool?.type === 'single_condition') {
    const singleCondition = conditionOrBool as FormFieldSingleCondition;
    if (isOutOfScope(singleCondition, ctx)) return DEFAULT_RESPONSE;
    const result = _evalSingleCondition(singleCondition, values, ctx);
    const triggerValue = values[singleCondition.field_name];
    console.debug(ctx?.logger, 'evalCondition', singleCondition, `'${JSON.stringify(triggerValue)}'`, result);
    return result;
  } else if (conditionOrBool?.type === 'multiple_condition') {
    const multipleCondition = conditionOrBool as FormFieldMultipleCondition;
    if (isOutOfScope(multipleCondition, ctx)) return DEFAULT_RESPONSE;
    let result = false;
    if (multipleCondition.value.length) {
      if (multipleCondition.operator === 'and') {
        result = true;
        for (let innerCondition of multipleCondition.value) {
          result = result && evalCondition(innerCondition, values, ctx);
          if (!result) break; // bool AND shortcut: break loop on first false condition, result is false
        }
      } else if (multipleCondition.operator === 'or') {
        result = false;
        for (let innerCondition of multipleCondition.value) {
          result = result || evalCondition(innerCondition, values, ctx);
          if (result) break; // bool OR shortcut: break loop on first true condition, result is true
        }
      } else {
        throw Error(`Unsupported multiple_condition operator ${multipleCondition.operator}`);
      }
    }
    if (multipleCondition.reverse) {
      result = !result;
    }
    console.debug(ctx?.logger, 'evalCondition', multipleCondition, result);
    return result;
  } else {
    throw Error(`Unsupported condition type ${conditionOrBool?.type}`);
  }
}

function toLength(value: any): number {
  return value && typeof value.length === 'number' ? value.length : -1;
}

function toComparableValue(value: any) {
  if (isDate(value)) {
    return parseDate(value).getTime();
  } else if (isDateTime(value)) {
    return parseDateTime(value).getTime();
  }
  return Number(value);
}

function _evalSingleCondition(singleCondition: FormFieldSingleCondition, values: any, ctx?: ComputeContext): boolean {
  const triggerValue = values[singleCondition.field_name];
  let result;
  if (singleCondition.operator === 'equal') {
    result = equal(triggerValue, singleCondition.value);
  } else if (singleCondition.operator === 'equal_ignore_case') {
    result = `${triggerValue}`.toLocaleLowerCase() === `${singleCondition.value}`.toLocaleLowerCase();
  } else if (singleCondition.operator === 'is_blank') {
    result = isBlankOrEmpty(triggerValue);
  } else if (singleCondition.operator === 'is_not_blank') {
    result = !isBlankOrEmpty(triggerValue);
  } else if (singleCondition.operator === 'regex') {
    result = testRegex(new RegExp(singleCondition.value), triggerValue);
  } else if (singleCondition.operator === 'is_valid_type') {
    result = _isValidType(singleCondition.value, triggerValue);
  } else if (singleCondition.operator === 'max_length') {
    result = toLength(triggerValue) <= singleCondition.value;
  } else if (singleCondition.operator === 'max_length_relative') {
    result = toLength(triggerValue) <= toLength(values[singleCondition.value]);
  } else if (singleCondition.operator === 'min_length') {
    result = toLength(triggerValue) >= singleCondition.value;
  } else if (singleCondition.operator === 'min_length_relative') {
    result = toLength(triggerValue) >= toLength(values[singleCondition.value]);
  } else if (singleCondition.operator === 'greater_than') {
    result = toComparableValue(triggerValue) > toComparableValue(singleCondition.value);
  } else if (singleCondition.operator === 'greater_than_relative') {
    result = toComparableValue(triggerValue) > toComparableValue(values[singleCondition.value]);
  } else if (singleCondition.operator === 'greater_than_or_equal') {
    result = toComparableValue(triggerValue) >= toComparableValue(singleCondition.value);
  } else if (singleCondition.operator === 'greater_than_or_equal_relative') {
    result = toComparableValue(triggerValue) >= toComparableValue(values[singleCondition.value]);
  } else if (singleCondition.operator === 'less_than') {
    result = toComparableValue(triggerValue) < toComparableValue(singleCondition.value);
  } else if (singleCondition.operator === 'less_than_relative') {
    result = toComparableValue(triggerValue) < toComparableValue(values[singleCondition.value]);
  } else if (singleCondition.operator === 'less_than_or_equal') {
    result = toComparableValue(triggerValue) <= toComparableValue(singleCondition.value);
  } else if (singleCondition.operator === 'less_than_or_equal_relative') {
    result = toComparableValue(triggerValue) <= toComparableValue(values[singleCondition.value]);
  } else if (singleCondition.operator === 'eval') {
    result = !!evalExpression(singleCondition.value, values, ctx);
  } else {
    throw Error(`Unsupported single_condition operator ${singleCondition.operator}`);
  }
  if (singleCondition.reverse) {
    result = !result;
  }
  return result;
}

const PHONE_NUMBER_REGEX = /^\+\d+ ?\d{3,} ?\d{6,}$/;
const ZIP_CODE_REGEX = /^\d{5}$/;
// Adapted from HTML5 input[type=email] validation (added check for top-level domain: `my@email` must fail)
// Original copied from https://emailregex.com/#:~:text=regex%20used%20in%C2%A0type%3D%E2%80%9Demail%E2%80%9D%20from%C2%A0W3C%3A
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

function _isValidType(type: string, value: any): boolean {
  if (isBlank(value)) {
    return true; // always allow blank values
  } else if (type === 'phone' || type === 'phone_number') {
    return testRegex(PHONE_NUMBER_REGEX, value);
  } else if (type === 'zipcode' || type === 'zip_code') {
    return testRegex(ZIP_CODE_REGEX, value);
  } else if (type === 'email') {
    return testRegex(EMAIL_REGEX, value);
  } else if (type === 'date') {
    return isBlank(value) || isDate(value);
  } else if (type === 'datetime') {
    return isBlank(value) || isDateTime(value);
  } else if (type === 'time') {
    return isBlank(value) || isTime(value);
  } else {
    throw Error(`Unsupported is_valid_type ${type}`);
  }
}

export function buildIsSectionHiddenFunction(
  section: FormSectionSchema,
  defaultCtx?: ComputeContext
): ConditionOrBool | undefined {
  return __buildConditionOrBoolFunction(section.hidden, defaultCtx, undefined);
}

export function buildIsHiddenFunction(
  field: FormFieldSchema,
  defaultCtx?: ComputeContext
): ConditionOrBool | undefined {
  // hidden condition may need to be re-computed if self field changed -> pass self as additional_dependencies
  return __buildConditionOrBoolFunction(field.hidden, defaultCtx, [field.field_name]);
}

export function buildIsDisabledFunction(
  field: FormFieldSchema,
  defaultCtx?: ComputeContext
): ConditionOrBool | undefined {
  // disabled condition may need to be re-computed if self field changed -> pass self as additional_dependencies
  return __buildConditionOrBoolFunction(field.disabled, defaultCtx, [field.field_name]);
}

export function buildIsRequiredFunction(
  field: FormFieldSchema,
  defaultCtx?: ComputeContext
): ConditionOrBool | undefined {
  if (field?.required) {
    if (typeof field.required === 'string') {
      return __buildConditionOrBoolFunction(true, defaultCtx, [field.field_name]);
    } else {
      return __buildConditionOrBoolFunction(field.required.condition, defaultCtx, [field.field_name]);
    }
  }
}

function __buildConditionOrBoolFunction(
  conditionOrBool: FormFieldConditionOrBool | undefined,
  defaultCtx: ComputeContext | undefined,
  additional_dependencies: string[] | undefined
): ConditionOrBool | undefined {
  if (conditionOrBool !== undefined) {
    const dependencies = getConditionDependencies(conditionOrBool);
    const compute = (values: any, ctx?: ComputeContext) => {
      return evalCondition(conditionOrBool, values, {...defaultCtx, ...ctx});
    };
    if (additional_dependencies) pushAllIfAbsent(dependencies, additional_dependencies);
    return {dependencies, compute};
  }
}

const DEFAULT_RESPONSE = false;

export const DummyConditionOrBool: ConditionOrBool = {
  dependencies: [],
  compute: (values: any) => DEFAULT_RESPONSE,
};

export type ConditionOrBoolFunType = (values: any, ctx?: ComputeContext) => boolean;

export interface ConditionOrBool {
  dependencies: string[]; // possible other fields mentioned in the condition
  compute: ConditionOrBoolFunType;
}
