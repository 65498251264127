import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Link,
  Skeleton,
  TextField,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, {useEffect, useState} from 'react';
import {Condition, FormDataType, Query, QueryFieldSchema, QueryFormSchemaResponseWrapper} from '../../model/Query';
import {Auth0User, isPowerOrg} from '../../model/Auth0User';
import {useAuth0} from '@auth0/auth0-react';
import {GridCellParams, GridColDef} from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {FormProvider, useFieldArray, useForm} from 'react-hook-form';
import Container from '@mui/material/Container';
import QueryContextProvider from '../../context/QueryContextProvider';
import {PageStickyHeader} from '../PageStickyHeader';
import PageHeadline from '../../components/PageHeadline';
import {PageTopActions} from '../PageTopActions';
import Button from '@mui/material/Button';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {FeatureName} from '../../../paths';
import {DynamicOptionsService} from '../../services/DynamicOptionsService';
import FieldsApiClient from '../../services/api/FieldsApiClient';
import VirtualFieldList from '../query/components/VirtualFieldList';
import QueryCondition from '../query/components/QueryCondition';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import Typography from '@mui/material/Typography';
import DataGridCustom from '../../components/DataGridCustom';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {useTranslation} from 'react-i18next';
import {useFormAPI} from '../../form/FormAPI';
import {FormConfig} from '../../form/FormConfig';
import {useStateSubject} from '../../form/state/FormState';
import {isQueryLastConditionValid} from '../query/utils/queryValidationMethods';
import QueryService from '../../services/QueryService';
import {ConditionOperator, LogicOperator} from '../query/ConditionOperator';
import Keywords from './components/Keywords';
import ToggleSwitch from '../../components/shared/ToggleSwitch';
import {isEmpty, pick, cloneDeep} from 'lodash';
import {PaginatedResponse} from '../../services/model/PaginatedResponse';
import {buildTableColumns} from '../query/QueryBuilder';
import QueryFieldsSortable from '../query/components/QueryFieldsSortable';
import {onRejectSubmit} from '../../form/errorHandler';
import {Form} from '../../form/Form';
import {FormFieldType, FormSchema} from '../../form/logic/FormSchema';
import {FormHelper} from '../../form/FormHelper';
import getSchemaConf from './transcriptionSchema';
import CallTranscriptionPopUpView from './CallTranscriptionPopUpView';
import {
  Transcription,
  RecordingAnalyticsFieldDetailsMetadata,
  dynamicDisplayFieldInfo,
  TranscriptionsQuery,
} from '../../model/CallTranscriptions';
import {TestAttributes} from '../../TestAttributes';
import {paletteModeDark} from '../../context/LightDarkThemeProvider';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {AxiosResponse} from 'axios';
import {formatDateTime} from '../../utils/DateUtils';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import {NextgenFormFieldSchema, NextgenFormSchema} from '../../form/api/middleware/NextgenFormSchema';

function CallTranscriptionsPage() {
  const {setLoading} = useLoading();

  const [featureName] = useState<FeatureName>(FeatureName.RECORDING_ANALYTICS);
  const [allFields, setAllFields] = useState<Array<QueryFieldSchema> | null>(null);
  const [conditionFields, setConditionFields] = useState<Array<QueryFieldSchema>>([]);
  const [popUpFieldsToDisplay, setPopUpFieldsToDisplay] = useState<Array<dynamicDisplayFieldInfo>>([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [config, setConfig] = useState<FormConfig>();
  const [searchFieldQuery, setSearchFieldQuery] = useState<string>('');
  const [filteredFields, setFilteredFields] = useState<Array<QueryFieldSchema>>([]);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [queryResult, setQueryResult] = useState<Array<Transcription>>([]);
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [pageToRequest, setPageToRequest] = useState<number>(1);
  const [temporaryPaginationTable, setTemporaryPaginationTable] = useState<string>('');
  const [schema, setFormSchema] = useState<FormSchema | false>(false);
  const [helper, setFormHelper] = useState<FormHelper | false>(false);
  const [transcription, setTranscription] = useState<Transcription>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [userKeywords, setUserKeywords] = useState<Array<string>>([]);
  const [leadKeywords, setLeadKeywords] = useState<Array<string>>([]);
  const [displayConfidenceScores, setDisplayConfidenceScores] = useState<boolean>(false);
  const [includeDirectInboundCalls, setIncludeDirectInboundCalls] = useState<boolean>(false);
  const [defaultQueryFields, setDefaultQueryFields] = useState<Array<QueryFieldSchema>>();
  const [defaultValues, setDefaultValues] = useState<Query>();

  const [pageCount, setPageCount] = useState<number>(0);

  const formAPI = useFormAPI();
  const pageSize: number = 50;
  const default_columns: GridColDef[] = [
    {field: 'transcription_id', headerName: 'transcription id', width: 150},
    {field: 'call_start_timestamp', headerName: 'call start time', width: 150},
  ];
  const [recordingAnalyticsColumns, setRecordingAnalyticsColumns] = useState<GridColDef[]>(default_columns);
  const [listHeight, setListHeight] = useState<number>(400);
  const [expanded, setExpanded] = useState<boolean>(true);
  const methods = useForm<Query>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {reset, control, getValues, formState} = methods;
  const {isValid} = formState;
  const {fields, append, remove, insert, swap} = useFieldArray({
    control,
    name: 'query_conditions',
  });
  const lastCondition = fields.length - 2;

  const HIDDEN_INNER_FIELDS = [
    'transcription_id',
    'relative_call_recording_file_path',
    'lead_clips_transcriptions',
    'user_clips_transcriptions',
  ];
  const USER_KEYWORDS_DYNAMIC_FIELDS: dynamicDisplayFieldInfo[] = [
    {field_name: 'user_number_of_matches', field_label: 'user number of matches'},
    {field_name: 'user_keywords_matched', field_label: 'user keywords matched'},
  ];
  const LEAD_KEYWORDS_DYNAMIC_FIELDS: dynamicDisplayFieldInfo[] = [
    {field_name: 'lead_number_of_matches', field_label: 'lead number of matches'},
    {field_name: 'lead_keywords_matched', field_label: 'lead keywords matched'},
  ];
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.RECORDING_ANALYTICS_QUERIES);
  const auth0 = useAuth0<Auth0User>();
  const {user} = auth0;
  const {useAxiosBFF} = useAxiosContext();
  const {t} = useTranslation();
  const fieldNameRequiredCondition = 'leads_created_at';
  const $state = useStateSubject();
  const power = isPowerOrg(user);
  const queryConditions = getValues('query_conditions');
  const theme = useTheme();

  const order = 'order';
  const orderBy = 'order_by';

  const [{error: getMetadataForQueriesError}, getMetadataForQueries] = useAxiosBFF<QueryFormSchemaResponseWrapper>(
    {
      url: `/${FeatureName.QUERIES}`,
      method: 'GET',
      params: {metadata_from: featureName},
    },
    {manual: true}
  );

  const [{response: getSchemaResponse, error: getSchemaError}, getSchema] = useAxiosBFF<NextgenFormSchema>(
    {
      url: `/featurefields`,
      method: 'GET',
      params: {feature_name: FeatureName.RECORDING_ANALYTICS},
    },
    {manual: true}
  );

  const [{error: hasRunTranscriptionError, loading: isRunTranscriptionLoading}, runTranscriptionsQuery] = useAxiosBFF<
    PaginatedResponse<Transcription>
  >(
    {
      url: `transcription-results`,
      method: 'POST',
    },
    {manual: true}
  );

  const action_column = {
    index: 0,
    field: 'actions',
    headerName: 'actions',
    width: 150,
    hide: false,
    renderCell: (params: GridCellParams) => {
      return (
        <IconButton
          id="open-transcription-pop-up-view"
          onClick={() => {
            setTranscription(params.row);
          }}
        >
          <VisibilityIcon />
        </IconButton>
      );
    },
  };

  const get90DaysAgo = () => {
    let defaultOffset: number = 24 * 60 * 60 * 1000 * 90;
    let todayTruncatedTimestamp: Date = new Date(new Date().setHours(0, 0, 0, 0));
    return todayTruncatedTimestamp.setTime(todayTruncatedTimestamp.getTime() - defaultOffset);
  };

  function initializeDefaultValues(defaultQueryFields: QueryFieldSchema[], allFields: Array<QueryFieldSchema> | null) {
    const defaultConditionFieldName = 'leads_created_at';
    const defaultConditionField = allFields?.find(
      (field: QueryFieldSchema) => field.field_name === defaultConditionFieldName
    );
    const defaultDisplayFieldNames = defaultQueryFields.map((col: QueryFieldSchema) => col.field_name);

    const defaultValues: Query = {
      query_fields: defaultDisplayFieldNames,
      query_conditions: [],
    };

    if (defaultConditionField) {
      defaultValues.query_conditions.push({
        field_id: defaultConditionField.field_id,
        field_name: defaultConditionField.field_name,
        field_type: FormFieldType.TIMESTAMP,
        data_type: FormDataType.TIMESTAMP,
        condition_type: ConditionOperator.DATE_FROM,
        sequence_operator: LogicOperator.AND,
        values: [
          {
            value: formatDateTime(get90DaysAgo()),
          },
        ],
      });
    }
    setDefaultValues(defaultValues);
  }

  useEffect(() => {
    setLoading(isRunTranscriptionLoading, 'CallTranscriptionsPage');
  }, [isRunTranscriptionLoading]);

  useEffect(() => {
    setConfig({$state});
  }, [$state]);
  useEffect(() => {
    if (fields.length > 5) {
      const height = (fields.length - 5) * 100;
      setListHeight(400 + height);
    }
  }, [fields]);
  useEffect(() => {
    getMetadataForQueries().then((response: AxiosResponse<QueryFormSchemaResponseWrapper>) => {
      if (!response?.data?.query_condition_fields) return;

      const data: QueryFormSchemaResponseWrapper = response.data;
      new DynamicOptionsService(FieldsApiClient.with(auth0))
        .resolveDynamicOptions(data.query_condition_fields)
        .then((query_condition_fields) => ({...data, query_condition_fields}))
        .then((queryMetadata: QueryFormSchemaResponseWrapper) => {
          const searchableFields = queryMetadata.query_display_fields;

          const conditionFields = queryMetadata.query_condition_fields;

          searchableFields?.forEach(
            (f) =>
              (f.label = t(
                `feature.${featureName}.field.${f.field_name}.query_display_field`,
                t(`feature.${featureName}.field.${f.field_name}`, f.label)
              ))
          );
          conditionFields?.forEach(
            (f) =>
              (f.label = t(
                `feature.${featureName}.field.${f.field_name}.query_condition_field`,
                t(`feature.${featureName}.field.${f.field_name}`, f.label)
              ))
          );

          setAllFields(searchableFields);
          setFilteredFields(conditionFields);
          setConditionFields(conditionFields);
          setDefaultQueryFields(searchableFields?.filter((column) => column?.is_default));

          getSchema();
        });
    });
  }, [auth0]);

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  useEffect(() => {
    const isConditionValid: boolean = isQueryLastConditionValid(formState, queryConditions);
    const isFormValid = isConditionValid ? false : !isValid;
    setIsFormValid(isFormValid);
  }, [queryConditions, formState, isValid]);

  useEffect(() => {
    if (conditionFields) {
      const result = conditionFields.filter((filter: QueryFieldSchema) => {
        const searchTextInLowerCase = searchFieldQuery.toLowerCase();
        return (
          filter.label?.toLowerCase().includes(searchTextInLowerCase) ||
          filter.label_search?.toLowerCase().includes(searchTextInLowerCase)
        );
      });
      setFilteredFields(result);
    }
  }, [searchFieldQuery]);

  useEffect(() => {
    if (allFields) {
      setFormSchema(getSchemaConf(allFields, [USER_KEYWORDS_DYNAMIC_FIELDS[0], LEAD_KEYWORDS_DYNAMIC_FIELDS[0]]));
    }
  }, [allFields]);

  useEffect(() => {
    if (schema) {
      const _helper = new FormHelper({schema, initialValues: null, config});
      setFormHelper(_helper);
      return () => _helper.destroy();
    }
  }, [schema]);

  useEffect(() => {
    if (count) {
      setPageCount(count / pageSize - 1);
    }
  }, [count]);

  useEffect(() => {
    if (page === pageCount && count < totalResults) {
      setPageToRequest(pageToRequest + 1);
    }
  }, [page]);

  useEffect(() => {
    if (defaultQueryFields && allFields) {
      initializeDefaultValues(defaultQueryFields, allFields);
    }
  }, [defaultQueryFields, allFields]);

  useEffect(() => {
    const schemaFields: Array<NextgenFormFieldSchema> | undefined = getSchemaResponse?.data?.sections[0]?.fields;
    if (schemaFields && allFields) {
      const detailsFieldIDs: Array<string> = schemaFields
        .filter((field: NextgenFormFieldSchema) => {
          const fieldMetadata = field?.metadata as RecordingAnalyticsFieldDetailsMetadata;
          return fieldMetadata?.display_in_transcription_details;
        })
        .map((field: NextgenFormFieldSchema) => field.field_id);

      const fieldsToDispalyInDetails = allFields
        .filter((field: QueryFieldSchema) => detailsFieldIDs.includes(field.field_id))
        .map((field: QueryFieldSchema): dynamicDisplayFieldInfo => {
          return {field_label: field.label ? field.label : field.field_name, field_name: field.field_name};
        });

      setPopUpFieldsToDisplay(fieldsToDispalyInDetails);
    }
  }, [getSchemaResponse, allFields]);

  useEffect(() => {
    if (getMetadataForQueriesError) {
      NotificationService.getInstance().sendNotification(
        t('recording-analytics.dynamic-fields-error'),
        NotificationType.ERROR
      );
    }
  }, [getMetadataForQueriesError]);

  useEffect(() => {
    if (getSchemaError) {
      NotificationService.getInstance().sendNotification(
        t('recording-analytics.popup-fields-error'),
        NotificationType.ERROR
      );
    }
  }, [getSchemaError]);

  useEffect(() => {
    if (hasRunTranscriptionError) {
      NotificationService.getInstance().sendNotification(
        t('recording-analytics.transcriptions-query-error'),
        NotificationType.ERROR
      );
    }
  }, [hasRunTranscriptionError]);

  useEffect(() => {
    if (pageToRequest > 1) {
      handleRunCallTranscription(true);
    }
  }, [pageToRequest]);

  useEffect(() => {
    if (transcription) {
      setIsDialogOpen(true);
    }
  }, [transcription]);

  function setDisplayConfidenceScoresHandler() {
    setDisplayConfidenceScores(!displayConfidenceScores);
  }

  function setIncludeDirectInboundCallsHandler() {
    setIncludeDirectInboundCalls(!includeDirectInboundCalls);
  }

  function initializePaginationState(response: any) {
    setQueryResult(response?.results);
    setTotalResults(response?.count_total);
    setCount(response?.count);
    setTemporaryPaginationTable(response?.true_time);
    setPageToRequest(1);
    setPage(0);
  }

  function getQueryData(valuesFromReactHook: Query) {
    const valuesFromReactHookClone = cloneDeep(valuesFromReactHook);
    const formStateValues = $state.getValue().values;
    const queryData: TranscriptionsQuery = {
      ...pick(formStateValues, [order, orderBy]),
      ...pick(valuesFromReactHookClone, ['query_conditions', 'query_fields']),
      ...pick(valuesFromReactHookClone, ['user_keywords']),
      ...pick(valuesFromReactHookClone, ['lead_keywords']),
      obtain_direct_inbound_calls: includeDirectInboundCalls,
    };
    queryData.query_conditions[0].sequence_operator = LogicOperator.AND;

    queryData.query_fields?.splice(1, 0, ...HIDDEN_INNER_FIELDS);

    return queryData;
  }

  function getTableColumns(
    queryData: Query,
    inputUserKeywords: Array<string>,
    inputLeadKeywords: Array<string>
  ): GridColDef[] {
    const dynamicFieldsToDisplay: dynamicDisplayFieldInfo[] = [];

    if (inputLeadKeywords && inputLeadKeywords.length > 0) {
      dynamicFieldsToDisplay.splice(0, 0, ...LEAD_KEYWORDS_DYNAMIC_FIELDS);
    }
    if (inputUserKeywords && inputUserKeywords.length > 0) {
      dynamicFieldsToDisplay.splice(0, 0, ...USER_KEYWORDS_DYNAMIC_FIELDS);
    }
    let formattedColumns = buildTableColumns(queryData, power, allFields, featureName)
      .filter((column) => !HIDDEN_INNER_FIELDS.includes(column.field))
      .map((column, index) => {
        let tableColumnData = {...column};
        if (column.field === 'crm_lead_id') {
          tableColumnData = {
            ...tableColumnData,
            renderCell: (params: GridCellParams) => (
              <Link
                href={`/leads/${params.row.crm_lead_id}`}
                target="_blank"
                sx={{
                  color:
                    theme.palette.mode === paletteModeDark ? theme.palette.secondary.main : theme.palette.primary.dark,
                }}
              >
                {params.row.crm_lead_id}
              </Link>
            ),
          };
        }
        if (column.field.length >= 28) {
          tableColumnData = {...tableColumnData, width: 230};
        }
        return {...tableColumnData, index: 0};
      });

    formattedColumns.push(action_column);
    formattedColumns.splice(
      0,
      0,
      ...dynamicFieldsToDisplay.map((field: dynamicDisplayFieldInfo) => {
        return {
          index: 0,
          field: field.field_name,
          headerName: field.field_label,
          width: 230,
          hide: false,
        };
      })
    );

    return formattedColumns;
  }

  function getParams(page: number, temporaryPaginationTable?: string) {
    const params = {
      action: 'run',
      page,
    };
    if (temporaryPaginationTable) {
      params['temporaryPaginationTable'] = temporaryPaginationTable;
    }
    return params;
  }

  function hasValidQueryOrderValues(order: string, orderBy: string): boolean {
    return !isEmpty(order) && !isEmpty(orderBy);
  }

  function handleRunCallTranscription(pagination: boolean): void {
    const valuesFromReactHook = getValues();
    const queryData = getQueryData(valuesFromReactHook);
    const validQueryOrderValues = hasValidQueryOrderValues(queryData[order], queryData[orderBy]);

    if (validQueryOrderValues) {
      const pageToRequestParam = getParams(pagination ? pageToRequest : 1, temporaryPaginationTable);

      runTranscriptionsQuery({data: queryData, params: pageToRequestParam}).then(
        (response: AxiosResponse<PaginatedResponse<Transcription>>) => {
          if (response.data.results) {
            const inputKeywords = pick(valuesFromReactHook, ['user_keywords', 'lead_keywords']);
            setUserKeywords(inputKeywords['user_keywords'] ? inputKeywords['user_keywords'] : []);
            setLeadKeywords(inputKeywords['lead_keywords'] ? inputKeywords['lead_keywords'] : []);

            const columns = getTableColumns(queryData, inputKeywords['user_keywords'], inputKeywords['lead_keywords']);
            setRecordingAnalyticsColumns(columns);

            if (!pagination) {
              initializePaginationState(response.data);
            } else {
              setQueryResult([...queryResult, ...(response.data?.results || [])]);
              setCount(count + response.data?.count);
              setTemporaryPaginationTable(response.data?.table_id ? response.data?.table_id : '');
            }
          }
        }
      );
    } else {
      NotificationService.getInstance().sendNotification(t('shared.missing-field'), NotificationType.ERROR);
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW]}>
        <Container maxWidth={false}>
          <FormProvider {...methods}>
            {allFields && (
              <QueryContextProvider defaultValue={conditionFields}>
                <Grid container spacing={3}>
                  <PageStickyHeader>
                    <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
                      <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                        <PageHeadline>{t('recording-analytics.headline')}</PageHeadline>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <PageTopActions>
                          <div>
                            <Button
                              color="secondary"
                              id={'search-btn'}
                              disabled={isFormValid || !(fields.length > 0)}
                              onClick={() => handleRunCallTranscription(false)}
                              fullWidth
                            >
                              {t('recording-analytics.actions.search')}
                            </Button>
                          </div>
                        </PageTopActions>
                      </Grid>
                    </Grid>
                  </PageStickyHeader>

                  <Grid item xs={12} sx={{minWidth: '100%', maxWidth: 'none'}}>
                    {helper ? (
                      <Grid item xs={12}>
                        <Form helper={helper} api={formAPI} onSubmit={() => {}} onRejectSubmit={onRejectSubmit} />
                      </Grid>
                    ) : (
                      <Skeleton variant="rectangular" animation="wave" height={150} />
                    )}
                    <Grid item xs={12}>
                      <Divider
                        sx={{marginBottom: (theme) => theme.spacing(2), marginTop: (theme) => theme.spacing(2)}}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} spacing={3}>
                    <Grid item container xs={12} spacing={3}>
                      <Grid item container xs={12} md={9} spacing={3}>
                        <Grid item xs={12}>
                          <Keywords
                            controllerName={'user_keywords'}
                            tooltip={t('recording-analytics.keywords.user-helper-text')}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Keywords
                            controllerName={'lead_keywords'}
                            tooltip={t('recording-analytics.keywords.lead-helper-text')}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={0.5}>
                        <Divider orientation="vertical" sx={{marginRight: (theme) => theme.spacing(3)}} />
                      </Grid>

                      <Grid container item xs={12} md={2.5} spacing={3}>
                        <Grid item xs={12}>
                          <ToggleSwitch
                            checkedStatus={displayConfidenceScores}
                            onCheckedBehavior={setDisplayConfidenceScoresHandler}
                            label={t('recording-analytics.toggle-display-confidence-label')}
                            tooltip={t('recording-analytics.toggle-display-confidence-tooltip')}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ToggleSwitch
                            checkedStatus={includeDirectInboundCalls}
                            onCheckedBehavior={setIncludeDirectInboundCallsHandler}
                            label={t('recording-analytics.include-direct-inbound-calls-label')}
                            tooltip={t('recording-analytics.include-direct-inbound-calls-tooltip')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: (theme) => theme.spacing(4)}}>
                      <Typography variant={'h4'}>
                        {t('query.table.result')} {totalResults}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <QueryFieldsSortable fields={allFields} />
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion expanded={expanded}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          onClick={() => {
                            setExpanded(!expanded);
                          }}
                        >
                          <Typography variant="h4">{t('query.conditions')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={3}>
                            <Grid container item xs={12} lg={3} spacing={3} alignContent={'flex-start'}>
                              <Grid item xs={12}>
                                <InputLabel htmlFor="query-search-field-input">{t('query.filter')}</InputLabel>
                                <TextField
                                  fullWidth
                                  id="query-search-field-input"
                                  placeholder={t('query.filter')}
                                  onChange={(event) => setSearchFieldQuery(event.target.value)}
                                  value={searchFieldQuery}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                {filteredFields.length > 0 && (
                                  <VirtualFieldList
                                    height={listHeight}
                                    fields={filteredFields}
                                    handleOnClick={(field: QueryFieldSchema) => {
                                      QueryService.getInstance().handleOnFieldClick(
                                        field,
                                        fields,
                                        fieldNameRequiredCondition,
                                        insert,
                                        append
                                      );
                                      setListHeight(listHeight + (fields.length > 5 ? 100 : 0));
                                    }}
                                    disabled={
                                      formState.isDirty && isFormValid && getValues('query_conditions')?.length !== 0
                                    }
                                  />
                                )}
                              </Grid>
                            </Grid>

                            <Grid item xs={12} lg={9}>
                              {fields.map((conditionField: Condition, index: number) => {
                                const isRemovableConditionField =
                                  conditionField?.field_name !== fieldNameRequiredCondition ||
                                  (conditionField?.field_name === fieldNameRequiredCondition &&
                                    index !== fields.length - 1);

                                return (
                                  <QueryCondition
                                    key={'query-conditions-' + conditionField.id}
                                    conditionField={conditionField}
                                    conditionIndex={index}
                                    fieldNameRequiredCondition={fieldNameRequiredCondition}
                                  >
                                    <Grid item>
                                      {isRemovableConditionField && (
                                        <IconButton
                                          {...{[TestAttributes.BUTTON_NAME]: `remove-condition-${index}-btn`}}
                                          id={`remove-condition-${index}-btn`}
                                          color="primary"
                                          aria-label="remove condition"
                                          component="button"
                                          onClick={() => {
                                            remove(index);
                                            setListHeight(listHeight - (fields.length > 5 ? 100 : 0));
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      )}
                                    </Grid>

                                    <Grid item container flexWrap={'nowrap'}>
                                      {index !== 0 && isRemovableConditionField && (
                                        <IconButton
                                          {...{[TestAttributes.BUTTON_NAME]: `swap-up-condition-${index}-btn`}}
                                          id={`swap-up-condition-${index}-btn`}
                                          color={'secondary'}
                                          aria-label="swap up condition"
                                          component="button"
                                          onClick={() => swap(index, index - 1)}
                                          size="large"
                                        >
                                          <ArrowUpwardRoundedIcon />
                                        </IconButton>
                                      )}
                                      {index !== lastCondition && isRemovableConditionField && (
                                        <IconButton
                                          {...{[TestAttributes.BUTTON_NAME]: `swap-down-condition-${index}-btn`}}
                                          id={`swap-down-condition-${index}-btn`}
                                          color={'secondary'}
                                          aria-label="swap down condition"
                                          component="button"
                                          onClick={() => swap(index, index + 1)}
                                          size="large"
                                        >
                                          <ArrowDownwardRoundedIcon />
                                        </IconButton>
                                      )}
                                    </Grid>
                                  </QueryCondition>
                                );
                              })}
                              {fields.length === 0 && (
                                <Box display={'flex'} justifyContent={'center'}>
                                  <Typography variant={'h4'}>{t('query.empty-query-message')}</Typography>
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>

                  {totalResults ? (
                    <>
                      <Grid item xs={12} sx={{marginTop: (theme) => theme.spacing(4)}}>
                        <Typography variant={'h4'}>
                          {t('query.table.result')} {totalResults}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{height: '500px', marginBottom: '70px'}}
                        {...{[TestAttributes.TABLE_NAME]: 'table-results'}}
                      >
                        <DataGridCustom
                          tableColumns={recordingAnalyticsColumns}
                          rows={
                            queryResult?.map((item: any, index: number) => {
                              return {
                                actions: item.lead_id,
                                ...item,
                                id: index,
                              };
                            }) || []
                          }
                          userPermissions={userPermissions}
                          page={page}
                          pageSize={pageSize}
                          rowsPerPageOptions={[pageSize]}
                          onPageChange={(newPage: any) => setPage(newPage)}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} sx={{marginTop: (theme) => theme.spacing(4)}}>
                      <Typography variant={'h4'}>
                        {t('query.table.result')} {totalResults}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </QueryContextProvider>
            )}
          </FormProvider>
          <ConfirmationDialog
            message={t('shared.clear-form-modal-content')}
            headline={t('shared.clear-form-modal-headline')}
            isDialogOpen={isConfirmationDialogOpen}
            handleClose={() => setIsConfirmationDialogOpen(false)}
          >
            <Button
              onClick={() => {
                formAPI.reset();
                setIsConfirmationDialogOpen(false);
              }}
              id="confirmation-dialog-action-btn"
            >
              {t('shared.accept')}
            </Button>
          </ConfirmationDialog>
        </Container>
        {transcription && (
          <CallTranscriptionPopUpView
            isOpen={isDialogOpen}
            handleClose={() => setIsDialogOpen(false)}
            transcription={transcription}
            userKeywords={userKeywords}
            leadKeywords={leadKeywords}
            displayConfidenceScores={displayConfidenceScores}
            fieldsToDisplay={popUpFieldsToDisplay}
            setDisplayConfidenceScoresHandler={setDisplayConfidenceScoresHandler}
          />
        )}
      </AccessControl>
    </Box>
  );
}

export default CallTranscriptionsPage;
