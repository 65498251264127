import React, {PropsWithChildren} from 'react';
import {FormSchema} from './logic/FormSchema';
import {defaultFormConfig, FormConfig} from './FormConfig';
import {FormStateHandler} from './state/FormStateHandler';
import {FormSchemaHelper} from './logic/FormSchemaHelper';
import {BehaviorSubject, Subscription} from 'rxjs';
import {FormState} from './state/FormState';

export interface FormHelperProps {
  schema: FormSchema;
  initialValues?: any;
  config?: FormConfig;
  formId?: string | undefined;
}

export class FormHelper {
  readonly formId: string;
  readonly stateHandler: FormStateHandler;
  readonly schemaHelper: FormSchemaHelper;
  readonly schema: FormSchema;
  config: FormConfig;

  private $stateSubscription?: Subscription;

  constructor(props: FormHelperProps) {
    const {schema, initialValues, config, formId} = props;
    this.schema = schema;
    this.config = config || defaultFormConfig();
    this.formId = schema.name ?? formId ?? `unknown`;
    this.schemaHelper = new FormSchemaHelper(schema, this.config.decorators);
    this.stateHandler = new FormStateHandler(this);
    this.stateHandler.withInitialValues(initialValues);
    this.stateHandler.next();
    this.initStateSubject(this.config.$state);
    console.debug(`[FormHelper ${this.formId}] Created`, this);
    // expose to global
    window['__FORM_HELPER__'] = this;
  }

  private initStateSubject($state?: BehaviorSubject<FormState>) {
    if ($state) {
      if (this.$stateSubscription) {
        this.$stateSubscription.unsubscribe();
      }
      this.$stateSubscription = this.stateHandler.$state.subscribe((next) => next.initialized && $state.next(next));
    }
  }

  destroy() {
    if (this.$stateSubscription) {
      this.$stateSubscription.unsubscribe();
    }
  }
}

const FormHelperContext = React.createContext<FormHelper | false>(false);

export interface FormHelperProviderProps {
  helper: FormHelper;
}

export function FormHelperProvider({children, helper}: PropsWithChildren<FormHelperProviderProps>) {
  return <FormHelperContext.Provider value={helper} children={children} />;
}

export function useFormHelper(): FormHelper {
  const helper = React.useContext(FormHelperContext);
  if (!helper) {
    throw new Error(`Illegal access. FormHelper not initialized`);
  }
  return helper;
}
