import {Box, Grid} from '@mui/material';
import Container from '@mui/material/Container';
import {useCallback, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import PageHeadline from '../../components/PageHeadline';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName} from '../../../paths';
import {PageStickyHeader} from '../PageStickyHeader';
import {PageTopActions} from '../PageTopActions';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {FormBuilder} from '../../form/FormBuilder';
import {useFormAPI} from '../../form/FormAPI';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import {Operation, OperationListItem} from '../../model/Operation';
import {onRejectSubmit} from '../../form/errorHandler';
import {OnSubmitHandler} from '../../form/model';
import {FormState} from '../../form/state/FormState';
import {DefaultResponse} from '../../services/model/DefaultResponse';
import {TestAttributes} from '../../TestAttributes';
import {Task} from '../../model/Task';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {useNavigate} from 'react-router-dom';
import {difference} from '../../form/utils';
import {Skeleton} from '@mui/lab';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

function OperationPage() {
  const {id} = useParams();
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const {useAxiosUserAPI} = useAxiosContext();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [isDeactivatedModalOpen, setIsDeactivatedModalOpen] = useState<boolean>(false);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.OPERATIONS);
  const [operation, setOperation] = useState<Operation | null>(null);
  const formAPI = useFormAPI();

  const navigate = useNavigate();

  const [, getEntityList] = useAxiosUserAPI<ResponseListWrapper<OperationListItem>>(
    {url: `/${FeatureName.OPERATIONS}`, params: {formatted: true}},
    {
      manual: false,
    }
  );
  const [{data: entityData, loading: isGetEntityLoading, error: getEntityError}, getEntityData] =
    useAxiosUserAPI<Operation>(`/${FeatureName.OPERATIONS}/${id}`, {manual: true});

  const [{error: patchEntityError, loading: isPatchEntityLoading, response: patchEntityResponse}, patchEntityData] =
    useAxiosUserAPI<Operation>(
      {url: `/${FeatureName.OPERATIONS}/${id}`, method: 'PATCH', params: {formatted: true}},
      {manual: true}
    );

  const [{error: postEntityError, loading: isPostEntityLoading, response: postEntityResponse}, postEntityData] =
    useAxiosUserAPI<Task>({url: `/${FeatureName.OPERATIONS}`, method: 'POST'}, {manual: true});

  const [
    {data: deleteTeamData, loading: isDeleteEntityLoading, response: deleteEntityResponse, error: deleteEntityError},
    deleteEntity,
  ] = useAxiosUserAPI<DefaultResponse>({url: `/${FeatureName.OPERATIONS}/${id}`, method: 'delete'}, {manual: true});

  const onSubmit: OnSubmitHandler = useCallback(
    (formData: Partial<Operation>, state: FormState) => {
      if (id) {
        formData = difference(formData, operation);
        patchEntityData({
          data: formData,
        }).then(() => {
          getEntityData();
        });
      } else {
        postEntityData({
          data: formData,
        }).then(() => {});
      }
    },
    [operation]
  );

  useEffect(() => {
    const loading = isGetEntityLoading || isDeleteEntityLoading || isPostEntityLoading || isPatchEntityLoading;
    setLoading(loading, 'OperationPage');
  }, [isGetEntityLoading, isDeleteEntityLoading, isPostEntityLoading, isPatchEntityLoading]);

  useEffect(() => {
    const entityError = deleteEntityError || patchEntityError || postEntityError;
    if (entityError) {
      NotificationService.getInstance().sendNotification(entityError?.response?.data?.message, NotificationType.ERROR);
    }
  }, [deleteEntityError, patchEntityError, postEntityError]);

  useEffect(() => {
    if (deleteEntityResponse?.status === 200) {
      getEntityList().then(() => {
        NotificationService.getInstance().sendNotification(
          deleteEntityResponse?.data?.message,
          NotificationType.SUCCESS
        );
        navigate('/' + FeatureName.OPERATIONS);
      });
    }
  }, [deleteTeamData]);

  useEffect(() => {
    if (id) {
      getEntityData();
    }
  }, [id]);

  useEffect(() => {
    if (entityData) {
      setOperation(entityData);
    } else if (getEntityError) {
      NotificationService.getInstance().sendNotification(getEntityError.message, NotificationType.ERROR);
    }
  }, [entityData]);

  useEffect(() => {
    if (postEntityResponse?.status === 201) {
      getEntityList().then(() => {
        NotificationService.getInstance().sendNotification(
          postEntityResponse.data?.['message'],
          NotificationType.SUCCESS
        );
        navigate(`/${FeatureName.OPERATIONS}/${postEntityResponse.data['id']}`);
      });
    }
  }, [postEntityResponse]);

  useEffect(() => {
    if (patchEntityResponse?.status === 200) {
      getEntityList().then(() => {
        NotificationService.getInstance().sendNotification(
          patchEntityResponse.data?.['message'],
          NotificationType.SUCCESS
        );
      });
    }
  }, [patchEntityResponse]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('operations.headline')}</PageHeadline>
              </Grid>
              <Grid item xs={12} md={6}>
                <PageTopActions>
                  {id && (
                    <AccessControl
                      userPermissions={userPermissions}
                      allowedPermissions={[UserPermissions.DEACTIVATE_FEATURE_ITEM]}
                    >
                      <ConfirmationDialog
                        message={t('operations.deactivate-message')}
                        headline={t('operations.deactivate')}
                        isDialogOpen={isDeactivatedModalOpen}
                        handleClose={() => setIsDeactivatedModalOpen(false)}
                      >
                        <Button
                          onClick={() => {
                            deleteEntity();
                            setIsDeactivatedModalOpen(false);
                          }}
                          {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
                          id="confirmation-dialog-action-btn"
                        >
                          {t('operations.deactivate')}
                        </Button>
                      </ConfirmationDialog>

                      <Button
                        {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                        id={'confirmation-dialog-btn'}
                        color="secondary"
                        onClick={() => setIsDeactivatedModalOpen(true)}
                      >
                        {t('operations.deactivate')}
                      </Button>
                    </AccessControl>
                  )}

                  <Button
                    {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                    id={'confirmation-dialog-btn'}
                    color="secondary"
                    onClick={() => setIsConfirmationDialogOpen(true)}
                  >
                    {t('shared.clear')}
                  </Button>

                  <AccessControl
                    userPermissions={userPermissions}
                    allowedPermissions={[id ? UserPermissions.MODIFY : UserPermissions.CREATE]}
                  >
                    <Button
                      {...{[TestAttributes.BUTTON_NAME]: 'save-btn'}}
                      id={'save-btn'}
                      onClick={() => {
                        formAPI.submit();
                      }}
                    >
                      {id ? t('shared.update') : t('shared.save')}
                    </Button>
                  </AccessControl>
                </PageTopActions>
              </Grid>
            </Grid>
          </PageStickyHeader>
          <Grid item xs={12}>
            {isGetEntityLoading ? (
              <Skeleton variant="rectangular" height={500} />
            ) : (
              <FormBuilder
                onRejectSubmit={onRejectSubmit}
                formId={FeatureName.OPERATIONS}
                api={formAPI}
                initialValues={operation}
                onSubmit={onSubmit}
              />
            )}
          </Grid>
        </Grid>
        <ConfirmationDialog
          message={t('shared.clear-form-modal-content')}
          headline={t('shared.clear-form-modal-headline')}
          isDialogOpen={isConfirmationDialogOpen}
          handleClose={() => setIsConfirmationDialogOpen(false)}
        >
          <Button
            onClick={() => {
              formAPI.reset();
              setIsConfirmationDialogOpen(false);
            }}
            {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
            id="confirmation-dialog-action-btn"
          >
            {t('shared.accept')}
          </Button>
        </ConfirmationDialog>
      </Container>
    </Box>
  );
}

export default OperationPage;
