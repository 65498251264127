import React, {useCallback, useEffect, useState} from 'react';
import PlacesAutocomplete, {geocodeByAddress, Suggestion} from 'react-places-autocomplete';
import {requireNotUndefined} from '../utils';
import {SpartanTextField} from './SpartanTextField';
import {propsWithTestAttributes, SpartanFieldProps} from './model';
import {styled} from '@mui/material/styles';
import {Button, CircularProgress, MenuItem} from '@mui/material';
import {Link} from 'react-router-dom';
import {useFormHelper} from '../FormHelper';
import {LinkedFieldSchema} from '../logic/FormSchema';
import {TestAttributes} from '../../TestAttributes';
import {useTranslation} from 'react-i18next';

export const SuggestionList = styled('div')(({theme}) => ({
  zIndex: 2,
  borderRadius: '4px',
  position: 'absolute',
  backgroundColor: theme.palette.background.default,
  border: '1px solid #dadada',
}));

export function SpartanAutoAddressField(_props: SpartanFieldProps) {
  const {t} = useTranslation();
  const props = propsWithTestAttributes(_props);
  const {value, name, onChange} = props;
  const {schemaHelper} = useFormHelper();
  const field = requireNotUndefined(schemaHelper.getFieldSchema(name), `Missing field ${name}`);
  const [address, setAddress] = useState<string>(value);
  const [linkedFields, setLinkedFields] = useState<Array<LinkedFieldSchema>>();
  useEffect(() => {
    if (field.linked_fields) {
      setLinkedFields([...field.linked_fields]);
    }
  }, [field.linked_fields]);

  useEffect(() => {
    if (address || value) {
      onChange({target: {name, value: address.toLowerCase()}});
    }
  }, [address]);

  const handleSelect = useCallback(
    (address: string, placeID: string) => {
      if (linkedFields) {
        setAddress(address);
        geocodeByAddress(address)
          .then((results: google.maps.GeocoderResult[]) => {
            const addressComponents: google.maps.GeocoderAddressComponent[] = results[0].address_components;
            console.debug(`Auto address results (input/output):`, address, addressComponents);
            linkedFields.forEach((linkedField) => {
              const addressComponentName = addressComponents.find((addressComponent) =>
                addressComponent.types?.some((type) => type === linkedField?.link_key)
              )?.long_name;
              onChange({target: {name: linkedField.field_name, value: addressComponentName?.toLowerCase() ?? ''}});
            });
            const streetNumber = addressComponents.find((addressComponent) =>
              addressComponent.types?.some((type) => type === 'street_number')
            )?.long_name;
            const route =
              addressComponents.find((addressComponent) => addressComponent.types?.some((type) => type === 'route'))
                ?.long_name ?? '';
            const street = streetNumber ? streetNumber + ' ' + route : route;
            if (route && route?.length > 0) {
              onChange({target: {name, value: street}});
            }
          })
          .catch((error) => console.error('SpartanAutoAddressField error', error));
      }
    },
    [linkedFields]
  );

  return (
    <>
      <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
        {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
          <>
            <SpartanTextField
              {..._props}
              onChange={getInputProps().onChange}
              onBlur={getInputProps().onBlur}
              value={value}
            />
            <SuggestionList>
              {loading && <CircularProgress color="inherit" />}
              {suggestions.map((suggestion: Suggestion, index: number) => {
                return (
                  <Link {...getSuggestionItemProps(suggestion)} key={suggestion.id + '-' + index} to={'#'}>
                    <MenuItem value={suggestion.id}>{suggestion.description}</MenuItem>
                  </Link>
                );
              })}
            </SuggestionList>
          </>
        )}
      </PlacesAutocomplete>
      <Button
        onClick={() => {
          window.open(`https://www.google.com/maps/search/?api=1&query=${value}`, '_blank', 'noopener');
        }}
        color={'primary'}
        size={'small'}
        disabled={value.trim().length === 0}
        id={'address-btn-' + props.name}
        {...{[TestAttributes.BUTTON_NAME]: 'address-btn' + props.name}}
        sx={{justifyContent: 'flex-start', marginTop: '2%', textDecoration: 'none'}}
      >
        {t('form.open-in-google-maps')}
      </Button>
    </>
  );
}
