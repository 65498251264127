import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import React, {useState} from 'react';
import TabContext from '@mui/lab/TabContext';
import {useTranslation} from 'react-i18next';
import {FolderPath} from '../../model/Files';
import FileList from './components/FileList';
import {FileExtensions} from '../../model/FileExtensions';
import {TestAttributes} from '../../TestAttributes';

function SettingsUploadsCampaigns() {
  const {t} = useTranslation();

  const [value, setValue] = useState('0');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList onChange={handleChange} aria-label="autoCampaign tabs">
        <Tab label={t('settings.mmsTab')} value="0" {...{[TestAttributes.TAB_NAME]: 'mms-tab'}} />
        <Tab label={t('settings.ivrsTab')} value="1" {...{[TestAttributes.TAB_NAME]: 'ivrs-tab'}} />
        <Tab label={t('settings.voicemailTab')} value="2" {...{[TestAttributes.TAB_NAME]: 'voicemail-tab'}} />
        <Tab label={t('settings.emailTab')} value="3" {...{[TestAttributes.TAB_NAME]: 'email-tab'}} />
      </TabList>

      <>
        <TabPanel value="0">
          <FileList filePath={FolderPath.MMS} extensions={[FileExtensions.IMAGE]} />
        </TabPanel>
        <TabPanel value="1">
          <FileList filePath={FolderPath.IVR} extensions={[FileExtensions.WAV]} />
        </TabPanel>
        <TabPanel value="2">
          <FileList filePath={FolderPath.VOICEMAIL} extensions={[FileExtensions.WAV]} />
        </TabPanel>
        <TabPanel value="3">
          <FileList filePath={FolderPath.EMAIL} extensions={[FileExtensions.IMAGE]} />
        </TabPanel>
      </>
    </TabContext>
  );
}

export default SettingsUploadsCampaigns;
