import React from 'react';
import {SpartanTextFieldProps} from './model';
import {SpartanTextField} from './SpartanTextField';

export const SpartanTextAreaField = ({InputProps, ...props}: SpartanTextFieldProps) => (
  <SpartanTextField
    {...props}
    InputProps={{
      ...InputProps,
      type: 'text',
      multiline: true,
      minRows: 3,
      maxRows: 10,
    }}
  />
);
