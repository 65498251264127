export enum FileExtensions {
  TSV = '.tsv',
  CSV = '.csv',
  PDF = '.pdf',
  DOC = '.doc',
  DOCX = '.docx',
  XLSX = '.xlsx',
  PPTX = '.pptx',
  JPEG = '.jpeg',
  JPG = '.jpg',
  PNG = '.png',
  WAV = '.wav',
  MP3 = '.mp3',
  MP4 = '.mp4',
  M4A = '.m4a',
  WMA = '.wma',
  IMAGE = 'image/*',
  AUDIO = 'audios/*',
  VIDEO = 'videos/*',
}
