import * as React from 'react';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import {MaterialUiIcons} from './MaterialUiIcons';

interface Props {
  iconName: string;
  color?: string;
  fontSize?: 'small' | 'inherit' | 'large' | 'medium' | undefined;
}

export default function MaterialUiDynamicIcon({iconName, color, fontSize}: Props) {
  const Icon = MaterialUiIcons[iconName] ?? ImageNotSupportedIcon;
  return (
    <span>
      <Icon sx={{color: color}} fontSize={fontSize} />
    </span>
  );
}
