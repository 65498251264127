import {Breadcrumbs, Grid, useTheme} from '@mui/material';
import Container from '@mui/material/Container';
import React, {PropsWithChildren} from 'react';
import PageHeadline from '../../components/PageHeadline';
import {useTranslation} from 'react-i18next';
import {PageStickyHeader} from '../PageStickyHeader';
import {SysPageInfo} from './model/SysPageInfo';
import {SpartaLink} from './components/SpartaLink';

interface Props {
  page: SysPageInfo;
}

function SystemSettingsPageContainer({page, children}: PropsWithChildren<Props>) {
  const {t} = useTranslation();
  const theme = useTheme();

  return (
    <Container maxWidth={false}>
      <PageStickyHeader>
        <Grid container spacing={3} sx={{marginBottom: theme.spacing(3)}}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              {page.breadcrumbs?.map((link) => (
                <SpartaLink link={link} key={link.path} />
              ))}
              <span>{t(page.translation)}</span>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
            <PageHeadline>{t(page.translation)}</PageHeadline>
          </Grid>
        </Grid>
      </PageStickyHeader>
      {children}
    </Container>
  );
}

export default SystemSettingsPageContainer;
