import {useState} from 'react';
import {Box, Grid} from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';

function CookieConsent() {
  const {t} = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const cookieConsent = 'cookie-consent';
  const getItemLocalStorage = localStorage.getItem(cookieConsent);

  const handleConsent = () => {
    localStorage.setItem('cookie-consent', 'true');
    setIsVisible(false);
  };

  return (
    (!getItemLocalStorage && (
      <Box
        sx={{
          bottom: '16px',
          left: '8px',
          zIndex: '1300',
          position: 'fixed',
          display: isVisible ? 'block' : 'none',
          maxWidth: 400,
          p: 2,
          bgcolor: 'primary.main',
        }}
      >
        <Container>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: 'secondary.main',
                }}
                variant={'h6'}
              >
                {t('cookie-consent.message')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button color="secondary" variant="outlined" onClick={handleConsent} id={'cookie-consent-btn'}>
                {t('cookie-consent.actions.accept')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    )) ||
    null
  );
}

export default CookieConsent;
