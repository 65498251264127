import {ListItemBase} from './BaseItems';

export enum TypeSourceNumber {
  SHORT_CODE = 'short code',
  CAMPAIGN_DID = 'campaign did',
  USER_DID = 'user sms did',
}
export enum TypeCallSource {
  CALLER_DID = 'caller did',
  USER_DID = 'user did',
  LOCAL_PRESENCE = 'local presence',
}
export enum CampaignType {
  CALL = 'call',
  SMS = 'sms',
}

export enum CampaignDataSource {
  QUERY = 'query',
  TSV = 'tsv',
}

export enum CampaignDialerType {
  POWER = 'power',
  PREDICTIVE = 'predictive',
  CALL_NOW = 'call_now',
  ND = 'nd',
}

interface BaseCampaign {
  campaign_id: string;
  campaign_type?: string;
  name?: string;
  status_campaign?: string;
  script?: string;
  dialer_type?: string;
  include_duplicates?: boolean;
  dial_attempts?: number;
  source?: string;
  caller_id?: string;
  local_presence?: boolean;
}
export interface Campaign extends BaseCampaign {
  end_date?: string;
  end_time?: string;
  start_date?: string;
  start_time?: string;
  guide_content?: string;
  number?: string;
  short_code?: string;
  type_source_number?: string;
  caller_source_type?: string;
  sms_text?: string;
  preview_call_local_presence_bucket?: string;
  local_presence?: boolean;
  fallback_caller_did?: string;
  bucket_id?: string;
}

export interface CampaignListItem extends BaseCampaign, ListItemBase {
  description: string;
  timezone: string;
  batchsize: number;
  start_timestamp: string;
  end_timestamp: string;
  include_do_not_call: boolean;
  source_data: string;
}

export interface LeadToBurn {
  campaign_id: string;
  lead_id: string;
  created_by?: string;
  campaign_type: string;
}

export interface CampaignToCopy {
  campaign_id: string;
  name: string;
  filename?: string;
  campaign_id_tsv?: string;
}

export interface CalcTimeBetweenCallsProps {
  source_data: CampaignDataSource;
  start_timestamp: string;
  end_timestamp: string;
  lead_count?: number;
  filename?: string;
  campaign_id?: string;
  query_id?: string;
}

export interface LoggedInCampaign {
  id: string;
  campaignName: string;
}

export interface Bucket {
  bucket: string;
  caller_ids: string[];
}
