import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {Lead} from '../../../model/Lead';
import {FormBuilder} from '../../../form/FormBuilder';
import {FeatureName} from '../../../../paths';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {Auth0User, getUserExtension, getUserId, getUsername, getUserPhoneNumber} from '../../../model/Auth0User';
import {AppendOptionsFormFieldSchemaDecorator} from '../../../form/logic/FormDecorator';
import {useAuth0} from '@auth0/auth0-react';
import {difference} from '../../../form/utils';
import OrganizationService from '../../../services/OrganizationService';
import {FormConfig} from '../../../form/FormConfig';
import {HttpMethods} from '../../../model/HttpMethods';
import {AxiosResponse} from 'axios';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import {DefaultResponse} from '../../../services/model/DefaultResponse';
import {useAxiosContext} from '../../../context/AxiosContext';
import {useLoading} from '../../../context/LoadingContext';

interface CallDialogProps {
  isOpen: boolean;
  handleClose: Function;
  lead: Lead;
}

function CallDialog({isOpen, handleClose, lead}: CallDialogProps) {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const auth0 = useAuth0<Auth0User>();
  const {setLoading} = useLoading();
  const submitEl = useRef<HTMLButtonElement>(null);
  const [initialValues, setInitialValues] = useState<any>();
  const [config, setConfig] = useState<FormConfig>();

  const [{error: hasMakeCallError, loading}, makeCall] = useAxiosBFF<DefaultResponse>(
    {
      method: HttpMethods.POST,
      url: `callclick/`,
    },
    {manual: true}
  );
  useEffect(() => {
    setLoading(loading || isOpen, 'CallDialog');
  }, [loading, isOpen]);
  useEffect(() => {
    if (hasMakeCallError) {
      NotificationService.getInstance().sendNotification(
        hasMakeCallError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasMakeCallError]);

  useEffect(() => {
    setConfig({
      decorators: [
        new AppendOptionsFormFieldSchemaDecorator(
          'call_to',
          {
            label: lead?.primary_phone || lead?.owner_1_cell_phone || '',
            value: lead?.primary_phone || lead?.owner_1_cell_phone || '',
          },
          {
            label: lead?.secondary_phone || lead?.owner_2_cell_phone || '',
            value: lead?.secondary_phone || lead?.owner_2_cell_phone || '',
          }
        ),
      ],
    });
  }, [lead]);

  useEffect(() => {
    setInitialValues({...lead, call_to: lead?.primary_phone || lead?.owner_1_cell_phone || ''});
  }, [lead]);

  function onSubmit(data: any) {
    const organization = OrganizationService.getInstance().getCurrentValue();

    if (lead?.lead_id) {
      const leadId = lead?.lead_id;
      const phoneNumber = data['call_to'];
      data = difference(data, lead);

      // Ugly fix, this endpoint expects stuff that is not in the form
      data['lead_id'] = leadId;
      data['phone_number'] = phoneNumber;

      data['campaign_name'] = 'call click';
      data['press_one'] = organization?.press_one;
      data['user_id'] = getUserId(auth0.user);
      data['local_presence'] = organization?.local_presence;
      data['account'] = getUsername(auth0.user);
      data['caller_id'] = getUserPhoneNumber(auth0.user);
      data['extension'] = getUserExtension(auth0.user);

      delete data['call_to'];

      makeCall({data: data}).then((response: AxiosResponse) => {
        if (response?.status === 200) {
          NotificationService.getInstance().sendNotification(response?.data?.message, NotificationType.SUCCESS);
        }
      });
    }
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={isOpen}
        onClose={() => handleClose()}
        aria-labelledby="call-dialog-title"
      >
        <DialogTitle id="call-dialog-title">
          {t('leads.dialogs.call.call-a-lead')}
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {lead && (
            <FormBuilder onSubmit={onSubmit} formId={FeatureName.CALL} initialValues={initialValues} config={config}>
              <Button ref={submitEl} type="submit" sx={{display: 'none'}}>
                {t('leads.dialogs.call.call-now')}
              </Button>
            </FormBuilder>
          )}
        </DialogContent>

        <DialogActions sx={{justifyContent: 'initial'}}>
          <Button
            color="primary"
            id={'call-btn'}
            onClick={() => {
              submitEl?.current?.click();
            }}
          >
            {t('leads.dialogs.call.call-now')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CallDialog;
