/**
 * Used in end-2-end test to identify elements in DOM.
 * Convention is to use data attributes, see https://developer.mozilla.org/en-US/docs/Learn/HTML/Howto/Use_data_attributes
 * The attribute name should be easy to remember. Try following the pattern `data-{element name}-{name or id}`.
 * The value should not change over time (avoid using labels or other data that is displayed to the user, since it may change over time).
 */
export enum TestAttributes {
  FIELD_ID = 'data-field-id',
  FIELD_NAME = 'data-field-name',
  TAB_NAME = 'data-tab-name',
  SECTION_NAME = 'data-section-name',
  BUTTON_NAME = 'data-button-name',
  FIELD_OPTION_NAME = 'data-field-option-name',
  ACCORDION_NAME = 'data-accordion-name',
  LOADING_NAME = 'data-loading-name',
  ALERT_NAME = 'data-alert-name',
  TABLE_NAME = 'data-table-name',
  // Add other attributes if necessary...
}
