import {Grid, IconButton, Skeleton, TextField, Tooltip} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useLoading} from '../../../context/LoadingContext';
import {useConfigContext} from '../../../context/ConfigContext';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import RestoreIcon from '@mui/icons-material/Restore';
import {Config, READONLY_CONFIG_KEYS} from '../../../Config';
import {env} from '../../../../env';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import {useAuth0} from '@auth0/auth0-react';

interface ConfigParam {
  key: string;
  value: any;
  original?: ConfigParam;
}

function isValid(param?: ConfigParam): boolean {
  return param !== undefined && param.key.length > 0 && param.value !== undefined;
}

function isReadonly(key: string) {
  const keys = READONLY_CONFIG_KEYS as string[];
  return keys.includes(key);
}

export default function FrontendConfigPageComponent() {
  const [params, setParams] = useState<ConfigParam[] | null>(null);
  const [newParam, setNewParam] = useState<ConfigParam>({key: '', value: ''});
  const {setLoading} = useLoading();
  const {config, refreshConfig} = useConfigContext();
  const {getAccessTokenSilently} = useAuth0();

  useEffect(() => {
    setParams(
      Object.entries(config)
        .map(([key, value]) => ({key, value}))
        .sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0))
    );
  }, [config]);

  if (params === null) {
    return (
      <Grid container spacing={1} sx={{marginBottom: 1}}>
        {[...Array(6)].map((x) => (
          <Grid item xs={5}>
            <Skeleton variant="rectangular" animation="wave" height={36} />
          </Grid>
        ))}
      </Grid>
    );
  }

  function patchConfig(partial: Partial<Config>) {
    if (!partial || Object.keys(partial).length === 0) return;
    setLoading(true, 'FrontendConfigPage');
    getAccessTokenSilently()
      .then((token) =>
        fetch(env.CONFIG_URL, {
          method: 'PATCH',
          body: JSON.stringify(partial),
          headers: {authorization: `bearer ${token}`, 'content-type': 'application/json'},
        })
      )
      .then((resp) => {
        if (!resp.ok) throw Error('save config failed');
        refreshConfig();
        setNewParam({key: '', value: ''});
      })
      .catch((error) => {
        NotificationService.getInstance().sendNotification(error.message, NotificationType.ERROR);
      })
      .finally(() => setLoading(false, 'FrontendConfigPage'));
  }

  function saveParam(param: ConfigParam) {
    if (isValid(param)) {
      const partial = {[param.key]: param.value};
      if (param.original && param.original.key !== param.key) {
        partial[param.original.key] = null;
      }
      patchConfig(partial);
    }
  }

  function removeParam(param: ConfigParam) {
    if (param.key) {
      const ok = window.confirm(
        'Attention! This action may compromise the behaviour of the current application. Continue?'
      );
      if (ok) {
        patchConfig({[param.key]: null});
      }
    }
  }

  function updateParam(oldParam: ConfigParam, newParam: ConfigParam) {
    if (oldParam && isValid(newParam)) {
      const copy = params ? [...params] : [];
      copy.splice(
        copy.findIndex((p) => p.key === oldParam.key),
        1,
        {...newParam, original: oldParam.original ?? oldParam}
      );
      setParams(copy);
    }
  }

  function resetParam(param: ConfigParam) {
    if (param.original) {
      const copy = params ? [...params] : [];
      copy.splice(
        copy.findIndex((p) => p.key === param.key),
        1,
        param.original
      );
      setParams(copy);
    }
  }

  return (
    <>
      {params.map((p, index) => (
        <Grid container spacing={1} key={p.key} sx={{marginBottom: 1}}>
          <Grid item xs={5}>
            <TextField
              fullWidth
              disabled={isReadonly(p.key)}
              value={p.key}
              onChange={(event) => updateParam(p, {...p, key: event.target.value})}
            ></TextField>
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              disabled={isReadonly(p.key)}
              value={p.value}
              onChange={(event) => updateParam(p, {...p, value: event.target.value})}
            ></TextField>
          </Grid>
          {!isReadonly(p.key) && !p.original && (
            <Grid item>
              <Tooltip title={'delete'} placement="top">
                <IconButton onClick={() => removeParam(p)} edge="end">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {p.original && (
            <Grid item>
              <Tooltip title={'save'} placement="top">
                <span>
                  <IconButton disabled={!isValid(p)} onClick={() => saveParam(p)} edge="end">
                    <UploadIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          )}
          {p.original && (
            <Grid item>
              <Tooltip title={'reset'} placement="top">
                <IconButton onClick={() => resetParam(p)} edge="end">
                  <RestoreIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      ))}
      <Grid container spacing={1} sx={{marginBottom: 1}}>
        <Grid item xs={5}>
          <TextField
            fullWidth
            value={newParam.key}
            onChange={(event) => setNewParam({...newParam, key: event.target.value})}
            onKeyUp={(event) => event.key === 'Enter' && saveParam(newParam)}
          ></TextField>
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            value={newParam.value}
            onChange={(event) => setNewParam({...newParam, value: event.target.value})}
            onKeyUp={(event) => event.key === 'Enter' && saveParam(newParam)}
          ></TextField>
        </Grid>
        <Grid item>
          <Tooltip title={'save'} placement="top">
            <span>
              <IconButton disabled={!isValid(newParam)} onClick={() => saveParam(newParam)} edge="end">
                <UploadIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
}
