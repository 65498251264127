import {Grid, Typography} from '@mui/material';
import {TestAttributes} from '../../../TestAttributes';
import {useTranslation} from 'react-i18next';

interface QueryTotalResultProps {
  totalResults: number | null;
  isDirty: boolean;
}

const QueryTotalResult = (props: QueryTotalResultProps) => {
  const {totalResults, isDirty} = props;
  const {t} = useTranslation();
  return (
    <Grid item xs={12} {...{[TestAttributes.SECTION_NAME]: 'section-results'}}>
      <Typography variant={'h4'}>
        {t('query.table.result')} {totalResults} {isDirty ? t('query.table.outdated-results') : ''}
      </Typography>
    </Grid>
  );
};

export default QueryTotalResult;
