import {FormFieldSchema} from './logic/FormSchema';
import * as _ from 'lodash';
import {OnEventHandler} from './model';

export function decorateOnChange(onChange: OnEventHandler, valueMapper: (value: any) => any): OnEventHandler {
  return (event: any) => {
    const name = event?.target?.name;
    const value = valueMapper(event?.target?.value);
    onChange({...event, target: {...event?.target, name, value}});
  };
}

export function toSize(field: FormFieldSchema) {
  return field.size === 'xxl'
    ? 12
    : field.size === 'xl'
    ? 6
    : field.size === 'lg'
    ? 4
    : field.size === 'md'
    ? 3
    : field.size === 'sm'
    ? 2
    : 1;
}

export function requireNotUndefined<T>(target: T | undefined, message: string): T {
  if (target === undefined) throw new Error(message);
  return target;
}

export function intersectArrays<T>(a1: T[], a2: T[]): T[] {
  return a1.filter((item) => a2.indexOf(item) !== -1);
}

export function deepEqual(source1: any, source2: any): boolean {
  return source1 === source2 || JSON.stringify(source1) === JSON.stringify(source2);
}

export function deepCopy<T>(source: T): T {
  return _.cloneDeep(source);
}

export function deepMerge(target: any, source: any): void {
  requireNotUndefined(target, 'Undefined target');
  _.merge(target, source);
}

export class MultiValueMap<K, V> extends Map<K, V[]> {
  add(key: K, value: V) {
    const array = this.get(key);
    if (array) {
      pushIfAbsent(array, value);
    } else {
      this.set(key, [value]);
    }
  }
}

export class ExtendedMap<K, V> extends Map<K, V> {
  computeIfAbsent(key: K, supplier: () => V) {
    let value = this.get(key);
    if (!value) {
      value = supplier();
      this.set(key, value);
    }
    return value;
  }
}

export function removeFromArray<T>(array: T[], value: T): T[] {
  let index;
  while ((index = array.indexOf(value)) >= 0) {
    array.splice(index, 1);
  }
  return array;
}

export function removeAllFromArray<T>(array: T[], values: T[]): T[] {
  let tmp = array;
  for (const value of values) {
    tmp = removeFromArray(tmp, value);
  }
  return tmp;
}

export function pushIfAbsent(array: any[], value: any): void {
  array.includes(value) || array.push(value);
}

export function pushAllIfAbsent(array: any[], values: any[]): void {
  for (const value of values) {
    pushIfAbsent(array, value);
  }
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object: any, base: any) {
  function changes(object: Object, base: Object) {
    return _.transform(object, function (result: Object, value: any, key: string) {
      if (
        !_.isEqual(value, base[key]) &&
        ((!Array.isArray(value) && (value !== '' || (value === '' && !_.isUndefined(base[key])))) ||
          (Array.isArray(value) && !(_.isEmpty(value) && _.isUndefined(base[key]))))
      ) {
        result[key] = !(_.isArray(value) && _.isEmpty(value))
          ? _.isObject(value) && _.isObject(base[key]) && !Array.isArray(value)
            ? changes(value, base[key])
            : value
          : null;
      }
    });
  }

  return changes(object, base);
}
