import {useTranslation} from 'react-i18next';
import {Grid, Typography} from '@mui/material';
import DensityRadioGroup from './components/DensityRadioGroup';
import ThemeMode from './components/ThemeMode';
import LanguageMode from './components/LanguageMode';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName} from '../../../paths';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import ChatMode from './components/ChatMode';

function UserSettings() {
  const {t} = useTranslation();
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.NAVBAR);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2">{t('profile.settings')}</Typography>
      </Grid>
      <Grid item>
        <DensityRadioGroup />
      </Grid>
      <Grid item>
        <ThemeMode />
      </Grid>
      <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW_TRANSLATION]}>
        <Grid item>
          <LanguageMode />
        </Grid>
      </AccessControl>
      <Grid item>
        <ChatMode />
      </Grid>
    </Grid>
  );
}

export default UserSettings;
