import {createContext, PropsWithChildren, useContext} from 'react';
import {FormFeature} from '../model/FormFeature';

const Context = createContext<FeatureContext | null>(null);

interface Props extends PropsWithChildren {
  feature: FormFeature;
}

export const FeatureProvider = ({feature, children}: Props) => {
  return <Context.Provider value={{feature}}>{children}</Context.Provider>;
};

export interface FeatureContext {
  feature: FormFeature;
}

export function useFeatureContext(): FeatureContext {
  const value = useContext(Context);
  if (!value) throw Error('invalid context');
  return value;
}
