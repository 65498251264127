import * as React from 'react';
import {PropsWithChildren, useState} from 'react';
import {Controller, useFieldArray, useFormContext} from 'react-hook-form';
import Grid from '@mui/material/Grid';
import {IconButton, InputLabel, MenuItem, Select, Tooltip} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import ControlledInput from '../../shared/ControlledInput';
import {useTranslation} from 'react-i18next';
import {SpartanFieldOption} from '../../../form/fields/model';
import {UserGuideActionType, UserGuideControls} from './UserGuideConsts';
import Typography from '@mui/material/Typography';

interface ScriptStepOptionProps {
  stepIndex: number;
}

export default function UserGuideAction({stepIndex}: PropsWithChildren<ScriptStepOptionProps>) {
  const {t} = useTranslation();
  const {control} = useFormContext();
  const [actionOptions] = useState<Array<SpartanFieldOption>>([
    {value: UserGuideActionType.HIGHLIGHT, label: t('user-guide.editor.action.highlight')},
    {value: UserGuideActionType.CLICK, label: t('user-guide.editor.action.click')},
  ]);

  const {fields, append, remove} = useFieldArray({
    control,
    name: `${UserGuideControls.FIELD_ARRAY_STEPS}.${stepIndex}.actions`,
  });

  return (
    <Grid container>
      <Grid container item spacing={3} xs={11}>
        {fields.length === 0 && (
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Typography variant={'body1'}> {t('user-guide.editor.action.no-actions')} </Typography>
          </Grid>
        )}

        {fields.map((currentField, index) => (
          <Grid
            container
            item
            spacing={3}
            key={currentField?.id}
            className={`call-script-step-${stepIndex}-action-wrapper`}
          >
            <Grid item xs={3}>
              <ControlledInput
                id={`step-${stepIndex}-actions-${index}-name`}
                name={`${UserGuideControls.FIELD_ARRAY_STEPS}.${stepIndex}.actions.${index}.target`}
                label={t('user-guide.editor.action.target')}
                isRequired={true}
                toolTipText={t('user-guide.editor.action.target')}
              ></ControlledInput>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title={t('user-guide.editor.action.action-type') || ''} placement={'right-start'}>
                <Controller
                  render={({field: controllerField}) => {
                    return (
                      <>
                        <InputLabel htmlFor={controllerField.name + '-actions'}>
                          {t('user-guide.editor.action.action-type')}
                        </InputLabel>
                        <Select fullWidth id={`step-${stepIndex}-actions-${index}-type`} {...controllerField}>
                          {actionOptions?.map((option: SpartanFieldOption) => (
                            <MenuItem
                              id={controllerField.name + '-actions-' + option.value}
                              key={controllerField.name + '-action-' + option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    );
                  }}
                  name={`${UserGuideControls.FIELD_ARRAY_STEPS}.${stepIndex}.actions.${index}.type`}
                  control={control}
                  rules={{required: true}}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
              <IconButton
                id={`remove-step-${stepIndex}-actions-${index}-btn`}
                color="primary"
                aria-label="remove step actions"
                component="button"
                onClick={() => remove(index)}
                size="large"
              >
                <IndeterminateCheckBoxIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={1} display={'flex'} alignSelf={'flex-end'} mb={'5px'}>
        <IconButton
          id={`add-step-${stepIndex}-action-btn`}
          color="primary"
          aria-label="add step action"
          component="button"
          onClick={() => append({target: t('user-guide.editor.action.field-id'), type: UserGuideActionType.HIGHLIGHT})}
          size="large"
        >
          <AddBoxIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
