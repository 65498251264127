import {Backdrop, CircularProgress} from '@mui/material';
import {TestAttributes} from '../../TestAttributes';
import {useLoading} from '../../context/LoadingContext';

export function BackdropCircularProgress() {
  return (
    <Backdrop
      sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
      open={true}
      {...{[TestAttributes.LOADING_NAME]: 'loading-component'}}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default function Loading() {
  const {loading} = useLoading();
  return loading ? <BackdropCircularProgress /> : null;
}
