import {Button, Grid, TextField} from '@mui/material';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAuth0} from '@auth0/auth0-react';
import {Chat, ChatSendMessage, ChatViewMessage} from '../../../model/SmsPortal';
import {Auth0User, getUsername, getUserSmsDid} from '../../../model/Auth0User';
import {formatDateTime} from '../../../utils/DateUtils';
import {FeatureName} from '../../../../paths';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import {DefaultResponse} from '../../../services/model/DefaultResponse';
import {paletteModeDark, useColors} from '../../../context/LightDarkThemeProvider';
import {useAxiosContext} from '../../../context/AxiosContext';
import {useLoading} from '../../../context/LoadingContext';

interface ChatFieldProps {
  chat: Chat | null;
  setNewMessage: (newMessage: ChatViewMessage) => void;
}

function ChatField({chat, setNewMessage}: ChatFieldProps) {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const {setLoading} = useLoading();
  const colors = useColors();
  const auth0 = useAuth0<Auth0User>();
  const {user} = auth0;
  const [message, setMessage] = useState<string>('');

  const [{response: postMessageResponse, error: postMessageError, loading: postMsgLoading}, postMessage] =
    useAxiosBFF<DefaultResponse>(
      {
        url: `${FeatureName.SMS_PORTAL_API_WORKAROUND_PATH}/${chat?.lead_id}`,
        method: 'POST',
      },
      {manual: true}
    );

  useEffect(() => {
    setLoading(postMsgLoading, 'ChatField');
  }, [postMsgLoading]);

  useEffect(() => {
    if (postMessageResponse) {
      if (postMessageResponse?.status === 201) {
        addNewMessage(message);
        setMessage('');
        NotificationService.getInstance().sendNotification(
          postMessageResponse?.data?.message,
          NotificationType.SUCCESS
        );
      }
    }
  }, [postMessageResponse]);
  useEffect(() => {
    if (postMessageError) {
      NotificationService.getInstance().sendNotification(
        postMessageError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [postMessageError]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.currentTarget.value);
  };

  const sendMessage = () => {
    if (chat) {
      const data: ChatSendMessage = {
        number: chat?.from_number,
        text: message,
        source: getUserSmsDid(user) || '',
      };
      postMessage({data});
    }
  };

  const addNewMessage = (message: string) => {
    const newMessage: ChatViewMessage = {
      message,
      number: getUserSmsDid(user) || '',
      sms_status: 'sent',
      created_at: formatDateTime(new Date()),
      username: getUsername(user) || '',
    };
    setNewMessage(newMessage);
  };

  return (
    <Grid container item spacing={3} direction={'column'}>
      <Grid item>
        <TextField
          fullWidth
          multiline
          minRows={3}
          maxRows={10}
          value={message}
          onChange={handleChange}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === paletteModeDark
                ? colors.darkModeColors.darkModeSurfaceTransparency
                : theme.palette.secondary.main,
          }}
        />
      </Grid>
      <Grid item>
        <Button
          id={'send-sms-btn'}
          onClick={() => sendMessage()}
          disabled={!chat || message === ''}
          sx={{
            mb: '1rem',
            color: (theme) => theme.palette.primary.contrastText,
          }}
        >
          {t('sms-portal.send-message')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default ChatField;
