enum Auth0ErrorCode {
  /**
   * Returned directly from Auth0 when a blocked user attempts to login.
   */
  USER_IS_BLOCKED = 'user_is_blocked',
  /**
   * Returned by our load-nextgen-user-by-email action.
   * Not able to identify the user in spanner, another login with different user may succeed.
   */
  USER_NOT_FOUND = 'user_not_found',
  /**
   * Returned by our load-nextgen-user-by-email action.
   * User data in spanner is malformed, another login with different user may succeed.
   * ACTION REQUIRED in spanner to fix the malformed user!
   */
  AUTH0_CONFIG_ERROR = 'auth0_config_error',
  /**
   * Returned by our load-nextgen-user-by-email action.
   * Configuration error in Auth0, another login would be useless.
   * ACTION REQUIRED in Auth0 dashboard or in the code here!
   */
  SPANNER_INVALID_DATA = 'spanner_data_error',
  /**
   * Returned by our load-nextgen-user-by-email action.
   * Client send an invalid login request.
   * ACTION REQUIRED in FE to fix the login request!
   */
  INVALID_LOGIN_REQUEST = 'invalid_login_request',
  /**
   * Returned by our check last password change action.
   * It's been more than X days since the last password change
   * ACTION REQUIRED click on forgot password, and set a new one
   */
  USER_SHOULD_UPDATE_PASSWORD = 'user_should_update_pass',
  /**
   * Any other error
   */
  UNKNOWN_ERROR = 'unknown_error',
}

function isAllowReLogin(code: Auth0ErrorCode): boolean {
  switch (code) {
    case Auth0ErrorCode.USER_IS_BLOCKED:
    case Auth0ErrorCode.USER_NOT_FOUND:
    case Auth0ErrorCode.USER_SHOULD_UPDATE_PASSWORD:
      return true;
  }
  return false;
}

function toTranslationKey(code: Auth0ErrorCode): string {
  switch (code) {
    case Auth0ErrorCode.USER_IS_BLOCKED:
      return 'login.errors.user-is-blocked';
    case Auth0ErrorCode.USER_NOT_FOUND:
      return 'login.errors.user-not-found';
    case Auth0ErrorCode.USER_SHOULD_UPDATE_PASSWORD:
      return 'login.errors.user-should-update-password';
  }
  return 'login.errors.generic-error';
}

interface Auth0ErrorResponse {
  error: string;
  error_description?: string;
}

export class Auth0Error {
  translationKey: string;
  allowReLogin: boolean;

  constructor(e: Error) {
    const error = JSON.parse(JSON.stringify(e)) as Auth0ErrorResponse;

    let spartanErrorCode = error.error_description?.trim().replace(/\s+/g, '_') as Auth0ErrorCode;
    if (!Object.values(Auth0ErrorCode).includes(spartanErrorCode)) {
      spartanErrorCode = Auth0ErrorCode.UNKNOWN_ERROR;
    }

    console.warn('Authentication error', spartanErrorCode, error);

    this.allowReLogin = isAllowReLogin(spartanErrorCode);
    this.translationKey = toTranslationKey(spartanErrorCode);
  }
}
