import {FormDataType} from '../../model/Query';

export enum LogicOperator {
  AND = 'and',
  OR = 'or',
}

export enum ConditionOperator {
  EQUALS = 'equals',
  NOT_EQUAL = 'not_equal',
  EQUALS_BOOLEAN = 'equals_boolean',
  NOT_EQUAL_BOOLEAN = 'not_equal_boolean',
  CONTAINS = 'contains',
  STARTS_WITH = 'starts_with',
  ENDS_WITH = 'ends_with',
  NULL = 'is_null',
  NOT_NULL = 'is_not_null',
  LESS_THAN = 'less_than',
  GREATER_THAN = 'greater_than',
  LESS_THAN_OR_EQUAL = 'less_than_or_equal',
  GREATER_THAN_OR_EQUAL = 'greater_than_or_equal',
  // dates
  DATE_FROM = 'date_from',
  DATE_TO = 'date_to',
  DATE_BETWEEN = 'date_between',
  DATE_YESTERDAY = 'date_yesterday',
  LAST_N_DAYS = 'last_n_days',
  TO_YESTERDAY = 'to_yesterday',
  TODAY = 'today',
  N_DAYS_AGO = 'n_days_ago',
}

export function getDefaultConditionOperator(dataType: FormDataType): ConditionOperator {
  let conditionOperator: ConditionOperator;

  switch (dataType) {
    case FormDataType.TEXT:
    case FormDataType.PHONE:
    case FormDataType.SELECT:
    case FormDataType.NUMBER:
      conditionOperator = ConditionOperator.EQUALS;
      break;
    case FormDataType.DATE:
    case FormDataType.TIMESTAMP:
      conditionOperator = ConditionOperator.DATE_TO;
      break;
    case FormDataType.LIST:
      conditionOperator = ConditionOperator.CONTAINS;
      break;
    case FormDataType.BOOLEAN:
      conditionOperator = ConditionOperator.EQUALS_BOOLEAN;
      break;
    default:
      conditionOperator = ConditionOperator.NOT_NULL;
  }
  return conditionOperator;
}
