import {propsWithTestAttributes, SpartanFieldProps} from './model';
import {requireNotUndefined} from '../utils';
import {useFormHelper} from '../FormHelper';
import {LinkedFieldSchema} from '../logic/FormSchema';
import {Box, Grid, Typography, Card, CardActionArea, CardContent, useTheme} from '@mui/material';
import DynamicIcon from '../../components/DynamicIcon';
import {useState, useEffect} from 'react';

interface SpartanOptionCardGroupConfiguration {
  title?: string;
  icon?: string;
  color?: string;
  bolder?: boolean;
}

interface SpartanAutoFillOptionCardFieldProps extends SpartanFieldProps {
  groupInfo?: JSON;
}

export function SpartanAutoFillOptionCardField(_props: SpartanAutoFillOptionCardFieldProps) {
  const props = propsWithTestAttributes(_props);
  const theme = useTheme();
  const {name, value, label, disabled: hidden, onChange} = props;
  const groupInfo: SpartanOptionCardGroupConfiguration = props.groupInfo as SpartanOptionCardGroupConfiguration;
  const {schemaHelper} = useFormHelper();
  const field = requireNotUndefined(schemaHelper.getFieldSchema(name), `Missing field ${name}`);
  const [linkedFields, setLinkedFields] = useState<Array<LinkedFieldSchema>>();
  useEffect(() => {
    if (field.linked_fields) {
      setLinkedFields([...field.linked_fields]);
    }
  }, [field.linked_fields]);

  return (
    <>
      <Grid key={'prompt-option-' + name} item hidden={hidden}>
        <Card id={name.replace(' ', '-').toLowerCase()}>
          <CardActionArea
            onClick={() => {
              onChange({target: {name: linkedFields?.at(0)?.field_name, value: value ?? ''}});
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexFlow: 'column',
                backgroundColor: groupInfo?.color,
              }}
            >
              {groupInfo?.icon && (
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <DynamicIcon iconName={groupInfo.icon} color={theme.palette.primary.main} fontSize="large" />
                </Box>
              )}
              {groupInfo?.title && (
                <Typography
                  variant={'h2'}
                  sx={{
                    textTransform: 'capitalize',
                    textAlign: 'center',
                    marginBottom: theme.spacing(1),
                  }}
                  dangerouslySetInnerHTML={{
                    __html: groupInfo.title,
                  }}
                />
              )}

              <Typography
                variant={groupInfo?.bolder ? 'caption' : 'body2'}
                sx={{
                  fontSize: groupInfo?.bolder ? '0.825rem' : '0.875rem',
                  color: groupInfo?.color ? 'black' : '',
                  textAlign: 'center',
                }}
              >
                {label}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </>
  );
}
