import {InputLabel, TextField} from '@mui/material';
import {buildSetValue, propsWithTestAttributes, SpartanInputFieldProps, wrapInTooltipIfNecessary} from './model';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers-pro';
import {ErrorMessage} from './components/ErrorMessage';
import {formatDate, parseDate} from '../../utils/DateUtils';
import {SpartanLabelWithTooltip} from './SpartanLabelWithTooltip';
import {enUS, es} from 'date-fns/locale';
import {useTranslation} from 'react-i18next';

export interface SpartanDateFieldProps extends SpartanInputFieldProps {
  minDate?: any;
  maxDate?: any;
}

function toValue(pickerValue: any) {
  if (pickerValue) {
    const year = parseDate(pickerValue).getFullYear();
    if (isNaN(year) || year < 1000) {
      return 'Invalid Date'; // same output of formatDate() when input is not a valid date
    }
    return formatDate(pickerValue);
  }
  return null;
}

export function SpartanDateField(_props: SpartanDateFieldProps) {
  const {i18n} = useTranslation();

  const props = propsWithTestAttributes(_props);
  const {
    id,
    name,
    value,
    label,
    disabled,
    onBlur,
    onFocus,
    hasError,
    error,
    inputRef,
    inputProps,
    minDate,
    maxDate,
    isNestedLabel = false,
  } = props;
  const setValue = buildSetValue(props);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n?.language === 'es' ? es : enUS}>
      <DatePicker
        value={value ? parseDate(value) : null}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        onChange={(date: any) => setValue(toValue(date))}
        inputRef={inputRef}
        renderInput={(params: any) => {
          const {
            error: errorToIgnore,
            helperText: helperTextToIgnore,
            label: labelToIgnore,
            inputProps: inputPropsParam,
            ...restOfParams
          } = params;
          const _inputProps = {...inputPropsParam, ...inputProps};
          return (
            <>
              {!isNestedLabel && (
                <InputLabel error={hasError} htmlFor={id}>
                  <SpartanLabelWithTooltip label={label} />
                </InputLabel>
              )}
              {wrapInTooltipIfNecessary(
                props,
                <TextField
                  fullWidth
                  id={id}
                  name={name}
                  {...restOfParams}
                  error={hasError}
                  inputProps={_inputProps}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  label={isNestedLabel ? label : undefined}
                />
              )}
              <ErrorMessage hasError={hasError} error={error} />
            </>
          );
        }}
      />
    </LocalizationProvider>
  );
}
