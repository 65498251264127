import React from 'react';
import SingleSectionComponent from './SingleSectionComponent';
import ListLayoutComponent from './shared/ListLayoutComponent';
import {useFeatureContext} from '../contexts/FeatureContext';
import SectionEditorCreate from './editors/SectionEditorCreate';
import {SectionsProvider, useSectionsContext} from '../contexts/SectionsContext';

interface Props {
  sectionId: string;
}

function InternalSectionSectionsComponent({sectionId}: Props) {
  const {sections, loading} = useSectionsContext();
  const {feature} = useFeatureContext();
  const editor = <SectionEditorCreate partial={{feature_id: feature.feature_id, father_section: sectionId}} />;
  const children = sections?.map((section) => <SingleSectionComponent section={section} key={section.section_id} />);
  return <ListLayoutComponent title={'Sections'} children={children} editor={editor} loading={loading} />;
}

export default function ListSectionSectionComponent({sectionId}: Props) {
  return (
    <SectionsProvider sectionId={sectionId}>
      <InternalSectionSectionsComponent sectionId={sectionId} />
    </SectionsProvider>
  );
}
