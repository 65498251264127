import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {GridCellParams, GridColDef, GridRowModel} from '@mui/x-data-grid';
import {Box, Button, Container, Divider, Grid, IconButton, TextField, Typography} from '@mui/material';
import FileDownload from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers-pro';
import {FeatureName, Path} from '../../../paths';
import PageHeadline from '../../components/PageHeadline';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {PageTopActions} from '../PageTopActions';
import {FolderPath, ResponseFile} from '../../model/Files';
import {PageStickyHeader} from '../PageStickyHeader';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import {MetaFile} from '../../model/MetaFiles';
import DataGridCustom from '../../components/DataGridCustom';
import {TestAttributes} from '../../TestAttributes';
import {ColumnSizes} from '../../components/shared/ColumnSize';
import {useFiles} from '../../UseFiles';
import {enUS, es} from 'date-fns/locale';

function SmsLogListPage() {
  const {i18n} = useTranslation();
  const {t} = useTranslation();
  const defaultTableColumns: GridColDef[] = [{field: 'name', headerName: 'file name', width: 800}];
  const [files, setFiles] = useState<MetaFile[] | null>(null);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.SMS_LOG);
  const [folder, setFolder] = useState<string>('');
  const [tableColumns] = useState<GridColDef[]>(defaultTableColumns);
  const [dateValue, setDateValue] = useState<Date | null>(new Date());
  const [showResultsMessage, setShowResultsMessage] = useState<boolean>(false);
  const {getMetaFiles, getFile} = useFiles();

  useEffect(() => {
    if (files) {
      const isSearchResponse = files?.length > 0;
      setShowResultsMessage(!isSearchResponse);
    }
  }, [files]);

  useEffect(() => {
    if (dateValue) {
      dateValue?.getMonth();
      const year = dateValue?.getFullYear();
      const month = dateValue?.getMonth() + 1;
      setFolder(`${FolderPath.SMS_LOG_ARCHIVE}${year}/${month}/`);
    }
  }, [dateValue]);

  function handleLeadSearch(searchParams: string) {
    if (getMetaFiles) {
      getMetaFiles(searchParams).then((_files: MetaFile[]) => {
        setFiles(_files);
      });
    }
  }

  function downloadFile(cell: GridCellParams) {
    const {row}: GridRowModel = cell;
    getFile(row).then((_file: ResponseFile) => {
      window.open(_file?.url);
    });
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={6} md={7} order={{xs: 1, md: 1}} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('sms-log.archive.headline')}</PageHeadline>
              </Grid>
              <Grid item xs={12} md={4} order={{xs: 3, md: 2}} sx={{alignSelf: 'center'}}>
                <PageTopActions>
                  <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.CREATE]}>
                    <Link to={'/' + Path.SMS_LOG + '/create'}>
                      <Button fullWidth color="secondary" id={'add-btn'} {...{[TestAttributes.BUTTON_NAME]: 'add-btn'}}>
                        {t('globalActions.addButton')}
                      </Button>
                    </Link>
                  </AccessControl>
                </PageTopActions>
              </Grid>
            </Grid>
          </PageStickyHeader>

          <Divider />
          <Grid item container xs={12} spacing={1} alignItems="flex-end">
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n?.language === 'es' ? es : enUS}>
                <DatePicker
                  views={['year', 'month']}
                  label={t('sms-log.archive.date-picker')}
                  minDate={new Date('2021-01-02')}
                  maxDate={new Date()}
                  value={dateValue}
                  onChange={(newValue: any) => {
                    setDateValue(newValue);
                  }}
                  renderInput={(params: any) => <TextField {...params} helperText={null} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              sm={1}
              sx={{
                my: 1,
              }}
            >
              <Button
                onClick={() => handleLeadSearch(folder)}
                fullWidth
                id={'search-btn'}
                {...{[TestAttributes.BUTTON_NAME]: 'search-btn'}}
              >
                <SearchIcon />
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{height: '700px'}}
            justifyContent={'center'}
            {...{[TestAttributes.TABLE_NAME]: 'table-results'}}
          >
            {files && files?.length > 0 && (
              <DataGridCustom
                tableColumns={[
                  ...tableColumns,
                  {
                    field: 'actions',
                    filterable: false,
                    disableExport: true,
                    headerName: t('table-columns.actions'),
                    width: ColumnSizes.MD,
                    renderCell: (params: GridCellParams) => {
                      return (
                        <IconButton onClick={() => downloadFile(params)} size="large">
                          <FileDownload />
                        </IconButton>
                      );
                    },
                  },
                ]}
                rows={
                  files?.map((item: MetaFile, index: number) => {
                    return {
                      actions: item?.name,
                      ...item,
                      id: index,
                    };
                  }) || []
                }
                userPermissions={userPermissions}
              />
            )}

            {showResultsMessage && (
              <Grid item xs={12} sx={{marginTop: (theme) => theme.spacing(4)}}>
                <Typography variant={'h4'}>{t('shared.table-result')} </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default SmsLogListPage;
