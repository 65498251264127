import {CampaignScript} from '../model/CampaignScript';

export interface CallScriptResponse {
  guide_id: string;
  guide_name: string;
  guide_content: string;
}

export function toScript(resp: CallScriptResponse): CampaignScript {
  return {
    ...resp,
    guide_content: JSON.parse(resp?.guide_content || ''),
  };
}
