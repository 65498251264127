import {Observable, Subject, Subscription} from 'rxjs';
import UserService from './UserService';
import {WebSocketMessageData, WebSocketService} from './WebSocketService';

function isWebSocketNotification(obj: any): obj is WebSocketNotification {
  return obj && obj.type === 'notification' && typeof obj.message === 'string';
}

function extractWebSocketNotification(message: WebSocketMessageData): WebSocketNotification | null {
  return isWebSocketNotification(message.notification) ? message.notification : null;
}

interface WebSocketNotification {
  message: string;
  // if no users array is present, then the notification is meant for all users
  users?: Array<string>;
}

export enum NotificationType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export interface NotificationMessage {
  type: NotificationType;
  text: string;
}

export default class NotificationService {
  private static INSTANCE: NotificationService;
  private readonly subscription: Subscription;
  private readonly notifications = new Subject<NotificationMessage>();

  // IMPORTANT: this MUST remain a singleton! (or we need to unsubscribe from WebSocketService)
  static getInstance(): NotificationService {
    if (!NotificationService.INSTANCE) {
      NotificationService.INSTANCE = new NotificationService();
    }
    return NotificationService.INSTANCE;
  }

  constructor() {
    this.subscription = WebSocketService.getInstance()
      .listen()
      .subscribe((message: WebSocketMessageData) => this.handleWebSocketMessageData(message));
  }

  private handleWebSocketMessageData(message: WebSocketMessageData) {
    const notification = extractWebSocketNotification(message);
    if (notification) {
      const userId = UserService.getInstance().getValue()?.user_id ?? '';
      const forAllUsers = !notification.users;
      const forCurrentUser = notification.users?.some((user_id) => user_id === userId);
      if (forAllUsers || forCurrentUser) {
        this.sendNotification(notification.message, NotificationType.INFO);
      }
    }
  }

  sendNotification(message: string, type: NotificationType) {
    const notification = {text: message, type: type};
    console.debug(`Notification:`, notification);
    this.notifications.next(notification);
  }

  getNotification(): Observable<NotificationMessage> {
    return this.notifications.asObservable();
  }
}
