import React, {useEffect, useState} from 'react';
import {Alert, Button, FormControl, Grid, MenuItem, Select, TextField, Typography, useTheme} from '@mui/material';
import {TestAttributes} from '../../../TestAttributes';
import {Organization, OrganizationRole} from '../../../model/Organization';
import OrganizationService from '../../../services/OrganizationService';
import {useConfig} from '../../../context/ConfigContext';
import {BackdropCircularProgress} from '../../../components/shared/Loading';
import {useAxiosContext} from '../../../context/AxiosContext';
import {HttpMethods} from '../../../model/HttpMethods';
import {FeatureName} from '../../../../paths';
import {DefaultResponse} from '../../../services/model/DefaultResponse';
import {useLoading} from '../../../context/LoadingContext';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import Auth0ContextHolder from '../../../services/Auth0ContextHolder';
import {Auth0User, Auth0UserPermission, hasUserPermission} from '../../../model/Auth0User';
import {useAuth0} from '@auth0/auth0-react';
import Checkbox from '@mui/material/Checkbox';

export default function CachePageComponent() {
  const config = useConfig();
  const [roleId, setRoleId] = useState<string>();
  const [key, setKey] = useState<string>('grapes_lead_columns');
  const theme = useTheme();
  const {useAxiosBFF} = useAxiosContext();
  const {setLoading} = useLoading();
  const [organization, setOrganization] = useState<Organization>();
  const {user} = useAuth0<Auth0User>();
  const canImpersonateAny = hasUserPermission(user, Auth0UserPermission.IMPERSONATE_ANY);
  const [allOrgs, setAllOrgs] = useState(false);

  const [{error: deleteCacheError, loading: isDeleteCacheLoading, response: deleteCacheResponseResponse}, clearCache] =
    useAxiosBFF<DefaultResponse>(
      {
        method: HttpMethods.DELETE,
        url: `${FeatureName.FEATURE_FIELDS}/cache`,
        params: canImpersonateAny && allOrgs ? {'all-orgs': true} : {},
      },
      {manual: true}
    );

  const [
    {error: deleteCacheKeyError, loading: isDeleteCacheKeyLoading, response: deleteCacheKeyResponseResponse},
    clearCacheKey,
  ] = useAxiosBFF<DefaultResponse>(
    {
      method: HttpMethods.DELETE,
      url: `${FeatureName.FEATURE_FIELDS}/cache?key=${encodeURIComponent(key)}`,
      params: canImpersonateAny && allOrgs ? {'all-orgs': true} : {},
    },
    {manual: true}
  );

  useEffect(() => {
    const subscription = OrganizationService.getInstance()
      .getOrganization()
      .subscribe((org) => {
        if (org) {
          setOrganization(org);
        }
      });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const loading = isDeleteCacheLoading || isDeleteCacheKeyLoading;
    setLoading(loading, 'OrganizationCachePage');
  }, [isDeleteCacheLoading, isDeleteCacheKeyLoading]);

  useEffect(() => {
    const entityError = deleteCacheKeyError || deleteCacheError;
    if (entityError) {
      NotificationService.getInstance().sendNotification(entityError?.response?.data?.message, NotificationType.ERROR);
    }
  }, [deleteCacheKeyError, deleteCacheError]);

  useEffect(() => {
    if (deleteCacheResponseResponse?.status === 200) {
      NotificationService.getInstance().sendNotification(
        deleteCacheResponseResponse.data?.['message'],
        NotificationType.SUCCESS
      );
    }
  }, [deleteCacheResponseResponse]);

  useEffect(() => {
    if (deleteCacheKeyResponseResponse?.status === 200) {
      NotificationService.getInstance().sendNotification(
        deleteCacheKeyResponseResponse.data?.['message'],
        NotificationType.SUCCESS
      );
    }
  }, [deleteCacheKeyResponseResponse]);

  async function clearBFFCacheRole() {
    const auth0 = Auth0ContextHolder.getInstance().get();
    if (!auth0) throw Error('Unauthorized');
    if (!auth0.isAuthenticated) throw Error('Unauthorized');
    const token = await auth0.getAccessTokenSilently();
    try {
      setLoading(true, 'OrganizationCachePage');
      const response = await fetch(
        `https://bff.${config.ENV_NAME}.spartanapproach.com/api/v1/cache?selected_role=${
          roleId ? encodeURIComponent(roleId) : roleId
        }`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseMessage: DefaultResponse = await response.json();
      setLoading(false, 'OrganizationCachePage');

      if (response.ok) {
        NotificationService.getInstance().sendNotification(responseMessage?.message, NotificationType.SUCCESS);
      } else {
        NotificationService.getInstance().sendNotification(responseMessage?.message, NotificationType.ERROR);
      }
    } catch (error) {
      NotificationService.getInstance().sendNotification('request failed', NotificationType.ERROR);
      setLoading(false, 'OrganizationCachePage');
    }
  }

  return (
    <>
      {organization ? (
        <>
          <Grid container rowSpacing={5}>
            {canImpersonateAny && (
              <Grid item container spacing={3}>
                <Grid item>
                  <Checkbox checked={allOrgs} onChange={(e) => setAllOrgs((prev) => !prev)} />
                </Grid>
                <Grid item>
                  {allOrgs ? (
                    <Alert severity={'warning'}>
                      Apply to all orgs <b>enabled</b>!
                    </Alert>
                  ) : (
                    <Alert severity={'info'}>Apply to all orgs</Alert>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid item container spacing={3}>
              <Grid item>
                <Button color="secondary" onClick={() => clearCache()}>
                  {'clear'}
                </Button>
              </Grid>
              <Grid item>
                <Typography>Clear primary features cache (Forms and their fields and options)</Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={3}>
              <Grid item>
                <Button color="secondary" onClick={() => clearCacheKey()}>
                  {'clear'}
                </Button>
              </Grid>
              <Grid item>
                <FormControl sx={{width: theme.spacing(30)}}>
                  <TextField
                    id={'clear-key-field'}
                    value={key}
                    placeholder={'key'}
                    onChange={(event) => setKey(event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Typography>Clear Specific Cache Key</Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={3}>
              <Grid item>
                <Button color="secondary" onClick={() => clearBFFCacheRole()}>
                  {'clear'}
                </Button>
              </Grid>
              <Grid item>
                <FormControl sx={{width: theme.spacing(30)}}>
                  <Select
                    {...{[TestAttributes.FIELD_NAME]: 'selected-role'}}
                    id="selected-role"
                    value={roleId ?? ''}
                    onChange={(e: any) => setRoleId(e.target.value)}
                  >
                    <MenuItem key={'empty'} value={''}>
                      {' '}
                    </MenuItem>
                    {organization.roles.map((feature: OrganizationRole) => (
                      <MenuItem
                        key={feature.role_id}
                        id={feature.role_id}
                        value={feature.role_id}
                        {...{[TestAttributes.FIELD_OPTION_NAME]: feature.role_name}}
                      >
                        {feature.role_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Typography>Clear direct feature related data and direct feature related permissions (bff)</Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <BackdropCircularProgress />
      )}
    </>
  );
}
