import {CampaignDialerType} from './Campaign';

export function isCampaignWithDates(campaign: WebSocketCampaignBase): boolean {
  return (
    typeof campaign.end_date &&
    typeof campaign.end_time === 'string' &&
    typeof campaign.start_date === 'string' &&
    typeof campaign.start_time === 'string'
  );
}

export function isCampaignWithDPSData(campaign: WebSocketCampaign): campaign is WebSocketCampaignWithDPSData {
  const wsCampaign = campaign as WebSocketCampaignWithDPSData;
  return wsCampaign && wsCampaign.actual_dps >= 0;
}

export interface WebSocketCampaignBase {
  _key: string; // original key from WS message object
  campaign_id: string | null; // not all WS campaigns have valid campaign_id, null if enable to parse uuid
  name: string;
  dialer_type: CampaignDialerType;
  messages: number;
  refill: boolean;
  end_date?: string;
  end_time?: string;
  start_date?: string;
  start_time?: string;
}

export interface WebSocketCampaignCallNow extends WebSocketCampaignBase {
  users: Array<string>;
  active_hours: boolean;
  priority: number;
}

export interface WebSocketCampaignWithDPSData extends WebSocketCampaignBase {
  expected_dps: number;
  actual_dps: number;
  consumption_rate?: string;
}
export interface WebSocketCampaigns {
  predictive_campaigns: Array<WebSocketCampaignBase>;
  power_campaigns: Array<WebSocketCampaignBase | WebSocketCampaignWithDPSData>;
  after_hours: Array<WebSocketCampaignBase>;
  call_now: Array<WebSocketCampaignCallNow>;
}

export type WebSocketCampaign = WebSocketCampaignBase | WebSocketCampaignCallNow | WebSocketCampaignWithDPSData;
