import moment, {Moment} from 'moment';

export const SPARTAN_DATETIME_WITH_TZ_FORMAT = 'yyyy-MM-DD HH:mm:ssZ';

export function formatDateTimeWithTz(value: any): string {
  const m = toMoment(value);
  const f = m.seconds(0).milliseconds(0).format(SPARTAN_DATETIME_WITH_TZ_FORMAT);
  console.debug('[DateUtils] formatDateTime', value, '>>', f);
  return f;
}

export function isDateTimeWithTz(value: any): boolean {
  return (
    moment(value, 'yyyy-MM-DD HH:mm:ss', true).isValid() ||
    moment(value, 'yyyy-MM-DD HH:mm:ssZ', true).isValid() ||
    moment(value, 'yyyy-MM-DD HH:mm:ss.SSS', true).isValid() ||
    moment(value, 'yyyy-MM-DD HH:mm:ss.SSSZ', true).isValid() ||
    moment(value, 'yyyy-MM-DD HH:mm:ss.SSSSSS', true).isValid() ||
    moment(value, 'yyyy-MM-DD HH:mm:ss.SSSSSSZ', true).isValid()
  );
}

export function parseDateTimeWithTz(value: any): Date {
  const m = toMoment(value);
  const date = m.toDate();
  console.debug('[DateUtils] parseDateTime', value, '>>', date);
  return date;
}

// do not export toMoment, let's try to hide moment library as long as possible
function toMoment(value: any, format?: string): Moment {
  if (format) return moment(value, format, true);
  return moment(value, true);
}
