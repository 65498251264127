import React, {useEffect, useState} from 'react';
import {Box, Button, Container, FormControl, Grid, IconButton, Menu, MenuItem, Select} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {Skeleton} from '@mui/lab';
import {useAuth0} from '@auth0/auth0-react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {GridCellParams, GridColDef, GridRowModel} from '@mui/x-data-grid';
import PageHeadline from '../../components/PageHeadline';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {FeatureName, Path} from '../../../paths';
import {QueryListItem, QueryMode, queryModeList, QueryToCopy} from '../../model/Query';
import {PageTopActions} from '../PageTopActions';
import {Auth0User, isPowerOrg} from '../../model/Auth0User';
import {PageStickyHeader} from '../PageStickyHeader';
import CopyQueryDialog from './dialogs/CopyQueryDialog';
import {formatDateTime} from '../../utils/DateUtils';
import QueryService from '../../services/QueryService';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import {useTableColumns} from '../UseTableColumns';
import DataGridCustom from '../../components/DataGridCustom';
import {TestAttributes} from '../../TestAttributes';
import {getDynamicTranslationKey} from '../../../i18n/Utils';
import {ColumnSizes} from '../../components/shared/ColumnSize';
import SpartanSnackbar from '../../components/SpartanSnackbar';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {DefaultResponse} from '../../services/model/DefaultResponse';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

function QueryListPage() {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const auth0 = useAuth0<Auth0User>();
  const {user} = auth0;
  const {setLoading} = useLoading();
  const navigate = useNavigate();
  const {featureId} = useParams();
  const featureName = featureId ?? '';
  const queryForm = FeatureName[`${featureName.toUpperCase()}_QUERIES`];
  const {userPermissions}: UsePermissionState = usePermissions(queryForm);

  const [filteredQueries, setFilteredQueries] = useState<Array<QueryListItem>>([]);
  const [isCopyQueryDialogOpen, setIsCopyQueryDialogOpen] = useState<boolean>(false);
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedQuery, setSelectedQuery] = useState<QueryListItem | null>(null);
  const [queryMode, setQueryMode] = useState<QueryMode>(QueryService.getInstance().getQueryModeFilter());
  const [actionColumn, setActionColumn] = useState<GridColDef>();
  const power = isPowerOrg(user);
  const getQueryModeList: QueryMode[] = queryModeList(power);

  const [{response: getListQueryResponse, loading: isEntityListLoading, error: hasEntityListError}, getAllQueries] =
    useAxiosBFF<ResponseListWrapper<QueryListItem>>(
      {
        url: `/${FeatureName.QUERIES}`,
        method: 'GET',
        params: {type: queryMode},
      },
      {manual: true}
    );
  const [
    {response: postCopyQueryResponse, error: postCopyQueryError, loading: postCopyQueryLoading},
    postCopyQueryData,
  ] = useAxiosBFF<DefaultResponse>(
    {
      url: `${FeatureName.QUERIES}`,
      method: 'POST',
      params: {
        copy: 'true',
      },
    },
    {manual: true}
  );
  useEffect(() => {
    setLoading(isEntityListLoading || postCopyQueryLoading, 'QueryBuilderListPage');
  }, [isEntityListLoading, postCopyQueryLoading]);
  useEffect(() => {
    if (postCopyQueryResponse?.status === 200) {
      getAllQueries();
    }
  }, [postCopyQueryResponse]);
  useEffect(() => {
    if (postCopyQueryError) {
      NotificationService.getInstance().sendNotification(
        postCopyQueryError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [postCopyQueryError]);
  useEffect(() => {
    if (getListQueryResponse) {
      setFilteredQueries(getListQueryResponse?.data?.results || []);
    }
  }, [getListQueryResponse]);

  useEffect(() => {
    setActionColumn({
      field: 'actions',
      headerName: t('table-columns.actions'),
      width: ColumnSizes.MD,
      filterable: false,
      disableExport: true,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <IconButton
              {...{[TestAttributes.BUTTON_NAME]: 'query-list-more-actions-btn'}}
              id="query-list-more-actions-btn"
              aria-haspopup="true"
              onClick={(event: React.MouseEvent<HTMLElement>) => handleActionsMenuIconClick(event, params)}
              size="large"
            >
              <MoreHorizIcon />
            </IconButton>
          </>
        );
      },
    });
  }, []);
  const tableColumns = useTableColumns({
    featureName: queryForm,
    actionColumn,
  });

  function handleMoreActionsMenuClose(): void {
    setActionsMenuAnchorEl(null);
  }

  function handleQuerySelected(): void {
    handleMoreActionsMenuClose();
    setIsCopyQueryDialogOpen(true);
  }

  function handleActionsMenuIconClick(event: React.MouseEvent<HTMLElement>, cell: GridCellParams): void {
    const {row}: GridRowModel = cell;
    setActionsMenuAnchorEl(event.currentTarget);
    setSelectedQuery(row);
  }

  useEffect(() => {
    QueryService.getInstance().setQueryModeFilter(queryMode);

    getAllQueries();
  }, [queryMode]);

  function handleCopyQuery(queryId: string, copiedQueryName: string): void {
    const data: QueryToCopy = {
      query_id: queryId,
      name: copiedQueryName,
    };
    postCopyQueryData({data});
  }

  useEffect(() => {
    if (userPermissions && !userPermissions?.some((permission) => permission === UserPermissions.VIEW)) {
      navigate('/' + Path.DASHBOARD);
    }
  }, [userPermissions]);

  useEffect(() => {
    if (userPermissions && !userPermissions?.some((permission) => permission === UserPermissions.VIEW_PUBLIC_QUERIES)) {
      setQueryMode(QueryMode.PUBLIC);
    }
  }, [userPermissions]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {hasEntityListError && (
            <SpartanSnackbar type={NotificationType.ERROR} message={hasEntityListError.message}></SpartanSnackbar>
          )}
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>
                  {t('query.headline')} - {t(`dashboard.${featureName}`)}
                </PageHeadline>
              </Grid>
              <Grid item xs={12} md={6}>
                <PageTopActions>
                  <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.CREATE]}>
                    <Link to={`/queries/${featureName}/create`}>
                      <Button fullWidth color="secondary" id={'add-btn'} {...{[TestAttributes.BUTTON_NAME]: 'add-btn'}}>
                        {t('query.actions.add')}
                      </Button>
                    </Link>
                  </AccessControl>
                  <AccessControl
                    userPermissions={userPermissions}
                    allowedPermissions={[UserPermissions.VIEW_PUBLIC_QUERIES]}
                  >
                    <FormControl
                      sx={{
                        width: '150px',
                      }}
                    >
                      <Select
                        {...{[TestAttributes.FIELD_NAME]: 'selected-mode'}}
                        id="selected-query-type"
                        value={queryMode}
                        onChange={(e: any) => setQueryMode(e.target.value)}
                      >
                        {getQueryModeList.map((queryModeItem: string) => (
                          <MenuItem
                            key={'selected-item-' + queryModeItem}
                            {...{[TestAttributes.FIELD_OPTION_NAME]: queryModeItem}}
                            id={'menu-item-selected-mode-' + queryModeItem}
                            value={queryModeItem}
                          >
                            {t(getDynamicTranslationKey('query-mode', queryModeItem))}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </AccessControl>
                </PageTopActions>
              </Grid>
            </Grid>
          </PageStickyHeader>

          <Grid item xs={12} sx={{height: '500px'}} {...{[TestAttributes.TABLE_NAME]: 'table-results'}}>
            {isEntityListLoading ? (
              <Skeleton variant="rectangular" height={500} />
            ) : (
              <DataGridCustom
                tableColumns={tableColumns}
                rows={
                  filteredQueries?.map((query: QueryListItem, index: number) => {
                    return {
                      ...query,
                      created_by: query.created_by?.username,
                      last_updated_by: query.last_updated_by?.username,
                      created_at: formatDateTime(query.created_at),
                      actions: query.query_id,
                      id: index,
                    };
                  }) || []
                }
                userPermissions={userPermissions}
              />
            )}
            <Menu
              id="query-list-more-actions-menu"
              keepMounted
              anchorEl={actionsMenuAnchorEl}
              open={Boolean(actionsMenuAnchorEl)}
              onClose={handleMoreActionsMenuClose}
            >
              {selectedQuery && (
                <MenuItem
                  id={'query-list-more-actions-menu-item-view'}
                  {...{[TestAttributes.FIELD_OPTION_NAME]: 'query-list-more-actions-menu-item-view'}}
                  onClick={() => navigate(`/${FeatureName.QUERIES}/${featureName}/${selectedQuery.query_id}`)}
                >
                  {t('shared.view')}
                </MenuItem>
              )}
              <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.COPY]}>
                <MenuItem
                  id={'query-list-more-actions-menu-item-copy'}
                  {...{[TestAttributes.FIELD_OPTION_NAME]: 'query-list-more-actions-menu-item-copy'}}
                  onClick={() => handleQuerySelected()}
                >
                  {t('shared.copy')}
                </MenuItem>
              </AccessControl>
            </Menu>
          </Grid>
        </Grid>
      </Container>
      <CopyQueryDialog
        isOpen={isCopyQueryDialogOpen}
        handleClose={() => setIsCopyQueryDialogOpen(false)}
        selectedQuery={selectedQuery}
        copyQuery={handleCopyQuery}
      />
    </Box>
  );
}
export default QueryListPage;
