import {Box, Grid} from '@mui/material';
import Container from '@mui/material/Container';
import {FormBuilder} from '../../form/FormBuilder';
import React, {useCallback, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import PageHeadline from '../../components/PageHeadline';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName, Path} from '../../../paths';
import {PageStickyHeader} from '../PageStickyHeader';
import {PageTopActions} from '../PageTopActions';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {useFormAPI} from '../../form/FormAPI';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import {onRejectSubmit} from '../../form/errorHandler';
import {TestAttributes} from '../../TestAttributes';
import {Skeleton} from '@mui/lab';
import {LocalPresenceDid, LocalPresenceDids} from '../../model/LocalPresenceDids';
import {HttpMethods} from '../../model/HttpMethods';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {DefaultResponse} from '../../services/model/DefaultResponse';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import {difference} from '../../form/utils';
import {useNavigate} from 'react-router-dom';
import {OnSubmitHandler} from '../../form/model';

function LocalPresenceDidsPage() {
  const {id} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {useAxiosOrganizationAPI} = useAxiosContext();
  const {setLoading} = useLoading();
  const [isDeactivatedModalOpen, setIsDeactivatedModalOpen] = useState<boolean>(false);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.LOCAL_PRESENCE_DIDS);
  const formAPI = useFormAPI();
  const [localPresenceDid, setLocalPresenceDid] = useState<LocalPresenceDids | null>(null);

  const [
    {response: getLocalPresenceDidResponse, loading: isGetDidLoading, error: hasGetLocalDidError},
    getLocalPresenceDid,
  ] = useAxiosOrganizationAPI<LocalPresenceDids>(
    {url: `/${Path.LOCAL_PRESENCE_DIDS}/${id}`, method: HttpMethods.GET},
    {
      manual: true,
    }
  );

  const [
    {response: patchLocalPresenceDidResponse, loading: isPatchDidLoading, error: hasPatchLocalDidError},
    patchLocalPresenceDid,
  ] = useAxiosOrganizationAPI<LocalPresenceDids>(
    {url: `/${Path.LOCAL_PRESENCE_DIDS}/${id}`, method: HttpMethods.PATCH},
    {
      manual: true,
    }
  );

  const [, getLocalPresenceDIDList] = useAxiosOrganizationAPI<ResponseListWrapper<LocalPresenceDids>>(
    {url: `/${Path.LOCAL_PRESENCE_DIDS}`, method: HttpMethods.GET},
    {
      manual: false,
    }
  );

  const [
    {
      response: deleteLocalPresenceDIDResponse,
      loading: isDeleteLocalPresenceDIDLoading,
      error: deleteLocalPresenceDIDError,
      data: deleteLocalPresenceDIDData,
    },
    deleteLocalPresenceDID,
  ] = useAxiosOrganizationAPI<DefaultResponse>(
    {url: `/${Path.LOCAL_PRESENCE_DIDS}/${id}`, method: HttpMethods.DELETE},
    {
      manual: true,
    }
  );

  const [
    {
      error: hasPostLocalPresenceDidError,
      loading: isPostLocalPresenceDidLoading,
      response: postLocalPresenceDidResponse,
    },
    postLocalPresenceDidData,
  ] = useAxiosOrganizationAPI<LocalPresenceDid>(
    {url: `/${Path.LOCAL_PRESENCE_DIDS}`, method: 'POST'},
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (id) {
      getLocalPresenceDid();
    }
  }, [id]);

  useEffect(() => {
    if (getLocalPresenceDidResponse) {
      setLocalPresenceDid(getLocalPresenceDidResponse.data);
    }
  }, [getLocalPresenceDidResponse]);

  useEffect(() => {
    const loading =
      isGetDidLoading || isDeleteLocalPresenceDIDLoading || isPostLocalPresenceDidLoading || isPatchDidLoading;
    setLoading(loading, 'LocalPresenceDidsPage');
  }, [isGetDidLoading, isDeleteLocalPresenceDIDLoading, isPostLocalPresenceDidLoading, isPatchDidLoading]);

  useEffect(() => {
    const entityError =
      deleteLocalPresenceDIDError || hasPostLocalPresenceDidError || hasPatchLocalDidError || hasGetLocalDidError;
    if (entityError) {
      NotificationService.getInstance().sendNotification(entityError?.response?.data?.message, NotificationType.ERROR);
    }
  }, [deleteLocalPresenceDIDError, hasPostLocalPresenceDidError, hasPatchLocalDidError]);

  useEffect(() => {
    if (deleteLocalPresenceDIDResponse?.status === 200) {
      getLocalPresenceDIDList().then(() => {
        NotificationService.getInstance().sendNotification(
          deleteLocalPresenceDIDResponse?.data?.message,
          NotificationType.SUCCESS
        );
        navigate('/' + FeatureName.LOCAL_PRESENCE_DIDS);
      });
    }
  }, [deleteLocalPresenceDIDData]);

  useEffect(() => {
    if (postLocalPresenceDidResponse?.status === 201) {
      getLocalPresenceDIDList().then(() => {
        NotificationService.getInstance().sendNotification(
          postLocalPresenceDidResponse.data?.['message'],
          NotificationType.SUCCESS
        );
        navigate(`/${FeatureName.LOCAL_PRESENCE_DIDS}/${postLocalPresenceDidResponse.data['id']}`);
      });
    }
  }, [postLocalPresenceDidResponse]);

  useEffect(() => {
    if (patchLocalPresenceDidResponse?.status === 200) {
      getLocalPresenceDIDList().then(() => {
        NotificationService.getInstance().sendNotification(
          patchLocalPresenceDidResponse.data?.['message'],
          NotificationType.SUCCESS
        );
      });
    }
  }, [patchLocalPresenceDidResponse]);

  useEffect(() => {
    if (hasPostLocalPresenceDidError) {
      NotificationService.getInstance().sendNotification(
        hasPostLocalPresenceDidError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasPostLocalPresenceDidError]);

  const onSubmit: OnSubmitHandler = useCallback(
    (formData: Partial<LocalPresenceDid>) => {
      if (id) {
        formData = difference(formData, localPresenceDid);
        patchLocalPresenceDid({
          data: {...formData, status: 'active'},
        }).then(() => {
          getLocalPresenceDid();
        });
      } else {
        postLocalPresenceDidData({
          data: {...formData, status: 'active'},
        });
      }
    },
    [localPresenceDid]
  );
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('local-presence-dids.headline')}</PageHeadline>
              </Grid>
              <Grid item xs={12} md={6}>
                <PageTopActions>
                  {id && (
                    <AccessControl
                      userPermissions={userPermissions}
                      allowedPermissions={[UserPermissions.DEACTIVATE_FEATURE_ITEM]}
                    >
                      <ConfirmationDialog
                        message={t('local-presence-dids.delete-message')}
                        headline={t('local-presence-dids.delete-headline')}
                        isDialogOpen={isDeactivatedModalOpen}
                        handleClose={() => setIsDeactivatedModalOpen(false)}
                      >
                        <Button
                          onClick={() => {
                            deleteLocalPresenceDID();
                            setIsDeactivatedModalOpen(false);
                          }}
                          {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
                          id="confirmation-dialog-action-btn"
                        >
                          {t('local-presence-dids.delete-did-button')}
                        </Button>
                      </ConfirmationDialog>

                      <Button
                        {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                        id={'confirmation-dialog-btn'}
                        color="secondary"
                        onClick={() => setIsDeactivatedModalOpen(true)}
                      >
                        {t('local-presence-dids.delete-did-button')}
                      </Button>
                    </AccessControl>
                  )}
                  <AccessControl
                    userPermissions={userPermissions}
                    allowedPermissions={[id ? UserPermissions.MODIFY : UserPermissions.CREATE]}
                  >
                    <Button
                      {...{[TestAttributes.BUTTON_NAME]: 'save-btn'}}
                      id={'save-btn'}
                      onClick={() => formAPI.submit()}
                    >
                      {id ? t('shared.update') : t('shared.save')}
                    </Button>
                  </AccessControl>
                </PageTopActions>
              </Grid>
            </Grid>
          </PageStickyHeader>

          <Grid item xs={12}>
            {isGetDidLoading ? (
              <Skeleton variant="rectangular" height={500} />
            ) : (
              <FormBuilder
                formId={FeatureName.LOCAL_PRESENCE_DIDS}
                api={formAPI}
                initialValues={localPresenceDid}
                onSubmit={onSubmit}
                onRejectSubmit={onRejectSubmit}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default LocalPresenceDidsPage;
