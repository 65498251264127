import BehaviorSubjectService from './common/BehaviorSubjectService';
import {Observable, ReplaySubject} from 'rxjs';
import LocalStorageService from './LocalStorageService';
import {LoggedInCampaign} from '../model/Campaign';

export default class LoggedInCampaignService extends BehaviorSubjectService<LoggedInCampaign> {
  private static INSTANCE = new LoggedInCampaignService();
  private localStorageKey = 'loggedInCampaign';
  private campaign = new ReplaySubject<LoggedInCampaign>(1);
  private emptyCampaign = {campaignName: '', id: ''};

  constructor() {
    super();
    const lastSelectedCampaign = LocalStorageService.getInstance().getObject(this.localStorageKey);
    if (lastSelectedCampaign) {
      this.campaign.next(lastSelectedCampaign as LoggedInCampaign);
    }
  }

  static getInstance(): LoggedInCampaignService {
    return LoggedInCampaignService.INSTANCE;
  }

  setLoggedCampaign(campaignData: LoggedInCampaign): void {
    LocalStorageService.getInstance().setObject(this.localStorageKey, campaignData);
    this.campaign.next(campaignData);
  }

  getLoggedCampaign(): Observable<LoggedInCampaign> {
    return this.campaign.asObservable();
  }

  setEmptyCampaign(): void {
    this.setLoggedCampaign(this.emptyCampaign);
  }
}
