import {Dialog, DialogContent, DialogContentText, DialogTitle, IconButton} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {FileExtensions} from '../../model/FileExtensions';
import CloseIcon from '@mui/icons-material/Close';
import {HttpMethods} from '../../model/HttpMethods';
import {DropzoneWrapper} from '../../form/components/DropzoneWrapper';

interface UploadLeadsDialogProps {
  isOpen: boolean;
  handleClose: Function;
  disclaimer?: string;
  method?: HttpMethods;
  resourceType: string;
  resourceURL?: string;
  resourceCallback?: Function;
}

function UploadLeadsDialog({
  isOpen,
  handleClose,
  method,
  resourceType,
  resourceURL,
  resourceCallback,
}: UploadLeadsDialogProps) {
  const {t} = useTranslation();
  const contentText =
    method === HttpMethods.POST
      ? t(`uploader.upload-conditions.post.${resourceType}`)
      : t(`uploader.upload-conditions.patch.${resourceType}`);

  const notSupportedFileMsg = t(`uploader.file-not-supported`);

  return (
    <>
      <Dialog fullWidth={true} maxWidth={'md'} open={isOpen} onClose={() => handleClose()}>
        <DialogTitle>
          {t('uploader.upload-dialog')}
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DropzoneWrapper
            acceptedExtensions={[FileExtensions.TSV]}
            maxFiles={1}
            method={method}
            resourceType={resourceType}
            resourceURL={resourceURL}
            resourceCallback={resourceCallback}
            notSupportedFileMsg={notSupportedFileMsg}
            importFiles
          />
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default UploadLeadsDialog;
