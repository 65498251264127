import {FormFieldSchema, FormSchema, FormSectionSchema} from '../../logic/FormSchema';
import {traverseSchemaFields, traverseSchemaSections} from '../../logic/utils';

type TransFunction = (key: string, defaultValue?: string | undefined) => string;

// This method has side effects! Use with caution!
export function applyTranslations(schema: FormSchema, formId: string, t: TransFunction): FormSchema {
  traverseSchemaSections(schema, (section) => processSection(section, formId, t));
  traverseSchemaFields(schema, (field) => processField(field, formId, t));
  return schema;
}

function processSection(section: FormSectionSchema, formId: string, t: TransFunction) {
  if (section.section_name) {
    const section_name_sanitized = section.section_name?.replace(/\s+/g, '_').toLowerCase();
    section.section_label = t(`feature.${formId}.section.${section_name_sanitized}`, section.section_label);
  }
}

function processField(field: FormFieldSchema, formId: string, t: TransFunction) {
  field.label = t(`feature.${formId}.field.${field.field_name}`, field.label);
  if (field.options) {
    for (const option of field.options) {
      if (option.value === undefined || option.value === null) continue;
      const sanitizedValue = option.value
        .toString()
        .replaceAll(/\W+/g, '_') // replace all non chars with _
        .replaceAll(/(^_|_$)/g, '') // drop leading/trailing underscores
        .toLowerCase();
      option.label = t(`feature.${formId}.field.${field.field_name}.option.${sanitizedValue}`, option.label);
    }
  }
}
