import {Controller, Message, useFieldArray, useFormContext, useFormState, useWatch} from 'react-hook-form';
import {Alert, Grid, IconButton} from '@mui/material';
import React, {Fragment, ReactElement, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import {FormFieldType} from '../../../form/logic/FormSchema';
import {SpartanFieldOption, SpartanFieldProps} from '../../../form/fields/model';
import {SpartanTextField} from '../../../form/fields/SpartanTextField';
import {SpartanMultiSelectField} from '../../../form/fields/SpartanMultiSelectField';
import {SpartanSelectField} from '../../../form/fields/SpartanSelectField';
import {SpartanNumberField} from '../../../form/fields/SpartanNumberField';
import {Condition, QueryFieldSchema} from '../../../model/Query';
import {ConditionOperator} from '../ConditionOperator';
import {SpartanDateField} from '../../../form/fields/SpartanDateField';
import {SpartanDateTimeField} from '../../../form/fields/SpartanDateTimeField';

import {QueryContext} from '../../../context/QueryContextProvider';
import DateBetweenController from './DateBetweenController';
import {toControlledComponentSimpleValue} from '../../../form/logic/utils';
import {invalidDate, invalidTime, isMissingOrEmpty, isQueryLastConditionValid} from '../utils/queryValidationMethods';
import {SpartanTimeField} from '../../../form/fields/SpartanTimeField';

interface RepeatableSectionValueProps {
  conditionIndex: number;
  conditionField: Condition;
}

function isFieldValueNotRequired(conditionOperator: ConditionOperator) {
  return (
    conditionOperator === ConditionOperator.NOT_NULL ||
    conditionOperator === ConditionOperator.NULL ||
    conditionOperator === ConditionOperator.DATE_YESTERDAY ||
    conditionOperator === ConditionOperator.TODAY
  );
}

function toOptions(conditionFieldSchema: QueryFieldSchema | any): SpartanFieldOption[] {
  const options = conditionFieldSchema?.select_options ? conditionFieldSchema.select_options : [];
  return options as SpartanFieldOption[];
}

function isArrayOfBooleans(conditionFieldSchema: QueryFieldSchema | any): boolean {
  const options = toOptions(conditionFieldSchema);
  if (options.length) {
    // Ignore type warning on next line, SpartanFieldOption.value is actually of type `any` (will be fixed in another branch)
    return options.every((opt) => typeof opt.value === 'boolean');
  }
  return false;
}

function QueryConditionValue({conditionField, conditionIndex}: RepeatableSectionValueProps) {
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [allowMultipleConditions, setAllowMultipleConditions] = useState<boolean>(true);
  const [conditionFieldSchema, setConditionFieldSchema] = useState<QueryFieldSchema | undefined>(undefined);
  const {t} = useTranslation();
  const [firstConditionType, setFirstConditionType] = useState<ConditionOperator>();
  const [isAddConditionDisabled, setIsAddConditionDisabled] = useState<boolean>(true);
  const requiredErrorMsj = t('query.error-messages.required-value');
  const nDaysErrorMsj = t('query.error-messages.n-days-error');
  const queryContextValue = useContext(QueryContext);
  const {control, setValue, getValues, formState} = useFormContext();
  const queryConditions = getValues('query_conditions');
  const {isDirty, isValid} = useFormState({control});
  const {fields, append, remove} = useFieldArray({
    control,
    name: `query_conditions.${conditionIndex}.values`,
  });

  const currentConditionType = useWatch({control, name: `query_conditions.${conditionIndex}.condition_type`});
  const watchConditions = useWatch({control, name: `query_conditions.${conditionIndex}.values`});
  useEffect(() => {
    const conditionOperator = currentConditionType;
    setFirstConditionType(conditionOperator);
  }, []);

  useEffect(() => {
    const currentConditionField = queryContextValue?.fields?.find(
      (field) => field.field_id === conditionField.field_id
    );
    setConditionFieldSchema(currentConditionField);
    // if currentConditionField is a select with an array of booleans, then we do no allow multiple conditions
    const arrayOfBooleans = isArrayOfBooleans(currentConditionField);
    const notRequired = isFieldValueNotRequired(currentConditionType);
    const isArrayOfBooleansOrNotRequired = arrayOfBooleans || notRequired;
    setAllowMultipleConditions(!isArrayOfBooleansOrNotRequired);
  }, [queryContextValue]);

  useEffect(() => {
    const notRequired = isFieldValueNotRequired(currentConditionType);
    setIsRequired(!notRequired);

    if (firstConditionType && currentConditionType) {
      if (notRequired) {
        setValue(`query_conditions.${conditionIndex}.values`, [{value: ''}], {shouldTouch: false});
        setAllowMultipleConditions(false);
      } else {
        if (currentConditionType === ConditionOperator.DATE_BETWEEN) {
          setValue(`query_conditions.${conditionIndex}.values`, [{from: '', to: ''}]);
        } else {
          setValue(`query_conditions.${conditionIndex}.values`, [{value: ''}], {shouldTouch: true});
        }
        setAllowMultipleConditions(true);
      }
    }
  }, [currentConditionType]);

  useEffect(() => {
    const isConditionValid: boolean = isQueryLastConditionValid(formState, queryConditions);
    const isFormValid = isConditionValid ? false : isDirty && !isValid;
    setIsAddConditionDisabled(isFormValid);
  }, [formState, isDirty, isValid, queryConditions, watchConditions]);

  function validateInput(value: any, conditionOperator: ConditionOperator): Message | Message[] | boolean | undefined {
    if (isRequired && isMissingOrEmpty(value)) {
      return requiredErrorMsj;
    } else if (value === invalidDate) {
      return invalidDate;
    } else if (value === invalidTime) {
      return invalidTime;
    } else if (
      (conditionOperator === ConditionOperator.LAST_N_DAYS || conditionOperator === ConditionOperator.N_DAYS_AGO) &&
      (value < 1 || value > 1820)
    ) {
      return nDaysErrorMsj;
    } else {
      return undefined;
    }
  }

  function timeStampType(conditionType: string, spartanFieldProps: SpartanFieldProps): ReactElement {
    switch (conditionType) {
      case ConditionOperator.LAST_N_DAYS:
      case ConditionOperator.N_DAYS_AGO:
        return <SpartanNumberField {...spartanFieldProps} />;
      case ConditionOperator.TO_YESTERDAY:
        return <SpartanTimeField {...spartanFieldProps} />;

      default:
        return <SpartanDateTimeField {...spartanFieldProps} />;
    }
  }

  return (
    <>
      <Grid container item xs={8} spacing={3} alignItems={'center'}>
        {fields.map((item, valueIndex) => (
          <Fragment key={item.id}>
            <Grid item xs={10}>
              {currentConditionType === ConditionOperator.DATE_BETWEEN ? (
                <DateBetweenController
                  conditionIndex={conditionIndex}
                  valueIndex={valueIndex}
                  conditionFieldSchema={conditionFieldSchema}
                />
              ) : (
                <Controller
                  render={({field: controllerField, fieldState}) => {
                    const error = fieldState.error ? fieldState.error.message : undefined;
                    const hasError = !!error;

                    function onFocus(e: any): void {}

                    function onChange(e: any): void {
                      let value = e.target.value;
                      if (typeof value === 'boolean') {
                        setAllowMultipleConditions(false);
                        setValue(`query_conditions.${conditionIndex}.values`, [{value}]);
                      } else {
                        controllerField.onChange(e);
                      }
                    }

                    const spartanFieldProps: SpartanFieldProps = {
                      onChange: onChange,
                      onFocus: onFocus,
                      onBlur: controllerField.onBlur,
                      id: 'condition-field-' + conditionFieldSchema?.field_name,
                      label: valueIndex === 0 ? '' : `${t('query.or-value')}`,
                      name: conditionFieldSchema?.label_search || 'value',
                      value: toControlledComponentSimpleValue(controllerField.value),
                      hasError: hasError,
                      error: error,
                      isNestedLabel: true,
                    };

                    if (!isRequired) {
                      return <></>;
                    }

                    switch (conditionFieldSchema?.field_type) {
                      case FormFieldType.ZIPCODE:
                      case FormFieldType.PHONE:
                      case FormFieldType.EMAIL:
                      case FormFieldType.TEXT:
                      case FormFieldType.TEXTAREA:
                      case FormFieldType.AUTO_ADDRESS:
                        return <SpartanTextField {...spartanFieldProps} />;

                      case FormFieldType.SELECTMULTIPLE:
                        return (
                          <SpartanMultiSelectField {...spartanFieldProps} options={toOptions(conditionFieldSchema)} />
                        );

                      case FormFieldType.SELECT:
                        return <SpartanSelectField {...spartanFieldProps} options={toOptions(conditionFieldSchema)} />;

                      case FormFieldType.CURRENCY:
                      case FormFieldType.NUMBER:
                      case FormFieldType.FLOAT:
                        return <SpartanNumberField {...spartanFieldProps} />;

                      case FormFieldType.DATE:
                        return currentConditionType === ConditionOperator.LAST_N_DAYS ||
                          currentConditionType === ConditionOperator.N_DAYS_AGO ? (
                          <SpartanNumberField {...spartanFieldProps} />
                        ) : (
                          <SpartanDateField {...spartanFieldProps} />
                        );

                      case FormFieldType.TIMESTAMP:
                        return timeStampType(currentConditionType, spartanFieldProps);

                      default:
                        return (
                          <Alert severity="warning">
                            {t('shared.no-supported-field-type')} {conditionFieldSchema?.field_type}
                          </Alert>
                        );
                    }
                  }}
                  name={`query_conditions.${conditionIndex}.values.${valueIndex}.value`}
                  control={control}
                  rules={{
                    validate: (value) => validateInput(value, currentConditionType),
                  }}
                />
              )}
            </Grid>

            {allowMultipleConditions && (
              <Grid item xs={2}>
                <IconButton
                  id={`remove-condition-value-${valueIndex}-btn`}
                  color={'primary'}
                  aria-label="remove value"
                  disabled={fields.length <= 1}
                  component="button"
                  onClick={() => remove(valueIndex)}
                  size="large"
                >
                  <IndeterminateCheckBoxRoundedIcon />
                </IconButton>
              </Grid>
            )}
          </Fragment>
        ))}
      </Grid>

      <Grid item display={'flex'} alignSelf={'flex-end'}>
        {allowMultipleConditions && (
          <IconButton
            id={`add-condition-${conditionIndex}-btn`}
            color={'primary'}
            aria-label="add condition"
            component="button"
            disabled={isAddConditionDisabled}
            onClick={() => {
              append({value: ''});
            }}
            size="large"
          >
            <AddBoxRoundedIcon />
          </IconButton>
        )}
      </Grid>
    </>
  );
}

export default React.memo(QueryConditionValue);
