import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Box, Button, Container, Grid} from '@mui/material';
import PageHeadline from '../../components/PageHeadline';
import {PageTopActions} from '../PageTopActions';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName, Path} from '../../../paths';
import {PageStickyHeader} from '../PageStickyHeader';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import Typography from '@mui/material/Typography';
import {useParams} from 'react-router';
import {CallScriptCondition} from './CallScriptCondition';
import {useAxiosContext} from '../../context/AxiosContext';

function CallScriptManagerConditionsPage() {
  const {useAxiosFormAdminAPI} = useAxiosContext();
  const {t} = useTranslation();
  const {id} = useParams();

  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.USER_GUIDE);

  const [{data: getConditions}] = useAxiosFormAdminAPI<ResponseListWrapper<CallScriptCondition>>(
    `/${Path.CONDITIONAL_RULES}?resource_type=user_guide&resource_id=${id}`
  );

  useEffect(() => {}, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('call-script-page.headline')} Conditions</PageHeadline>
              </Grid>
              <Grid item xs={12} md={6}>
                <PageTopActions>
                  <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.CREATE]}>
                    <Link to={`/${Path.USER_GUIDE}/create`}>
                      <Button fullWidth color="secondary" id={'add-btn'}>
                        {t('globalActions.addButton')}
                      </Button>
                    </Link>
                  </AccessControl>
                </PageTopActions>
              </Grid>
            </Grid>
          </PageStickyHeader>
          <Grid item xs={12}>
            <Typography>A</Typography>

            {getConditions?.results.map((condition) => {
              return (
                <div>
                  <Typography>{condition?.rule_type}</Typography>
                  <Typography>{condition?.disabled}</Typography>
                </div>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default CallScriptManagerConditionsPage;
