import React from 'react';
import SystemSettingsPageContainer from '../SystemSettingsPageContainer';
import {SystemSettingsPages} from '../model/SysPageInfo';
import FormEditorPageComponent from './FormEditorPageComponent';

export default function SystemSettingsFormEditorPage() {
  return (
    <SystemSettingsPageContainer page={SystemSettingsPages.FORM_EDITOR}>
      <FormEditorPageComponent />
    </SystemSettingsPageContainer>
  );
}
