import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAuth0} from '@auth0/auth0-react';
import {Button, Grid, IconButton, Tooltip, Typography} from '@mui/material';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import {StringKeyObject} from '../state/FormState';
import {buildSetValue, SpartanTextFieldProps} from './model';
import {SpartanTextField} from './SpartanTextField';
import FilesService from '../../services/FilesService';
import {CalcTimeBetweenCallsProps, CampaignDataSource} from '../../model/Campaign';
import {Auth0User} from '../../model/Auth0User';
import {DefaultResponse} from '../../services/model/DefaultResponse';
import FieldHelpDialog from '../../components/dialogs/FieldHelpDialog';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {FeatureName} from '../../../paths';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

interface SpartanTBCFieldProps extends SpartanTextFieldProps {
  formValues?: StringKeyObject<any>;
}
export const SpartanTBCField = ({InputProps, formValues, ...props}: SpartanTBCFieldProps) => {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const auth0 = useAuth0<Auth0User>();
  const {setLoading} = useLoading();
  const [calcTimeBetweenCallsInfo, setCalcTimeBetweenCallsInfo] = useState<CalcTimeBetweenCallsProps | null>(null);
  const [isFieldHelpDialogModalOpen, setIsFieldHelpDialogModalOpen] = useState<boolean>(false);
  const [isConfirmationDialogModalOpen, setIsConfirmationDialogModalOpen] = useState<boolean>(false);
  const [timeBetweenCallsRecommended, setTimeBetweenCallsRecommended] = useState<number>(0);
  const [isCalcTimeBetweenCallsButtonDisabled, setIsCalcTimeBetweenCallsButtonDisabled] = useState<boolean>(true);
  const setValue = buildSetValue(props);
  const onChange = (event: any) => setValue(parseFloat(event.target.value));

  const [
    {response: calcTimeBetweenCallsResponse, loading: calcTimeBetweenCallsLoader, error: calcTimeBetweenCallsError},
    calcTimeBetweenCalls,
  ] = useAxiosBFF<DefaultResponse>(
    {
      url: `/${FeatureName.CAMPAIGNS}`,
      method: 'GET',
      params: {
        ...{calculate_cps: 'true'},
        ...calcTimeBetweenCallsInfo,
      },
    },
    {manual: true}
  );

  useEffect(() => {
    if (
      formValues?.source_data &&
      formValues?.start_timestamp &&
      formValues?.end_timestamp &&
      ((formValues?.source_data === CampaignDataSource.TSV && formValues?.filename) ||
        (formValues?.source_data === CampaignDataSource.QUERY && formValues?.queries))
    ) {
      setIsCalcTimeBetweenCallsButtonDisabled(false);
    } else {
      setIsCalcTimeBetweenCallsButtonDisabled(true);
    }
  }, [formValues]);
  useEffect(() => {
    if (calcTimeBetweenCallsInfo) {
      calcTimeBetweenCalls();
    }
  }, [calcTimeBetweenCallsInfo]);

  useEffect(() => {
    setLoading(calcTimeBetweenCallsLoader, `SpartanTBCField/${props.id}`);
  }, [calcTimeBetweenCallsLoader, props.id]);

  useEffect(() => {
    if (calcTimeBetweenCallsResponse) {
      if (calcTimeBetweenCallsResponse?.status === 200) {
        setTimeBetweenCallsRecommended(calcTimeBetweenCallsResponse?.data?.['time']);
        setIsConfirmationDialogModalOpen(true);
      }
    }
  }, [calcTimeBetweenCallsResponse]);
  useEffect(() => {
    if (calcTimeBetweenCallsError) {
      NotificationService.getInstance().sendNotification(
        calcTimeBetweenCallsError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [calcTimeBetweenCallsError]);
  async function calcTimeBetweenCall() {
    let calcProps = {};
    switch (formValues?.source_data as CampaignDataSource) {
      case CampaignDataSource.TSV:
        if (formValues?.campaign_id) {
          calcProps = {filename: formValues?.filename, campaign_id: formValues?.campaign_id};
        } else {
          const leadCount = await FilesService.with(auth0).getTSVFileCount(formValues?.filename);
          calcProps = {lead_count: leadCount};
        }
        break;
      case CampaignDataSource.QUERY:
        calcProps = {query_id: formValues?.queries};
        break;
    }
    const calcTimeBetweenCallsProps: CalcTimeBetweenCallsProps = {
      source_data: formValues?.source_data,
      start_timestamp: formValues?.start_timestamp,
      end_timestamp: formValues?.end_timestamp,
      ...calcProps,
    };
    setCalcTimeBetweenCallsInfo(calcTimeBetweenCallsProps);
  }

  return (
    <>
      <Grid container spacing={1} justifyContent="flex-start" alignItems="flex-end">
        <Grid item xs={8} md={6} lg={4}>
          <SpartanTextField {...props} onChange={onChange} InputProps={{...InputProps, type: 'number'}} />
        </Grid>
        <Grid item>
          <Tooltip
            title={
              isCalcTimeBetweenCallsButtonDisabled ? <Typography>{t('tbc.calc-btn-tooltip-msg')} </Typography> : ''
            }
            placement="top-start"
          >
            <span>
              <Button id={'calc-btn'} onClick={calcTimeBetweenCall} disabled={isCalcTimeBetweenCallsButtonDisabled}>
                {t('tbc.calc-btn')}
              </Button>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <IconButton
            size="small"
            id="tbc-support"
            onClick={() => {
              setIsFieldHelpDialogModalOpen(true);
            }}
          >
            <ContactSupportIcon />
          </IconButton>
        </Grid>
      </Grid>
      <FieldHelpDialog
        isOpen={isFieldHelpDialogModalOpen}
        handleClose={() => setIsFieldHelpDialogModalOpen(false)}
        title={t('tbc.headline')}
        supportInfo={[t('tbc.dialog-1'), t('tbc.dialog-2'), t('tbc.dialog-3')]}
      />
      <ConfirmationDialog
        message={`${t('tbc.message')}${timeBetweenCallsRecommended}`}
        headline={t('tbc.headline')}
        isDialogOpen={isConfirmationDialogModalOpen}
        handleClose={() => setIsConfirmationDialogModalOpen(false)}
      >
        <Button
          onClick={() => {
            setValue(timeBetweenCallsRecommended);
            setIsConfirmationDialogModalOpen(false);
          }}
          id="confirmation-dialog-action-btn"
        >
          {t('shared.accept')}
        </Button>
      </ConfirmationDialog>
    </>
  );
};
