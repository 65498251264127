import jsPDF from 'jspdf';
import moment from 'moment';
import i18next from 'i18next';
import {FormFieldSchema, FormFieldSize, FormSectionSchema} from '../../../form/logic/FormSchema';
import {Lead} from '../../../model/Lead';
import {orderBy} from 'lodash';
import {OrganizationName} from '../../../model/Organization';

export interface SimpleSection {
  fields: Array<FormFieldSchemaPDF>;
  title: string;
}

export interface FormFieldSchemaPDF {
  label: string;
  size: FormFieldSize;
  value: any;
}

export default function GetConsentByOrg(organization: string): string {
  let consent: string;

  switch (organization) {
    case OrganizationName.PBC:
      consent = i18next.t('leads.pbc-consent-message');
      break;
    default:
      consent = '';
  }
  return consent;
}

function getPdfFields(sections: Array<FormSectionSchema>, values: Lead): Array<SimpleSection> {
  const filterSection: Array<SimpleSection> = sections?.map((section: FormSectionSchema) => {
    const fields = orderBy(section?.fields, 'index', 'asc')?.filter((field: FormFieldSchema) => {
      return field.in_pdf;
    });
    return {
      title: section.pdf_label ?? '',
      fields: fields
        ? fields?.map((field: FormFieldSchema) => {
            return {
              label: field.pdf_label ?? '',
              size: field.size,
              value: values[field.field_name] ? values[field.field_name] : '',
            };
          })
        : [],
    };
  });

  return filterSection?.filter((section: SimpleSection) => {
    return section?.fields?.length > 0;
  });
}

function setSignatureOnDocument(
  doc: jsPDF,
  titlePosX: number,
  y: number,
  linePosX1: number,
  linePosX2: number,
  signature: string,
  infoPosX: number
): number {
  const sigDate = moment(new Date(), 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY');
  doc.setFont('Helvetica', 'bold');
  doc.setFontSize(12);
  doc.text('SIGNATURE', titlePosX, y, {align: 'right'});
  doc.setDrawColor('green');
  doc.line(linePosX1, y - 1, linePosX2, y - 1);
  y += 5;
  doc.addImage(signature, 'PNG', infoPosX, y, 0, 0);
  y = 220;
  doc.setFontSize(10);
  doc.setFont('Helvetica', 'bold');
  doc.text('Date:', infoPosX, y);
  const width = doc.getTextWidth('Date');
  doc.setFont('Helvetica', 'basic');
  doc.setFontSize(10);
  doc.text(sigDate, infoPosX + width + 2, y);
  return y;
}

function setConsentOnDocument(
  y: number,
  doc: jsPDF,
  titlePosX: number,
  linePosX1: number,
  linePosX2: number,
  organization: string,
  infoPosX: number
) {
  y = 225;
  doc.setFont('Helvetica', 'bold');
  doc.setFontSize(12);
  doc.text('CONSENT', titlePosX, y, {align: 'right'});
  doc.setDrawColor('green');
  doc.line(linePosX1, y - 1, linePosX2, y - 1);
  y = 232;
  doc.setFont('Helvetica', 'basic');
  doc.setFontSize(9);
  doc.text(GetConsentByOrg(organization), infoPosX, y, {maxWidth: 158, align: 'justify'});
  doc.setLineWidth(4.8);
  doc.setDrawColor('green');
  doc.line(0, 2, 216, 2);
  doc.line(0, 277, 216, 277);
}

function setFieldsOnDocument(
  pdfFields: Array<SimpleSection>,
  doc: jsPDF,
  titlePosX: number,
  linePosX1: number,
  linePosX2: number,
  y: number,
  infoPosX: number
): number {
  pdfFields?.forEach((section: SimpleSection) => {
    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(12);
    doc.setLineWidth(1.0);
    const title = doc.splitTextToSize(section?.title.toUpperCase(), 30);
    doc.text(title, titlePosX, y, {align: 'right'});
    doc.setDrawColor('green');
    doc.line(linePosX1, y - 1, linePosX2, y - 1);
    y += 5;

    section?.fields?.forEach((field: FormFieldSchemaPDF) => {
      y += 5;
      doc.setFontSize(10);
      doc.setFont('Helvetica', 'bold');
      const width = doc.getTextWidth(field?.label);
      doc.text(`${field?.label}:`, infoPosX, y);
      doc.setFont('Helvetica', 'basic');
      doc.setFontSize(10);
      doc.text(field?.value.toString(), infoPosX + width + 2, y);
    });

    y += 10;
  });
  return y;
}

export function generatePdf(
  pdfName: string,
  sections: Array<FormSectionSchema>,
  values: Lead,
  header: any,
  signature: string,
  organization: string
) {
  let y: number = 64;
  const infoPosX: number = 50;
  const linePosX1: number = 50;
  const linePosX2: number = 216;
  const titlePosX: number = 45;

  const pdfFields = getPdfFields(sections, values);

  const doc = new jsPDF({
    orientation: 'p',
    format: 'letter',
  });
  y = setFieldsOnDocument(pdfFields, doc, titlePosX, linePosX1, linePosX2, y, infoPosX);

  if (header) doc.addImage(header, 'PNG', 0, 0, 0, 0);

  if (signature) {
    y = setSignatureOnDocument(doc, titlePosX, y, linePosX1, linePosX2, signature, infoPosX);
  }

  setConsentOnDocument(y, doc, titlePosX, linePosX1, linePosX2, organization, infoPosX);

  doc.save(`${pdfName}.pdf`);
}
