import FieldsApiClient from '../services/api/FieldsApiClient';
import {DynamicSourceType} from '../form/logic/FormSchema';
import {pushIfAbsent} from '../form/utils';
import {SpartanFieldOption} from '../form/fields/model';

export interface DynamicOptionsField {
  dynamic_type?: DynamicSourceType;
  dynamic_source?: string;
  select_options?: SpartanFieldOption[];
}

export class DynamicOptionsService {
  constructor(private fieldsApiClient: FieldsApiClient) {}

  async resolveDynamicOptions<T extends DynamicOptionsField>(fields: T[]): Promise<T[]> {
    // step 1. collect all distinct dynamic_sources (as string array) to prevent duplicate requests
    const dynamic_sources: string[] = [];
    fields.forEach((field) => {
      if (field.dynamic_source && field.dynamic_type === DynamicSourceType.ONDEMAND) {
        pushIfAbsent(dynamic_sources, field.dynamic_source);
      }
    });

    // step 2. fetch all dynamic_sources from BE
    const dynamic_options_by_source = await this.fieldsApiClient.getDynamicOptionsInParallel(dynamic_sources);

    // step 3. replace field options with dynamic_options fetched from BE
    const resolved_fields = fields.map((field) => {
      if (
        field.dynamic_source &&
        field.dynamic_type === DynamicSourceType.ONDEMAND &&
        dynamic_options_by_source.has(field.dynamic_source)
      ) {
        return {...field, select_options: dynamic_options_by_source.get(field.dynamic_source)};
      }
      return field;
    });

    return resolved_fields;
  }
}
