import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {PartialUserSettings, UserSettings} from '../model/UserSettings';
import {useTranslation} from 'react-i18next';
import UserSettingsLegacy from './UserSettingsLegacy';

const defaultSettings: UserSettings = {
  preferred_language: UserSettingsLegacy.preferred_language || 'en',
  theme_palette_mode: UserSettingsLegacy.theme_palette_mode || 'light',
  show_chat: UserSettingsLegacy.show_chat || false,
  grid_density: UserSettingsLegacy.grid_density || 'standard',
};

export const UserSettingsProvider = ({children}: PropsWithChildren) => {
  const {isAuthenticated, getAccessTokenSilently} = useAuth0();
  const {i18n} = useTranslation();
  const [userSettings, setUserSettings] = useState<UserSettings>(defaultSettings);
  const [partialUserSettings, setPartialUserSettings] = useState<PartialUserSettings | undefined>();

  useEffect(() => {
    const us = window.localStorage.getItem('user_settings');
    applyUserSettings(us ? {...userSettings, ...JSON.parse(us)} : userSettings);
  }, []);

  useEffect(() => {
    window.localStorage.setItem('user_settings', JSON.stringify(userSettings));
  }, [userSettings]);

  useEffect(() => {
    if (!isAuthenticated) return;
    const controller = new AbortController();
    getAccessTokenSilently()
      .then((token) =>
        fetch('/api/user/v2/user-settings', {
          signal: controller.signal,
          headers: {authorization: `bearer ${token}`},
        })
      )
      .then((response) => response.json())
      .then((data) => applyUserSettings(data))
      .catch((error) => console.warn('Cannot load user settings', error));
    return () => controller.abort();
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (!partialUserSettings || Object.keys(partialUserSettings).length === 0) return;
    const controller = new AbortController();
    getAccessTokenSilently()
      .then((token) =>
        fetch('/api/user/v2/user-settings', {
          signal: controller.signal,
          method: 'PATCH',
          body: JSON.stringify(partialUserSettings),
          headers: {authorization: `bearer ${token}`, 'content-type': 'application/json'},
        })
      )
      .then((response) => setPartialUserSettings(undefined))
      .catch((error) => console.warn('Cannot update user settings', error));
    return () => controller.abort();
  }, [isAuthenticated, getAccessTokenSilently, partialUserSettings]);

  useEffect(() => {
    applyUserSettings(partialUserSettings);
  }, [partialUserSettings]);

  function applyUserSettings(settings: PartialUserSettings | undefined) {
    if (settings === undefined) return;
    if (settings.preferred_language !== undefined) {
      i18n.changeLanguage(settings.preferred_language);
    }
    setUserSettings({...userSettings, ...settings});
  }

  return (
    <UserSettingsContext.Provider
      value={{
        userSettings,
        setPartialUserSettings,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export interface UserSettingsContextInterface {
  userSettings: UserSettings;
  setPartialUserSettings: (settings: PartialUserSettings) => void;
}

const UserSettingsContext = createContext<UserSettingsContextInterface>({
  userSettings: defaultSettings,
  setPartialUserSettings: (settings: PartialUserSettings) => {},
});

export function useUserSettingsContext(): UserSettingsContextInterface {
  return useContext(UserSettingsContext);
}
