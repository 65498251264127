import {UserPermissions} from '../../../components/shared/AccessControl';
import {useUserCampaigns} from './UseUserCampaigns';
import CampaignsCard from './CampaignsCard';
import {useTranslation} from 'react-i18next';
import {Auth0User, isPowerOrg} from '../../../model/Auth0User';
import {useAuth0} from '@auth0/auth0-react';

interface CampaignsCardProps {
  userPermissions: UserPermissions[];
}

function CampaignsCards({userPermissions}: CampaignsCardProps) {
  const {t} = useTranslation();
  const {user} = useAuth0<Auth0User>();
  const isPowerUser = isPowerOrg(user);
  const userCampaigns = useUserCampaigns({userPermissions, isPowerUser});
  return (
    <>
      <CampaignsCard
        title={t('dashboard.campaign.name')}
        campaigns={(isPowerUser ? userCampaigns?.power_campaigns : userCampaigns?.predictive_campaigns) ?? []}
        userPermissions={userPermissions}
      />
      {isPowerUser && (
        <CampaignsCard
          title={t('dashboard.campaign.after-hours')}
          campaigns={userCampaigns?.after_hours ?? []}
          userPermissions={userPermissions}
        />
      )}
    </>
  );
}

export default CampaignsCards;
