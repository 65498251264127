import {Dialog, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface FieldHelpDialogProps {
  isOpen: boolean;
  handleClose: Function;
  title: string;
  supportInfo: Array<string>;
}

function FieldHelpDialog({isOpen, handleClose, supportInfo, title}: FieldHelpDialogProps) {
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={isOpen}
        onClose={() => handleClose()}
        aria-labelledby="field-help-dialog-title"
        aria-describedby="support-dialog-emailInfo support-dialog-secondInfo support-dialog-subject support-dialog-msg"
        sx={{textAlign: 'justify'}}
      >
        <HelpOutlineIcon color="primary" sx={{fontSize: 70, margin: 'auto'}} />
        <DialogTitle sx={{textAlign: 'center'}} id="field-help-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent sx={{width: '100%', padding: (theme) => ` ${theme.spacing(2)}`}}>
          {supportInfo?.map((info: string, index: number) => (
            <DialogContentText
              sx={{width: '100%', padding: (theme) => ` ${theme.spacing(2)}`}}
              id={`support-dialog-fieldInfo-${index}`}
              key={`support-dialog-fieldInfo-${index}`}
            >
              {info}
            </DialogContentText>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
}
export default FieldHelpDialog;
