import React, {ReactNode, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import {useTranslation} from 'react-i18next';
import {Box, Button, Container, Grid, InputLabel, styled, TextField} from '@mui/material';
import {
  Auth0User,
  getUserExtension,
  getUserId,
  getUsername,
  getUserPhoneNumber,
  getUserSmsDid,
} from '../../model/Auth0User';
import {Lead} from '../../model/Lead';
import {useFormAPI} from '../../form/FormAPI';
import LeadEditor from '../lead/LeadEditor';
import {Campaign, CampaignType, TypeCallSource, TypeSourceNumber} from '../../model/Campaign';
import {CALL_NOW_CAMPAIGN_PREFIX, replaceScriptPlaceholders} from '../../services/CampaignService';
import {Call} from '../../model/Call';
import {Organization} from '../../model/Organization';
import OrganizationService from '../../services/OrganizationService';
import {FeatureName, Path} from '../../../paths';
import LeadService from '../../services/LeadService';
import {Sms} from '../../model/Sms';
import {DefaultResponse} from '../../services/model/DefaultResponse';
import UserGuideStepper from '../../components/user-guide/UserGuideStepper';
import {AutoAction} from '../../model/AutoAction';
import {CampaignScript, CampaignScriptContent} from '../../model/CampaignScript';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import LoggedInCampaignService from '../../services/LoggedInCampaignService';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {AxiosResponse} from 'axios';
import {HttpMethods} from '../../model/HttpMethods';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

export const FixedCampaignScriptSms = styled('div')(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    position: 'fixed',
    width: '30%',
    fontFamily: 'Quicksand',
  },
}));

export enum CallType {
  PREDICTIVE = 'callpredictive',
  CALLNOW = 'callnow',
}

function CampaignViewPage() {
  const {useAxiosBFF} = useAxiosContext();
  const {t} = useTranslation();
  const {campaignId} = useParams();
  const formAPI = useFormAPI();
  const auth0 = useAuth0<Auth0User>();
  const navigate = useNavigate();
  const {setLoading} = useLoading();
  const [smsToSend, setSmsToSend] = useState<string>('');
  const location: any = useLocation();
  const [burned, setBurned] = useState<boolean>(false);
  const [campaign, setCampaign] = useState<Campaign>();
  const [lead, setLead] = useState<Lead | null>(null);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [source, setSource] = useState<string>('');
  const [callerSource, setCallerSource] = useState<string>('');
  const isCallNowQueue = campaignId?.startsWith(CALL_NOW_CAMPAIGN_PREFIX);
  const [campaignScript, setCampaignScript] = useState<CampaignScriptContent | null>();
  const [guideId, setGuideId] = useState<string>();
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.LEADS);
  const [prevLocation, setPrevLocation] = useState<string>('');
  const [requestLead, setRequestLead] = useState<boolean>(false);

  const [{response: userGuideResponse, loading: getScriptLoading}, getCampaignScriptData] = useAxiosBFF<CampaignScript>(
    `/${FeatureName.USER_GUIDE}/${guideId}`,
    {manual: true}
  );
  const [{loading: getAutoActionLoading}, getAutoAction] = useAxiosBFF<AutoAction>(
    {
      url: `/${FeatureName.AUTO_ACTIONS}`,
      method: 'GET',
    },
    {manual: true}
  );

  const FixedCampaignScript = styled('div')(({theme}) => ({
    fontFamily: 'Quicksand',
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      overflowY: 'scroll',
      top: isCallNowQueue ? '200px' : '400px',
      bottom: '90px',
    },
    [theme.breakpoints.up('lg')]: {
      position: 'fixed',
      overflowY: 'scroll',
      top: '200px',
      bottom: '90px',
    },
  }));

  const [{response: campaignDataResponse, error: campaignDataError, loading: getCampaignLoading}, getCampaignData] =
    useAxiosBFF<Campaign>(
      {
        url: `/${FeatureName.CAMPAIGNS}/${campaignId}`,
        method: 'GET',
        params: {formatted: false},
      },
      {manual: true}
    );

  const [
    {response: getCampaignLeadResponse, error: getCampaignLeadError, loading: requestNextLeadLoading},
    requestNextLead,
  ] = useAxiosBFF<Lead>(
    {
      url: `outputs`,
      method: HttpMethods.GET,
      params: {
        campaign_id: campaign?.campaign_id,
        duplicates: campaign?.include_duplicates,
        campaign_type: campaign?.campaign_type,
        dial_attempts: campaign?.campaign_type === CampaignType.CALL ? campaign?.dial_attempts : 0,
      },
    },
    {manual: true}
  );

  const [{error: hasGetLeadByIdError, loading: isGetLeadsByIdLoading}, getLeadsById] = useAxiosBFF<Lead>(
    {method: HttpMethods.GET},
    {manual: true}
  );

  const [{loading: isMakeCallLoading}, makeCall] = useAxiosBFF(
    {
      method: HttpMethods.POST,
    },
    {manual: true}
  );

  const [{error: hasSendSmsError, loading: isSendSmsLoading}, sendSms] = useAxiosBFF(
    {
      url: 'sms/',
      method: HttpMethods.POST,
    },
    {manual: true}
  );

  const [{error: hasUpdateLeadError, loading: isUpdateLeadLoading}, updateLead] = useAxiosBFF(
    {
      method: HttpMethods.PATCH,
    },
    {manual: true}
  );

  const [{error: hasCreateLeadError, loading: isCreateLeadLoading}, createLead] = useAxiosBFF(
    {
      method: HttpMethods.POST,
      url: `${FeatureName.LEADS}`,
    },
    {manual: true}
  );

  const [
    {response: postBurnCampaignLeadResponse, error: postBurnCampaignLeadError, loading: burnLeadLoading},
    burnCampaignLead,
  ] = useAxiosBFF<DefaultResponse>({url: `burners/`, method: 'POST'}, {manual: true});

  useEffect(() => {
    const loading =
      isSendSmsLoading ||
      isGetLeadsByIdLoading ||
      isUpdateLeadLoading ||
      isCreateLeadLoading ||
      isMakeCallLoading ||
      burnLeadLoading ||
      requestNextLeadLoading ||
      getCampaignLoading ||
      getScriptLoading ||
      getAutoActionLoading;
    setLoading(loading, 'CampaignViewPage');
  }, [
    isSendSmsLoading,
    isGetLeadsByIdLoading,
    isUpdateLeadLoading,
    isCreateLeadLoading,
    isMakeCallLoading,
    burnLeadLoading,
    requestNextLeadLoading,
    getCampaignLoading,
    getScriptLoading,
    getAutoActionLoading,
  ]);

  useEffect(() => {
    if (userGuideResponse) {
      const theScript: CampaignScriptContent = JSON.parse(userGuideResponse?.data?.guide_content.toString());
      setCampaignScript(theScript);
    }
  }, [userGuideResponse]);

  useEffect(() => {
    const subscription = OrganizationService.getInstance()
      .getOrganization()
      .subscribe((org) => {
        setOrganization(org);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (campaignDataResponse) {
      setCampaign(campaignDataResponse?.data);
    }
  }, [campaignDataResponse]);

  useEffect(() => {
    if (hasSendSmsError) {
      NotificationService.getInstance().sendNotification(
        hasSendSmsError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasSendSmsError]);

  useEffect(() => {
    if (hasUpdateLeadError) {
      NotificationService.getInstance().sendNotification(
        hasUpdateLeadError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasUpdateLeadError]);

  useEffect(() => {
    if (hasCreateLeadError) {
      NotificationService.getInstance().sendNotification(
        hasCreateLeadError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasCreateLeadError]);

  useEffect(() => {
    if (campaignDataError) {
      NotificationService.getInstance().sendNotification(
        campaignDataError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [campaignDataError]);

  useEffect(() => {
    if (postBurnCampaignLeadResponse?.status === 200) {
      setBurned(true);
    }
  }, [postBurnCampaignLeadResponse]);

  useEffect(() => {
    if (postBurnCampaignLeadError) {
      NotificationService.getInstance().sendNotification(
        postBurnCampaignLeadError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [postBurnCampaignLeadError]);

  useEffect(() => {
    if (hasGetLeadByIdError) {
      NotificationService.getInstance().sendNotification(
        hasGetLeadByIdError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasGetLeadByIdError]);

  useEffect(() => {
    if (getCampaignLeadResponse && getCampaignLeadResponse?.data) {
      const nextLead = getCampaignLeadResponse?.data;
      setLead(nextLead);
      if (nextLead) {
        setBurned(false);
        setLead(nextLead);
        if (isCallNowQueue) {
          onCallCampaignLead(nextLead);
        }
        // FE-1005
        // if lead does not exist:
        // on predictive campaign queue redirect to dashboardPage
        // on call now queue redirect to last URL before call now view
      } else {
        if (!isCallNowQueue) {
          LoggedInCampaignService.getInstance().setEmptyCampaign();
          navigate('/dashboard');
        } else {
          navigate(prevLocation);
        }
      }
    }
  }, [getCampaignLeadResponse]);

  useEffect(() => {
    if (getCampaignLeadError) {
      NotificationService.getInstance().sendNotification(
        getCampaignLeadError?.response?.data?.message,
        NotificationType.ERROR
      );
      if (isCallNowQueue) {
        navigate(prevLocation);
      } else {
        LoggedInCampaignService.getInstance().setEmptyCampaign();
        navigate(`/${Path.DASHBOARD}`);
      }
    }
  }, [getCampaignLeadError]);

  useEffect(() => {
    if (campaign?.guide_content) {
      const theScript: CampaignScriptContent = JSON.parse(campaign?.guide_content || '');
      setCampaignScript(theScript);
    } else if (isCallNowQueue && guideId) {
      getCampaignScriptData();
    } else {
      setCampaignScript(null);
    }
  }, [guideId, campaign]);

  useEffect(() => {
    if (campaignId) {
      getCampaign(campaignId);
    }
  }, [campaignId, location?.state]);

  useEffect(() => {
    if (location?.state?.prevLocation !== location?.pathname) {
      setPrevLocation(location?.state?.prevLocation);
    }
  }, [location?.state]);

  useEffect(() => {
    if (campaign) {
      requestNextLead();
    }
  }, [campaign]);

  useEffect(() => {
    if (campaign && campaign?.type_source_number) {
      switch (campaign?.type_source_number) {
        case TypeSourceNumber.SHORT_CODE:
          setSource(campaign?.short_code ? campaign.short_code : '');
          break;
        case TypeSourceNumber.USER_DID:
          setSource(getUserSmsDid(auth0.user) || '');
          break;
        case TypeSourceNumber.CAMPAIGN_DID:
          setSource(campaign?.source ? campaign?.source : '');
          break;
      }
    }
  }, [campaign]);

  useEffect(() => {
    if (campaign) {
      switch (campaign?.caller_source_type) {
        case TypeCallSource.CALLER_DID:
          setCallerSource(campaign?.caller_id ? campaign.caller_id : '');
          break;
        case TypeCallSource.USER_DID:
          setCallerSource(getUserPhoneNumber(auth0.user) || '');
          break;
        case TypeCallSource.LOCAL_PRESENCE:
          setCallerSource(campaign?.fallback_caller_did ? campaign?.fallback_caller_did : '');
          break;
        default:
          setCallerSource(getUserPhoneNumber(auth0.user) || '');
      }
    }
  }, [campaign]);

  useEffect(() => {
    if (campaign && campaign.campaign_type === CampaignType.SMS) {
      setSmsToSend(campaign?.sms_text || '');
    }
  }, [campaign]);

  useEffect(() => {
    if (requestLead) {
      requestNextLead();
      setRequestLead(false);
    }
  }, [location.search]);

  function getCampaign(campaignId: string): void {
    if (isCallNowQueue) {
      const actionId = campaignId.replace(CALL_NOW_CAMPAIGN_PREFIX, '');

      getAutoAction({url: `/${FeatureName.AUTO_ACTIONS}/${actionId}`}).then((response: any) => {
        const autoAction: AutoAction = response.data;
        setGuideId(autoAction?.guide_id);
        const campaign: Campaign = {
          campaign_id: campaignId,
          name: autoAction.name,
          script: !autoAction?.guide_id ? autoAction?.call_now_script : '',
          campaign_type: CampaignType.CALL,
          bucket_id: autoAction.bucket_id,
          local_presence: autoAction.local_presence,
        };
        setCampaign(campaign);
      });
    } else {
      getCampaignData();
    }
  }

  function onCallCampaignLead(lead: Lead) {
    if (auth0?.user && organization && campaign && lead) {
      const localPresence = isCallNowQueue
        ? campaign.local_presence
        : campaign?.caller_source_type === TypeCallSource.LOCAL_PRESENCE;
      const call: Call = {
        account: getUsername(auth0.user) || '',
        campaign_id: campaign?.campaign_id,
        caller_id: callerSource,
        extension: getUserExtension(auth0.user) || '',
        local_presence: localPresence || false,
        press_one: organization?.press_one,
        phone_number: lead[organization?.call_now_key],
        user_id: getUserId(auth0.user) || '',
        lead_id: lead?.lead_id || '',
        campaign_name: campaign.name || '',
        created_by: getUserId(auth0.user) || '',
      };
      if (!isCallNowQueue) {
        call['bucket'] = campaign.preview_call_local_presence_bucket || '';
      } else {
        call['bucket'] = campaign.bucket_id || '';
      }

      let makeCallUrl = isCallNowQueue ? CallType.CALLNOW : CallType.PREDICTIVE;
      makeCall({data: call, url: `${makeCallUrl}/`}).then((response: AxiosResponse) => {
        if (!burned && !isCallNowQueue) {
          NotificationService.getInstance().sendNotification(response?.data?.message, NotificationType.SUCCESS);
          burnLead(campaign);
        }
      });
    }
  }

  function burnLead(campaign: Campaign): void {
    burnCampaignLead({
      data: {
        campaign_id: campaign.campaign_id,
        campaign_type: campaign.campaign_type || CampaignType.SMS,
        lead_id: lead?.lead_id || '',
        created_by: getUserId(auth0.user),
      },
    });
  }

  function onSendSms() {
    if (auth0?.user && campaign && lead?.lead_id && smsToSend) {
      const sms: Sms = {
        source: source,
        number: lead?.[organization?.cell_phone_key || ''],
        text: replaceScriptPlaceholders(smsToSend, lead),
        campaign_id: campaign.campaign_id,
        user_id: getUserId(auth0.user) || '',
        lead_id: lead?.lead_id,
        created_by: getUserId(auth0.user) || '',
        type_source_number: campaign.type_source_number || '',
      };

      sendSms({data: sms}).then((response: AxiosResponse) => {
        if (response?.status === 200) {
          NotificationService.getInstance().sendNotification(response?.data?.message, NotificationType.SUCCESS);
        }
        burnLead(campaign);
        requestNextLead();
        const shouldSave =
          response?.status === 400 &&
          (response?.data.message.includes('is not a mobile number') ||
            response?.data.message.includes('is not a valid phone number'));
        if (shouldSave) {
          const data = {sms_invalid: true};
          saveLead(campaignId ?? lead?.lead_id, data)?.then((response: AxiosResponse) => {
            if (response?.status === 200) {
              NotificationService.getInstance().sendNotification(response?.data?.message, NotificationType.SUCCESS);
            }
          });
        }
      });
    }
  }

  function saveLead(id: string | undefined, data: any): Promise<AxiosResponse> | undefined {
    if (id) {
      return updateLead({url: `${FeatureName.LEADS}/${id}`, data: data}).then((response: AxiosResponse) => response);
    } else {
      return createLead({data: data}).then((response: AxiosResponse) => response);
    }
  }

  function scripts(): ReactNode {
    if (campaign && campaign.campaign_type === CampaignType.SMS) {
      return (
        <FixedCampaignScriptSms>
          <InputLabel htmlFor={'campaign-sms-script'}>{t('leads.message')}</InputLabel>
          <TextField
            id={'campaign-sms-script'}
            onChange={(e) => setSmsToSend(e.target.value)}
            fullWidth={true}
            placeholder={t('leads.message')}
            value={replaceScriptPlaceholders(smsToSend || campaign?.sms_text, lead)}
            InputProps={{
              multiline: true,
              minRows: 3,
              maxRows: 10,
            }}
          />
        </FixedCampaignScriptSms>
      );
    } else if (campaign && campaign.campaign_type === CampaignType.CALL) {
      if (campaignScript) {
        return (
          <FixedCampaignScript>
            <UserGuideStepper steps={campaignScript?.steps || []} model={lead}></UserGuideStepper>
          </FixedCampaignScript>
        );
      } else {
        return (
          <FixedCampaignScript>
            <Box
              sx={{
                color: (theme) => theme.palette.text.primary,
                marginBottom: (theme) => theme.spacing(2),
                '& p': {
                  fontFamily: 'Quicksand',
                  fontSize: 'large',
                },
              }}
              dangerouslySetInnerHTML={{
                __html: replaceScriptPlaceholders(campaign.script, lead),
              }}
            />
          </FixedCampaignScript>
        );
      }
    }
  }

  function getLeadById(leadId?: string) {
    if (leadId) {
      getLeadsById({url: `/${FeatureName.LEADS}/${leadId}`, params: {formatted: true}}).then(
        (leadResponse: AxiosResponse<Lead>) => {
          setLead(leadResponse.data);
        }
      );
    }
  }

  function onSubmitSucceeded(response: AxiosResponse<DefaultResponse>, formValues: any) {
    if (LeadService.getInstance().isSaveAndNext()) {
      LeadService.getInstance().setSaveAndNext(false);
      if (campaign) {
        requestNextLead();
      }
    } else {
      getLeadById(lead?.lead_id);
    }
  }

  function onSaveAndNextClick() {
    LeadService.getInstance().setSaveAndNext(true);
    formAPI.submit();
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <LeadEditor
            lead={lead}
            setLead={setLead}
            onSubmitSucceeded={onSubmitSucceeded}
            subHeadline={!isCallNowQueue ? campaign?.name ?? '' : ''}
            headline={!isCallNowQueue ? `${t('campaign.headline')}` : `${t('leads.dialogs.call.call-now')}`}
            formAPI={formAPI}
            campaignScript={scripts()}
            userPermissions={userPermissions}
          >
            {!isCallNowQueue && (
              <Button
                color="secondary"
                id={'logout-btn'}
                onClick={() => {
                  LoggedInCampaignService.getInstance().setEmptyCampaign();
                  navigate(`/${Path.DASHBOARD}`);
                }}
              >
                {t('shared.logout')}
              </Button>
            )}

            {campaign?.campaign_type === CampaignType.SMS && !isCallNowQueue && lead ? (
              <Button color="secondary" onClick={() => onSendSms()} id={'send-sms-btn'}>
                {t('leads.dialogs.sms.sms-now')}
              </Button>
            ) : (
              lead &&
              !isCallNowQueue && (
                <Button color="secondary" onClick={() => onCallCampaignLead(lead)} id={'call-btn'}>
                  {t('leads.call')}
                </Button>
              )
            )}
            {campaign && !isCallNowQueue && (
              <Button
                color="secondary"
                onClick={() => {
                  navigate({search: `?timestamp=${Date.now()}`});
                  setRequestLead(true);
                }}
                id={'next-btn'}
              >
                {t('leads.next')}
              </Button>
            )}
            {campaign && !isCallNowQueue && (
              <Button
                id={'save-next-btn'}
                color="secondary"
                onClick={() => {
                  onSaveAndNextClick();
                }}
              >
                {t('leads.save-next')}
              </Button>
            )}
          </LeadEditor>
        </Grid>
      </Container>
    </Box>
  );
}

export default CampaignViewPage;
