import React, {ChangeEvent, useEffect, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Box, Button, Container, Divider, Grid, InputLabel, MenuItem, Select, Typography} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PageHeadline from '../../components/PageHeadline';
import {PageTopActions} from '../PageTopActions';
import SearchTextField from '../../components/shared/SearchTextField';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {LeadListItem, LeadListSearchParams, LeadListSearchSortOption} from '../../model/Lead';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName, Path} from '../../../paths';
import {PageStickyHeader} from '../PageStickyHeader';
import LeadService from '../../services/LeadService';
import LeadAttachmentSpeedDial from './components/LeadAttachmentDial';
import {formatDateTime} from '../../utils/DateUtils';
import {useTableColumns} from '../UseTableColumns';
import DataGridCustom from '../../components/DataGridCustom';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {TestAttributes} from '../../TestAttributes';
import {AxiosResponse} from 'axios';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

function LeadListPage() {
  const {useAxiosBFF} = useAxiosContext();
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const [leads, setLeads] = useState<Array<LeadListItem> | null>(null);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.LEADS);
  const [searchParams, setSearchParams] = useState<LeadListSearchParams>(LeadService.getInstance().getSearchParams());
  const [showResultsMessage, setShowResultsMessage] = useState<boolean>(false);
  const [isSearchDisabled, setIsSearchDisabled] = useState<boolean>(
    !(searchParams.search || searchParams.last_edited_by_me)
  );
  const [queryParam] = useSearchParams();
  const tableColumns = useTableColumns({
    featureName: FeatureName.LEADS,
    defaultActionColumn: true,
  });

  const [{loading: isGetLeadsLoading}, getLeads] = useAxiosBFF<ResponseListWrapper<LeadListItem>>(
    {url: `/${FeatureName.LEADS}`, method: 'GET'},
    {manual: true}
  );

  useEffect(() => {
    const query = queryParam.get('query');
    const lastSearchParams = LeadService.getInstance().getSearchParams();
    if (query) {
      lastSearchParams.search = query;
      setSearchParams(lastSearchParams);
    }
    handleLeadSearch(lastSearchParams);
  }, []);

  useEffect(() => {
    setIsSearchDisabled(!(searchParams.search || searchParams.last_edited_by_me));
  }, [searchParams]);

  useEffect(() => {
    setLoading(isGetLeadsLoading, 'LeadListPage');
  }, [isGetLeadsLoading]);

  function handleLeadSearch(searchParams: LeadListSearchParams) {
    if (!isSearchDisabled) {
      getLeads({params: {...searchParams, formatted: false}}).then(
        (responseListWrapper: AxiosResponse<ResponseListWrapper<LeadListItem>>) => {
          const searchResponse = responseListWrapper?.data.results ?? null;
          setLeads(searchResponse);
          const isSearchResponse = responseListWrapper?.data.results?.length > 0;
          setShowResultsMessage(!isSearchResponse);
          LeadService.getInstance().setSearchParams(searchParams);
        }
      );
    }
  }

  function handleSearchChange(e: ChangeEvent<{value: string}>) {
    setSearchParams({...searchParams, search: e.target.value});
  }
  function handleSortByChange(e: any) {
    setSearchParams({...searchParams, order_by: e.target.value});
  }
  function handleLastUpdatedByMeChange(e: ChangeEvent<HTMLInputElement>) {
    setSearchParams({...searchParams, last_edited_by_me: e.target.checked});
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={6} md={7} order={{xs: 1, md: 1}} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('leads.headline')}</PageHeadline>
              </Grid>
              <Grid item xs={12} md={4} order={{xs: 3, md: 2}} sx={{alignSelf: 'center'}}>
                <PageTopActions>
                  <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.CREATE]}>
                    <Link to={'/' + FeatureName.LEADS + '/create'}>
                      <Button fullWidth color="secondary" id={'add-btn'} {...{[TestAttributes.BUTTON_NAME]: 'add-btn'}}>
                        {t('globalActions.addButton')}
                      </Button>
                    </Link>
                  </AccessControl>
                  <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW_QUERIES]}>
                    <Link to={`/queries/${FeatureName.LEADS}`}>
                      <Button
                        fullWidth
                        id={'view-queries-leads'}
                        {...{[TestAttributes.BUTTON_NAME]: 'view-queries-leads'}}
                      >
                        {t('globalActions.queryButton')}
                      </Button>
                    </Link>
                  </AccessControl>
                  <AccessControl
                    userPermissions={userPermissions}
                    allowedPermissions={[UserPermissions.VIEW_MASSIVE_UPDATE]}
                  >
                    <Link to={'/' + Path.MASSIVE_UPDATE}>
                      <Button
                        fullWidth
                        id={'massive-update-btn'}
                        {...{[TestAttributes.BUTTON_NAME]: 'massive-update-btn'}}
                      >
                        {t('leads.massive-update')}
                      </Button>
                    </Link>
                  </AccessControl>
                </PageTopActions>
              </Grid>
              <Grid item xs={6} md={1} order={{xs: 2, md: 3}}>
                <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW_UPLOAD_TSV]}>
                  <LeadAttachmentSpeedDial />
                </AccessControl>
              </Grid>
            </Grid>
          </PageStickyHeader>

          <Divider />
          <Grid item xs={12}>
            <Typography variant={'h5'}>{t('leads.search-message')}</Typography>
          </Grid>
          <Grid item container xs={12} spacing={3} sx={{alignItems: 'flex-end'}}>
            <Grid item xs={12} sm={5}>
              <SearchTextField
                id={'lead_search'}
                value={searchParams?.search}
                translationKey={'leads.search'}
                handleOnChange={handleSearchChange}
                handleOnSearch={() => handleLeadSearch(searchParams)}
              ></SearchTextField>
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputLabel htmlFor="lead-sort-by">{t('leads.sort')}</InputLabel>
              <Select
                id="lead_sort_by"
                value={searchParams?.order_by}
                onChange={handleSortByChange}
                labelId="lead-sort-by"
                label={t('leads.sort')}
                fullWidth
              >
                <MenuItem value={LeadListSearchSortOption.CREATED_AT}>{t('leads.created-date')}</MenuItem>
                <MenuItem value={LeadListSearchSortOption.LAST_UPDATED_AT}>{t('leads.last-update')}</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={1}>
              <InputLabel htmlFor="lead-limit">{t('leads.show')}</InputLabel>
              <Select
                id="lead_limit"
                value={searchParams?.limit}
                onChange={(e) => setSearchParams({...searchParams, limit: e.target.value as number})}
                labelId="lead-limit"
                label={t('leads.show')}
                fullWidth
              >
                <MenuItem value={50}>{50}</MenuItem>
                <MenuItem value={250}>{250}</MenuItem>
                <MenuItem value={500}>{500}</MenuItem>
                <MenuItem value={1000}>{1000}</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={2} mr={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="last_edited_by_me"
                    checked={searchParams?.last_edited_by_me}
                    onChange={handleLastUpdatedByMeChange}
                  />
                }
                label={t('leads.last-edited-by-me').toString()}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={1}
              sx={{
                my: 1,
              }}
            >
              <Button
                onClick={() => handleLeadSearch(searchParams)}
                fullWidth
                id={'search-btn'}
                disabled={isSearchDisabled}
              >
                <SearchIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{height: '700px'}}
            justifyContent={'center'}
            {...{[TestAttributes.TABLE_NAME]: 'table-results'}}
          >
            {leads && leads?.length > 0 && (
              <DataGridCustom
                tableColumns={tableColumns}
                rows={
                  leads?.map((item: LeadListItem, index: number) => {
                    return {
                      actions: item.lead_id,
                      ...item,
                      created_at: formatDateTime(item.created_at),
                      id: index,
                    };
                  }) || []
                }
                userPermissions={userPermissions}
              />
            )}
            {showResultsMessage && (
              <Grid item xs={12} sx={{marginTop: (theme) => theme.spacing(4)}}>
                <Typography variant={'h4'}>{t('shared.table-result')} </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default LeadListPage;
