import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FormControl, InputLabel, Link, Typography} from '@mui/material';
import {SpartanFieldProps, wrapInTooltipIfNecessary} from './model';
import {SpartanLabelWithTooltip} from './SpartanLabelWithTooltip';
import {StringKeyObject} from '../state/FormState';
import {ErrorMessage} from './components/ErrorMessage';
import {SpartanLinkConfiguration, TargetType} from '../logic/FormSchema';

interface SpartanLinkFieldProps extends SpartanFieldProps {
  formValues?: StringKeyObject<any>;
  linkValue?: JSON;
}

export function SpartanLinkField({formValues, ...props}: SpartanLinkFieldProps) {
  const {t} = useTranslation();
  const {id, label, hasError, error, linkValue} = props;
  const [linkConfigValue, setLinkConfigValue] = useState<SpartanLinkConfiguration>();

  useEffect(() => {
    const value: SpartanLinkConfiguration = linkValue as SpartanLinkConfiguration;
    if (value && value.url && formValues) {
      setLinkConfigValue({
        ...linkConfigValue,
        name: value?.name ? value?.name : replaceScriptPlaceholders(value?.url, formValues, value?.format),
        target: value?.target ? value?.target : TargetType.BLANK,
        url: value?.url ? replaceScriptPlaceholders(value?.url, formValues, value?.format) : undefined,
        format: value?.format,
      });
    }
  }, [formValues, linkValue]);

  const fromBooleanToYesNo = (initValue: boolean) => {
    return initValue ? 'yes' : 'no';
  };

  const addPrefix = (value: any, prefix: string) => {
    return `${prefix}${value}`;
  };

  const valueFormatter = (key: string, value: string, format?: StringKeyObject<any>) => {
    let parseValue = value;
    if (format && Object.keys(format).includes(key)) {
      const list = format[key];
      list.forEach((obj: any) => {
        if (obj.function === 'toUpperCase') {
          parseValue = parseValue.toUpperCase();
        }
        if (obj.function === 'fromBooleanToYesNo') {
          parseValue = fromBooleanToYesNo(Boolean(parseValue));
        }
        if (obj.function === 'addPrefix') {
          parseValue = addPrefix(parseValue, obj.parameters);
        }
      });
    }
    return parseValue;
  };

  function replaceScriptPlaceholders(url: string, values: StringKeyObject<any>, format?: StringKeyObject<any>): string {
    let [baseUrl, queryString] = url.split('?');
    const keysPattern = /{(.+?)}/g;
    let key = null;
    const keys: string[] = [];
    while ((key = keysPattern.exec(queryString)) !== null) {
      keys.push(key[1]);
    }
    keys?.forEach((key: string) => {
      const value = values[key];
      if (value === null || value === undefined) {
        queryString = queryString?.replace(`{${key}}`, '');
      } else {
        queryString = queryString?.replace(`{${key}}`, encodeURIComponent(valueFormatter(key, value, format)));
      }
    });
    return baseUrl + '?' + queryString;
  }

  return (
    <>
      <InputLabel error={hasError} htmlFor={id}>
        <SpartanLabelWithTooltip label={label} />
      </InputLabel>
      <FormControl error={hasError}>
        {wrapInTooltipIfNecessary(
          props,
          linkConfigValue && linkConfigValue?.url ? (
            <Link href={linkConfigValue?.url} target={linkConfigValue.target} underline={'hover'}>
              {linkConfigValue.name}
            </Link>
          ) : (
            <Typography variant={'h4'}> {t('leads.field_link')}</Typography>
          )
        )}
        <ErrorMessage hasError={hasError} error={error} />
      </FormControl>
    </>
  );
}
