import {useLocation} from 'react-router-dom';
import {useEffect} from 'react';
// @ts-ignore
import {useAnalytics} from 'use-analytics';
import OrganizationService from '../../services/OrganizationService';
import {useAuth0} from '@auth0/auth0-react';
import {Auth0User, getOrgName, getUserEmail, getUserId} from '../../model/Auth0User';

function PageViewTracker() {
  const {user} = useAuth0<Auth0User>();
  const location = useLocation();
  const {page, identify} = useAnalytics();

  useEffect(() => {
    if (user) {
      identify(getUserId(user), {
        email: getUserEmail(user),
        org: getOrgName(user),
      });
    }
  }, [user]);

  useEffect(() => {
    page({org: OrganizationService.getInstance().getCurrentValue()?.name});
  }, [location]);

  return <></>;
}

export default PageViewTracker;
