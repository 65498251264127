export interface Chat {
  from_number: string;
  lead_id: string;
  first_name: string;
  last_name: string;
  chat_owner: string;
  unread: number;
  sms_status: string;
}
export interface ChatViewMessage {
  message: string;
  number: string;
  sms_status: string;
  created_at: string;
  username: string;
}

export interface ChatSendMessage {
  number: string;
  text: string;
  source: string;
}

export enum ChatViewMessageStatus {
  SENT = 'sent',
  RECEIVED = 'received',
}
