import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Container, Grid, Skeleton, Typography} from '@mui/material';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import {PageTopActions} from '../PageTopActions';
import {FeatureName} from '../../../paths';
import PageHeadline from '../../components/PageHeadline';
import {PageStickyHeader} from '../PageStickyHeader';
import {FormBuilder} from '../../form/FormBuilder';
import {useFormAPI} from '../../form/FormAPI';
import {ActionType, AutoAction, QueueType} from '../../model/AutoAction';
import {Status} from '../../model/Feature';
import {onRejectSubmit} from '../../form/errorHandler';
import {DefaultResponse} from '../../services/model/DefaultResponse';
import {useSettings} from '../UseSettings';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {TestAttributes} from '../../TestAttributes';
import {StringKeyObject, useStateSubject} from '../../form/state/FormState';
import BucketDialog from '../campaign/dialogs/BucketDialog';
import {FormConfig} from '../../form/FormConfig';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {difference} from '../../form/utils';
import {OnSubmitHandler} from '../../form/model';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import FeatureNotFound from '../notFound/FeatureNotFound';

function AutoActionPage() {
  const {id} = useParams();
  const {useAxiosBFF} = useAxiosContext();
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const navigate = useNavigate();
  const {settings} = useSettings();
  const formAPI = useFormAPI();
  const [config, setConfig] = useState<FormConfig>();
  const [scheduleTime, setScheduleTime] = useState<string>('');
  const [isUndoConfirmationDialogOpen, setIsUndoConfirmationDialogOpen] = useState<boolean>(false);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState<boolean>(false);
  const [isClearQueueConfirmationDialogOpen, setIsClearQueueConfirmationDialogOpen] = useState<boolean>(false);
  const [isBucketDialogOpen, setIsBucketDialogOpen] = useState<boolean>(false);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.AUTO_ACTIONS);
  const [isValid, setIsValid] = useState<boolean>(true);
  const $state = useStateSubject();
  const [formStateValues, setFormStateValues] = useState<StringKeyObject<any>>({});

  const [{data: autoAction, loading: isAutoActionLoading, error: getAutoActionError}, getAutoActionData] =
    useAxiosBFF<AutoAction>(`/${FeatureName.AUTO_ACTIONS}/${id}`, {manual: true});

  const [
    {loading: isAutoActionPatchLoading, error: patchAutoActionError, response: patchAutoActionResponse},
    patchAutoActionData,
  ] = useAxiosBFF<AutoAction>({url: `/${FeatureName.AUTO_ACTIONS}/${id}`, method: 'PATCH'}, {manual: true});

  const [
    {error: postAutoActionError, response: postAutoActionResponse, loading: postAutoActionLoading},
    postAutoActionData,
  ] = useAxiosBFF<AutoAction>({url: `/${FeatureName.AUTO_ACTIONS}`, method: 'POST'}, {manual: true});

  const [
    {error: deleteAutoActionError, response: deleteAutoActionResponse, loading: deleteAutoActionLoading},
    deleteAutoAction,
  ] = useAxiosBFF<ResponseListWrapper<DefaultResponse>>(
    {
      url: `/${FeatureName.AUTO_ACTIONS}/${id}`,
      method: 'DELETE',
    },
    {manual: true}
  );
  const [{error: deleteQueueError, response: deleteQueueResponse, loading: deleteQueueLoading}, deleteQueue] =
    useAxiosBFF<ResponseListWrapper<DefaultResponse>>(
      {
        url: `/${FeatureName.AUTO_ACTIONS}/${id}/queue/${
          autoAction?.action_type === ActionType.CALL_NOW ? QueueType.CALL_NOW : QueueType.AFTER_HOURS
        }`,
        method: 'DELETE',
      },
      {manual: true}
    );

  useEffect(() => {
    const loading =
      deleteAutoActionLoading ||
      postAutoActionLoading ||
      isAutoActionLoading ||
      isAutoActionPatchLoading ||
      deleteQueueLoading;
    setLoading(loading, 'AutoActionPage');
  }, [
    deleteAutoActionLoading,
    isAutoActionPatchLoading,
    isAutoActionLoading,
    postAutoActionLoading,
    deleteQueueLoading,
  ]);

  useEffect(() => {
    const sub = $state.subscribe((next) => setFormStateValues(next.values));
    return () => sub.unsubscribe();
  }, [$state]);

  useEffect(() => {
    setConfig({...config, $state: $state});
  }, [$state]);

  useEffect(() => {
    if (settings) {
      setScheduleTime(`${settings?.office_start_hour} - ${settings?.office_end_hour}`);
    }
  }, [settings]);

  useEffect(() => {
    if (postAutoActionResponse?.status === 201) {
      NotificationService.getInstance().sendNotification(
        postAutoActionResponse.data?.['message'],
        NotificationType.SUCCESS
      );
      navigate(`/${FeatureName.AUTO_ACTIONS}/${postAutoActionResponse.data['id']}`);
    }
  }, [postAutoActionResponse]);
  useEffect(() => {
    if (patchAutoActionResponse?.status === 200) {
      NotificationService.getInstance().sendNotification(
        patchAutoActionResponse.data?.['message'],
        NotificationType.SUCCESS
      );
      getAutoActionData();
    }
  }, [patchAutoActionResponse]);
  useEffect(() => {
    if (patchAutoActionError) {
      NotificationService.getInstance().sendNotification(
        patchAutoActionError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [patchAutoActionError]);
  useEffect(() => {
    if (postAutoActionError) {
      NotificationService.getInstance().sendNotification(
        postAutoActionError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [postAutoActionError]);
  useEffect(() => {
    if (getAutoActionError) {
      NotificationService.getInstance().sendNotification(
        getAutoActionError?.response?.data?.message,
        NotificationType.ERROR
      );
      setIsValid(false);
    }
  }, [getAutoActionError]);
  useEffect(() => {
    if (deleteQueueResponse) {
      NotificationService.getInstance().sendNotification(
        deleteQueueResponse?.data?.['message'],
        NotificationType.SUCCESS
      );
    }
  }, [deleteQueueResponse]);
  useEffect(() => {
    if (deleteAutoActionResponse) {
      NotificationService.getInstance().sendNotification(
        deleteAutoActionResponse?.data?.['message'],
        NotificationType.SUCCESS
      );
      navigate('/' + FeatureName.AUTO_ACTIONS);
    }
  }, [deleteAutoActionResponse]);

  useEffect(() => {
    if (deleteQueueError) {
      NotificationService.getInstance().sendNotification(
        deleteQueueError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [deleteQueueError]);
  useEffect(() => {
    if (deleteAutoActionError) {
      NotificationService.getInstance().sendNotification(
        deleteAutoActionError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [deleteAutoActionError]);
  useEffect(() => {
    if (id) {
      getAutoActionData();
    }
  }, [id]);

  const onSubmit: OnSubmitHandler = useCallback(
    (formData: Partial<AutoAction>) => {
      if (id) {
        formData = difference(formData, autoAction);
        patchAutoActionData({
          data: formData,
        });
      } else {
        postAutoActionData({
          data: formData,
        });
      }
    },
    [autoAction]
  );

  function clearQueue() {
    if (id && autoAction) {
      deleteQueue();
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        {isValid ? (
          <Grid container spacing={3}>
            <PageStickyHeader>
              <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
                <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                  <PageHeadline>{t('automatic-actions.headline')}</PageHeadline>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PageTopActions>
                    <ConfirmationDialog
                      message={t('shared.clear-form-modal-content')}
                      headline={t('shared.clear-form-modal-headline')}
                      isDialogOpen={isUndoConfirmationDialogOpen}
                      handleClose={() => setIsUndoConfirmationDialogOpen(false)}
                    >
                      <Button
                        onClick={() => {
                          formAPI.reset();
                          setIsUndoConfirmationDialogOpen(false);
                        }}
                        id="confirmation-dialog-action-btn"
                      >
                        {t('shared.accept')}
                      </Button>
                    </ConfirmationDialog>
                    <ConfirmationDialog
                      message={t('automatic-actions.clear-queue-message')}
                      headline={t('automatic-actions.clear-queue')}
                      isDialogOpen={isClearQueueConfirmationDialogOpen}
                      handleClose={() => setIsClearQueueConfirmationDialogOpen(false)}
                    >
                      <Button
                        onClick={() => {
                          clearQueue();
                          setIsClearQueueConfirmationDialogOpen(false);
                        }}
                        id="confirmation-dialog-action-btn"
                      >
                        {t('shared.accept')}
                      </Button>
                    </ConfirmationDialog>

                    <ConfirmationDialog
                      message={t('automatic-actions.deactivate-message')}
                      headline={t('automatic-actions.deactivate')}
                      isDialogOpen={isDeleteConfirmationDialogOpen}
                      handleClose={() => setIsDeleteConfirmationDialogOpen(false)}
                    >
                      <Button
                        onClick={() => {
                          deleteAutoAction();
                          setIsDeleteConfirmationDialogOpen(false);
                        }}
                        id="confirmation-dialog-action-btn"
                      >
                        {t('shared.accept')}
                      </Button>
                    </ConfirmationDialog>
                    {formStateValues.local_presence_bucket && (
                      <Button
                        id={'view-dids-btn'}
                        {...{[TestAttributes.BUTTON_NAME]: 'view-dids-btn'}}
                        color="secondary"
                        onClick={() => {
                          setIsBucketDialogOpen(true);
                        }}
                      >
                        {t('shared.view-dids')}
                      </Button>
                    )}
                    <Button
                      id={'undo-confirmation-dialog-btn'}
                      color="secondary"
                      onClick={() => setIsUndoConfirmationDialogOpen(true)}
                    >
                      {t('shared.clear')}
                    </Button>
                    {id && (
                      <>
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={[UserPermissions.CLEAR_QUEUE]}
                        >
                          <Button
                            id={'clear-callnow-confirmation-dialog-btn'}
                            color="secondary"
                            onClick={() => setIsClearQueueConfirmationDialogOpen(true)}
                          >
                            {t('automatic-actions.clear-queue')}
                          </Button>
                        </AccessControl>
                        <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.DELETE]}>
                          <Button
                            id={'delete-dialog-btn'}
                            color="secondary"
                            onClick={() => setIsDeleteConfirmationDialogOpen(true)}
                          >
                            {t('automatic-actions.deactivate')}
                          </Button>
                        </AccessControl>
                      </>
                    )}
                    <AccessControl
                      userPermissions={userPermissions}
                      allowedPermissions={[id ? UserPermissions.MODIFY : UserPermissions.CREATE]}
                    >
                      <Button id={'save-btn'} onClick={() => formAPI.submit()}>
                        {id ? t('shared.update') : t('shared.save')}
                      </Button>
                    </AccessControl>
                  </PageTopActions>
                  {autoAction?.status === Status.ACTIVE && (
                    <Typography sx={{mt: (theme) => theme.spacing(1), textAlign: 'right'}}>
                      {t('automatic-actions.active-auto-action-message')}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </PageStickyHeader>
            <Grid item xs={12} sx={{display: 'flex'}}>
              <DateRangeRoundedIcon color="disabled" />
              <Typography variant={'h6'}>{`${t('settings.officeSchedule')}: ${scheduleTime}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              {isAutoActionLoading ? (
                <Skeleton variant="rectangular" height={500} />
              ) : (
                <FormBuilder
                  config={config}
                  onRejectSubmit={onRejectSubmit}
                  formId={FeatureName.AUTO_ACTIONS}
                  api={formAPI}
                  initialValues={autoAction}
                  onSubmit={onSubmit}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <FeatureNotFound />
        )}
        <BucketDialog
          bucketName={formStateValues.local_presence_bucket}
          isDialogOpen={isBucketDialogOpen}
          handleClose={() => setIsBucketDialogOpen(false)}
          localPresence={true}
        ></BucketDialog>
      </Container>
    </Box>
  );
}

export default AutoActionPage;
