import {useCallback, useEffect} from 'react';
import {GeneralSettings} from '../model/GeneralSettings';
import {difference} from '../form/utils';
import {FeatureName} from '../../paths';
import NotificationService, {NotificationType} from '../services/NotificationService';
import {useAxiosContext} from '../context/AxiosContext';
import {useLoading} from '../context/LoadingContext';

export interface UseGeneralSettingsState {
  settings?: GeneralSettings;
  submitGeneralSettingsForm: (formData: any) => void;
}

export function useSettings(): UseGeneralSettingsState {
  const {useAxiosBFF} = useAxiosContext();
  const {setLoading} = useLoading();
  const [{data: settings, error: getGeneralSettingsError, loading: getGeneralSettingsLoading}, getGeneralSettings] =
    useAxiosBFF<GeneralSettings>(`/${FeatureName.NIGHT_SETTINGS}/overnight/`, {manual: true});

  const [
    {error: patchGeneralSettingsError, response: patchGeneralSettingsResponse, loading: patchGeneralSettingsLoading},
    patchGeneralSettingsData,
  ] = useAxiosBFF<GeneralSettings>(
    {url: `/${FeatureName.NIGHT_SETTINGS}/${settings?.overnight_id}`, method: 'PATCH', params: {formatted: true}},
    {manual: true}
  );

  useEffect(() => {
    setLoading(patchGeneralSettingsLoading || getGeneralSettingsLoading, 'useSettings');
  }, [patchGeneralSettingsLoading, getGeneralSettingsLoading]);

  useEffect(() => {
    if (getGeneralSettingsError) {
      NotificationService.getInstance().sendNotification(
        getGeneralSettingsError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [getGeneralSettingsError]);

  useEffect(() => {
    if (patchGeneralSettingsResponse?.status === 200) {
      NotificationService.getInstance().sendNotification(
        patchGeneralSettingsResponse.data?.['message'],
        NotificationType.SUCCESS
      );
    }
  }, [patchGeneralSettingsResponse]);
  useEffect(() => {
    if (patchGeneralSettingsError) {
      NotificationService.getInstance().sendNotification(
        patchGeneralSettingsError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [patchGeneralSettingsError]);
  useEffect(() => {
    getGeneralSettings();
  }, []);

  const submitGeneralSettingsForm = useCallback(
    (formData: Partial<GeneralSettings>) => {
      if (settings?.overnight_id) {
        formData = difference(formData, settings);
        patchGeneralSettingsData({
          data: formData,
        }).then(getGeneralSettings);
      }
    },
    [settings]
  );

  return {settings, submitGeneralSettingsForm};
}
