import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Button, Container, Grid, Typography} from '@mui/material';
import PageHeadline from '../../components/PageHeadline';
import {FormBuilder} from '../../form/FormBuilder';
import {FeatureName} from '../../../paths';
import {PageStickyHeader} from '../PageStickyHeader';
import ChatField from './components/ChatField';
import ChatView from './components/ChatView';
import ChatHistory from './components/ChatHistory';
import {
  AssistantResponseWrapper,
  ChatInformation,
  ChatViewMessage,
  ChatViewMessageStatus,
  Interaction,
  InteractionContext,
  InteractionRole,
} from '../../model/CreativeAssistant';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {PageTopActions} from '../PageTopActions';
import {TestAttributes} from '../../TestAttributes';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

function CreativeAssistantPage() {
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const {useAxiosBFF} = useAxiosContext();
  const [initialChatState, setInitialChatState] = useState<boolean>(true);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.CREATIVE_ASSISTANT);
  const [interactionContext, setInteractionContext] = useState<Array<InteractionContext>>([]);
  const [newMessage, setNewMessage] = useState<ChatViewMessage | null>(null);
  const [chatMessages, setChatMessages] = useState<Array<ChatViewMessage> | null>(null);
  const [chatId, setChatId] = useState<string | null>(null);
  const [chatLimitReached, setChatLimitReached] = useState<boolean>(false);
  const [selectedChat, setSelectedChat] = useState<ChatInformation | null>(null);
  const [chatHistory, setChatHistory] = useState<Array<ChatInformation>>([]);

  const interactionsToMessages = (interactions: Array<InteractionContext>) => {
    return interactions.map((interaction) => {
      return {
        message: interaction.content,
        owner: interaction.role,
        status:
          interaction.role === InteractionRole.ASSISTANT ? ChatViewMessageStatus.RECEIVED : ChatViewMessageStatus.SENT,
      } as ChatViewMessage;
    });
  };

  const [
    {response: getChatHistoryResponse, error: getChatHistoryError, loading: getChatHistoryLoading},
    getChatHistory,
  ] = useAxiosBFF<Array<ChatInformation>>(
    {
      url: `${FeatureName.CREATIVE_ASSISTANT}/chats`,
      method: 'GET',
    },
    {manual: true}
  );

  useEffect(() => {
    if (!getChatHistoryResponse || getChatHistoryResponse?.status !== 200) {
      return;
    }
    setChatHistory(getChatHistoryResponse.data);
    if (!selectedChat) {
      setSelectedChat(getChatHistoryResponse.data[0]);
    }
  }, [getChatHistoryResponse]);

  useEffect(() => {
    if (getChatHistoryError) {
      NotificationService.getInstance().sendNotification(
        t('creative-assistant.chat-history-error'),
        NotificationType.ERROR
      );
    }
  }, [getChatHistoryError]);

  useEffect(() => {
    getChatHistory();
  }, []);

  const [
    {response: getChatInteractionsResponse, loading: getChatInteractionsLoading, error: hasGetChatInteractionsError},
    getChatInteractions,
  ] = useAxiosBFF<Array<InteractionContext>>(
    {
      url: `${FeatureName.CREATIVE_ASSISTANT}/chats/interactions`,
      method: 'GET',
    },
    {manual: true}
  );

  useEffect(() => {
    if (getChatInteractionsResponse) {
      if (getChatInteractionsResponse?.status === 200) {
        setInteractionContext(getChatInteractionsResponse.data);
        setChatMessages(interactionsToMessages(getChatInteractionsResponse.data));
      }
    }
  }, [getChatInteractionsResponse]);

  useEffect(() => {
    if (hasGetChatInteractionsError) {
      NotificationService.getInstance().sendNotification(
        t('creative-assistant.chat-interactions-error'),
        NotificationType.ERROR
      );
    }
  }, [hasGetChatInteractionsError]);

  const [
    {response: assistantResponse, loading: getAssistantResponseLoading, error: hasAssistantResponseError},
    getAssistantResponse,
  ] = useAxiosBFF<AssistantResponseWrapper>(
    {url: `${FeatureName.CREATIVE_ASSISTANT}/interactions`, method: 'POST'},
    {manual: true}
  );

  useEffect(() => {
    if (!hasAssistantResponseError) return;

    if (hasAssistantResponseError.response!.status >= 500) {
      NotificationService.getInstance().sendNotification(
        t('creative-assistant.general-server-problems'),
        NotificationType.ERROR
      );
      NotificationService.getInstance().sendNotification(
        t('creative-assistant.reduce-instruction-scope'),
        NotificationType.ERROR
      );
    }

    if (hasAssistantResponseError.response!.status === 403) {
      NotificationService.getInstance().sendNotification(
        t('creative-assistant.token-limit-surpassed'),
        NotificationType.ERROR
      );

      if (chatMessages?.length === 1) {
        setChatMessages(null);
        NotificationService.getInstance().sendNotification(
          t('creative-assistant.reduce-instruction-size'),
          NotificationType.ERROR
        );
      } else {
        setChatLimitReached(true);
        NotificationService.getInstance().sendNotification(
          t('creative-assistant.start-new-chat'),
          NotificationType.ERROR
        );
      }
    }
  }, [hasAssistantResponseError]);

  useEffect(() => {
    if (assistantResponse) {
      if (assistantResponse?.status === 200) {
        if (assistantResponse.data.assistant_chat_id && !chatId) {
          setChatId(assistantResponse.data.assistant_chat_id);
          getChatHistory();
        }

        const generated_text = assistantResponse.data.generated_text;

        const newUserInteractionContext: InteractionContext = {
          role: InteractionRole.USER,
          content: newMessage!.message,
        };
        const newAssistantInteractionContext: InteractionContext = {
          role: InteractionRole.ASSISTANT,
          content: generated_text,
        };
        setInteractionContext([...interactionContext, newUserInteractionContext, newAssistantInteractionContext]);

        const newAssistantMessage: ChatViewMessage = {
          message: assistantResponse.data.generated_text,
          status: ChatViewMessageStatus.RECEIVED,
          owner: InteractionRole.ASSISTANT,
        };
        setNewMessage(newAssistantMessage);

        setInitialChatState(false);
      }
    }
  }, [assistantResponse]);

  useEffect(() => {
    if (initialChatState) {
      setChatMessages(null);
      setInteractionContext([]);
      setChatId(null);
      setNewMessage(null);
      setChatLimitReached(false);
      setSelectedChat(null);
    }
  }, [initialChatState]);

  useEffect(() => {
    if (!newMessage) return;

    setChatMessages([...(chatMessages || []), newMessage]);

    if (newMessage.owner === InteractionRole.USER) {
      let newInteraction: Interaction = {
        interaction_context: interactionContext,
        prompt: newMessage.message,
        ...(chatId && {assistant_chat_id: chatId}),
      };
      getAssistantResponse({data: newInteraction});
    }
  }, [newMessage]);

  const handleOnChatSelected = (chat: ChatInformation) => {
    setChatId(chat.assistant_chat_id);
    setSelectedChat(chat);
    getChatInteractions({params: {assistant_chat_id: chat.assistant_chat_id}});
    setChatLimitReached(false);
  };

  const handleOnDeleteChat = (chatToDelete: ChatInformation) => {
    const chatIndex = chatHistory.findIndex(
      (chatInfo) => chatInfo.assistant_chat_id === chatToDelete.assistant_chat_id
    );
    chatHistory.splice(chatIndex, 1);
    if (!chatHistory || chatHistory.length === 0) {
      setInitialChatState(true);
    } else if (chatToDelete.assistant_chat_id === selectedChat?.assistant_chat_id) {
      setChatMessages(null);
      setNewMessage(null);
      handleOnChatSelected(chatHistory[0]);
    }
  };

  const handleOnChatNameEdit = (chatToEdit: ChatInformation, newChatName: string) => {
    const chatIndex = chatHistory.findIndex((chatInfo) => chatInfo.assistant_chat_id === chatToEdit.assistant_chat_id);
    let chatToUpdate: ChatInformation = chatHistory.splice(chatIndex, 1)[0];
    chatToUpdate.chat_name = newChatName;
    chatHistory.splice(chatIndex, 0, chatToUpdate);
  };

  const submitPromptForm = useCallback((formData: Partial<any>) => {
    const promptInput = formData.prompt_input?.trim();
    if (promptInput) {
      const newUserMessage: ChatViewMessage = {
        message: promptInput,
        status: ChatViewMessageStatus.SENT,
        owner: InteractionRole.USER,
      };
      setNewMessage(newUserMessage);
      setSelectedChat(null);
    }
  }, []);

  const copyFullChat = useCallback(() => {
    if (!chatMessages) {
      NotificationService.getInstance().sendNotification(t('creative-assistant.empty-chat'), NotificationType.ERROR);
      return;
    }
    const fullChatText: string = chatMessages!
      .reduce((accumulator: string, currentValue: ChatViewMessage) => {
        return `${accumulator}${currentValue.owner}:\n${currentValue.message}\n\n`;
      }, '')
      .trim();

    navigator.clipboard.writeText(fullChatText);

    NotificationService.getInstance().sendNotification(
      t('creative-assistant.copied-full-chat'),
      NotificationType.SUCCESS
    );
  }, [chatMessages]);

  const onChatHistoryOpen = () => {
    if (initialChatState && chatHistory && chatHistory.length > 0) {
      handleOnChatSelected(chatHistory[0]);
      setInitialChatState(false);
    }
  };

  useEffect(() => {
    const loading = getChatHistoryLoading || getChatInteractionsLoading || getAssistantResponseLoading;
    setLoading(loading, 'CreativeAssistantPage');
  }, [getChatHistoryLoading, getChatInteractionsLoading, getAssistantResponseLoading]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={5}>
          <PageStickyHeader>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('creative-assistant.headline')}</PageHeadline>
              </Grid>
              {initialChatState && (
                <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW]}>
                  <Grid item xs={12} md={4} order={{xs: 3, md: 2}} sx={{alignSelf: 'center'}}>
                    <PageTopActions>
                      <Button
                        fullWidth
                        color="secondary"
                        id={'history-btn'}
                        onClick={onChatHistoryOpen}
                        disabled={!(chatHistory && chatHistory.length > 0)}
                        {...{[TestAttributes.BUTTON_NAME]: 'history-btn'}}
                      >
                        {t('creative-assistant.view-history')}
                      </Button>
                    </PageTopActions>
                  </Grid>
                </AccessControl>
              )}
            </Grid>
          </PageStickyHeader>

          <Grid item lg={8}>
            <Typography>{t('creative-assistant.capabilities-disclaimer')} </Typography>
          </Grid>

          <Grid item container lg={12} spacing={2} justifyContent="center">
            {initialChatState && (
              <Grid item lg={8}>
                <FormBuilder formId={FeatureName.CREATIVE_ASSISTANT} onSubmit={submitPromptForm}></FormBuilder>
              </Grid>
            )}

            {!initialChatState && (
              <Grid item container lg={12} spacing={2}>
                <Grid item container lg={4}>
                  <ChatHistory
                    chatsInformation={chatHistory}
                    onChatClick={handleOnChatSelected}
                    selectedChat={selectedChat}
                    onChatDelete={handleOnDeleteChat}
                    onChatReset={() => {
                      setInitialChatState(true);
                      setChatId(null);
                      setNewMessage(null);
                      setSelectedChat(null);
                    }}
                    onChatNameEdit={handleOnChatNameEdit}
                  />
                </Grid>
                <Grid item container lg={8} spacing={2}>
                  <Grid item xs={12}>
                    <ChatView chatMessages={chatMessages} />
                  </Grid>
                  <Grid container item xs={12}>
                    <ChatField
                      setNewMessage={setNewMessage}
                      copyChat={copyFullChat}
                      chatLimitReached={chatLimitReached}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default CreativeAssistantPage;
