import {Grid, IconButton, Paper, styled, Typography, useMediaQuery, useTheme} from '@mui/material';
import React, {useCallback, useEffect, useRef} from 'react';
import {ChatViewMessage, ChatViewMessageStatus} from '../../../model/CreativeAssistant';
import ArrowCircleUpSharpIcon from '@mui/icons-material/ArrowCircleUpSharp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import {paletteModeDark, useColors} from '../../../context/LightDarkThemeProvider';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import {useTranslation} from 'react-i18next';

interface ChatViewProps {
  chatMessages: Array<ChatViewMessage> | null;
}

export const ChatArrows = styled('div')(({theme}) => ({
  width: '50px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '-116px',
  marginRight: '12px',
  float: 'right',
  opacity: 0.7,
}));

function ChatView({chatMessages}: ChatViewProps) {
  const {t} = useTranslation();
  const topRef = useRef<HTMLDivElement>(null);
  const endRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const colors = useColors();
  const isMobileViewActive = useMediaQuery(theme.breakpoints.down('lg'));

  const scrollToTopRef = () => {
    if (topRef) {
      topRef?.current?.scrollIntoView(false);
    }
  };
  const scrollToEndRef = () => {
    if (endRef) {
      endRef?.current?.scrollIntoView(false);
    }
  };

  useEffect(() => {
    if (chatMessages) {
      scrollToEndRef();
    }
  }, [chatMessages]);

  const copyMessage = useCallback((messageText: string) => {
    navigator.clipboard.writeText(messageText);

    NotificationService.getInstance().sendNotification(
      t('creative-assistant.copied-message'),
      NotificationType.SUCCESS
    );
  }, []);

  const messageMarginSpacing = isMobileViewActive ? 2 : 6;

  const MessageContainer = styled(Grid)(({theme}) => ({
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  }));

  return (
    <>
      <Grid
        item
        container
        xs={12}
        sx={{
          height: '450px',
          overflow: 'auto',
          borderRadius: '3px',
          padding: '8px',
          background:
            theme.palette.mode === paletteModeDark
              ? colors.darkModeColors.darkModeSurfaceTransparency
              : theme.palette.secondary.main,
        }}
      >
        <div ref={topRef}></div>
        {chatMessages &&
          chatMessages?.map((chatMessage: ChatViewMessage, index: number) => {
            const messageTextColor =
              chatMessage.status === ChatViewMessageStatus.RECEIVED
                ? theme.palette.mode === paletteModeDark
                  ? theme.palette.primary.contrastText
                  : theme.palette.secondary.contrastText
                : theme.palette.mode === paletteModeDark
                ? theme.palette.secondary.main
                : theme.palette.background.default;
            const messageBackgroundColor =
              chatMessage.status === ChatViewMessageStatus.RECEIVED
                ? theme.palette.mode === paletteModeDark
                  ? theme.palette.secondary.main
                  : theme.palette.background.default
                : theme.palette.primary.main;
            const clipBoardButtonColor =
              chatMessage.status === ChatViewMessageStatus.RECEIVED
                ? theme.palette.primary.main
                : theme.palette.mode === paletteModeDark
                ? theme.palette.secondary.main
                : theme.palette.background.default;

            return (
              <MessageContainer
                ref={endRef}
                item
                key={'chat-message-' + index}
                sm={12}
                display={'flex'}
                alignItems={'baseline'}
                justifyContent={chatMessage.status === ChatViewMessageStatus.SENT ? 'flex-end' : 'flex-start'}
              >
                <Paper
                  sx={{
                    textAlign: chatMessage.status === ChatViewMessageStatus.RECEIVED ? 'left' : 'right',
                    padding: (theme) => theme.spacing(2),
                    marginRight: (theme) =>
                      chatMessage.status === ChatViewMessageStatus.RECEIVED
                        ? theme.spacing(messageMarginSpacing)
                        : theme.spacing(5),
                    marginLeft: (theme) =>
                      chatMessage.status === ChatViewMessageStatus.RECEIVED
                        ? theme.spacing(5)
                        : theme.spacing(messageMarginSpacing),
                    borderRadius: '3px',
                    marginBottom: (theme) => theme.spacing(2),
                    background: messageBackgroundColor,
                    color: messageTextColor,
                    position: 'relative',
                    maxWidth: '80%',
                    minWidth: '100px',
                    '&:after': {
                      content: "''",
                      position: 'absolute',
                      width: '0',
                      height: '0',
                      borderTop: `15px solid ${messageBackgroundColor}`,
                      borderLeft: '15px solid transparent',
                      borderRight: '10px solid transparent',
                      top: '0',
                      left: chatMessage.status === ChatViewMessageStatus.RECEIVED ? '-10px' : 'none',
                    },
                  }}
                >
                  <Grid
                    item
                    container
                    justifyContent={'space-between'}
                    sx={{
                      marginTop: '-5px',
                    }}
                  >
                    <Typography variant={'h6'} sx={{fontWeight: 'bold', color: messageTextColor}}>
                      {chatMessage.owner}
                    </Typography>

                    {chatMessage.status === ChatViewMessageStatus.RECEIVED && (
                      <IconButton
                        id={'copy-assistant-btn'}
                        size={'small'}
                        sx={{
                          marginTop: '-5px',
                          height: '25px',
                        }}
                        onClick={() => {
                          copyMessage(chatMessage.message);
                        }}
                      >
                        <ContentPasteRoundedIcon
                          sx={{
                            color: clipBoardButtonColor,
                            fontSize: 'medium',
                          }}
                        />
                      </IconButton>
                    )}
                  </Grid>

                  <Typography
                    variant={'body1'}
                    sx={{
                      color: messageTextColor,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {chatMessage.message}
                  </Typography>
                </Paper>
              </MessageContainer>
            );
          })}
      </Grid>
      {chatMessages && (
        <ChatArrows>
          <IconButton
            sx={{color: theme.palette.primary.main}}
            onClick={() => {
              scrollToTopRef();
            }}
            size="large"
          >
            <ArrowCircleUpSharpIcon></ArrowCircleUpSharpIcon>
          </IconButton>

          <IconButton
            sx={{color: theme.palette.primary.main}}
            onClick={() => {
              scrollToEndRef();
            }}
            size="large"
          >
            <ArrowCircleDownIcon></ArrowCircleDownIcon>
          </IconButton>
        </ChatArrows>
      )}
    </>
  );
}

export default ChatView;
