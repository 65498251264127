import {getOrgName, Auth0User} from '../model/Auth0User';
import {useAuth0} from '@auth0/auth0-react';
import {useTheme} from '@mui/material';
import {paletteModeDark} from '../context/LightDarkThemeProvider';
import {ReactComponent as DarkModeLogo} from '../../darkmode_logo_sparta_header_CRM.svg';
import {ReactComponent as DHLogo} from '../../DH_logo.svg';
import {ReactComponent as SpartaLogo} from '../../logo_thick_line.svg';

const Logo = () => {
  const auth0 = useAuth0<Auth0User>();
  const {user} = auth0;
  const orgName = getOrgName(user);
  const theme = useTheme();
  return (
    <>
      {orgName?.includes('digitalheartbeat') ? (
        <DHLogo />
      ) : theme.palette.mode === paletteModeDark ? (
        <DarkModeLogo />
      ) : (
        <SpartaLogo />
      )}
    </>
  );
};

export default Logo;
