import React from 'react';
import PageContainer from '../PageContainer';
import CachePageComponent from './CachePageComponent';

export default function CachePage() {
  return (
    <PageContainer title={'Cache'}>
      <CachePageComponent />
    </PageContainer>
  );
}
