import {BehaviorSubject, Observable} from 'rxjs';
import LocalStorageService from './LocalStorageService';
import {
  Auth0User,
  getUserOrganizationRoles,
  getUserRoleId,
} from '../model/Auth0User';

export default class UserRoleService {
  private static INSTANCE = new UserRoleService();
  private localStorageKey = 'lastSelectedRole';

  private selectedRole = new BehaviorSubject<string | null>(null);

  constructor() {
    const lastSelectedRole = LocalStorageService.getInstance().get(this.localStorageKey);
    if (lastSelectedRole) {
      this.selectedRole.next(lastSelectedRole);
    }
  }

  setInitialSelectedRole(user: Auth0User) {
    const storedRole = UserRoleService.getInstance().getCurrentValue();
    const defaultRole = getUserRoleId(user) || '';

    if (storedRole) {
      const hasRole = getUserOrganizationRoles(user).some((role) => role.role_id === storedRole);
      UserRoleService.getInstance().setSelectedRole(hasRole ? storedRole : defaultRole);
    } else {
      UserRoleService.getInstance().setSelectedRole(defaultRole);
    }
  }

  static getInstance(): UserRoleService {
    return UserRoleService.INSTANCE;
  }

  setSelectedRole(selectedRole: string): void {
    LocalStorageService.getInstance().set(this.localStorageKey, selectedRole);
    this.selectedRole.next(selectedRole);
  }

  getCurrentValue(): string | null {
    return this.selectedRole.value;
  }

  getSelectedRole(): Observable<string | null> {
    return this.selectedRole.asObservable();
  }
}
