import insxCloudIcon from '../../assets/icons/INSXCloud.svg';
import spartaChatIcon from '../../assets/icons/sparta_chat.svg';
import spartaCommIcon from '../../assets/icons/sparta_comm.svg';
import spartaMetricsIcon from '../../assets/icons/sparta_metrics.svg';
import spartaPhoneMacIcon from '../../assets/icons/sparta_phone_mac.svg';
import spartaPhoneWindowsIcon from '../../assets/icons/sparta_phone_windows.svg';
import openFolder from '../../assets/icons/open_folder.svg';
import closedFolder from '../../assets/icons/closed_folder.svg';
import removeImage from '../../assets/icons/remove_image.svg';

const iconMap = new Map([
  ['insxCloudIcon', insxCloudIcon],
  ['spartaChatIcon', spartaChatIcon],
  ['spartaCommIcon', spartaCommIcon],
  ['spartaMetricsIcon', spartaMetricsIcon],
  ['spartaPhoneMacIcon', spartaPhoneMacIcon],
  ['spartaPhoneWindowsIcon', spartaPhoneWindowsIcon],
  ['openFolder', openFolder],
  ['closedFolder', closedFolder],
  ['removeImage', removeImage],
]);

export default iconMap;
