import {FormFieldSchema, FormFieldType} from '../FormSchema';
import {
  evalCondition,
  findCondition,
  FormFieldConditionOrBool,
  getConditionDependencies,
} from '../conditions/Condition';
import {pushAllIfAbsent} from '../../utils';
import {ComputeContext} from '../ComputeContext';
// shortcut to allow inline definition in field schema, example { ... "required": "Required field", ...}
export type FormFieldValidationOrMessage = string | FormFieldValidation;

export function isMessage(validationOrMessage: FormFieldValidationOrMessage): boolean {
  return typeof validationOrMessage === 'string';
}

export interface FormFieldValidation {
  message: string | undefined;
  condition: FormFieldConditionOrBool;
}

function _containsValidTypeCondition(validations: FormFieldValidation[], type: string) {
  for (let validation of validations) {
    const found = findCondition(validation.condition, (c) => c.operator === 'is_valid_type' && c.value === type);
    if (found) return true;
  }
  return false;
}

// we may want to append some custom validations based on other field attributes
function _preProcessValidations(field: FormFieldSchema): FormFieldValidation[] {
  const validations: FormFieldValidation[] = [];

  // if required is defined, build the equivalent validation object
  if (field?.required) {
    let validation;
    if (typeof field.required === 'string') {
      validation = {
        message: field.required,
        condition: {
          type: 'single_condition',
          field_name: field.field_name,
          operator: 'is_not_blank',
        },
      } as FormFieldValidation;
    } else {
      validation = {
        message: field.required.message,
        condition: {
          type: 'multiple_condition',
          operator: 'and',
          reverse: true,
          value: [
            {
              type: 'single_condition',
              field_name: field.field_name,
              operator: 'is_blank',
            },
            field.required.condition,
          ],
        },
      } as FormFieldValidation;
    }
    validations.push(validation);
  }

  // some field types do no have native validation so we prepend a `is_valid_type` condition
  switch (field.field_type) {
    case FormFieldType.DATE:
      if (!_containsValidTypeCondition(validations, 'date')) {
        validations.push({
          message: 'Invalid date',
          condition: {
            type: 'single_condition',
            field_name: field.field_name,
            operator: 'is_valid_type',
            value: 'date',
          },
        } as FormFieldValidation);
      }
      break;
    case FormFieldType.TIMESTAMP:
    case FormFieldType.DATETIME:
      if (!_containsValidTypeCondition(validations, 'datetime')) {
        validations.push({
          message: 'Invalid date/time',
          condition: {
            type: 'single_condition',
            field_name: field.field_name,
            operator: 'is_valid_type',
            value: 'datetime',
          },
        } as FormFieldValidation);
      }
      break;
    case FormFieldType.TIME:
      if (!_containsValidTypeCondition(validations, 'time')) {
        validations.push({
          message: 'Invalid time',
          condition: {
            type: 'single_condition',
            field_name: field.field_name,
            operator: 'is_valid_type',
            value: 'time',
          },
        } as FormFieldValidation);
      }
      break;
    case FormFieldType.EMAIL:
      if (!_containsValidTypeCondition(validations, 'email')) {
        validations.push({
          message: 'Invalid email',
          condition: {
            type: 'single_condition',
            field_name: field.field_name,
            operator: 'is_valid_type',
            value: 'email',
          },
        } as FormFieldValidation);
      }
      break;
    // case FormFieldType.PHONE:
    //   if (!_containsValidTypeCondition(validations, 'phone')) {
    //     validations.push({
    //       message: 'Invalid phone number',
    //       condition: {
    //         type: 'single_condition',
    //         field_name: field.field_name,
    //         operator: 'is_valid_type',
    //         value: 'phone',
    //       },
    //     } as FormFieldValidation);
    //   }
    //   break;
    // case FormFieldType.ZIPCODE:
    //   if (!_containsValidTypeCondition(validations, 'zipcode')) {
    //     validations.push({
    //       message: 'Invalid zip code',
    //       condition: {
    //         type: 'single_condition',
    //         field_name: field.field_name,
    //         operator: 'is_valid_type',
    //         value: 'zipcode',
    //       },
    //     } as FormFieldValidation);
    //   }
    //   break;
  }

  if (field?.validations) {
    validations.push(...field.validations);
  }
  return validations;
}

function _buildValidation(validations: FormFieldValidation[], defaultCtx?: ComputeContext): Validation {
  let dependencies: string[] = [];
  let compute: ValidationFunType = DummyValidation.compute;
  if (validations?.length) {
    for (let validation of validations) {
      pushAllIfAbsent(dependencies, getConditionDependencies(validation.condition));
    }
    compute = (allValues: any, ctx?: ComputeContext) => {
      for (const validation of validations) {
        const error = !evalCondition(validation.condition, allValues, {...defaultCtx, ...ctx});
        if (error) return validation.message;
      }
      return undefined;
    };
  }
  return {dependencies, compute};
}

export function buildValidation(field: FormFieldSchema, defaultCtx?: ComputeContext): Validation | undefined {
  const validation = _preProcessValidations(field);
  if (validation.length) {
    return _buildValidation(validation, defaultCtx);
  }
}

export const DummyValidation: Validation = {
  dependencies: [],
  compute: (values: any) => undefined,
};
export type ValidationFunType = (values: any, ctx?: ComputeContext) => string | undefined;

export interface Validation {
  dependencies: string[]; // possible other fields mentioned in the condition
  compute: ValidationFunType;
}
