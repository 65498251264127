import React, {PropsWithChildren} from 'react';
import {Alert, Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Controller, useFormContext} from 'react-hook-form';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {MassiveUpdateFieldToUpdate} from '../MassiveUpdateFormModel';
import {SpartanFieldOption, SpartanFieldProps} from '../../../form/fields/model';
import {FormFieldName, FormFieldType} from '../../../form/logic/FormSchema';
import {SpartanTextField} from '../../../form/fields/SpartanTextField';
import {SpartanEmailField} from '../../../form/fields/SpartanEmailField';
import {SpartanPhoneField} from '../../../form/fields/SpartanPhoneField';
import {SpartanMultiSelectField} from '../../../form/fields/SpartanMultiSelectField';
import {SpartanSelectField} from '../../../form/fields/SpartanSelectField';
import {SpartanNumberField} from '../../../form/fields/SpartanNumberField';
import {SpartanZipCodeField} from '../../../form/fields/SpartanZipCodeField';
import {SpartanDateField} from '../../../form/fields/SpartanDateField';
import {SpartanDateTimeField} from '../../../form/fields/SpartanDateTimeField';
import {toControlledComponentSimpleValue} from '../../../form/logic/utils';
import {SpartanCurrencyField} from '../../../form/fields/SpartanCurrencyField';
import Auth0ContextHolder from '../../../services/Auth0ContextHolder';
import {getOrgName} from '../../../model/Auth0User';
import {SpartanPhoneFavtelField} from '../../../form/fields/SpartanPhoneFavtelField';

interface MassiveUpdateFieldValueProps {
  field: MassiveUpdateFieldToUpdate;
  index: number;
}

function toOptions(massiveUpdateFieldToUpdate: MassiveUpdateFieldToUpdate): SpartanFieldOption[] {
  const options = massiveUpdateFieldToUpdate?.select_options || [];
  return options as SpartanFieldOption[];
}

function MassiveUpdateFieldValue({field, index, children}: PropsWithChildren<MassiveUpdateFieldValueProps>) {
  const {control} = useFormContext();
  const {t} = useTranslation();

  function getController(massiveUpdateFieldToUpdate: MassiveUpdateFieldToUpdate): React.ReactNode {
    return (
      <Controller
        render={({field: controllerField, fieldState}) => {
          const error = fieldState.error ? fieldState.error.message : undefined;
          const hasError = !!error;

          const spartanFieldProps: SpartanFieldProps = {
            onChange: controllerField.onChange,
            onFocus: (event) => {},
            onBlur: controllerField.onBlur,
            id: 'condition-field-' + massiveUpdateFieldToUpdate?.field_name,
            label: t('massive-update.value'),
            name: massiveUpdateFieldToUpdate?.label || 'value',
            value: toControlledComponentSimpleValue(controllerField.value),
            hasError: hasError,
            error: error,
          };

          if (massiveUpdateFieldToUpdate.field_name === FormFieldName.TINY_URL) {
            return <span>{t('massive-update.tiny-url-text')}</span>;
          }
          switch (massiveUpdateFieldToUpdate?.field_type) {
            case FormFieldType.AUTO_ADDRESS:
            case FormFieldType.TEXT:
              return <SpartanTextField {...spartanFieldProps} />;

            case FormFieldType.EMAIL:
              return <SpartanEmailField {...spartanFieldProps} />;

            case FormFieldType.PHONE:
              // TODO Make a refactor to take this from BE: CRM1-561
              const auth0 = Auth0ContextHolder.getInstance().get();
              if (getOrgName(auth0?.user) === 'favtel') return <SpartanPhoneFavtelField {...spartanFieldProps} />;
              return <SpartanPhoneField {...spartanFieldProps} />;

            case FormFieldType.SELECTMULTIPLE:
              return <SpartanMultiSelectField {...spartanFieldProps} options={toOptions(massiveUpdateFieldToUpdate)} />;

            case FormFieldType.SELECT:
              return <SpartanSelectField {...spartanFieldProps} options={toOptions(massiveUpdateFieldToUpdate)} />;

            case FormFieldType.NUMBER:
            case FormFieldType.FLOAT:
              return <SpartanNumberField {...spartanFieldProps} />;

            case FormFieldType.ZIPCODE:
              return <SpartanZipCodeField {...spartanFieldProps} />;

            case FormFieldType.DATE:
              return <SpartanDateField {...spartanFieldProps} />;

            case FormFieldType.TIMESTAMP:
              return <SpartanDateTimeField {...spartanFieldProps} />;

            case FormFieldType.CURRENCY:
              return <SpartanCurrencyField {...spartanFieldProps} />;

            default:
              return (
                <Alert severity="warning">
                  {t('shared.no-supported-field-type')} {massiveUpdateFieldToUpdate?.field_type}
                </Alert>
              );
          }
        }}
        name={`fields_update.${index}.value`}
        control={control}
        rules={{
          required: false,
        }}
      />
    );
  }

  return (
    <Grid
      container
      item
      xs={12}
      alignItems={'flex-end'}
      sx={{
        padding: (theme) => theme.spacing(2),
        marginTop: {xs: 3, md: 0},
      }}
    >
      <Grid item xs={10} sm={4} alignSelf={'center'}>
        <Typography variant={'body1'}>{field.label}</Typography>
      </Grid>
      <Grid item xs={2}>
        <ArrowRightAltIcon />
      </Grid>
      <Grid item xs={10} sm={4}>
        {getController(field)}
      </Grid>
      <Grid item xs={2}>
        {children}
      </Grid>
    </Grid>
  );
}

export default MassiveUpdateFieldValue;
