import {createContext, PropsWithChildren, useContext} from 'react';
import {FormCoRule} from '../model/FormCoRule';

const Context = createContext<CoRuleContext | null>(null);

interface Props extends PropsWithChildren {
  corule: FormCoRule;
}

export const CoruleProvider = ({corule, children}: Props) => {
  return <Context.Provider value={{corule}}>{children}</Context.Provider>;
};

export interface CoRuleContext {
  corule: FormCoRule;
}

export function useCoRuleContext(): CoRuleContext {
  const value = useContext(Context);
  if (!value) throw Error('invalid context');
  return value;
}
