import {ListItemBase} from './BaseItems';
export enum ScheduleType {
  OFFICE_HOURS = 'office_hours',
  AFTER_HOURS = 'after_hours',
}

export enum ActionType {
  SMS = 'sms',
  CAL = 'call',
  EMAIL = 'email',
  CALL_NOW = 'call_now',
}

export enum QueueType {
  AFTER_HOURS = 'after_hours',
  CALL_NOW = 'call_now',
}

interface AutoActionBase {
  auto_action_id: string;
  name: string;
  action_type: ActionType;
  schedule_type: ScheduleType;
  start_time: number;
  query_id: string;
  status: string;
}

export interface AutoActionListItem extends AutoActionBase, ListItemBase {}
export interface AutoAction extends AutoActionBase {
  call_now_script?: string;
  guide_id?: string;
  local_presence?: boolean
  bucket_id?: string
}
