import {BehaviorSubject} from 'rxjs';
import {QueryDetail, QueryMode} from '../model/Query';
import {Condition, QueryFieldSchema} from '../model/Query';
import {getDefaultConditionOperator} from '../pages/query/ConditionOperator';

export function toQueryDetails(resp: QueryDetail): QueryDetail {
  return {
    ...resp,
    query: {
      ...resp.query,
      ...{
        query_conditions: resp.query.query_conditions?.map((condition) => {
          return {
            ...condition,
            ...{values: condition.values ?? []},
          };
        }),
      },
    },
  };
}

export default class QueryService {
  private static INSTANCE = new QueryService();
  private queryMode = new BehaviorSubject<QueryMode>(QueryMode.PUBLIC);

  static getInstance(): QueryService {
    return QueryService.INSTANCE;
  }

  setQueryModeFilter(queryMode: QueryMode) {
    this.queryMode.next(queryMode);
  }

  getQueryModeFilter(): QueryMode {
    return this.queryMode.value;
  }

  handleOnFieldClick(
    field: QueryFieldSchema,
    fields: QueryFieldSchema[],
    fieldNameStatus: string,
    insert: Function,
    append: Function
  ): void {
    const type = getDefaultConditionOperator(field.data_type);

    const fieldToAdd: Condition = {
      field_id: field.field_id,
      field_name: field.field_name,
      data_type: field.data_type,
      field_type: field.field_type,
      condition_type: type,
      values: [{value: ''}],
    };

    const fieldsLength = fields?.length - 1;

    const hasStatusField = fields[fieldsLength]?.field_name === fieldNameStatus;

    if (hasStatusField) {
      insert(fieldsLength, fieldToAdd);
    } else {
      append(fieldToAdd);
    }
  }
}
