import {Fragment, useEffect, useState} from 'react';
import List from '@mui/material/List';
import {Collapse} from '@mui/material';
import {NavItem} from './NavItem';
import {SpartanMenuItem} from '../model/SpartanMenuItems';
import {FeatureName} from '../../paths';
import UserRoleService from '../services/UserRoleService';
import {NextgenFormSchema} from '../form/api/middleware/NextgenFormSchema';
import NotificationService, {NotificationType} from '../services/NotificationService';
import {useAxiosContext} from '../context/AxiosContext';
import {useLoading} from '../context/LoadingContext';

interface SideNavProps {
  isSideNavOpen: boolean;
}
function SideNav({isSideNavOpen}: SideNavProps) {
  const {useAxiosFormAPI} = useAxiosContext();
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [sideNavItems, setSideNavItems] = useState<Array<SpartanMenuItem>>([]);
  const {setLoading} = useLoading();
  const handleCollapseStatus = () => {
    setOpenCollapse(!openCollapse);
  };
  const [{response: getMenuItemsResponse, error: getMenuItemsError, loading: itemsLoading}, getMenuItems] =
    useAxiosFormAPI<NextgenFormSchema>(
      {
        url: `/featurefields`,
        method: 'GET',
        params: {feature_name: FeatureName.SIDENAV},
      },
      {manual: true}
    );

  useEffect(() => {
    if (getMenuItemsResponse) {
      setSideNavItems(getMenuItemsResponse?.data?.menu_items || []);
    }
  }, [getMenuItemsResponse]);

  useEffect(() => {
    setLoading(itemsLoading, 'SideNav');
  }, [itemsLoading]);

  useEffect(() => {
    if (getMenuItemsError) {
      NotificationService.getInstance().sendNotification(
        getMenuItemsError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [getMenuItemsError]);

  useEffect(() => {
    getMenuItems();
  }, [UserRoleService.getInstance().getCurrentValue()]);

  return (
    <List sx={{paddingLeft: '8px'}}>
      {sideNavItems?.map((item: SpartanMenuItem, index: number) => {
        if (item.children) {
          return (
            <Fragment key={index}>
              <NavItem
                key={item.menu_item_id}
                title={item.label}
                icon={item.icon}
                onClick={handleCollapseStatus}
                isCollapse={openCollapse}
                tooltip={item.tooltip}
              />

              <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{marginLeft: isSideNavOpen ? {xs: 0, md: '15px'} : undefined}}>
                  {item?.children.map((item: SpartanMenuItem) => (
                    <NavItem
                      href={item.action}
                      key={item.menu_item_id}
                      title={item.label}
                      icon={item.icon}
                      isCollapse={openCollapse}
                      actionMessage={item.action_message}
                      tooltip={item.tooltip}
                    />
                  ))}
                </List>
              </Collapse>
            </Fragment>
          );
        } else {
          return (
            <NavItem
              href={item.action}
              key={item.menu_item_id}
              title={item.label}
              icon={item.icon}
              isCollapse={openCollapse}
              tooltip={item.tooltip}
            />
          );
        }
      })}
    </List>
  );
}

export default SideNav;
