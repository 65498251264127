import {createContext, PropsWithChildren, useContext} from 'react';
import {useEntities} from '../hooks/useEntities';
import {compareByName, FormAction} from '../model/FormAction';
import {compareByPriority, FormRole} from '../model/FormRole';

const Context = createContext<SharedEntitiesContext | null>(null);

interface Props extends PropsWithChildren {}

export const SharedEntitiesProvider = ({children}: Props) => {
  const {entities: actions} = useEntities<FormAction>('actions', 'actions', compareByName);
  const {entities: roles} = useEntities<FormRole>('roles', 'roles', compareByPriority);
  return <Context.Provider value={{actions, roles}}>{children}</Context.Provider>;
};

export interface SharedEntitiesContext {
  actions: FormAction[] | null;
  roles: FormRole[] | null;
}

export function useSharedEntitiesContext(): SharedEntitiesContext {
  const value = useContext(Context);
  if (!value) throw Error('invalid context');
  return value;
}
