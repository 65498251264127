import {Alert, Snackbar} from '@mui/material';
import {useEffect, useState} from 'react';
import NotificationService, {NotificationMessage} from '../../services/NotificationService';
import {SnackbarCloseReason} from '@mui/material/Snackbar/Snackbar';
import {TestAttributes} from '../../TestAttributes';

function Notification() {
  const [notifications, setNotifications] = useState<NotificationMessage[]>([]);
  const [currentNotification, setCurrentNotification] = useState<NotificationMessage | undefined>();

  useEffect(() => {
    const subscription = NotificationService.getInstance()
      .getNotification()
      .subscribe((notification: NotificationMessage) => {
        setNotifications((prev) => {
          console.debug('Received notification:', notification);
          return [...prev, notification];
        });
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    // if notificationToShow is undefined and notifications still has elements, set the first element as notificationToShow
    // To improve user experience, we introduce a short delay between notifications
    if (!currentNotification && notifications.length) {
      const taskId = setTimeout(() => {
        const nextNotifications = [...notifications];
        const nextNotificationToShow = nextNotifications.shift();
        console.debug('Show notification:', nextNotificationToShow);
        setCurrentNotification(nextNotificationToShow);
        setNotifications(nextNotifications);
      }, 300);
      return () => clearTimeout(taskId);
    }
  }, [currentNotification, notifications]);

  function closeCurrentNotification() {
    console.debug('Close notification:', currentNotification);
    setCurrentNotification(undefined);
  }

  return currentNotification ? (
    <Snackbar
      open={true}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      autoHideDuration={5000}
      onClose={(event, reason: SnackbarCloseReason) => {
        if (reason !== 'clickaway') {
          closeCurrentNotification();
        }
      }}
      sx={{marginTop: (theme) => theme.spacing(18)}}
    >
      <Alert
        onClose={() => closeCurrentNotification()}
        severity={currentNotification.type}
        sx={{width: '100%'}}
        {...{[TestAttributes.ALERT_NAME]: 'alert-component'}}
      >
        {currentNotification.text}
      </Alert>
    </Snackbar>
  ) : (
    <></>
  );
}

export default Notification;
