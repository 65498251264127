import React, {useCallback, useEffect, useRef, useState} from 'react';
import IMask from 'imask';
import {buildSetValue, propsWithTestAttributes, SpartanFieldProps} from '../model';

export interface WithIMaskOptions {
  preProcessValue?: (value: any) => string;
  postProcessValue?: (unmaskedValue: string) => any;
}

export function withIMask<T extends SpartanFieldProps>(
  WrappedComponent: React.ComponentType<T>,
  iMaskConfig: IMask.AnyMaskedOptions,
  options?: WithIMaskOptions
) {
  const preProcessValue = options?.preProcessValue ? options.preProcessValue : (v: any) => v;
  const postProcessValue = options?.postProcessValue ? options.postProcessValue : (v: any) => v;

  function initMaskValue(mask: IMask.InputMask<any> | false, value: any) {
    if (mask && value !== undefined) {
      const preProcessedValue = preProcessValue(value);
      if (typeof preProcessedValue !== 'string') {
        throw new Error(
          `Masked fields only support string values (use preProcessValue/postProcessValue to convert value to/from string)`
        );
      }
      mask.value = preProcessedValue;
    }
  }

  const DecoratedComponent = (props: T) => {
    const inputRef = useRef<HTMLInputElement>();
    // remove `name`, `value`, `onChange` from wrappedComponentProps to prevent WrappedComponent from binding to the form
    const {name, value, onChange, onBlur, onFocus, ...wrappedComponentProps} = propsWithTestAttributes(props);
    const [mask, setMask] = useState<IMask.InputMask<any> | false>(false);

    useEffect(() => {
      if (!inputRef.current) throw Error(`Missing input HTMLElement for field '${props.name}'`);
      const _mask = IMask(inputRef.current, {lazy: true, ...iMaskConfig});
      const setValue = buildSetValue(props);

      function accept(event: any) {
        const newValue = postProcessValue(_mask.unmaskedValue);
        if (newValue !== undefined) setValue(newValue);
      }

      _mask.on('accept', accept);

      initMaskValue(_mask, value);

      setMask(_mask);

      return () => {
        _mask.off('accept', accept);
        _mask.destroy();
      };
    }, []);

    useEffect(() => initMaskValue(mask, value), [value]);

    // since we do not pass down `name`, some handlers will not work properly, so we need to patch them
    const onChangePatched = undefined; // do nothing on change, the mask itself takes care of updating the value
    const onBlurPatched = useCallback(
      (event: any) => onBlur({...event, target: {...event.target, name}}),
      [name, onBlur]
    );
    const onFocusPatched = useCallback(
      (event: any) => onFocus({...event, target: {...event.target, name}}),
      [name, onFocus]
    );

    return (
      <WrappedComponent
        {...(wrappedComponentProps as T)}
        onChange={onChangePatched}
        onBlur={onBlurPatched}
        onFocus={onFocusPatched}
        inputRef={inputRef}
      />
    );
  };

  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  DecoratedComponent.displayName = `withIMask(${displayName})`;

  return DecoratedComponent;
}
