import moment, {Moment} from 'moment';

export const SPARTAN_DATE_FORMAT = 'yyyy-MM-DD';
export const SPARTAN_DATETIME_FORMAT = 'yyyy-MM-DD HH:mm:ss';
export const SPARTAN_TIME_FORMAT = 'HH:mm:ss';
export const TIMEZONE_REGEX = /[-+](\d+|\d{2}:\d{2})$/;

export function formatDate(value: any): string {
  const m = toMoment(value);
  const f = m.hours(0).minutes(0).seconds(0).milliseconds(0).format(SPARTAN_DATE_FORMAT);
  console.debug('[DateUtils] formatDate', value, '>>', f);
  return f;
}

export function formatDateTime(value: any): string {
  const m = toMoment(value);
  const f = m.seconds(0).milliseconds(0).format(SPARTAN_DATETIME_FORMAT);
  console.debug('[DateUtils] formatDateTime', value, '>>', f);
  return f;
}

export function formatTime(value: any): string {
  const m = toMoment(value);
  const f = m.seconds(0).milliseconds(0).date(1).month(1).year(1970).format(SPARTAN_TIME_FORMAT);
  console.debug('[DateUtils] formatTime', value, '>>', f);
  return f;
}

export function isDate(value: any): boolean {
  return moment(value, SPARTAN_DATE_FORMAT, true).isValid();
}

export function isDateTime(value: any, ignoreTimezone = true): boolean {
  if (ignoreTimezone && typeof value === 'string') {
    value = value.replace(TIMEZONE_REGEX, '');
  }
  return (
    moment(value, SPARTAN_DATETIME_FORMAT, true).isValid() ||
    moment(value, 'yyyy-MM-DD HH:mm:ss.SSS', true).isValid() ||
    moment(value, 'yyyy-MM-DD HH:mm:ss.SSSSSS', true).isValid()
  );
}

export function isTime(value: any): boolean {
  return moment(value, SPARTAN_TIME_FORMAT, true).isValid();
}

export function parseDate(value: any): Date {
  const m = toMoment(value);
  const date = m.toDate();
  console.debug('[DateUtils] parseDate', value, '>>', date);
  return date;
}

export function parseDateTime(value: any, ignoreTimezone = true): Date {
  if (ignoreTimezone && typeof value === 'string') {
    value = value.replace(TIMEZONE_REGEX, '');
  }
  const m = toMoment(value);
  const date = m.toDate();
  console.debug('[DateUtils] parseDateTime', value, '>>', date);
  return date;
}

export function parseTime(value: any): Date {
  const m = toMoment(value, SPARTAN_TIME_FORMAT).date(1).month(0).year(1900); // start at year 0 for Date
  const date = m.toDate();
  console.debug('[DateUtils] parseTime', value, '>>', date);
  return date;
}

/**
 * Unix time (aka Epoch time) is the number of seconds elapsed since January 1 1970
 */
export function toEpoch(value: any): number {
  const epoch = toMoment(value).toDate().getTime() / 1000;
  console.debug('[DateUtils] toEpoch', value, '>>', epoch);
  return epoch;
}

// do not export toMoment, let's try to hide moment library as long as possible
function toMoment(value: any, format?: string): Moment {
  if (format) return moment(value, format, true);
  return moment(value, true);
}
