import {Controller, Message, useFormContext, useWatch} from 'react-hook-form';
import React, {Fragment, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {FormFieldType} from '../../../form/logic/FormSchema';
import {SpartanFieldProps} from '../../../form/fields/model';
import {QueryFieldSchema} from '../../../model/Query';
import {SpartanDateField} from '../../../form/fields/SpartanDateField';
import {SpartanDateTimeField} from '../../../form/fields/SpartanDateTimeField';
import {ErrorMessage} from '../../../form/fields/components/ErrorMessage';
import {invalidDate} from '../utils/queryValidationMethods';
import {Grid} from '@mui/material';

interface DateControllerProps {
  conditionIndex: number;
  valueIndex: number;
  conditionFieldSchema?: QueryFieldSchema;
}

function DateBetweenController({conditionIndex, valueIndex, conditionFieldSchema}: DateControllerProps) {
  const {t} = useTranslation();

  const {control, getValues, trigger} = useFormContext();

  const toDate = useWatch({control, name: `query_conditions.${conditionIndex}.values.${valueIndex}.to`});

  const fromDate = useWatch({control, name: `query_conditions.${conditionIndex}.values.${valueIndex}.from`});

  useEffect(() => {
    trigger(`query_conditions.${conditionIndex}.values.${valueIndex}.from`);
  }, [toDate]);

  useEffect(() => {
    trigger(`query_conditions.${conditionIndex}.values.${valueIndex}.to`);
  }, [fromDate]);

  const validation = (value: string): Message | Message[] | boolean | undefined => {
    const dateFrom = new Date(getValues(`query_conditions.${conditionIndex}.values.${valueIndex}.from`));
    const dateTo = new Date(getValues(`query_conditions.${conditionIndex}.values.${valueIndex}.to`));
    const requiredErrorMsj = t('query.error-messages.required-date');
    const biggerErrorMsj = t('query.error-messages.bigger-date');

    if (value === invalidDate) {
      return invalidDate;
    }

    if (dateTo?.getDate() && dateFrom?.getTime()) {
      if (dateFrom < dateTo) {
        return true;
      } else {
        return biggerErrorMsj;
      }
    } else {
      return requiredErrorMsj;
    }
  };

  return (
    <>
      <Controller
        render={({field: controllerField, fieldState}) => {
          const error = fieldState.error ? fieldState.error.message : undefined;
          const hasError = !!error;

          const spartanFieldProps: SpartanFieldProps = {
            onChange: controllerField.onChange,
            onFocus: (e: any) => {},
            onBlur: controllerField.onBlur,
            value: controllerField.value,
            id: conditionIndex + '-' + valueIndex + '-' + conditionFieldSchema?.field_id,
            name: conditionFieldSchema?.label_search || 'value',
            label: valueIndex === 0 ? 'from' : 'OR from',
            hasError: hasError,
            error: error,
            isNestedLabel: true,
          };

          return (
            <Grid container item xs={12} sx={{marginBottom: (theme) => theme.spacing(1)}}>
              {conditionFieldSchema?.field_type === FormFieldType.DATE ? (
                <SpartanDateField {...spartanFieldProps} />
              ) : (
                <SpartanDateTimeField {...spartanFieldProps} />
              )}
            </Grid>
          );
        }}
        name={`query_conditions.${conditionIndex}.values.${valueIndex}.from`}
        control={control}
        rules={{
          required: true,
          validate: (value) => validation(value),
        }}
        defaultValue={getValues(`query_conditions.${conditionIndex}.values.${valueIndex}.from`)}
      />

      <Controller
        render={({field: controllerField, fieldState}) => {
          const spartanFieldProps: SpartanFieldProps = {
            onChange: controllerField.onChange,
            onFocus: (e: any) => {},
            onBlur: controllerField.onBlur,
            value: controllerField.value,
            id: conditionIndex + '-' + valueIndex + '-' + conditionFieldSchema?.field_id,
            name: conditionFieldSchema?.label_search || 'value',
            label: 'to',
            isNestedLabel: true,
          };

          return (
            <>
              {conditionFieldSchema?.field_type === FormFieldType.DATE ? (
                <SpartanDateField {...spartanFieldProps} />
              ) : (
                <SpartanDateTimeField {...spartanFieldProps} />
              )}
              <ErrorMessage hasError={fieldState.invalid} error={fieldState.error?.message} />
            </>
          );
        }}
        name={`query_conditions.${conditionIndex}.values.${valueIndex}.to`}
        control={control}
        rules={{
          required: true,
          validate: validation,
        }}
        defaultValue={getValues(`conditions.${conditionIndex}.values.${valueIndex}.to`)}
      />
    </>
  );
}

export default React.memo(DateBetweenController);
