import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Container, Divider, Grid, styled, Typography} from '@mui/material';
import UserGuideEditor from '../../components/user-guide/editor/UserGuideEditor';
import PageHeadline from '../../components/PageHeadline';
import {PageStickyHeader} from '../PageStickyHeader';
import {PageTopActions} from '../PageTopActions';
import {CampaignScript} from '../../model/CampaignScript';
import UserGuideStepper from '../../components/user-guide/UserGuideStepper';
import {difference} from '../../form/utils';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName} from '../../../paths';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {CallScriptResponse, toScript} from '../../services/CallScriptService';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import FeatureNotFound from '../notFound/FeatureNotFound';

const ScriptSample = styled('div')(({theme}) => ({
  overflowY: 'scroll',
  [theme.breakpoints.up('sm')]: {
    position: 'inherit',
  },
  [theme.breakpoints.up('md')]: {
    position: 'fixed',
    top: '200px',
    bottom: '20px',
    maxWidth: '50%',
  },
}));

function CallScriptManagerPage() {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const {id} = useParams();
  const {setLoading} = useLoading();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isValidId, setIsValidId] = useState<boolean>(true);
  const methods = useForm<CampaignScript>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {formState} = methods;
  const {isValid} = formState;

  const [updatedScript, setUpdatedScript] = useState<CampaignScript>();
  const [campaignScriptData, setCampaignScriptData] = useState<CampaignScript>();
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.USER_GUIDE);
  const [{loading: isEntityListLoading}, getCampaignScriptList] = useAxiosBFF<ResponseListWrapper<CampaignScript>>(
    `/${FeatureName.USER_GUIDE}`
  );

  const [
    {data: CampaignScriptDataResponse, loading: isCampaignScriptLoading, error: getCampaignScriptDataError},
    getCampaignScriptData,
  ] = useAxiosBFF<CallScriptResponse>(`/${FeatureName.USER_GUIDE}/${id}`, {manual: true});

  const [
    {loading: deleteCampaignScriptLoading, error: deleteCampaignScriptError, response: deleteCampaignScriptResponse},
    deleteCampaignScriptData,
  ] = useAxiosBFF<CampaignScript>({url: `/${FeatureName.USER_GUIDE}/${id}`, method: 'DELETE'}, {manual: true});

  const [
    {loading: isCampaignScriptPatchLoading, error: patchCampaignScriptError, response: patchCampaignScriptResponse},
    patchCampaignScriptData,
  ] = useAxiosBFF<CampaignScript>(
    {url: `/${FeatureName.USER_GUIDE}/${id}`, method: 'PATCH', params: {formatted: true}},
    {manual: true}
  );

  const [
    {loading: postCampaignScriptResponseLoading, error: postCampaignScriptError, response: postCampaignScriptResponse},
    postCampaignScriptData,
  ] = useAxiosBFF<CampaignScript>({url: `/${FeatureName.USER_GUIDE}`, method: 'POST'}, {manual: true});

  useEffect(() => {
    const loading =
      deleteCampaignScriptLoading ||
      isCampaignScriptLoading ||
      isCampaignScriptPatchLoading ||
      postCampaignScriptResponseLoading;
    setLoading(loading, 'CallScriptManagerPage');
  }, [
    deleteCampaignScriptLoading,
    isCampaignScriptLoading,
    isCampaignScriptPatchLoading,
    postCampaignScriptResponseLoading,
  ]);

  useEffect(() => {
    if (getCampaignScriptDataError) {
      NotificationService.getInstance().sendNotification('Not found', NotificationType.ERROR);
      setIsValidId(false);
    }
  }, [getCampaignScriptDataError]);

  useEffect(() => {
    if (postCampaignScriptResponse?.status === 200) {
      getCampaignScriptList().then(() => {
        NotificationService.getInstance().sendNotification(
          t('shared.successfully-created-msg'),
          NotificationType.SUCCESS
        );
        navigate(`/${FeatureName.USER_GUIDE}/${postCampaignScriptResponse.data['guide_id']}`);
      });
    }
  }, [postCampaignScriptResponse]);
  useEffect(() => {
    if (deleteCampaignScriptResponse?.status === 200) {
      getCampaignScriptList().then(() => {
        NotificationService.getInstance().sendNotification(
          deleteCampaignScriptResponse?.data?.['message'],
          NotificationType.SUCCESS
        );
        navigate(`/${FeatureName.USER_GUIDE}`);
      });
    }
  }, [deleteCampaignScriptResponse]);
  useEffect(() => {
    if (patchCampaignScriptResponse?.status === 200) {
      NotificationService.getInstance().sendNotification(
        t('shared.successfully-updated-msg'),
        NotificationType.SUCCESS
      );
    }
  }, [patchCampaignScriptResponse]);

  useEffect(() => {
    const error = deleteCampaignScriptError || postCampaignScriptError || patchCampaignScriptError;
    if (error) {
      NotificationService.getInstance().sendNotification(error?.response?.data?.message, NotificationType.ERROR);
    }
  }, [postCampaignScriptError, patchCampaignScriptError, deleteCampaignScriptError]);

  useEffect(() => {
    if (id) {
      getCampaignScriptData();
    }
  }, [id]);

  useEffect(() => {
    setUpdatedScript(campaignScriptData);
  }, [campaignScriptData]);

  useEffect(() => {
    if (CampaignScriptDataResponse) {
      setCampaignScriptData(toScript(CampaignScriptDataResponse || ''));
    }
  }, [CampaignScriptDataResponse]);

  const saveScript = useCallback(() => {
    if (updatedScript) {
      let data: any = JSON.parse(JSON.stringify(updatedScript));

      if (id) {
        data = difference(updatedScript, CampaignScriptDataResponse);
      }
      if (data?.guide_content) {
        data.guide_content = JSON.stringify(data.guide_content);
      }
      if (id) {
        patchCampaignScriptData({
          data,
        });
      } else {
        postCampaignScriptData({
          data,
        });
      }
    }
  }, [updatedScript, CampaignScriptDataResponse]);

  function handleDeleteUserGuide() {
    if (id) {
      deleteCampaignScriptData();
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        {isValidId ? (
          <FormProvider {...methods}>
            <Grid container spacing={3}>
              <PageStickyHeader>
                <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
                  <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                    <PageHeadline>
                      <div>
                        {t('call-script-page.headline')}
                        {updatedScript?.guide_name && (
                          <Typography variant={'h4'}>{`${updatedScript?.guide_name}`}</Typography>
                        )}
                      </div>
                    </PageHeadline>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PageTopActions>
                      {id && (
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={[UserPermissions.DEACTIVATE_FEATURE_ITEM]}
                        >
                          <Button
                            id={'delete-btn'}
                            onClick={() => {
                              setIsConfirmationDialogOpen(true);
                            }}
                          >
                            {t('shared.deactivate')}
                          </Button>
                        </AccessControl>
                      )}
                      <AccessControl
                        userPermissions={userPermissions}
                        allowedPermissions={[id ? UserPermissions.MODIFY : UserPermissions.CREATE]}
                      >
                        <Button id={'save-btn'} onClick={() => saveScript()} disabled={!isValid}>
                          {id ? t('shared.update') : t('shared.save')}
                        </Button>
                      </AccessControl>
                    </PageTopActions>
                  </Grid>
                </Grid>
              </PageStickyHeader>
              <Divider />

              <Grid container item spacing={5}>
                <Grid item xs={12} md={6}>
                  <UserGuideEditor
                    script={campaignScriptData}
                    onChange={(v: CampaignScript) => setUpdatedScript(v)}
                  ></UserGuideEditor>
                </Grid>
                <Grid item container xs={12} md={6} spacing={3}>
                  <ScriptSample>
                    <Grid item xs={12}>
                      <Typography variant={'h2'}>{t('call-script-page.preview')}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={11}
                      sx={{
                        border: (theme) => `1px solid ${theme.palette.divider}`,
                        borderRadius: '5px',
                        padding: '14px',
                      }}
                    >
                      <UserGuideStepper steps={updatedScript?.guide_content.steps || []}></UserGuideStepper>
                    </Grid>
                  </ScriptSample>
                </Grid>
              </Grid>
            </Grid>
          </FormProvider>
        ) : (
          <FeatureNotFound />
        )}
        <ConfirmationDialog
          message={t('shared.deactivate-message')}
          headline={t('shared.deactivate')}
          isDialogOpen={isConfirmationDialogOpen}
          handleClose={() => setIsConfirmationDialogOpen(false)}
        >
          <Button
            onClick={() => {
              handleDeleteUserGuide();
              setIsConfirmationDialogOpen(false);
            }}
            id="confirmation-dialog-action-btn"
          >
            {t('shared.accept')}
          </Button>
        </ConfirmationDialog>
      </Container>
    </Box>
  );
}

export default CallScriptManagerPage;
