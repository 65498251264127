import {ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {GridDensity, GridDensityTypes} from '@mui/x-data-grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {TestAttributes} from '../../../TestAttributes';
import {useUserSettingsContext} from '../../../context/UserSettingsContext';

export default function DensityRadioGroup() {
  const {t} = useTranslation();
  const {userSettings, setPartialUserSettings} = useUserSettingsContext();

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    setPartialUserSettings({grid_density: event.target.value as GridDensity});
  }

  return (
    <FormControl>
      <FormLabel id="radio-buttons-group-table-rows-label">{t('density.title')}</FormLabel>
      <RadioGroup
        aria-labelledby="radio-buttons-group-label"
        id="radio-buttons-group-table-rows"
        name="radio-buttons-group"
        value={userSettings.grid_density}
        onChange={handleChange}
      >
        <FormControlLabel
          value={GridDensityTypes.Compact}
          control={<Radio id="radio-button-compact" size="small" />}
          label={`${t('density.compact')}`}
          {...{[TestAttributes.FIELD_OPTION_NAME]: 'radio-button-compact'}}
        />
        <FormControlLabel
          value={GridDensityTypes.Standard}
          control={<Radio id="radio-button-standard" size="small" />}
          label={`${t('density.standard')}`}
          {...{[TestAttributes.FIELD_OPTION_NAME]: 'radio-button-standard'}}
        />
        <FormControlLabel
          value={GridDensityTypes.Comfortable}
          control={<Radio id="radio-button-comfortable" size="small" />}
          label={`${t('density.comfortable')}`}
          {...{[TestAttributes.FIELD_OPTION_NAME]: 'radio-button-comfortable'}}
        />
      </RadioGroup>
    </FormControl>
  );
}
