import React, {PropsWithChildren, useEffect, useState} from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  isSpannerDdlResult,
  isSpannerDmlResult,
  isSpannerSqlResult,
  SpannerDmlResult,
  SpannerResult,
  SpannerSqlResult,
} from './model';
import Button from '@mui/material/Button';

interface Props {
  results: SpannerResult[];
}

interface Props extends PropsWithChildren {
  fullScreen?: boolean;
  open?: boolean;
  handleClose?: () => void;
}

const styleDialogBox = {
  height: '90vh',
  width: '100%',
  padding: 2,
};

export default function SpannerResultsComponent({results}: Props) {
  const [open, setOpen] = useState<boolean>(true);

  return (
    <>
      {open || <Button onClick={() => setOpen(true)}>re-open last result</Button>}
      <Dialog fullWidth={true} maxWidth={'xl'} open={open} onClose={() => setOpen(false)}>
        <Box sx={styleDialogBox}>
          <SpannerResultTabsComponent results={results} />
        </Box>
      </Dialog>
    </>
  );
}

function SpannerResultTabsComponent({results}: Props) {
  const [tab, setTab] = useState<string>();

  useEffect(() => {
    if (results) {
      setTab(results[0].database_id);
    } else {
      setTab(undefined);
    }
  }, [results]);

  return (
    <Grid container spacing={2}>
      {tab ? (
        <>
          {results.map((r) => (
            <Grid item>
              <Button variant={r.database_id === tab ? 'contained' : 'outlined'} onClick={() => setTab(r.database_id)}>
                {r.database_id}
              </Button>
            </Grid>
          ))}
          {results.map((r) => (
            <Grid item xs={12} sx={{display: r.database_id === tab ? 'block' : 'none'}}>
              <SpannerResultComponent result={r} />
            </Grid>
          ))}
        </>
      ) : results && results.length ? (
        <CircularProgress />
      ) : (
        <Typography>no results</Typography>
      )}
    </Grid>
  );
}

function SpannerResultComponent({result}: {result: SpannerResult}) {
  const hasResult = result.result !== undefined && result.result !== null;
  return (
    <>
      {result.error ? (
        <Alert severity={'error'}>{result.error}</Alert>
      ) : hasResult && isSpannerSqlResult(result) ? (
        <SpannerSqlResultComponent result={result} />
      ) : hasResult && isSpannerDmlResult(result) ? (
        <SpannerDmlResultComponent result={result} />
      ) : hasResult && isSpannerDdlResult(result) ? (
        <Alert severity={'info'}>{result.result}</Alert>
      ) : (
        <Typography>this should never happen, check network tab</Typography>
      )}
    </>
  );
}

function toString(value: any): string {
  if (typeof value === 'object') {
    return JSON.stringify(value);
  } else {
    return `${value}`;
  }
}

function SpannerSqlResultComponent({result}: {result: SpannerSqlResult}) {
  if (!result || !result.result || !result.result.length) {
    return <Typography>no results</Typography>;
  }
  const headers = result.result[0];
  const rows = result.result.slice(1);
  return (
    <>
      <TableContainer component={Paper} sx={{maxHeight: '100%'}}>
        <Table sx={{minWidth: 650}} size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {headers
                .map((header) => toString(header))
                .map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.join()} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                {row
                  .map((cell) => toString(cell))
                  .map((cell) => (
                    <TableCell key={cell}>{cell}</TableCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function SpannerDmlResultComponent({result}: {result: SpannerDmlResult}) {
  if (!result || !result.result || !result.result.length) {
    return <Typography>no results</Typography>;
  }
  return (
    <>
      {result.result.map((r, index) => (
        <>
          {index > 0 && <br />}
          {r.status === 'error' ? (
            <Alert severity={'error'}>
              <pre>{r.statement}</pre>
              <span>{r.error}</span>
            </Alert>
          ) : r.status === 'ok' ? (
            <Alert severity={'info'}>
              <pre>{r.statement}</pre>
              <span>{r.affected_rows} affected row(s)</span>
            </Alert>
          ) : (
            <Alert severity={'warning'} sx={{backgroundColor: '#eee'}}>
              <pre>{r.statement}</pre>
              <span>{r.status}</span>
            </Alert>
          )}
        </>
      ))}
    </>
  );
}
