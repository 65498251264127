export interface ResponseFile {
  message: string;
  statusCode: number;
  url: string;
  filename?: string;
  campaign_id?: string;
  extension?: string;
  size?: string;
  created_at?: string;
}

export enum FolderPath {
  LEADS_ATTACHMENTS = 'attachments/leads/',
  EXPORTS = 'exports/',
  SMS_LOG_ARCHIVE = 'exports/sms_logs/',
  MMS = 'mms',
  IVR = 'ivrs',
  VOICEMAIL = 'voicemails',
  EMAIL = 'emails',
}

export interface FileContent {
  folder: string;
  filename: string;
  type: string;
}
