import {FormFieldValidation, FormFieldValidationOrMessage} from '../../logic/validations/Validation';
import {
  FormFieldCondition,
  FormFieldConditionOrBool,
  FormFieldMultipleCondition,
  FormFieldMultipleConditionOperator,
  FormFieldSingleCondition,
  FormFieldSingleConditionOperator,
  FormFieldSingleConditionOperators,
  isCondition,
} from '../../logic/conditions/Condition';
import {hasText} from '../../logic/utils';
import {removeFromArray} from '../../utils';

// used when explicit message is no provided
export const DEFAULT_REQUIRED_MESSAGE = 'Required';

export interface ParseRulesOutput {
  validations?: FormFieldValidation[];
  required?: FormFieldValidationOrMessage;
  disabled?: FormFieldConditionOrBool;
  hidden?: FormFieldConditionOrBool;
}

interface ParsedNextgenOperator {
  operator: FormFieldSingleConditionOperator;
  reverse?: boolean;
}

function parseNextgenOperator(operator: NextgenOperator | undefined): ParsedNextgenOperator {
  if (operator) {
    switch (operator) {
      case '!=':
        return {operator: 'equal', reverse: true};
      case '>=':
        return {operator: 'greater_than_or_equal'};
      case '>':
        return {operator: 'greater_than'};
      case '<=':
        return {operator: 'less_than_or_equal'};
      case '<':
        return {operator: 'less_than'};
    }
    if (FormFieldSingleConditionOperators.includes(operator)) {
      return {operator: operator as FormFieldSingleConditionOperator};
    }
  }
  return {operator: 'equal'};
}

function toSingleCondition(ruleCondition: NextgenRuleCondition): FormFieldSingleCondition {
  return {
    type: 'single_condition',
    ...parseNextgenOperator(ruleCondition.operator),
    field_name: ruleCondition.id,
    value: ruleCondition.value,
  };
}

function toMultipleCondition(
  conditions: FormFieldConditionOrBool[],
  operator: FormFieldMultipleConditionOperator
): FormFieldMultipleCondition {
  return {
    type: 'multiple_condition',
    operator,
    value: conditions,
  };
}

function toFormFieldCondition(ruleConditions: NextgenRuleCondition[]): FormFieldCondition {
  if (ruleConditions.length === 1) {
    return toSingleCondition(ruleConditions[0]);
  } else {
    const value: FormFieldCondition[] = [];
    for (const ruleCondition of ruleConditions) {
      value.push(toSingleCondition(ruleCondition));
    }
    return toMultipleCondition(value, 'and');
  }
}

function toFormFieldConditionOrBool(rule: NextgenRule): FormFieldConditionOrBool {
  if (rule.enableon !== undefined) {
    const condition = toFormFieldCondition(rule.enableon);
    return condition;
  }
  if (rule.disableon !== undefined) {
    const condition = toFormFieldCondition(rule.disableon);
    condition.reverse = true;
    return condition;
  }
  return true;
}

function parseRule(rule: NextgenRule): Partial<FormFieldSingleCondition> {
  const {name} = rule;
  if (name) {
    switch (name) {
      case 'greatherThan':
      case 'greaterThan':
        return {operator: 'greater_than', value: Number(rule.number)};
      case 'greatherThanRelative':
      case 'greaterThanRelative':
        return {operator: 'greater_than_relative', value: rule.ref};
      case 'lessThan':
        return {operator: 'less_than', value: Number(rule.number)};
      case 'lessThanRelative':
        return {operator: 'less_than_relative', value: rule.ref};
      case 'maxLength':
        return {operator: 'max_length', value: Number(rule.number)};
      case 'maxLengthRelative':
        return {operator: 'max_length_relative', value: rule.ref};
      case 'minLength':
        return {operator: 'min_length', value: Number(rule.number)};
      case 'minLengthRelative':
        return {operator: 'min_length_relative', value: rule.ref};
      case 'pattern':
        return {operator: 'regex', value: rule.regex};
    }
  }
  throw new Error(`Unknown NextgenRuleName '${name}'`);
}

function toFormFieldValidationCondition(fieldName: string, rule: NextgenRule): FormFieldConditionOrBool {
  return {
    type: 'single_condition',
    field_name: fieldName,
    ...parseRule(rule),
  };
}

function toFormFieldValidation(fieldName: string, rule: NextgenRule): FormFieldValidation {
  const message = rule.message || DEFAULT_REQUIRED_MESSAGE;
  let condition = toFormFieldValidationCondition(fieldName, rule);
  if (rule.enableon !== undefined) {
    const tmp = toFormFieldCondition(rule.enableon);
    tmp.reverse = true;
    const conditions = [tmp, condition];
    condition = toMultipleCondition(conditions, 'or');
  }
  if (rule.disableon !== undefined) {
    const tmp = toFormFieldCondition(rule.disableon);
    const conditions = [tmp, condition];
    condition = toMultipleCondition(conditions, 'or');
  }
  return {message, condition};
}

function parseRaw<T>(raw?: string): T | undefined {
  if (raw && hasText(raw)) {
    const sanitized = raw.replace(/'/g, '"');
    return JSON.parse(sanitized);
  }
}

function parseRawRules(rawRules?: string): NextgenRules {
  return parseRaw<NextgenRules>(rawRules) || {};
}

function parseRawManualRules(rawManualRules?: string): NextgenManualRules {
  return parseRaw<NextgenManualRules>(rawManualRules) || [];
}

interface WithRules {
  rules: string;
}

interface WithManualRules {
  field_name: string;
  manual_rules: string;
}

type ParseRulesInput = WithRules | WithManualRules | any;

// export only for test
export function toShowIfHasContentRule(field_name: string): FormFieldSingleCondition {
  return {
    type: 'single_condition',
    field_name,
    operator: 'is_blank',
    scope: 'first_render', // apply this condition only on first_render
  } as FormFieldSingleCondition;
}

// export only for test
export function toDisableIfHasContentRule(field_name: string): FormFieldSingleCondition {
  return {
    type: 'single_condition',
    field_name,
    operator: 'is_not_blank',
    scope: 'first_render', // apply this condition only on first_render
  } as FormFieldSingleCondition;
}

export function parseRules(input: ParseRulesInput): ParseRulesOutput {
  if (!('rules' in input) && !('manual_rules' in input)) return {};

  const hidden: FormFieldConditionOrBool[] = [];
  const disabled: FormFieldConditionOrBool[] = [];
  const required: FormFieldConditionOrBool[] = [];
  const validations: FormFieldValidation[] = [];
  let requiredMessage;

  if ('rules' in input) {
    const rules: NextgenRules = parseRawRules(input.rules);
    for (const rule of rules.rules || []) {
      if (rule.name === 'hidden') {
        hidden.push(toFormFieldConditionOrBool(rule));
      } else if (rule.name === 'disabled') {
        disabled.push(toFormFieldConditionOrBool(rule));
      } else if (rule.name === 'required') {
        // only first message found is used
        if (!requiredMessage) {
          requiredMessage = rule.message;
        }
        required.push(toFormFieldConditionOrBool(rule));
      } else {
        // all other rules are handled by toFormFieldValidation
        validations.push(toFormFieldValidation(input.field_name, rule));
      }
    }
  }

  if ('manual_rules' in input) {
    const field_name: string = input.field_name;
    const manualRules: NextgenManualRules = parseRawManualRules(input.manual_rules);
    for (const manualRule of manualRules) {
      if (manualRule === 'show_if_has_content') {
        // TODO investigate if this is good enough (static true conditions will obfuscate other conditions)
        removeFromArray(hidden, true); // remove previously defined static conditions
        hidden.push(toShowIfHasContentRule(field_name));
      } else if (manualRule === 'disabled_if_has_contents') {
        // TODO investigate if this is good enough (static true conditions will obfuscate other conditions)
        removeFromArray(disabled, true); // remove previously defined static conditions
        disabled.push(toDisableIfHasContentRule(field_name));
      } else {
        throw Error(`Unknown manual rule '${manualRule}'`);
      }
    }
  }

  const parsedRules: ParseRulesOutput = {};

  if (hidden.length === 1) {
    parsedRules.hidden = hidden[0];
  } else if (hidden.length > 1) {
    parsedRules.hidden = toMultipleCondition(hidden, 'or');
  }

  if (disabled.length === 1) {
    parsedRules.disabled = disabled[0];
  } else if (disabled.length > 1) {
    parsedRules.disabled = toMultipleCondition(disabled, 'or');
  }

  if (required.length === 1) {
    if (!isCondition(required[0]) && required[0] === true) {
      parsedRules.required = requiredMessage || DEFAULT_REQUIRED_MESSAGE;
    } else {
      parsedRules.required = {
        message: requiredMessage || DEFAULT_REQUIRED_MESSAGE,
        condition: required[0],
      };
    }
  } else if (required.length > 1) {
    parsedRules.required = {
      message: requiredMessage || DEFAULT_REQUIRED_MESSAGE,
      condition: toMultipleCondition(required, 'or'),
    };
  }

  if (validations.length > 0) {
    parsedRules.validations = validations;
  }

  return parsedRules;
}

type NextgenManualRules = NextgenManualRule[];
type NextgenManualRule = 'disabled_if_has_contents' | 'show_if_has_content';

interface NextgenRules {
  rules?: NextgenRule[];
}

interface NextgenRule {
  name: NextgenRuleName;
  regex?: string;
  message?: string;
  enableon?: NextgenRuleCondition[];
  disableon?: NextgenRuleCondition[];
  number?: number;
  ref?: string; // relative field name used on "relative" rules
}

type NextgenRuleName =
  | 'disabled'
  | 'hidden'
  | 'required'
  | 'pattern'
  | 'greatherThan'
  | 'greatherThanRelative'
  | 'greaterThan'
  | 'greaterThanRelative'
  | 'lessThan'
  | 'lessThanRelative'
  | 'maxLength'
  | 'maxLengthRelative'
  | 'minLength'
  | 'minLengthRelative';

interface NextgenRuleCondition {
  id: string;
  value: string;
  operator?: NextgenOperator;
}

type NextgenOperator = '>' | '<' | '>=' | '<=' | '!=' | '===' | FormFieldSingleConditionOperator;
