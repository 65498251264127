import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {QueryListItem} from '../../../model/Query';

interface CopyQueryDialogProps {
  isOpen: boolean;
  handleClose: Function;
  copyQuery: Function;
  selectedQuery: QueryListItem | null;
}

function CopyQueryDialog({isOpen, handleClose, selectedQuery, copyQuery}: CopyQueryDialogProps) {
  const {t} = useTranslation();
  const [copiedQueryName, setCopiedQueryName] = useState<string>('');
  function handleOnClose() {
    setCopiedQueryName('');
    handleClose();
  }
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={isOpen}
      onClose={() => handleOnClose()}
      aria-labelledby="copy-query-dialog-title"
    >
      <DialogTitle id="task-dialog-title">
        {t('query.actions.copy-query-label')} {selectedQuery?.name} ?
        <IconButton
          aria-label="close"
          onClick={() => handleOnClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <InputLabel>{t('query.actions.new-name')}</InputLabel>
        <TextField
          fullWidth
          id={'copy-query-field'}
          value={copiedQueryName}
          placeholder={t('query.query-name')}
          onChange={(event) => setCopiedQueryName(event.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button id={'copy-query-cancel-btn'} color="secondary" onClick={() => handleOnClose()}>
          {t('shared.cancel')}
        </Button>
        <Button
          id={'copy-query-btn'}
          disabled={!copiedQueryName}
          onClick={() => {
            handleOnClose();
            copyQuery(selectedQuery?.query_id, copiedQueryName);
          }}
        >
          {t('query.actions.copy-query')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CopyQueryDialog;
