import {Box, Button, Grid, LinearProgress, Link, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';
import AccessControl, {UserPermissions} from '../../../components/shared/AccessControl';
import {isCampaignWithDPSData, isCampaignWithDates, WebSocketCampaign} from '../../../model/WebSocketCampaign';
import LoggedInCampaignService from '../../../services/LoggedInCampaignService';
import {FeatureName} from '../../../../paths';
import UserRoleService from '../../../services/UserRoleService';
import {useEffect, useState} from 'react';
import {Auth0User, getUserOrganizationRoles} from '../../../model/Auth0User';
import {OrganizationRoleName} from '../../../model/Organization';

interface CampaignCardProps {
  campaign: WebSocketCampaign;
  userPermissions?: Array<UserPermissions>;
}

function CampaignCard({campaign, userPermissions}: CampaignCardProps) {
  const {t} = useTranslation();
  const auth0 = useAuth0<Auth0User>();
  const {user} = auth0;
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>();
  const [selectedRoleName, setSelectedRoleName] = useState<string | null>();
  const [openLink, setOpenLink] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    const sub = UserRoleService.getInstance()
      .getSelectedRole()
      .subscribe((role) => setSelectedRoleId(role));
    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    if (user && selectedRoleId) {
      const role = getUserOrganizationRoles(user).find((role: any) => role.role_id === selectedRoleId);
      setSelectedRoleName(role?.role_name);
    }
  }, [selectedRoleId, user]);

  useEffect(() => {
    if (selectedRoleName) {
      const openLink =
        selectedRoleName === OrganizationRoleName.ADMIN || selectedRoleName === OrganizationRoleName.POWER;
      setOpenLink(openLink);
    }
  }, [selectedRoleName]);
  const id = campaign?.campaign_id?.split('_').pop();
  const isAfterHour = campaign?.campaign_id?.startsWith('after_hours');
  return (
    <Box key={campaign._key}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Grid container item spacing={3}>
            <Grid item container spacing={3}>
              <Grid item xs={6} md={8}>
                {openLink && (
                  <Link
                    component="button"
                    variant="body1"
                    underline="hover"
                    sx={{color: 'black', textAlign: 'left'}}
                    onClick={() => {
                      navigate(`/${isAfterHour ? FeatureName.AUTO_ACTIONS : FeatureName.CAMPAIGNS}/${id}`);
                    }}
                  >
                    <Typography variant={'body1'} sx={{fontWeight: '400', wordBreak: 'break-word'}}>
                      {campaign.name}
                    </Typography>
                  </Link>
                )}
                {!openLink && (
                  <Typography variant={'body1'} sx={{fontWeight: '400', wordBreak: 'break-word'}}>
                    {campaign.name}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={6} md={4}>
                {campaign.refill ? (
                  <LinearProgress color="primary" />
                ) : (
                  <Typography>
                    {t('dashboard.campaign.batch')}: {campaign.messages}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container spacing={3}>
              {isCampaignWithDates(campaign) && (
                <Grid item xs={6}>
                  <Typography variant={'body1'} letterSpacing={1}>
                    {campaign.start_time} - {campaign.end_time}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={6}>
                {campaign.refill && (
                  <Grid item>
                    <Typography variant={'body1'} display={'flex'} alignItems={'center'}>
                      {campaign.refill && ` (${t('dashboard.campaign.refilling')})`}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isCampaignWithDPSData(campaign) && (
          <Grid item xs={12} md={6}>
            <Grid container item spacing={2}>
              <Grid item container xs={12}>
                <Typography sx={{fontWeight: '400'}}>
                  {t('dashboard.campaign.expected-dps')} {campaign.expected_dps}
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                <Typography sx={{fontWeight: '400'}}>
                  {t('dashboard.campaign.actual-dps')} {campaign.actual_dps}
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                <Typography sx={{fontWeight: '400'}}>
                  {t('dashboard.campaign.consumption-rate')} {campaign.consumption_rate}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {campaign.campaign_id && (
          <Grid item xs={12} sm={4}>
            <AccessControl
              userPermissions={userPermissions ?? null}
              allowedPermissions={[UserPermissions.CAMPAIGNS_SIGN_IN]}
            >
              <Button
                size="small"
                disabled={campaign.messages === 0}
                onClick={() => {
                  const loggedInCampaign = {id: campaign?.campaign_id as string, campaignName: campaign?.name};
                  LoggedInCampaignService.getInstance().setLoggedCampaign(loggedInCampaign);
                  navigate(`/campaigns/view/${campaign?.campaign_id}`);
                }}
              >
                {t('dashboard.campaign.login')}
              </Button>
            </AccessControl>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default CampaignCard;
