import {FeatureName} from '../../../paths';
import {Auth0ContextInterface} from '@auth0/auth0-react';
import {Auth0User, getUserRoleId} from '../../model/Auth0User';
import {DynamicOptionsResponse} from '../model/DynamicOptionsResponse';
import UserRoleService from '../UserRoleService';

type DynamicOptions = DynamicOptionsResponse['dynamic_options'];

export default class FieldsApiClient {
  readonly auth0: Auth0ContextInterface<Auth0User>;

  static with(auth0: Auth0ContextInterface<Auth0User>) {
    return new FieldsApiClient(auth0);
  }
  constructor(auth0: Auth0ContextInterface<Auth0User>) {
    this.auth0 = auth0;
  }

  async getDynamicOptions(source: string): Promise<DynamicOptionsResponse> {
    const {getAccessTokenSilently, user} = this.auth0;
    const selectedRole = UserRoleService.getInstance().getCurrentValue() || getUserRoleId(user);
    const auth0token = await getAccessTokenSilently();
    const path = `/api/v1/${FeatureName.FEATURE_FIELDS}/dynamic_select_options/${source}/?formatted=false&selected_role=${selectedRole}`;
    return fetch(path, {
      headers: {authorization: `bearer ${auth0token}`},
    })
      .then((response) => response.json())
      .then((data: Promise<DynamicOptionsResponse>) => {
        return data;
      });
  }

  async getDynamicOptionsInParallel(dynamic_sources: string[]): Promise<Map<string, DynamicOptions>> {
    // step 1. collect all distinct dynamic_sources (as string array) to prevent duplicate requests
    const unique_dynamic_sources = Array.from(new Set(dynamic_sources));

    // step 2. fetch all dynamic_sources from BE and store them into a Map
    const promises: Promise<void>[] = [];
    const dynamic_options_by_source = new Map<string, DynamicOptions>();
    for (const dynamic_source of unique_dynamic_sources) {
      const promise = this.getDynamicOptions(dynamic_source).then((response: DynamicOptionsResponse) => {
        if (response && response.dynamic_options) {
          dynamic_options_by_source.set(dynamic_source, response.dynamic_options);
        }
      });
      promises.push(promise);
    }

    // step 2b. wait for all requests in parallel
    await Promise.all(promises);

    return dynamic_options_by_source;
  }
}
