import React, {useState} from 'react';
import {Button} from '@mui/material';
import {useImportExport} from '../hooks/useImportExport';
import ModalWrapper from './shared/ModalWrapper';
import ImportComponent from './ImportComponent';

interface Props {}

export default function ImportButton({}: Props) {
  const {importing} = useImportExport();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button disabled={importing} onClick={() => setOpen(true)} color={'secondary'} size={'medium'}>
        import
      </Button>
      <ModalWrapper open={open} handleClose={() => setOpen(false)}>
        <ImportComponent onImportCompleted={() => setOpen(false)} />
      </ModalWrapper>
    </>
  );
}
