import Button from '@mui/material/Button';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAuth0} from '@auth0/auth0-react';
import {Auth0User} from '../../model/Auth0User';

function TermsAndConditionsDialog() {
  const {t} = useTranslation();
  const {logout} = useAuth0<Auth0User>();
  const [isOpen, setIsOpen] = useState(true);

  function handleAcceptConditions() {
    sessionStorage.setItem('Terms&ConditionsPowerAccepted', 'true');
    setIsOpen(false);
  }

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={isOpen}>
      {/*<HelpOutlineIcon color="primary" sx={{fontSize: 70, margin: 'auto'}} />*/}
      <DialogTitle>{t('dashboard.terms-and-conditions-title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('dashboard.terms-and-conditions')}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center'}}>
        <Button id={'cancel-btn'} onClick={() => logout({returnTo: window.location.origin + '/login'})}>
          {t('supportDialogs.cancelButton')}
        </Button>
        <Button color={'primary'} onClick={() => handleAcceptConditions()} id={'email-btn'}>
          {t('supportDialogs.accept-button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default TermsAndConditionsDialog;
