import {useTranslation} from 'react-i18next';
import {DialogContent, DialogContentText, Typography} from '@mui/material';
import SpartanDialog from '../../../components/dialogs/SpartanDialog';

interface DeleteDialogProps {
  isDialogOpen: boolean;
  handleClose: Function;
  handleConfirm: Function;
}

function DeletePaymentInfoDialog({isDialogOpen, handleClose, handleConfirm}: DeleteDialogProps) {
  const {t} = useTranslation();
  return (
    <SpartanDialog
      headline={t('leads.dialogs.delete-payment-info.headline')}
      isDialogOpen={isDialogOpen}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      confirmationMessage={t('shared.accept')}
    >
      <DialogContent>
        <DialogContentText id="confirmation-dialog-content-1">
          <Typography variant={'h5'}>{t('leads.dialogs.delete-payment-info.paragraph-1')}</Typography>
          <Typography variant={'h5'}>{t('leads.dialogs.delete-payment-info.paragraph-2')}</Typography>
        </DialogContentText>
        <DialogContentText
          id="confirmation-dialog-content-3"
          sx={{width: '100%', padding: (theme) => ` ${theme.spacing(2)}`}}
        >
          <Typography variant={'h4'}>{t('leads.dialogs.delete-payment-info.debit-info')}</Typography>
          <Typography>{t('leads.dialogs.delete-payment-info.debit-type')}</Typography>
          <Typography>{t('leads.dialogs.delete-payment-info.debit-name')}</Typography>
          <Typography>{t('leads.dialogs.delete-payment-info.debit-number')}</Typography>
          <Typography>{t('leads.dialogs.delete-payment-info.cvv')}</Typography>
          <Typography>{t('leads.dialogs.delete-payment-info.exp-date')}</Typography>
        </DialogContentText>
        <DialogContentText
          id="confirmation-dialog-content-4"
          sx={{width: '100%', padding: (theme) => ` ${theme.spacing(2)}`}}
        >
          <Typography variant={'h4'}>{t('leads.dialogs.delete-payment-info.credit-info')}</Typography>
          <Typography>{t('leads.dialogs.delete-payment-info.credit-type')}</Typography>
          <Typography>{t('leads.dialogs.delete-payment-info.credit-name')}</Typography>
          <Typography>{t('leads.dialogs.delete-payment-info.credit-number')}</Typography>
          <Typography>{t('leads.dialogs.delete-payment-info.cvv')}</Typography>
          <Typography>{t('leads.dialogs.delete-payment-info.exp-date')}</Typography>
        </DialogContentText>
        <DialogContentText
          id="confirmation-dialog-content-5"
          sx={{width: '100%', padding: (theme) => ` ${theme.spacing(2)}`}}
        >
          <Typography variant={'h4'}>{t('leads.dialogs.delete-payment-info.eft-info')}</Typography>
          <Typography>{t('leads.dialogs.delete-payment-info.bank-name')}</Typography>
          <Typography>{t('leads.dialogs.delete-payment-info.account-name')}</Typography>
          <Typography>{t('leads.dialogs.delete-payment-info.routing-number')}</Typography>
          <Typography>{t('leads.dialogs.delete-payment-info.account-number')}</Typography>
        </DialogContentText>
      </DialogContent>
    </SpartanDialog>
  );
}

export default DeletePaymentInfoDialog;
