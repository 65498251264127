import {Container, Grid, Typography} from '@mui/material';
import {Alert} from '@mui/material';
import {useTranslation} from 'react-i18next';

function CrdSearchPage() {
  const {t} = useTranslation();
  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} sx={{marginTop: (theme) => theme.spacing(1)}}>
        <Grid item xs={12}>
          <Typography variant={'h2'}>{t('cdr-search.title')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Alert severity={'info'}>
            <Typography variant={'h3'}>{t('cdr-search.under-construction')}</Typography>
            <Typography variant={'body1'}>
              {t('cdr-search.headline')}
              <br />
              <a
                href="https://spartanapproach.atlassian.net/servicedesk/customer/portals"
                target="_blank"
                rel="noreferrer"
              >
                https://spartanapproach.atlassian.net/servicedesk/customer/portals
              </a>
              <br />
              Sparta Support / CDR Search / I want to request a CDR export
            </Typography>
          </Alert>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CrdSearchPage;
