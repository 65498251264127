import React from 'react';
import SystemSettingsPageContainer from '../SystemSettingsPageContainer';
import {SystemSettingsPages} from '../model/SysPageInfo';
import UserPermissionsPageComponent from './UserPermissionsPageComponent';

export default function SystemSettingsUserPermissionsPage() {
  return (
    <SystemSettingsPageContainer page={SystemSettingsPages.USER_PERMISSIONS}>
      <UserPermissionsPageComponent />
    </SystemSettingsPageContainer>
  );
}
