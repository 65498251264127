import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Container, Grid, Skeleton} from '@mui/material';
import {useParams} from 'react-router';
import {FormBuilder} from '../../form/FormBuilder';
import PageHeadline from '../../components/PageHeadline';
import {FeatureName} from '../../../paths';
import {Task, TaskListItem, TaskStatus} from '../../model/Task';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {PageStickyHeader} from '../PageStickyHeader';
import {PageTopActions} from '../PageTopActions';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {
  AppendOptionsFormFieldSchemaDecorator,
  BaseFormFieldSchemaDecorator,
  FormFieldSchemaDecorator,
} from '../../form/logic/FormDecorator';
// @ts-ignore
import {FormConfig} from '../../form/FormConfig';
import {useFormAPI} from '../../form/FormAPI';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import {onRejectSubmit} from '../../form/errorHandler';
import {TestAttributes} from '../../TestAttributes';
import {difference} from '../../form/utils';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import FeatureNotFound from '../notFound/FeatureNotFound';

function TaskPage() {
  const {useAxiosBFF} = useAxiosContext();
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const {id} = useParams();
  const navigate = useNavigate();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [config, setConfig] = useState<FormConfig>();
  const [isValid, setIsValid] = useState<boolean>(true);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.TASKS);
  const formAPI = useFormAPI();

  const [{loading: isTaskListLoading}, getTaskList] = useAxiosBFF<ResponseListWrapper<TaskListItem>>(
    {url: `/${FeatureName.TASKS}`, params: {formatted: true}},
    {
      manual: false,
    }
  );
  const [{data: taskData, loading: isTaskLoading, error: hasGetTaskError}, getTaskData] = useAxiosBFF<Task>(
    `/${FeatureName.TASKS}/${id}`,
    {manual: true}
  );

  const [{loading: isTaskPatchLoading, error: hasPatchTaskError, response: patchTaskResponse}, patchTaskData] =
    useAxiosBFF<Task>({url: `/${FeatureName.TASKS}/${id}`, method: 'PATCH', params: {formatted: true}}, {manual: true});

  const [{error: hasPostTaskError, loading: isTaskPostLoading, response: postTaskResponse}, postTaskData] =
    useAxiosBFF<Task>({url: `/${FeatureName.TASKS}`, method: 'POST'}, {manual: true});

  useEffect(() => {
    const loading = isTaskLoading || isTaskPatchLoading || isTaskPostLoading || isTaskListLoading;
    setLoading(loading, 'TaskPage');
  }, [isTaskLoading, isTaskPostLoading, isTaskPatchLoading, isTaskListLoading]);

  useEffect(() => {
    if (postTaskResponse?.status === 201) {
      NotificationService.getInstance().sendNotification(postTaskResponse?.data?.['message'], NotificationType.SUCCESS);
      navigate(`/${FeatureName.TASKS}/${postTaskResponse.data['id']}`);
    }
  }, [postTaskResponse]);

  useEffect(() => {
    if (hasPostTaskError) {
      NotificationService.getInstance().sendNotification(
        hasPostTaskError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasPostTaskError]);

  useEffect(() => {
    if (hasPatchTaskError) {
      NotificationService.getInstance().sendNotification(
        hasPatchTaskError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasPatchTaskError]);
  useEffect(() => {
    if (hasGetTaskError) {
      NotificationService.getInstance().sendNotification(
        hasGetTaskError?.response?.data?.message,
        NotificationType.ERROR
      );
      setIsValid(false);
    }
  }, [hasGetTaskError]);
  useEffect(() => {
    if (patchTaskResponse?.status === 200) {
      NotificationService.getInstance().sendNotification(
        patchTaskResponse?.data?.['message'],
        NotificationType.SUCCESS
      );
    }
  }, [patchTaskResponse]);

  const onSubmit = useCallback(
    (formData: Partial<Task>) => {
      if (id) {
        formData = difference(formData, taskData);
        patchTaskData({
          data: formData,
        }).then(() => {
          getTaskList();
        });
      } else {
        postTaskData({
          data: formData,
        }).then(() => {
          getTaskList();
        });
      }
    },
    [taskData]
  );

  useEffect(() => {
    if (id) {
      getTaskData();
    }
  }, [id]);

  useEffect(() => {
    let decorators: FormFieldSchemaDecorator[] = [
      new BaseFormFieldSchemaDecorator((field) => {
        if (field.field_name && ['status'].includes(field.field_name.toLowerCase())) {
          field.hidden = !taskData?.task_id;
        }
      }),
    ];

    if (taskData?.status === TaskStatus.OVERDUE) {
      decorators.push(
        new AppendOptionsFormFieldSchemaDecorator('status', {
          label: 'overdue',
          value: 'overdue',
        })
      );
    }

    setConfig({decorators});
  }, [taskData]);

  useEffect(() => {
    if (taskData) {
      const isDisabled =
        (taskData?.status === TaskStatus.OVERDUE || taskData?.status === TaskStatus.CANCELLED) &&
        !userPermissions?.some((userPermission) => userPermission === UserPermissions.MODIFY);
      setSaveButtonDisabled(isDisabled);
    }
  }, [taskData, userPermissions]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        {isValid ? (
          <Grid container spacing={3}>
            <PageStickyHeader>
              <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
                <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                  <PageHeadline>{t('tasks.headline')}</PageHeadline>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PageTopActions>
                    <Button
                      {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                      id={'confirmation-dialog-btn'}
                      color="secondary"
                      onClick={() => setIsConfirmationDialogOpen(true)}
                    >
                      {t('shared.clear')}
                    </Button>
                    <AccessControl
                      userPermissions={userPermissions}
                      allowedPermissions={[id ? UserPermissions.MODIFY : UserPermissions.CREATE]}
                    >
                      <Button
                        id={'save-btn'}
                        {...{[TestAttributes.BUTTON_NAME]: 'save-btn'}}
                        disabled={saveButtonDisabled}
                        onClick={() => formAPI.submit()}
                      >
                        {id ? t('shared.update') : t('shared.save')}
                      </Button>
                    </AccessControl>
                  </PageTopActions>
                </Grid>
              </Grid>
            </PageStickyHeader>
            <Grid item xs={12}>
              {isTaskLoading ? (
                <Skeleton variant="rectangular" height={500} />
              ) : (
                <FormBuilder
                  formId={FeatureName.TASKS}
                  api={formAPI}
                  onSubmit={onSubmit}
                  initialValues={taskData}
                  config={config}
                  onRejectSubmit={onRejectSubmit}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <FeatureNotFound />
        )}
        <ConfirmationDialog
          message={t('shared.clear-form-modal-content')}
          headline={t('shared.clear-form-modal-headline')}
          isDialogOpen={isConfirmationDialogOpen}
          handleClose={() => setIsConfirmationDialogOpen(false)}
        >
          <Button
            onClick={() => {
              formAPI.reset();
              setIsConfirmationDialogOpen(false);
            }}
            {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
            id="confirmation-dialog-action-btn"
          >
            {t('shared.accept')}
          </Button>
        </ConfirmationDialog>
      </Container>
    </Box>
  );
}

export default TaskPage;
