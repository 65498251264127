import {Box, Grid} from '@mui/material';
import Container from '@mui/material/Container';
import {useCallback, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import PageHeadline from '../../components/PageHeadline';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName} from '../../../paths';
import {useAuth0} from '@auth0/auth0-react';
import {Auth0User, getUserId} from '../../model/Auth0User';
import {PageStickyHeader} from '../PageStickyHeader';
import {PageTopActions} from '../PageTopActions';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {FormBuilder} from '../../form/FormBuilder';
import {useFormAPI} from '../../form/FormAPI';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import {Team, TeamListItem} from '../../model/Team';
import {onRejectSubmit} from '../../form/errorHandler';
import {OnSubmitHandler} from '../../form/model';
import {FormState} from '../../form/state/FormState';
import UserService from '../../services/UserService';
import {DefaultResponse} from '../../services/model/DefaultResponse';
import {TestAttributes} from '../../TestAttributes';
import {UserResponse} from '../../services/model/UserResponse';
import {Task} from '../../model/Task';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {TeamResponse} from '../../services/model/TeamResponse';
import {useNavigate} from 'react-router-dom';
import {difference} from '../../form/utils';
import TeamService from '../../services/TeamService';
import {Status} from '../../model/Feature';
import {Skeleton} from '@mui/lab';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import FeatureNotFound from '../notFound/FeatureNotFound';

function TeamPage() {
  const {id} = useParams();
  const {useAxiosBFF} = useAxiosContext();
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const auth0 = useAuth0<Auth0User>();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [isDeactivatedModalOpen, setIsDeactivatedModalOpen] = useState<boolean>(false);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.TEAMS);
  const [team, setTeam] = useState<Team | null>(null);
  const formAPI = useFormAPI();
  const [userId, setUserId] = useState<string | undefined>();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [{data: userResponse}, getUserData] = useAxiosBFF<UserResponse>(
    {url: `/${FeatureName.USERS}/${userId}`},
    {manual: true}
  );
  const navigate = useNavigate();

  const [{}, getTeamList] = useAxiosBFF<ResponseListWrapper<TeamListItem>>(
    {url: `/${FeatureName.TEAMS}`, params: {formatted: true}},
    {
      manual: false,
    }
  );
  const [{data: teamData, loading: isTeamLoading, error: hasTeamError}, getTeamData] = useAxiosBFF<TeamResponse>(
    `/${FeatureName.TEAMS}/${id}`,
    {manual: true}
  );

  const [{error: hasPatchTeamError, loading: isPatchTeamLoading, response: patchTeamResponse}, patchTeamData] =
    useAxiosBFF<Team>({url: `/${FeatureName.TEAMS}/${id}`, method: 'PATCH', params: {formatted: true}}, {manual: true});

  const [{error: hasPostTeamError, loading: isPostTeamLoading, response: postTeamResponse}, postTeamData] =
    useAxiosBFF<Task>({url: `/${FeatureName.TEAMS}`, method: 'POST'}, {manual: true});

  const [
    {data: deleteTeamData, loading: isDeleteTeamLoading, response: deleteTeamResponse, error: hasDeleteTeamError},
    deleteTeam,
  ] = useAxiosBFF<DefaultResponse>({url: `/${FeatureName.TEAMS}/${id}`, method: 'delete'}, {manual: true});

  function updateUser(): void {
    getUserData();
    if (userResponse) {
      UserService.getInstance().set(UserService.getInstance().toUser(userResponse));
    }
  }

  function updateUserIfMember(state: FormState) {
    const userId = getUserId(auth0.user);
    if (userId && team && team.members && Array.isArray(team.members)) {
      const {initialValues} = state.initialValues;
      const currentUserIsMember = team.members.includes(userId);
      const currentUserWasMember = initialValues?.members?.includes(userId);
      if (currentUserIsMember || currentUserWasMember) {
        updateUser();
      }
    }
  }
  const onSubmit: OnSubmitHandler = useCallback(
    (formData: Partial<Team>, state: FormState) => {
      if (id) {
        formData = difference(formData, team);
        patchTeamData({
          data: formData,
        }).then(() => {
          updateUserIfMember(state);
          getTeamData();
        });
      } else {
        formData.status = Status.ACTIVE;
        if (formData?.campaigns?.length === 0) {
          delete formData?.campaigns;
        }
        postTeamData({
          data: formData,
        }).then(() => {
          updateUserIfMember(state);
        });
      }
    },
    [team]
  );

  useEffect(() => {
    const loading = isTeamLoading || isDeleteTeamLoading || isPostTeamLoading || isPatchTeamLoading;
    setLoading(loading, 'TeamPage');
  }, [isTeamLoading, isDeleteTeamLoading, isPostTeamLoading, isPatchTeamLoading]);

  useEffect(() => {
    if (deleteTeamResponse?.status === 200) {
      getTeamList().then(() => {
        NotificationService.getInstance().sendNotification(deleteTeamResponse?.data?.message, NotificationType.SUCCESS);
        updateUser();
        navigate('/' + FeatureName.TEAMS);
      });
    }
  }, [deleteTeamData]);

  useEffect(() => {
    if (id) {
      getTeamData();
    }
  }, [id]);

  useEffect(() => {
    if (teamData) {
      setTeam(TeamService.getInstance().toTeam(teamData));
    } else if (hasTeamError) {
      NotificationService.getInstance().sendNotification(hasTeamError?.response?.data?.message, NotificationType.ERROR);
      setIsValid(false);
    }
  }, [teamData, hasTeamError]);

  useEffect(() => {
    if (postTeamResponse?.status === 201) {
      getTeamList().then(() => {
        NotificationService.getInstance().sendNotification(
          postTeamResponse.data?.['message'],
          NotificationType.SUCCESS
        );
        navigate(`/${FeatureName.TEAMS}/${postTeamResponse.data['id']}`);
      });
    }
  }, [postTeamResponse]);

  useEffect(() => {
    if (patchTeamResponse?.status === 200) {
      getTeamList().then(() => {
        NotificationService.getInstance().sendNotification(
          patchTeamResponse.data?.['message'],
          NotificationType.SUCCESS
        );
      });
    }
  }, [patchTeamResponse]);

  useEffect(() => {
    if (hasPostTeamError) {
      NotificationService.getInstance().sendNotification(
        hasPostTeamError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasPostTeamError]);

  useEffect(() => {
    if (hasPatchTeamError) {
      NotificationService.getInstance().sendNotification(
        hasPatchTeamError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasPatchTeamError]);

  useEffect(() => {
    if (hasDeleteTeamError) {
      NotificationService.getInstance().sendNotification(
        hasDeleteTeamError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasDeleteTeamError]);

  useEffect(() => {
    if (userResponse) {
      UserService.getInstance().set(UserService.getInstance().toUser(userResponse));
    }
  }, [userResponse]);

  useEffect(() => {
    if (auth0) {
      setUserId(getUserId(auth0.user));
    }
  }, [auth0]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        {isValid ? (
          <Grid container spacing={3}>
            <PageStickyHeader>
              <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
                <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                  <PageHeadline>{t('teams.headline')}</PageHeadline>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PageTopActions>
                    {id && (
                      <AccessControl
                        userPermissions={userPermissions}
                        allowedPermissions={[UserPermissions.DEACTIVATE_FEATURE_ITEM]}
                      >
                        <ConfirmationDialog
                          message={t('teams.deactivate-message')}
                          headline={t('teams.deactivate')}
                          isDialogOpen={isDeactivatedModalOpen}
                          handleClose={() => setIsDeactivatedModalOpen(false)}
                        >
                          <Button
                            onClick={() => {
                              updateUser();
                              deleteTeam();
                              setIsDeactivatedModalOpen(false);
                            }}
                            {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
                            id="confirmation-dialog-action-btn"
                          >
                            {t('teams.deactivate')}
                          </Button>
                        </ConfirmationDialog>

                        <Button
                          {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                          id={'confirmation-dialog-btn'}
                          color="secondary"
                          onClick={() => setIsDeactivatedModalOpen(true)}
                        >
                          {t('teams.deactivate')}
                        </Button>
                      </AccessControl>
                    )}

                    <Button
                      {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                      id={'confirmation-dialog-btn'}
                      color="secondary"
                      onClick={() => setIsConfirmationDialogOpen(true)}
                    >
                      {t('shared.clear')}
                    </Button>

                    <AccessControl
                      userPermissions={userPermissions}
                      allowedPermissions={[id ? UserPermissions.MODIFY : UserPermissions.CREATE]}
                    >
                      <Button
                        {...{[TestAttributes.BUTTON_NAME]: 'save-btn'}}
                        id={'save-btn'}
                        onClick={() => {
                          formAPI.submit();
                        }}
                      >
                        {id ? t('shared.update') : t('shared.save')}
                      </Button>
                    </AccessControl>
                  </PageTopActions>
                </Grid>
              </Grid>
            </PageStickyHeader>
            <Grid item xs={12}>
              {isTeamLoading ? (
                <Skeleton variant="rectangular" height={500} />
              ) : (
                <FormBuilder
                  onRejectSubmit={onRejectSubmit}
                  formId={FeatureName.TEAMS}
                  api={formAPI}
                  initialValues={team}
                  onSubmit={onSubmit}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <FeatureNotFound />
        )}
        <ConfirmationDialog
          message={t('shared.clear-form-modal-content')}
          headline={t('shared.clear-form-modal-headline')}
          isDialogOpen={isConfirmationDialogOpen}
          handleClose={() => setIsConfirmationDialogOpen(false)}
        >
          <Button
            onClick={() => {
              formAPI.reset();
              setIsConfirmationDialogOpen(false);
            }}
            {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
            id="confirmation-dialog-action-btn"
          >
            {t('shared.accept')}
          </Button>
        </ConfirmationDialog>
      </Container>
    </Box>
  );
}

export default TeamPage;
