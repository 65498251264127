import React from 'react';
import {buildSetValue, SpartanTextFieldProps} from './model';
import {SpartanTextField} from './SpartanTextField';

export const SpartanNumberField = ({InputProps, ...props}: SpartanTextFieldProps) => {
  // FE-304 overwrite onChange to correctly set value as Number
  const setValue = buildSetValue(props);
  const onChange = (event: any) => setValue(parseFloat(event.target.value));
  return <SpartanTextField {...props} onChange={onChange} InputProps={{...InputProps, type: 'number'}} />;
};
