import React from 'react';
import {PartialUserSettings, PreferredLanguage} from '../model/UserSettings';
import {PaletteMode} from '@mui/material';
import {GridDensity} from '@mui/x-data-grid';

// This is just for backward compatibility. It can be removed after all users have logged in at least one time
// after https://spartanapproach.atlassian.net/browse/FE-1214 has been released.
// The only purpose is to transparently convert the user settings from legacy format (stored in local storage)
// to PartialUserSettings object. In such way the user will not have to set their preferences again.

function getLegacyPreferredLanguage(): PreferredLanguage | undefined {
  const lng = window.localStorage.getItem('lng');
  if (lng === 'es') return 'es';
  if (lng === 'en') return 'en';
  return undefined;
}

function getLegacyThemePaletteMode(): PaletteMode | undefined {
  const darkMode = window.localStorage.getItem('darkMode');
  if (darkMode === 'true') return 'dark';
  if (darkMode === 'false') return 'light';
  return undefined;
}

function getLegacyShowChat(): boolean | undefined {
  const json = window.localStorage.getItem('UserPreferences');
  if (json) {
    const isChatEnabled: any = JSON.parse(json).isChatEnabled;
    if (typeof isChatEnabled === 'boolean') {
      return isChatEnabled;
    }
  }
  return undefined;
}

function getGridDensity(): GridDensity | undefined {
  const json = window.localStorage.getItem('UserPreferences');
  if (json) {
    const density: any = JSON.parse(json).density;
    if (density === 'standard' || density === 'compact' || density === 'comfortable') return density;
  }
  return undefined;
}

const UserSettingsLegacy: PartialUserSettings = {
  preferred_language: getLegacyPreferredLanguage(),
  theme_palette_mode: getLegacyThemePaletteMode(),
  show_chat: getLegacyShowChat(),
  grid_density: getGridDensity(),
};

export default UserSettingsLegacy;
