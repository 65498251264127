import {FormBaseEntity} from './FormBaseEntity';

export interface FormFeature extends FormBaseEntity {
  name: string;
  label: string;
  type: string;
  url: string;
  feature_id: string;
  cta: string;
  feature_id_key?: string;
  icon_path?: string;
}

export function compareByName<T extends FormFeature>(a: T, b: T) {
  const nameA = a.name ?? '';
  const nameB = b.name ?? '';
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}
