import NotificationService, {NotificationType} from '../../../../services/NotificationService';

interface UseNotification {
  sendError: (message: string) => void;
  sendInfo: (message: string) => void;
  sendSuccess: (message: string) => void;
}

export function useNotifications(): UseNotification {
  function send(message: string, type: NotificationType) {
    NotificationService.getInstance().sendNotification(message, type);
  }

  function sendError(message: string) {
    send(message, NotificationType.ERROR);
  }

  function sendInfo(message: string) {
    send(message, NotificationType.INFO);
  }

  function sendSuccess(message: string) {
    send(message, NotificationType.SUCCESS);
  }

  return {
    sendError,
    sendInfo,
    sendSuccess,
  };
}
