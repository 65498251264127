import {FormFieldOption, FormFieldSchema, FormSectionSchema} from './FormSchema';

/**
 * Applied at build time (before first rendering).
 */
export type FormDecorator = FormFieldSchemaDecorator | FormSectionSchemaDecorator;
export type FormDecoratorFunction<T> = (schema: T) => void;

// FormFieldSchemaDecorator

export function decorateField(field: FormFieldSchema, decorators: undefined | FormDecorator[]) {
  return decorators
    ?.filter((dec) => dec.type === 'FormFieldSchemaDecorator')
    .map((dec) => dec as FormFieldSchemaDecorator)
    .forEach((dec) => dec.decorate(field));
}

export interface FormFieldSchemaDecorator {
  type: 'FormFieldSchemaDecorator';
  decorate: FormDecoratorFunction<FormFieldSchema>;
}

export class BaseFormFieldSchemaDecorator implements FormFieldSchemaDecorator {
  type: 'FormFieldSchemaDecorator' = 'FormFieldSchemaDecorator';
  decorate: FormDecoratorFunction<FormFieldSchema>;

  constructor(decorate: FormDecoratorFunction<FormFieldSchema>) {
    this.decorate = decorate;
  }
}

export class AppendOptionsFormFieldSchemaDecorator extends BaseFormFieldSchemaDecorator {
  constructor(field_name: string, ...options: FormFieldOption[]) {
    super((schema) => field_name === schema.field_name && appendOptions(schema, ...options));
  }
}

export function appendOptions(field: FormFieldSchema, ...options: FormFieldOption[]) {
  if (!field.options) field.options = [];
  for (const opt of options) {
    const index = field.options.findIndex((opt2) => opt.value === opt2.value);
    if (index < 0) field.options.push(opt);
  }
}

// FormSectionSchemaDecorator

export function decorateSection(section: FormSectionSchema, decorators: undefined | FormDecorator[]) {
  return decorators
    ?.filter((dec) => dec.type === 'FormSectionSchemaDecorator')
    .map((dec) => dec as FormSectionSchemaDecorator)
    .forEach((dec) => dec.decorate(section));
}

export interface FormSectionSchemaDecorator {
  type: 'FormSectionSchemaDecorator';
  decorate: FormDecoratorFunction<FormSectionSchema>;
}

export class BaseFormSectionSchemaDecorator implements FormSectionSchemaDecorator {
  type: 'FormSectionSchemaDecorator' = 'FormSectionSchemaDecorator';
  decorate: FormDecoratorFunction<FormSectionSchema>;

  constructor(decorate: FormDecoratorFunction<FormSectionSchema>) {
    this.decorate = decorate;
  }
}
