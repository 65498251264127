import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import {Lead} from '../../../model/Lead';
import {FormBuilder} from '../../../form/FormBuilder';
import {FeatureName} from '../../../../paths';
import CloseIcon from '@mui/icons-material/Close';
import {useAuth0} from '@auth0/auth0-react';
import {Auth0User, getUserSmsDid} from '../../../model/Auth0User';
import {AppendOptionsFormFieldSchemaDecorator} from '../../../form/logic/FormDecorator';
import {difference} from '../../../form/utils';
import {FormConfig} from '../../../form/FormConfig';
import {HttpMethods} from '../../../model/HttpMethods';
import {AxiosResponse} from 'axios';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import {useAxiosContext} from '../../../context/AxiosContext';
import {useLoading} from '../../../context/LoadingContext';

interface SmsDialogProps {
  isOpen: boolean;
  handleClose: Function;
  lead: Lead | null;
}

function SmsDialog({isOpen, handleClose, lead}: SmsDialogProps) {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const auth0 = useAuth0<Auth0User>();
  const {user} = auth0;
  const {setLoading} = useLoading();
  const submitEl = useRef<HTMLButtonElement>(null);
  const [initialValues, setInitialValues] = useState<any>();
  const [config, setConfig] = useState<FormConfig>();

  const [{error: hasSendSmsError, loading}, sendSms] = useAxiosBFF(
    {
      url: 'sms/',
      method: HttpMethods.POST,
    },
    {manual: true}
  );

  useEffect(() => {
    setLoading(loading || isOpen, 'SmsDialog');
  }, [loading, isOpen]);

  useEffect(() => {
    if (hasSendSmsError) {
      NotificationService.getInstance().sendNotification(
        hasSendSmsError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasSendSmsError]);

  useEffect(() => {
    setConfig({
      decorators: [
        new AppendOptionsFormFieldSchemaDecorator(
          'sms_to',
          {
            label: lead?.primary_phone || lead?.owner_1_cell_phone || '',
            value: lead?.primary_phone || lead?.owner_1_cell_phone || '',
          },
          {
            label: lead?.secondary_phone || lead?.owner_2_cell_phone || '',
            value: lead?.secondary_phone || lead?.owner_2_cell_phone || '',
          }
        ),
      ],
    });
  }, [lead]);

  useEffect(() => {
    setInitialValues({...lead, sms_to: lead?.primary_phone || lead?.owner_1_cell_phone || ''});
  }, [lead]);

  function onSubmit(data: any) {
    if (lead?.lead_id) {
      const leadId = lead?.lead_id;
      const number = data['sms_to'];
      data = difference(data, lead);

      // Ugly fix, this endpoint expects stuff that is not in the form
      data['lead_id'] = leadId;
      data['number'] = number;
      data['text'] = data['message'];
      data['source'] = getUserSmsDid(user) || '';

      delete data['message'];
      delete data['sms_to'];

      sendSms({data}).then((response: AxiosResponse) => {
        if (response?.status === 200) {
          NotificationService.getInstance().sendNotification(response?.data?.message, NotificationType.SUCCESS);
        }
      });
    }
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={isOpen}
        onClose={() => handleClose()}
        aria-labelledby="sms-dialog-title"
      >
        <DialogTitle id="sms-dialog-title">
          {t('leads.dialogs.sms.message-a-lead')}
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {lead && (
            <FormBuilder formId={FeatureName.SMS} onSubmit={onSubmit} initialValues={initialValues} config={config}>
              <Button ref={submitEl} type="submit" disabled={loading} sx={{display: 'none'}}>
                {t('leads.dialogs.sms.sms-now')}
              </Button>
            </FormBuilder>
          )}
        </DialogContent>

        <DialogActions>
          <Grid container direction={'row'} justifyContent={'right'} spacing={2}>
            <Grid item>{loading && <CircularProgress size={32} />}</Grid>
            <Grid item>
              <Button
                color="primary"
                id={'sms-btn'}
                disabled={loading}
                onClick={() => {
                  submitEl?.current?.click();
                }}
              >
                {t('leads.dialogs.sms.sms-now')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SmsDialog;
