export type ComputeScope = 'first_render';

export interface WithComputeScope {
  scope?: ComputeScope;
}

export function isOutOfScope(target: WithComputeScope, ctx?: ComputeContext): boolean {
  if (target.scope === 'first_render') {
    const first_render = !!ctx?.first_render;
    return !first_render;
  }
  return false;
}

export interface ComputeContext {
  first_render?: boolean;
  logger?: string;
}
