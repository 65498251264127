import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useAuth0} from '@auth0/auth0-react';
import {chunk} from 'lodash';
import {
  Avatar,
  Badge,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {Chat} from '../../../model/SmsPortal';
import AccessControl, {UserPermissions} from '../../../components/shared/AccessControl';
import {FeatureName} from '../../../../paths';
import {Auth0User, getUserId} from '../../../model/Auth0User';
import {usePermissions, UsePermissionState} from '../../UsePermissions';
import {DefaultResponse} from '../../../services/model/DefaultResponse';
import {HttpMethods} from '../../../model/HttpMethods';
import {AxiosResponse} from 'axios';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import {useAxiosContext} from '../../../context/AxiosContext';
import {useLoading} from '../../../context/LoadingContext';

interface ChatListProps {
  chats: Array<Chat>;
  onChatClick: Function;
  onArchive: Function;
}
const SIZE = 30;

function filterChatsBySearchText(chatsToFilter: Array<Chat>, searchChatText: string): Array<Chat> {
  return chatsToFilter?.filter(
    (chat: Chat) =>
      chat.first_name?.includes(searchChatText) ||
      chat.last_name?.includes(searchChatText) ||
      chat.from_number?.includes(searchChatText)
  );
}
function filterChats(
  chatsToFilter: Array<Chat>,
  searchChatText: string,
  viewAll: boolean,
  userId: string
): Array<Array<Chat>> {
  const filteredChatListBySearch = filterChatsBySearchText(chatsToFilter, searchChatText);
  const filteredChatList = viewAll
    ? filteredChatListBySearch
    : filteredChatListBySearch?.filter((chat: Chat) => chat.chat_owner === userId);
  return splitChatList(filteredChatList);
}

function splitChatList(chatList: Array<Chat>): Array<Array<Chat>> {
  return chunk(chatList, SIZE);
}

function ChatList({chats, onChatClick, onArchive}: ChatListProps) {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const auth0 = useAuth0<Auth0User>();
  const {setLoading} = useLoading();
  const userId = getUserId(auth0.user) ?? '';
  const [filteredChats, setFilteredChats] = useState<Array<Array<Chat>> | null>(splitChatList(chats));
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);

  const [searchChat, setSearchChat] = useState<string>('');
  const [viewAllChats, setViewAllChats] = useState<boolean>(false);
  const [moreActionsEl, setMoreActionsEl] = useState<null | HTMLElement>(null);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.SMS_PORTAL);
  const [pagesAmount, setPagesAmount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const theme = useTheme();
  const isMobileViewActive = useMediaQuery(theme.breakpoints.down('md'));

  const [{error: hasUpdateLeadError, loading: isUpdateLeadLoading}, updateLead] = useAxiosBFF<DefaultResponse>(
    {
      method: HttpMethods.PATCH,
    },
    {manual: true}
  );

  useEffect(() => {
    if (hasUpdateLeadError) {
      NotificationService.getInstance().sendNotification(
        hasUpdateLeadError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasUpdateLeadError]);

  useEffect(() => {
    setLoading(isUpdateLeadLoading, 'ChatList');
  }, [isUpdateLeadLoading]);

  useEffect(() => {
    setFilteredChats(filterChats(chats, searchChat, viewAllChats, userId));
    setPage(0);
  }, [chats, viewAllChats, searchChat, userId]);

  useEffect(() => {
    if (filteredChats) {
      setPagesAmount(Math.ceil(filteredChats.length));
    }
  }, [filteredChats]);

  const handleChatMoreActionsClose = () => {
    setMoreActionsEl(null);
  };

  const markChatAsRead = (leadId: string) => {
    if (chats) {
      const chatIndex = chats.findIndex((chat) => chat.lead_id === leadId);
      chats[chatIndex].unread = 0;
      setFilteredChats(filterChats(chats, searchChat, viewAllChats, userId));
    }
  };
  const archiveChat = () => {
    handleChatMoreActionsClose();
    const leadToUpdate = {chat_open: false};
    if (selectedChat?.lead_id) {
      updateLead({url: `${FeatureName.LEADS}/${selectedChat?.lead_id}`, data: leadToUpdate}).then(
        (response: AxiosResponse<DefaultResponse>) => {
          if (response?.status === 200) {
            NotificationService.getInstance().sendNotification(response?.data?.message, NotificationType.SUCCESS);
          }
          const chatIndex = chats.findIndex((chat) => chat.lead_id === selectedChat.lead_id);
          chats.splice(chatIndex, 1);
          setFilteredChats(filterChats(chats, searchChat, viewAllChats, userId));
          onArchive();
        }
      );
    }
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  return (
    <Grid container item spacing={3} sx={{height: '100%'}}>
      <Grid item sm={12} display={'flex'} justifyContent={'center'} alignContent={'baseline'}>
        <Pagination
          count={pagesAmount}
          variant="outlined"
          color="primary"
          showFirstButton
          showLastButton
          size={'large'}
          onChange={handleChangePage}
          page={page + 1}
        />
      </Grid>
      <Grid item sm={12}>
        <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW_ALL_CHATS]}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              checked={viewAllChats}
              label={`${t('sms-portal.checkbox')}`}
              onChange={() => setViewAllChats(!viewAllChats)}
            />
          </FormGroup>
        </AccessControl>
        <TextField
          fullWidth
          id="filter-search"
          placeholder={t('sms-portal.filter')}
          onChange={(event) => setSearchChat(event.target.value)}
          value={searchChat}
        />
      </Grid>
      <Grid item sm={12} sx={{...(isMobileViewActive ? {width: '100%'} : {})}}>
        <List sx={{maxHeight: '500px', overflow: 'auto'}}>
          {filteredChats ? (
            filteredChats[page]?.map((chat: Chat, index: number) => {
              return (
                <ListItem
                  divider
                  button
                  key={'virtual-chat-list-row' + index}
                  sx={{borderBottom: `1px solid ${theme.palette.primary.main}`}}
                >
                  <ListItemAvatar>
                    <Badge
                      color={'primary'}
                      badgeContent={chat.unread}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    >
                      <Avatar sx={{background: theme.palette.secondary.light}}>
                        {(chat?.first_name && chat?.last_name
                          ? `${chat?.first_name.charAt(0)}${chat?.last_name.charAt(0)}`
                          : ''
                        ).toUpperCase()}
                      </Avatar>
                    </Badge>
                  </ListItemAvatar>

                  <ListItemText
                    primary={`${chat.first_name} ${chat.last_name}`}
                    secondary={chat.from_number}
                    onClick={() => {
                      onChatClick(chat);
                      markChatAsRead(chat.lead_id);
                    }}
                  />
                  <IconButton
                    color="inherit"
                    onClick={(e) => {
                      setMoreActionsEl(e.currentTarget);
                      setSelectedChat(chat);
                    }}
                    size="large"
                  >
                    <MoreHorizIcon sx={{color: theme.palette.primary.main}} />
                  </IconButton>
                </ListItem>
              );
            })
          ) : (
            <h4>No chats</h4>
          )}
        </List>
        <Menu
          id="chat-list-more-actions"
          anchorEl={moreActionsEl}
          open={Boolean(moreActionsEl)}
          onClose={handleChatMoreActionsClose}
        >
          <Link to={'/leads/' + selectedChat?.lead_id} target={'_blank'}>
            <MenuItem onClick={handleChatMoreActionsClose}>{t('sms-portal.chat-list-actions.view')}</MenuItem>
          </Link>
          <MenuItem onClick={() => archiveChat()}>{t('sms-portal.chat-list-actions.archive')}</MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}

export default ChatList;
