import React, {useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useTranslation} from 'react-i18next';
import {Box, Container, Grid} from '@mui/material';
import PageHeadline from '../../components/PageHeadline';
import {FormBuilder} from '../../form/FormBuilder';
import {FeatureName} from '../../../paths';
import {PageStickyHeader} from '../PageStickyHeader';
import {Auth0User, getUserId} from '../../model/Auth0User';
import {usePermissions} from '../UsePermissions';
import UserSettings from './UserSettings';
import {UserResponse} from '../../services/model/UserResponse';
import UserService from '../../services/UserService';
import {useUserSettingsContext} from '../../context/UserSettingsContext';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

function UserProfilePage() {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const auth0 = useAuth0<Auth0User>();
  const {setLoading} = useLoading();
  const [profile, setProfile] = useState<any | null>(null);
  usePermissions(FeatureName.PROFILE);
  const [userId, setUserId] = useState<string | undefined>();

  const [refresh, setRefresh] = useState<number>(0);
  const {userSettings} = useUserSettingsContext();
  useEffect(() => {
    setRefresh((prev) => prev + 1);
  }, [userSettings.preferred_language]);

  const [{data: profileData, loading: getProfileLoading}, getProfileData] = useAxiosBFF<UserResponse>(
    {url: `/${FeatureName.USERS}/${userId}`},
    {manual: true}
  );

  useEffect(() => {
    setLoading(getProfileLoading, 'UserProfilePage');
  }, [getProfileLoading]);

  useEffect(() => {
    if (profileData) {
      setProfile(UserService.getInstance().toUser(profileData));
    }
  }, [profileData]);

  useEffect(() => {
    if (auth0) {
      setUserId(getUserId(auth0.user));
    }
  }, [auth0]);

  useEffect(() => {
    if (userId) {
      getProfileData();
    }
  }, [userId]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <PageStickyHeader>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('profile.headline')}</PageHeadline>
              </Grid>
            </Grid>
          </PageStickyHeader>

          <Grid item lg={12}>
            <FormBuilder formId={FeatureName.PROFILE} initialValues={profile} refresh={refresh}></FormBuilder>
          </Grid>
          <Grid item lg={12}>
            <UserSettings />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default UserProfilePage;
