import React, {PropsWithChildren} from 'react';
import {Box, Dialog, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props extends PropsWithChildren {
  open?: boolean;
  handleClose?: () => void;
}

const styleDialog = {};

const styleDialogBox = {
  width: '100%',
  padding: 5,
};
export default function ModalWrapper({open, handleClose, children}: Props) {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xl'}
      sx={styleDialog}
      open={open ?? true}
      onClose={() => handleClose && handleClose()}
    >
      {handleClose && (
        <IconButton color={'primary'} onClick={handleClose} sx={{position: 'absolute', top: 10, right: 10}}>
          <CloseIcon />
        </IconButton>
      )}
      <Box sx={styleDialogBox}>{children}</Box>
    </Dialog>
  );
}
