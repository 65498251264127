import {useEffect, useState} from 'react';
import {useTheme} from '@mui/material';
import {buildSetValue, SpartanTextFieldProps} from './model';
import {SpartanTextAreaField} from './SpartanTextAreaField';

export const SpartanRichTextField = ({InputProps, ...props}: SpartanTextFieldProps) => {
  const {name, value, disabled} = props;
  const [editor, setEditor] = useState<any>();
  const setValue = buildSetValue(props);
  const theme = useTheme();
  useEffect(() => {
    // @ts-ignore
    let _editor = new Jodit(`#${name}`, {
      readonly: disabled,
      theme: theme.palette.mode,
      events: {
        change: function (content: string) {
          setValue(content);
        },
      },
    });
    _editor.value = value;
    setEditor(_editor);
    return () => {
      _editor = null;
    };
  }, []);

  useEffect(() => {
    if (editor) {
      editor.setReadOnly(disabled);
    }
  }, [disabled]);

  return (
    <div key={name}>
      <SpartanTextAreaField {...props} id={name} inputRef={editor} />
    </div>
  );
};
