import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useState} from 'react';
import {FormPermission, ResourcePermissionsBatchUpdate} from '../model/FormPermission';
import {useFormEditorFetch} from '../hooks/useFormEditorFetch';
import {compareByIndex} from '../model/FormBaseEntity';

const Context = createContext<ResourcePermissionsContext | null>(null);

interface Props extends PropsWithChildren {
  resourceId: string;
}

export const ResourcePermissionsProvider = ({resourceId, children}: Props) => {
  const {get, put, sendDelete} = useFormEditorFetch();
  const [permissions, setPermissions] = useState<FormPermission[] | null>(null);

  const handleGet = useCallback(
    async () =>
      get(`permissions/resource/${resourceId}`).then((data) => setPermissions(data.results?.sort(compareByIndex))),
    [resourceId]
  );

  const handleBatchUpdate = useCallback(
    async (data: ResourcePermissionsBatchUpdate) => {
      const promises = [];
      for (const u of data?.toAdd ?? []) {
        const path = `permissions/resource/${resourceId}/action/${u.action_id}/role/${u.role_id}`;
        promises.push(put(path, null));
      }
      for (const u of data?.toDelete ?? []) {
        const path = `permissions/resource/${resourceId}/action/${u.action_id}/role/${u.role_id}`;
        promises.push(sendDelete(path));
      }
      Promise.all(promises).then((data) => handleGet());
    },
    [resourceId]
  );

  useEffect(() => {
    handleGet().then();
  }, [handleGet]);

  return <Context.Provider value={{permissions, handleBatchUpdate}}>{children}</Context.Provider>;
};

export interface ResourcePermissionsContext {
  permissions: FormPermission[] | null;
  handleBatchUpdate: (data: ResourcePermissionsBatchUpdate) => void;
}

export function useResourcePermissionsContext(): ResourcePermissionsContext {
  const value = useContext(Context);
  if (!value) throw Error('invalid context');
  return value;
}
