import {Checkbox} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {HttpMethods} from '../../../../model/HttpMethods';
import {DefaultResponse} from '../../../../services/model/DefaultResponse';
import NotificationService, {NotificationType} from '../../../../services/NotificationService';
import {PermissionActionProps} from './models/models';
import {useAxiosContext} from '../../../../context/AxiosContext';
import {useLoading} from '../../../../context/LoadingContext';

function PermissionAction({resourceId, actionId, roleId, checked}: PermissionActionProps) {
  const {t} = useTranslation();
  const {useAxiosPermissionAdminAPI} = useAxiosContext();
  const {setLoading} = useLoading();
  const featureName = 'permissions';
  const [isChecked, setIsChecked] = useState<boolean>(checked);
  const [{error: hasPostPermissionError, loading: isPostPermissionLoading}, PostPermission] =
    useAxiosPermissionAdminAPI<DefaultResponse>(
      {
        method: HttpMethods.POST,
      },
      {manual: true}
    );
  const [{error: hasDeletePermissionError, loading: isDeletePermissionLoading}, DeletePermission] =
    useAxiosPermissionAdminAPI<DefaultResponse>(
      {
        method: HttpMethods.DELETE,
      },
      {manual: true}
    );

  useEffect(() => {
    const error = hasDeletePermissionError || hasPostPermissionError;
    if (error) {
      NotificationService.getInstance().sendNotification(error?.response?.data?.message, NotificationType.ERROR);
    }
  }, [hasDeletePermissionError, hasPostPermissionError]);

  useEffect(() => {
    setLoading(isDeletePermissionLoading || isPostPermissionLoading, 'PermissionAction');
  }, [isDeletePermissionLoading, isPostPermissionLoading]);

  const addPermission = () => {
    PostPermission({
      url: `/${featureName}`,
      data: {resource_id: resourceId, role_id: roleId, action_id: actionId, resource_type: 'field'},
    }).then((response) => {
      if (response.status === 201) {
        NotificationService.getInstance().sendNotification(
          t('shared.successfully-updated-msg'),
          NotificationType.SUCCESS
        );
      }
      setIsChecked(!isChecked);
    });
  };

  const removePermission = () => {
    DeletePermission({
      url: `/${featureName}/by?resource_id=${resourceId}&role_id=${roleId}&action_id=${actionId}`,
    }).then((response) => {
      if (response.status === 204) {
        NotificationService.getInstance().sendNotification(
          t('shared.successfully-deleted-msg'),
          NotificationType.SUCCESS
        );
      }
      setIsChecked(!isChecked);
    });
  };

  const changePermissionStatus = () => {
    if (isChecked) {
      removePermission();
    } else {
      addPermission();
    }
  };

  return (
    <Checkbox
      key={`cb-${resourceId}-${roleId}-${actionId}`}
      checked={isChecked}
      onChange={() => changePermissionStatus()}
    />
  );
}

export default PermissionAction;
