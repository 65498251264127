import {FormBuilder} from '../../form/FormBuilder';
import {useTranslation} from 'react-i18next';
import {FeatureName} from '../../../paths';
import {Button} from '@mui/material';
import {useSettings} from '../UseSettings';

function OfficeSchedulePage() {
  const {t} = useTranslation();
  const {settings, submitGeneralSettingsForm} = useSettings();

  return (
    <>
      {settings && (
        <FormBuilder
          formId={FeatureName.GENERAL_SETTINGS}
          initialValues={settings}
          onSubmit={submitGeneralSettingsForm}
        >
          <Button color="primary" type="submit" id={'save-general-settings-btn'}>
            {t('shared.save')}
          </Button>
        </FormBuilder>
      )}
    </>
  );
}

export default OfficeSchedulePage;
