import React, {createContext, PropsWithChildren, useContext, useMemo} from 'react';
import {makeUseAxios, UseAxios} from 'axios-hooks';
import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import Auth0ContextHolder from '../services/Auth0ContextHolder';
import UserRoleService from '../services/UserRoleService';
import {getUserId, getUserRoleId} from '../model/Auth0User';
import {HttpMethods} from '../model/HttpMethods';
import {useConfig} from './ConfigContext';
import {env} from '../../env';

async function onFulfilledRequest<D>(config: AxiosRequestConfig<D>): Promise<AxiosRequestConfig<D>> {
  const auth0 = Auth0ContextHolder.getInstance().get();
  if (!auth0) throw Error('Unauthorized');
  if (!auth0.isAuthenticated) throw Error('Unauthorized');
  const token = await auth0.getAccessTokenSilently();

  const selectedRole = UserRoleService.getInstance().getCurrentValue() || getUserRoleId(auth0.user);

  function trimObj(obj: any) {
    if (obj && typeof obj === 'object') {
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'string') {
          obj[key] = obj[key].trim();
        }
      });
    }
    return obj;
  }

  // FIXME This should go in BE
  let updatedData: any = trimObj(config.data);
  if (config.method === HttpMethods.POST) {
    updatedData = {...config.data, created_by: getUserId(auth0.user)};
  } else if (config.method === HttpMethods.PATCH || config.method === HttpMethods.DELETE) {
    updatedData = {...config.data, last_updated_by: getUserId(auth0.user)};
  }

  return {
    ...config,
    data: trimObj(updatedData),
    params: {...config.params, selected_role: selectedRole},
    headers: {...config.headers, Authorization: `Bearer ${token}`},
  };
}

async function onFulfilledRequestV2<D>(config: AxiosRequestConfig<D>): Promise<AxiosRequestConfig<D>> {
  const auth0 = Auth0ContextHolder.getInstance().get();
  if (!auth0) {
    throw Error('Unauthorized');
  }
  if (!auth0.isAuthenticated) {
    throw Error('Unauthorized');
  }

  const token = await auth0.getAccessTokenSilently();

  const selectedRole = UserRoleService.getInstance().getCurrentValue() || getUserRoleId(auth0.user);

  let updatedData: any = config.data;

  return {
    ...config,
    data: updatedData,
    params: {...config.params, selected_role: selectedRole},
    headers: {...config.headers, Authorization: `Bearer ${token}`},
  };
}

function createInstanceWithAuth(config?: AxiosRequestConfig): AxiosInstance {
  const instance = axios.create(config);
  instance.interceptors.request.use(onFulfilledRequest);
  return instance;
}

function createInstanceWithAuthV2(config?: AxiosRequestConfig): AxiosInstance {
  const instance = axios.create(config);
  instance.interceptors.request.use(onFulfilledRequestV2);
  return instance;
}

function prependBaseUrlIfRelative(path: string) {
  // if path is absolute, take as it is.
  // if path is relative, prepend BASE_URL.
  if (/^https?:\/\//.test(path)) return path;
  else return `${env.BASE_URL}${path}`;
}

export const AxiosProvider = ({children}: PropsWithChildren) => {
  const {
    BFF_API_PATH,
    FORM_API_PATH,
    FORM_ADMIN_API_PATH,
    TASK_API_PATH,
    PERMISSION_ADMIN_API_PATH,
    USER_API_PATH,
    ORGANIZATION_API_PATH,
  } = useConfig();

  const useAxiosBFF = useMemo(() => {
    return makeUseAxios({
      axios: createInstanceWithAuth({baseURL: prependBaseUrlIfRelative(BFF_API_PATH)}),
    });
  }, [BFF_API_PATH]);

  const useAxiosFormAPI = useMemo(() => {
    return makeUseAxios({
      axios: createInstanceWithAuth({baseURL: prependBaseUrlIfRelative(FORM_API_PATH)}),
    });
  }, [FORM_API_PATH]);

  const useAxiosFormAdminAPI = useMemo(() => {
    return makeUseAxios({
      axios: createInstanceWithAuth({baseURL: prependBaseUrlIfRelative(FORM_ADMIN_API_PATH)}),
    });
  }, [FORM_ADMIN_API_PATH]);

  const useAxiosTaskAPI = useMemo(() => {
    return makeUseAxios({
      axios: createInstanceWithAuth({baseURL: prependBaseUrlIfRelative(TASK_API_PATH)}),
    });
  }, [TASK_API_PATH]);

  const useAxiosPermissionAdminAPI = useMemo(() => {
    return makeUseAxios({
      axios: createInstanceWithAuthV2({baseURL: prependBaseUrlIfRelative(PERMISSION_ADMIN_API_PATH)}),
    });
  }, [PERMISSION_ADMIN_API_PATH]);

  const useAxiosUserAPI = useMemo(() => {
    return makeUseAxios({
      axios: createInstanceWithAuth({baseURL: prependBaseUrlIfRelative(USER_API_PATH)}),
    });
  }, [USER_API_PATH]);

  const useAxiosOrganizationAPI = useMemo(() => {
    return makeUseAxios({
      axios: createInstanceWithAuth({baseURL: prependBaseUrlIfRelative(ORGANIZATION_API_PATH)}),
    });
  }, [ORGANIZATION_API_PATH]);

  // TODO rename to useAxiosBaseUrl or similar
  const useAxiosAPIv2 = useMemo(() => {
    return makeUseAxios({
      axios: createInstanceWithAuth({baseURL: env.BASE_URL}),
    });
  }, []);

  return (
    <AxiosContext.Provider
      value={{
        useAxiosBFF,
        useAxiosFormAPI,
        useAxiosFormAdminAPI,
        useAxiosTaskAPI,
        useAxiosPermissionAdminAPI,
        useAxiosUserAPI,
        useAxiosOrganizationAPI,
        useAxiosAPIv2,
      }}
    >
      {children}
    </AxiosContext.Provider>
  );
};

export const useAxiosDefault = makeUseAxios({
  axios,
});

export interface AxiosContextInterface {
  useAxiosBFF: UseAxios;
  useAxiosFormAPI: UseAxios;
  useAxiosFormAdminAPI: UseAxios;
  useAxiosTaskAPI: UseAxios;
  useAxiosPermissionAdminAPI: UseAxios;
  useAxiosUserAPI: UseAxios;
  useAxiosOrganizationAPI: UseAxios;
  useAxiosAPIv2: UseAxios;
}

const AxiosContext = createContext<AxiosContextInterface>({
  useAxiosBFF: useAxiosDefault,
  useAxiosFormAPI: useAxiosDefault,
  useAxiosFormAdminAPI: useAxiosDefault,
  useAxiosTaskAPI: useAxiosDefault,
  useAxiosPermissionAdminAPI: useAxiosDefault,
  useAxiosUserAPI: useAxiosDefault,
  useAxiosOrganizationAPI: useAxiosDefault,
  useAxiosAPIv2: useAxiosDefault,
});

export function useAxiosContext(): AxiosContextInterface {
  return useContext(AxiosContext);
}
