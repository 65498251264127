import AbcIcon from '@mui/icons-material/Abc';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import AccessibleIcon from '@mui/icons-material/Accessible';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AdbIcon from '@mui/icons-material/Adb';
import AddIcon from '@mui/icons-material/Add';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AddCardIcon from '@mui/icons-material/AddCard';
import AddchartIcon from '@mui/icons-material/Addchart';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCommentIcon from '@mui/icons-material/AddComment';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import AddLinkIcon from '@mui/icons-material/AddLink';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import AdfScannerIcon from '@mui/icons-material/AdfScanner';
import AdjustIcon from '@mui/icons-material/Adjust';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import AirIcon from '@mui/icons-material/Air';
import AirlinesIcon from '@mui/icons-material/Airlines';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import AirlineSeatFlatAngledIcon from '@mui/icons-material/AirlineSeatFlatAngled';
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import AirlineSeatLegroomExtraIcon from '@mui/icons-material/AirlineSeatLegroomExtra';
import AirlineSeatLegroomNormalIcon from '@mui/icons-material/AirlineSeatLegroomNormal';
import AirlineSeatLegroomReducedIcon from '@mui/icons-material/AirlineSeatLegroomReduced';
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import AirplayIcon from '@mui/icons-material/Airplay';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import AlarmIcon from '@mui/icons-material/Alarm';
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AlbumIcon from '@mui/icons-material/Album';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AllOutIcon from '@mui/icons-material/AllOut';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AnchorIcon from '@mui/icons-material/Anchor';
import AndroidIcon from '@mui/icons-material/Android';
import AnimationIcon from '@mui/icons-material/Animation';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import AodIcon from '@mui/icons-material/Aod';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ApiIcon from '@mui/icons-material/Api';
import AppBlockingIcon from '@mui/icons-material/AppBlocking';
import AppleIcon from '@mui/icons-material/Apple';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ApprovalIcon from '@mui/icons-material/Approval';
import AppsIcon from '@mui/icons-material/Apps';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import ArchiveIcon from '@mui/icons-material/Archive';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArticleIcon from '@mui/icons-material/Article';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssistantIcon from '@mui/icons-material/Assistant';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import AtmIcon from '@mui/icons-material/Atm';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachmentIcon from '@mui/icons-material/Attachment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AttractionsIcon from '@mui/icons-material/Attractions';
import AttributionIcon from '@mui/icons-material/Attribution';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import AutoFixOffIcon from '@mui/icons-material/AutoFixOff';
import AutofpsSelectIcon from '@mui/icons-material/AutofpsSelect';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation';
import BackpackIcon from '@mui/icons-material/Backpack';
import BackspaceIcon from '@mui/icons-material/Backspace';
import BackupIcon from '@mui/icons-material/Backup';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import BadgeIcon from '@mui/icons-material/Badge';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import BalanceIcon from '@mui/icons-material/Balance';
import BalconyIcon from '@mui/icons-material/Balcony';
import BallotIcon from '@mui/icons-material/Ballot';
import BarChartIcon from '@mui/icons-material/BarChart';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import BathroomIcon from '@mui/icons-material/Bathroom';
import BathtubIcon from '@mui/icons-material/Bathtub';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery20Icon from '@mui/icons-material/Battery20';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery30Icon from '@mui/icons-material/Battery30';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery60Icon from '@mui/icons-material/Battery60';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import Battery80Icon from '@mui/icons-material/Battery80';
import Battery90Icon from '@mui/icons-material/Battery90';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import BatteryCharging30Icon from '@mui/icons-material/BatteryCharging30';
import BatteryCharging50Icon from '@mui/icons-material/BatteryCharging50';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import BatteryCharging80Icon from '@mui/icons-material/BatteryCharging80';
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatterySaverIcon from '@mui/icons-material/BatterySaver';
import BatteryStdIcon from '@mui/icons-material/BatteryStd';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import BedIcon from '@mui/icons-material/Bed';
import BedroomBabyIcon from '@mui/icons-material/BedroomBaby';
import BedroomChildIcon from '@mui/icons-material/BedroomChild';
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import BedtimeOffIcon from '@mui/icons-material/BedtimeOff';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import BentoIcon from '@mui/icons-material/Bento';
import BikeScooterIcon from '@mui/icons-material/BikeScooter';
import BiotechIcon from '@mui/icons-material/Biotech';
import BlenderIcon from '@mui/icons-material/Blender';
import BlindsClosedIcon from '@mui/icons-material/BlindsClosed';
import BlockIcon from '@mui/icons-material/Block';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import BluetoothAudioIcon from '@mui/icons-material/BluetoothAudio';
import BluetoothConnectedIcon from '@mui/icons-material/BluetoothConnected';
import BluetoothDisabledIcon from '@mui/icons-material/BluetoothDisabled';
import BluetoothDriveIcon from '@mui/icons-material/BluetoothDrive';
import BluetoothSearchingIcon from '@mui/icons-material/BluetoothSearching';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import BlurOffIcon from '@mui/icons-material/BlurOff';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import BoltIcon from '@mui/icons-material/Bolt';
import BookIcon from '@mui/icons-material/Book';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import BorderHorizontalIcon from '@mui/icons-material/BorderHorizontal';
import BorderInnerIcon from '@mui/icons-material/BorderInner';
import BorderLeftIcon from '@mui/icons-material/BorderLeft';
import BorderOuterIcon from '@mui/icons-material/BorderOuter';
import BorderRightIcon from '@mui/icons-material/BorderRight';
import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import BorderTopIcon from '@mui/icons-material/BorderTop';
import BorderVerticalIcon from '@mui/icons-material/BorderVertical';
import BoyIcon from '@mui/icons-material/Boy';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import BreakfastDiningIcon from '@mui/icons-material/BreakfastDining';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import Brightness6Icon from '@mui/icons-material/Brightness6';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import BrightnessLowIcon from '@mui/icons-material/BrightnessLow';
import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery';
import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import BrushIcon from '@mui/icons-material/Brush';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import BugReportIcon from '@mui/icons-material/BugReport';
import BuildIcon from '@mui/icons-material/Build';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import BungalowIcon from '@mui/icons-material/Bungalow';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import BusAlertIcon from '@mui/icons-material/BusAlert';
import BusinessIcon from '@mui/icons-material/Business';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CabinIcon from '@mui/icons-material/Cabin';
import CableIcon from '@mui/icons-material/Cable';
import CachedIcon from '@mui/icons-material/Cached';
import CakeIcon from '@mui/icons-material/Cake';
import CalculateIcon from '@mui/icons-material/Calculate';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import CameraIcon from '@mui/icons-material/Camera';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import CameraFrontIcon from '@mui/icons-material/CameraFront';
import CameraIndoorIcon from '@mui/icons-material/CameraIndoor';
import CameraOutdoorIcon from '@mui/icons-material/CameraOutdoor';
import CameraRearIcon from '@mui/icons-material/CameraRear';
import CameraRollIcon from '@mui/icons-material/CameraRoll';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import CampaignIcon from '@mui/icons-material/Campaign';
import CancelIcon from '@mui/icons-material/Cancel';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import CarpenterIcon from '@mui/icons-material/Carpenter';
import CarRentalIcon from '@mui/icons-material/CarRental';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import CasesIcon from '@mui/icons-material/Cases';
import CasinoIcon from '@mui/icons-material/Casino';
import CastIcon from '@mui/icons-material/Cast';
import CastConnectedIcon from '@mui/icons-material/CastConnected';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import CastleIcon from '@mui/icons-material/Castle';
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon';
import CategoryIcon from '@mui/icons-material/Category';
import CelebrationIcon from '@mui/icons-material/Celebration';
import CellTowerIcon from '@mui/icons-material/CellTower';
import CellWifiIcon from '@mui/icons-material/CellWifi';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import ChairIcon from '@mui/icons-material/Chair';
import ChairAltIcon from '@mui/icons-material/ChairAlt';
import ChaletIcon from '@mui/icons-material/Chalet';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import ChargingStationIcon from '@mui/icons-material/ChargingStation';
import ChatIcon from '@mui/icons-material/Chat';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import ChurchIcon from '@mui/icons-material/Church';
import CircleIcon from '@mui/icons-material/Circle';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import ClassIcon from '@mui/icons-material/Class';
import CleanHandsIcon from '@mui/icons-material/CleanHands';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ClearIcon from '@mui/icons-material/Clear';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import ClosedCaptionDisabledIcon from '@mui/icons-material/ClosedCaptionDisabled';
import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CloudIcon from '@mui/icons-material/Cloud';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Co2Icon from '@mui/icons-material/Co2';
import CodeIcon from '@mui/icons-material/Code';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import CoffeeIcon from '@mui/icons-material/Coffee';
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker';
import CollectionsIcon from '@mui/icons-material/Collections';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import ColorizeIcon from '@mui/icons-material/Colorize';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import CommentIcon from '@mui/icons-material/Comment';
import CommentBankIcon from '@mui/icons-material/CommentBank';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import CommitIcon from '@mui/icons-material/Commit';
import CommuteIcon from '@mui/icons-material/Commute';
import CompareIcon from '@mui/icons-material/Compare';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CompassCalibrationIcon from '@mui/icons-material/CompassCalibration';
import CompressIcon from '@mui/icons-material/Compress';
import ComputerIcon from '@mui/icons-material/Computer';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import ConstructionIcon from '@mui/icons-material/Construction';
import ContactlessIcon from '@mui/icons-material/Contactless';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ContactsIcon from '@mui/icons-material/Contacts';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ContrastIcon from '@mui/icons-material/Contrast';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import CookieIcon from '@mui/icons-material/Cookie';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CopyrightIcon from '@mui/icons-material/Copyright';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CottageIcon from '@mui/icons-material/Cottage';
import CountertopsIcon from '@mui/icons-material/Countertops';
import CreateIcon from '@mui/icons-material/Create';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import CribIcon from '@mui/icons-material/Crib';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import CropIcon from '@mui/icons-material/Crop';
import Crop169Icon from '@mui/icons-material/Crop169';
import Crop32Icon from '@mui/icons-material/Crop32';
import Crop54Icon from '@mui/icons-material/Crop54';
import Crop75Icon from '@mui/icons-material/Crop75';
import CropDinIcon from '@mui/icons-material/CropDin';
import CropFreeIcon from '@mui/icons-material/CropFree';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import CropRotateIcon from '@mui/icons-material/CropRotate';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import CssIcon from '@mui/icons-material/Css';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CurrencyFrancIcon from '@mui/icons-material/CurrencyFranc';
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CurrencyYenIcon from '@mui/icons-material/CurrencyYen';
import CurrencyYuanIcon from '@mui/icons-material/CurrencyYuan';
import CurtainsIcon from '@mui/icons-material/Curtains';
import CurtainsClosedIcon from '@mui/icons-material/CurtainsClosed';
import CycloneIcon from '@mui/icons-material/Cyclone';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import DataArrayIcon from '@mui/icons-material/DataArray';
import DataObjectIcon from '@mui/icons-material/DataObject';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeblurIcon from '@mui/icons-material/Deblur';
import DeckIcon from '@mui/icons-material/Deck';
import DehazeIcon from '@mui/icons-material/Dehaze';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import DescriptionIcon from '@mui/icons-material/Description';
import DeselectIcon from '@mui/icons-material/Deselect';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import DetailsIcon from '@mui/icons-material/Details';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import DeveloperBoardOffIcon from '@mui/icons-material/DeveloperBoardOff';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import DevicesIcon from '@mui/icons-material/Devices';
import DevicesFoldIcon from '@mui/icons-material/DevicesFold';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import DialerSipIcon from '@mui/icons-material/DialerSip';
import DialpadIcon from '@mui/icons-material/Dialpad';
import DiamondIcon from '@mui/icons-material/Diamond';
import DifferenceIcon from '@mui/icons-material/Difference';
import DiningIcon from '@mui/icons-material/Dining';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import DirectionsIcon from '@mui/icons-material/Directions';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import DirectionsOffIcon from '@mui/icons-material/DirectionsOff';
import DirectionsRailwayIcon from '@mui/icons-material/DirectionsRailway';
import DirectionsRailwayFilledIcon from '@mui/icons-material/DirectionsRailwayFilled';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DirectionsSubwayIcon from '@mui/icons-material/DirectionsSubway';
import DirectionsSubwayFilledIcon from '@mui/icons-material/DirectionsSubwayFilled';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import DirectionsTransitFilledIcon from '@mui/icons-material/DirectionsTransitFilled';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirtyLensIcon from '@mui/icons-material/DirtyLens';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import DiscFullIcon from '@mui/icons-material/DiscFull';
import DiscountIcon from '@mui/icons-material/Discount';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import DnsIcon from '@mui/icons-material/Dns';
import DockIcon from '@mui/icons-material/Dock';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import DomainIcon from '@mui/icons-material/Domain';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import DoNotDisturbOffIcon from '@mui/icons-material/DoNotDisturbOff';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import DoNotDisturbOnTotalSilenceIcon from '@mui/icons-material/DoNotDisturbOnTotalSilence';
import DoNotStepIcon from '@mui/icons-material/DoNotStep';
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import DoorBackIcon from '@mui/icons-material/DoorBack';
import DoorbellIcon from '@mui/icons-material/Doorbell';
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import DoorSlidingIcon from '@mui/icons-material/DoorSliding';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DownloadingIcon from '@mui/icons-material/Downloading';
import DraftsIcon from '@mui/icons-material/Drafts';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DryIcon from '@mui/icons-material/Dry';
import DryCleaningIcon from '@mui/icons-material/DryCleaning';
import DuoIcon from '@mui/icons-material/Duo';
import DvrIcon from '@mui/icons-material/Dvr';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import EarbudsIcon from '@mui/icons-material/Earbuds';
import EarbudsBatteryIcon from '@mui/icons-material/EarbudsBattery';
import EastIcon from '@mui/icons-material/East';
import EdgesensorHighIcon from '@mui/icons-material/EdgesensorHigh';
import EdgesensorLowIcon from '@mui/icons-material/EdgesensorLow';
import EditIcon from '@mui/icons-material/Edit';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import EditOffIcon from '@mui/icons-material/EditOff';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import EggIcon from '@mui/icons-material/Egg';
import EggAltIcon from '@mui/icons-material/EggAlt';
import EighteenMpIcon from '@mui/icons-material/EighteenMp';
import EightKIcon from '@mui/icons-material/EightK';
import EightKPlusIcon from '@mui/icons-material/EightKPlus';
import EightMpIcon from '@mui/icons-material/EightMp';
import EightteenMpIcon from '@mui/icons-material/EightteenMp';
import EjectIcon from '@mui/icons-material/Eject';
import ElderlyIcon from '@mui/icons-material/Elderly';
import ElderlyWomanIcon from '@mui/icons-material/ElderlyWoman';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import ElectricMopedIcon from '@mui/icons-material/ElectricMoped';
import ElectricRickshawIcon from '@mui/icons-material/ElectricRickshaw';
import ElectricScooterIcon from '@mui/icons-material/ElectricScooter';
import ElevatorIcon from '@mui/icons-material/Elevator';
import ElevenMpIcon from '@mui/icons-material/ElevenMp';
import EmailIcon from '@mui/icons-material/Email';
import EmergencyRecordingIcon from '@mui/icons-material/EmergencyRecording';
import EmergencyShareIcon from '@mui/icons-material/EmergencyShare';
import EMobiledataIcon from '@mui/icons-material/EMobiledata';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import EmojiSymbolsIcon from '@mui/icons-material/EmojiSymbols';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import EngineeringIcon from '@mui/icons-material/Engineering';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EscalatorIcon from '@mui/icons-material/Escalator';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import EuroIcon from '@mui/icons-material/Euro';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import EventIcon from '@mui/icons-material/Event';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import EvStationIcon from '@mui/icons-material/EvStation';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandIcon from '@mui/icons-material/Expand';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExplicitIcon from '@mui/icons-material/Explicit';
import ExploreIcon from '@mui/icons-material/Explore';
import ExploreOffIcon from '@mui/icons-material/ExploreOff';
import ExposureIcon from '@mui/icons-material/Exposure';
import ExtensionIcon from '@mui/icons-material/Extension';
import ExtensionOffIcon from '@mui/icons-material/ExtensionOff';
import FaceIcon from '@mui/icons-material/Face';
import FacebookIcon from '@mui/icons-material/Facebook';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import FaceRetouchingOffIcon from '@mui/icons-material/FaceRetouchingOff';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FactoryIcon from '@mui/icons-material/Factory';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FaxIcon from '@mui/icons-material/Fax';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import FeedIcon from '@mui/icons-material/Feed';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FemaleIcon from '@mui/icons-material/Female';
import FenceIcon from '@mui/icons-material/Fence';
import FestivalIcon from '@mui/icons-material/Festival';
import FiberDvrIcon from '@mui/icons-material/FiberDvr';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import FiberPinIcon from '@mui/icons-material/FiberPin';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecord';
import FifteenMpIcon from '@mui/icons-material/FifteenMp';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FilterIcon from '@mui/icons-material/Filter';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Filter5Icon from '@mui/icons-material/Filter5';
import Filter6Icon from '@mui/icons-material/Filter6';
import Filter7Icon from '@mui/icons-material/Filter7';
import Filter8Icon from '@mui/icons-material/Filter8';
import Filter9Icon from '@mui/icons-material/Filter9';
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FilterBAndWIcon from '@mui/icons-material/FilterBAndW';
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import FilterFramesIcon from '@mui/icons-material/FilterFrames';
import FilterHdrIcon from '@mui/icons-material/FilterHdr';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import FilterTiltShiftIcon from '@mui/icons-material/FilterTiltShift';
import FilterVintageIcon from '@mui/icons-material/FilterVintage';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import FireExtinguisherIcon from '@mui/icons-material/FireExtinguisher';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import FitbitIcon from '@mui/icons-material/Fitbit';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import FiveGIcon from '@mui/icons-material/FiveG';
import FiveKIcon from '@mui/icons-material/FiveK';
import FiveKPlusIcon from '@mui/icons-material/FiveKPlus';
import FiveMpIcon from '@mui/icons-material/FiveMp';
import FivteenMpIcon from '@mui/icons-material/FivteenMp';
import FlagIcon from '@mui/icons-material/Flag';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import FlakyIcon from '@mui/icons-material/Flaky';
import FlareIcon from '@mui/icons-material/Flare';
import FlashAutoIcon from '@mui/icons-material/FlashAuto';
import FlashlightOffIcon from '@mui/icons-material/FlashlightOff';
import FlashlightOnIcon from '@mui/icons-material/FlashlightOn';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FlatwareIcon from '@mui/icons-material/Flatware';
import FlightIcon from '@mui/icons-material/Flight';
import FlightClassIcon from '@mui/icons-material/FlightClass';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlipIcon from '@mui/icons-material/Flip';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import FloodIcon from '@mui/icons-material/Flood';
import FlourescentIcon from '@mui/icons-material/Flourescent';
import FlutterDashIcon from '@mui/icons-material/FlutterDash';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import FolderIcon from '@mui/icons-material/Folder';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import FontDownloadOffIcon from '@mui/icons-material/FontDownloadOff';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import ForestIcon from '@mui/icons-material/Forest';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FortIcon from '@mui/icons-material/Fort';
import ForumIcon from '@mui/icons-material/Forum';
import ForwardIcon from '@mui/icons-material/Forward';
import Forward10Icon from '@mui/icons-material/Forward10';
import Forward30Icon from '@mui/icons-material/Forward30';
import Forward5Icon from '@mui/icons-material/Forward5';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import FoundationIcon from '@mui/icons-material/Foundation';
import FourGMobiledataIcon from '@mui/icons-material/FourGMobiledata';
import FourGPlusMobiledataIcon from '@mui/icons-material/FourGPlusMobiledata';
import FourKIcon from '@mui/icons-material/FourK';
import FourKPlusIcon from '@mui/icons-material/FourKPlus';
import FourMpIcon from '@mui/icons-material/FourMp';
import FourteenMpIcon from '@mui/icons-material/FourteenMp';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FunctionsIcon from '@mui/icons-material/Functions';
import GamepadIcon from '@mui/icons-material/Gamepad';
import GamesIcon from '@mui/icons-material/Games';
import GarageIcon from '@mui/icons-material/Garage';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import GavelIcon from '@mui/icons-material/Gavel';
import GestureIcon from '@mui/icons-material/Gesture';
import GetAppIcon from '@mui/icons-material/GetApp';
import GifIcon from '@mui/icons-material/Gif';
import GifBoxIcon from '@mui/icons-material/GifBox';
import GirlIcon from '@mui/icons-material/Girl';
import GiteIcon from '@mui/icons-material/Gite';
import GitHubIcon from '@mui/icons-material/GitHub';
import GMobiledataIcon from '@mui/icons-material/GMobiledata';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import GoogleIcon from '@mui/icons-material/Google';
import GppBadIcon from '@mui/icons-material/GppBad';
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import GpsOffIcon from '@mui/icons-material/GpsOff';
import GradeIcon from '@mui/icons-material/Grade';
import GradientIcon from '@mui/icons-material/Gradient';
import GradingIcon from '@mui/icons-material/Grading';
import GrainIcon from '@mui/icons-material/Grain';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import GrassIcon from '@mui/icons-material/Grass';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import GridGoldenratioIcon from '@mui/icons-material/GridGoldenratio';
import GridOffIcon from '@mui/icons-material/GridOff';
import GridOnIcon from '@mui/icons-material/GridOn';
import GridViewIcon from '@mui/icons-material/GridView';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import HailIcon from '@mui/icons-material/Hail';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HandymanIcon from '@mui/icons-material/Handyman';
import HardwareIcon from '@mui/icons-material/Hardware';
import HdIcon from '@mui/icons-material/Hd';
import HdrAutoIcon from '@mui/icons-material/HdrAuto';
import HdrAutoSelectIcon from '@mui/icons-material/HdrAutoSelect';
import HdrEnhancedSelectIcon from '@mui/icons-material/HdrEnhancedSelect';
import HdrOffIcon from '@mui/icons-material/HdrOff';
import HdrOffSelectIcon from '@mui/icons-material/HdrOffSelect';
import HdrOnIcon from '@mui/icons-material/HdrOn';
import HdrOnSelectIcon from '@mui/icons-material/HdrOnSelect';
import HdrPlusIcon from '@mui/icons-material/HdrPlus';
import HdrStrongIcon from '@mui/icons-material/HdrStrong';
import HdrWeakIcon from '@mui/icons-material/HdrWeak';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import HeadphonesBatteryIcon from '@mui/icons-material/HeadphonesBattery';
import HeadsetIcon from '@mui/icons-material/Headset';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import HeadsetOffIcon from '@mui/icons-material/HeadsetOff';
import HealingIcon from '@mui/icons-material/Healing';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import HearingIcon from '@mui/icons-material/Hearing';
import HearingDisabledIcon from '@mui/icons-material/HearingDisabled';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import HeightIcon from '@mui/icons-material/Height';
import HelpIcon from '@mui/icons-material/Help';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HevcIcon from '@mui/icons-material/Hevc';
import HexagonIcon from '@mui/icons-material/Hexagon';
import HideImageIcon from '@mui/icons-material/HideImage';
import HideSourceIcon from '@mui/icons-material/HideSource';
import HighlightIcon from '@mui/icons-material/Highlight';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HighQualityIcon from '@mui/icons-material/HighQuality';
import HikingIcon from '@mui/icons-material/Hiking';
import HistoryIcon from '@mui/icons-material/History';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import HiveIcon from '@mui/icons-material/Hive';
import HlsIcon from '@mui/icons-material/Hls';
import HlsOffIcon from '@mui/icons-material/HlsOff';
import HMobiledataIcon from '@mui/icons-material/HMobiledata';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import HomeIcon from '@mui/icons-material/Home';
import HomeMaxIcon from '@mui/icons-material/HomeMax';
import HomeMiniIcon from '@mui/icons-material/HomeMini';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import HotelIcon from '@mui/icons-material/Hotel';
import HotTubIcon from '@mui/icons-material/HotTub';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import HouseIcon from '@mui/icons-material/House';
import HouseboatIcon from '@mui/icons-material/Houseboat';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import HPlusMobiledataIcon from '@mui/icons-material/HPlusMobiledata';
import HtmlIcon from '@mui/icons-material/Html';
import HttpIcon from '@mui/icons-material/Http';
import HttpsIcon from '@mui/icons-material/Https';
import HubIcon from '@mui/icons-material/Hub';
import HvacIcon from '@mui/icons-material/Hvac';
import IcecreamIcon from '@mui/icons-material/Icecream';
import IceSkatingIcon from '@mui/icons-material/IceSkating';
import ImageIcon from '@mui/icons-material/Image';
import ImageAspectRatioIcon from '@mui/icons-material/ImageAspectRatio';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ImagesearchRollerIcon from '@mui/icons-material/ImagesearchRoller';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import InboxIcon from '@mui/icons-material/Inbox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import InfoIcon from '@mui/icons-material/Info';
import InputIcon from '@mui/icons-material/Input';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import InsightsIcon from '@mui/icons-material/Insights';
import InstagramIcon from '@mui/icons-material/Instagram';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import InterestsIcon from '@mui/icons-material/Interests';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import InventoryIcon from '@mui/icons-material/Inventory';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import InvertColorsOffIcon from '@mui/icons-material/InvertColorsOff';
import IosShareIcon from '@mui/icons-material/IosShare';
import IronIcon from '@mui/icons-material/Iron';
import IsoIcon from '@mui/icons-material/Iso';
import JavascriptIcon from '@mui/icons-material/Javascript';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import JoinRightIcon from '@mui/icons-material/JoinRight';
import KayakingIcon from '@mui/icons-material/Kayaking';
import KebabDiningIcon from '@mui/icons-material/KebabDining';
import KeyIcon from '@mui/icons-material/Key';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import KeyboardAltIcon from '@mui/icons-material/KeyboardAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardHideIcon from '@mui/icons-material/KeyboardHide';
import KeyboardOptionKeyIcon from '@mui/icons-material/KeyboardOptionKey';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import KingBedIcon from '@mui/icons-material/KingBed';
import KitchenIcon from '@mui/icons-material/Kitchen';
import KitesurfingIcon from '@mui/icons-material/Kitesurfing';
import LabelIcon from '@mui/icons-material/Label';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import LabelOffIcon from '@mui/icons-material/LabelOff';
import LanIcon from '@mui/icons-material/Lan';
import LandscapeIcon from '@mui/icons-material/Landscape';
import LandslideIcon from '@mui/icons-material/Landslide';
import LanguageIcon from '@mui/icons-material/Language';
import LaptopIcon from '@mui/icons-material/Laptop';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import LastPageIcon from '@mui/icons-material/LastPage';
import LaunchIcon from '@mui/icons-material/Launch';
import LayersIcon from '@mui/icons-material/Layers';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import LeakRemoveIcon from '@mui/icons-material/LeakRemove';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import LensIcon from '@mui/icons-material/Lens';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import LightIcon from '@mui/icons-material/Light';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import LightModeIcon from '@mui/icons-material/LightMode';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import LinkIcon from '@mui/icons-material/Link';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LiquorIcon from '@mui/icons-material/Liquor';
import ListIcon from '@mui/icons-material/List';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import LivingIcon from '@mui/icons-material/Living';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import LocalPlayIcon from '@mui/icons-material/LocalPlay';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import LocalSeeIcon from '@mui/icons-material/LocalSee';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationDisabledIcon from '@mui/icons-material/LocationDisabled';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import LockIcon from '@mui/icons-material/Lock';
import LockClockIcon from '@mui/icons-material/LockClock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockResetIcon from '@mui/icons-material/LockReset';
import LoginIcon from '@mui/icons-material/Login';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import LogoutIcon from '@mui/icons-material/Logout';
import LooksIcon from '@mui/icons-material/Looks';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import LoopIcon from '@mui/icons-material/Loop';
import LoupeIcon from '@mui/icons-material/Loupe';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import LteMobiledataIcon from '@mui/icons-material/LteMobiledata';
import LtePlusMobiledataIcon from '@mui/icons-material/LtePlusMobiledata';
import LuggageIcon from '@mui/icons-material/Luggage';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import LyricsIcon from '@mui/icons-material/Lyrics';
import MailIcon from '@mui/icons-material/Mail';
import MailLockIcon from '@mui/icons-material/MailLock';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MaleIcon from '@mui/icons-material/Male';
import ManIcon from '@mui/icons-material/Man';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MapIcon from '@mui/icons-material/Map';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import MarginIcon from '@mui/icons-material/Margin';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import MasksIcon from '@mui/icons-material/Masks';
import MaximizeIcon from '@mui/icons-material/Maximize';
import MediaBluetoothOffIcon from '@mui/icons-material/MediaBluetoothOff';
import MediaBluetoothOnIcon from '@mui/icons-material/MediaBluetoothOn';
import MediationIcon from '@mui/icons-material/Mediation';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import MedicationIcon from '@mui/icons-material/Medication';
import MedicationLiquidIcon from '@mui/icons-material/MedicationLiquid';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MemoryIcon from '@mui/icons-material/Memory';
import MenuIcon from '@mui/icons-material/Menu';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MergeIcon from '@mui/icons-material/Merge';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import MessageIcon from '@mui/icons-material/Message';
import MicIcon from '@mui/icons-material/Mic';
import MicExternalOffIcon from '@mui/icons-material/MicExternalOff';
import MicExternalOnIcon from '@mui/icons-material/MicExternalOn';
import MicNoneIcon from '@mui/icons-material/MicNone';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import MissedVideoCallIcon from '@mui/icons-material/MissedVideoCall';
import MmsIcon from '@mui/icons-material/Mms';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import MobileOffIcon from '@mui/icons-material/MobileOff';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import ModeIcon from '@mui/icons-material/Mode';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import ModeFanOffIcon from '@mui/icons-material/ModeFanOff';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MoneyIcon from '@mui/icons-material/Money';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import MoneyOffCsredIcon from '@mui/icons-material/MoneyOffCsred';
import MonitorIcon from '@mui/icons-material/Monitor';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import MonochromePhotosIcon from '@mui/icons-material/MonochromePhotos';
import MoodIcon from '@mui/icons-material/Mood';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import MopedIcon from '@mui/icons-material/Moped';
import MoreIcon from '@mui/icons-material/More';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MosqueIcon from '@mui/icons-material/Mosque';
import MotionPhotosAutoIcon from '@mui/icons-material/MotionPhotosAuto';
import MotionPhotosOffIcon from '@mui/icons-material/MotionPhotosOff';
import MouseIcon from '@mui/icons-material/Mouse';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import MovieIcon from '@mui/icons-material/Movie';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import MovingIcon from '@mui/icons-material/Moving';
import MpIcon from '@mui/icons-material/Mp';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import MuseumIcon from '@mui/icons-material/Museum';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import MusicOffIcon from '@mui/icons-material/MusicOff';
import MusicVideoIcon from '@mui/icons-material/MusicVideo';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import NatIcon from '@mui/icons-material/Nat';
import NatureIcon from '@mui/icons-material/Nature';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigationIcon from '@mui/icons-material/Navigation';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import NearbyOffIcon from '@mui/icons-material/NearbyOff';
import NearMeIcon from '@mui/icons-material/NearMe';
import NearMeDisabledIcon from '@mui/icons-material/NearMeDisabled';
import NestCamWiredStandIcon from '@mui/icons-material/NestCamWiredStand';
import NetworkCellIcon from '@mui/icons-material/NetworkCell';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import NetworkLockedIcon from '@mui/icons-material/NetworkLocked';
import NetworkPingIcon from '@mui/icons-material/NetworkPing';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import NetworkWifi1BarIcon from '@mui/icons-material/NetworkWifi1Bar';
import NetworkWifi2BarIcon from '@mui/icons-material/NetworkWifi2Bar';
import NetworkWifi3BarIcon from '@mui/icons-material/NetworkWifi3Bar';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import NfcIcon from '@mui/icons-material/Nfc';
import NightlifeIcon from '@mui/icons-material/Nightlife';
import NightlightIcon from '@mui/icons-material/Nightlight';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import NightShelterIcon from '@mui/icons-material/NightShelter';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import NineKIcon from '@mui/icons-material/NineK';
import NineKPlusIcon from '@mui/icons-material/NineKPlus';
import NineMpIcon from '@mui/icons-material/NineMp';
import NineteenMpIcon from '@mui/icons-material/NineteenMp';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import NoBackpackIcon from '@mui/icons-material/NoBackpack';
import NoCellIcon from '@mui/icons-material/NoCell';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import NoDrinksIcon from '@mui/icons-material/NoDrinks';
import NoEncryptionIcon from '@mui/icons-material/NoEncryption';
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';
import NoFlashIcon from '@mui/icons-material/NoFlash';
import NoFoodIcon from '@mui/icons-material/NoFood';
import NoiseAwareIcon from '@mui/icons-material/NoiseAware';
import NoiseControlOffIcon from '@mui/icons-material/NoiseControlOff';
import NoLuggageIcon from '@mui/icons-material/NoLuggage';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import NoMeetingRoomIcon from '@mui/icons-material/NoMeetingRoom';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import NordicWalkingIcon from '@mui/icons-material/NordicWalking';
import NorthIcon from '@mui/icons-material/North';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import NorthWestIcon from '@mui/icons-material/NorthWest';
import NoSimIcon from '@mui/icons-material/NoSim';
import NoStrollerIcon from '@mui/icons-material/NoStroller';
import NotAccessibleIcon from '@mui/icons-material/NotAccessible';
import NoteIcon from '@mui/icons-material/Note';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import NotesIcon from '@mui/icons-material/Notes';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import NoTransferIcon from '@mui/icons-material/NoTransfer';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import NumbersIcon from '@mui/icons-material/Numbers';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import OfflineShareIcon from '@mui/icons-material/OfflineShare';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import OnDeviceTrainingIcon from '@mui/icons-material/OnDeviceTraining';
import OneKIcon from '@mui/icons-material/OneK';
import OneKkIcon from '@mui/icons-material/OneKk';
import OneKPlusIcon from '@mui/icons-material/OneKPlus';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import OpacityIcon from '@mui/icons-material/Opacity';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OpenInNewOffIcon from '@mui/icons-material/OpenInNewOff';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import OutboundIcon from '@mui/icons-material/Outbound';
import OutboxIcon from '@mui/icons-material/Outbox';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import OutletIcon from '@mui/icons-material/Outlet';
import OutputIcon from '@mui/icons-material/Output';
import PaddingIcon from '@mui/icons-material/Padding';
import PagesIcon from '@mui/icons-material/Pages';
import PageviewIcon from '@mui/icons-material/Pageview';
import PaidIcon from '@mui/icons-material/Paid';
import PaletteIcon from '@mui/icons-material/Palette';
import PanoramaIcon from '@mui/icons-material/Panorama';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import PanoramaHorizontalIcon from '@mui/icons-material/PanoramaHorizontal';
import PanoramaHorizontalSelectIcon from '@mui/icons-material/PanoramaHorizontalSelect';
import PanoramaPhotosphereIcon from '@mui/icons-material/PanoramaPhotosphere';
import PanoramaPhotosphereSelectIcon from '@mui/icons-material/PanoramaPhotosphereSelect';
import PanoramaVerticalIcon from '@mui/icons-material/PanoramaVertical';
import PanoramaVerticalSelectIcon from '@mui/icons-material/PanoramaVerticalSelect';
import PanoramaWideAngleIcon from '@mui/icons-material/PanoramaWideAngle';
import PanoramaWideAngleSelectIcon from '@mui/icons-material/PanoramaWideAngleSelect';
import PanToolIcon from '@mui/icons-material/PanTool';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import ParaglidingIcon from '@mui/icons-material/Paragliding';
import ParkIcon from '@mui/icons-material/Park';
import PartyModeIcon from '@mui/icons-material/PartyMode';
import PasswordIcon from '@mui/icons-material/Password';
import PatternIcon from '@mui/icons-material/Pattern';
import PauseIcon from '@mui/icons-material/Pause';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PausePresentationIcon from '@mui/icons-material/PausePresentation';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentsIcon from '@mui/icons-material/Payments';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import PendingIcon from '@mui/icons-material/Pending';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PentagonIcon from '@mui/icons-material/Pentagon';
import PeopleIcon from '@mui/icons-material/People';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PercentIcon from '@mui/icons-material/Percent';
import PermCameraMicIcon from '@mui/icons-material/PermCameraMic';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import PermScanWifiIcon from '@mui/icons-material/PermScanWifi';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PestControlIcon from '@mui/icons-material/PestControl';
import PestControlRodentIcon from '@mui/icons-material/PestControlRodent';
import PetsIcon from '@mui/icons-material/Pets';
import PhishingIcon from '@mui/icons-material/Phishing';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneBluetoothSpeakerIcon from '@mui/icons-material/PhoneBluetoothSpeaker';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import PhoneLockedIcon from '@mui/icons-material/PhoneLocked';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import PhotoIcon from '@mui/icons-material/Photo';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import PhotoSizeSelectSmallIcon from '@mui/icons-material/PhotoSizeSelectSmall';
import PhpIcon from '@mui/icons-material/Php';
import PianoIcon from '@mui/icons-material/Piano';
import PianoOffIcon from '@mui/icons-material/PianoOff';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import PieChartIcon from '@mui/icons-material/PieChart';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import PinIcon from '@mui/icons-material/Pin';
import PinchIcon from '@mui/icons-material/Pinch';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PinterestIcon from '@mui/icons-material/Pinterest';
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import PixIcon from '@mui/icons-material/Pix';
import PlaceIcon from '@mui/icons-material/Place';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PlayDisabledIcon from '@mui/icons-material/PlayDisabled';
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';
import PlayLessonIcon from '@mui/icons-material/PlayLesson';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PolicyIcon from '@mui/icons-material/Policy';
import PollIcon from '@mui/icons-material/Poll';
import PolylineIcon from '@mui/icons-material/Polyline';
import PoolIcon from '@mui/icons-material/Pool';
import PortableWifiOffIcon from '@mui/icons-material/PortableWifiOff';
import PortraitIcon from '@mui/icons-material/Portrait';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PowerIcon from '@mui/icons-material/Power';
import PowerInputIcon from '@mui/icons-material/PowerInput';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import PreviewIcon from '@mui/icons-material/Preview';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PrintIcon from '@mui/icons-material/Print';
import PrintDisabledIcon from '@mui/icons-material/PrintDisabled';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import PropaneIcon from '@mui/icons-material/Propane';
import PropaneTankIcon from '@mui/icons-material/PropaneTank';
import PsychologyIcon from '@mui/icons-material/Psychology';
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import PublishIcon from '@mui/icons-material/Publish';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import PushPinIcon from '@mui/icons-material/PushPin';
import QrCodeIcon from '@mui/icons-material/QrCode';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import QueueIcon from '@mui/icons-material/Queue';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import QuizIcon from '@mui/icons-material/Quiz';
import RadarIcon from '@mui/icons-material/Radar';
import RadioIcon from '@mui/icons-material/Radio';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RailwayAlertIcon from '@mui/icons-material/RailwayAlert';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import RampLeftIcon from '@mui/icons-material/RampLeft';
import RampRightIcon from '@mui/icons-material/RampRight';
import RateReviewIcon from '@mui/icons-material/RateReview';
import RawOffIcon from '@mui/icons-material/RawOff';
import RawOnIcon from '@mui/icons-material/RawOn';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import RecommendIcon from '@mui/icons-material/Recommend';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import RectangleIcon from '@mui/icons-material/Rectangle';
import RedditIcon from '@mui/icons-material/Reddit';
import RedeemIcon from '@mui/icons-material/Redeem';
import RedoIcon from '@mui/icons-material/Redo';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import RefreshIcon from '@mui/icons-material/Refresh';
import RememberMeIcon from '@mui/icons-material/RememberMe';
import RemoveIcon from '@mui/icons-material/Remove';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import RemoveFromQueueIcon from '@mui/icons-material/RemoveFromQueue';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import RemoveRoadIcon from '@mui/icons-material/RemoveRoad';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import ReorderIcon from '@mui/icons-material/Reorder';
import RepeatIcon from '@mui/icons-material/Repeat';
import RepeatOnIcon from '@mui/icons-material/RepeatOn';
import RepeatOneIcon from '@mui/icons-material/RepeatOne';
import RepeatOneOnIcon from '@mui/icons-material/RepeatOneOn';
import ReplayIcon from '@mui/icons-material/Replay';
import Replay10Icon from '@mui/icons-material/Replay10';
import Replay30Icon from '@mui/icons-material/Replay30';
import Replay5Icon from '@mui/icons-material/Replay5';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import ReplyIcon from '@mui/icons-material/Reply';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import ReportIcon from '@mui/icons-material/Report';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ReportOffIcon from '@mui/icons-material/ReportOff';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ResetTvIcon from '@mui/icons-material/ResetTv';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import RestoreIcon from '@mui/icons-material/Restore';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import ReviewsIcon from '@mui/icons-material/Reviews';
import RiceBowlIcon from '@mui/icons-material/RiceBowl';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import RMobiledataIcon from '@mui/icons-material/RMobiledata';
import RocketIcon from '@mui/icons-material/Rocket';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import RollerShadesIcon from '@mui/icons-material/RollerShades';
import RollerShadesClosedIcon from '@mui/icons-material/RollerShadesClosed';
import RollerSkatingIcon from '@mui/icons-material/RollerSkating';
import RoofingIcon from '@mui/icons-material/Roofing';
import RoomIcon from '@mui/icons-material/Room';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RoundaboutLeftIcon from '@mui/icons-material/RoundaboutLeft';
import RoundaboutRightIcon from '@mui/icons-material/RoundaboutRight';
import RouteIcon from '@mui/icons-material/Route';
import RouterIcon from '@mui/icons-material/Router';
import RowingIcon from '@mui/icons-material/Rowing';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import RsvpIcon from '@mui/icons-material/Rsvp';
import RttIcon from '@mui/icons-material/Rtt';
import RuleIcon from '@mui/icons-material/Rule';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import RunCircleIcon from '@mui/icons-material/RunCircle';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import SafetyDividerIcon from '@mui/icons-material/SafetyDivider';
import SailingIcon from '@mui/icons-material/Sailing';
import SanitizerIcon from '@mui/icons-material/Sanitizer';
import SatelliteIcon from '@mui/icons-material/Satellite';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import SaveIcon from '@mui/icons-material/Save';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import SavingsIcon from '@mui/icons-material/Savings';
import ScaleIcon from '@mui/icons-material/Scale';
import ScannerIcon from '@mui/icons-material/Scanner';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SchemaIcon from '@mui/icons-material/Schema';
import SchoolIcon from '@mui/icons-material/School';
import ScienceIcon from '@mui/icons-material/Science';
import ScoreIcon from '@mui/icons-material/Score';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import ScreenLockLandscapeIcon from '@mui/icons-material/ScreenLockLandscape';
import ScreenLockPortraitIcon from '@mui/icons-material/ScreenLockPortrait';
import ScreenLockRotationIcon from '@mui/icons-material/ScreenLockRotation';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import ScreenRotationAltIcon from '@mui/icons-material/ScreenRotationAlt';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import ScreenshotIcon from '@mui/icons-material/Screenshot';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import ScubaDivingIcon from '@mui/icons-material/ScubaDiving';
import SdIcon from '@mui/icons-material/Sd';
import SdCardIcon from '@mui/icons-material/SdCard';
import SdCardAlertIcon from '@mui/icons-material/SdCardAlert';
import SdStorageIcon from '@mui/icons-material/SdStorage';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import SecurityIcon from '@mui/icons-material/Security';
import SecurityUpdateIcon from '@mui/icons-material/SecurityUpdate';
import SecurityUpdateGoodIcon from '@mui/icons-material/SecurityUpdateGood';
import SecurityUpdateWarningIcon from '@mui/icons-material/SecurityUpdateWarning';
import SegmentIcon from '@mui/icons-material/Segment';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import SellIcon from '@mui/icons-material/Sell';
import SendIcon from '@mui/icons-material/Send';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import SendTimeExtensionIcon from '@mui/icons-material/SendTimeExtension';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import SensorDoorIcon from '@mui/icons-material/SensorDoor';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import SensorsIcon from '@mui/icons-material/Sensors';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import SensorWindowIcon from '@mui/icons-material/SensorWindow';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SetMealIcon from '@mui/icons-material/SetMeal';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import SettingsBluetoothIcon from '@mui/icons-material/SettingsBluetooth';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import SettingsCellIcon from '@mui/icons-material/SettingsCell';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite';
import SettingsInputHdmiIcon from '@mui/icons-material/SettingsInputHdmi';
import SettingsInputSvideoIcon from '@mui/icons-material/SettingsInputSvideo';
import SettingsOverscanIcon from '@mui/icons-material/SettingsOverscan';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import SettingsPowerIcon from '@mui/icons-material/SettingsPower';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice';
import SevenKIcon from '@mui/icons-material/SevenK';
import SevenKPlusIcon from '@mui/icons-material/SevenKPlus';
import SevenMpIcon from '@mui/icons-material/SevenMp';
import SeventeenMpIcon from '@mui/icons-material/SeventeenMp';
import SevereColdIcon from '@mui/icons-material/SevereCold';
import ShareIcon from '@mui/icons-material/Share';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import ShieldIcon from '@mui/icons-material/Shield';
import ShieldMoonIcon from '@mui/icons-material/ShieldMoon';
import ShopIcon from '@mui/icons-material/Shop';
import Shop2Icon from '@mui/icons-material/Shop2';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import ShopTwoIcon from '@mui/icons-material/ShopTwo';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import ShortTextIcon from '@mui/icons-material/ShortText';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ShowerIcon from '@mui/icons-material/Shower';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import ShuffleOnIcon from '@mui/icons-material/ShuffleOn';
import ShutterSpeedIcon from '@mui/icons-material/ShutterSpeed';
import SickIcon from '@mui/icons-material/Sick';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import SignalCellularAlt1BarIcon from '@mui/icons-material/SignalCellularAlt1Bar';
import SignalCellularAlt2BarIcon from '@mui/icons-material/SignalCellularAlt2Bar';
import SignalCellularConnectedNoInternet0BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';
import SignalCellularConnectedNoInternet1BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet1Bar';
import SignalCellularConnectedNoInternet2BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet2Bar';
import SignalCellularConnectedNoInternet3BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet3Bar';
import SignalCellularConnectedNoInternet4BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet4Bar';
import SignalCellularNodataIcon from '@mui/icons-material/SignalCellularNodata';
import SignalCellularNoSimIcon from '@mui/icons-material/SignalCellularNoSim';
import SignalCellularNullIcon from '@mui/icons-material/SignalCellularNull';
import SignalCellularOffIcon from '@mui/icons-material/SignalCellularOff';
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar';
import SignalWifi1BarIcon from '@mui/icons-material/SignalWifi1Bar';
import SignalWifi1BarLockIcon from '@mui/icons-material/SignalWifi1BarLock';
import SignalWifi2BarIcon from '@mui/icons-material/SignalWifi2Bar';
import SignalWifi2BarLockIcon from '@mui/icons-material/SignalWifi2BarLock';
import SignalWifi3BarIcon from '@mui/icons-material/SignalWifi3Bar';
import SignalWifi3BarLockIcon from '@mui/icons-material/SignalWifi3BarLock';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import SignalWifi4BarLockIcon from '@mui/icons-material/SignalWifi4BarLock';
import SignalWifiBadIcon from '@mui/icons-material/SignalWifiBad';
import SignalWifiConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiConnectedNoInternet4';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4';
import SignalWifiStatusbarNullIcon from '@mui/icons-material/SignalWifiStatusbarNull';
import SignLanguageIcon from '@mui/icons-material/SignLanguage';
import SignpostIcon from '@mui/icons-material/Signpost';
import SimCardIcon from '@mui/icons-material/SimCard';
import SimCardAlertIcon from '@mui/icons-material/SimCardAlert';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import SipIcon from '@mui/icons-material/Sip';
import SixKIcon from '@mui/icons-material/SixK';
import SixKPlusIcon from '@mui/icons-material/SixKPlus';
import SixMpIcon from '@mui/icons-material/SixMp';
import SixteenMpIcon from '@mui/icons-material/SixteenMp';
import SixtyFpsIcon from '@mui/icons-material/SixtyFps';
import SixtyFpsSelectIcon from '@mui/icons-material/SixtyFpsSelect';
import SkateboardingIcon from '@mui/icons-material/Skateboarding';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SleddingIcon from '@mui/icons-material/Sledding';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import SmartScreenIcon from '@mui/icons-material/SmartScreen';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SmokeFreeIcon from '@mui/icons-material/SmokeFree';
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms';
import SmsIcon from '@mui/icons-material/Sms';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import SnoozeIcon from '@mui/icons-material/Snooze';
import SnowboardingIcon from '@mui/icons-material/Snowboarding';
import SnowmobileIcon from '@mui/icons-material/Snowmobile';
import SnowshoeingIcon from '@mui/icons-material/Snowshoeing';
import SoapIcon from '@mui/icons-material/Soap';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import SortIcon from '@mui/icons-material/Sort';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import SosIcon from '@mui/icons-material/Sos';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import SourceIcon from '@mui/icons-material/Source';
import SouthIcon from '@mui/icons-material/South';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import SpaIcon from '@mui/icons-material/Spa';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import SpatialAudioIcon from '@mui/icons-material/SpatialAudio';
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
import SpatialTrackingIcon from '@mui/icons-material/SpatialTracking';
import SpeakerIcon from '@mui/icons-material/Speaker';
import SpeakerGroupIcon from '@mui/icons-material/SpeakerGroup';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import SpeakerPhoneIcon from '@mui/icons-material/SpeakerPhone';
import SpeedIcon from '@mui/icons-material/Speed';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import SpokeIcon from '@mui/icons-material/Spoke';
import SportsIcon from '@mui/icons-material/Sports';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsCricketIcon from '@mui/icons-material/SportsCricket';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import SportsGolfIcon from '@mui/icons-material/SportsGolf';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import SportsRugbyIcon from '@mui/icons-material/SportsRugby';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import SquareIcon from '@mui/icons-material/Square';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import StadiumIcon from '@mui/icons-material/Stadium';
import StairsIcon from '@mui/icons-material/Stairs';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import StarRateIcon from '@mui/icons-material/StarRate';
import StarsIcon from '@mui/icons-material/Stars';
import StartIcon from '@mui/icons-material/Start';
import StayCurrentLandscapeIcon from '@mui/icons-material/StayCurrentLandscape';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import StayPrimaryLandscapeIcon from '@mui/icons-material/StayPrimaryLandscape';
import StayPrimaryPortraitIcon from '@mui/icons-material/StayPrimaryPortrait';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import StopIcon from '@mui/icons-material/Stop';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import StorageIcon from '@mui/icons-material/Storage';
import StoreIcon from '@mui/icons-material/Store';
import StorefrontIcon from '@mui/icons-material/Storefront';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import StormIcon from '@mui/icons-material/Storm';
import StraightIcon from '@mui/icons-material/Straight';
import StraightenIcon from '@mui/icons-material/Straighten';
import StreamIcon from '@mui/icons-material/Stream';
import StreetviewIcon from '@mui/icons-material/Streetview';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import StrollerIcon from '@mui/icons-material/Stroller';
import StyleIcon from '@mui/icons-material/Style';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import SubjectIcon from '@mui/icons-material/Subject';
import SubscriptIcon from '@mui/icons-material/Subscript';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import SubtitlesOffIcon from '@mui/icons-material/SubtitlesOff';
import SubwayIcon from '@mui/icons-material/Subway';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SupportIcon from '@mui/icons-material/Support';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SurfingIcon from '@mui/icons-material/Surfing';
import SurroundSoundIcon from '@mui/icons-material/SurroundSound';
import SwapCallsIcon from '@mui/icons-material/SwapCalls';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import SwipeIcon from '@mui/icons-material/Swipe';
import SwipeDownIcon from '@mui/icons-material/SwipeDown';
import SwipeDownAltIcon from '@mui/icons-material/SwipeDownAlt';
import SwipeLeftIcon from '@mui/icons-material/SwipeLeft';
import SwipeLeftAltIcon from '@mui/icons-material/SwipeLeftAlt';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import SwipeRightAltIcon from '@mui/icons-material/SwipeRightAlt';
import SwipeUpIcon from '@mui/icons-material/SwipeUp';
import SwipeUpAltIcon from '@mui/icons-material/SwipeUpAlt';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import SwitchCameraIcon from '@mui/icons-material/SwitchCamera';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import SwitchRightIcon from '@mui/icons-material/SwitchRight';
import SwitchVideoIcon from '@mui/icons-material/SwitchVideo';
import SynagogueIcon from '@mui/icons-material/Synagogue';
import SyncIcon from '@mui/icons-material/Sync';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import SystemSecurityUpdateIcon from '@mui/icons-material/SystemSecurityUpdate';
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import SystemSecurityUpdateWarningIcon from '@mui/icons-material/SystemSecurityUpdateWarning';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import TabIcon from '@mui/icons-material/Tab';
import TableBarIcon from '@mui/icons-material/TableBar';
import TableChartIcon from '@mui/icons-material/TableChart';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import TableRowsIcon from '@mui/icons-material/TableRows';
import TabletIcon from '@mui/icons-material/Tablet';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import TableViewIcon from '@mui/icons-material/TableView';
import TabUnselectedIcon from '@mui/icons-material/TabUnselected';
import TagIcon from '@mui/icons-material/Tag';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import TapasIcon from '@mui/icons-material/Tapas';
import TaskIcon from '@mui/icons-material/Task';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';
import TelegramIcon from '@mui/icons-material/Telegram';
import TempleBuddhistIcon from '@mui/icons-material/TempleBuddhist';
import TempleHinduIcon from '@mui/icons-material/TempleHindu';
import TenMpIcon from '@mui/icons-material/TenMp';
import TerminalIcon from '@mui/icons-material/Terminal';
import TerrainIcon from '@mui/icons-material/Terrain';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import TextRotateUpIcon from '@mui/icons-material/TextRotateUp';
import TextRotateVerticalIcon from '@mui/icons-material/TextRotateVertical';
import TextRotationAngledownIcon from '@mui/icons-material/TextRotationAngledown';
import TextRotationAngleupIcon from '@mui/icons-material/TextRotationAngleup';
import TextRotationDownIcon from '@mui/icons-material/TextRotationDown';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import TextsmsIcon from '@mui/icons-material/Textsms';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TextureIcon from '@mui/icons-material/Texture';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import TheatersIcon from '@mui/icons-material/Theaters';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import ThermostatAutoIcon from '@mui/icons-material/ThermostatAuto';
import ThirteenMpIcon from '@mui/icons-material/ThirteenMp';
import ThirtyFpsIcon from '@mui/icons-material/ThirtyFps';
import ThirtyFpsSelectIcon from '@mui/icons-material/ThirtyFpsSelect';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import ThreeGMobiledataIcon from '@mui/icons-material/ThreeGMobiledata';
import ThreeKIcon from '@mui/icons-material/ThreeK';
import ThreeKPlusIcon from '@mui/icons-material/ThreeKPlus';
import ThreeMpIcon from '@mui/icons-material/ThreeMp';
import ThreePIcon from '@mui/icons-material/ThreeP';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import TimelineIcon from '@mui/icons-material/Timeline';
import TimerIcon from '@mui/icons-material/Timer';
import Timer10Icon from '@mui/icons-material/Timer10';
import Timer10SelectIcon from '@mui/icons-material/Timer10Select';
import Timer3Icon from '@mui/icons-material/Timer3';
import Timer3SelectIcon from '@mui/icons-material/Timer3Select';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import TimesOneMobiledataIcon from '@mui/icons-material/TimesOneMobiledata';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import TireRepairIcon from '@mui/icons-material/TireRepair';
import TitleIcon from '@mui/icons-material/Title';
import TocIcon from '@mui/icons-material/Toc';
import TodayIcon from '@mui/icons-material/Today';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import TokenIcon from '@mui/icons-material/Token';
import TollIcon from '@mui/icons-material/Toll';
import TonalityIcon from '@mui/icons-material/Tonality';
import TopicIcon from '@mui/icons-material/Topic';
import TornadoIcon from '@mui/icons-material/Tornado';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import TourIcon from '@mui/icons-material/Tour';
import ToysIcon from '@mui/icons-material/Toys';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import TrafficIcon from '@mui/icons-material/Traffic';
import TrainIcon from '@mui/icons-material/Train';
import TramIcon from '@mui/icons-material/Tram';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import TransformIcon from '@mui/icons-material/Transform';
import TransgenderIcon from '@mui/icons-material/Transgender';
import TransitEnterexitIcon from '@mui/icons-material/TransitEnterexit';
import TranslateIcon from '@mui/icons-material/Translate';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import TryIcon from '@mui/icons-material/Try';
import TsunamiIcon from '@mui/icons-material/Tsunami';
import TtyIcon from '@mui/icons-material/Tty';
import TuneIcon from '@mui/icons-material/Tune';
import TungstenIcon from '@mui/icons-material/Tungsten';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import TurnSlightLeftIcon from '@mui/icons-material/TurnSlightLeft';
import TurnSlightRightIcon from '@mui/icons-material/TurnSlightRight';
import TvIcon from '@mui/icons-material/Tv';
import TvOffIcon from '@mui/icons-material/TvOff';
import TwelveMpIcon from '@mui/icons-material/TwelveMp';
import TwentyFourMpIcon from '@mui/icons-material/TwentyFourMp';
import TwentyOneMpIcon from '@mui/icons-material/TwentyOneMp';
import TwentyThreeMpIcon from '@mui/icons-material/TwentyThreeMp';
import TwentyTwoMpIcon from '@mui/icons-material/TwentyTwoMp';
import TwentyZeroMpIcon from '@mui/icons-material/TwentyZeroMp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TwoKIcon from '@mui/icons-material/TwoK';
import TwoKPlusIcon from '@mui/icons-material/TwoKPlus';
import TwoMpIcon from '@mui/icons-material/TwoMp';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import UmbrellaIcon from '@mui/icons-material/Umbrella';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import UndoIcon from '@mui/icons-material/Undo';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import UpdateIcon from '@mui/icons-material/Update';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import UploadIcon from '@mui/icons-material/Upload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import UsbIcon from '@mui/icons-material/Usb';
import UsbOffIcon from '@mui/icons-material/UsbOff';
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';
import UTurnRightIcon from '@mui/icons-material/UTurnRight';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import VapeFreeIcon from '@mui/icons-material/VapeFree';
import VapingRoomsIcon from '@mui/icons-material/VapingRooms';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalShadesIcon from '@mui/icons-material/VerticalShades';
import VerticalShadesClosedIcon from '@mui/icons-material/VerticalShadesClosed';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import VibrationIcon from '@mui/icons-material/Vibration';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import VideogameAssetOffIcon from '@mui/icons-material/VideogameAssetOff';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import VideoStableIcon from '@mui/icons-material/VideoStable';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import ViewArrayIcon from '@mui/icons-material/ViewArray';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import ViewComfyAltIcon from '@mui/icons-material/ViewComfyAlt';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ViewCompactAltIcon from '@mui/icons-material/ViewCompactAlt';
import ViewCozyIcon from '@mui/icons-material/ViewCozy';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import VignetteIcon from '@mui/icons-material/Vignette';
import VillaIcon from '@mui/icons-material/Villa';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import VoiceOverOffIcon from '@mui/icons-material/VoiceOverOff';
import VolcanoIcon from '@mui/icons-material/Volcano';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import VpnKeyOffIcon from '@mui/icons-material/VpnKeyOff';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import VrpanoIcon from '@mui/icons-material/Vrpano';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WashIcon from '@mui/icons-material/Wash';
import WatchIcon from '@mui/icons-material/Watch';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import WatchOffIcon from '@mui/icons-material/WatchOff';
import WaterIcon from '@mui/icons-material/Water';
import WaterDamageIcon from '@mui/icons-material/WaterDamage';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import WavesIcon from '@mui/icons-material/Waves';
import WbAutoIcon from '@mui/icons-material/WbAuto';
import WbCloudyIcon from '@mui/icons-material/WbCloudy';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import WbIridescentIcon from '@mui/icons-material/WbIridescent';
import WbShadeIcon from '@mui/icons-material/WbShade';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import WcIcon from '@mui/icons-material/Wc';
import WebIcon from '@mui/icons-material/Web';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import WebAssetOffIcon from '@mui/icons-material/WebAssetOff';
import WebhookIcon from '@mui/icons-material/Webhook';
import WeekendIcon from '@mui/icons-material/Weekend';
import WestIcon from '@mui/icons-material/West';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import WidgetsIcon from '@mui/icons-material/Widgets';
import WifiIcon from '@mui/icons-material/Wifi';
import Wifi1BarIcon from '@mui/icons-material/Wifi1Bar';
import Wifi2BarIcon from '@mui/icons-material/Wifi2Bar';
import WifiCallingIcon from '@mui/icons-material/WifiCalling';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import WifiChannelIcon from '@mui/icons-material/WifiChannel';
import WifiFindIcon from '@mui/icons-material/WifiFind';
import WifiLockIcon from '@mui/icons-material/WifiLock';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WifiPasswordIcon from '@mui/icons-material/WifiPassword';
import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import WifiTetheringErrorIcon from '@mui/icons-material/WifiTetheringError';
import WifiTetheringOffIcon from '@mui/icons-material/WifiTetheringOff';
import WindowIcon from '@mui/icons-material/Window';
import WindPowerIcon from '@mui/icons-material/WindPower';
import WineBarIcon from '@mui/icons-material/WineBar';
import WomanIcon from '@mui/icons-material/Woman';
import WorkIcon from '@mui/icons-material/Work';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import WrapTextIcon from '@mui/icons-material/WrapText';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import YardIcon from '@mui/icons-material/Yard';
import YouTubeIcon from '@mui/icons-material/YouTube';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

export const MaterialUiIcons = {
  Abc: AbcIcon,
  AccessAlarm: AccessAlarmIcon,
  AccessAlarms: AccessAlarmsIcon,
  Accessibility: AccessibilityIcon,
  AccessibilityNew: AccessibilityNewIcon,
  Accessible: AccessibleIcon,
  AccessibleForward: AccessibleForwardIcon,
  AccessTime: AccessTimeIcon,
  AccessTimeFilled: AccessTimeFilledIcon,
  AccountBalance: AccountBalanceIcon,
  AccountBalanceWallet: AccountBalanceWalletIcon,
  AccountBox: AccountBoxIcon,
  AccountCircle: AccountCircleIcon,
  AccountTree: AccountTreeIcon,
  AcUnit: AcUnitIcon,
  Adb: AdbIcon,
  Add: AddIcon,
  AddAlarm: AddAlarmIcon,
  AddAlert: AddAlertIcon,
  AddAPhoto: AddAPhotoIcon,
  AddBox: AddBoxIcon,
  AddBusiness: AddBusinessIcon,
  AddCard: AddCardIcon,
  Addchart: AddchartIcon,
  AddCircle: AddCircleIcon,
  AddCircleOutline: AddCircleOutlineIcon,
  AddComment: AddCommentIcon,
  AddIcCall: AddIcCallIcon,
  AddLink: AddLinkIcon,
  AddLocation: AddLocationIcon,
  AddLocationAlt: AddLocationAltIcon,
  AddModerator: AddModeratorIcon,
  AddPhotoAlternate: AddPhotoAlternateIcon,
  AddReaction: AddReactionIcon,
  AddRoad: AddRoadIcon,
  AddShoppingCart: AddShoppingCartIcon,
  AddTask: AddTaskIcon,
  AddToDrive: AddToDriveIcon,
  AddToHomeScreen: AddToHomeScreenIcon,
  AddToPhotos: AddToPhotosIcon,
  AddToQueue: AddToQueueIcon,
  AdfScanner: AdfScannerIcon,
  Adjust: AdjustIcon,
  AdminPanelSettings: AdminPanelSettingsIcon,
  AdUnits: AdUnitsIcon,
  Agriculture: AgricultureIcon,
  Air: AirIcon,
  Airlines: AirlinesIcon,
  AirlineSeatFlat: AirlineSeatFlatIcon,
  AirlineSeatFlatAngled: AirlineSeatFlatAngledIcon,
  AirlineSeatIndividualSuite: AirlineSeatIndividualSuiteIcon,
  AirlineSeatLegroomExtra: AirlineSeatLegroomExtraIcon,
  AirlineSeatLegroomNormal: AirlineSeatLegroomNormalIcon,
  AirlineSeatLegroomReduced: AirlineSeatLegroomReducedIcon,
  AirlineSeatReclineExtra: AirlineSeatReclineExtraIcon,
  AirlineSeatReclineNormal: AirlineSeatReclineNormalIcon,
  AirlineStops: AirlineStopsIcon,
  AirplanemodeActive: AirplanemodeActiveIcon,
  AirplanemodeInactive: AirplanemodeInactiveIcon,
  AirplaneTicket: AirplaneTicketIcon,
  Airplay: AirplayIcon,
  AirportShuttle: AirportShuttleIcon,
  Alarm: AlarmIcon,
  AlarmAdd: AlarmAddIcon,
  AlarmOff: AlarmOffIcon,
  AlarmOn: AlarmOnIcon,
  Album: AlbumIcon,
  AlignHorizontalCenter: AlignHorizontalCenterIcon,
  AlignHorizontalLeft: AlignHorizontalLeftIcon,
  AlignHorizontalRight: AlignHorizontalRightIcon,
  AlignVerticalBottom: AlignVerticalBottomIcon,
  AlignVerticalCenter: AlignVerticalCenterIcon,
  AlignVerticalTop: AlignVerticalTopIcon,
  AllInbox: AllInboxIcon,
  AllInclusive: AllInclusiveIcon,
  AllOut: AllOutIcon,
  AlternateEmail: AlternateEmailIcon,
  AltRoute: AltRouteIcon,
  Analytics: AnalyticsIcon,
  Anchor: AnchorIcon,
  Android: AndroidIcon,
  Animation: AnimationIcon,
  Announcement: AnnouncementIcon,
  Aod: AodIcon,
  Apartment: ApartmentIcon,
  Api: ApiIcon,
  AppBlocking: AppBlockingIcon,
  Apple: AppleIcon,
  AppRegistration: AppRegistrationIcon,
  Approval: ApprovalIcon,
  Apps: AppsIcon,
  AppSettingsAlt: AppSettingsAltIcon,
  AppShortcut: AppShortcutIcon,
  AppsOutage: AppsOutageIcon,
  Architecture: ArchitectureIcon,
  Archive: ArchiveIcon,
  ArrowBack: ArrowBackIcon,
  ArrowBackIos: ArrowBackIosIcon,
  ArrowBackIosNew: ArrowBackIosNewIcon,
  ArrowCircleDown: ArrowCircleDownIcon,
  ArrowCircleLeft: ArrowCircleLeftIcon,
  ArrowCircleRight: ArrowCircleRightIcon,
  ArrowCircleUp: ArrowCircleUpIcon,
  ArrowDownward: ArrowDownwardIcon,
  ArrowDropDown: ArrowDropDownIcon,
  ArrowDropDownCircle: ArrowDropDownCircleIcon,
  ArrowDropUp: ArrowDropUpIcon,
  ArrowForward: ArrowForwardIcon,
  ArrowForwardIos: ArrowForwardIosIcon,
  ArrowLeft: ArrowLeftIcon,
  ArrowRight: ArrowRightIcon,
  ArrowRightAlt: ArrowRightAltIcon,
  ArrowUpward: ArrowUpwardIcon,
  Article: ArticleIcon,
  ArtTrack: ArtTrackIcon,
  AspectRatio: AspectRatioIcon,
  Assessment: AssessmentIcon,
  Assignment: AssignmentIcon,
  AssignmentInd: AssignmentIndIcon,
  AssignmentLate: AssignmentLateIcon,
  AssignmentReturn: AssignmentReturnIcon,
  AssignmentReturned: AssignmentReturnedIcon,
  AssignmentTurnedIn: AssignmentTurnedInIcon,
  Assistant: AssistantIcon,
  AssistantDirection: AssistantDirectionIcon,
  AssistantPhoto: AssistantPhotoIcon,
  AssuredWorkload: AssuredWorkloadIcon,
  Atm: AtmIcon,
  AttachEmail: AttachEmailIcon,
  AttachFile: AttachFileIcon,
  Attachment: AttachmentIcon,
  AttachMoney: AttachMoneyIcon,
  Attractions: AttractionsIcon,
  Attribution: AttributionIcon,
  AudioFile: AudioFileIcon,
  Audiotrack: AudiotrackIcon,
  AutoAwesome: AutoAwesomeIcon,
  AutoAwesomeMosaic: AutoAwesomeMosaicIcon,
  AutoAwesomeMotion: AutoAwesomeMotionIcon,
  AutoDelete: AutoDeleteIcon,
  AutoFixHigh: AutoFixHighIcon,
  AutoFixNormal: AutoFixNormalIcon,
  AutoFixOff: AutoFixOffIcon,
  AutofpsSelect: AutofpsSelectIcon,
  AutoGraph: AutoGraphIcon,
  AutoMode: AutoModeIcon,
  Autorenew: AutorenewIcon,
  AutoStories: AutoStoriesIcon,
  AvTimer: AvTimerIcon,
  BabyChangingStation: BabyChangingStationIcon,
  Backpack: BackpackIcon,
  Backspace: BackspaceIcon,
  Backup: BackupIcon,
  BackupTable: BackupTableIcon,
  Badge: BadgeIcon,
  BakeryDining: BakeryDiningIcon,
  Balance: BalanceIcon,
  Balcony: BalconyIcon,
  Ballot: BallotIcon,
  BarChart: BarChartIcon,
  BatchPrediction: BatchPredictionIcon,
  Bathroom: BathroomIcon,
  Bathtub: BathtubIcon,
  Battery0Bar: Battery0BarIcon,
  Battery1Bar: Battery1BarIcon,
  Battery20: Battery20Icon,
  Battery2Bar: Battery2BarIcon,
  Battery30: Battery30Icon,
  Battery3Bar: Battery3BarIcon,
  Battery4Bar: Battery4BarIcon,
  Battery50: Battery50Icon,
  Battery5Bar: Battery5BarIcon,
  Battery60: Battery60Icon,
  Battery6Bar: Battery6BarIcon,
  Battery80: Battery80Icon,
  Battery90: Battery90Icon,
  BatteryAlert: BatteryAlertIcon,
  BatteryCharging20: BatteryCharging20Icon,
  BatteryCharging30: BatteryCharging30Icon,
  BatteryCharging50: BatteryCharging50Icon,
  BatteryCharging60: BatteryCharging60Icon,
  BatteryCharging80: BatteryCharging80Icon,
  BatteryCharging90: BatteryCharging90Icon,
  BatteryChargingFull: BatteryChargingFullIcon,
  BatteryFull: BatteryFullIcon,
  BatterySaver: BatterySaverIcon,
  BatteryStd: BatteryStdIcon,
  BatteryUnknown: BatteryUnknownIcon,
  BeachAccess: BeachAccessIcon,
  Bed: BedIcon,
  BedroomBaby: BedroomBabyIcon,
  BedroomChild: BedroomChildIcon,
  BedroomParent: BedroomParentIcon,
  Bedtime: BedtimeIcon,
  BedtimeOff: BedtimeOffIcon,
  Beenhere: BeenhereIcon,
  Bento: BentoIcon,
  BikeScooter: BikeScooterIcon,
  Biotech: BiotechIcon,
  Blender: BlenderIcon,
  BlindsClosed: BlindsClosedIcon,
  Block: BlockIcon,
  Bloodtype: BloodtypeIcon,
  Bluetooth: BluetoothIcon,
  BluetoothAudio: BluetoothAudioIcon,
  BluetoothConnected: BluetoothConnectedIcon,
  BluetoothDisabled: BluetoothDisabledIcon,
  BluetoothDrive: BluetoothDriveIcon,
  BluetoothSearching: BluetoothSearchingIcon,
  BlurCircular: BlurCircularIcon,
  BlurLinear: BlurLinearIcon,
  BlurOff: BlurOffIcon,
  BlurOn: BlurOnIcon,
  Bolt: BoltIcon,
  Book: BookIcon,
  Bookmark: BookmarkIcon,
  BookmarkAdd: BookmarkAddIcon,
  BookmarkAdded: BookmarkAddedIcon,
  BookmarkBorder: BookmarkBorderIcon,
  BookmarkRemove: BookmarkRemoveIcon,
  Bookmarks: BookmarksIcon,
  BookOnline: BookOnlineIcon,
  BorderAll: BorderAllIcon,
  BorderBottom: BorderBottomIcon,
  BorderClear: BorderClearIcon,
  BorderColor: BorderColorIcon,
  BorderHorizontal: BorderHorizontalIcon,
  BorderInner: BorderInnerIcon,
  BorderLeft: BorderLeftIcon,
  BorderOuter: BorderOuterIcon,
  BorderRight: BorderRightIcon,
  BorderStyle: BorderStyleIcon,
  BorderTop: BorderTopIcon,
  BorderVertical: BorderVerticalIcon,
  Boy: BoyIcon,
  BrandingWatermark: BrandingWatermarkIcon,
  BreakfastDining: BreakfastDiningIcon,
  Brightness1: Brightness1Icon,
  Brightness2: Brightness2Icon,
  Brightness3: Brightness3Icon,
  Brightness4: Brightness4Icon,
  Brightness5: Brightness5Icon,
  Brightness6: Brightness6Icon,
  Brightness7: Brightness7Icon,
  BrightnessAuto: BrightnessAutoIcon,
  BrightnessHigh: BrightnessHighIcon,
  BrightnessLow: BrightnessLowIcon,
  BrightnessMedium: BrightnessMediumIcon,
  BrokenImage: BrokenImageIcon,
  BrowseGallery: BrowseGalleryIcon,
  BrowserNotSupported: BrowserNotSupportedIcon,
  BrowserUpdated: BrowserUpdatedIcon,
  BrunchDining: BrunchDiningIcon,
  Brush: BrushIcon,
  BubbleChart: BubbleChartIcon,
  BugReport: BugReportIcon,
  Build: BuildIcon,
  BuildCircle: BuildCircleIcon,
  Bungalow: BungalowIcon,
  BurstMode: BurstModeIcon,
  BusAlert: BusAlertIcon,
  Business: BusinessIcon,
  BusinessCenter: BusinessCenterIcon,
  Cabin: CabinIcon,
  Cable: CableIcon,
  Cached: CachedIcon,
  Cake: CakeIcon,
  Calculate: CalculateIcon,
  CalendarMonth: CalendarMonthIcon,
  CalendarToday: CalendarTodayIcon,
  CalendarViewDay: CalendarViewDayIcon,
  CalendarViewMonth: CalendarViewMonthIcon,
  CalendarViewWeek: CalendarViewWeekIcon,
  Call: CallIcon,
  CallEnd: CallEndIcon,
  CallMade: CallMadeIcon,
  CallMerge: CallMergeIcon,
  CallMissed: CallMissedIcon,
  CallMissedOutgoing: CallMissedOutgoingIcon,
  CallReceived: CallReceivedIcon,
  CallSplit: CallSplitIcon,
  CallToAction: CallToActionIcon,
  Camera: CameraIcon,
  CameraAlt: CameraAltIcon,
  CameraEnhance: CameraEnhanceIcon,
  CameraFront: CameraFrontIcon,
  CameraIndoor: CameraIndoorIcon,
  CameraOutdoor: CameraOutdoorIcon,
  CameraRear: CameraRearIcon,
  CameraRoll: CameraRollIcon,
  Cameraswitch: CameraswitchIcon,
  Campaign: CampaignIcon,
  Cancel: CancelIcon,
  CancelPresentation: CancelPresentationIcon,
  CancelScheduleSend: CancelScheduleSendIcon,
  CandlestickChart: CandlestickChartIcon,
  CarCrash: CarCrashIcon,
  CardGiftcard: CardGiftcardIcon,
  CardMembership: CardMembershipIcon,
  CardTravel: CardTravelIcon,
  Carpenter: CarpenterIcon,
  CarRental: CarRentalIcon,
  CarRepair: CarRepairIcon,
  Cases: CasesIcon,
  Casino: CasinoIcon,
  Cast: CastIcon,
  CastConnected: CastConnectedIcon,
  CastForEducation: CastForEducationIcon,
  Castle: CastleIcon,
  CatchingPokemon: CatchingPokemonIcon,
  Category: CategoryIcon,
  Celebration: CelebrationIcon,
  CellTower: CellTowerIcon,
  CellWifi: CellWifiIcon,
  CenterFocusStrong: CenterFocusStrongIcon,
  CenterFocusWeak: CenterFocusWeakIcon,
  Chair: ChairIcon,
  ChairAlt: ChairAltIcon,
  Chalet: ChaletIcon,
  ChangeCircle: ChangeCircleIcon,
  ChangeHistory: ChangeHistoryIcon,
  ChargingStation: ChargingStationIcon,
  Chat: ChatIcon,
  ChatBubble: ChatBubbleIcon,
  ChatBubbleOutline: ChatBubbleOutlineIcon,
  Check: CheckIcon,
  CheckBox: CheckBoxIcon,
  CheckBoxOutlineBlank: CheckBoxOutlineBlankIcon,
  CheckCircle: CheckCircleIcon,
  CheckCircleOutline: CheckCircleOutlineIcon,
  Checkroom: CheckroomIcon,
  ChevronLeft: ChevronLeftIcon,
  ChevronRight: ChevronRightIcon,
  ChildCare: ChildCareIcon,
  ChildFriendly: ChildFriendlyIcon,
  ChromeReaderMode: ChromeReaderModeIcon,
  Church: ChurchIcon,
  Circle: CircleIcon,
  CircleNotifications: CircleNotificationsIcon,
  Class: ClassIcon,
  CleanHands: CleanHandsIcon,
  CleaningServices: CleaningServicesIcon,
  Clear: ClearIcon,
  ClearAll: ClearAllIcon,
  Close: CloseIcon,
  ClosedCaption: ClosedCaptionIcon,
  ClosedCaptionDisabled: ClosedCaptionDisabledIcon,
  ClosedCaptionOff: ClosedCaptionOffIcon,
  CloseFullscreen: CloseFullscreenIcon,
  Cloud: CloudIcon,
  CloudCircle: CloudCircleIcon,
  CloudDone: CloudDoneIcon,
  CloudDownload: CloudDownloadIcon,
  CloudOff: CloudOffIcon,
  CloudQueue: CloudQueueIcon,
  CloudSync: CloudSyncIcon,
  CloudUpload: CloudUploadIcon,
  Co2: Co2Icon,
  Code: CodeIcon,
  CodeOff: CodeOffIcon,
  Coffee: CoffeeIcon,
  CoffeeMaker: CoffeeMakerIcon,
  Collections: CollectionsIcon,
  CollectionsBookmark: CollectionsBookmarkIcon,
  Colorize: ColorizeIcon,
  ColorLens: ColorLensIcon,
  Comment: CommentIcon,
  CommentBank: CommentBankIcon,
  CommentsDisabled: CommentsDisabledIcon,
  Commit: CommitIcon,
  Commute: CommuteIcon,
  Compare: CompareIcon,
  CompareArrows: CompareArrowsIcon,
  CompassCalibration: CompassCalibrationIcon,
  Compress: CompressIcon,
  Computer: ComputerIcon,
  ConfirmationNumber: ConfirmationNumberIcon,
  ConnectedTv: ConnectedTvIcon,
  ConnectingAirports: ConnectingAirportsIcon,
  ConnectWithoutContact: ConnectWithoutContactIcon,
  Construction: ConstructionIcon,
  Contactless: ContactlessIcon,
  ContactMail: ContactMailIcon,
  ContactPage: ContactPageIcon,
  ContactPhone: ContactPhoneIcon,
  Contacts: ContactsIcon,
  ContactSupport: ContactSupportIcon,
  ContentCopy: ContentCopyIcon,
  ContentCut: ContentCutIcon,
  ContentPaste: ContentPasteIcon,
  ContentPasteGo: ContentPasteGoIcon,
  ContentPasteOff: ContentPasteOffIcon,
  ContentPasteSearch: ContentPasteSearchIcon,
  Contrast: ContrastIcon,
  ControlCamera: ControlCameraIcon,
  ControlPoint: ControlPointIcon,
  ControlPointDuplicate: ControlPointDuplicateIcon,
  Cookie: CookieIcon,
  CoPresent: CoPresentIcon,
  CopyAll: CopyAllIcon,
  Copyright: CopyrightIcon,
  Coronavirus: CoronavirusIcon,
  CorporateFare: CorporateFareIcon,
  Cottage: CottageIcon,
  Countertops: CountertopsIcon,
  Create: CreateIcon,
  CreateNewFolder: CreateNewFolderIcon,
  CreditCard: CreditCardIcon,
  CreditCardOff: CreditCardOffIcon,
  CreditScore: CreditScoreIcon,
  Crib: CribIcon,
  CrisisAlert: CrisisAlertIcon,
  Crop: CropIcon,
  Crop169: Crop169Icon,
  Crop32: Crop32Icon,
  Crop54: Crop54Icon,
  Crop75: Crop75Icon,
  CropDin: CropDinIcon,
  CropFree: CropFreeIcon,
  CropLandscape: CropLandscapeIcon,
  CropOriginal: CropOriginalIcon,
  CropPortrait: CropPortraitIcon,
  CropRotate: CropRotateIcon,
  CropSquare: CropSquareIcon,
  Css: CssIcon,
  CurrencyBitcoin: CurrencyBitcoinIcon,
  CurrencyExchange: CurrencyExchangeIcon,
  CurrencyFranc: CurrencyFrancIcon,
  CurrencyLira: CurrencyLiraIcon,
  CurrencyPound: CurrencyPoundIcon,
  CurrencyRuble: CurrencyRubleIcon,
  CurrencyRupee: CurrencyRupeeIcon,
  CurrencyYen: CurrencyYenIcon,
  CurrencyYuan: CurrencyYuanIcon,
  Curtains: CurtainsIcon,
  CurtainsClosed: CurtainsClosedIcon,
  Cyclone: CycloneIcon,
  Dangerous: DangerousIcon,
  DarkMode: DarkModeIcon,
  Dashboard: DashboardIcon,
  DashboardCustomize: DashboardCustomizeIcon,
  DataArray: DataArrayIcon,
  DataObject: DataObjectIcon,
  DataSaverOff: DataSaverOffIcon,
  DataSaverOn: DataSaverOnIcon,
  DataThresholding: DataThresholdingIcon,
  DataUsage: DataUsageIcon,
  DateRange: DateRangeIcon,
  Deblur: DeblurIcon,
  Deck: DeckIcon,
  Dehaze: DehazeIcon,
  Delete: DeleteIcon,
  DeleteForever: DeleteForeverIcon,
  DeleteOutline: DeleteOutlineIcon,
  DeleteSweep: DeleteSweepIcon,
  DeliveryDining: DeliveryDiningIcon,
  DensityLarge: DensityLargeIcon,
  DensityMedium: DensityMediumIcon,
  DensitySmall: DensitySmallIcon,
  DepartureBoard: DepartureBoardIcon,
  Description: DescriptionIcon,
  Deselect: DeselectIcon,
  DesignServices: DesignServicesIcon,
  DesktopAccessDisabled: DesktopAccessDisabledIcon,
  DesktopMac: DesktopMacIcon,
  DesktopWindows: DesktopWindowsIcon,
  Details: DetailsIcon,
  DeveloperBoard: DeveloperBoardIcon,
  DeveloperBoardOff: DeveloperBoardOffIcon,
  DeveloperMode: DeveloperModeIcon,
  DeviceHub: DeviceHubIcon,
  Devices: DevicesIcon,
  DevicesFold: DevicesFoldIcon,
  DevicesOther: DevicesOtherIcon,
  DeviceThermostat: DeviceThermostatIcon,
  DeviceUnknown: DeviceUnknownIcon,
  DialerSip: DialerSipIcon,
  Dialpad: DialpadIcon,
  Diamond: DiamondIcon,
  Difference: DifferenceIcon,
  Dining: DiningIcon,
  DinnerDining: DinnerDiningIcon,
  Directions: DirectionsIcon,
  DirectionsBike: DirectionsBikeIcon,
  DirectionsBoat: DirectionsBoatIcon,
  DirectionsBoatFilled: DirectionsBoatFilledIcon,
  DirectionsBus: DirectionsBusIcon,
  DirectionsBusFilled: DirectionsBusFilledIcon,
  DirectionsCar: DirectionsCarIcon,
  DirectionsCarFilled: DirectionsCarFilledIcon,
  DirectionsOff: DirectionsOffIcon,
  DirectionsRailway: DirectionsRailwayIcon,
  DirectionsRailwayFilled: DirectionsRailwayFilledIcon,
  DirectionsRun: DirectionsRunIcon,
  DirectionsSubway: DirectionsSubwayIcon,
  DirectionsSubwayFilled: DirectionsSubwayFilledIcon,
  DirectionsTransit: DirectionsTransitIcon,
  DirectionsTransitFilled: DirectionsTransitFilledIcon,
  DirectionsWalk: DirectionsWalkIcon,
  DirtyLens: DirtyLensIcon,
  DisabledByDefault: DisabledByDefaultIcon,
  DiscFull: DiscFullIcon,
  Discount: DiscountIcon,
  DisplaySettings: DisplaySettingsIcon,
  Dns: DnsIcon,
  Dock: DockIcon,
  DocumentScanner: DocumentScannerIcon,
  DoDisturb: DoDisturbIcon,
  DoDisturbAlt: DoDisturbAltIcon,
  DoDisturbOff: DoDisturbOffIcon,
  DoDisturbOn: DoDisturbOnIcon,
  Domain: DomainIcon,
  DomainAdd: DomainAddIcon,
  DomainDisabled: DomainDisabledIcon,
  DomainVerification: DomainVerificationIcon,
  Done: DoneIcon,
  DoneAll: DoneAllIcon,
  DoneOutline: DoneOutlineIcon,
  DoNotDisturb: DoNotDisturbIcon,
  DoNotDisturbAlt: DoNotDisturbAltIcon,
  DoNotDisturbOff: DoNotDisturbOffIcon,
  DoNotDisturbOn: DoNotDisturbOnIcon,
  DoNotDisturbOnTotalSilence: DoNotDisturbOnTotalSilenceIcon,
  DoNotStep: DoNotStepIcon,
  DoNotTouch: DoNotTouchIcon,
  DonutLarge: DonutLargeIcon,
  DonutSmall: DonutSmallIcon,
  DoorBack: DoorBackIcon,
  Doorbell: DoorbellIcon,
  DoorFront: DoorFrontIcon,
  DoorSliding: DoorSlidingIcon,
  DoubleArrow: DoubleArrowIcon,
  DownhillSkiing: DownhillSkiingIcon,
  Download: DownloadIcon,
  DownloadDone: DownloadDoneIcon,
  DownloadForOffline: DownloadForOfflineIcon,
  Downloading: DownloadingIcon,
  Drafts: DraftsIcon,
  DragHandle: DragHandleIcon,
  DragIndicator: DragIndicatorIcon,
  DriveEta: DriveEtaIcon,
  DriveFileMove: DriveFileMoveIcon,
  DriveFileRenameOutline: DriveFileRenameOutlineIcon,
  DriveFolderUpload: DriveFolderUploadIcon,
  Dry: DryIcon,
  DryCleaning: DryCleaningIcon,
  Duo: DuoIcon,
  Dvr: DvrIcon,
  DynamicFeed: DynamicFeedIcon,
  DynamicForm: DynamicFormIcon,
  Earbuds: EarbudsIcon,
  EarbudsBattery: EarbudsBatteryIcon,
  East: EastIcon,
  EdgesensorHigh: EdgesensorHighIcon,
  EdgesensorLow: EdgesensorLowIcon,
  Edit: EditIcon,
  EditAttributes: EditAttributesIcon,
  EditLocation: EditLocationIcon,
  EditLocationAlt: EditLocationAltIcon,
  EditNotifications: EditNotificationsIcon,
  EditOff: EditOffIcon,
  EditRoad: EditRoadIcon,
  Egg: EggIcon,
  EggAlt: EggAltIcon,
  EighteenMp: EighteenMpIcon,
  EightK: EightKIcon,
  EightKPlus: EightKPlusIcon,
  EightMp: EightMpIcon,
  EightteenMp: EightteenMpIcon,
  Eject: EjectIcon,
  Elderly: ElderlyIcon,
  ElderlyWoman: ElderlyWomanIcon,
  ElectricalServices: ElectricalServicesIcon,
  ElectricBike: ElectricBikeIcon,
  ElectricBolt: ElectricBoltIcon,
  ElectricCar: ElectricCarIcon,
  ElectricMeter: ElectricMeterIcon,
  ElectricMoped: ElectricMopedIcon,
  ElectricRickshaw: ElectricRickshawIcon,
  ElectricScooter: ElectricScooterIcon,
  Elevator: ElevatorIcon,
  ElevenMp: ElevenMpIcon,
  Email: EmailIcon,
  EmergencyRecording: EmergencyRecordingIcon,
  EmergencyShare: EmergencyShareIcon,
  EMobiledata: EMobiledataIcon,
  EmojiEmotions: EmojiEmotionsIcon,
  EmojiEvents: EmojiEventsIcon,
  EmojiFlags: EmojiFlagsIcon,
  EmojiFoodBeverage: EmojiFoodBeverageIcon,
  EmojiNature: EmojiNatureIcon,
  EmojiObjects: EmojiObjectsIcon,
  EmojiPeople: EmojiPeopleIcon,
  EmojiSymbols: EmojiSymbolsIcon,
  EmojiTransportation: EmojiTransportationIcon,
  EnergySavingsLeaf: EnergySavingsLeafIcon,
  Engineering: EngineeringIcon,
  EnhancedEncryption: EnhancedEncryptionIcon,
  Equalizer: EqualizerIcon,
  Error: ErrorIcon,
  ErrorOutline: ErrorOutlineIcon,
  Escalator: EscalatorIcon,
  EscalatorWarning: EscalatorWarningIcon,
  Euro: EuroIcon,
  EuroSymbol: EuroSymbolIcon,
  Event: EventIcon,
  EventAvailable: EventAvailableIcon,
  EventBusy: EventBusyIcon,
  EventNote: EventNoteIcon,
  EventRepeat: EventRepeatIcon,
  EventSeat: EventSeatIcon,
  EvStation: EvStationIcon,
  ExitToApp: ExitToAppIcon,
  Expand: ExpandIcon,
  ExpandCircleDown: ExpandCircleDownIcon,
  ExpandLess: ExpandLessIcon,
  ExpandMore: ExpandMoreIcon,
  Explicit: ExplicitIcon,
  Explore: ExploreIcon,
  ExploreOff: ExploreOffIcon,
  Exposure: ExposureIcon,
  Extension: ExtensionIcon,
  ExtensionOff: ExtensionOffIcon,
  Face: FaceIcon,
  Facebook: FacebookIcon,
  FaceRetouchingNatural: FaceRetouchingNaturalIcon,
  FaceRetouchingOff: FaceRetouchingOffIcon,
  FactCheck: FactCheckIcon,
  Factory: FactoryIcon,
  FamilyRestroom: FamilyRestroomIcon,
  Fastfood: FastfoodIcon,
  FastForward: FastForwardIcon,
  FastRewind: FastRewindIcon,
  Favorite: FavoriteIcon,
  FavoriteBorder: FavoriteBorderIcon,
  Fax: FaxIcon,
  FeaturedPlayList: FeaturedPlayListIcon,
  FeaturedVideo: FeaturedVideoIcon,
  Feed: FeedIcon,
  Feedback: FeedbackIcon,
  Female: FemaleIcon,
  Fence: FenceIcon,
  Festival: FestivalIcon,
  FiberDvr: FiberDvrIcon,
  FiberManualRecord: FiberManualRecordIcon,
  FiberNew: FiberNewIcon,
  FiberPin: FiberPinIcon,
  FiberSmartRecord: FiberSmartRecordIcon,
  FifteenMp: FifteenMpIcon,
  FileCopy: FileCopyIcon,
  FileDownload: FileDownloadIcon,
  FileDownloadDone: FileDownloadDoneIcon,
  FileDownloadOff: FileDownloadOffIcon,
  FileOpen: FileOpenIcon,
  FilePresent: FilePresentIcon,
  FileUpload: FileUploadIcon,
  Filter: FilterIcon,
  Filter1: Filter1Icon,
  Filter2: Filter2Icon,
  Filter3: Filter3Icon,
  Filter4: Filter4Icon,
  Filter5: Filter5Icon,
  Filter6: Filter6Icon,
  Filter7: Filter7Icon,
  Filter8: Filter8Icon,
  Filter9: Filter9Icon,
  Filter9Plus: Filter9PlusIcon,
  FilterAlt: FilterAltIcon,
  FilterAltOff: FilterAltOffIcon,
  FilterBAndW: FilterBAndWIcon,
  FilterCenterFocus: FilterCenterFocusIcon,
  FilterDrama: FilterDramaIcon,
  FilterFrames: FilterFramesIcon,
  FilterHdr: FilterHdrIcon,
  FilterList: FilterListIcon,
  FilterListOff: FilterListOffIcon,
  FilterNone: FilterNoneIcon,
  FilterTiltShift: FilterTiltShiftIcon,
  FilterVintage: FilterVintageIcon,
  FindInPage: FindInPageIcon,
  FindReplace: FindReplaceIcon,
  Fingerprint: FingerprintIcon,
  FireExtinguisher: FireExtinguisherIcon,
  Fireplace: FireplaceIcon,
  FirstPage: FirstPageIcon,
  Fitbit: FitbitIcon,
  FitnessCenter: FitnessCenterIcon,
  FitScreen: FitScreenIcon,
  FiveG: FiveGIcon,
  FiveK: FiveKIcon,
  FiveKPlus: FiveKPlusIcon,
  FiveMp: FiveMpIcon,
  FivteenMp: FivteenMpIcon,
  Flag: FlagIcon,
  FlagCircle: FlagCircleIcon,
  Flaky: FlakyIcon,
  Flare: FlareIcon,
  FlashAuto: FlashAutoIcon,
  FlashlightOff: FlashlightOffIcon,
  FlashlightOn: FlashlightOnIcon,
  FlashOff: FlashOffIcon,
  FlashOn: FlashOnIcon,
  Flatware: FlatwareIcon,
  Flight: FlightIcon,
  FlightClass: FlightClassIcon,
  FlightLand: FlightLandIcon,
  FlightTakeoff: FlightTakeoffIcon,
  Flip: FlipIcon,
  FlipCameraAndroid: FlipCameraAndroidIcon,
  FlipCameraIos: FlipCameraIosIcon,
  FlipToBack: FlipToBackIcon,
  FlipToFront: FlipToFrontIcon,
  Flood: FloodIcon,
  Flourescent: FlourescentIcon,
  FlutterDash: FlutterDashIcon,
  FmdBad: FmdBadIcon,
  FmdGood: FmdGoodIcon,
  Folder: FolderIcon,
  FolderCopy: FolderCopyIcon,
  FolderDelete: FolderDeleteIcon,
  FolderOff: FolderOffIcon,
  FolderOpen: FolderOpenIcon,
  FolderShared: FolderSharedIcon,
  FolderSpecial: FolderSpecialIcon,
  FolderZip: FolderZipIcon,
  FollowTheSigns: FollowTheSignsIcon,
  FontDownload: FontDownloadIcon,
  FontDownloadOff: FontDownloadOffIcon,
  FoodBank: FoodBankIcon,
  Forest: ForestIcon,
  ForkLeft: ForkLeftIcon,
  ForkRight: ForkRightIcon,
  FormatAlignCenter: FormatAlignCenterIcon,
  FormatAlignJustify: FormatAlignJustifyIcon,
  FormatAlignLeft: FormatAlignLeftIcon,
  FormatAlignRight: FormatAlignRightIcon,
  FormatBold: FormatBoldIcon,
  FormatClear: FormatClearIcon,
  FormatColorFill: FormatColorFillIcon,
  FormatColorReset: FormatColorResetIcon,
  FormatColorText: FormatColorTextIcon,
  FormatIndentDecrease: FormatIndentDecreaseIcon,
  FormatIndentIncrease: FormatIndentIncreaseIcon,
  FormatItalic: FormatItalicIcon,
  FormatLineSpacing: FormatLineSpacingIcon,
  FormatListBulleted: FormatListBulletedIcon,
  FormatListNumbered: FormatListNumberedIcon,
  FormatListNumberedRtl: FormatListNumberedRtlIcon,
  FormatOverline: FormatOverlineIcon,
  FormatPaint: FormatPaintIcon,
  FormatQuote: FormatQuoteIcon,
  FormatShapes: FormatShapesIcon,
  FormatSize: FormatSizeIcon,
  FormatStrikethrough: FormatStrikethroughIcon,
  FormatTextdirectionLToR: FormatTextdirectionLToRIcon,
  FormatTextdirectionRToL: FormatTextdirectionRToLIcon,
  FormatUnderlined: FormatUnderlinedIcon,
  Fort: FortIcon,
  Forum: ForumIcon,
  Forward: ForwardIcon,
  Forward10: Forward10Icon,
  Forward30: Forward30Icon,
  Forward5: Forward5Icon,
  ForwardToInbox: ForwardToInboxIcon,
  Foundation: FoundationIcon,
  FourGMobiledata: FourGMobiledataIcon,
  FourGPlusMobiledata: FourGPlusMobiledataIcon,
  FourK: FourKIcon,
  FourKPlus: FourKPlusIcon,
  FourMp: FourMpIcon,
  FourteenMp: FourteenMpIcon,
  FreeBreakfast: FreeBreakfastIcon,
  Fullscreen: FullscreenIcon,
  FullscreenExit: FullscreenExitIcon,
  Functions: FunctionsIcon,
  Gamepad: GamepadIcon,
  Games: GamesIcon,
  Garage: GarageIcon,
  GasMeter: GasMeterIcon,
  Gavel: GavelIcon,
  Gesture: GestureIcon,
  GetApp: GetAppIcon,
  Gif: GifIcon,
  GifBox: GifBoxIcon,
  Girl: GirlIcon,
  Gite: GiteIcon,
  GitHub: GitHubIcon,
  GMobiledata: GMobiledataIcon,
  GolfCourse: GolfCourseIcon,
  Google: GoogleIcon,
  GppBad: GppBadIcon,
  GppGood: GppGoodIcon,
  GppMaybe: GppMaybeIcon,
  GpsFixed: GpsFixedIcon,
  GpsNotFixed: GpsNotFixedIcon,
  GpsOff: GpsOffIcon,
  Grade: GradeIcon,
  Gradient: GradientIcon,
  Grading: GradingIcon,
  Grain: GrainIcon,
  GraphicEq: GraphicEqIcon,
  Grass: GrassIcon,
  Grid3x3: Grid3x3Icon,
  Grid4x4: Grid4x4Icon,
  GridGoldenratio: GridGoldenratioIcon,
  GridOff: GridOffIcon,
  GridOn: GridOnIcon,
  GridView: GridViewIcon,
  Group: GroupIcon,
  GroupAdd: GroupAddIcon,
  GroupRemove: GroupRemoveIcon,
  Groups: GroupsIcon,
  GroupWork: GroupWorkIcon,
  GTranslate: GTranslateIcon,
  Hail: HailIcon,
  Handshake: HandshakeIcon,
  Handyman: HandymanIcon,
  Hardware: HardwareIcon,
  Hd: HdIcon,
  HdrAuto: HdrAutoIcon,
  HdrAutoSelect: HdrAutoSelectIcon,
  HdrEnhancedSelect: HdrEnhancedSelectIcon,
  HdrOff: HdrOffIcon,
  HdrOffSelect: HdrOffSelectIcon,
  HdrOn: HdrOnIcon,
  HdrOnSelect: HdrOnSelectIcon,
  HdrPlus: HdrPlusIcon,
  HdrStrong: HdrStrongIcon,
  HdrWeak: HdrWeakIcon,
  Headphones: HeadphonesIcon,
  HeadphonesBattery: HeadphonesBatteryIcon,
  Headset: HeadsetIcon,
  HeadsetMic: HeadsetMicIcon,
  HeadsetOff: HeadsetOffIcon,
  Healing: HealingIcon,
  HealthAndSafety: HealthAndSafetyIcon,
  Hearing: HearingIcon,
  HearingDisabled: HearingDisabledIcon,
  HeartBroken: HeartBrokenIcon,
  HeatPump: HeatPumpIcon,
  Height: HeightIcon,
  Help: HelpIcon,
  HelpCenter: HelpCenterIcon,
  HelpOutline: HelpOutlineIcon,
  Hevc: HevcIcon,
  Hexagon: HexagonIcon,
  HideImage: HideImageIcon,
  HideSource: HideSourceIcon,
  Highlight: HighlightIcon,
  HighlightAlt: HighlightAltIcon,
  HighlightOff: HighlightOffIcon,
  HighQuality: HighQualityIcon,
  Hiking: HikingIcon,
  History: HistoryIcon,
  HistoryEdu: HistoryEduIcon,
  HistoryToggleOff: HistoryToggleOffIcon,
  Hive: HiveIcon,
  Hls: HlsIcon,
  HlsOff: HlsOffIcon,
  HMobiledata: HMobiledataIcon,
  HolidayVillage: HolidayVillageIcon,
  Home: HomeIcon,
  HomeMax: HomeMaxIcon,
  HomeMini: HomeMiniIcon,
  HomeRepairService: HomeRepairServiceIcon,
  HomeWork: HomeWorkIcon,
  HorizontalRule: HorizontalRuleIcon,
  HorizontalSplit: HorizontalSplitIcon,
  Hotel: HotelIcon,
  HotTub: HotTubIcon,
  HourglassBottom: HourglassBottomIcon,
  HourglassDisabled: HourglassDisabledIcon,
  HourglassEmpty: HourglassEmptyIcon,
  HourglassFull: HourglassFullIcon,
  HourglassTop: HourglassTopIcon,
  House: HouseIcon,
  Houseboat: HouseboatIcon,
  HouseSiding: HouseSidingIcon,
  HowToReg: HowToRegIcon,
  HowToVote: HowToVoteIcon,
  HPlusMobiledata: HPlusMobiledataIcon,
  Html: HtmlIcon,
  Http: HttpIcon,
  Https: HttpsIcon,
  Hub: HubIcon,
  Hvac: HvacIcon,
  Icecream: IcecreamIcon,
  IceSkating: IceSkatingIcon,
  Image: ImageIcon,
  ImageAspectRatio: ImageAspectRatioIcon,
  ImageNotSupported: ImageNotSupportedIcon,
  ImageSearch: ImageSearchIcon,
  ImagesearchRoller: ImagesearchRollerIcon,
  ImportantDevices: ImportantDevicesIcon,
  ImportContacts: ImportContactsIcon,
  ImportExport: ImportExportIcon,
  Inbox: InboxIcon,
  IndeterminateCheckBox: IndeterminateCheckBoxIcon,
  Info: InfoIcon,
  Input: InputIcon,
  InsertChart: InsertChartIcon,
  InsertComment: InsertCommentIcon,
  InsertDriveFile: InsertDriveFileIcon,
  InsertEmoticon: InsertEmoticonIcon,
  InsertInvitation: InsertInvitationIcon,
  InsertLink: InsertLinkIcon,
  InsertPageBreak: InsertPageBreakIcon,
  InsertPhoto: InsertPhotoIcon,
  Insights: InsightsIcon,
  Instagram: InstagramIcon,
  InstallDesktop: InstallDesktopIcon,
  InstallMobile: InstallMobileIcon,
  IntegrationInstructions: IntegrationInstructionsIcon,
  Interests: InterestsIcon,
  InterpreterMode: InterpreterModeIcon,
  Inventory: InventoryIcon,
  Inventory2: Inventory2Icon,
  InvertColors: InvertColorsIcon,
  InvertColorsOff: InvertColorsOffIcon,
  IosShare: IosShareIcon,
  Iron: IronIcon,
  Iso: IsoIcon,
  Javascript: JavascriptIcon,
  JoinFull: JoinFullIcon,
  JoinInner: JoinInnerIcon,
  JoinLeft: JoinLeftIcon,
  JoinRight: JoinRightIcon,
  Kayaking: KayakingIcon,
  KebabDining: KebabDiningIcon,
  Key: KeyIcon,
  Keyboard: KeyboardIcon,
  KeyboardAlt: KeyboardAltIcon,
  KeyboardArrowDown: KeyboardArrowDownIcon,
  KeyboardArrowLeft: KeyboardArrowLeftIcon,
  KeyboardArrowRight: KeyboardArrowRightIcon,
  KeyboardArrowUp: KeyboardArrowUpIcon,
  KeyboardBackspace: KeyboardBackspaceIcon,
  KeyboardCapslock: KeyboardCapslockIcon,
  KeyboardCommandKey: KeyboardCommandKeyIcon,
  KeyboardControlKey: KeyboardControlKeyIcon,
  KeyboardDoubleArrowDown: KeyboardDoubleArrowDownIcon,
  KeyboardDoubleArrowLeft: KeyboardDoubleArrowLeftIcon,
  KeyboardDoubleArrowRight: KeyboardDoubleArrowRightIcon,
  KeyboardDoubleArrowUp: KeyboardDoubleArrowUpIcon,
  KeyboardHide: KeyboardHideIcon,
  KeyboardOptionKey: KeyboardOptionKeyIcon,
  KeyboardReturn: KeyboardReturnIcon,
  KeyboardTab: KeyboardTabIcon,
  KeyboardVoice: KeyboardVoiceIcon,
  KeyOff: KeyOffIcon,
  KingBed: KingBedIcon,
  Kitchen: KitchenIcon,
  Kitesurfing: KitesurfingIcon,
  Label: LabelIcon,
  LabelImportant: LabelImportantIcon,
  LabelOff: LabelOffIcon,
  Lan: LanIcon,
  Landscape: LandscapeIcon,
  Landslide: LandslideIcon,
  Language: LanguageIcon,
  Laptop: LaptopIcon,
  LaptopChromebook: LaptopChromebookIcon,
  LaptopMac: LaptopMacIcon,
  LaptopWindows: LaptopWindowsIcon,
  LastPage: LastPageIcon,
  Launch: LaunchIcon,
  Layers: LayersIcon,
  LayersClear: LayersClearIcon,
  Leaderboard: LeaderboardIcon,
  LeakAdd: LeakAddIcon,
  LeakRemove: LeakRemoveIcon,
  LegendToggle: LegendToggleIcon,
  Lens: LensIcon,
  LensBlur: LensBlurIcon,
  LibraryAdd: LibraryAddIcon,
  LibraryAddCheck: LibraryAddCheckIcon,
  LibraryBooks: LibraryBooksIcon,
  LibraryMusic: LibraryMusicIcon,
  Light: LightIcon,
  Lightbulb: LightbulbIcon,
  LightbulbCircle: LightbulbCircleIcon,
  LightMode: LightModeIcon,
  LinearScale: LinearScaleIcon,
  LineAxis: LineAxisIcon,
  LineStyle: LineStyleIcon,
  LineWeight: LineWeightIcon,
  Link: LinkIcon,
  LinkedCamera: LinkedCameraIcon,
  LinkedIn: LinkedInIcon,
  LinkOff: LinkOffIcon,
  Liquor: LiquorIcon,
  List: ListIcon,
  ListAlt: ListAltIcon,
  LiveHelp: LiveHelpIcon,
  LiveTv: LiveTvIcon,
  Living: LivingIcon,
  LocalActivity: LocalActivityIcon,
  LocalAirport: LocalAirportIcon,
  LocalAtm: LocalAtmIcon,
  LocalBar: LocalBarIcon,
  LocalCafe: LocalCafeIcon,
  LocalCarWash: LocalCarWashIcon,
  LocalConvenienceStore: LocalConvenienceStoreIcon,
  LocalDining: LocalDiningIcon,
  LocalDrink: LocalDrinkIcon,
  LocalFireDepartment: LocalFireDepartmentIcon,
  LocalFlorist: LocalFloristIcon,
  LocalGasStation: LocalGasStationIcon,
  LocalGroceryStore: LocalGroceryStoreIcon,
  LocalHospital: LocalHospitalIcon,
  LocalHotel: LocalHotelIcon,
  LocalLaundryService: LocalLaundryServiceIcon,
  LocalLibrary: LocalLibraryIcon,
  LocalMall: LocalMallIcon,
  LocalMovies: LocalMoviesIcon,
  LocalOffer: LocalOfferIcon,
  LocalParking: LocalParkingIcon,
  LocalPharmacy: LocalPharmacyIcon,
  LocalPhone: LocalPhoneIcon,
  LocalPizza: LocalPizzaIcon,
  LocalPlay: LocalPlayIcon,
  LocalPolice: LocalPoliceIcon,
  LocalPostOffice: LocalPostOfficeIcon,
  LocalPrintshop: LocalPrintshopIcon,
  LocalSee: LocalSeeIcon,
  LocalShipping: LocalShippingIcon,
  LocalTaxi: LocalTaxiIcon,
  LocationCity: LocationCityIcon,
  LocationDisabled: LocationDisabledIcon,
  LocationOff: LocationOffIcon,
  LocationOn: LocationOnIcon,
  LocationSearching: LocationSearchingIcon,
  Lock: LockIcon,
  LockClock: LockClockIcon,
  LockOpen: LockOpenIcon,
  LockReset: LockResetIcon,
  Login: LoginIcon,
  LogoDev: LogoDevIcon,
  Logout: LogoutIcon,
  Looks: LooksIcon,
  Looks3: Looks3Icon,
  Looks4: Looks4Icon,
  Looks5: Looks5Icon,
  Looks6: Looks6Icon,
  LooksOne: LooksOneIcon,
  LooksTwo: LooksTwoIcon,
  Loop: LoopIcon,
  Loupe: LoupeIcon,
  LowPriority: LowPriorityIcon,
  Loyalty: LoyaltyIcon,
  LteMobiledata: LteMobiledataIcon,
  LtePlusMobiledata: LtePlusMobiledataIcon,
  Luggage: LuggageIcon,
  LunchDining: LunchDiningIcon,
  Lyrics: LyricsIcon,
  Mail: MailIcon,
  MailLock: MailLockIcon,
  MailOutline: MailOutlineIcon,
  Male: MaleIcon,
  Man: ManIcon,
  ManageAccounts: ManageAccountsIcon,
  ManageHistory: ManageHistoryIcon,
  ManageSearch: ManageSearchIcon,
  Map: MapIcon,
  MapsHomeWork: MapsHomeWorkIcon,
  MapsUgc: MapsUgcIcon,
  Margin: MarginIcon,
  MarkAsUnread: MarkAsUnreadIcon,
  MarkChatRead: MarkChatReadIcon,
  MarkChatUnread: MarkChatUnreadIcon,
  MarkEmailRead: MarkEmailReadIcon,
  MarkEmailUnread: MarkEmailUnreadIcon,
  Markunread: MarkunreadIcon,
  MarkUnreadChatAlt: MarkUnreadChatAltIcon,
  MarkunreadMailbox: MarkunreadMailboxIcon,
  Masks: MasksIcon,
  Maximize: MaximizeIcon,
  MediaBluetoothOff: MediaBluetoothOffIcon,
  MediaBluetoothOn: MediaBluetoothOnIcon,
  Mediation: MediationIcon,
  MedicalInformation: MedicalInformationIcon,
  MedicalServices: MedicalServicesIcon,
  Medication: MedicationIcon,
  MedicationLiquid: MedicationLiquidIcon,
  MeetingRoom: MeetingRoomIcon,
  Memory: MemoryIcon,
  Menu: MenuIcon,
  MenuBook: MenuBookIcon,
  MenuOpen: MenuOpenIcon,
  Merge: MergeIcon,
  MergeType: MergeTypeIcon,
  Message: MessageIcon,
  Mic: MicIcon,
  MicExternalOff: MicExternalOffIcon,
  MicExternalOn: MicExternalOnIcon,
  MicNone: MicNoneIcon,
  MicOff: MicOffIcon,
  Microwave: MicrowaveIcon,
  MilitaryTech: MilitaryTechIcon,
  Minimize: MinimizeIcon,
  MinorCrash: MinorCrashIcon,
  MiscellaneousServices: MiscellaneousServicesIcon,
  MissedVideoCall: MissedVideoCallIcon,
  Mms: MmsIcon,
  MobiledataOff: MobiledataOffIcon,
  MobileFriendly: MobileFriendlyIcon,
  MobileOff: MobileOffIcon,
  MobileScreenShare: MobileScreenShareIcon,
  Mode: ModeIcon,
  ModeComment: ModeCommentIcon,
  ModeEdit: ModeEditIcon,
  ModeEditOutline: ModeEditOutlineIcon,
  ModeFanOff: ModeFanOffIcon,
  ModelTraining: ModelTrainingIcon,
  ModeNight: ModeNightIcon,
  ModeOfTravel: ModeOfTravelIcon,
  ModeStandby: ModeStandbyIcon,
  MonetizationOn: MonetizationOnIcon,
  Money: MoneyIcon,
  MoneyOff: MoneyOffIcon,
  MoneyOffCsred: MoneyOffCsredIcon,
  Monitor: MonitorIcon,
  MonitorHeart: MonitorHeartIcon,
  MonitorWeight: MonitorWeightIcon,
  MonochromePhotos: MonochromePhotosIcon,
  Mood: MoodIcon,
  MoodBad: MoodBadIcon,
  Moped: MopedIcon,
  More: MoreIcon,
  MoreHoriz: MoreHorizIcon,
  MoreTime: MoreTimeIcon,
  MoreVert: MoreVertIcon,
  Mosque: MosqueIcon,
  MotionPhotosAuto: MotionPhotosAutoIcon,
  MotionPhotosOff: MotionPhotosOffIcon,
  Mouse: MouseIcon,
  MoveDown: MoveDownIcon,
  MoveToInbox: MoveToInboxIcon,
  MoveUp: MoveUpIcon,
  Movie: MovieIcon,
  MovieCreation: MovieCreationIcon,
  MovieFilter: MovieFilterIcon,
  Moving: MovingIcon,
  Mp: MpIcon,
  MultilineChart: MultilineChartIcon,
  MultipleStop: MultipleStopIcon,
  Museum: MuseumIcon,
  MusicNote: MusicNoteIcon,
  MusicOff: MusicOffIcon,
  MusicVideo: MusicVideoIcon,
  MyLocation: MyLocationIcon,
  Nat: NatIcon,
  Nature: NatureIcon,
  NaturePeople: NaturePeopleIcon,
  NavigateBefore: NavigateBeforeIcon,
  NavigateNext: NavigateNextIcon,
  Navigation: NavigationIcon,
  NearbyError: NearbyErrorIcon,
  NearbyOff: NearbyOffIcon,
  NearMe: NearMeIcon,
  NearMeDisabled: NearMeDisabledIcon,
  NestCamWiredStand: NestCamWiredStandIcon,
  NetworkCell: NetworkCellIcon,
  NetworkCheck: NetworkCheckIcon,
  NetworkLocked: NetworkLockedIcon,
  NetworkPing: NetworkPingIcon,
  NetworkWifi: NetworkWifiIcon,
  NetworkWifi1Bar: NetworkWifi1BarIcon,
  NetworkWifi2Bar: NetworkWifi2BarIcon,
  NetworkWifi3Bar: NetworkWifi3BarIcon,
  NewReleases: NewReleasesIcon,
  Newspaper: NewspaperIcon,
  NextPlan: NextPlanIcon,
  NextWeek: NextWeekIcon,
  Nfc: NfcIcon,
  Nightlife: NightlifeIcon,
  Nightlight: NightlightIcon,
  NightlightRound: NightlightRoundIcon,
  NightShelter: NightShelterIcon,
  NightsStay: NightsStayIcon,
  NineK: NineKIcon,
  NineKPlus: NineKPlusIcon,
  NineMp: NineMpIcon,
  NineteenMp: NineteenMpIcon,
  NoAccounts: NoAccountsIcon,
  NoBackpack: NoBackpackIcon,
  NoCell: NoCellIcon,
  NoCrash: NoCrashIcon,
  NoDrinks: NoDrinksIcon,
  NoEncryption: NoEncryptionIcon,
  NoEncryptionGmailerrorred: NoEncryptionGmailerrorredIcon,
  NoFlash: NoFlashIcon,
  NoFood: NoFoodIcon,
  NoiseAware: NoiseAwareIcon,
  NoiseControlOff: NoiseControlOffIcon,
  NoLuggage: NoLuggageIcon,
  NoMeals: NoMealsIcon,
  NoMeetingRoom: NoMeetingRoomIcon,
  NoPhotography: NoPhotographyIcon,
  NordicWalking: NordicWalkingIcon,
  North: NorthIcon,
  NorthEast: NorthEastIcon,
  NorthWest: NorthWestIcon,
  NoSim: NoSimIcon,
  NoStroller: NoStrollerIcon,
  NotAccessible: NotAccessibleIcon,
  Note: NoteIcon,
  NoteAdd: NoteAddIcon,
  NoteAlt: NoteAltIcon,
  Notes: NotesIcon,
  NotificationAdd: NotificationAddIcon,
  NotificationImportant: NotificationImportantIcon,
  Notifications: NotificationsIcon,
  NotificationsActive: NotificationsActiveIcon,
  NotificationsNone: NotificationsNoneIcon,
  NotificationsOff: NotificationsOffIcon,
  NotificationsPaused: NotificationsPausedIcon,
  NotInterested: NotInterestedIcon,
  NotListedLocation: NotListedLocationIcon,
  NoTransfer: NoTransferIcon,
  NotStarted: NotStartedIcon,
  Numbers: NumbersIcon,
  OfflineBolt: OfflineBoltIcon,
  OfflinePin: OfflinePinIcon,
  OfflineShare: OfflineShareIcon,
  OilBarrel: OilBarrelIcon,
  OndemandVideo: OndemandVideoIcon,
  OnDeviceTraining: OnDeviceTrainingIcon,
  OneK: OneKIcon,
  OneKk: OneKkIcon,
  OneKPlus: OneKPlusIcon,
  OnlinePrediction: OnlinePredictionIcon,
  Opacity: OpacityIcon,
  OpenInBrowser: OpenInBrowserIcon,
  OpenInFull: OpenInFullIcon,
  OpenInNew: OpenInNewIcon,
  OpenInNewOff: OpenInNewOffIcon,
  OpenWith: OpenWithIcon,
  OtherHouses: OtherHousesIcon,
  Outbound: OutboundIcon,
  Outbox: OutboxIcon,
  OutdoorGrill: OutdoorGrillIcon,
  Outlet: OutletIcon,
  Output: OutputIcon,
  Padding: PaddingIcon,
  Pages: PagesIcon,
  Pageview: PageviewIcon,
  Paid: PaidIcon,
  Palette: PaletteIcon,
  Panorama: PanoramaIcon,
  PanoramaFishEye: PanoramaFishEyeIcon,
  PanoramaHorizontal: PanoramaHorizontalIcon,
  PanoramaHorizontalSelect: PanoramaHorizontalSelectIcon,
  PanoramaPhotosphere: PanoramaPhotosphereIcon,
  PanoramaPhotosphereSelect: PanoramaPhotosphereSelectIcon,
  PanoramaVertical: PanoramaVerticalIcon,
  PanoramaVerticalSelect: PanoramaVerticalSelectIcon,
  PanoramaWideAngle: PanoramaWideAngleIcon,
  PanoramaWideAngleSelect: PanoramaWideAngleSelectIcon,
  PanTool: PanToolIcon,
  PanToolAlt: PanToolAltIcon,
  Paragliding: ParaglidingIcon,
  Park: ParkIcon,
  PartyMode: PartyModeIcon,
  Password: PasswordIcon,
  Pattern: PatternIcon,
  Pause: PauseIcon,
  PauseCircle: PauseCircleIcon,
  PauseCircleFilled: PauseCircleFilledIcon,
  PauseCircleOutline: PauseCircleOutlineIcon,
  PausePresentation: PausePresentationIcon,
  Payment: PaymentIcon,
  Payments: PaymentsIcon,
  PedalBike: PedalBikeIcon,
  Pending: PendingIcon,
  PendingActions: PendingActionsIcon,
  Pentagon: PentagonIcon,
  People: PeopleIcon,
  PeopleAlt: PeopleAltIcon,
  PeopleOutline: PeopleOutlineIcon,
  Percent: PercentIcon,
  PermCameraMic: PermCameraMicIcon,
  PermContactCalendar: PermContactCalendarIcon,
  PermDataSetting: PermDataSettingIcon,
  PermDeviceInformation: PermDeviceInformationIcon,
  PermIdentity: PermIdentityIcon,
  PermMedia: PermMediaIcon,
  PermPhoneMsg: PermPhoneMsgIcon,
  PermScanWifi: PermScanWifiIcon,
  Person: PersonIcon,
  PersonAdd: PersonAddIcon,
  PersonAddAlt: PersonAddAltIcon,
  PersonAddAlt1: PersonAddAlt1Icon,
  PersonAddDisabled: PersonAddDisabledIcon,
  PersonalVideo: PersonalVideoIcon,
  PersonOff: PersonOffIcon,
  PersonOutline: PersonOutlineIcon,
  PersonPin: PersonPinIcon,
  PersonPinCircle: PersonPinCircleIcon,
  PersonRemove: PersonRemoveIcon,
  PersonRemoveAlt1: PersonRemoveAlt1Icon,
  PersonSearch: PersonSearchIcon,
  PestControl: PestControlIcon,
  PestControlRodent: PestControlRodentIcon,
  Pets: PetsIcon,
  Phishing: PhishingIcon,
  Phone: PhoneIcon,
  PhoneAndroid: PhoneAndroidIcon,
  PhoneBluetoothSpeaker: PhoneBluetoothSpeakerIcon,
  PhoneCallback: PhoneCallbackIcon,
  PhoneDisabled: PhoneDisabledIcon,
  PhoneEnabled: PhoneEnabledIcon,
  PhoneForwarded: PhoneForwardedIcon,
  PhoneInTalk: PhoneInTalkIcon,
  PhoneIphone: PhoneIphoneIcon,
  Phonelink: PhonelinkIcon,
  PhonelinkErase: PhonelinkEraseIcon,
  PhonelinkLock: PhonelinkLockIcon,
  PhonelinkOff: PhonelinkOffIcon,
  PhonelinkRing: PhonelinkRingIcon,
  PhonelinkSetup: PhonelinkSetupIcon,
  PhoneLocked: PhoneLockedIcon,
  PhoneMissed: PhoneMissedIcon,
  PhonePaused: PhonePausedIcon,
  Photo: PhotoIcon,
  PhotoAlbum: PhotoAlbumIcon,
  PhotoCamera: PhotoCameraIcon,
  PhotoCameraBack: PhotoCameraBackIcon,
  PhotoCameraFront: PhotoCameraFrontIcon,
  PhotoFilter: PhotoFilterIcon,
  PhotoLibrary: PhotoLibraryIcon,
  PhotoSizeSelectActual: PhotoSizeSelectActualIcon,
  PhotoSizeSelectLarge: PhotoSizeSelectLargeIcon,
  PhotoSizeSelectSmall: PhotoSizeSelectSmallIcon,
  Php: PhpIcon,
  Piano: PianoIcon,
  PianoOff: PianoOffIcon,
  PictureAsPdf: PictureAsPdfIcon,
  PictureInPicture: PictureInPictureIcon,
  PictureInPictureAlt: PictureInPictureAltIcon,
  PieChart: PieChartIcon,
  PieChartOutline: PieChartOutlineIcon,
  Pin: PinIcon,
  Pinch: PinchIcon,
  PinDrop: PinDropIcon,
  Pinterest: PinterestIcon,
  PivotTableChart: PivotTableChartIcon,
  Pix: PixIcon,
  Place: PlaceIcon,
  Plagiarism: PlagiarismIcon,
  PlayArrow: PlayArrowIcon,
  PlayCircle: PlayCircleIcon,
  PlayCircleFilled: PlayCircleFilledIcon,
  PlayCircleFilledWhite: PlayCircleFilledWhiteIcon,
  PlayCircleOutline: PlayCircleOutlineIcon,
  PlayDisabled: PlayDisabledIcon,
  PlayForWork: PlayForWorkIcon,
  PlayLesson: PlayLessonIcon,
  PlaylistAdd: PlaylistAddIcon,
  PlaylistAddCheck: PlaylistAddCheckIcon,
  PlaylistAddCheckCircle: PlaylistAddCheckCircleIcon,
  PlaylistAddCircle: PlaylistAddCircleIcon,
  PlaylistPlay: PlaylistPlayIcon,
  PlaylistRemove: PlaylistRemoveIcon,
  Plumbing: PlumbingIcon,
  PlusOne: PlusOneIcon,
  Podcasts: PodcastsIcon,
  PointOfSale: PointOfSaleIcon,
  Policy: PolicyIcon,
  Poll: PollIcon,
  Polyline: PolylineIcon,
  Pool: PoolIcon,
  PortableWifiOff: PortableWifiOffIcon,
  Portrait: PortraitIcon,
  PostAdd: PostAddIcon,
  Power: PowerIcon,
  PowerInput: PowerInputIcon,
  PowerOff: PowerOffIcon,
  PowerSettingsNew: PowerSettingsNewIcon,
  PrecisionManufacturing: PrecisionManufacturingIcon,
  PregnantWoman: PregnantWomanIcon,
  PresentToAll: PresentToAllIcon,
  Preview: PreviewIcon,
  PriceChange: PriceChangeIcon,
  PriceCheck: PriceCheckIcon,
  Print: PrintIcon,
  PrintDisabled: PrintDisabledIcon,
  PriorityHigh: PriorityHighIcon,
  PrivacyTip: PrivacyTipIcon,
  ProductionQuantityLimits: ProductionQuantityLimitsIcon,
  Propane: PropaneIcon,
  PropaneTank: PropaneTankIcon,
  Psychology: PsychologyIcon,
  Public: PublicIcon,
  PublicOff: PublicOffIcon,
  Publish: PublishIcon,
  PublishedWithChanges: PublishedWithChangesIcon,
  PunchClock: PunchClockIcon,
  PushPin: PushPinIcon,
  QrCode: QrCodeIcon,
  QrCode2: QrCode2Icon,
  QrCodeScanner: QrCodeScannerIcon,
  QueryBuilder: QueryBuilderIcon,
  QueryStats: QueryStatsIcon,
  QuestionAnswer: QuestionAnswerIcon,
  QuestionMark: QuestionMarkIcon,
  Queue: QueueIcon,
  QueueMusic: QueueMusicIcon,
  QueuePlayNext: QueuePlayNextIcon,
  Quickreply: QuickreplyIcon,
  Quiz: QuizIcon,
  Radar: RadarIcon,
  Radio: RadioIcon,
  RadioButtonChecked: RadioButtonCheckedIcon,
  RadioButtonUnchecked: RadioButtonUncheckedIcon,
  RailwayAlert: RailwayAlertIcon,
  RamenDining: RamenDiningIcon,
  RampLeft: RampLeftIcon,
  RampRight: RampRightIcon,
  RateReview: RateReviewIcon,
  RawOff: RawOffIcon,
  RawOn: RawOnIcon,
  ReadMore: ReadMoreIcon,
  Receipt: ReceiptIcon,
  ReceiptLong: ReceiptLongIcon,
  RecentActors: RecentActorsIcon,
  Recommend: RecommendIcon,
  RecordVoiceOver: RecordVoiceOverIcon,
  Rectangle: RectangleIcon,
  Reddit: RedditIcon,
  Redeem: RedeemIcon,
  Redo: RedoIcon,
  ReduceCapacity: ReduceCapacityIcon,
  Refresh: RefreshIcon,
  RememberMe: RememberMeIcon,
  Remove: RemoveIcon,
  RemoveCircle: RemoveCircleIcon,
  RemoveCircleOutline: RemoveCircleOutlineIcon,
  RemoveDone: RemoveDoneIcon,
  RemoveFromQueue: RemoveFromQueueIcon,
  RemoveModerator: RemoveModeratorIcon,
  RemoveRedEye: RemoveRedEyeIcon,
  RemoveRoad: RemoveRoadIcon,
  RemoveShoppingCart: RemoveShoppingCartIcon,
  Reorder: ReorderIcon,
  Repeat: RepeatIcon,
  RepeatOn: RepeatOnIcon,
  RepeatOne: RepeatOneIcon,
  RepeatOneOn: RepeatOneOnIcon,
  Replay: ReplayIcon,
  Replay10: Replay10Icon,
  Replay30: Replay30Icon,
  Replay5: Replay5Icon,
  ReplayCircleFilled: ReplayCircleFilledIcon,
  Reply: ReplyIcon,
  ReplyAll: ReplyAllIcon,
  Report: ReportIcon,
  ReportGmailerrorred: ReportGmailerrorredIcon,
  ReportOff: ReportOffIcon,
  ReportProblem: ReportProblemIcon,
  RequestPage: RequestPageIcon,
  RequestQuote: RequestQuoteIcon,
  ResetTv: ResetTvIcon,
  RestartAlt: RestartAltIcon,
  Restaurant: RestaurantIcon,
  RestaurantMenu: RestaurantMenuIcon,
  Restore: RestoreIcon,
  RestoreFromTrash: RestoreFromTrashIcon,
  RestorePage: RestorePageIcon,
  Reviews: ReviewsIcon,
  RiceBowl: RiceBowlIcon,
  RingVolume: RingVolumeIcon,
  RMobiledata: RMobiledataIcon,
  Rocket: RocketIcon,
  RocketLaunch: RocketLaunchIcon,
  RollerShades: RollerShadesIcon,
  RollerShadesClosed: RollerShadesClosedIcon,
  RollerSkating: RollerSkatingIcon,
  Roofing: RoofingIcon,
  Room: RoomIcon,
  RoomPreferences: RoomPreferencesIcon,
  RoomService: RoomServiceIcon,
  Rotate90DegreesCcw: Rotate90DegreesCcwIcon,
  Rotate90DegreesCw: Rotate90DegreesCwIcon,
  RotateLeft: RotateLeftIcon,
  RotateRight: RotateRightIcon,
  RoundaboutLeft: RoundaboutLeftIcon,
  RoundaboutRight: RoundaboutRightIcon,
  Route: RouteIcon,
  Router: RouterIcon,
  Rowing: RowingIcon,
  RssFeed: RssFeedIcon,
  Rsvp: RsvpIcon,
  Rtt: RttIcon,
  Rule: RuleIcon,
  RuleFolder: RuleFolderIcon,
  RunCircle: RunCircleIcon,
  RunningWithErrors: RunningWithErrorsIcon,
  RvHookup: RvHookupIcon,
  SafetyCheck: SafetyCheckIcon,
  SafetyDivider: SafetyDividerIcon,
  Sailing: SailingIcon,
  Sanitizer: SanitizerIcon,
  Satellite: SatelliteIcon,
  SatelliteAlt: SatelliteAltIcon,
  Save: SaveIcon,
  SaveAlt: SaveAltIcon,
  SaveAs: SaveAsIcon,
  SavedSearch: SavedSearchIcon,
  Savings: SavingsIcon,
  Scale: ScaleIcon,
  Scanner: ScannerIcon,
  ScatterPlot: ScatterPlotIcon,
  Schedule: ScheduleIcon,
  ScheduleSend: ScheduleSendIcon,
  Schema: SchemaIcon,
  School: SchoolIcon,
  Science: ScienceIcon,
  Score: ScoreIcon,
  Scoreboard: ScoreboardIcon,
  ScreenLockLandscape: ScreenLockLandscapeIcon,
  ScreenLockPortrait: ScreenLockPortraitIcon,
  ScreenLockRotation: ScreenLockRotationIcon,
  ScreenRotation: ScreenRotationIcon,
  ScreenRotationAlt: ScreenRotationAltIcon,
  ScreenSearchDesktop: ScreenSearchDesktopIcon,
  ScreenShare: ScreenShareIcon,
  Screenshot: ScreenshotIcon,
  ScreenshotMonitor: ScreenshotMonitorIcon,
  ScubaDiving: ScubaDivingIcon,
  Sd: SdIcon,
  SdCard: SdCardIcon,
  SdCardAlert: SdCardAlertIcon,
  SdStorage: SdStorageIcon,
  Search: SearchIcon,
  SearchOff: SearchOffIcon,
  Security: SecurityIcon,
  SecurityUpdate: SecurityUpdateIcon,
  SecurityUpdateGood: SecurityUpdateGoodIcon,
  SecurityUpdateWarning: SecurityUpdateWarningIcon,
  Segment: SegmentIcon,
  SelectAll: SelectAllIcon,
  SelfImprovement: SelfImprovementIcon,
  Sell: SellIcon,
  Send: SendIcon,
  SendAndArchive: SendAndArchiveIcon,
  SendTimeExtension: SendTimeExtensionIcon,
  SendToMobile: SendToMobileIcon,
  SensorDoor: SensorDoorIcon,
  SensorOccupied: SensorOccupiedIcon,
  Sensors: SensorsIcon,
  SensorsOff: SensorsOffIcon,
  SensorWindow: SensorWindowIcon,
  SentimentDissatisfied: SentimentDissatisfiedIcon,
  SentimentNeutral: SentimentNeutralIcon,
  SentimentSatisfied: SentimentSatisfiedIcon,
  SentimentSatisfiedAlt: SentimentSatisfiedAltIcon,
  SentimentVeryDissatisfied: SentimentVeryDissatisfiedIcon,
  SentimentVerySatisfied: SentimentVerySatisfiedIcon,
  SetMeal: SetMealIcon,
  Settings: SettingsIcon,
  SettingsAccessibility: SettingsAccessibilityIcon,
  SettingsApplications: SettingsApplicationsIcon,
  SettingsBackupRestore: SettingsBackupRestoreIcon,
  SettingsBluetooth: SettingsBluetoothIcon,
  SettingsBrightness: SettingsBrightnessIcon,
  SettingsCell: SettingsCellIcon,
  SettingsEthernet: SettingsEthernetIcon,
  SettingsInputAntenna: SettingsInputAntennaIcon,
  SettingsInputComponent: SettingsInputComponentIcon,
  SettingsInputComposite: SettingsInputCompositeIcon,
  SettingsInputHdmi: SettingsInputHdmiIcon,
  SettingsInputSvideo: SettingsInputSvideoIcon,
  SettingsOverscan: SettingsOverscanIcon,
  SettingsPhone: SettingsPhoneIcon,
  SettingsPower: SettingsPowerIcon,
  SettingsRemote: SettingsRemoteIcon,
  SettingsSuggest: SettingsSuggestIcon,
  SettingsSystemDaydream: SettingsSystemDaydreamIcon,
  SettingsVoice: SettingsVoiceIcon,
  SevenK: SevenKIcon,
  SevenKPlus: SevenKPlusIcon,
  SevenMp: SevenMpIcon,
  SeventeenMp: SeventeenMpIcon,
  SevereCold: SevereColdIcon,
  Share: ShareIcon,
  ShareLocation: ShareLocationIcon,
  Shield: ShieldIcon,
  ShieldMoon: ShieldMoonIcon,
  Shop: ShopIcon,
  Shop2: Shop2Icon,
  ShoppingBag: ShoppingBagIcon,
  ShoppingBasket: ShoppingBasketIcon,
  ShoppingCart: ShoppingCartIcon,
  ShoppingCartCheckout: ShoppingCartCheckoutIcon,
  ShopTwo: ShopTwoIcon,
  Shortcut: ShortcutIcon,
  ShortText: ShortTextIcon,
  ShowChart: ShowChartIcon,
  Shower: ShowerIcon,
  Shuffle: ShuffleIcon,
  ShuffleOn: ShuffleOnIcon,
  ShutterSpeed: ShutterSpeedIcon,
  Sick: SickIcon,
  SignalCellular0Bar: SignalCellular0BarIcon,
  SignalCellular1Bar: SignalCellular1BarIcon,
  SignalCellular2Bar: SignalCellular2BarIcon,
  SignalCellular3Bar: SignalCellular3BarIcon,
  SignalCellular4Bar: SignalCellular4BarIcon,
  SignalCellularAlt: SignalCellularAltIcon,
  SignalCellularAlt1Bar: SignalCellularAlt1BarIcon,
  SignalCellularAlt2Bar: SignalCellularAlt2BarIcon,
  SignalCellularConnectedNoInternet0Bar: SignalCellularConnectedNoInternet0BarIcon,
  SignalCellularConnectedNoInternet1Bar: SignalCellularConnectedNoInternet1BarIcon,
  SignalCellularConnectedNoInternet2Bar: SignalCellularConnectedNoInternet2BarIcon,
  SignalCellularConnectedNoInternet3Bar: SignalCellularConnectedNoInternet3BarIcon,
  SignalCellularConnectedNoInternet4Bar: SignalCellularConnectedNoInternet4BarIcon,
  SignalCellularNodata: SignalCellularNodataIcon,
  SignalCellularNoSim: SignalCellularNoSimIcon,
  SignalCellularNull: SignalCellularNullIcon,
  SignalCellularOff: SignalCellularOffIcon,
  SignalWifi0Bar: SignalWifi0BarIcon,
  SignalWifi1Bar: SignalWifi1BarIcon,
  SignalWifi1BarLock: SignalWifi1BarLockIcon,
  SignalWifi2Bar: SignalWifi2BarIcon,
  SignalWifi2BarLock: SignalWifi2BarLockIcon,
  SignalWifi3Bar: SignalWifi3BarIcon,
  SignalWifi3BarLock: SignalWifi3BarLockIcon,
  SignalWifi4Bar: SignalWifi4BarIcon,
  SignalWifi4BarLock: SignalWifi4BarLockIcon,
  SignalWifiBad: SignalWifiBadIcon,
  SignalWifiConnectedNoInternet4: SignalWifiConnectedNoInternet4Icon,
  SignalWifiOff: SignalWifiOffIcon,
  SignalWifiStatusbar4Bar: SignalWifiStatusbar4BarIcon,
  SignalWifiStatusbarConnectedNoInternet4: SignalWifiStatusbarConnectedNoInternet4Icon,
  SignalWifiStatusbarNull: SignalWifiStatusbarNullIcon,
  SignLanguage: SignLanguageIcon,
  Signpost: SignpostIcon,
  SimCard: SimCardIcon,
  SimCardAlert: SimCardAlertIcon,
  SimCardDownload: SimCardDownloadIcon,
  SingleBed: SingleBedIcon,
  Sip: SipIcon,
  SixK: SixKIcon,
  SixKPlus: SixKPlusIcon,
  SixMp: SixMpIcon,
  SixteenMp: SixteenMpIcon,
  SixtyFps: SixtyFpsIcon,
  SixtyFpsSelect: SixtyFpsSelectIcon,
  Skateboarding: SkateboardingIcon,
  SkipNext: SkipNextIcon,
  SkipPrevious: SkipPreviousIcon,
  Sledding: SleddingIcon,
  Slideshow: SlideshowIcon,
  SlowMotionVideo: SlowMotionVideoIcon,
  SmartButton: SmartButtonIcon,
  SmartDisplay: SmartDisplayIcon,
  Smartphone: SmartphoneIcon,
  SmartScreen: SmartScreenIcon,
  SmartToy: SmartToyIcon,
  SmokeFree: SmokeFreeIcon,
  SmokingRooms: SmokingRoomsIcon,
  Sms: SmsIcon,
  SmsFailed: SmsFailedIcon,
  SnippetFolder: SnippetFolderIcon,
  Snooze: SnoozeIcon,
  Snowboarding: SnowboardingIcon,
  Snowmobile: SnowmobileIcon,
  Snowshoeing: SnowshoeingIcon,
  Soap: SoapIcon,
  SocialDistance: SocialDistanceIcon,
  SolarPower: SolarPowerIcon,
  Sort: SortIcon,
  SortByAlpha: SortByAlphaIcon,
  Sos: SosIcon,
  SoupKitchen: SoupKitchenIcon,
  Source: SourceIcon,
  South: SouthIcon,
  SouthAmerica: SouthAmericaIcon,
  SouthEast: SouthEastIcon,
  SouthWest: SouthWestIcon,
  Spa: SpaIcon,
  SpaceBar: SpaceBarIcon,
  SpatialAudio: SpatialAudioIcon,
  SpatialAudioOff: SpatialAudioOffIcon,
  SpatialTracking: SpatialTrackingIcon,
  Speaker: SpeakerIcon,
  SpeakerGroup: SpeakerGroupIcon,
  SpeakerNotes: SpeakerNotesIcon,
  SpeakerNotesOff: SpeakerNotesOffIcon,
  SpeakerPhone: SpeakerPhoneIcon,
  Speed: SpeedIcon,
  Spellcheck: SpellcheckIcon,
  Splitscreen: SplitscreenIcon,
  Spoke: SpokeIcon,
  Sports: SportsIcon,
  SportsBar: SportsBarIcon,
  SportsBaseball: SportsBaseballIcon,
  SportsBasketball: SportsBasketballIcon,
  SportsCricket: SportsCricketIcon,
  SportsEsports: SportsEsportsIcon,
  SportsFootball: SportsFootballIcon,
  SportsGolf: SportsGolfIcon,
  SportsGymnastics: SportsGymnasticsIcon,
  SportsHandball: SportsHandballIcon,
  SportsHockey: SportsHockeyIcon,
  SportsKabaddi: SportsKabaddiIcon,
  SportsMartialArts: SportsMartialArtsIcon,
  SportsMma: SportsMmaIcon,
  SportsMotorsports: SportsMotorsportsIcon,
  SportsRugby: SportsRugbyIcon,
  SportsScore: SportsScoreIcon,
  SportsSoccer: SportsSoccerIcon,
  SportsTennis: SportsTennisIcon,
  SportsVolleyball: SportsVolleyballIcon,
  Square: SquareIcon,
  SquareFoot: SquareFootIcon,
  SsidChart: SsidChartIcon,
  StackedBarChart: StackedBarChartIcon,
  StackedLineChart: StackedLineChartIcon,
  Stadium: StadiumIcon,
  Stairs: StairsIcon,
  Star: StarIcon,
  StarBorder: StarBorderIcon,
  StarBorderPurple500: StarBorderPurple500Icon,
  StarHalf: StarHalfIcon,
  StarOutline: StarOutlineIcon,
  StarPurple500: StarPurple500Icon,
  StarRate: StarRateIcon,
  Stars: StarsIcon,
  Start: StartIcon,
  StayCurrentLandscape: StayCurrentLandscapeIcon,
  StayCurrentPortrait: StayCurrentPortraitIcon,
  StayPrimaryLandscape: StayPrimaryLandscapeIcon,
  StayPrimaryPortrait: StayPrimaryPortraitIcon,
  StickyNote2: StickyNote2Icon,
  Stop: StopIcon,
  StopCircle: StopCircleIcon,
  StopScreenShare: StopScreenShareIcon,
  Storage: StorageIcon,
  Store: StoreIcon,
  Storefront: StorefrontIcon,
  StoreMallDirectory: StoreMallDirectoryIcon,
  Storm: StormIcon,
  Straight: StraightIcon,
  Straighten: StraightenIcon,
  Stream: StreamIcon,
  Streetview: StreetviewIcon,
  StrikethroughS: StrikethroughSIcon,
  Stroller: StrollerIcon,
  Style: StyleIcon,
  SubdirectoryArrowLeft: SubdirectoryArrowLeftIcon,
  SubdirectoryArrowRight: SubdirectoryArrowRightIcon,
  Subject: SubjectIcon,
  Subscript: SubscriptIcon,
  Subscriptions: SubscriptionsIcon,
  Subtitles: SubtitlesIcon,
  SubtitlesOff: SubtitlesOffIcon,
  Subway: SubwayIcon,
  Summarize: SummarizeIcon,
  Superscript: SuperscriptIcon,
  SupervisedUserCircle: SupervisedUserCircleIcon,
  SupervisorAccount: SupervisorAccountIcon,
  Support: SupportIcon,
  SupportAgent: SupportAgentIcon,
  Surfing: SurfingIcon,
  SurroundSound: SurroundSoundIcon,
  SwapCalls: SwapCallsIcon,
  SwapHoriz: SwapHorizIcon,
  SwapHorizontalCircle: SwapHorizontalCircleIcon,
  SwapVert: SwapVertIcon,
  SwapVerticalCircle: SwapVerticalCircleIcon,
  Swipe: SwipeIcon,
  SwipeDown: SwipeDownIcon,
  SwipeDownAlt: SwipeDownAltIcon,
  SwipeLeft: SwipeLeftIcon,
  SwipeLeftAlt: SwipeLeftAltIcon,
  SwipeRight: SwipeRightIcon,
  SwipeRightAlt: SwipeRightAltIcon,
  SwipeUp: SwipeUpIcon,
  SwipeUpAlt: SwipeUpAltIcon,
  SwipeVertical: SwipeVerticalIcon,
  SwitchAccessShortcut: SwitchAccessShortcutIcon,
  SwitchAccessShortcutAdd: SwitchAccessShortcutAddIcon,
  SwitchAccount: SwitchAccountIcon,
  SwitchCamera: SwitchCameraIcon,
  SwitchLeft: SwitchLeftIcon,
  SwitchRight: SwitchRightIcon,
  SwitchVideo: SwitchVideoIcon,
  Synagogue: SynagogueIcon,
  Sync: SyncIcon,
  SyncAlt: SyncAltIcon,
  SyncDisabled: SyncDisabledIcon,
  SyncLock: SyncLockIcon,
  SyncProblem: SyncProblemIcon,
  SystemSecurityUpdate: SystemSecurityUpdateIcon,
  SystemSecurityUpdateGood: SystemSecurityUpdateGoodIcon,
  SystemSecurityUpdateWarning: SystemSecurityUpdateWarningIcon,
  SystemUpdate: SystemUpdateIcon,
  SystemUpdateAlt: SystemUpdateAltIcon,
  Tab: TabIcon,
  TableBar: TableBarIcon,
  TableChart: TableChartIcon,
  TableRestaurant: TableRestaurantIcon,
  TableRows: TableRowsIcon,
  Tablet: TabletIcon,
  TabletAndroid: TabletAndroidIcon,
  TabletMac: TabletMacIcon,
  TableView: TableViewIcon,
  TabUnselected: TabUnselectedIcon,
  Tag: TagIcon,
  TagFaces: TagFacesIcon,
  TakeoutDining: TakeoutDiningIcon,
  TapAndPlay: TapAndPlayIcon,
  Tapas: TapasIcon,
  Task: TaskIcon,
  TaskAlt: TaskAltIcon,
  TaxiAlert: TaxiAlertIcon,
  Telegram: TelegramIcon,
  TempleBuddhist: TempleBuddhistIcon,
  TempleHindu: TempleHinduIcon,
  TenMp: TenMpIcon,
  Terminal: TerminalIcon,
  Terrain: TerrainIcon,
  TextDecrease: TextDecreaseIcon,
  TextFields: TextFieldsIcon,
  TextFormat: TextFormatIcon,
  TextIncrease: TextIncreaseIcon,
  TextRotateUp: TextRotateUpIcon,
  TextRotateVertical: TextRotateVerticalIcon,
  TextRotationAngledown: TextRotationAngledownIcon,
  TextRotationAngleup: TextRotationAngleupIcon,
  TextRotationDown: TextRotationDownIcon,
  TextRotationNone: TextRotationNoneIcon,
  Textsms: TextsmsIcon,
  TextSnippet: TextSnippetIcon,
  Texture: TextureIcon,
  TheaterComedy: TheaterComedyIcon,
  Theaters: TheatersIcon,
  Thermostat: ThermostatIcon,
  ThermostatAuto: ThermostatAutoIcon,
  ThirteenMp: ThirteenMpIcon,
  ThirtyFps: ThirtyFpsIcon,
  ThirtyFpsSelect: ThirtyFpsSelectIcon,
  ThreeDRotation: ThreeDRotationIcon,
  ThreeGMobiledata: ThreeGMobiledataIcon,
  ThreeK: ThreeKIcon,
  ThreeKPlus: ThreeKPlusIcon,
  ThreeMp: ThreeMpIcon,
  ThreeP: ThreePIcon,
  ThreeSixty: ThreeSixtyIcon,
  ThumbDown: ThumbDownIcon,
  ThumbDownAlt: ThumbDownAltIcon,
  ThumbDownOffAlt: ThumbDownOffAltIcon,
  ThumbsUpDown: ThumbsUpDownIcon,
  ThumbUp: ThumbUpIcon,
  ThumbUpAlt: ThumbUpAltIcon,
  ThumbUpOffAlt: ThumbUpOffAltIcon,
  Thunderstorm: ThunderstormIcon,
  Timelapse: TimelapseIcon,
  Timeline: TimelineIcon,
  Timer: TimerIcon,
  Timer10: Timer10Icon,
  Timer10Select: Timer10SelectIcon,
  Timer3: Timer3Icon,
  Timer3Select: Timer3SelectIcon,
  TimerOff: TimerOffIcon,
  TimesOneMobiledata: TimesOneMobiledataIcon,
  TimeToLeave: TimeToLeaveIcon,
  TipsAndUpdates: TipsAndUpdatesIcon,
  TireRepair: TireRepairIcon,
  Title: TitleIcon,
  Toc: TocIcon,
  Today: TodayIcon,
  ToggleOff: ToggleOffIcon,
  ToggleOn: ToggleOnIcon,
  Token: TokenIcon,
  Toll: TollIcon,
  Tonality: TonalityIcon,
  Topic: TopicIcon,
  Tornado: TornadoIcon,
  TouchApp: TouchAppIcon,
  Tour: TourIcon,
  Toys: ToysIcon,
  TrackChanges: TrackChangesIcon,
  Traffic: TrafficIcon,
  Train: TrainIcon,
  Tram: TramIcon,
  TransferWithinAStation: TransferWithinAStationIcon,
  Transform: TransformIcon,
  Transgender: TransgenderIcon,
  TransitEnterexit: TransitEnterexitIcon,
  Translate: TranslateIcon,
  TravelExplore: TravelExploreIcon,
  TrendingDown: TrendingDownIcon,
  TrendingFlat: TrendingFlatIcon,
  TrendingUp: TrendingUpIcon,
  TripOrigin: TripOriginIcon,
  Try: TryIcon,
  Tsunami: TsunamiIcon,
  Tty: TtyIcon,
  Tune: TuneIcon,
  Tungsten: TungstenIcon,
  TurnedIn: TurnedInIcon,
  TurnedInNot: TurnedInNotIcon,
  TurnLeft: TurnLeftIcon,
  TurnRight: TurnRightIcon,
  TurnSlightLeft: TurnSlightLeftIcon,
  TurnSlightRight: TurnSlightRightIcon,
  Tv: TvIcon,
  TvOff: TvOffIcon,
  TwelveMp: TwelveMpIcon,
  TwentyFourMp: TwentyFourMpIcon,
  TwentyOneMp: TwentyOneMpIcon,
  TwentyThreeMp: TwentyThreeMpIcon,
  TwentyTwoMp: TwentyTwoMpIcon,
  TwentyZeroMp: TwentyZeroMpIcon,
  Twitter: TwitterIcon,
  TwoK: TwoKIcon,
  TwoKPlus: TwoKPlusIcon,
  TwoMp: TwoMpIcon,
  TwoWheeler: TwoWheelerIcon,
  Umbrella: UmbrellaIcon,
  Unarchive: UnarchiveIcon,
  Undo: UndoIcon,
  UnfoldLess: UnfoldLessIcon,
  UnfoldMore: UnfoldMoreIcon,
  Unpublished: UnpublishedIcon,
  Unsubscribe: UnsubscribeIcon,
  Upcoming: UpcomingIcon,
  Update: UpdateIcon,
  UpdateDisabled: UpdateDisabledIcon,
  Upgrade: UpgradeIcon,
  Upload: UploadIcon,
  UploadFile: UploadFileIcon,
  Usb: UsbIcon,
  UsbOff: UsbOffIcon,
  UTurnLeft: UTurnLeftIcon,
  UTurnRight: UTurnRightIcon,
  Vaccines: VaccinesIcon,
  VapeFree: VapeFreeIcon,
  VapingRooms: VapingRoomsIcon,
  Verified: VerifiedIcon,
  VerifiedUser: VerifiedUserIcon,
  VerticalAlignBottom: VerticalAlignBottomIcon,
  VerticalAlignCenter: VerticalAlignCenterIcon,
  VerticalAlignTop: VerticalAlignTopIcon,
  VerticalShades: VerticalShadesIcon,
  VerticalShadesClosed: VerticalShadesClosedIcon,
  VerticalSplit: VerticalSplitIcon,
  Vibration: VibrationIcon,
  VideoCall: VideoCallIcon,
  Videocam: VideocamIcon,
  VideoCameraBack: VideoCameraBackIcon,
  VideoCameraFront: VideoCameraFrontIcon,
  VideocamOff: VideocamOffIcon,
  VideoFile: VideoFileIcon,
  VideogameAsset: VideogameAssetIcon,
  VideogameAssetOff: VideogameAssetOffIcon,
  VideoLabel: VideoLabelIcon,
  VideoLibrary: VideoLibraryIcon,
  VideoSettings: VideoSettingsIcon,
  VideoStable: VideoStableIcon,
  ViewAgenda: ViewAgendaIcon,
  ViewArray: ViewArrayIcon,
  ViewCarousel: ViewCarouselIcon,
  ViewColumn: ViewColumnIcon,
  ViewComfy: ViewComfyIcon,
  ViewComfyAlt: ViewComfyAltIcon,
  ViewCompact: ViewCompactIcon,
  ViewCompactAlt: ViewCompactAltIcon,
  ViewCozy: ViewCozyIcon,
  ViewDay: ViewDayIcon,
  ViewHeadline: ViewHeadlineIcon,
  ViewInAr: ViewInArIcon,
  ViewKanban: ViewKanbanIcon,
  ViewList: ViewListIcon,
  ViewModule: ViewModuleIcon,
  ViewQuilt: ViewQuiltIcon,
  ViewSidebar: ViewSidebarIcon,
  ViewStream: ViewStreamIcon,
  ViewTimeline: ViewTimelineIcon,
  ViewWeek: ViewWeekIcon,
  Vignette: VignetteIcon,
  Villa: VillaIcon,
  Visibility: VisibilityIcon,
  VisibilityOff: VisibilityOffIcon,
  VoiceChat: VoiceChatIcon,
  Voicemail: VoicemailIcon,
  VoiceOverOff: VoiceOverOffIcon,
  Volcano: VolcanoIcon,
  VolumeDown: VolumeDownIcon,
  VolumeMute: VolumeMuteIcon,
  VolumeOff: VolumeOffIcon,
  VolumeUp: VolumeUpIcon,
  VolunteerActivism: VolunteerActivismIcon,
  VpnKey: VpnKeyIcon,
  VpnKeyOff: VpnKeyOffIcon,
  VpnLock: VpnLockIcon,
  Vrpano: VrpanoIcon,
  Wallpaper: WallpaperIcon,
  Warehouse: WarehouseIcon,
  Warning: WarningIcon,
  WarningAmber: WarningAmberIcon,
  Wash: WashIcon,
  Watch: WatchIcon,
  WatchLater: WatchLaterIcon,
  WatchOff: WatchOffIcon,
  Water: WaterIcon,
  WaterDamage: WaterDamageIcon,
  WaterfallChart: WaterfallChartIcon,
  Waves: WavesIcon,
  WbAuto: WbAutoIcon,
  WbCloudy: WbCloudyIcon,
  WbIncandescent: WbIncandescentIcon,
  WbIridescent: WbIridescentIcon,
  WbShade: WbShadeIcon,
  WbSunny: WbSunnyIcon,
  WbTwilight: WbTwilightIcon,
  Wc: WcIcon,
  Web: WebIcon,
  WebAsset: WebAssetIcon,
  WebAssetOff: WebAssetOffIcon,
  Webhook: WebhookIcon,
  Weekend: WeekendIcon,
  West: WestIcon,
  WhatsApp: WhatsAppIcon,
  Whatshot: WhatshotIcon,
  WheelchairPickup: WheelchairPickupIcon,
  WhereToVote: WhereToVoteIcon,
  Widgets: WidgetsIcon,
  Wifi: WifiIcon,
  Wifi1Bar: Wifi1BarIcon,
  Wifi2Bar: Wifi2BarIcon,
  WifiCalling: WifiCallingIcon,
  WifiCalling3: WifiCalling3Icon,
  WifiChannel: WifiChannelIcon,
  WifiFind: WifiFindIcon,
  WifiLock: WifiLockIcon,
  WifiOff: WifiOffIcon,
  WifiPassword: WifiPasswordIcon,
  WifiProtectedSetup: WifiProtectedSetupIcon,
  WifiTethering: WifiTetheringIcon,
  WifiTetheringError: WifiTetheringErrorIcon,
  WifiTetheringOff: WifiTetheringOffIcon,
  Window: WindowIcon,
  WindPower: WindPowerIcon,
  WineBar: WineBarIcon,
  Woman: WomanIcon,
  Work: WorkIcon,
  WorkHistory: WorkHistoryIcon,
  WorkOff: WorkOffIcon,
  WorkOutline: WorkOutlineIcon,
  WorkspacePremium: WorkspacePremiumIcon,
  Workspaces: WorkspacesIcon,
  WrapText: WrapTextIcon,
  WrongLocation: WrongLocationIcon,
  Wysiwyg: WysiwygIcon,
  Yard: YardIcon,
  YouTube: YouTubeIcon,
  YoutubeSearchedFor: YoutubeSearchedForIcon,
  ZoomIn: ZoomInIcon,
  ZoomInMap: ZoomInMapIcon,
  ZoomOut: ZoomOutIcon,
  ZoomOutMap: ZoomOutMapIcon,
};
