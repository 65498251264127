import React, {useState} from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PublishIcon from '@mui/icons-material/Publish';
import Backdrop from '@mui/material/Backdrop';
import UploadLeadsDialog from '../../../components/dialogs/UploadTSVDialog';
import {useTranslation} from 'react-i18next';
import {HttpMethods} from '../../../model/HttpMethods';
import {ResourceTypes} from '../../../model/ResourceTypes';
import {TestAttributes} from '../../../TestAttributes';

function LeadAttachmentSpeedDial() {
  const [isSpeedDialOpen, setIsSpeedDialOpen] = useState<boolean>(false);
  const [isUploaderDialogOpen, setIsUploaderDialogOpen] = useState<boolean>(false);
  const [speedDialAction, setSpeedDialAction] = useState<HttpMethods>();
  const {t} = useTranslation();
  const handleOpen = () => setIsSpeedDialOpen(true);
  const handleClose = () => setIsSpeedDialOpen(false);

  const handleUploadNew = () => {
    setIsUploaderDialogOpen(true);
    setSpeedDialAction(HttpMethods.POST);
  };

  const handleUploadExisting = () => {
    setIsUploaderDialogOpen(true);
    setSpeedDialAction(HttpMethods.PATCH);
  };

  return (
    <>
      <Backdrop open={isSpeedDialOpen} />
      <SpeedDial
        {...{[TestAttributes.BUTTON_NAME]: 'speed-dial-btn'}}
        ariaLabel="LeadPage Attachment SpeedDial"
        direction={'down'}
        sx={{position: 'absolute', top: {xs: '70px', md: '16px'}, right: (theme) => theme.spacing(1)}}
        icon={<PublishIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={isSpeedDialOpen}
      >
        <SpeedDialAction
          id={'lead-speed-dial-action-upload-new'}
          icon={<PublishIcon />}
          tooltipTitle={t('uploader.speed-dial.new')}
          tooltipOpen
          onClick={() => handleUploadNew()}
        />
        <SpeedDialAction
          id={'lead-speed-dial-action-upload-existing'}
          icon={<PublishIcon />}
          tooltipTitle={t('uploader.speed-dial.existing')}
          tooltipOpen
          onClick={() => handleUploadExisting()}
        />
      </SpeedDial>
      <UploadLeadsDialog
        isOpen={isUploaderDialogOpen}
        handleClose={() => setIsUploaderDialogOpen(false)}
        method={speedDialAction}
        resourceType={ResourceTypes.LEADS}
      />
    </>
  );
}

export default LeadAttachmentSpeedDial;
