import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {Box, Grid, IconButton, Paper, styled, Typography, useMediaQuery, useTheme} from '@mui/material';
import ArrowCircleUpSharpIcon from '@mui/icons-material/ArrowCircleUpSharp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {paletteModeDark, useColors} from '../../../context/LightDarkThemeProvider';
import {ConversationMessage, ConversationMessageStatus} from '../../../model/ConversationHistory';
import TranscriptionConfidenceBar from './TranscriptionConfidenceBar';
import {AudioHandle} from '../../../components/AudioPlayer';

interface ConversationHistoryViewProps {
  chat: Array<ConversationMessage>;
  newMessage?: ConversationMessage;
  audioHandle: AudioHandle | null;
  displayConfidenceScores: boolean;
}

export const ChatArrows = styled('div')(() => ({
  width: '53px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '-116px',
  marginRight: '12px',
  float: 'right',
  opacity: 0.7,
}));

function ConversationHistory({chat, newMessage, audioHandle, displayConfidenceScores}: ConversationHistoryViewProps) {
  const {t} = useTranslation();
  const topRef = useRef<HTMLDivElement>(null);
  const endRef = useRef<HTMLDivElement>(null);
  const messageRefs = useRef<Array<HTMLDivElement | null>>([]);
  const theme = useTheme();
  const colors = useColors();
  const isMobileViewActive = useMediaQuery(theme.breakpoints.down('lg'));
  const [messages, setMessages] = useState<Array<ConversationMessage> | null>(null);

  function checkMessageMatchesKeywords(chatMessage: ConversationMessage): boolean | undefined {
    return chatMessage?.keyword_matches && chatMessage.keyword_matches.length > 0;
  }

  useEffect(() => {
    if (chat) {
      setMessages(chat);
      messageRefs.current = [];
    }
  }, [chat]);
  useEffect(() => {
    if (newMessage) {
      setMessages([...(messages || []), newMessage]);
    }
  }, [newMessage]);
  useEffect(() => {
    if (messages) {
      const firstMatchIndexFound = messages.findIndex(checkMessageMatchesKeywords);

      if (messageRefs.current.length > 0 && firstMatchIndexFound !== -1) {
        setTimeout(() => {
          messageRefs.current[firstMatchIndexFound]?.scrollIntoView(true);
        }, 500);
      }
    }
  }, [messages]);

  const scrollToTopRef = () => {
    if (topRef) {
      topRef.current?.scrollIntoView(false);
    }
  };
  const scrollToEndRef = () => {
    if (endRef) {
      endRef.current?.scrollIntoView(false);
    }
  };

  const formatSeconds = (seconds: number) => {
    const secondsDuration: moment.Duration = moment.duration(seconds * 1000);
    const minutesString: string = `${secondsDuration.minutes()}`.padStart(2, '0');
    const secondsString: string = `${secondsDuration.seconds()}`.padStart(2, '0');
    return `${minutesString}:${secondsString}`;
  };

  const messageMarginSpacing = isMobileViewActive ? 2 : 6;

  const MessageContainer = styled(Grid)(({theme}) => ({
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  }));

  return (
    <>
      {chat.length > 0 ? (
        <>
          <Grid
            container
            item
            xs={12}
            zeroMinWidth
            style={{overflow: 'auto'}}
            sx={{
              borderRadius: '3px',
              padding: '8px',
              height: '450px',
              background:
                theme.palette.mode === paletteModeDark
                  ? colors.darkModeColors.darkModeSurfaceTransparency
                  : theme.palette.secondary.main,
            }}
          >
            <div ref={topRef}></div>
            {messages &&
              messages?.map((chatMessage: ConversationMessage, index: number) => {
                const messageTextColor =
                  chatMessage.status === ConversationMessageStatus.RECEIVED
                    ? theme.palette.mode === paletteModeDark
                      ? theme.palette.primary.contrastText
                      : theme.palette.secondary.contrastText
                    : theme.palette.mode === paletteModeDark
                    ? theme.palette.secondary.main
                    : theme.palette.background.default;
                const messageBackgroundColor =
                  chatMessage.status === ConversationMessageStatus.RECEIVED
                    ? theme.palette.mode === paletteModeDark
                      ? theme.palette.secondary.main
                      : theme.palette.background.default
                    : theme.palette.primary.main;

                const messageMatchesKeywords = checkMessageMatchesKeywords(chatMessage);
                const shouldDisplayConfidenceScores = messageMatchesKeywords || displayConfidenceScores;
                const messageBottomBorder = messageMatchesKeywords ? 'thick solid lime' : null;
                const skipForwardButtonColor =
                  chatMessage.status === ConversationMessageStatus.RECEIVED
                    ? theme.palette.primary.main
                    : theme.palette.mode === paletteModeDark
                    ? theme.palette.secondary.main
                    : theme.palette.background.default;

                const onChatPlaybackClick = (chatMessage: ConversationMessage) => {
                  if (audioHandle && chatMessage.start_time) {
                    audioHandle.clipPlayClicked(chatMessage.start_time);
                  }
                };

                return (
                  <MessageContainer
                    ref={(ref) => (messageRefs.current[index] = ref)}
                    item
                    key={'chat-message-' + chatMessage.created_at + '-' + index}
                    sm={12}
                    display={'flex'}
                    alignItems={'baseline'}
                    zeroMinWidth
                    justifyContent={chatMessage.status === ConversationMessageStatus.SENT ? 'flex-end' : 'flex-start'}
                  >
                    <Box
                      sx={{
                        maxWidth: '80%',
                      }}
                      display={'flex'}
                      flexDirection={'column'}
                    >
                      {shouldDisplayConfidenceScores && (
                        <Box
                          sx={{
                            marginTop: '10px',

                            marginRight: (theme) =>
                              chatMessage.status === ConversationMessageStatus.RECEIVED
                                ? theme.spacing(messageMarginSpacing)
                                : theme.spacing(5),
                            marginLeft: (theme) =>
                              chatMessage.status === ConversationMessageStatus.RECEIVED
                                ? theme.spacing(5)
                                : messageMarginSpacing,
                            color:
                              theme.palette.mode === paletteModeDark
                                ? theme.palette.background.default
                                : theme.palette.secondary.contrastText,
                          }}
                          display={'flex'}
                          justifyContent={'flex-start'}
                        >
                          <TranscriptionConfidenceBar
                            confidenceValue={chatMessage.confidence_score}
                            detectedLanguage={chatMessage.language}
                          />
                        </Box>
                      )}

                      <Paper
                        sx={{
                          textAlign: chatMessage.status === ConversationMessageStatus.RECEIVED ? 'left' : 'right',
                          padding: (theme) => theme.spacing(2),
                          marginRight: (theme) =>
                            chatMessage.status === ConversationMessageStatus.RECEIVED
                              ? theme.spacing(messageMarginSpacing)
                              : theme.spacing(5),
                          marginLeft: (theme) =>
                            chatMessage.status === ConversationMessageStatus.RECEIVED
                              ? theme.spacing(5)
                              : messageMarginSpacing,
                          marginBottom: (theme) => theme.spacing(2),
                          borderRadius: '3px',
                          background: messageBackgroundColor,
                          color: messageTextColor,
                          borderBottom: messageBottomBorder,
                          position: 'relative',
                          minWidth: '170px',
                          '&:after': {
                            content: "''",
                            position: 'absolute',
                            width: '0',
                            height: '0',
                            borderTop: `15px solid ${messageBackgroundColor}`,
                            borderLeft: '15px solid transparent',
                            borderRight: '10px solid transparent',
                            top: '0',
                            left: chatMessage.status === ConversationMessageStatus.RECEIVED ? '-10px' : 'none',
                          },
                        }}
                      >
                        <Typography variant={'h6'} sx={{fontWeight: 'bold', color: messageTextColor}}>
                          {chatMessage.owner}
                        </Typography>
                        <Typography
                          variant={'body1'}
                          sx={{
                            color: messageTextColor,
                          }}
                        >
                          {chatMessage.transcription}
                        </Typography>
                        <Box
                          display={'flex'}
                          sx={{
                            justifyContent:
                              chatMessage.status === ConversationMessageStatus.RECEIVED ? 'flex-start' : 'flex-end',
                            marginTop: '5px',
                            marginBottom: '-10px',
                          }}
                        >
                          <IconButton
                            id={'play-button'}
                            sx={{
                              height: '20px',
                              width: '0px',
                              '&:hover': {
                                backgroundColor: 'transparent',
                              },
                            }}
                            onClick={() => {
                              onChatPlaybackClick(chatMessage);
                            }}
                          >
                            <PlayCircleIcon
                              sx={{
                                color: skipForwardButtonColor,
                                fontSize: 'medium',
                              }}
                            />
                          </IconButton>
                          <Typography
                            variant={'body2'}
                            sx={{
                              fontStyle: 'italic',
                              color: messageTextColor,
                              marginLeft: '2px',
                            }}
                            onClick={() => {
                              onChatPlaybackClick(chatMessage);
                            }}
                          >
                            {chatMessage.start_time ? formatSeconds(chatMessage.start_time) : formatSeconds(0)}
                          </Typography>
                        </Box>
                      </Paper>
                    </Box>
                  </MessageContainer>
                );
              })}
            <div ref={endRef}></div>
          </Grid>
          {messages && (
            <ChatArrows>
              <IconButton
                sx={{color: theme.palette.primary.main}}
                onClick={() => {
                  scrollToTopRef();
                }}
                size="large"
              >
                <ArrowCircleUpSharpIcon />
              </IconButton>

              <IconButton
                sx={{color: theme.palette.primary.main}}
                onClick={() => {
                  scrollToEndRef();
                }}
                size="large"
              >
                <ArrowCircleDownIcon />
              </IconButton>
            </ChatArrows>
          )}
        </>
      ) : (
        <Box
          sx={{
            borderRadius: '3px',
            padding: '8px',
            background:
              theme.palette.mode === paletteModeDark
                ? colors.darkModeColors.darkModeSurfaceTransparency
                : theme.palette.secondary.main,
          }}
        >
          <Typography
            variant={'h4'}
            sx={{
              textAlign: 'center',
            }}
          >
            {t('sms-portal.no-messages')}
          </Typography>
        </Box>
      )}
    </>
  );
}

export default ConversationHistory;
