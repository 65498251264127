import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {Lead} from '../../../model/Lead';
import {FormBuilder} from '../../../form/FormBuilder';
import {FeatureName} from '../../../../paths';
import {Feature} from '../../../model/Feature';
import {difference} from '../../../form/utils';
import {BaseFormFieldSchemaDecorator} from '../../../form/logic/FormDecorator';
import {FormConfig} from '../../../form/FormConfig';
import {Task} from '../../../model/Task';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import {useAxiosContext} from '../../../context/AxiosContext';
import {useLoading} from '../../../context/LoadingContext';

interface TasksDialogProps {
  isOpen: boolean;
  handleClose: Function;
  lead: Lead;
}

function TasksDialog({isOpen, handleClose, lead}: TasksDialogProps) {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const {setLoading} = useLoading();
  const submitEl = useRef<HTMLButtonElement>(null);
  const [leadFeature, setLeadFeature] = useState<Feature | null>(null);
  const [initialValues, setInitialValues] = useState<any>();
  const [config, setConfig] = useState<FormConfig>();

  const [{error: hasPostTaskError, response: postTaskResponse, loading: postTaskLoading}, postTaskData] =
    useAxiosBFF<Task>({url: `/${FeatureName.TASKS}`, method: 'POST'}, {manual: true});

  const [{response: featureResponse}] = useAxiosBFF<Feature>({
    url: `/feature/${FeatureName.LEADS}`,
    method: 'GET',
  });

  useEffect(() => {
    if (featureResponse) {
      setLeadFeature(featureResponse.data);
    }
  }, [featureResponse]);

  useEffect(() => {
    setLoading(postTaskLoading || isOpen, 'TasksDialog');
  }, [postTaskLoading, isOpen]);

  useEffect(() => {
    if (postTaskResponse?.status === 201) {
      NotificationService.getInstance().sendNotification(postTaskResponse?.data?.['message'], NotificationType.SUCCESS);
    }
  }, [postTaskResponse]);

  useEffect(() => {
    if (hasPostTaskError) {
      NotificationService.getInstance().sendNotification(
        hasPostTaskError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasPostTaskError]);

  useEffect(() => {
    setInitialValues({
      feature_item_id: lead?.lead_id,
      feature_id: leadFeature?.feature_id,
    });
  }, [lead, leadFeature]);

  useEffect(() => {
    setConfig({
      decorators: [
        new BaseFormFieldSchemaDecorator((field) => {
          if (field.field_name && ['status'].includes(field.field_name.toLowerCase())) {
            field.hidden = true;
          }
          if (field.field_name && ['feature_item_id'].includes(field.field_name.toLowerCase())) {
            field.disabled = true;
          }
          if (field.field_name && ['feature_id'].includes(field.field_name.toLowerCase())) {
            field.disabled = true;
          }
        }),
      ],
    });
  }, []);

  function submitForm(data: any) {
    if (lead?.lead_id) {
      data = difference(data, lead);

      postTaskData({data}).then(() => {
        if (handleClose) {
          handleClose();
        }
      });
    }
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={isOpen}
        onClose={() => handleClose()}
        aria-labelledby="task-dialog-title"
      >
        <DialogTitle id="task-dialog-title">
          {t('leads.dialogs.tasks.create-task')}
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <FormBuilder formId={FeatureName.TASKS} onSubmit={submitForm} initialValues={initialValues} config={config}>
            <Button ref={submitEl} type="submit" sx={{display: 'none'}}>
              {t('leads.dialogs.tasks.create-task')}
            </Button>
          </FormBuilder>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            id={'task-btn'}
            onClick={() => {
              submitEl?.current?.click();
            }}
          >
            {t('leads.dialogs.tasks.create-task')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TasksDialog;
