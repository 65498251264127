import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiContext,
} from '@mui/x-data-grid';
import {useEffect} from 'react';
import AccessControl, {UserPermissions} from './AccessControl';
import {useUserSettingsContext} from '../../context/UserSettingsContext';

export default function GridToolbar(props: any) {
  const {userPermissions, csvOptions, ...others} = props;
  const apiRef = useGridApiContext();
  const state = apiRef.current.state;
  const {setPartialUserSettings} = useUserSettingsContext();

  useEffect(() => {
    if (state?.density?.value) {
      setPartialUserSettings({grid_density: state.density.value});
    }
  }, [state?.density]);

  return (
    <GridToolbarContainer>
      <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW_TABLE_COLUMNS_CONFIG]}>
        <GridToolbarColumnsButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
      </AccessControl>
      <GridToolbarFilterButton {...others} />
      <GridToolbarDensitySelector nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
      <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW_TABLE_EXPORT]}>
        <GridToolbarExport {...others} csvOptions={csvOptions} printOptions={{disableToolbarButton: true}} />
      </AccessControl>
    </GridToolbarContainer>
  );
}
