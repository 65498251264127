import React from 'react';
import SystemSettingsPageContainer from '../SystemSettingsPageContainer';
import {SystemSettingsPages} from '../model/SysPageInfo';
import CachePageComponent from '../../admincrm/cache/CachePageComponent';

export default function SystemSettingsCachePage() {
  return (
    <SystemSettingsPageContainer page={SystemSettingsPages.CACHE}>
      <CachePageComponent />
    </SystemSettingsPageContainer>
  );
}
