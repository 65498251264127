import {InputLabel, TextField} from '@mui/material';
import {buildSetValue, propsWithTestAttributes, SpartanInputFieldProps} from './model';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {ErrorMessage} from './components/ErrorMessage';
import {TimePicker} from '@mui/x-date-pickers-pro';
import {formatTime, isTime, parseTime} from '../../utils/DateUtils';
import {SpartanLabelWithTooltip} from './SpartanLabelWithTooltip';

export interface SpartanTimeFieldProps extends SpartanInputFieldProps {
  minTime?: any;
  maxTime?: any;
}

function toValue(pickerValue: any) {
  let value = pickerValue;
  if (value) {
    if (isTime(value)) {
      return formatTime(value);
    } else {
      return 'Invalid Time';
    }
  }
  return null;
}

export function SpartanTimeField(_props: SpartanTimeFieldProps) {
  const props = propsWithTestAttributes(_props);
  const {id, name, value, label, disabled, onBlur, onFocus, hasError, error, inputRef, inputProps, minTime, maxTime} =
    props;
  const setValue = buildSetValue(props);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        value={value ? parseTime(value) : null}
        disableIgnoringDatePartForTimeValidation={true}
        minTime={minTime}
        maxTime={maxTime}
        disabled={disabled}
        onChange={(time: any) => setValue(toValue(time))}
        inputRef={inputRef}
        renderInput={(params: any) => {
          const {
            error: errorToIgnore,
            helperText: helperTextToIgnore,
            label: labelToIgnore,
            inputProps: inputPropsParam,
            ...restOfParams
          } = params;
          const _inputProps = {...inputPropsParam, ...inputProps};
          return (
            <>
              <InputLabel error={hasError} htmlFor={id}>
                <SpartanLabelWithTooltip label={label} />
              </InputLabel>
              <TextField
                fullWidth
                id={id}
                name={name}
                {...restOfParams}
                error={hasError}
                inputProps={_inputProps}
                onBlur={onBlur}
                onFocus={onFocus}
              />
              <ErrorMessage hasError={hasError} error={error} />
            </>
          );
        }}
      />
    </LocalizationProvider>
  );
}
