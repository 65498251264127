import React from 'react';
import {Autocomplete, InputLabel, TextField} from '@mui/material';
import {
  buildSetValue,
  propsWithTestAttributes,
  SpartanFieldOption,
  SpartanFieldWithOptionProps,
  wrapInTooltipIfNecessary,
} from './model';
import {ErrorMessage} from './components/ErrorMessage';
import {isBlank, toControlledComponentSimpleValue} from '../logic/utils';
import {SpartanLabelWithTooltip} from './SpartanLabelWithTooltip';

function findOptionByValue(options: SpartanFieldOption[], value: any): SpartanFieldOption | undefined {
  return options.find((opt) => opt.value === value);
}

export function SpartanSelectField(_props: SpartanFieldWithOptionProps) {
  const props = propsWithTestAttributes(_props);
  const {
    id,
    name,
    value: initialValue,
    label,
    placeholder,
    disabled,
    onBlur,
    onFocus,
    hasError,
    error,
    options: initialOptions,
    inputRef,
    inputProps,
    isNestedLabel = false,
  } = props;
  const setValue = buildSetValue(props);

  let value = initialValue;
  let options = [...initialOptions];
  let selectedOption = findOptionByValue(options, value);

  if (!selectedOption) {
    const unselectedValue = toControlledComponentSimpleValue(undefined);

    // if value not blank, ignore incoming value and use blank instead (this should never happen)
    if (value !== unselectedValue) {
      console.warn(`Option '${value}' not found for field ${name}`);
      value = unselectedValue;
    }

    // if no selectable option, build additional blank option
    selectedOption = {value, label: ``};
    options = [...options, selectedOption];
  }

  return (
    <>
      {!isNestedLabel && (
        <InputLabel error={hasError} htmlFor={id}>
          <SpartanLabelWithTooltip label={label} />
        </InputLabel>
      )}
      <Autocomplete
        id={id}
        fullWidth={true}
        placeholder={placeholder || label}
        value={selectedOption}
        disabled={disabled}
        onChange={(event, option, reason, details) => {
          setValue(toControlledComponentSimpleValue(option?.value));
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        options={options}
        getOptionDisabled={(option) => (typeof option?.selectable !== 'undefined' ? !option?.selectable : false)}
        isOptionEqualToValue={(option: SpartanFieldOption) => option.value === value}
        getOptionLabel={(option: SpartanFieldOption) => option?.label || ''}
        filterSelectedOptions
        disableClearable={isBlank(value)}
        renderInput={(params) => {
          const {inputProps: inputPropsFromParams, ...restOfParams} = params;
          const _inputProps = {...inputPropsFromParams, ...inputProps};
          return wrapInTooltipIfNecessary(
            props,
            <TextField
              {...restOfParams}
              name={name}
              error={hasError}
              variant="outlined"
              inputRef={inputRef}
              inputProps={_inputProps}
              label={isNestedLabel ? label : undefined}
            />
          );
        }}
      />
      <ErrorMessage hasError={hasError} error={error} />
    </>
  );
}
