import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Grid, Typography, Button, Container} from '@mui/material';

function NotFound() {
  const {t} = useTranslation();
  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} sx={{marginTop: (theme) => theme.spacing(1)}}>
        <Grid item xs={12}>
          <Typography variant={'h2'}>{t('shared.page-not-found')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Link to="/dashboard">
            <Button id="cancel-btn">{t('shared.back-dashboard')}</Button>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NotFound;
