import React from 'react';
import PageContainer from '../PageContainer';
import SupportedOrgsPageComponent from './SupportedOrgsPageComponent';

export default function SupportedOrgsPage() {
  return (
    <PageContainer title={'Supported Orgs'}>
      <SupportedOrgsPageComponent />
    </PageContainer>
  );
}
