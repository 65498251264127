import {FormControl, Grid, InputLabel, MenuItem, Select} from '@mui/material';
import FlatPieChart from '../../../components/charts/FlatPieChart';
import SortedBarChart from '../../../components/charts/BarChart';
import React from 'react';

interface QuerySummaryProps {
  //property?: string;
}

function QuerySummary({...props}: QuerySummaryProps) {
  const [field, setField] = React.useState('Age');

  const handleChange = (event: any) => {
    setField(event.target.value as string);
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="fields">fields</InputLabel>
          <Select labelId="fields" id="fields" value={field} label="field" onChange={handleChange}>
            <MenuItem value={'Age'}>Age</MenuItem>
            <MenuItem value={'Status'}>Status</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FlatPieChart name={field} />
      </Grid>
      <Grid item xs={12} md={8}>
        <SortedBarChart name={field} />
      </Grid>
    </Grid>
  );
}

export default QuerySummary;
