import {createContext, PropsWithChildren, useContext} from 'react';
import {useEntities} from '../hooks/useEntities';
import {compareByIndex} from '../model/FormBaseEntity';
import {FormField} from '../model/FormField';
import {useImportExport} from '../hooks/useImportExport';

type Entity = FormField;

const Context = createContext<EntitiesContext | null>(null);

interface Props extends PropsWithChildren {
  sectionId: string;
}

export const FieldsProvider = ({sectionId, children}: Props) => {
  const {entities, loading, locked, handleCreate, handlePatch, handleDelete} = useEntities<Entity>(
    `sections/${sectionId}/fields`,
    'fields',
    compareByIndex
  );
  const {exportEntity, exporting, copyEntity, copying} = useImportExport();
  async function handleExport(id: string) {
    await exportEntity('fields', id);
  }
  async function handleCopy(id: string) {
    await copyEntity('fields', id);
  }
  return (
    <Context.Provider
      value={{
        fields: entities,
        loading,
        locked: locked || exporting || copying,
        handleCreate,
        handlePatch,
        handleDelete,
        handleExport,
        handleCopy,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export interface EntitiesContext {
  fields: Entity[] | null;
  handleCreate: (data: Entity) => Promise<void>;
  handlePatch: (id: string, data: Partial<Entity>) => Promise<void>;
  handleDelete: (id: string) => Promise<void>;
  handleExport: (id: string) => Promise<void>;
  handleCopy: (id: string) => Promise<void>;
  loading: boolean;
  locked: boolean;
}

export function useFieldsContext(): EntitiesContext {
  const value = useContext(Context);
  if (!value) throw Error('invalid context');
  return value;
}
