import {useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {useTranslation} from 'react-i18next';
import {Support} from '../../model/Settings';
import {Auth0User, getUserEmail} from '../../model/Auth0User';
import {Email} from '../../model/Email';
import {AxiosResponse} from 'axios';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {HttpMethods} from '../../model/HttpMethods';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

interface EmailDialogProps {
  isOpen: boolean;
  handleClose: Function;
  support: Support;
}

function EmailDialog({isOpen, handleClose, support}: EmailDialogProps) {
  const {useAxiosBFF} = useAxiosContext();
  const {t} = useTranslation();
  const auth0 = useAuth0<Auth0User>();
  const [subject, setSubject] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
  const {setLoading} = useLoading();

  const [{error: hasSendEmailError, loading: isSendEmailLoading}, sendEmail] = useAxiosBFF(
    {
      url: 'emails/',
      method: HttpMethods.POST,
    },
    {manual: true}
  );

  useEffect(() => {
    if (hasSendEmailError) {
      NotificationService.getInstance().sendNotification(hasSendEmailError?.message, NotificationType.ERROR);
    }
  }, [hasSendEmailError]);

  useEffect(() => {
    setLoading(isSendEmailLoading, 'EmailDialog');
  }, [isSendEmailLoading]);

  useEffect(() => {
    if (text && subject) {
      setDisabledSubmit(false);
    } else {
      setDisabledSubmit(true);
    }
  }, [subject, text]);

  function handleOnSubmit() {
    const data: Email = {
      destination: support.contact_key,
      source: support.contact_key,
      subject: `crm-email_support - ${subject}`,
      text: `User ${getUserEmail(auth0.user) ?? ''}: ${text}`,
    };

    sendEmail({data: data}).then((response: AxiosResponse) => {
      if (response?.status === 200) {
        NotificationService.getInstance().sendNotification(response?.data?.message, NotificationType.SUCCESS);
        handleClose();
      }
    });

    setText('');
    setSubject('');
  }
  function handleOnClose() {
    setText('');
    setSubject('');
    handleClose();
  }
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={isOpen}
        onClose={() => handleOnClose()}
        aria-labelledby="email-dialog-title"
        aria-describedby="support-dialog-emailInfo support-dialog-secondInfo support-dialog-subject support-dialog-msg"
        sx={{textAlign: 'center'}}
      >
        <HelpOutlineIcon color="primary" sx={{fontSize: 70, margin: 'auto'}} />
        <DialogTitle id="email-dialog-title">{t('supportDialogs.headLine')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="support-dialog-emailInfo">
            {t('supportDialogs.emailInfo') + support.contact_key}
          </DialogContentText>

          <DialogContentText
            id="support-dialog-secondInfo"
            sx={{width: '100%', padding: (theme) => ` ${theme.spacing(2)}`}}
          >
            {t('supportDialogs.secondInfo')}
          </DialogContentText>
          <DialogContentText id="support-dialog-thirdInfo">{t('supportDialogs.thirdInfo')}</DialogContentText>
          <DialogContentText id="support-dialog-subject">
            <TextField
              required
              id="support-subject-box"
              label={t('supportDialogs.subjectBox')}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              sx={{width: '100%', padding: (theme) => ` ${theme.spacing(1)}`}}
            />
          </DialogContentText>
          <DialogContentText id="support-dialog-msg">
            <TextField
              required
              id="support-message-box"
              label={t('supportDialogs.messageBox')}
              multiline
              rows={4}
              value={text}
              onChange={(e) => setText(e.target.value)}
              sx={{width: '100%', padding: (theme) => ` ${theme.spacing(1)}`}}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center'}}>
          <Button onClick={() => handleOnClose()} id={'cancel-btn'}>
            {t('supportDialogs.cancelButton')}
          </Button>
          <Button color={'primary'} onClick={() => handleOnSubmit()} id={'email-btn'} disabled={disabledSubmit}>
            {t('supportDialogs.emailButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default EmailDialog;
