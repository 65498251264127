import {Grid, IconButton, MenuItem, Select, TextField, Tooltip} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useLoading} from '../../../context/LoadingContext';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import RestoreIcon from '@mui/icons-material/Restore';
import BusinessIcon from '@mui/icons-material/Business';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import {useAuth0} from '@auth0/auth0-react';
import Typography from '@mui/material/Typography';

const ENVS = ['development', 'testing', 'staging', 'production'];

interface SupportedOrg {
  env_name: string;
  org_name: string;
  project_id?: string | null;
  instance_id: string;
  database_id?: string | null;
  original?: SupportedOrg;
}

function toSeverity(env: string) {
  return env === 'production'
    ? 'error'
    : env === 'staging'
    ? 'warning'
    : env === 'testing'
    ? 'info'
    : env === 'development'
    ? 'success'
    : undefined;
}

const SUPPORTED_ORGS_ENDPOINT = '/api/admincrm/v1/supported-orgs';

export default function SupportedOrgsPageComponent() {
  const [params, setParams] = useState<SupportedOrg[]>([]);
  const [newParam, setNewParam] = useState<SupportedOrg>({env_name: '', org_name: '', instance_id: ''});
  const {setLoading} = useLoading();
  const {getAccessTokenSilently} = useAuth0();

  function upsert(param: SupportedOrg) {
    setLoading(true, 'SupportedOrgsPage/upsert');
    const exists = params.find((p) => p.env_name === param.env_name && p.org_name === param.org_name) !== undefined;
    getAccessTokenSilently()
      .then((token) =>
        fetch(SUPPORTED_ORGS_ENDPOINT, {
          method: exists ? 'PATCH' : 'POST',
          body: JSON.stringify(param),
          headers: {authorization: `bearer ${token}`, 'content-type': 'application/json'},
        })
      )
      .then((resp) => {
        if (!resp.ok) throw Error('upsert failed');
        get();
        setNewParam({env_name: '', org_name: '', instance_id: ''});
      })
      .catch((error) => {
        NotificationService.getInstance().sendNotification(error.message, NotificationType.ERROR);
      })
      .finally(() => setLoading(false, 'SupportedOrgsPage/upsert'));
  }

  function remove(param: SupportedOrg) {
    setLoading(true, 'SupportedOrgsPage/remove');
    getAccessTokenSilently()
      .then((token) =>
        fetch(`${SUPPORTED_ORGS_ENDPOINT}/${param.env_name}:${param.org_name}`, {
          method: 'DELETE',
          body: JSON.stringify(param),
          headers: {authorization: `bearer ${token}`},
        })
      )
      .then((resp) => {
        if (!resp.ok) throw Error('upsert failed');
        get();
        setNewParam({env_name: '', org_name: '', instance_id: ''});
      })
      .catch((error) => {
        NotificationService.getInstance().sendNotification(error.message, NotificationType.ERROR);
      })
      .finally(() => setLoading(false, 'SupportedOrgsPage/remove'));
  }

  function get() {
    setLoading(true, 'SupportedOrgsPage/GET');
    getAccessTokenSilently()
      .then((token) =>
        fetch(SUPPORTED_ORGS_ENDPOINT, {
          method: 'GET',
          headers: {authorization: `bearer ${token}`},
        })
      )
      .then((resp) => {
        if (!resp.ok) throw Error('get failed');
        return resp.json();
      })
      .then((data) => setParams(data.results))
      .catch((error) => {
        NotificationService.getInstance().sendNotification(error.message, NotificationType.ERROR);
      })
      .finally(() => setLoading(false, 'SupportedOrgsPage/GET'));
  }

  useEffect(() => {
    get();
  }, []);

  function saveParam(param: SupportedOrg) {
    if (param.env_name && param.org_name && param.instance_id) {
      upsert(param);
    }
  }

  function removeParam(param: SupportedOrg) {
    if (param.env_name && param.org_name) {
      const ok = window.confirm('Attention! This action cannot be undone. Continue?');
      if (ok) {
        remove(param);
      }
    }
  }

  function updateParam(oldParam: SupportedOrg, newParam: SupportedOrg) {
    if (oldParam && newParam.env_name && newParam.org_name && newParam.instance_id) {
      const copy = [...params];
      copy.splice(
        copy.findIndex((p) => p.env_name === oldParam.env_name && p.org_name === oldParam.org_name),
        1,
        {...newParam, original: oldParam.original ?? oldParam}
      );
      console.log(copy);
      setParams(copy);
    }
  }

  function resetParam(param: SupportedOrg) {
    if (param.original) {
      const copy = [...params];
      copy.splice(
        copy.findIndex((p) => p.env_name === param.env_name && p.org_name === param.org_name),
        1,
        param.original
      );
      console.log(copy);
      setParams(copy);
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <BusinessIcon sx={{color: 'transparent'}}></BusinessIcon>
        </Grid>
        <Grid item xs={2}>
          <Typography>environment</Typography>
        </Grid>{' '}
        <Grid item xs={2}>
          <Typography>organization</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>instance</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>database</Typography>
        </Grid>
      </Grid>
      {params.map((p, index) => (
        <Grid container spacing={1} key={p.env_name + ':' + p.org_name}>
          <Grid item>
            <BusinessIcon color={toSeverity(p.env_name)}></BusinessIcon>
          </Grid>
          <Grid item xs={2}>
            <TextField fullWidth disabled value={p.env_name}></TextField>
          </Grid>{' '}
          <Grid item xs={2}>
            <TextField fullWidth disabled value={p.org_name}></TextField>
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              value={p.instance_id}
              onChange={(event) => updateParam(p, {...p, instance_id: event.target.value})}
            ></TextField>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title={'leave blank to adopt naming convention `{env}_{org}`'} placement="top">
              <TextField
                fullWidth
                value={p.database_id ?? ''}
                onChange={(event) => updateParam(p, {...p, database_id: event.target.value})}
              ></TextField>
            </Tooltip>
          </Grid>
          {!p.original && (
            <Grid item>
              <Tooltip title={'delete'} placement="top">
                <IconButton onClick={() => removeParam(p)} edge="end">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {p.original && (
            <Grid item>
              <Tooltip title={'save'} placement="top">
                <IconButton onClick={() => saveParam(p)} edge="end">
                  <UploadIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {p.original && (
            <Grid item>
              <Tooltip title={'reset'} placement="top">
                <IconButton onClick={() => resetParam(p)} edge="end">
                  <RestoreIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      ))}
      <Grid container spacing={1}>
        <Grid item>
          <BusinessIcon color={toSeverity(newParam.env_name)}></BusinessIcon>
        </Grid>
        <Grid item xs={2}>
          <Select
            fullWidth
            value={newParam.env_name}
            onChange={(event) => setNewParam({...newParam, env_name: event.target.value})}
          >
            <MenuItem key={''} value={''}>
              {'-'}
            </MenuItem>
            {ENVS.map((env) => (
              <MenuItem key={env} value={env}>
                {env}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            value={newParam.org_name}
            onChange={(event) => setNewParam({...newParam, org_name: event.target.value})}
            onKeyUp={(event) => event.key === 'Enter' && saveParam(newParam)}
          ></TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            value={newParam.instance_id}
            onChange={(event) => setNewParam({...newParam, instance_id: event.target.value})}
            onKeyUp={(event) => event.key === 'Enter' && saveParam(newParam)}
          ></TextField>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title={'leave blank to adopt naming convention `{env}_{org}`'} placement="top">
            <TextField
              fullWidth
              value={newParam.database_id}
              onChange={(event) => setNewParam({...newParam, database_id: event.target.value})}
              onKeyUp={(event) => event.key === 'Enter' && saveParam(newParam)}
            ></TextField>
          </Tooltip>
        </Grid>
        <Grid item>
          <IconButton
            disabled={!newParam.env_name || !newParam.org_name || !newParam.instance_id}
            onClick={() => saveParam(newParam)}
            edge="end"
          >
            <Tooltip title={'save'} placement="top">
              <UploadIcon />
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}
