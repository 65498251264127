import React from 'react';
import SystemSettingsPageContainer from '../SystemSettingsPageContainer';
import {SystemSettingsPages} from '../model/SysPageInfo';
import FeaturePermissionPageComponent from './FeaturePermissionPageComponent';

export default function SystemSettingsFeaturePermissionPage() {
  return (
    <SystemSettingsPageContainer page={SystemSettingsPages.ROLE_PERMISSIONS}>
      <FeaturePermissionPageComponent />
    </SystemSettingsPageContainer>
  );
}
