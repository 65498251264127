import React, {useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {Auth0User} from '../../../model/Auth0User';
import {useLoading} from '../../../context/LoadingContext';
import {Grid, IconButton, InputAdornment, TextField, Tooltip} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import NotificationService, {NotificationType} from '../../../services/NotificationService';

interface AvailablePermissionsProps {
  permissions: string[];
  refreshPermissions: () => Promise<void>;
}

export function AvailablePermissions({permissions, refreshPermissions}: AvailablePermissionsProps) {
  const [permission, setPermission] = useState<string>('');
  const auth0 = useAuth0<Auth0User>();
  const {setLoading} = useLoading();

  function sendError(data: any) {
    const message = data?.message ?? data?.error_data?.message ?? 'error';
    NotificationService.getInstance().sendNotification(message, NotificationType.ERROR);
  }

  function onSaveClicked(permission: string) {
    setLoading(true, `user-permissions-available/put/${permission}`);
    auth0
      .getAccessTokenSilently()
      .then((token) =>
        fetch(`/api/user/v2/user-permissions-available/${permission}`, {
          method: 'PUT',
          headers: {authorization: `bearer ${token}`},
        })
      )
      .then((response) => refreshPermissions())
      .then(() => setPermission(''))
      .finally(() => setLoading(false, `user-permissions-available/put/${permission}`));
  }

  function onRemoveClicked(permission: string) {
    setLoading(true, `user-permissions-available/delete/${permission}`);
    auth0
      .getAccessTokenSilently()
      .then((token) =>
        fetch(`/api/user/v2/user-permissions-available/${permission}`, {
          method: 'DELETE',
          headers: {authorization: `bearer ${token}`},
        })
      )
      .then((response) => (response.ok ? refreshPermissions() : response.json().then(sendError)))
      .finally(() => setLoading(false, `user-permissions-available/delete/${permission}`));
  }

  return (
    <>
      <Grid container spacing={2} xs={12}>
        {permissions.map((p, index) => (
          <Grid item xs={12} key={'permission-' + p}>
            <TextField
              style={{width: '100%', maxWidth: 500}}
              value={p}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={'remove'} placement="top">
                      <IconButton onClick={() => onRemoveClicked(p)} edge="end">
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Grid>
        ))}
        <Grid item xs={12} key={'permission-to-add'}>
          <TextField
            style={{width: '100%', maxWidth: 500}}
            value={permission}
            onChange={(event) => setPermission(event.target.value)}
            onKeyUp={(event) => event.key === 'Enter' && onSaveClicked(permission)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" variant={'filled'}>
                  <Tooltip title={'save'} placement="top">
                    <IconButton disabled={!permission} onClick={() => onSaveClicked(permission)} edge="end">
                      <UploadIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
      </Grid>
    </>
  );
}
