import React from 'react';
import {Chip} from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';

export default function RenderCount({label, style}: any) {
  const renders = React.useRef(0);
  renders.current++;
  const text = label ? `${renders.current} ${label}` : renders.current;
  return <Chip icon={<SpeedIcon />} label={text} style={{position: 'absolute', ...style}} />;
}
