import React from 'react';
import ListFeatureSectionComponent from './ListFeatureSectionComponent';
import SingleLayoutComponent from './shared/SingleLayoutComponent';
import FeatureEditorUpdate from './editors/FeatureEditorUpdate';
import {FormFeature} from '../model/FormFeature';
import {FeatureProvider} from '../contexts/FeatureContext';
import ListFeatureMenuItemComponent from './ListFeatureMenuItemComponent';

interface Props {
  feature: FormFeature;
}

export default function SingleFeatureComponent({feature}: Props) {
  const editor = <FeatureEditorUpdate feature={feature} />;
  const label = feature.name ?? feature.feature_id;
  return (
    <FeatureProvider feature={feature}>
      <SingleLayoutComponent label={label} editor={editor} disabled={feature.disabled}>
        {feature.type === 'menu_item' ? (
          <ListFeatureMenuItemComponent featureId={feature.feature_id} />
        ) : (
          <ListFeatureSectionComponent featureId={feature.feature_id} />
        )}
      </SingleLayoutComponent>
    </FeatureProvider>
  );
}
