import React, {PropsWithChildren, useState} from 'react';
import {Button, Grid, Typography} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Props extends PropsWithChildren {
  label: string;
}

export default function ExpandableWrapper({label, children}: Props) {
  const [expand, setExpand] = useState(false);

  const Icon = expand ? KeyboardArrowDownIcon : KeyboardArrowRightIcon;

  return (
    <Grid container spacing={1} rowSpacing={1}>
      <Grid item>
        <Button disabled={!children} onClick={() => setExpand((prev) => !prev)} variant={'text'}>
          <Icon />
        </Button>
      </Grid>
      <Grid item>
        <Typography variant={'h3'} sx={{margin: 0.8}}>
          {label}
        </Typography>
      </Grid>
      {expand && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
    </Grid>
  );
}
