import i18n from 'i18next';
import translationEN from './en/translation.json';
import translationES from './es/translation.json';
import {initReactI18next} from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LocalStorageService from '../app/services/LocalStorageService';
import {env} from '../env';

// See https://www.i18next.com/overview/configuration-options

if (env.IS_TEST) {
  i18n.use(initReactI18next).init({lng: 'en', resources: {en: {}}});
} else {
  i18n
    .use(HttpBackend)
    .use(initReactI18next)
    .init({
      debug: false, // if true, it will write to console when a key is missing
      lng: LocalStorageService.getInstance().get('lng'),
      fallbackLng: 'en',
      backend: {
        loadPath: '/api/i18n/locales/by-origin/{{lng}}/{{ns}}.json',
        crossDomain: true,
        withCredentials: false,
      },
      preload: ['en', 'es'],
      load: 'languageOnly',
      partialBundledLanguages: true,
    })
    .then(() => {
      i18n.addResourceBundle('en', 'translation', translationEN);
      i18n.addResourceBundle('es', 'translation', translationES);
    });
}

export default i18n;
