import {Grid, Typography} from '@mui/material';
import PermissionRole from './PermissionRole';
import {PermissionFeatureProps} from './models/models';

function PermissionResource({resourceId, label, roles, configActions}: PermissionFeatureProps) {
  return (
    <>
      {label && <Typography>{label}</Typography>}
      <Grid container xs={12}>
        <Grid container item xs={11}>
          <Grid container item direction="column" alignItems="center" xs={1}>
            {configActions?.map((column) => (
              <Grid key={`column-${resourceId}-${column}`} item xs={4}>
                <Typography>{column}</Typography>
              </Grid>
            ))}
          </Grid>
          {roles.map((role) => (
            <PermissionRole roleId={role.roleId} actions={role.actions} label={role.label} resourceId={resourceId} />
          ))}
        </Grid>
      </Grid>
    </>
  );
}

export default PermissionResource;
