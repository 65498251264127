import {PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import {Dialog, DialogActions, DialogTitle, IconButton, Button} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ConfirmationDialogProps {
  headline?: string;
  isDialogOpen: boolean;
  handleClose: Function;
  handleConfirm?: Function;
  confirmationMessage?: string;
}

function SpartanDialog({
  headline,
  isDialogOpen,
  handleClose,
  handleConfirm,
  confirmationMessage,
  children,
}: PropsWithChildren<ConfirmationDialogProps>) {
  const {t} = useTranslation();

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={() => handleClose()}
        maxWidth="md"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">
          {headline}
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            id="confirmation-dialog-close-btn"
            sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {children}
        <DialogActions>
          <Button onClick={() => handleClose()} id="confirmation-dialog-cancel-btn" color="secondary">
            {t('shared.cancel')}
          </Button>
          {handleConfirm && (
            <Button
              onClick={() => {
                handleConfirm();
                handleClose();
              }}
              id="confirmation-dialog-action-btn"
            >
              {confirmationMessage}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
export default SpartanDialog;
