import {InputLabel, Typography} from '@mui/material';
import {SpartanLabelWithTooltip} from './SpartanLabelWithTooltip';

interface SpartanLabelFieldProps {
  label: string;
  value: string;
}

export function SpartanLabelField(props: SpartanLabelFieldProps) {
  const {label, value} = props;

  return (
    <>
      <InputLabel>
        <SpartanLabelWithTooltip label={label} />
      </InputLabel>
      <Typography variant="body1">{Array.isArray(value) ? value?.join(', ') : value.toString()}</Typography>
    </>
  );
}
