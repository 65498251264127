import {Card, CardContent, Grid, InputLabel} from '@mui/material';
import {useEffect, useState} from 'react';
import {buildSetValue, propsWithTestAttributes, SpartanTextFieldProps} from './model';
import {IFileWithMeta, StatusValue} from 'react-dropzone-uploader';
import {FileExtensions} from '../../model/FileExtensions';
import {HttpMethods} from '../../model/HttpMethods';
import {ResponseFile} from '../../model/Files';
import {DropzoneWrapper} from '../components/DropzoneWrapper';
import {FeatureName} from '../../../paths';
import {StringKeyObject} from '../state/FormState';
import {usePermissions, UsePermissionState} from '../../pages/UsePermissions';
import {MetaFile} from '../../model/MetaFiles';
import {useFiles} from '../../UseFiles';

interface SpartanFileUploaderProps {
  fieldProps: SpartanTextFieldProps;
  maxFiles?: number;
  acceptedExtensions?: Array<FileExtensions>;
  method?: HttpMethods;
  filesToShow?: Array<ResponseFile>;
  importFiles?: boolean;
  formValues?: StringKeyObject<any>;
}

export function SpartanFileUploader({fieldProps, formValues}: SpartanFileUploaderProps) {
  const props = propsWithTestAttributes(fieldProps);
  const {id, label, hasError} = props;
  const setValue = buildSetValue(props);
  const [files, setFiles] = useState<MetaFile[] | []>([]);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.CAMPAIGNS);
  const [updatingField, setUpdatingField] = useState<boolean>(false);
  const {getMetaFiles} = useFiles();

  function setFilesToShow() {
    setUpdatingField(true);
    const formFileInfo = formValues?.filename?.split('/');
    if (getMetaFiles) {
      getMetaFiles(`${formFileInfo[2]}/${formFileInfo[3]}/`).then((_files: MetaFile[]) => {
        setFiles(_files);
      });
    }
  }

  useEffect(() => {
    if (formValues?.filename) {
      setFilesToShow();
    }
  }, []);

  useEffect(() => {
    if (typeof formValues?.filename === 'string') {
      setFilesToShow();
    }
  }, [formValues?.filename]);

  function onChangeFile(file: IFileWithMeta, statusValue: StatusValue, allFiles: IFileWithMeta[]) {
    if (statusValue === 'done') {
      setValue(file?.file);
    } else if (statusValue === 'removed') {
      setValue(null);
    }
  }

  return (
    <Grid container={true} justifyContent={'center'}>
      <Card sx={{maxWidth: '50vw', width: '100%'}}>
        <CardContent>
          <InputLabel error={hasError} htmlFor={id}>
            {label}
          </InputLabel>
          <DropzoneWrapper
            acceptedExtensions={[FileExtensions.TSV]}
            maxFiles={1}
            filesToShow={files}
            disableUploadButton
            userPermissions={userPermissions}
            disableUploader={updatingField}
            onChangeFunction={onChangeFile}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
