import {
  FormFieldOption,
  FormFieldSchema,
  FormFieldSize,
  FormFieldType,
  FormSchema,
  FormSectionSchema,
  FormSectionType,
  LinkedFieldSchema,
} from '../../logic/FormSchema';
import {
  NextgenFormFieldSchema,
  NextgenFormFieldSelectOption,
  NextgenFormLinkedFieldSchema,
  NextgenFormSchema,
  NextgenFormSectionSchema,
} from './NextgenFormSchema';
import {parseRules} from './parseRules';
import {parseTriggers} from './parseTriggers';

function toFieldSize(val: any): FormFieldSize {
  let result: FormFieldSize = val as FormFieldSize;
  if (!result) {
    return FormFieldSize.SM;
    // TODO throw Error
    // throw Error(`Unknown FormFieldSize '${val}'`);
  }
  return result;
}

function toFieldType(val: any): FormFieldType {
  let result: FormFieldType = val as FormFieldType;
  if (!result) {
    throw Error(`Unknown FormFieldType '${val}'`);
  }
  return result;
}

function toSectionType(val: any): FormSectionType {
  let result: FormSectionType = val as FormSectionType;
  if (!result) {
    throw Error(`Unknown FormSectionType '${val}'`);
  }
  return result;
}

function toLinkedField(linkedField: NextgenFormLinkedFieldSchema): LinkedFieldSchema {
  return {
    field_name: linkedField.field_name,
    link_key: linkedField.link_key,
  };
}

function toOption(selectOption: NextgenFormFieldSelectOption): FormFieldOption {
  return {
    label: selectOption.label,
    value: selectOption.value,
    selectable: selectOption.per_modify,
  };
}

export function parseNextgenFormSchema(nextgenFormSchema: NextgenFormSchema): FormSchema {
  const triggers = parseTriggers(nextgenFormSchema?.triggers);

  function toField(nextgenFormFieldSchema: NextgenFormFieldSchema): FormFieldSchema {
    const parsedRules = parseRules(nextgenFormFieldSchema);
    return {
      field_id: nextgenFormFieldSchema.field_id,
      field_name: nextgenFormFieldSchema.field_name,
      field_type: toFieldType(nextgenFormFieldSchema.field_type),
      size: toFieldSize(nextgenFormFieldSchema.size),
      label: nextgenFormFieldSchema.label,
      label_template: nextgenFormFieldSchema.label_template,
      index: nextgenFormFieldSchema.index,
      pdf_label: nextgenFormFieldSchema.pdf_label,
      in_pdf: nextgenFormFieldSchema.in_pdf,
      searchable: nextgenFormFieldSchema.searchable,
      default_value: nextgenFormFieldSchema.default_value,
      options: nextgenFormFieldSchema.select_options?.map(toOption),
      linked_fields: nextgenFormFieldSchema.linked_fields?.map(toLinkedField),
      triggers: nextgenFormFieldSchema.triggers ?? triggers.get(nextgenFormFieldSchema.field_name),
      hidden: nextgenFormFieldSchema.hidden ?? parsedRules.hidden,
      disabled: nextgenFormFieldSchema.disabled ?? parsedRules.disabled,
      required: nextgenFormFieldSchema.required ?? parsedRules.required,
      validations: nextgenFormFieldSchema.validations ?? parsedRules.validations,
      dynamic_type: nextgenFormFieldSchema.dynamic_type,
      dynamic_source: nextgenFormFieldSchema.dynamic_source,
      metadata: nextgenFormFieldSchema.metadata,
    };
  }

  function toSection(nextgenFormSectionSchema: NextgenFormSectionSchema): FormSectionSchema {
    const parsedRules = parseRules(nextgenFormSectionSchema);
    return {
      section_id: nextgenFormSectionSchema.section_id,
      section_name: nextgenFormSectionSchema.section_name,
      section_label: nextgenFormSectionSchema.section_label,
      section_type: toSectionType(nextgenFormSectionSchema.section_type),
      index: nextgenFormSectionSchema.index,
      pdf_label: nextgenFormSectionSchema.pdf_label,
      fields: nextgenFormSectionSchema.fields?.map(toField),
      sections: nextgenFormSectionSchema.sections?.map(toSection),
      hidden: nextgenFormSectionSchema.hidden ?? parsedRules.hidden,
    };
  }

  return {
    name: nextgenFormSchema.name,
    sections: nextgenFormSchema?.sections?.map(toSection),
    dependencies: nextgenFormSchema.dependencies,
  };
}
