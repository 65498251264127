import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {chunk} from 'lodash';
import {
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {ChatInformation} from '../../../model/CreativeAssistant';
import {FeatureName} from '../../../../paths';
import {DefaultResponse} from '../../../services/model/DefaultResponse';
import {HttpMethods} from '../../../model/HttpMethods';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {formatDateTime} from '../../../utils/DateUtils';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import {useAxiosContext} from '../../../context/AxiosContext';
import {useLoading} from '../../../context/LoadingContext';

interface ChatHistoryProps {
  chatsInformation: Array<ChatInformation>;
  selectedChat: ChatInformation | null;
  onChatClick: Function;
  onChatDelete: Function;
  onChatReset: Function;
  onChatNameEdit: Function;
}
const SIZE = 15;

function ChatHistory({
  chatsInformation,
  selectedChat,
  onChatClick,
  onChatDelete,
  onChatReset,
  onChatNameEdit,
}: ChatHistoryProps) {
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const {useAxiosBFF} = useAxiosContext();
  const [chatForActions, setChatForActions] = useState<null | ChatInformation>(null);
  const [moreActionsEl, setMoreActionsEl] = useState<null | HTMLElement>(null);
  const [isEditingName, setIsEditingName] = useState<boolean>(false);
  const [editNameValue, setEditNameValue] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const theme = useTheme();
  const isMobileViewActive = useMediaQuery(theme.breakpoints.down('md'));

  const chatHistoryPages: Array<Array<ChatInformation>> = chunk(chatsInformation, SIZE);
  const pagesAmount: number = Math.ceil(chatHistoryPages.length);

  const updatePageAfterDelete = (chatToDelete: ChatInformation) => {
    if (selectedChat && chatToDelete && chatsInformation.length > 1) {
      let maxIndexCorrected: number = chatsInformation.length - 2;
      let maxPageCorrected: number = Math.floor(maxIndexCorrected / SIZE);
      if (selectedChat.assistant_chat_id === chatToDelete.assistant_chat_id) {
        setPage(0);
      } else if (page > maxPageCorrected) {
        setPage(maxPageCorrected);
      }
    }
  };

  const [{response: deleteChatNameResponse, error: hasDeleteChatError, loading: isDeleteChatLoading}, deleteChat] =
    useAxiosBFF<DefaultResponse>(
      {
        url: `${FeatureName.CREATIVE_ASSISTANT}/chats`,
        method: HttpMethods.DELETE,
      },
      {manual: true}
    );

  useEffect(() => {
    if (hasDeleteChatError) {
      NotificationService.getInstance().sendNotification(
        t('creative-assistant.chat-delete-error'),
        NotificationType.ERROR
      );
    }
  }, [hasDeleteChatError]);

  useEffect(() => {
    if (deleteChatNameResponse && deleteChatNameResponse?.status === 204 && chatForActions) {
      NotificationService.getInstance().sendNotification(
        t('creative-assistant.chat-delete-success'),
        NotificationType.SUCCESS
      );
      let chatToDelete: ChatInformation = chatForActions;
      handleChatMoreActionsClose();
      setChatForActions(null);
      updatePageAfterDelete(chatToDelete);
      onChatDelete(chatToDelete);
    }
  }, [deleteChatNameResponse]);

  const [{response: editChatNameResponse, error: hasEditChatNameError, loading: isEditChatNameLoading}, editChatName] =
    useAxiosBFF<DefaultResponse>(
      {
        url: `${FeatureName.CREATIVE_ASSISTANT}/chats`,
        method: HttpMethods.PATCH,
      },
      {manual: true}
    );

  useEffect(() => {
    if (hasEditChatNameError) {
      NotificationService.getInstance().sendNotification(
        t('creative-assistant.chat-edit-error'),
        NotificationType.ERROR
      );
    }
  }, [hasEditChatNameError]);

  useEffect(() => {
    const loading = isDeleteChatLoading || isEditChatNameLoading;
    setLoading(loading, 'ChatHistory');
  }, [isDeleteChatLoading, isEditChatNameLoading]);

  useEffect(() => {
    if (editChatNameResponse?.status === 204) {
      NotificationService.getInstance().sendNotification(
        t('creative-assistant.chat-edit-success'),
        NotificationType.SUCCESS
      );
      setIsEditingName(false);
      handleChatMoreActionsClose();
      onChatNameEdit(chatForActions, editNameValue);
    }
  }, [editChatNameResponse]);

  const handleChatMoreActionsClose = () => {
    setMoreActionsEl(null);
  };

  const handleEditNamePressed = () => {
    setIsEditingName(true);
    handleChatMoreActionsClose();
  };

  const handleChatDelete = () => {
    if (chatForActions?.assistant_chat_id) {
      handleChatMoreActionsClose();
      deleteChat({
        params: {assistant_chat_id: chatForActions.assistant_chat_id},
      });
    }
  };

  const handleOnEditNameChange = (e: any) => {
    setEditNameValue(e.target.value);
  };

  const handleOnEditKeyDownPress = (e: any) => {
    if (e.key === 'Enter') {
      handleChatNameEdit();
    } else if (e.key === 'Escape') {
      setIsEditingName(false);
    }
  };

  const handleChatNameEdit = () => {
    if (chatForActions?.assistant_chat_id && editNameValue) {
      editChatName({
        params: {assistant_chat_id: chatForActions.assistant_chat_id, chat_name: editNameValue},
      });
    }
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  const handleChatClick = (chat: ChatInformation) => {
    if (chat.assistant_chat_id !== selectedChat?.assistant_chat_id && !isEditingName) {
      setIsEditingName(false);
      handleChatMoreActionsClose();
      onChatClick(chat);
    }
  };

  return (
    <Grid container item spacing={3} sx={{height: '100%'}}>
      <Grid item sm={12} display={'flex'} justifyContent={'center'} alignContent={'baseline'}>
        <Pagination
          count={pagesAmount}
          variant="outlined"
          color="primary"
          showFirstButton
          showLastButton
          size={'large'}
          onChange={handleChangePage}
          page={page + 1}
        />
      </Grid>
      <Grid item sm={12} sx={{...(isMobileViewActive ? {width: '100%'} : {})}}>
        <Grid item display={'flex'} justifyContent={'center'}>
          <Button
            id={'reset-chat-btn'}
            onClick={() => onChatReset()}
            sx={{
              mb: '1rem',
              color: (theme) => theme.palette.primary.contrastText,
            }}
          >
            {t('creative-assistant.reset-chat-button')}
          </Button>
        </Grid>

        <List sx={{maxHeight: '500px', overflow: 'auto'}}>
          {chatHistoryPages ? (
            chatHistoryPages[page]?.map((chatInfo: ChatInformation, index: number) => {
              const nameToDisplay = chatInfo?.chat_name
                ? chatInfo?.chat_name
                : chatInfo?.first_message.length < 25
                ? `${chatInfo?.first_message.slice(0, 25)}`
                : `${chatInfo?.first_message.slice(0, 25)} ...`;

              return (
                <ListItem
                  button
                  divider
                  key={'virtual-chat-list-row' + index}
                  sx={{borderBottom: `1px solid ${theme.palette.primary.main}`}}
                  onClick={() => {
                    handleChatClick(chatInfo);
                  }}
                >
                  <ListItemIcon>
                    <ChatBubbleOutlineIcon
                      sx={{
                        color:
                          selectedChat?.assistant_chat_id === chatInfo.assistant_chat_id
                            ? theme.palette.primary.main
                            : theme.palette.secondary.dark,
                      }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    primary={
                      chatInfo.assistant_chat_id === chatForActions?.assistant_chat_id && isEditingName ? (
                        <ClickAwayListener
                          onClickAway={() => {
                            if (!isEditChatNameLoading) {
                              setIsEditingName(false);
                              handleChatMoreActionsClose();
                            }
                          }}
                        >
                          <TextField
                            label={t('creative-assistant.edit-chat-field-label')}
                            focused
                            placeholder={t('creative-assistant.edit-chat-field-placeholder')}
                            size="small"
                            onChange={(e) => {
                              handleOnEditNameChange(e);
                            }}
                            onKeyDown={(e) => {
                              handleOnEditKeyDownPress(e);
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  sx={{cursor: 'pointer'}}
                                  onClick={() => {
                                    handleChatNameEdit();
                                  }}
                                  position="end"
                                >
                                  <CheckRoundedIcon
                                    sx={{
                                      color: theme.palette.primary.main,
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            InputLabelProps={{shrink: true}}
                          />
                        </ClickAwayListener>
                      ) : (
                        nameToDisplay
                      )
                    }
                    secondary={formatDateTime(chatInfo.created_at)}
                  />

                  <IconButton
                    color="inherit"
                    onClick={(e) => {
                      e.stopPropagation();

                      setIsEditingName(false);
                      handleChatMoreActionsClose();
                      setChatForActions(chatInfo);
                      setMoreActionsEl(e.currentTarget);
                    }}
                    size="large"
                  >
                    <MoreHorizIcon sx={{color: theme.palette.primary.main}} />
                  </IconButton>
                </ListItem>
              );
            })
          ) : (
            <h4>{t('creative-assistant.chat-history-no-chats')}</h4>
          )}
        </List>
        <Menu
          id="chat-list-more-actions"
          anchorEl={moreActionsEl}
          open={Boolean(moreActionsEl)}
          onClose={handleChatMoreActionsClose}
        >
          <MenuItem onClick={handleEditNamePressed}>{t('creative-assistant.chat-list-actions.edit-name')}</MenuItem>
          <MenuItem onClick={handleChatDelete}>{t('creative-assistant.chat-list-actions.delete')}</MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}

export default ChatHistory;
