import {FormControl, FormControlLabel} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {ErrorMessage} from './components/ErrorMessage';
import {buildSetValue, propsWithTestAttributes, SpartanInputFieldProps, wrapInTooltipIfNecessary} from './model';

export function SpartanCheckboxField(_props: SpartanInputFieldProps) {
  const props = propsWithTestAttributes(_props);
  const {id, name, value, label, disabled, onBlur, onFocus, hasError, error, inputRef, inputProps} = props;
  const setValue = buildSetValue(props);
  return (
    <>
      <FormControl error={hasError}>
        {wrapInTooltipIfNecessary(
          props,
          <FormControlLabel
            key={id}
            label={label}
            onBlur={onBlur}
            onFocus={onFocus}
            control={
              <Checkbox
                color={'primary'}
                name={name}
                disabled={disabled}
                checked={!!value}
                onChange={(event) => setValue(event.target.checked)}
                inputRef={inputRef}
                inputProps={inputProps}
              />
            }
          />
        )}
        <ErrorMessage hasError={hasError} error={error} />
      </FormControl>
    </>
  );
}
