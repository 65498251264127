import React, {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {Auth0User} from '../../../model/Auth0User';
import {useLoading} from '../../../context/LoadingContext';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

interface UserPermissionsV2Props {
  permissions: string[];
}

interface User {
  user_id: string;
  username: string;
  email: string;
}

export function UserPermissions({permissions}: UserPermissionsV2Props) {
  const [users, setUsers] = useState<User[]>([]);
  const [searchTermDebounced, setSearchTermDebounced] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<User | null>();
  const [selectedUserPermissions, setSelectedUserPermissions] = useState<string[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingUserPermissions, setLoadingUserPermissions] = useState(false);
  const auth0 = useAuth0<Auth0User>();
  const {setLoading} = useLoading();

  useEffect(() => {
    if (users.length == 1) {
      setSelectedUser(users[0]);
    } else {
      setSelectedUser(null);
    }
  }, [users]);

  const search = useMemo(
    () => (search: string) => {
      setLoadingUsers(true);
      auth0
        .getAccessTokenSilently()
        .then((token) => fetch(`/api/user/v2/user-search?q=${search}`, {headers: {authorization: `bearer ${token}`}}))
        .then((response) => response.json())
        .then((data) => setUsers(data))
        .finally(() => setLoadingUsers(false));
    },
    []
  );

  const getUserPermissions = useMemo(
    () => (userId: string) => {
      return auth0
        .getAccessTokenSilently()
        .then((token) =>
          fetch(`/api/user/v2/user-permissions/${userId}`, {headers: {authorization: `bearer ${token}`}})
        )
        .then((response) => response.json());
    },
    []
  );

  useEffect(() => {
    if (selectedUser?.user_id) {
      setLoadingUserPermissions(true);
      getUserPermissions(selectedUser?.user_id)
        .then((data) => setSelectedUserPermissions(data))
        .finally(() => setLoadingUserPermissions(false));
    } else {
      setSelectedUserPermissions([]);
    }
  }, [selectedUser?.user_id]);

  const setUserPermission = useMemo(
    () => (userId: string, permission: string, granted: boolean) => {
      setLoading(true, `UserPermissions/${userId}/${permission}`);
      auth0
        .getAccessTokenSilently()
        .then((token) =>
          fetch(`/api/user/v2/user-permissions/${userId}/${permission}`, {
            method: granted ? 'PUT' : 'DELETE',
            headers: {authorization: `bearer ${token}`},
          })
        )
        .then((data) => getUserPermissions(userId))
        .then((data) => setSelectedUserPermissions(data))
        .finally(() => setLoading(false, `UserPermissions/${userId}/${permission}`));
    },
    []
  );

  useEffect(() => {
    if (searchTermDebounced.trim().length) {
      search(searchTermDebounced);
    } else {
      setUsers([]);
    }
  }, [searchTermDebounced]);

  useEffect(() => {
    const task = setTimeout(() => setSearchTermDebounced(searchTerm), 350);
    return () => clearTimeout(task);
  }, [searchTerm]);

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value);
  }

  return (
    <>
      <Grid container spacing={2} xs={12}>
        <Grid item xs={12} key={'user-search'}>
          <TextField
            style={{width: '100%'}}
            value={searchTerm}
            placeholder={'search users'}
            onChange={onChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} lg={6} key={'user-list'}>
          {loadingUsers ? (
            <CircularProgress sx={{margin: 2, marginLeft: 2}} />
          ) : (
            <>
              {users.length == 0 ? (
                <Typography>No results</Typography>
              ) : (
                <Paper sx={{maxHeight: '40vh', overflow: 'auto'}}>
                  <List>
                    {users
                      .sort((a, b) => (a.username < b.username ? -1 : a.username > b.username ? 1 : 0))
                      .map((u) => (
                        <ListItem disablePadding key={'user-' + u.username}>
                          <ListItemButton selected={selectedUser == u} onClick={() => setSelectedUser(u)}>
                            <ListItemText primary={u.username} sx={{width: '30%'}} />
                            <ListItemText primary={u.email} sx={{width: '70%'}} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>
                </Paper>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={6} key={'user-permission-list'}>
          <Grid container>
            {selectedUser &&
              permissions.map((permission) => (
                <Grid item xs={12} key={permission}>
                  <FormControlLabel
                    key={permission}
                    label={permission}
                    disabled={loadingUserPermissions || loadingUsers}
                    control={
                      <Checkbox
                        color={'primary'}
                        name={permission}
                        disabled={false}
                        checked={selectedUserPermissions.includes(permission)}
                        onChange={(event) => setUserPermission(selectedUser?.user_id, permission, event.target.checked)}
                      />
                    }
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
