export interface FormAction {
  action_id: string;
  name: string;
}

export function compareByName<T extends FormAction>(a: T, b: T) {
  const nameA = a.name ?? '';
  const nameB = b.name ?? '';
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}
