import {ReactNode} from 'react';
import {styled} from '@mui/material';
import Typography from '@mui/material/Typography';

const Headline = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
}));

interface PageHeadlineProps {
  children?: ReactNode;
}

function PageHeadline({children}: PageHeadlineProps) {
  return (
    <Headline>
      <Typography variant={'h1'}>{children}</Typography>
    </Headline>
  );
}

export default PageHeadline;
