import React, {useEffect} from 'react';
import './ChatBox.css';
import {useUserSettingsContext} from '../../context/UserSettingsContext';

declare global {
  interface Window {
    kommunicate: any;
  }
}

function removeAllById(id: string) {
  document.querySelectorAll(`#${id}`).forEach((el) => el.remove());
}

function KommunicateChat() {
  const {userSettings} = useUserSettingsContext();

  useEffect(() => {
    if (userSettings.show_chat) {
      removeAllById('kommunicate-script');
      const element = document.createElement('script');
      element.type = 'text/javascript';
      element.async = true;
      element.src = 'https://widget.kommunicate.io/v2/kommunicate.app';
      element.id = 'kommunicate-script';
      document.head.appendChild(element);
      if (!window.kommunicate) window.kommunicate = {};
      window.kommunicate._globals = {
        appId: '3621ccdcb314e1001848893e01a9c6ead',
        automaticChatOpenOnNavigation: true,
        popupWidget: true,
      };
    } else {
      removeAllById('kommunicate-script');
      removeAllById('kommunicate-widget-iframe');
    }
  }, [userSettings.show_chat]);

  return <></>;
}

export default KommunicateChat;
