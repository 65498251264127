import React, {FunctionComponent} from 'react';
import {Outlet} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import Navbar from './Navbar';

const DashboardLayoutRoot = styled('div')(({theme}) => ({
  // backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const DashboardLayoutWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 55,
}));

export const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

export const DashboardLayoutContent = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.background.default,
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
}));

interface LayoutProps {
  hideSideNav?: boolean;
}

export const Layout: FunctionComponent<LayoutProps> = ({hideSideNav}) => {
  return (
    <DashboardLayoutRoot>
      <Navbar hideSideNav={hideSideNav}></Navbar>

      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default Layout;
