import {Auth0ContextInterface} from '@auth0/auth0-react';
import {Auth0User, getUserEmail, getUserId} from '../model/Auth0User';
import {IFileWithMeta} from 'react-dropzone-uploader';

export default class FilesService {
  private auth0: Auth0ContextInterface<Auth0User>;
  public filesUploadedSuccessMessage: string = 'files uploaded successfully';

  constructor(auth0: Auth0ContextInterface<Auth0User>) {
    this.auth0 = auth0;
  }

  static with(auth0: Auth0ContextInterface<Auth0User>) {
    return new FilesService(auth0);
  }

  async getTSVFileCount(file: File): Promise<number> {
    let count: number = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        resolve(
          e?.target?.result
            ? e?.target?.result
                ?.slice(e?.target?.result?.toString()?.indexOf('\n') + 1)
                ?.toString()
                ?.split('\n')?.length
            : 0
        );
      };
      fileReader.readAsText(file);
    });
    return count;
  }

  buildFormData(files: IFileWithMeta[], id?: string, folder?: string): FormData[] {
    const formDataArray: FormData[] = [];

    files.forEach((f) => {
      if (f?.meta?.status === 'done') {
        let formData = new FormData();
        const newFile = new File([f.file], `${f.file.name.toLowerCase().trim()}`, {
          type: f.file.type,
        });
        formData.append('file1', newFile);
        formData.append('folder', `${folder}${id ? id : 'substitudeId'}`);
        formData.append('email', getUserEmail(this.auth0.user) || '');
        formData.append('user_id', getUserId(this.auth0.user) || '');
        formDataArray.push(formData);
      }
    });

    return formDataArray;
  }
}
