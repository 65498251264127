import React from 'react';
import {SharedEntitiesProvider} from './contexts/SharedEntitiesContext';
import ListFeatureComponent from './components/ListFeatureComponent';
import {ImportProvider} from './contexts/ImportContext';

export default function FormEditorPageComponent() {
  return (
    <ImportProvider>
      <SharedEntitiesProvider>
        <ListFeatureComponent />
      </SharedEntitiesProvider>
    </ImportProvider>
  );
}
