import {Box, Button, Grid} from '@mui/material';
import Container from '@mui/material/Container';
import PageHeadline from '../../components/PageHeadline';
import React, {useCallback, useEffect, useState} from 'react';
import Tab from '@mui/material/Tab';
import {FormBuilder} from '../../form/FormBuilder';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import SettingsUploadsCampaigns from './SettingsUploadsCampaigns';
import {useTranslation} from 'react-i18next';
import {FeatureName} from '../../../paths';
import {PageStickyHeader} from '../PageStickyHeader';
import {difference} from '../../form/utils';
import {usePermissions} from '../UsePermissions';
import OfficeSchedulePage from './OfficeSchedulePage';
import {TestAttributes} from '../../TestAttributes';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import OrganizationService from '../../services/OrganizationService';
import {ChatTeamResponse, toChatTeamMember} from '../../services/UserService';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

interface Notification {
  users?: Array<string>;
  teams?: Array<string>;
  type: string;
  message: string;
}

function GeneralSettingPage() {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const {setLoading} = useLoading();
  const [value, setValue] = React.useState('0');
  const [chat, setChat] = useState<any | null>(null);
  const [notification, setNotification] = useState<Notification | {}>({});
  usePermissions(FeatureName.GENERAL_SETTINGS);
  const CHAT_SETTING_URL = 'chatteam/';
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [
    {error: postNotificationError, response: postNotificationResponse, loading: postNotificationLoading},
    postNotificationData,
  ] = useAxiosBFF<Notification>(
    {
      url: `${OrganizationService.getInstance().getCurrentValue()?.publisher}/`,
      method: 'POST',
      params: {
        notification: true,
      },
    },
    {manual: true}
  );
  const [{response: ChatSettingsResponse, error: ChatSettingsError}, getChatSettings] = useAxiosBFF<ChatTeamResponse>(
    {
      url: `${CHAT_SETTING_URL}`,
      method: 'GET',
    },
    {manual: true}
  );
  const [{error: postChatTeamError, response: postChatTeamResponse, loading: postChatsLoading}, saveChatSettings] =
    useAxiosBFF<any>(
      {
        url: `${CHAT_SETTING_URL}`,
        method: 'POST',
      },
      {manual: true}
    );

  useEffect(() => {
    const loading = postNotificationLoading || postChatsLoading;
    setLoading(loading, 'GeneralSettingPage');
  }, [postNotificationLoading, postChatsLoading]);

  useEffect(() => {
    if (postNotificationError) {
      NotificationService.getInstance().sendNotification(
        postNotificationError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [postNotificationError]);

  useEffect(() => {
    if (ChatSettingsError) {
      NotificationService.getInstance().sendNotification(
        ChatSettingsError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [ChatSettingsError]);

  useEffect(() => {
    if (postChatTeamError) {
      NotificationService.getInstance().sendNotification(
        postChatTeamError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [postChatTeamError]);

  useEffect(() => {
    if (postNotificationResponse?.status === 200) {
      NotificationService.getInstance().sendNotification(
        postNotificationResponse.data?.['message'],
        NotificationType.SUCCESS
      );
      setNotification({});
    }
  }, [postNotificationResponse]);

  useEffect(() => {
    if (ChatSettingsResponse) {
      setChat(toChatTeamMember(ChatSettingsResponse?.data));
    }
  }, [ChatSettingsResponse]);

  useEffect(() => {
    if (postChatTeamResponse?.status === 200) {
      NotificationService.getInstance().sendNotification(
        postChatTeamResponse.data?.['message'],
        NotificationType.SUCCESS
      );
      getChatSettings();
      setNotification({});
    }
  }, [postChatTeamResponse]);

  useEffect(() => {
    getChatSettings();
  }, []);

  const submitChatSettingsForm = useCallback(
    (formData: Partial<any>) => {
      if (chat) {
        formData = difference(formData, chat);
        saveChatSettings({
          data: formData,
        });
      }
    },
    [chat]
  );
  const submitNotificationSettingsForm = useCallback(
    (formData: Partial<any>) => {
      postNotificationData({
        data: {notification: formData},
      });
    },
    [notification]
  );

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <PageStickyHeader>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline> {t('settings.headLine')} </PageHeadline>
              </Grid>
            </Grid>
          </PageStickyHeader>
          <Grid item xs={12} md={12}>
            <TabContext value={value}>
              <TabList onChange={handleChange} aria-label="settings tabs">
                <Tab
                  {...{[TestAttributes.TAB_NAME]: 'office-schedule-tab'}}
                  label={t('settings.officeSchedule')}
                  value="0"
                />
                <Tab {...{[TestAttributes.TAB_NAME]: 'chat-tab'}} label={t('settings.chatTab')} value="1" />
                <Tab
                  {...{[TestAttributes.TAB_NAME]: 'notification-tab'}}
                  label={t('settings.notificationsTab')}
                  value="2"
                />
                <Tab {...{[TestAttributes.TAB_NAME]: 'uploads-tab'}} label={t('settings.uploads')} value="3" />
              </TabList>
              <TabPanel value="0">
                <OfficeSchedulePage />
              </TabPanel>
              <TabPanel value="1">
                {chat && (
                  <FormBuilder
                    formId={FeatureName.SETTINGS_CHAT}
                    initialValues={chat}
                    onSubmit={submitChatSettingsForm}
                  >
                    <Button
                      color="primary"
                      type="submit"
                      id={'save-chat-settings-btn'}
                      {...{[TestAttributes.BUTTON_NAME]: 'save-chat-settings-btn'}}
                    >
                      {t('shared.save')}
                    </Button>
                  </FormBuilder>
                )}
              </TabPanel>
              <TabPanel value="2">
                <FormBuilder
                  formId={FeatureName.SETTINGS_NOTIFICATIONS}
                  onSubmit={submitNotificationSettingsForm}
                  initialValues={notification}
                >
                  <Button
                    color="primary"
                    type="submit"
                    id={'save-notification-settings-btn'}
                    {...{[TestAttributes.BUTTON_NAME]: 'save-notification-settings-btn'}}
                  >
                    {t('shared.send')}
                  </Button>
                </FormBuilder>
              </TabPanel>
              <TabPanel value="3">
                <SettingsUploadsCampaigns></SettingsUploadsCampaigns>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default GeneralSettingPage;
