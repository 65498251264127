import {LeadComment} from '../../../model/LeadComment';
import {Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {formatDateTime} from '../../../utils/DateUtils';
import React from 'react';

interface CommentListProps {
  comments: Array<LeadComment>;
  accordionTitle: string;
}

function CommentList({comments, accordionTitle}: CommentListProps) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="comments-panel" id="comments-panel">
        <Typography variant={'body1'}>{accordionTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {comments?.map((commentItem: LeadComment, index: number) => (
          <Grid
            item
            container
            flexDirection={'row'}
            spacing={5}
            key={'comment-item-' + commentItem.section + '-' + index}
            sx={{
              pt: (theme) => `${theme.spacing(3)}`,
            }}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="h6">{commentItem?.section}</Typography>
              <Typography variant="body2">{`${commentItem?.created_by?.username || ''}  ${formatDateTime(
                commentItem?.created_at
              )}`}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" sx={{whiteSpace: 'pre-line'}}>
                {commentItem?.comment}
              </Typography>
            </Grid>
            <Divider sx={{width: '100%', mt: (theme) => `${theme.spacing(3)}`}} />
          </Grid>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default CommentList;
