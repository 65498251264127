import React, {PropsWithChildren} from 'react';
import {UserSettingsProvider} from './UserSettingsContext';
import LightDarkThemeProvider from './LightDarkThemeProvider';
import {ConfigProvider} from './ConfigContext';
import {Auth0OrganizationProvider} from './Auth0OrganizationProvider';
import {AxiosProvider} from './AxiosContext';
import {LoadingProvider} from './LoadingContext';
import GlobalStyles from '@mui/material/GlobalStyles';
import {BrowserRouter} from 'react-router-dom';

export const ContextsProvider = ({children}: PropsWithChildren) => (
  <LoadingProvider>
    <ConfigProvider>
      <AxiosProvider>
        <Auth0OrganizationProvider>
          <UserSettingsProvider>
            <LightDarkThemeProvider>
              <GlobalStyles
                styles={(theme) => ({
                  a: {
                    textDecoration: 'none',
                    color: theme.palette.text.primary,
                  },
                  '[role="tooltip"] a': {
                    color: theme.palette.text.secondary,
                  },
                  'a:visited': {
                    color: theme.palette.text.primary,
                  },
                  'a.active': {
                    color: theme.palette.primary.main,
                  },
                  html: {
                    height: '100%',
                    width: '100%',
                  },
                  body: {
                    height: '100%',
                    width: '100%',
                  },
                  li: {
                    listStyleType: 'none',
                  },
                  '#root': {
                    height: '100%',
                    width: '100%',
                  },
                  '*': {
                    boxSizing: 'border-box',
                    margin: 0,
                    padding: 0,
                    scrollBehavior: 'smooth',
                  },
                  '.jodit-container': {
                    width: '100% !important',
                  },
                  '.jodit-container ol, .jodit-container ul': {
                    paddingLeft: '40px',
                  },
                  '.jodit-container ul li': {
                    listStyleType: 'inherit',
                  },
                  '.jodit-container ol li': {
                    listStyleType: 'inherit',
                  },
                  '.jodit-container p': {
                    padding: 'inherit',
                  },
                  '.jodit-container a': {
                    textDecoration: 'underline',
                    color: 'blue',
                  },
                })}
              />
              <BrowserRouter>{children}</BrowserRouter>
            </LightDarkThemeProvider>
          </UserSettingsProvider>
        </Auth0OrganizationProvider>
      </AxiosProvider>
    </ConfigProvider>
  </LoadingProvider>
);
