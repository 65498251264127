// NOTE: everything that goes here is part of the closure and can be evaluated

// this is ONLY meant to be overwritten for test purpose
import {ComputeContext} from '../ComputeContext';

export const __ctx__ = {
  getCurrentDate: () => new Date(),
};
/* eslint-disable */
function calcAge(date: any): number {
  return Math.floor((__ctx__.getCurrentDate().getTime() - new Date(date).getTime()) / 31556952000);
}

function toString(val: any): string {
  return val ? `${val}` : '';
}

// assure toEpoch part of the closure and can be evaluated
const {toEpoch} = require('../../../utils/DateUtils');
/* eslint-disable */
export function evalExpression(expression: string, values: any, ctx?: ComputeContext): any {
  // TODO: consider refactoring
  // IMPORTANT: due to the extensive use of eval for evaluating condition, we allow the use of eval ONLY in this context,
  // therefore disabling the eslint rule here below.
  // For more info see https://spartanapproach.atlassian.net/browse/FE-834?focusedCommentId=35185
  // eslint-disable-next-line
  const result = eval(expression);
  console.debug(ctx?.logger, 'evalExpression', expression, result);
  return Number.isFinite(result) && !Number.isInteger(result) ? Number(result.toFixed(2)) : result;
}
