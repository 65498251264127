import {useEffect} from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import {CategoryAxis, ColumnSeries, LabelBullet, ValueAxis, XYChart} from '@amcharts/amcharts4/charts';
import Typography from '@mui/material/Typography';

interface SortedBarChartProps {
  name: string;
}

const graphData = [
  {
    name: 'Age',
    data: [
      {
        label: 'Lead Source 0',
        amount: 2255250,
      },
      {
        label: 'Lead Source 1',
        amount: 430000,
      },
      {
        label: 'Lead Source 2',
        amount: 1000000,
      },
      {
        label: 'Lead Source 3',
        amount: 246500,
      },
      {
        label: 'Lead Source 4',
        amount: 355000,
      },
      {
        label: 'Lead Source 5',
        amount: 400000,
      },
      {
        label: 'Lead Source 6',
        amount: 624000,
      },
      {
        label: 'Lead Source 7',
        amount: 329500,
      },
      {
        label: 'Lead Source 8',
        amount: 1000000,
      },
      {
        label: 'Lead Source 9',
        amount: 431000,
      },
      {
        label: 'Lead Source 10',
        amount: 1433333,
      },
      {
        label: 'Lead Source 11',
        amount: 1900000,
      },
    ],
  },
  {
    name: 'Status',
    data: [
      {
        label: 'Lead Source 0',
        amount: 2255250,
      },
      {
        label: 'Lead Source 1',
        amount: 430000,
      },

      {
        label: 'Lead Source 7',
        amount: 329500,
      },
      {
        label: 'Lead Source 8',
        amount: 1000000,
      },
      {
        label: 'Lead Source 9',
        amount: 431000,
      },
    ],
  },
];

function SortedBarChart({name}: SortedBarChartProps) {
  let chart: XYChart;

  useEffect(() => {
    chart = am4core.create('sorted-bar-chart-' + name, XYChart);
    createChart(chart);
    return () => {
      chart.dispose();
    };
  }, [name]);

  function createChart(chart: XYChart) {
    chart.padding(40, 40, 40, 40);

    let categoryAxis = chart.yAxes.push(new CategoryAxis());
    categoryAxis.fontFamily = 'Quicksand';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = 'label';
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.disabled = true;

    let valueAxis = chart.xAxes.push(new ValueAxis());
    valueAxis.fontFamily = 'Quicksand';
    valueAxis.min = 0;

    let series = chart.series.push(new ColumnSeries());
    series.dataFields.categoryY = 'label';
    series.dataFields.valueX = 'amount';
    series.tooltipText = '{valueX.value}';
    series.columns.template.strokeOpacity = 0;
    series.columns.template.column.cornerRadiusBottomRight = 5;
    series.columns.template.column.cornerRadiusTopRight = 5;

    let labelBullet = series.bullets.push(new LabelBullet());
    labelBullet.label.horizontalCenter = 'left';
    labelBullet.label.dx = 10;
    labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#.0as')}";
    labelBullet.locationX = 1;
    labelBullet.fontFamily = 'Quicksand';

    // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
    // series.columns.template.adapter.add('fill', function (fill, target: Column) {
    //   return chart.colors.getIndex(target.dataItem.index || 0);
    // });

    categoryAxis.sortBySeries = series;
    chart.data = graphData.find((g) => g.name === name)?.data || [];
  }

  return (
    <>
      <Typography variant={'h3'}>{name} Sorted Bar Chart</Typography>
      <div id={'sorted-bar-chart-' + name} style={{width: '100%', height: '500px'}}></div>
    </>
  );
}

export default SortedBarChart;
