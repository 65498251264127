export interface ConversationMessage {
  status: ConversationMessageStatus;
  created_at: string;
  owner: string;
  transcription: string;
  confidence_score: number;
  language: string;
  index?: number;
  keyword_matches?: Array<string>;
  start_time?: number;
}

export enum ConversationMessageStatus {
  SENT = 'sent',
  RECEIVED = 'received',
}
