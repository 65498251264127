import {FormFieldSchema, FormSchema, FormSectionSchema} from './FormSchema';
import {buildTrigger, Trigger} from './triggers/Trigger';
import {buildValidation, Validation} from './validations/Validation';
import {
  buildIsDisabledFunction,
  buildIsHiddenFunction,
  buildIsRequiredFunction,
  buildIsSectionHiddenFunction,
  ConditionOrBool,
} from './conditions/Condition';
import {traverseSchemaFields, traverseSchemaSections} from './utils';
import {MultiValueMap} from '../utils';
import {decorateField, decorateSection, FormDecorator} from './FormDecorator';
import {buildOptionsFilters, FieldOptionsFilter} from './options/FieldOptionsFilter';

export class FormSchemaHelper {
  public readonly schema: FormSchema;

  private readonly fieldNames: string[] = [];
  private readonly fieldSchemas = new Map<string, FormFieldSchema>();
  private readonly sectionSchemasById = new Map<string, FormSectionSchema>();

  private readonly triggersByDependency = new MultiValueMap<string, Trigger>();
  private readonly validationsByDependency = new MultiValueMap<string, Validation>();

  // TODO make these private
  public readonly validations = new Map<string, Validation>();
  public readonly triggers = new Map<string, Trigger>();
  public readonly isRequiredFunctions = new Map<string, ConditionOrBool>();
  public readonly isDisabledFunctions = new Map<string, ConditionOrBool>();
  public readonly isHiddenFunctions = new Map<string, ConditionOrBool>();
  public readonly isSectionHiddenFunctions = new Map<string, ConditionOrBool>();
  public readonly optionsFilters = new Map<string, FieldOptionsFilter>();

  constructor(schema: FormSchema, decorators?: FormDecorator[]) {
    this.schema = schema;
    this.init(decorators || []);
  }

  private init(decorators: FormDecorator[]) {
    sortByIndex(this.schema.sections);
    traverseSchemaSections(this.schema, (section) => this.processSection(section, decorators));
    traverseSchemaFields(this.schema, (field) => this.processField(field, decorators));
    const optionsFilters = buildOptionsFilters(this.schema);
    this.optionsFilters.clear();
    optionsFilters.forEach((value, key) => this.optionsFilters.set(key, value));
  }

  private processSection(section: FormSectionSchema, decorators: FormDecorator[]) {
    decorateSection(section, decorators);
    sortByIndex(section.fields);
    sortByIndex(section.sections);
    this.sectionSchemasById.set(section.section_id, section);
    const isSectionHidden = buildIsSectionHiddenFunction(section, {
      logger: `is-section-hidden-${section.section_id}`,
    });
    if (isSectionHidden) {
      this.isSectionHiddenFunctions.set(section.section_id, isSectionHidden);
    }
  }

  private processField(field: FormFieldSchema, decorators: FormDecorator[]) {
    decorateField(field, decorators);
    const fieldName = field.field_name;
    this.fieldNames.push(fieldName);
    this.fieldSchemas.set(fieldName, field);

    const validation = buildValidation(field, {logger: `validation-${field.field_name}`});
    if (validation) {
      this.validations.set(fieldName, validation);
      for (let dependency of validation.dependencies) {
        this.validationsByDependency.add(dependency, validation);
      }
    }
    const trigger = buildTrigger(field, {logger: `trigger-${field.field_name}`});
    if (trigger) {
      this.triggers.set(fieldName, trigger);
      for (let dependency of trigger.dependencies) {
        this.triggersByDependency.add(dependency, trigger);
      }
    }
    const isRequired = buildIsRequiredFunction(field, {logger: `is-required-${field.field_name}`});
    if (isRequired) {
      this.isRequiredFunctions.set(fieldName, isRequired);
    }
    const isHidden = buildIsHiddenFunction(field, {logger: `is-hidden-${field.field_name}`});
    if (isHidden) {
      this.isHiddenFunctions.set(fieldName, isHidden);
    }
    const isDisabled = buildIsDisabledFunction(field, {logger: `is-disabled-${field.field_name}`});
    if (isDisabled) {
      this.isDisabledFunctions.set(fieldName, isDisabled);
    }
  }

  getFieldNames(): string[] {
    return this.fieldNames;
  }

  getFieldSchema(name: string): FormFieldSchema | undefined {
    return this.fieldSchemas.get(name);
  }
}

interface WithIndex {
  index?: number;
}

function extractIndex(obj: WithIndex) {
  return obj.index || 0;
}

function sortByIndex(array: undefined | WithIndex[]): void {
  if (array && array.length) {
    array.sort((a, b) => {
      const indexA = extractIndex(a),
        indexB = extractIndex(b);
      return indexA - indexB;
    });
  }
}
