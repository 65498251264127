import {createContext, PropsWithChildren, useContext} from 'react';
import {useEntities} from '../hooks/useEntities';
import {FormCoRule} from '../model/FormCoRule';
import {useImportExport} from '../hooks/useImportExport';

type Entity = FormCoRule;

const Context = createContext<EntitiesContext | null>(null);

interface Props extends PropsWithChildren {
  resourceId: string;
}

export const CoRulesProvider = ({resourceId, children}: Props) => {
  const {entities, loading, locked, handleCreate, handlePatch, handleDelete} = useEntities<Entity>(
    `corules/by_resource_id/${resourceId}`,
    `corules`
  );
  const {exportEntity, exporting, copyEntity, copying} = useImportExport();
  async function handleExport(id: string) {
    await exportEntity('corules', id);
  }
  async function handleCopy(id: string) {
    await copyEntity('corules', id);
  }
  return (
    <Context.Provider
      value={{
        corules: entities,
        loading,
        locked: locked || exporting || copying,
        handleCreate,
        handlePatch,
        handleDelete,
        handleExport,
        handleCopy,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export interface EntitiesContext {
  corules: Entity[] | null;
  handleCreate: (data: Entity) => Promise<void>;
  handlePatch: (id: string, data: Partial<Entity>) => Promise<void>;
  handleDelete: (id: string) => Promise<void>;
  handleExport: (id: string) => Promise<void>;
  handleCopy: (id: string) => Promise<void>;
  loading: boolean;
  locked: boolean;
}

export function useCoRulesContext(): EntitiesContext {
  const value = useContext(Context);
  if (!value) throw Error('invalid context');
  return value;
}
