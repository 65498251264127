import {BehaviorSubject, Observable} from 'rxjs';
import {LeadListSearchParams, LeadListSearchSortOption} from '../model/Lead';
import {LeadComment} from '../model/LeadComment';

export default class LeadService {
  private static INSTANCE = new LeadService();
  private unsavedComments = new BehaviorSubject<Array<LeadComment>>([]);
  private lastSearchParams = new BehaviorSubject<LeadListSearchParams>({
    search: '',
    order_by: LeadListSearchSortOption.CREATED_AT,
    last_edited_by_me: false,
    limit: 50,
  });
  private saveAndNext = new BehaviorSubject<boolean>(false);

  addUnsavedComments(comment: LeadComment) {
    const currentComments = this.unsavedComments.value;
    currentComments.push(comment);
    this.unsavedComments.next(currentComments);
  }

  getUnsavedComments(): Observable<Array<LeadComment>> {
    return this.unsavedComments.asObservable();
  }

  clearUnsavedComments(): void {
    this.unsavedComments.next([]);
  }

  getUnsavedCommentsValue(): Array<LeadComment> {
    return this.unsavedComments.value;
  }

  static getInstance(): LeadService {
    return LeadService.INSTANCE;
  }

  setSearchParams(searchParams: LeadListSearchParams) {
    this.lastSearchParams.next(searchParams);
  }

  getSearchParams(): LeadListSearchParams {
    return this.lastSearchParams.value;
  }

  setSaveAndNext(value: boolean) {
    this.saveAndNext.next(value);
  }

  isSaveAndNext(): boolean {
    return this.saveAndNext.value;
  }
}
