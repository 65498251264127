import {Box, Grid} from '@mui/material';
import Container from '@mui/material/Container';
import {FormBuilder} from '../../form/FormBuilder';
import React, {useCallback, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';
import PageHeadline from '../../components/PageHeadline';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName} from '../../../paths';
import {User, UserListItem} from '../../model/User';
import {PageStickyHeader} from '../PageStickyHeader';
import {PageTopActions} from '../PageTopActions';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {BaseFormFieldSchemaDecorator} from '../../form/logic/FormDecorator';
import {FormConfig} from '../../form/FormConfig';
import {useFormAPI} from '../../form/FormAPI';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import {onRejectSubmit} from '../../form/errorHandler';
import {TestAttributes} from '../../TestAttributes';
import {Status} from '../../model/Feature';
import {UserResponse} from '../../services/model/UserResponse';
import UserService from '../../services/UserService';
import {difference} from '../../form/utils';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {Skeleton} from '@mui/lab';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import FeatureNotFound from '../notFound/FeatureNotFound';

function UserPage() {
  const {id} = useParams();
  const {useAxiosBFF} = useAxiosContext();
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const navigate = useNavigate();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [isDeactivatedModalOpen, setIsDeactivatedModalOpen] = useState<boolean>(false);
  const [config, setConfig] = useState<FormConfig>();
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.USERS);
  const formAPI = useFormAPI();
  const [user, setUser] = useState<User | null>(null);
  const [isValid, setIsValid] = useState<boolean>(true);
  const featureName = FeatureName.USERS;
  const featureFrontendPath = `/${FeatureName.USERS}`;

  const [{}, getUserList] = useAxiosBFF<ResponseListWrapper<UserListItem>>(
    {url: `${featureFrontendPath}`, params: {formatted: true}},
    {
      manual: true,
    }
  );
  const [{data: userResponse, loading: isUserLoading, error: getUserByIdError}, getUserData] =
    useAxiosBFF<UserResponse>({url: `${featureFrontendPath}/${id}`}, {manual: true});

  const [{response: postUserResponse, error: hasUserPostError, loading: isPostUserLoading}, postUserData] =
    useAxiosBFF<UserResponse>({url: `${featureFrontendPath}`, method: 'POST'}, {manual: true});

  const [{response: patchedUserResponse, error: hasUserPatchError, loading: isPatchUserLoading}, patchUserData] =
    useAxiosBFF<User>(
      {url: `${featureFrontendPath}/${id}`, method: 'PATCH', params: {formatted: true}},
      {manual: true}
    );

  const [{response: softDeleteUserResponse, error: hasSoftDeleteError, loading: isDeleteUserLoading}, softDeleteUser] =
    useAxiosBFF<UserResponse>(
      {url: `${featureFrontendPath}/${id}`, method: 'PATCH', data: {status: Status.INACTIVE}},
      {manual: true}
    );

  const onSubmit = useCallback(
    (formData: Partial<User>) => {
      if (id && user) {
        formData = difference(formData, user);
        patchUserData({
          data: formData,
        }).then(() => {
          getUserData();
        });
      } else {
        formData.status = Status.ACTIVE;
        postUserData({
          data: formData,
        });
      }
    },
    [user]
  );
  function deleteItem() {
    if (id) {
      softDeleteUser();
    }
  }

  async function submitUser(data: any): Promise<void> {
    if (!id) {
      data['password'] = 'Sp4t418123&'; // ugly
    }
    return onSubmit(data);
  }

  useEffect(() => {
    if (id) {
      getUserData();
    }
  }, [id]);
  useEffect(() => {
    if (getUserByIdError) {
      NotificationService.getInstance().sendNotification(
        getUserByIdError?.response?.data?.message,
        NotificationType.ERROR
      );
      setIsValid(false);
    }
  }, [getUserByIdError]);
  useEffect(() => {
    if (userResponse) {
      setUser(UserService.getInstance().toUser(userResponse));
    }
  }, [userResponse]);

  useEffect(() => {
    const loading = isUserLoading || isPostUserLoading || isPatchUserLoading || isDeleteUserLoading;
    setLoading(loading, 'UserPage');
  }, [isUserLoading, isPostUserLoading, isPatchUserLoading, isDeleteUserLoading]);

  useEffect(() => {
    if (postUserResponse?.status === 201) {
      NotificationService.getInstance().sendNotification(postUserResponse?.data?.['message'], NotificationType.SUCCESS);
      getUserList().then(() => {
        navigate(`${featureFrontendPath}/${postUserResponse.data['id']}`);
      });
    }
  }, [postUserResponse]);

  useEffect(() => {
    if (hasUserPostError) {
      NotificationService.getInstance().sendNotification(
        hasUserPostError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasUserPostError]);

  useEffect(() => {
    if (patchedUserResponse?.status === 200) {
      NotificationService.getInstance().sendNotification(
        patchedUserResponse?.data?.['message'],
        NotificationType.SUCCESS
      );
      getUserList();
    }
  }, [patchedUserResponse]);

  useEffect(() => {
    if (hasUserPatchError) {
      NotificationService.getInstance().sendNotification(
        hasUserPatchError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasUserPatchError]);

  useEffect(() => {
    if (softDeleteUserResponse?.status === 200) {
      getUserList().then(() => {
        NotificationService.getInstance().sendNotification(
          softDeleteUserResponse?.data?.['message'],
          NotificationType.SUCCESS
        );
        navigate(featureFrontendPath);
      });
    }
  }, [softDeleteUserResponse]);

  useEffect(() => {
    if (hasSoftDeleteError) {
      NotificationService.getInstance().sendNotification(
        hasSoftDeleteError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasSoftDeleteError]);

  useEffect(() => {
    const decorators = [
      new BaseFormFieldSchemaDecorator((field) => {
        if (field.field_name && ['password'].includes(field.field_name.toLowerCase())) {
          field.hidden = true;
        }
      }),
    ];
    if (id) {
      decorators.push(
        new BaseFormFieldSchemaDecorator((field) => {
          if (field.field_name && ['username', 'email'].includes(field.field_name.toLowerCase())) {
            field.disabled = true;
            field.tooltip_translation_key = 'users.disabled-username-email-tooltip-html';
          }
        })
      );
    }
    setConfig({decorators});
  }, [id]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        {isValid ? (
          <Grid container spacing={3}>
            <PageStickyHeader>
              <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
                <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                  <PageHeadline>{t('users.headline')}</PageHeadline>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PageTopActions>
                    {id && (
                      <AccessControl
                        userPermissions={userPermissions}
                        allowedPermissions={[UserPermissions.DEACTIVATE_FEATURE_ITEM]}
                      >
                        <ConfirmationDialog
                          message={t('users.deactivate-message')}
                          headline={t('users.deactivate')}
                          isDialogOpen={isDeactivatedModalOpen}
                          handleClose={() => setIsDeactivatedModalOpen(false)}
                        >
                          <Button
                            onClick={() => {
                              deleteItem();
                              setIsDeactivatedModalOpen(false);
                            }}
                            {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
                            id="confirmation-dialog-action-btn"
                          >
                            {t('users.deactivate')}
                          </Button>
                        </ConfirmationDialog>

                        <Button
                          {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                          id={'confirmation-dialog-btn'}
                          color="secondary"
                          onClick={() => setIsDeactivatedModalOpen(true)}
                        >
                          {t('users.deactivate')}
                        </Button>
                      </AccessControl>
                    )}
                    <Button
                      {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                      id={'confirmation-dialog-btn'}
                      color="secondary"
                      onClick={() => setIsConfirmationDialogOpen(true)}
                    >
                      {t('shared.clear')}
                    </Button>
                    <AccessControl
                      userPermissions={userPermissions}
                      allowedPermissions={[id ? UserPermissions.MODIFY : UserPermissions.CREATE]}
                    >
                      <Button
                        {...{[TestAttributes.BUTTON_NAME]: 'save-btn'}}
                        id={'save-btn'}
                        onClick={() => formAPI.submit()}
                      >
                        {id ? t('shared.update') : t('shared.save')}
                      </Button>
                    </AccessControl>
                  </PageTopActions>
                </Grid>
              </Grid>
            </PageStickyHeader>

            <Grid item xs={12}>
              {isUserLoading ? (
                <Skeleton variant="rectangular" height={500} />
              ) : (
                <FormBuilder
                  formId={featureName}
                  api={formAPI}
                  initialValues={user}
                  onSubmit={submitUser}
                  config={config}
                  onRejectSubmit={onRejectSubmit}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <FeatureNotFound />
        )}
        <ConfirmationDialog
          message={t('shared.clear-form-modal-content')}
          headline={t('shared.clear-form-modal-headline')}
          isDialogOpen={isConfirmationDialogOpen}
          handleClose={() => setIsConfirmationDialogOpen(false)}
        >
          <Button
            onClick={() => {
              formAPI.reset();
              setIsConfirmationDialogOpen(false);
            }}
            {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
            id="confirmation-dialog-action-btn"
          >
            {t('shared.accept')}
          </Button>
        </ConfirmationDialog>
      </Container>
    </Box>
  );
}

export default UserPage;
