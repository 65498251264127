import {extend} from 'lodash';
import {UserPermissions} from '../components/shared/AccessControl';

export interface Feature extends FeatureBase {
  created_at: Date;
  created_by: string;
  last_updated_at: Date;
  last_updated_by: string;
  feature_id: string;
  cta: string;
  disabled?: boolean;
  feature_id_key?: string;
  icon_path?: string;
  index?: string;
  actions: Array<UserPermissions>;
}
export interface FeatureBase {
  name: string;
  label: string;
  type: string;
  url: string;
}
export enum Status {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
