import React, {useEffect, useState} from 'react';
import {Dialog, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useAuth0} from '@auth0/auth0-react';
import {useTranslation} from 'react-i18next';
import {fromEvent, merge} from 'rxjs';
import {setInterval} from 'worker-timers';
import {useConfig} from '../../context/ConfigContext';

/**
 * Interval in seconds before session expiration to show "Session Timeout" dialog
 */
const dialogInterval = 120;

function formatCountDown(timeout: number) {
  const seconds = timeout % 60;
  const minutes = Math.floor(timeout / 60);
  if (minutes) {
    return `${minutes} min ${seconds} secs`;
  }
  return `${seconds} secs`;
}

function SessionTimeout() {
  const {SESSION_TIMEOUT_SECONDS} = useConfig();
  const {t} = useTranslation();
  const auth0 = useAuth0();
  const [showDialog, setShowDialog] = useState(false);
  const [idleSeconds, setIdleSeconds] = useState(-1);
  const [remainingSeconds, setRemainingSeconds] = useState(Number.MAX_SAFE_INTEGER);

  useEffect(() => {
    const subscription1 = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'touchstart'),
      fromEvent(document, 'keydown'),
      fromEvent(document, 'scroll')
    ).subscribe(() => setIdleSeconds(0));

    let timer = setInterval(() => {
      setIdleSeconds((prev) => prev + 1);
    }, 1000);

    return () => {
      subscription1.unsubscribe();
      window.clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const _remainingSeconds = SESSION_TIMEOUT_SECONDS - idleSeconds;
    if (_remainingSeconds <= 0) {
      auth0.logout({returnTo: window.location.origin + '/login'});
    } else if (_remainingSeconds < dialogInterval) {
      setShowDialog(true);
      setRemainingSeconds(_remainingSeconds);
    } else {
      setShowDialog(false);
    }
  }, [idleSeconds, SESSION_TIMEOUT_SECONDS]);

  return (
    <>
      <Dialog open={showDialog} maxWidth="md" fullWidth aria-labelledby="session-timeout-dialog-title">
        <DialogTitle id="confirmation-dialog-title">{t('session-timeout.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="session-timeout-dialog-content">
            <Typography variant="body1">{t('session-timeout.body')}</Typography>
            <Typography variant="h2">{formatCountDown(remainingSeconds)}</Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SessionTimeout;
