import {FormFieldTrigger} from './triggers/Trigger';
import {FormFieldValidation, FormFieldValidationOrMessage} from './validations/Validation';
import {FormFieldConditionOrBool} from './conditions/Condition';
import {StringKeyObject} from '../state/FormState';

export interface FormSchema {
  name?: string;
  sections?: FormSectionSchema[];
  dependencies?: object;
}

export interface FormSectionSchema {
  section_id: string;
  section_name?: string;
  section_type?: FormSectionType;
  index?: number;
  sections?: FormSectionSchema[];
  fields?: FormFieldSchema[];
  hidden?: FormFieldConditionOrBool;
  pdf_label?: string;
  section_label?: string;
}

export enum FormSectionType {
  BASIC = 'basic',
  TAB_CONTAINER = 'tab-container',
  TAB = 'tab',
  COLLAPSABLE = 'collapsable',
}

export interface FormFieldSchema {
  field_id: string;
  field_name: string;
  label?: string;
  label_search?: string;
  label_template?: string;
  index?: number;
  default_value?: any;
  min_value?: any;
  max_value?: any;
  field_type: FormFieldType;
  size: FormFieldSize;
  searchable?: boolean;
  options?: FormFieldOption[];
  triggers?: FormFieldTrigger[];
  validations?: FormFieldValidation[];
  required?: FormFieldValidationOrMessage;
  disabled?: FormFieldConditionOrBool;
  hidden?: FormFieldConditionOrBool;
  linked_fields?: Array<LinkedFieldSchema>;
  in_pdf?: boolean;
  pdf_label?: string;
  tooltip_translation_key?: string;
  dynamic_type?: DynamicSourceType;
  dynamic_source?: string;
  metadata?: any;
}

export enum DynamicSourceType {
  ONDEMAND = 'on_demand',
  BACKENDCODE = 'backend_code',
  SELECT = 'select',
}

export interface FormChatSchema {
  from_number: string;
  lead_id: string;
  first_name?: string;
  last_name?: string;
  chat_owner: string;
  unread: number;
}

export interface LinkedFieldSchema {
  field_name: string;
  link_key: string;
}

export interface FormFieldOption {
  label: string;
  value: any;
  selectable?: boolean;
}

export enum FormFieldSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
}

export enum FormFieldType {
  NUMBER = 'number',
  TEXT = 'text',
  EMAIL = 'email',
  PHONE = 'phone',
  PASSWORD = 'password',
  SELECT = 'select',
  SELECTMULTIPLE = 'selectmultiple',
  ADDRESS = 'address',
  ZIPCODE = 'zipcode',
  FLOAT = 'float',
  CVV = 'cvv',
  CREDIT = 'credit',
  CURRENCY = 'currency',
  exp_date = 'expDate',
  EXTENSION = 'extension',
  SOCIAL = 'social',
  DATE = 'date',
  TIMESTAMP = 'timestamp',
  TEXTAREA = 'textarea',
  RICH_TEXT_EDITOR = 'rich_text_editor',
  CHECKBOX = 'checkbox',
  CHECKBOXINLINE = 'checkboxinline', // same as checkbox with different layout, idk if it's a good idea to have a different type for that but oh well
  LABEL = 'label',
  COMMENTS = 'comments',
  HISTORY = 'history',
  UPLOAD = 'upload',
  COMMUNICATION = 'communication',
  SEARCH = 'search',
  INSX = 'insx',
  AUTO_ADDRESS = 'auto_address',
  TIME_BETWEEN_CALLS = 'tbc',

  // +++++++++++++++
  // New field types
  // +++++++++++++++
  DATETIME = 'datetime',
  TIME = 'time',
  NOTE = 'note',
  RADIOS = 'radios',
  CHECKBOXES = 'checkboxes',

  AUTO_FILL_OPTION_CARD = 'auto_fill_option_card',
  SUBMIT_TEXTAREA = 'submit_textarea',
  LINK = 'link',
}

export enum FormFieldName {
  TINY_URL = 'tiny_url',
}

export interface SpartanLinkConfiguration {
  url?: string;
  name?: string | null;
  target?: TargetType;
  format?: StringKeyObject<any>;
}

export enum TargetType {
  BLANK = '_blank',
  SELf = '_self',
}
