import {FormDecorator} from './logic/FormDecorator';
import {BehaviorSubject} from 'rxjs';
import {FormState} from './state/FormState';

export interface FormConfig {
  debug?: boolean;
  decorators?: FormDecorator[]; // Applied at build time (before first rendering)
  $state?: BehaviorSubject<FormState>;
}

export function defaultFormConfig(): FormConfig {
  return {};
}
