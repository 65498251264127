import {useEffect, useState} from 'react';
import {FeatureName, Path} from '../../paths';
import {Feature} from '../model/Feature';
import {UserPermissions} from '../components/shared/AccessControl';
import {useNavigate} from 'react-router-dom';
import NotificationService, {NotificationType} from '../services/NotificationService';
import {useAxiosContext} from '../context/AxiosContext';

export interface UsePermissionState {
  userPermissions: Array<UserPermissions> | null;
}

export function usePermissions(featureName: FeatureName): UsePermissionState {
  const navigate = useNavigate();
  const {useAxiosBFF} = useAxiosContext();

  const [userPermissions, setUserPermissions] = useState<Array<UserPermissions> | null>(null);

  const [{data: getUserPermissionsData, error: getUserPermissionsError}, getPermissions] = useAxiosBFF<Feature>(
    `/${Path.FEATURE}/${featureName}`,
    {manual: true}
  );
  useEffect(() => {
    getPermissions();
  }, []);
  useEffect(() => {
    if (getUserPermissionsData) {
      setUserPermissions(getUserPermissionsData?.actions || []);
    }
  }, [getUserPermissionsData]);

  useEffect(() => {
    if (getUserPermissionsError) {
      NotificationService.getInstance().sendNotification(
        getUserPermissionsError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [getUserPermissionsError]);

  useEffect(() => {
    if (
      getUserPermissionsData?.actions &&
      !getUserPermissionsData.actions?.some((permission: string) => permission === UserPermissions.VIEW)
    ) {
      navigate('/' + Path.DASHBOARD);
    }
  }, [getUserPermissionsData]);

  return {userPermissions};
}
