import React from 'react';
import SingleLayoutComponent from './shared/SingleLayoutComponent';
import CoRuleEditorUpdate from './editors/CoRuleEditorUpdate';
import {FormCoRule} from '../model/FormCoRule';
import {useSharedEntitiesContext} from '../contexts/SharedEntitiesContext';
import {CoruleProvider} from '../contexts/CoRuleContext';

interface Props {
  corule: FormCoRule;
}

export default function SingleCoRuleComponent({corule}: Props) {
  const editor = <CoRuleEditorUpdate corule={corule} />;
  const {roles} = useSharedEntitiesContext();

  function resolveRoleName(roleId: string) {
    return roles?.find((r) => r.role_id === roleId)?.role_name ?? roleId;
  }

  let label = corule.corule_type;
  if (corule.all_roles) {
    label += ` [default]`;
  } else if (corule.role_ids.length) {
    label += ` [${corule.role_ids.map(resolveRoleName).join(',')}]`;
  }
  return (
    <CoruleProvider corule={corule}>
      <SingleLayoutComponent label={label} editor={editor} disabled={corule.disabled} />
    </CoruleProvider>
  );
}
