import React, {useState} from 'react';
import {Alert, Button, CircularProgress, Grid, Typography} from '@mui/material';
import ModalWrapper from './ModalWrapper';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  title: string;
  editor?: React.ReactNode;
  children?: React.ReactNode[];
  loading?: boolean;
}

export default function ListLayoutComponent({title, editor, children, loading}: Props) {
  const [open, setOpen] = useState(false);
  return (
    <Grid container spacing={0} sx={{paddingLeft: 10}}>
      <Grid item sx={{marginBottom: 1}}>
        <Typography variant={'h6'}>
          {title}
          <Button
            size={'small'}
            disabled={!editor}
            onClick={() => setOpen((prev) => !prev)}
            variant={'text'}
            sx={{marginLeft: '1rem'}}
          >
            <AddIcon />
          </Button>
        </Typography>
      </Grid>
      {loading && (
        <Grid item>
          <CircularProgress size={28} />
        </Grid>
      )}
      <ModalWrapper open={open} handleClose={() => setOpen(false)}>
        {editor}
      </ModalWrapper>
      {children?.length ? (
        <Grid item xs={12}>
          {children}
        </Grid>
      ) : children?.length === 0 ? (
        <Grid item xs={12}>
          <Typography sx={{paddingLeft: 5, marginBottom: 2}}>nothing here</Typography>
        </Grid>
      ) : !loading ? (
        <Grid item xs={12}>
          <Alert severity={'warning'} sx={{paddingLeft: 5, marginBottom: 2}}>
            something went wrong
          </Alert>
        </Grid>
      ) : null}
    </Grid>
  );
}
