import Button from '@mui/material/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import {PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

interface ConfirmationDialogProps {
  message: string;
  headline?: string;
  isDialogOpen: boolean;
  handleClose: Function;
}

function ConfirmationDialog({
  message,
  headline,
  isDialogOpen,
  handleClose,
  children,
}: PropsWithChildren<ConfirmationDialogProps>) {
  const {t} = useTranslation();

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={() => handleClose()}
        maxWidth="md"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">
          {headline}
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            id="confirmation-dialog-close-btn"
            sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-content">
            <Typography variant="h4" component="span">
              {message}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} id="confirmation-dialog-cancel-btn" color="secondary">
            {t('shared.cancel')}
          </Button>
          {children}
        </DialogActions>
      </Dialog>
    </>
  );
}
export default ConfirmationDialog;
