import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AudioPlayer from '../../../components/AudioPlayer';
import {MetaFile} from '../../../model/MetaFiles';
import {ResponseFile} from '../../../model/Files';
import {useFiles} from '../../../UseFiles';

interface AudioFileDialogProps {
  isOpen: boolean;
  handleClose: Function;
  audioMetaFile?: MetaFile;
}

function AudioFileDialog({isOpen, handleClose, audioMetaFile}: AudioFileDialogProps) {
  const EMPTY = 'empty';
  const NULL = 'null';
  const [audioDuration, setAudioDuration] = useState<string>('--:--');
  const [audioUrl, setAudioUrl] = useState<string>(EMPTY);
  const {t} = useTranslation();
  const {getFile} = useFiles();

  useEffect(() => {
    setAudioDuration('--:--');
    if (audioMetaFile) {
      getFile(audioMetaFile)
        .then((file: ResponseFile) => {
          setAudioUrl(file.url);
        })
        .catch(() => setAudioUrl(NULL));
    }
  }, [audioMetaFile]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="audio-file-dialog-title"
    >
      <DialogTitle id="audio-file-dialog-title">
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{p: 5}}>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Typography component={'div'} sx={{mb: 1, wordBreak: 'break-all'}}>
              <b>{t('file.name')}: </b>
              {audioMetaFile?.name}
            </Typography>
            <Typography sx={{mb: 1}}>
              <b>{t('file.file-audio-duration')}:</b> {audioDuration}
            </Typography>
          </Grid>
          <Grid container item sm={12} sx={{my: 2}}>
            {audioUrl?.length > EMPTY.length && <AudioPlayer audioLink={audioUrl} setCallDuration={setAudioDuration} />}
            {audioUrl === EMPTY && <CircularProgress color="inherit" />}
            {audioUrl === NULL && <Typography color={'red'}>{t('file.file-not-found')}</Typography>}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AudioFileDialog;
