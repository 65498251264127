import React from 'react';
import SystemSettingsPageContainer from '../SystemSettingsPageContainer';
import {SystemSettingsPages} from '../model/SysPageInfo';
import FrontendConfigPageComponent from './FrontendConfigPageComponent';

export default function SystemSettingsFrontendConfigPage() {
  return (
    <SystemSettingsPageContainer page={SystemSettingsPages.FRONTEND_CONFIG}>
      <FrontendConfigPageComponent />
    </SystemSettingsPageContainer>
  );
}
