import {Lead} from '../model/Lead';

export function replaceScriptPlaceholders(script?: string, values?: Lead | null): string {
  let newScript = script ?? '';
  const keysPattern = /{(.+?)}/g;
  let key = null;
  const keys: string[] = [];
  while ((key = keysPattern.exec(newScript)) !== null) {
    keys.push(key[1]);
  }
  keys?.forEach((key: string) => {
    if (values && values[key] !== undefined) {
      const newValue =
        key === 'first_name'
          ? values[key]?.replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) => letter.toUpperCase())
          : values[key];
      newScript = newScript?.replace(`{${key}}`, newValue);
    }
  });
  return newScript;
}

export const CALL_NOW_CAMPAIGN_PREFIX = 'call_now_';
