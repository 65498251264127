import {DynamicSourceType} from './logic/FormSchema';
import {FormHelper} from './FormHelper';
import FieldsApiClient from '../services/api/FieldsApiClient';
import {traverseSchemaFields} from './logic/utils';
import {pushIfAbsent} from './utils';

export class DynamicHelper {
  constructor(private fieldsApiClient: FieldsApiClient) {}

  async resolveDynamicStuff(helper: FormHelper): Promise<FormHelper> {
    // step 1. collect all distinct dynamic_sources (as string array) to prevent duplicate requests
    const dynamic_sources: string[] = [];
    traverseSchemaFields(helper.schema, (field) => {
      if (field.dynamic_source && field.dynamic_type === DynamicSourceType.ONDEMAND) {
        pushIfAbsent(dynamic_sources, field.dynamic_source);
      }
    });

    // step 2. fetch all dynamic_sources from BE in parallel
    const dynamic_options_by_source = await this.fieldsApiClient.getDynamicOptionsInParallel(dynamic_sources);

    // step 3. overwrite field options with dynamic_options fetched from BE
    traverseSchemaFields(helper.schema, (field) => {
      if (
        field.dynamic_source &&
        field.dynamic_type === DynamicSourceType.ONDEMAND &&
        dynamic_options_by_source.has(field.dynamic_source)
      ) {
        field.options = dynamic_options_by_source.get(field.dynamic_source);
      }
    });

    return helper;
  }
}
