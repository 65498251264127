import {useTranslation} from 'react-i18next';
import {Box, Chip, Divider, Grid, List, ListItem, ListItemText, Typography} from '@mui/material';
import {Face} from '@mui/icons-material';
import {SpartanHistoryItem, SpartanHistoryItemChange} from '../../model/Lead';
import {formatDateTime} from '../../utils/DateUtils';
import {useEffect, useState} from 'react';
import {FormFieldType} from '../logic/FormSchema';
import {FeatureName} from '../../../paths';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

interface SpartanHistoryFieldProps {
  leadId: string;
}

const parse = (value: string) => {
  return value && JSON.parse(value);
};

export function SpartanHistoryField({leadId}: SpartanHistoryFieldProps) {
  const {useAxiosBFF} = useAxiosContext();
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const [history, setHistory] = useState<Array<SpartanHistoryItem> | null>(null);

  const getSortedHistory = (
    records: Array<SpartanHistoryItem>,
    value: string,
    index: number
  ): Array<SpartanHistoryItemChange> => {
    const record = parse(value);
    return Object.keys(record).map((key) => {
      if (key === FormFieldType.COMMENTS) {
        const comments: Array<string> = [];
        record.comments.forEach((comment: Array<string>) => {
          comments.push(`${comment[0]} → ${comment[1]}`);
        });
        return {
          key,
          comments,
        };
      } else {
        let before = t('history.no-value');
        let found = false;
        // check until reach older value
        records?.forEach((_record: SpartanHistoryItem) => {
          const change = parse(_record.change);
          const keys = Object.keys(change);
          keys?.forEach((next: string) => {
            if (key === next && !found) {
              before = change[key];
              found = true;
            }
          });
        });
        return {
          before,
          current: record[key],
          key,
        };
      }
    });
  };
  const [{error: hasGetHistoryError, response: getHistoryResponse, loading: isGetHistoryLoading}] = useAxiosBFF<
    ResponseListWrapper<SpartanHistoryItem>
  >(
    {
      method: 'GET',
      url: `${FeatureName.LEADS}/${leadId}/history`,
    },
    {useCache: false}
  );

  useEffect(() => {
    if (getHistoryResponse) {
      setHistory(getHistoryResponse?.data.results);
    }
  }, [getHistoryResponse]);

  useEffect(() => {
    setLoading(isGetHistoryLoading, `SpartanHistoryField/${leadId}`);
  }, [isGetHistoryLoading, leadId]);

  useEffect(() => {
    if (hasGetHistoryError) {
      NotificationService.getInstance().sendNotification(
        hasGetHistoryError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasGetHistoryError]);

  function getEditSource(editSource: string | undefined) {
    const translatedEditSource = t(`history.edit-source.${editSource}`.replace(/\s/g, '-'));
    if (translatedEditSource !== `history.edit-source.${editSource}`.replace(/\s/g, '+')) {
      return translatedEditSource;
    }
    return editSource;
  }

  return (
    <Box
      sx={{
        overflowY: 'scroll',
        overflowX: 'visible',
        maxHeight: 500,
      }}
    >
      {history &&
        history?.map((historyItem: SpartanHistoryItem, index: number) => (
          <Grid
            container
            flexDirection={'row'}
            key={historyItem?.history_id}
            spacing={3}
            sx={{py: (theme) => `${theme.spacing(2)}`}}
          >
            <Grid item xs={12} md={4}>
              <Chip
                icon={<Face />}
                key={historyItem?.created_by?.username}
                label={historyItem?.created_by?.username}
                sx={{mb: (theme) => `${theme.spacing(1)}`}}
              />
              <Typography variant="body2">{formatDateTime(historyItem?.created_at)}</Typography>
              <Typography variant="body2">
                {t('history.updated-on')}: {getEditSource(historyItem?.edit_source)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <List sx={{overflow: 'auto', position: 'relative', width: '100%'}}>
                {getSortedHistory(history?.slice(index + 1, history.length), historyItem?.change, index).map(
                  (historyItemChange: SpartanHistoryItemChange) => {
                    return (
                      <ListItem key={`record_${historyItemChange?.key}`} disableGutters>
                        <ListItemText>
                          <Grid container>
                            <Grid item>
                              <Typography
                                variant="h6"
                                sx={{display: 'inline-block', pr: (theme) => ` ${theme.spacing(1)}`}}
                              >
                                {historyItemChange?.key.replace(/_/g, ' ').replace(/"\s+|\s+"/g, '"')}:
                              </Typography>
                            </Grid>
                            <Grid item>
                              {!historyItemChange?.key.includes(FormFieldType.COMMENTS) && (
                                <Typography variant="body1" sx={{display: 'inline-block'}}>{`${t('history.before')}: ${
                                  historyItemChange?.before
                                } → ${t('history.current')}: ${historyItemChange?.current}`}</Typography>
                              )}
                              {historyItemChange?.key.includes(FormFieldType.COMMENTS) &&
                                historyItemChange?.comments?.map((comment) => {
                                  return (
                                    <Typography variant="body1" sx={{whiteSpace: 'pre-line'}}>
                                      {comment}
                                    </Typography>
                                  );
                                })}
                            </Grid>
                          </Grid>
                        </ListItemText>
                      </ListItem>
                    );
                  }
                )}
              </List>
            </Grid>
            <Divider sx={{width: '100%', mt: (theme) => `${theme.spacing(2)}`}} />
          </Grid>
        ))}
      {!history && <Typography variant="h6">{t('history.no-history-msg')}</Typography>}
    </Box>
  );
}
