export default class LocalStorageService {
  private static INSTANCE = new LocalStorageService();

  static getInstance(): LocalStorageService {
    return LocalStorageService.INSTANCE;
  }

  get(key: string): string {
    return window.localStorage.getItem(key) || '';
  }

  set(key: string, value: string): void {
    window.localStorage.setItem(key, value);
  }

  getObject(key: string): object {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : {};
  }

  setObject(key: string, value: object): void {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
}
