import React, {PropsWithChildren, StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import App from './app/App';
import './i18n/config';
import 'react-dropzone-uploader/dist/styles.css';
import reportWebVitals from './reportWebVitals';
import {ContextsProvider} from './app/context/ContextsProvider';
import {env} from './env';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

const StrictModeWrapper = ({children, strictModeEnabled}: PropsWithChildren<{strictModeEnabled: boolean}>) =>
  strictModeEnabled ? <StrictMode>{children}</StrictMode> : <>{children}</>;

root.render(
  <StrictModeWrapper strictModeEnabled={env.STRICT_MODE}>
    <ContextsProvider>
      <App />
    </ContextsProvider>
  </StrictModeWrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
