import {useEffect, useState} from 'react';

import {Looker40SDK} from '@looker/sdk';
import {AuthSession, AuthToken, BrowserTransport} from '@looker/sdk-rtl';
import {ApiSettings, IApiSettings} from '@looker/sdk-rtl/lib/apiSettings';
import {IRequestProps} from '@looker/sdk-rtl/lib/transport';
import {useAuth0} from '@auth0/auth0-react';

class SDKSession extends AuthSession {
  private lock = false;
  private activeToken = new AuthToken();
  getAccessTokenSilently: () => Promise<string>;

  constructor(settings: IApiSettings, getAccessTokenSilently: () => Promise<string>) {
    super(settings, new BrowserTransport(settings));
    this.getAccessTokenSilently = getAccessTokenSilently;
  }

  // This function checks to see if the user is already authenticated
  isAuthenticated() {
    return this.activeToken.isActive();
  }

  // This function gets the current token or fetches a new one if necessary
  async getToken() {
    while (this.lock) {
      // if exists another concurrent request, wait...
      await new Promise((resolve) => setTimeout(resolve, 200));
    }
    this.lock = true;
    if (!this.isAuthenticated()) {
      const auth0token = await this.getAccessTokenSilently();
      const response = await fetch(`/api/looker/auth`, {
        headers: {authorization: `bearer ${auth0token}`},
      });
      const token = await response.json();
      this.activeToken.setToken(token);
    }
    this.lock = false;
    return this.activeToken;
  }

  // This function authenticates a user, which involves getting a new token
  // It returns a modified object with a new authorization header.
  async authenticate(props: IRequestProps) {
    const token = await this.getToken();
    if (token && token.access_token) {
      props.mode = 'cors';
      delete props.credentials;
      props.headers = {
        ...props.headers,
        Authorization: `Bearer ${this.activeToken.access_token}`,
      };
    }
    return props;
  }
}

const defaultSettings = new ApiSettings({base_url: 'https://spartanapproach.cloud.looker.com'});

export default function useLookerSdk(): Looker40SDK | undefined {
  const {isAuthenticated, getAccessTokenSilently} = useAuth0();
  const [sdk, setSdk] = useState<Looker40SDK>();

  useEffect(() => {
    if (isAuthenticated) {
      setSdk(new Looker40SDK(new SDKSession(defaultSettings, getAccessTokenSilently)));
    } else {
      setSdk(undefined);
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  return sdk;
}
