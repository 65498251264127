import {useTranslation} from 'react-i18next';
import {DialogContent, DialogContentText, Typography} from '@mui/material';
import SpartanDialog from '../../components/dialogs/SpartanDialog';

interface DropzoneWrapperConfirmationDialogProps {
  isDialogOpen: boolean;
  handleClose: Function;
  handleConfirm: Function;
  confirmationTitle: string;
  confirmationBody: string;
}

function DropzoneWrapperConfirmationDialog({
  isDialogOpen,
  handleClose,
  handleConfirm,
  confirmationTitle,
  confirmationBody,
}: DropzoneWrapperConfirmationDialogProps) {
  const {t} = useTranslation();
  return (
    <SpartanDialog
      headline={confirmationTitle}
      isDialogOpen={isDialogOpen}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      confirmationMessage={t('shared.accept')}
    >
      <DialogContent>
        <DialogContentText id="confirmation-dialog-content-3" sx={{width: '100%'}}>
          <Typography variant="h4">{confirmationBody}</Typography>
        </DialogContentText>
      </DialogContent>
    </SpartanDialog>
  );
}

export default DropzoneWrapperConfirmationDialog;
