import {Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography} from '@mui/material';
import React, {PropsWithChildren, useEffect, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {Bucket} from '../../../model/Campaign';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import {useAxiosContext} from '../../../context/AxiosContext';
import {useLoading} from '../../../context/LoadingContext';

interface ConfirmationDialogProps {
  bucketName: string;
  localPresence: boolean;
  isDialogOpen: boolean;
  handleClose: Function;
}

function BucketDialog({
  bucketName,
  localPresence,
  isDialogOpen,
  handleClose,
  children,
}: PropsWithChildren<ConfirmationDialogProps>) {
  const {useAxiosBFF} = useAxiosContext();
  const {setLoading} = useLoading();
  const [bucket, setBucket] = useState<Bucket>();
  const [
    {response: getBucketNumbersResponse, error: getBucketNumbersError, loading: getBucketNumbersLoading},
    getBucketNumbers,
  ] = useAxiosBFF<Bucket>(
    {
      url: `cmpsettings/bucket/${bucketName}/?`,
      method: 'GET',
      params: {local_presence: localPresence},
    },
    {manual: true}
  );

  useEffect(() => {
    setLoading(getBucketNumbersLoading, 'BucketDialog');
  }, [getBucketNumbersLoading]);

  useEffect(() => {
    if (isDialogOpen && localPresence && bucketName) {
      getBucketNumbers();
    }
    return () => {
      setBucket(undefined);
    };
  }, [isDialogOpen, localPresence, bucketName]);

  useEffect(() => {
    if (getBucketNumbersResponse) {
      setBucket(getBucketNumbersResponse?.data);
    }
  }, [getBucketNumbersResponse]);

  useEffect(() => {
    if (getBucketNumbersError) {
      NotificationService.getInstance().sendNotification(
        getBucketNumbersError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [getBucketNumbersError]);
  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={() => handleClose()}
        maxWidth={'xs'}
        fullWidth={true}
        aria-labelledby="bucket-dialog"
        sx={{textAlign: 'center'}}
      >
        <DialogTitle id="bucket-dialog-title">
          <Typography variant={'h2'}>{bucketName}</Typography>
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            id="bucket-dialog-close-btn"
            sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent id={'bucket-dialog-content'}>
          {bucket &&
            bucket?.caller_ids?.map((caller_id: string) => {
              return (
                <>
                  <Divider />
                  <Box sx={{margin: '15px'}}>
                    <Typography variant={'h5'}>{caller_id}</Typography>
                  </Box>
                </>
              );
            })}
          {bucket && !bucket?.caller_ids && (
            <Box sx={{margin: '15px'}}>
              <Typography variant={'h5'}>{'No DIDS'}</Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
export default BucketDialog;
