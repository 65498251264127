import {Alert, Typography} from '@mui/material';
import React from 'react';
import {SpartanFieldProps} from './model';

export function SpartanNoteField(props: SpartanFieldProps) {
  const {label} = props;
  return (
    <Alert severity="info">
      {label && <Typography style={{fontSize: 'large'}} dangerouslySetInnerHTML={{__html: label}} />}
    </Alert>
  );
}
