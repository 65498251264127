import {FormControlLabel, FormLabel, Radio, RadioGroup} from '@mui/material';
import {propsWithTestAttributes, SpartanFieldOption, SpartanFieldWithOptionProps} from './model';
import {ErrorMessage} from './components/ErrorMessage';
import {SpartanLabelWithTooltip} from './SpartanLabelWithTooltip';

export function SpartanRadioGroupField(_props: SpartanFieldWithOptionProps) {
  const props = propsWithTestAttributes(_props);
  const {id, name, value, label, disabled, onChange, onBlur, onFocus, hasError, error, options, inputProps} = props;
  return (
    <>
      <FormLabel error={hasError} htmlFor={id}>
        <SpartanLabelWithTooltip label={label} />
      </FormLabel>
      <RadioGroup
        id={id}
        aria-label={label}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        {options.map((item: SpartanFieldOption, idx: number) => (
          <FormControlLabel
            key={idx}
            name={name}
            disabled={disabled}
            label={item.label}
            value={item.value}
            control={<Radio color="primary" inputProps={inputProps} />}
          />
        ))}
      </RadioGroup>
      <ErrorMessage hasError={hasError} error={error} />
    </>
  );
}
