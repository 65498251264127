import {InputLabel, TextField} from '@mui/material';
import React from 'react';
import {propsWithTestAttributes, SpartanTextFieldProps, wrapInTooltipIfNecessary} from './model';
import {ErrorMessage} from './components/ErrorMessage';
import {SpartanLabelWithTooltip} from './SpartanLabelWithTooltip';

/*
NOTE:
Do NOT use helperText in TextField, since it is not available for all components and it has a different style.
Use ErrorMessage instead.
*/

export function SpartanTextField(_props: SpartanTextFieldProps) {
  const props = propsWithTestAttributes(_props);
  const {
    id,
    name,
    value,
    label,
    placeholder,
    disabled,
    onChange,
    onBlur,
    onFocus,
    hasError,
    error,
    inputProps,
    InputProps,
    inputRef,
    isNestedLabel = false,
  } = props;

  return (
    <>
      {!isNestedLabel && (
        <InputLabel error={hasError} htmlFor={id}>
          <SpartanLabelWithTooltip label={label} />
        </InputLabel>
      )}
      {wrapInTooltipIfNecessary(
        props,
        <TextField
          id={id}
          fullWidth={true}
          placeholder={placeholder || label}
          label={isNestedLabel ? label : undefined}
          name={name}
          value={value}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          error={hasError}
          inputProps={inputProps}
          InputProps={InputProps}
          inputRef={inputRef}
        />
      )}

      <ErrorMessage hasError={hasError} error={error} />
    </>
  );
}
