import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Grid, InputLabel, MenuItem, Select, Typography} from '@mui/material';
import {SpartanFieldOption} from './model';
import LeadService from '../../services/LeadService';
import {LeadComment} from '../../model/LeadComment';
import CommentList from './components/CommentList';

interface SpartanCommentFieldProps {
  comments: Array<LeadComment>;
  options: SpartanFieldOption[];
}

const ALL = 'all';

export function SpartanCommentField({comments, options}: SpartanCommentFieldProps) {
  const {t} = useTranslation();
  const [sectionFilter, setSectionFilter] = useState<string>(ALL);
  const [filteredComments, setFilteredComments] = useState<Array<LeadComment>>(comments);
  const [unsavedLeadComments, setUnsavedLeadComments] = useState<Array<LeadComment>>([]);
  function handleFilterByChange(e: any) {
    setSectionFilter(e.target.value);
  }

  useEffect(() => {
    const filteredCommentsOrEmptyArray = comments?.filter(
      (comment) => comment?.section === sectionFilter || sectionFilter === ALL
    );
    setFilteredComments(filteredCommentsOrEmptyArray);
  }, [sectionFilter, comments]);

  useEffect(() => {
    const subscription = LeadService.getInstance()
      .getUnsavedComments()
      .subscribe((unsavedComments: Array<LeadComment>) => {
        setUnsavedLeadComments([...unsavedComments]);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          overflowY: 'scroll',
          overflowX: 'visible',
          maxHeight: 500,
          paddingTop: (theme) => ` ${theme.spacing(3)}`,
        }}
      >
        {filteredComments ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} sx={{pb: (theme) => ` ${theme.spacing(3)}`}}>
              <InputLabel>{t('comments.filter-by')}</InputLabel>
              <Select
                id="comment_filter_by"
                value={sectionFilter}
                onChange={handleFilterByChange}
                labelId="comment-filter-by"
                fullWidth
                label={t('comments.filter-by')}
              >
                {options?.map((option: SpartanFieldOption, index: number) => (
                  <MenuItem key={`comment_filter_option_${index}`} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={12}>
              <CommentList
                comments={filteredComments}
                accordionTitle={`${t('comments.comments')} (${filteredComments?.length})`}
              />
            </Grid>
          </Grid>
        ) : (
          !filteredComments && <Typography variant="h6">{`${t('comments.no-comments-msg')}`}</Typography>
        )}

        {unsavedLeadComments?.length > 0 ? (
          <CommentList
            comments={unsavedLeadComments}
            accordionTitle={`${t('comments.unsaved-comments')} (${unsavedLeadComments?.length})`}
          />
        ) : (
          <Typography variant="h6" sx={{marginTop: '1rem'}}>{`${t('comments.unsaved-comments')} (${
            unsavedLeadComments.length
          })`}</Typography>
        )}
      </Box>
    </>
  );
}
