import {NextgenFormTrigger, NextgenFormTriggerCondition} from './NextgenFormSchema';
import {
  FormFieldTrigger,
  FormFieldTriggerAction,
  FormFieldTriggerActionEval,
  FormFieldTriggerActionSetTimestamp,
  FormFieldTriggerActionSetValue,
} from '../../logic/triggers/Trigger';
import {
  FormFieldConditionOrBool,
  FormFieldMultipleCondition,
  FormFieldSingleCondition,
} from '../../logic/conditions/Condition';
import {MultiValueMap, requireNotUndefined} from '../../utils';

const SERIALIZED_ACTION = 'serialized_action';
const SUPPORTED_TRIGGER_VALUE_TYPES = [SERIALIZED_ACTION];

function toFormFieldTriggerAction(trigger: NextgenFormTrigger): FormFieldTriggerAction {
  if (trigger.value_type === SERIALIZED_ACTION) {
    requireNotUndefined(trigger.value, 'Missing trigger value');

    const action = JSON.parse(trigger.value);
    requireNotUndefined(action, 'Missing trigger action');

    const type = action.type;
    requireNotUndefined(type, 'Missing trigger action type');

    if (type === 'eval') {
      const expression = action.expression;
      requireNotUndefined(expression, "Malformed 'eval' action: missing expression");
      return {type, expression} as FormFieldTriggerActionEval;
    }

    if (type === 'set_value') {
      const value = action.value;
      requireNotUndefined(value, "Malformed 'set_value' action: missing value");
      return {type, value} as FormFieldTriggerActionSetValue;
    }

    if (type === 'set_timestamp') {
      return {type} as FormFieldTriggerActionSetTimestamp;
    }

    throw Error(`Unsupported trigger action type '${type}'`);
  }

  throw Error(`Unsupported trigger value_type '${trigger.value_type}'`);
}

function toFormFieldConditionOrBool(condition: NextgenFormTriggerCondition): FormFieldConditionOrBool {
  if (condition.type === 'single_condition') {
    return {
      type: 'single_condition',
      field_name: condition.trigger,
      operator: condition.operator,
      value: condition.value,
    } as FormFieldSingleCondition;
  } else if (condition.type === 'multiple_condition') {
    return {
      type: 'multiple_condition',
      operator: condition.operator,
      value: condition.value.map(toFormFieldConditionOrBool),
    } as FormFieldMultipleCondition;
  } else {
    throw Error(`Unsupported condition '${JSON.stringify(condition)}'`);
  }
}

function toFormFieldTrigger(trigger: NextgenFormTrigger): FormFieldTrigger {
  return {
    action: toFormFieldTriggerAction(trigger),
    condition: toFormFieldConditionOrBool(trigger?.conditions),
  };
}

export function parseTriggers(triggers: NextgenFormTrigger[] = []): MultiValueMap<string, FormFieldTrigger> {
  const result = new MultiValueMap<string, FormFieldTrigger>();
  for (const trigger of triggers) {
    if (SUPPORTED_TRIGGER_VALUE_TYPES.includes(trigger.value_type)) {
      result?.add(trigger?.target_field_name, toFormFieldTrigger(trigger));
    }
  }
  return result;
}
