export interface FormBaseEntity {
  disabled?: boolean;
  index?: number;
  created_at: Date;
  created_by: string;
  last_updated_at: Date | null;
  last_updated_by: string | null;
}

export function compareByIndex<T extends FormBaseEntity>(a: T, b: T) {
  return (a.index ?? 999) - (b.index ?? 999);
}
