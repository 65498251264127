import React from 'react';
import ListLayoutComponent from './shared/ListLayoutComponent';
import SingleFieldValueComponent from './SingleFieldValueComponent';
import FieldValueEditorCreate from './editors/FieldValueEditorCreate';
import {FieldValuesProvider, useFieldValuesContext} from '../contexts/FieldValuesContext';

interface Props {
  fieldId: string;
}

function InternalFieldValuesComponent({fieldId}: Props) {
  const {fieldValues, loading} = useFieldValuesContext();
  const editor = <FieldValueEditorCreate partial={{field_id: fieldId}} />;
  const children = fieldValues?.map((fieldValue) => (
    <SingleFieldValueComponent fieldValue={fieldValue} key={fieldValue.field_value_id} />
  ));
  return <ListLayoutComponent title={'Field Values'} children={children} editor={editor} loading={loading} />;
}
export default function ListFieldValueComponent({fieldId}: Props) {
  return (
    <FieldValuesProvider fieldId={fieldId}>
      <InternalFieldValuesComponent fieldId={fieldId} />
    </FieldValuesProvider>
  );
}
