import {FormFieldOption, FormSchema} from '../FormSchema';
import {ExtendedMap, MultiValueMap} from '../../utils';
import {ComputeContext} from '../ComputeContext';
import {equal} from '../utils';

interface DependencyFieldHolder {
  dependencyFieldName: string;
  dependencyFieldValue: any;
}

export function buildOptionsFilters(formSchema: FormSchema): Map<string, FieldOptionsFilter> {
  const optionsFilterDependencies = new MultiValueMap<string, string>();
  const optionsFilterTempStruct = new ExtendedMap<string, MultiValueMap<string, DependencyFieldHolder>>();

  const formDependencies = formSchema.dependencies || {};
  for (let dependencyFieldName in formDependencies) {
    for (let dependencyFieldValue in formDependencies[dependencyFieldName]) {
      for (let fieldName in formDependencies[dependencyFieldName][dependencyFieldValue]) {
        optionsFilterDependencies.add(fieldName, dependencyFieldName);
        let multiValueMap = optionsFilterTempStruct.computeIfAbsent(fieldName, () => new MultiValueMap());
        for (let fieldValue of formDependencies[dependencyFieldName][dependencyFieldValue][fieldName]) {
          multiValueMap.add(fieldValue, {dependencyFieldName, dependencyFieldValue});
        }
      }
    }
  }

  function buildOptionsFilter(fieldName: string): FieldOptionsFilter {
    const dependencies = optionsFilterTempStruct.get(fieldName);
    if (dependencies) {
      const compute = (options: FormFieldOption[], values: any, defaultCtx: ComputeContext | null) => {
        const results: FormFieldOption[] = [];
        for (const option of options) {
          const dependencyFields = dependencies.get(option.value) || [];
          for (const dependencyField of dependencyFields) {
            const result = equal(values[dependencyField.dependencyFieldName], dependencyField.dependencyFieldValue);
            if (result) {
              results.push(option);
            }
          }
        }
        return results;
      };
      return {compute, dependencies: optionsFilterDependencies.get(fieldName) || []};
    }
    return DummyOptionsFilter;
  }

  const optionsFilters = new ExtendedMap<string, FieldOptionsFilter>();
  for (let fieldName of Array.from(optionsFilterTempStruct.keys())) {
    optionsFilters.set(fieldName, buildOptionsFilter(fieldName));
  }
  return optionsFilters;
}

const DummyOptionsFilter: FieldOptionsFilter = {
  dependencies: [],
  compute: (options: FormFieldOption[], values: any, ctx: ComputeContext | null) => [],
};

export interface FieldOptionsFilter {
  dependencies: string[]; // possible other fields mentioned in the condition
  compute: (options: FormFieldOption[], values: any, ctx: ComputeContext | null) => FormFieldOption[];
}
