import * as am4core from '@amcharts/amcharts4/core';
import {Legend, PieChart, PieSeries} from '@amcharts/amcharts4/charts';

import {useEffect} from 'react';
import Typography from '@mui/material/Typography';

interface PieChartProps {
  name: string;
}

const graphData = [
  {
    name: 'Age',
    data: [
      {
        label: 'Duplicated Values',
        value: 7000,
      },
      {
        label: 'Distinct Values',
        value: 8000,
      },
      {
        label: 'Missing Values',
        value: 2000,
      },
    ],
  },
  {
    name: 'Status',
    data: [
      {
        label: 'Duplicated Values',
        value: 5000,
      },
      {
        label: 'Distinct Values',
        value: 10000,
      },
      {
        label: 'Missing Values',
        value: 7500,
      },
    ],
  },
];

function FlatPieChart({name}: PieChartProps) {
  let chart: PieChart;

  useEffect(() => {
    chart = am4core.create('pie-chart-' + name, PieChart);
    createChart(chart);
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [name]);

  function createChart(chart: PieChart) {
    // Add and configure Series
    let pieSeries = chart.series.push(new PieSeries());
    pieSeries.fontFamily = 'Quicksand';
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'label';

    // Let's cut a hole in our Pie chart the size of 30% the radius
    chart.innerRadius = am4core.percent(30);

    // Put a thick white border around each Slice
    pieSeries.slices.template.stroke = am4core.color('#fff');
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    // change the cursor on hover to make it apparent the object can be interacted with
    pieSeries.slices.template.cursorOverStyle = [
      {
        property: 'cursor',
        value: 'pointer',
      },
    ];

    pieSeries.alignLabels = false;
    pieSeries.labels.template.bent = true;
    pieSeries.labels.template.radius = 3;
    pieSeries.labels.template.padding(0, 0, 0, 0);

    pieSeries.ticks.template.disabled = true;
    // Create a base filter effect (as if it's not there) for the hover to return to
    let shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter());
    shadow.opacity = 0;

    // Create hover state
    let hoverState = pieSeries.slices.template.states.getKey('hover');

    // Slightly shift the shadow and make it more prominent on hover
    let hoverShadow = hoverState?.filters.push(new am4core.DropShadowFilter());

    if (hoverShadow) {
      hoverShadow.opacity = 0.7;
      hoverShadow.blur = 5;
    }

    let legend = (chart.legend = new Legend());
    legend.fontFamily = 'Quicksand';

    chart.data = graphData.find((g) => g.name === name)?.data || [];
  }

  return (
    <>
      <Typography variant={'h3'}>{name} Pie Chart</Typography>
      <div id={'pie-chart-' + name} style={{width: '100%', height: '500px'}}></div>
    </>
  );
}

export default FlatPieChart;
