export enum TaskStatus {
  TODO = 'to_do',
  CANCELLED = 'cancelled',
  OVERDUE = 'overdue',
  DONE = 'done',
}

interface TaskAssignee {
  value: string;
  label: string;
}
interface TaskType {
  value: string;
  label: string;
}
export interface Task {
  task_id: string;
  feature_id: string;
  feature_item_id: string;
  due: string;
  description: string;
  created_at: string;
  created_by: string;
  type: TaskType;
  status: TaskStatus;
  assignee: string;
}

export interface TaskListItem {
  task_id: string;
  due: string;
  description: string;
  created_at: string;
  type: TaskType;
  status: TaskListItemStatus;
  assignee: TaskAssignee;
}

interface TaskListItemStatus {
  value: string;
  label: string;
}
