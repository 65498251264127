import {useCallback} from 'react';
import {useConfig} from '../../../../context/ConfigContext';
import {useFetch} from './useFetch';

export interface UseFormAdminFetch {
  get: (path: string) => Promise<any>;
  post: (path: string, body: any) => Promise<any>;
  put: (path: string, body: any) => Promise<any>;
  patch: (path: string, body: any) => Promise<any>;
  sendDelete: (path: string) => Promise<any>;
}

export function useFormEditorFetch(): UseFormAdminFetch {
  const {send} = useFetch();
  const {FORM_EDITOR_API_PATH} = useConfig();
  const get = useCallback(async (path: string): Promise<any> => send('GET', `${FORM_EDITOR_API_PATH}/${path}`), [send]);
  const post = useCallback(
    async (path: string, body: any): Promise<any> => send('POST', `${FORM_EDITOR_API_PATH}/${path}`, body),
    [send]
  );
  const put = useCallback(
    async (path: string, body: any): Promise<any> => send('PUT', `${FORM_EDITOR_API_PATH}/${path}`, body),
    [send]
  );
  const patch = useCallback(
    async (path: string, body: any): Promise<any> => send('PATCH', `${FORM_EDITOR_API_PATH}/${path}`, body),
    [send]
  );
  const sendDelete = useCallback(
    async (path: string): Promise<any> => send('DELETE', `${FORM_EDITOR_API_PATH}/${path}`),
    [send]
  );
  return {get, post, put, patch, sendDelete};
}
