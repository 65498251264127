import React, {useEffect, useState} from 'react';
import {useRoutes} from 'react-router-dom';
import routes from '../routes';
import KommunicateChat from './components/chatbot/ChatBot';
import CookieConsent from './components/dialogs/CookieConsent';
import {useAuth0} from '@auth0/auth0-react';
import Notification from './components/shared/Notification';
import {LicenseInfo} from '@mui/x-license-pro';
import {Auth0User, getUserId} from './model/Auth0User';
import UserRoleService from './services/UserRoleService';
import OrganizationService from './services/OrganizationService';
import Hotjar from '@hotjar/browser';
import {FeatureName} from '../paths';
import {UserResponse} from './services/model/UserResponse';
import UserService from './services/UserService';
import {Organization} from './model/Organization';

// @ts-ignore
import {AnalyticsProvider} from 'use-analytics';
import PageViewTracker from './components/shared/PageViewTracker';
import SessionTimeout from './components/dialogs/SessionTimeout';
import Auth0ContextHolder from './services/Auth0ContextHolder';
import ErrorBoundary from './form/components/ErrorBoundary';
import {analyticsInstanceBuilder} from './analytics/Analytics';
import {AnalyticsInstance} from 'analytics';
import {useAxiosContext} from './context/AxiosContext';
import {useConfig} from './context/ConfigContext';
import {useLoading} from './context/LoadingContext';
import Loading from './components/shared/Loading';

function App() {
  const config = useConfig();
  const {useAxiosBFF} = useAxiosContext();
  const auth0 = useAuth0<Auth0User>();
  const [analyticsInstance, setAnalyticsInstance] = useState<AnalyticsInstance>(analyticsInstanceBuilder(false));
  const {isAuthenticated, isLoading, user} = auth0;
  const routing = useRoutes(routes(isAuthenticated, user, config));
  const userId = getUserId(user);
  const {setLoading} = useLoading();
  const [{data: userResponse}] = useAxiosBFF<UserResponse>({url: `/${FeatureName.USERS}/${userId}`}, {manual: false});

  const [{data: organizationData, loading: orgnizationLoading}, getOrganization] = useAxiosBFF<Organization>(
    {url: `/${FeatureName.ORGANIZATION}`},
    {
      manual: false,
    }
  );

  Auth0ContextHolder.init(auth0);

  useEffect(() => {
    Hotjar.init(3165938, 6);
  }, []);

  useEffect(() => {
    if (userResponse) {
      UserService.getInstance().set(UserService.getInstance().toUser(userResponse));
    }
  }, [userResponse, user]);

  useEffect(() => {
    if (user) {
      UserRoleService.getInstance().setInitialSelectedRole(user);
    }
  }, [user]);
  useEffect(() => {
    setLoading(orgnizationLoading, 'App');
  }, [orgnizationLoading]);

  useEffect(() => {
    if (user) {
      getOrganization();
    }
  }, [user]);

  useEffect(() => {
    if (organizationData) {
      OrganizationService.getInstance().setOrganization(organizationData);
    }
  }, [organizationData]);

  useEffect(() => {
    if (config.MUI_LICENSE_KEY) {
      LicenseInfo.setLicenseKey(config.MUI_LICENSE_KEY);
    }
  }, [config.MUI_LICENSE_KEY]);

  useEffect(() => {
    setAnalyticsInstance(analyticsInstanceBuilder(config.ENV_NAME === 'production'));
  }, [config.ENV_NAME]);

  return (
    <ErrorBoundary>
      <AnalyticsProvider instance={analyticsInstance}>
        <PageViewTracker />
        <CookieConsent />
        <Notification />
        <Loading />
        {!isLoading && routing}
        {isAuthenticated && <KommunicateChat />}
        {isAuthenticated && <SessionTimeout />}
      </AnalyticsProvider>
    </ErrorBoundary>
  );
}

export default App;
