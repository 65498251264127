import React, {useEffect, useState} from 'react';
import {Grid, Paper, Tab, Typography} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {PermissionFeatureProps, Resource} from './models/models';
import {TestAttributes} from '../../../../TestAttributes';
import PermissionResource from './PermissionResource';
import {ResponseListWrapper} from '../../../../services/model/ResponseListWrapper';
import {SpartanFieldOption} from '../../../../form/fields/model';
import {HttpMethods} from '../../../../model/HttpMethods';
import {getPermissions} from './utils/Utils';
import {useAxiosContext} from '../../../../context/AxiosContext';

function PermissionField({resourceId, label, roles, configActions, config, type}: PermissionFeatureProps) {
  const {useAxiosPermissionAdminAPI} = useAxiosContext();
  const featureName = 'permissions';
  const resourceType = 'field_value';
  const selectType = ['select', 'selectmultiple', 'comments'];
  const [value, setValue] = useState('0');
  const [permissions, setPermissions] = useState<Array<PermissionFeatureProps>>();
  const [fieldValues, setFieldValues] = useState<Array<Resource>>();
  const [actions, setActions] = useState<Array<string>>([]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [, getFieldValues] = useAxiosPermissionAdminAPI<ResponseListWrapper<SpartanFieldOption>>(
    {
      method: HttpMethods.GET,
    },
    {manual: true}
  );

  useEffect(() => {
    if (!fieldValues && value === '1')
      getFieldValues({url: `/${featureName}/fields/${resourceId}/field_values`}).then((response: any) => {
        setFieldValues(response.data);
      });
  }, [value]);

  useEffect(() => {
    if (config) {
      const _actions = config
        .find((conf: any) => conf.resource_type === resourceType)
        .roles[0].actions.map((action: any) => {
          return action.action_name;
        });
      _actions?.unshift('Role');
      setActions(_actions);
    }
  }, [config]);

  useEffect(() => {
    if (fieldValues && config) {
      const permissions = getPermissions(fieldValues, resourceType, config);

      setPermissions(permissions);
    }
  }, [fieldValues, config]);

  return (
    <Paper key={`papper-${resourceId}`}>
      <Typography>{label}</Typography>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="settings tabs">
          <Tab {...{[TestAttributes.TAB_NAME]: 'field-permission-tab'}} label={'field'} value="0" />
          {selectType.includes(type) && (
            <Tab {...{[TestAttributes.TAB_NAME]: 'field-values-tab'}} label={'field values'} value="1" />
          )}
        </TabList>
        <TabPanel value="0">
          <PermissionResource
            key={`tab-field-${resourceId}`}
            resourceId={resourceId}
            roles={roles}
            configActions={configActions}
            type={type}
          />
        </TabPanel>
        <TabPanel value="1">
          <Grid key={`tab-value-fields-${resourceId}`} xs={12}>
            {permissions?.map((permission) => (
              <PermissionResource
                key={`tab-value-fields-${resourceId}-${permission.resourceId}`}
                label={permission.label}
                resourceId={permission.resourceId}
                roles={permission.roles}
                configActions={actions}
                type={permission.type}
              />
            ))}
          </Grid>
        </TabPanel>
      </TabContext>
    </Paper>
  );
}

export default PermissionField;
