import React, {useState, useEffect, forwardRef, useRef, useImperativeHandle} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid, Slider, Stack, useTheme, Card, Zoom, Typography} from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CircularProgress from '@mui/material/CircularProgress';

interface AudioPlayerParams {
  audioLink?: string;
  setCallDuration: Function;
}

export type AudioHandle = {
  clipPlayClicked: (start_time: number) => void;
};

const AudioPlayer = forwardRef<AudioHandle, AudioPlayerParams>(({audioLink, setCallDuration}, externalAudioRef) => {
  const [audioDuration, setAudioDuration] = useState(0);
  const [lastSecondPlayed, setLastSecondPlayed] = useState<number>(0);
  const [playInterval, setPlayInterval] = useState<ReturnType<typeof setInterval> | null>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [showAudioSlider, setShowAudioSlider] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(100);
  const audioRef = useRef<HTMLAudioElement>(null);
  const {t} = useTranslation();
  const theme = useTheme();
  let downloadIcon = (
    <a href={audioLink} download target="_blank" rel="noreferrer">
      <CloudDownloadIcon id={'download-audio'} sx={{color: theme.palette.primary.main, cursor: 'pointer'}} />
    </a>
  );
  let playIconColor = isPlaying ? theme.palette.primary.dark : theme.palette.primary.main;
  let volumeIconColor = showAudioSlider ? theme.palette.primary.dark : theme.palette.primary.main;

  if (!audioLink) {
    downloadIcon = <CloudDownloadIcon id={'download-audio'} sx={{color: theme.palette.grey.A400, cursor: 'wait'}} />;
    playIconColor = theme.palette.grey.A400;
    volumeIconColor = theme.palette.grey.A400;
  }

  useEffect(() => {
    if (audioLink) {
      if (!isPlaying) {
        audioRef.current?.pause();
      } else {
        audioRef.current?.play();
      }
    }
  }, [isPlaying]);

  function setVolumeFromEvent(volumeEvent: Event) {
    const volume = parseInt((volumeEvent.target as HTMLInputElement).value);
    if (audioRef?.current) {
      audioRef.current.volume = volume / 100;
      setVolume(volume);
    }
  }

  function formatTime(seconds: number) {
    const roundedSeconds = Math.round(seconds);
    const minutes = Math.floor(roundedSeconds / 60);
    const remainingSeconds = roundedSeconds - minutes * 60;
    return `${minutes < 10 ? '0' + minutes : minutes}:${
      remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds
    }`;
  }

  function setAudioInterval() {
    if (!playInterval) {
      const newIntervalId = setInterval(() => {
        if (audioRef?.current?.currentTime && lastSecondPlayed !== Math.round(audioRef?.current?.currentTime)) {
          setLastSecondPlayed(Math.round(audioRef?.current?.currentTime));
        }
      }, 200);
      setPlayInterval(newIntervalId);
    }
  }

  useImperativeHandle<AudioHandle, AudioHandle>(externalAudioRef, () => ({
    clipPlayClicked(start_time) {
      if (audioRef?.current) {
        audioRef.current.currentTime = start_time;
        setIsPlaying(true);
      }
    },
  }));

  return (
    <>
      <Grid item xs={6} sx={{minWidth: 'fit-content', paddingRight: theme.spacing(1)}}>
        <Card sx={{padding: '0.5rem', width: '100%', cursor: !audioLink ? 'wait' : null}}>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} sm={4} sx={{margin: 0, textAlign: 'center'}}>
              <Grid item xs={4}>
                <PlayCircleIcon
                  id={'play-button'}
                  sx={{
                    color: playIconColor,
                    cursor: audioLink ? 'pointer' : 'wait',
                  }}
                  onClick={() => {
                    if (audioLink) {
                      setIsPlaying(!isPlaying);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                {downloadIcon}
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'block',
                }}
              >
                <VolumeUpIcon
                  id={'volume-icon'}
                  sx={{
                    color: volumeIconColor,
                    cursor: audioLink ? 'pointer' : 'wait',
                    marginRight: '1rem',
                  }}
                  onClick={() => {
                    if (audioLink) {
                      setShowAudioSlider(!showAudioSlider);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={8} sx={{width: showAudioSlider ? '80%' : 'auto'}}>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'inline-block',
                  width: '100%',
                }}
              >
                <Stack direction={'row'} spacing={1} sx={{alignItems: 'center'}}>
                  {showAudioSlider ? (
                    <Zoom in={showAudioSlider} style={{transitionDelay: showAudioSlider ? '100ms' : '50ms'}}>
                      <Slider
                        id={'volume-slider'}
                        sx={{width: '100%', marginRight: '1rem'}}
                        aria-label="Volume"
                        value={volume}
                        min={0}
                        max={100}
                        onChange={(e: Event) => {
                          setVolumeFromEvent(e);
                        }}
                      />
                    </Zoom>
                  ) : (
                    <>
                      <Typography width="30%">{formatTime(lastSecondPlayed)}</Typography>
                      <Slider
                        id={'audio-time-slider'}
                        disabled={!audioLink}
                        sx={{color: theme.palette.primary.dark, width: '100%'}}
                        aria-label="timeline"
                        value={lastSecondPlayed}
                        min={0}
                        max={audioDuration}
                        onChange={(e: Event) => {
                          const value = (e.target as HTMLInputElement).value;
                          setLastSecondPlayed(parseInt(value));
                          if (audioRef.current) {
                            audioRef.current.currentTime = parseInt(value);
                          }
                        }}
                      />
                      <Typography>{formatTime(audioDuration)}</Typography>
                    </>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <audio
            style={{display: 'none'}}
            src={audioLink}
            ref={audioRef}
            preload={'auto'}
            onPlaying={() => {
              setAudioInterval();
            }}
            onPause={() => {
              if (playInterval) {
                clearInterval(playInterval);
                setPlayInterval(null);
              }
            }}
            onLoadedData={() => {
              if (audioRef?.current?.duration) {
                setAudioDuration(Math.round(audioRef?.current?.duration));
                setCallDuration(formatTime(Math.round(audioRef?.current?.duration)));
              }
            }}
            onEnded={() => {
              setIsPlaying(false);
            }}
          />
        </Card>
      </Grid>
      {!audioLink && (
        <Grid item xs={6} sx={{paddingLeft: theme.spacing(1)}} display={'flex'}>
          <Typography>{t('audio-player.loading-audio')}</Typography>
          <CircularProgress color="inherit" size={20} sx={{ml: 2}} />
        </Grid>
      )}
    </>
  );
});

export default AudioPlayer;
