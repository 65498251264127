import {FormHelperText} from '@mui/material';

export interface ErrorMessageProps {
  hasError?: boolean;
  error?: string;
  formHelperTextProps?: any;
}

export function ErrorMessage({hasError, error, formHelperTextProps}: ErrorMessageProps) {
  if (hasError) {
    return (
      <FormHelperText error={hasError} {...formHelperTextProps}>
        {error}
      </FormHelperText>
    );
  } else {
    return <></>;
  }
}
