// @ts-ignore
import googleAnalytics from '@analytics/google-analytics';
import Analytics from 'analytics';

export function analyticsInstanceBuilder(enabled: boolean) {
  return Analytics({
    app: 'CRM',
    debug: true,
    plugins: [
      googleAnalytics({
        trackingId: 'UA-206499304-1',
        enabled: enabled,
        setCustomDimensionsToPage: true,
        customDimensions: {
          org: 'dimension1',
        },
      }),
    ],
  });
}
