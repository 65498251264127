import React from 'react';
import PercentageBarIndicator from '../../../components/shared/PercentageBarIndicator';
import {useTranslation} from 'react-i18next';

interface ConfidenceBarProps {
  confidenceValue: number;
  detectedLanguage: string;
}

function TranscriptionConfidenceBar({confidenceValue, detectedLanguage}: ConfidenceBarProps) {
  const {t} = useTranslation();

  const label_translation_key = 'recording-analytics.confidence-score.confidence-bar-label';
  const tooltip_translation_key =
    detectedLanguage === 'en'
      ? confidenceValue < 0.3333
        ? 'recording-analytics.confidence-score.low-clip-confidence'
        : confidenceValue < 0.6666
        ? 'recording-analytics.confidence-score.mid-clip-confidence'
        : 'recording-analytics.confidence-score.high-clip-confidence'
      : 'recording-analytics.confidence-score.foreign-language-confidence';

  return (
    <PercentageBarIndicator
      label={t(label_translation_key)}
      tooltip={t(tooltip_translation_key)}
      linearProgressProps={{
        value: confidenceValue * 100,
        sx: {width: '120px', borderRadius: '20px', height: '5px'},
        color: 'primary',
      }}
    />
  );
}

export default TranscriptionConfidenceBar;
