import React, {PropsWithChildren} from 'react';
import {Auth0Provider} from '@auth0/auth0-react';
import {useConfig} from './ConfigContext';

export const Auth0OrganizationProvider = ({children}: PropsWithChildren) => {
  const config = useConfig();
  return (
    <Auth0Provider
      organization={config.AUTH0_ORG_ID}
      domain={config.AUTH0_DOMAIN}
      clientId={config.AUTH0_CLIENT_ID}
      audience={config.AUTH0_API_AUDIENCE}
      redirectUri={window.location.origin}
    >
      {children}
    </Auth0Provider>
  );
};
