import {Box, Grid} from '@mui/material';
import Container from '@mui/material/Container';
import PageHeadline from '../../components/PageHeadline';
import React from 'react';
import {useTranslation} from 'react-i18next';
import SmsPortal from './components/SmsPortal';
import {PageStickyHeader} from '../PageStickyHeader';
import {FeatureName} from '../../../paths';
import {usePermissions} from '../UsePermissions';

function SmsPortalPage() {
  const {t} = useTranslation();
  usePermissions(FeatureName.SMS_PORTAL);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        height: '100%',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <PageStickyHeader>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('sms-portal.headline')}</PageHeadline>
              </Grid>
            </Grid>
          </PageStickyHeader>
          <Grid container item xs={12}>
            <SmsPortal></SmsPortal>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default SmsPortalPage;
