import React, {useState} from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PublishIcon from '@mui/icons-material/Publish';
import Backdrop from '@mui/material/Backdrop';
import UploadTSVDialog from '../../../components/dialogs/UploadTSVDialog';
import {useTranslation} from 'react-i18next';
import {HttpMethods} from '../../../model/HttpMethods';
import {ResourceTypes} from '../../../model/ResourceTypes';
import {TestAttributes} from '../../../TestAttributes';
import {Path} from '../../../../paths';

interface LocalPresenceAttachmentSpeedDialProps {
  resourceCallback?: Function;
}

function LocalPresenceAttachmentSpeedDial({resourceCallback}: LocalPresenceAttachmentSpeedDialProps) {
  const [isSpeedDialOpen, setIsSpeedDialOpen] = useState<boolean>(false);
  const [isUploaderDialogOpen, setIsUploaderDialogOpen] = useState<boolean>(false);
  const [speedDialAction, setSpeedDialAction] = useState<HttpMethods>();
  const {t} = useTranslation();
  const handleOpen = () => setIsSpeedDialOpen(true);
  const handleClose = () => setIsSpeedDialOpen(false);

  const handleUploadReplace = () => {
    setIsUploaderDialogOpen(true);
    setSpeedDialAction(HttpMethods.POST);
  };

  return (
    <>
      <Backdrop open={isSpeedDialOpen} />
      <SpeedDial
        {...{[TestAttributes.BUTTON_NAME]: 'speed-dial-btn'}}
        ariaLabel="LocalPresence Attachment SpeedDial"
        direction={'down'}
        sx={{position: 'absolute', top: {xs: '70px', md: '16px'}, right: (theme) => theme.spacing(1)}}
        icon={<PublishIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={isSpeedDialOpen}
      >
        <SpeedDialAction
          id={'local-presence-dids-speed-dial-action-upload-replace'}
          icon={<PublishIcon />}
          tooltipTitle={t('uploader.speed-dial.replace')}
          tooltipOpen
          onClick={() => handleUploadReplace()}
        />
      </SpeedDial>
      <UploadTSVDialog
        isOpen={isUploaderDialogOpen}
        handleClose={() => setIsUploaderDialogOpen(false)}
        method={speedDialAction}
        resourceType={ResourceTypes.LOCAL_PRESENCE_DIDS}
        resourceURL={`/api/organization/v2/${Path.REPLACE_LOCAL_PRESENCE_DIDS}`}
        resourceCallback={resourceCallback}
      />
    </>
  );
}

export default LocalPresenceAttachmentSpeedDial;
