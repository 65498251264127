export enum ChatViewMessageStatus {
  SENT = 'sent',
  RECEIVED = 'received',
}

export interface ChatViewMessage {
  message: string;
  status: ChatViewMessageStatus;
  owner: InteractionRole;
}

export enum InteractionRole {
  ASSISTANT = 'assistant',
  USER = 'user',
}

export interface InteractionContext {
  role: InteractionRole;
  content: string;
}

export interface Interaction {
  interaction_context: Array<InteractionContext>;
  prompt: string;
  assistant_chat_id?: string;
}

export interface AssistantResponseWrapper {
  generated_text: string;
  assistant_chat_id?: string;
}

export interface ChatInformation {
  created_at: string;
  assistant_chat_id: string;
  first_message: string;
  chat_name?: string;
}
