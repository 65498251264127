import {useTranslation} from 'react-i18next';
import {Grid, Typography} from '@mui/material';

function FeatureNotFound() {
  const {t} = useTranslation();
  return (
    <Grid
      container
      spacing={3}
      xs={12}
      display={'flex'}
      justifyContent={'center'}
      justifyItems={'center'}
      marginTop={'5%'}
    >
      <Grid item xs={12} justifySelf={'center'}>
        <Typography variant={'h2'} align="center">
          {t('shared.id-not-found-p1')}{' '}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'h2'} align="center">
          {t('shared.id-not-found-p2')}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default FeatureNotFound;
