import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {WebSocketMessageData, WebSocketService} from './WebSocketService';
import {WebSocketCampaignBase, WebSocketCampaigns} from '../model/WebSocketCampaign';
import {CampaignDialerType} from '../model/Campaign';

function extractCampaignIdOrNull(key: string): string | null {
  const matches = key.match(/([a-fA-F\d]{8}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{12})/);
  if (matches && matches.length > 0) {
    return key;
  }
  return null;
}

function parseRefill(value: any): boolean {
  return (value.refill ?? value.Refill ?? 'false').toLocaleLowerCase() === 'true';
}

function buildWebSocketCampaign(key: string, value: any): WebSocketCampaignBase {
  const cleanedCampaign = {...value};
  delete cleanedCampaign['Refill'];
  delete cleanedCampaign['Messages'];
  delete cleanedCampaign['Name'];

  return {
    ...cleanedCampaign,
    _key: key,
    campaign_id: extractCampaignIdOrNull(key),
    name: value.name ?? value.Name,
    dialer_type: value.dialer_type ?? 'nd',
    messages: value.messages ?? value.Messages ?? 0,
    refill: parseRefill(value),
  };
}

export class WebSocketCampaignService {
  private static INSTANCE: WebSocketCampaignService;
  private campaigns = new BehaviorSubject<WebSocketCampaigns>({
    predictive_campaigns: [],
    power_campaigns: [],
    after_hours: [],
    call_now: [],
  });
  private subscription: Subscription | undefined;

  // IMPORTANT: this MUST remain a singleton! (or we need to unsubscribe from WebSocketService)
  static getInstance(): WebSocketCampaignService {
    if (!WebSocketCampaignService.INSTANCE) {
      WebSocketCampaignService.INSTANCE = new WebSocketCampaignService();
    }
    return WebSocketCampaignService.INSTANCE;
  }

  constructor() {
    this.subscription = WebSocketService.getInstance()
      .listen()
      .subscribe((message: WebSocketMessageData) => this.handleWebSocketMessage(message));
  }

  private handleWebSocketMessage(message: WebSocketMessageData) {
    // discard empty messages
    if (!message || Object.keys(message).length === 0 || message?.notification) return;
    const formattedCampaigns: WebSocketCampaigns = {
      predictive_campaigns: [],
      power_campaigns: [],
      after_hours: [],
      call_now: [],
    };

    for (const [campaignType, campaigns] of Object.entries<any>(message)) {
      const filteredCampaigns = campaigns
        .filter((campaign: WebSocketCampaignBase) => {
          return Object.values(campaign)[0].dialer_type !== CampaignDialerType.ND;
        })
        .map((campaign: WebSocketCampaignBase) => {
          const campaignName = Object.keys(campaign)[0];
          return buildWebSocketCampaign(campaignName, campaign[campaignName]);
        });
      if (filteredCampaigns) {
        formattedCampaigns[campaignType] = filteredCampaigns;
      }
    }
    this.campaigns.next(formattedCampaigns);
  }

  listen(): Observable<WebSocketCampaigns> {
    return this.campaigns.asObservable();
  }
}
