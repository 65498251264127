import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Autocomplete, Grid, TextField} from '@mui/material';
import {SpartanFieldOption} from '../../../form/fields/model';
import {ResponseListWrapper} from '../../../services/model/ResponseListWrapper';
import {Path} from '../../../../paths';
import {HttpMethods} from '../../../model/HttpMethods';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import {useAxiosContext} from '../../../context/AxiosContext';
import {useLoading} from '../../../context/LoadingContext';
import {PermissionFeatureProps, Resource} from './permissions/models/models';
import PermissionField from './permissions/PermissionField';
import {getPermissions} from './permissions/utils/Utils';

const FEATURE_NAME = 'permissions';
const RESOURCE_TYPE = 'field';

export default function FeaturePermissionPageComponent() {
  const {t} = useTranslation();
  const {useAxiosPermissionAdminAPI} = useAxiosContext();
  const {setLoading} = useLoading();
  const [features, setFeatures] = useState<Array<SpartanFieldOption>>([]);
  const [sections, setSections] = useState<Array<SpartanFieldOption>>([]);
  const [selectedSection, setSelectedSection] = useState<SpartanFieldOption | null>(null);
  const [selectedFeature, setSelectedFeature] = useState<SpartanFieldOption | null>(null);
  const [config, setConfig] = useState<any>();
  const [fields, setFields] = useState<Array<Resource>>();
  const [permissions, setPermissions] = useState<Array<PermissionFeatureProps>>();
  const [actions, setActions] = useState<Array<string>>([]);

  const [{loading: getFeaturesLoading, error: getFeaturesError}, getFeatures] = useAxiosPermissionAdminAPI<
    ResponseListWrapper<SpartanFieldOption>
  >(
    {
      method: HttpMethods.GET,
    },
    {manual: true}
  );

  const [{loading: getSectionsLoading, error: getSectionsError}, getSections] = useAxiosPermissionAdminAPI<
    ResponseListWrapper<SpartanFieldOption>
  >(
    {
      method: HttpMethods.GET,
    },
    {manual: true}
  );

  const [{loading: getFieldsLoading, error: getFieldsError}, getFields] = useAxiosPermissionAdminAPI<
    ResponseListWrapper<SpartanFieldOption>
  >(
    {
      method: HttpMethods.GET,
    },
    {manual: true}
  );

  const [{loading: getConfigLoading, error: getConfigError}, getConfig] = useAxiosPermissionAdminAPI<
    ResponseListWrapper<any>
  >(
    {
      method: HttpMethods.GET,
    },
    {manual: true}
  );

  useEffect(() => {
    const error = getConfigError || getFieldsError || getSectionsError || getFeaturesError;
    if (error) {
      NotificationService.getInstance().sendNotification(error?.response?.data?.message, NotificationType.ERROR);
    }
  }, [getConfigError, getFieldsError, getSectionsError, getFeaturesError]);

  useEffect(() => {
    setLoading(
      getSectionsLoading || getFieldsLoading || getConfigLoading || getFeaturesLoading,
      'PermissionsEditorPage'
    );
  }, [getSectionsLoading, getFieldsLoading, getConfigLoading, getFeaturesLoading]);

  useEffect(() => {
    getFeatures({url: `/${FEATURE_NAME}/${Path.FEATURES}`}).then((response: any) =>
      setFeatures(response.data.map((f: any) => ({value: f.resource_id, label: f.resource_metadata_name})))
    );
  }, []);

  useEffect(() => {
    getConfig({url: `/${FEATURE_NAME}/resource-type-configs`}).then((configResponse) => {
      setConfig(configResponse.data);
    });
  }, []);

  useEffect(() => {
    setSelectedSection(null);
    setFields([]);
    if (selectedFeature) {
      getSections({url: `/${FEATURE_NAME}/${Path.FEATURES}/${selectedFeature.value}/sections`}).then(
        (response: any) => {
          const _sections = response.data.map((s: any) => {
            return {value: s.resource_id, label: s?.resource_metadata_name ?? 'NA'};
          });
          setSections(_sections);
        }
      );
    }
  }, [selectedFeature]);

  useEffect(() => {
    if (selectedSection) {
      getFields({url: `/${FEATURE_NAME}/sections/${selectedSection.value}/fields`}).then((response: any) => {
        setFields(response.data);
      });
    } else {
      setFields([]);
    }
  }, [selectedSection]);

  useEffect(() => {
    if (config) {
      const _actions = config
        .find((conf: any) => conf.resource_type === RESOURCE_TYPE)
        .roles[0].actions.map((action: any) => action.action_name);
      _actions?.unshift('Role');
      setActions(_actions);
    }
  }, [config]);

  useEffect(() => {
    if (fields && config) {
      const permissions = getPermissions(fields, RESOURCE_TYPE, config);
      setPermissions(permissions);
    }
  }, [fields, config]);

  const renderOptions = (props: React.HTMLAttributes<HTMLElement>, option: Partial<any>) => {
    return (
      <li {...props} key={option.value}>
        {option.label}
      </li>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <Autocomplete
            id="features-autocomplete"
            options={features}
            sx={{width: 300}}
            value={selectedFeature}
            onChange={(event, option) => setSelectedFeature(option)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t(`system-settings.${Path.SYS_SETTINGS_ROLE_PERMISSIONS}.features-dropdown`)}
              />
            )}
            renderOption={renderOptions}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            id="sections-autocomplete"
            options={sections}
            sx={{width: 500}}
            value={selectedSection}
            onChange={(event, option) => setSelectedSection(option)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t(`system-settings.${Path.SYS_SETTINGS_ROLE_PERMISSIONS}.sections-dropdown`)}
              />
            )}
            renderOption={renderOptions}
          />
        </Grid>
        <Grid item xs={12}>
          {permissions?.map((permission) => (
            <PermissionField
              key={`permission-field-${permission.resourceId}`}
              label={permission.label}
              resourceId={permission.resourceId}
              roles={permission.roles}
              configActions={actions}
              config={config}
              type={permission.type}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
}
