import {CampaignDialerType} from './Campaign';

export interface Organization {
  call_now_key: string;
  call_now_script: string;
  campaign_type: CampaignDialerType;
  cell_phone_key: string;
  email_source: string;
  first_name_key: string;
  has_call_now: boolean;
  has_pdf: boolean;
  has_support: boolean;
  has_tiny_url: boolean;
  has_upload_center: boolean;
  last_name_key: string;
  lead_name_key: string;
  local_presence: boolean;
  name: string;
  org_id: string;
  press_one: boolean;
  publisher: string;
  ws: string;
  roles: Array<OrganizationRole>;
}

export interface OrganizationRole {
  role_id: string;
  role_name: string;
}

export enum OrganizationRoleName {
  POWER = 'power',
  ADMIN = 'admin',
  OPS_ADMIN = 'ops-admin',
  TELEMARKETER = 'telemarketer',
  FORM_EDITOR = 'form-editor',
  SALES = 'sales',
}

export enum OrganizationSubdomain {
  AGENTLINK = 'agentlink',
  DEMO = 'demo',
  FIORELLA = 'fiorella',
  FIORELLA_OOS = 'fiorella-oos',
  PBC = 'pbc',
  LOOKOUT = 'lookout',
  FARIS = 'faris',
  UNIVISTA = 'univista',
  // TODO add other organizations ...
}

export enum OrganizationName {
  DEMO = 'demo',
  FIORELLA = 'fiorella',
  FIORELLA_POWER = 'fiorella-power',
  FIORELLA_OOS = 'fiorella-oos',
  FIORELLA_OOS_POWER = 'fiorella-oos-power',
  PBC = 'progressive-business-capital',
  PBC_POWER = 'progressive-business-capital-power',
  LOOKOUT = 'lookout',
  LOOKOUT_POWER = 'lookout-power',
  FAVTEL = 'favtel',
  FAVTEL_POWER = 'favtel-power',
  KASPER = 'kasper',
  KASPER_POWER = 'kasper-power',
  BRINKS = 'brinks',
  BRINKS_POWER = 'brinks-power',
}
