import {BehaviorSubject, Observable} from 'rxjs';
import {Organization} from '../model/Organization';

export default class OrganizationService {
  private static INSTANCE = new OrganizationService();
  private organization = new BehaviorSubject<Organization | null>(null);

  static getInstance(): OrganizationService {
    return OrganizationService.INSTANCE;
  }

  setOrganization(organization: Organization): void {
    this.organization.next(organization);
  }
  getOrganization(): Observable<Organization | null> {
    return this.organization.asObservable();
  }

  getCurrentValue(): Organization | null {
    return this.organization.value;
  }
}
