import {useState} from 'react';
import {Alert, Snackbar} from '@mui/material';
import {SnackbarCloseReason} from '@mui/material/Snackbar/Snackbar';
import {TestAttributes} from '../TestAttributes';
import {NotificationType} from '../services/NotificationService';

interface SpartanSnackbarProps {
  type?: NotificationType;
  message?: string;
}

function SpartanSnackbar({type, message}: SpartanSnackbarProps) {
  const [open, setOpen] = useState(true);
  return (
    <Snackbar
      open={open}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      autoHideDuration={5000}
      onClose={(event, reason: SnackbarCloseReason) => {
        if (reason !== 'clickaway') {
          setOpen(false);
        }
      }}
      sx={{marginTop: (theme) => theme.spacing(18)}}
    >
      <Alert
        onClose={() => setOpen(false)}
        severity={type}
        sx={{width: '100%'}}
        {...{[TestAttributes.ALERT_NAME]: 'alert-component'}}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SpartanSnackbar;
