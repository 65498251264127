import {useEffect, useState} from 'react';
import {
  GridCellParams,
  GridColDef,
  GridRowModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import {Button, Grid, IconButton, Menu, MenuItem} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {useTranslation} from 'react-i18next';
import {MetaFile} from '../../../model/MetaFiles';
import DropzoneDialog from '../dialogs/DropzoneDialog';
import {FileExtensions} from '../../../model/FileExtensions';
import {formatDateTime} from '../../../utils/DateUtils';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import DataGridCustom from '../../../components/DataGridCustom';
import {TestAttributes} from '../../../TestAttributes';
import {ColumnSizes} from '../../../components/shared/ColumnSize';
import AudioFileDialog from '../dialogs/AudioFileDialog';
import ImageFileDialog from '../dialogs/ImageFileDialog';
import {useFiles} from '../../../UseFiles';

interface FileListProps {
  filePath: string;
  extensions: FileExtensions[];
}

function GridToolbar(props: any) {
  const {t} = useTranslation();
  const {setFileInfo, filePath, extensions, csvOptions, ...others} = props;
  const [isDropzoneDialogOpen, setIsDropzoneDialogOpen] = useState<boolean>(false);

  return (
    <>
      <GridToolbarContainer>
        <Button onClick={() => setIsDropzoneDialogOpen(true)} size="small" color="primary" id={'upload-btn'}>
          <FileUploadIcon fontSize="small" />
          {t('uploader.upload')}
        </Button>
        <GridToolbarColumnsButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
        <GridToolbarFilterButton {...others} />
        <GridToolbarDensitySelector nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
      </GridToolbarContainer>
      <DropzoneDialog
        isOpen={isDropzoneDialogOpen}
        handleClose={() => setIsDropzoneDialogOpen(false)}
        extensions={extensions}
        filePath={filePath}
        setFileInformation={setFileInfo}
      />
    </>
  );
}

function FileList({filePath, extensions}: FileListProps) {
  const {t} = useTranslation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [selectedFileInfo, setSelectedFileInfo] = useState<MetaFile>();
  const [files, setFiles] = useState<MetaFile[] | null>(null);
  const [fileInfo, setFileInfo] = useState<File | null>();

  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isAudioDialogOpen, setIsAudioDialogOpen] = useState<boolean>(false);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState<boolean>(false);

  const {getMetaFiles, deleteFile} = useFiles();

  const columns: GridColDef[] = [
    {field: 'name', headerName: t('file.name'), width: 500},
    {field: 'extension', headerName: t('file.type'), width: ColumnSizes.XS},
    {
      field: 'created_at',
      headerName: t('file.modifiedAt'),
      width: ColumnSizes.LG,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateTime(params.value).toLocaleString();
      },
    },
    {field: 'size', headerName: t('file.size'), width: ColumnSizes.XS},
    {
      field: 'actions',
      filterable: false,
      disableExport: true,
      headerName: t('table-columns.actions'),
      width: ColumnSizes.MD,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <IconButton
              {...{[TestAttributes.BUTTON_NAME]: 'guide-list-more-actions-btn'}}
              id="guide-list-more-actions-btn"
              aria-haspopup="true"
              onClick={(event: React.MouseEvent<HTMLElement>) => handleActionsMenuIconClick(event, params)}
              size="large"
            >
              <MoreHorizIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  function handleMoreActionsMenuClose(): void {
    setActionsMenuAnchorEl(null);
  }

  function handleActionsMenuIconClick(event: React.MouseEvent<HTMLElement>, cell: GridCellParams): void {
    const {row}: GridRowModel = cell;
    setActionsMenuAnchorEl(event.currentTarget);
    setSelectedFileInfo(row);
  }
  function loadMetaFiles() {
    getMetaFiles(`${filePath}/`).then((_files: MetaFile[]) => {
      setFiles(_files);
    });
  }

  function handleDeleteClick(): void {
    handleMoreActionsMenuClose();
    setDeleteDialogOpen(true);
  }

  function handleOpenFileClick(): void {
    if (extensions.includes(FileExtensions.WAV)) {
      setIsAudioDialogOpen(true);
    }
    if (extensions.includes(FileExtensions.IMAGE)) {
      setIsImageDialogOpen(true);
    }
    handleMoreActionsMenuClose();
  }

  function handleDeleteFile() {
    if (selectedFileInfo) {
      deleteFile(selectedFileInfo.name, selectedFileInfo.folder_name).then(() => {
        loadMetaFiles();
      });
    }
  }

  useEffect(() => {
    if (fileInfo && !files?.some((file) => file?.name === fileInfo?.name)) {
      files?.push({
        name: fileInfo.name,
        key: fileInfo.name,
        folder_name: filePath,
        extension: fileInfo.name.split('.')[1],
        size: `${(fileInfo.size / 1024).toFixed(1)} KB`,
        created_at: formatDateTime(fileInfo.lastModified),
      });
      setFileInfo(null);
    }
  }, [fileInfo, files]);

  useEffect(() => {
    loadMetaFiles();
  }, []);

  return (
    <Grid sx={{height: '700px'}} justifyContent={'center'} {...{[TestAttributes.TABLE_NAME]: 'table-results'}}>
      <DataGridCustom
        customToolbar={GridToolbar}
        tableColumns={columns}
        componentsProps={{toolbar: {extensions, filePath, setFileInfo}}}
        rows={
          files?.map((item: MetaFile, index: number) => {
            return {
              actions: item?.name,
              ...item,
              id: index,
            };
          }) || []
        }
      />
      <Menu
        id="guide-list-more-actions-menu"
        keepMounted
        anchorEl={actionsMenuAnchorEl}
        open={Boolean(actionsMenuAnchorEl)}
        onClose={handleMoreActionsMenuClose}
      >
        {selectedFileInfo && (
          <MenuItem
            id={'guide-list-more-actions-menu-item-view'}
            {...{[TestAttributes.FIELD_OPTION_NAME]: 'guide-list-more-actions-menu-item-view'}}
            onClick={() => handleOpenFileClick()}
          >
            {t('shared.view')}
          </MenuItem>
        )}
        <MenuItem
          id={'guide-list-more-actions-menu-item-delete'}
          {...{[TestAttributes.FIELD_OPTION_NAME]: 'guide-list-more-actions-menu-item-delete'}}
          onClick={() => handleDeleteClick()}
        >
          {t('shared.delete')}
        </MenuItem>
      </Menu>
      {isAudioDialogOpen && (
        <AudioFileDialog
          audioMetaFile={selectedFileInfo}
          isOpen={isAudioDialogOpen}
          handleClose={() => setIsAudioDialogOpen(false)}
        />
      )}
      {isImageDialogOpen && (
        <ImageFileDialog
          imageMetaFile={selectedFileInfo}
          isOpen={isImageDialogOpen}
          handleClose={() => setIsImageDialogOpen(false)}
        />
      )}

      <ConfirmationDialog
        message={t('file.deactivate-message')}
        headline={t('file.headline')}
        isDialogOpen={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
      >
        <Button
          onClick={() => {
            handleDeleteFile();
            setDeleteDialogOpen(false);
          }}
          id="confirmation-dialog-action-btn"
        >
          {t('shared.deactivate')}
        </Button>
      </ConfirmationDialog>
    </Grid>
  );
}

export default FileList;
