import {Box, Grid} from '@mui/material';
import Container from '@mui/material/Container';
import {FormBuilder} from '../../form/FormBuilder';
import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import PageHeadline from '../../components/PageHeadline';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useState} from 'react';
import {Path} from '../../../paths';
import {hasChangedFields, useStateSubject} from '../../form/state/FormState';

function DemoPage() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [forms, setForms] = useState({});
  const $state = useStateSubject();

  useEffect(() => {
    const sub = $state.subscribe((next) => console.debug('state', next));
    return () => sub.unsubscribe();
  }, [$state]);

  function onCancel() {
    const state = $state.getValue();
    console.debug('onCancel state', state);
    const hasChanges = hasChangedFields(state);
    let cancel = true;
    if (hasChanges) {
      cancel = window.confirm(`Unsaved changes detected. Leave anyway?`);
    }
    if (cancel) {
      navigate('/' + Path.DASHBOARD);
    }
  }

  function persistOnLocalStorage(formId: string) {
    return (values: any) => {
      const old_values_raw = localStorage.getItem('values:' + formId);
      const old_values = old_values_raw ? JSON.parse(old_values_raw) : {};
      const new_values = {...old_values, ...values};
      localStorage.setItem('values:' + formId, JSON.stringify(new_values));
      alert(`Saved ${formId}:\n${JSON.stringify(new_values, null, 2)}`);
    };
  }

  function loadFromLocalStorage(formId: string, index: number) {
    const raw = localStorage.getItem('values:' + formId);
    const values = raw ? JSON.parse(raw) : undefined;
    setForms((prev) => {
      return {
        ...prev,
        [formId]: {id: formId, values, index},
      };
    });
  }

  function removeFromLocalStorage(formId: string) {
    return () => {
      localStorage.removeItem('values:' + formId);
      setForms((prev) => {
        return {
          ...prev,
          [formId]: {...prev[formId], values: undefined},
        };
      });
    };
  }

  const toChildren = (formId: string) => (
    <>
      <Button color="primary" type="submit">
        {t('shared.save')}
      </Button>
      <Button color="secondary" onClick={removeFromLocalStorage(formId)}>
        Clear
      </Button>
      <Button color="secondary" onClick={onCancel}>
        {t('shared.cancel')}
      </Button>
    </>
  );

  const formIds = [
    'demo-basic-ui',
    'demo-masked-fields',
    'demo-picker-fields',
    'demo-other-fields',
    'demo-validation',
    'demo-advanced-validation',
    'demo-triggers',
    'demo-auto-address',
    'demo-fe-314',
    'demo-fe-392',
    'demo-fe-435',
    'demo-fe-436',
  ];

  useEffect(() => {
    formIds.forEach(loadFromLocalStorage);
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={12} key={`grid-item-header`}>
            <PageHeadline>{t('demo.headline')}</PageHeadline>
          </Grid>
          {Object.keys(forms)
            .sort((formId: any) => forms[formId].index)
            .map((formId: any) => (
              <Grid item lg={12} key={`grid-item-${formId}`}>
                <FormBuilder
                  formId={formId}
                  onSubmit={persistOnLocalStorage(formId)}
                  initialValues={forms[formId].values}
                  config={{debug: true, $state}}
                >
                  {toChildren(formId)}
                </FormBuilder>
              </Grid>
            ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default DemoPage;
