import BehaviorSubjectService from './common/BehaviorSubjectService';
import {Team} from '../model/Team';
import {TeamResponse} from './model/TeamResponse';

export default class TeamService extends BehaviorSubjectService<Team> {
  private static INSTANCE = new TeamService();

  static getInstance(): TeamService {
    return TeamService.INSTANCE;
  }

  toTeam(resp: TeamResponse): Team {
    return {
      ...resp,
      campaigns: resp.campaign,
      members: resp.members?.map((member) => member.user_id),
    };
  }
}
