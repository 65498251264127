import React from 'react';
import SingleLayoutComponent from './shared/SingleLayoutComponent';
import {FormFieldValue} from '../model/FormFieldValue';
import FieldValueEditorUpdate from './editors/FieldValueEditorUpdate';

interface Props {
  fieldValue: FormFieldValue;
}

export default function SingleFieldValueComponent({fieldValue}: Props) {
  const editor = <FieldValueEditorUpdate fieldValue={fieldValue} />;
  return (
    <SingleLayoutComponent
      label={fieldValue.label}
      editor={editor}
      disabled={fieldValue.disabled}
    ></SingleLayoutComponent>
  );
}
