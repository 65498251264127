import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {useState} from 'react';
import {SpartanTextFieldProps} from './model';
import {SpartanTextField} from './SpartanTextField';

export function SpartanPasswordField({InputProps, ...props}: SpartanTextFieldProps) {
  const [visible, setVisibility] = useState(false);

  function handleVisibilityChange() {
    setVisibility((prev) => !prev);
  }

  return (
    <SpartanTextField
      {...props}
      InputProps={{
        ...InputProps,
        type: visible ? 'text' : 'password',
        endAdornment: (
          <InputAdornment sx={{cursor: 'pointer'}} onClick={handleVisibilityChange} position="end">
            {visible ? <Visibility /> : <VisibilityOff />}
          </InputAdornment>
        ),
      }}
    />
  );
}
