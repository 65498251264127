import {Chip, useTheme} from '@mui/material';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {arrayMove, sortableKeyboardCoordinates} from '@dnd-kit/sortable';
import {memo, useState} from 'react';
import Select, {GroupBase, OnChangeValue} from 'react-select';
import {OnEventHandler} from '../../model';
import {SpartanFieldOption, SpartanFieldWithOptionProps} from '../model';
import {MultiValue, ValueContainer} from './components/components';
import {GetStyleForSortableMultiSelect} from './styles/styles';
import {paletteModeDark, useColors} from '../../../context/LightDarkThemeProvider';

declare module 'react-select/dist/declarations/src/Select' {
  // eslint-disable-next-line
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    onDragEnd: Function;
  }
}

const components = {
  MultiValue,
  ValueContainer,
};

function SortableMultiSelectInput(props: SpartanFieldWithOptionProps) {
  const theme = useTheme();
  const colors = useColors();
  const {onChange, ...propsWithoutOnChange} = props;
  const {value} = propsWithoutOnChange;
  const [activeId, setActiveId] = useState<string | null>(null);
  function handleOnChange(valueReceived: OnChangeValue<SpartanFieldOption, true>, onChange: OnEventHandler): void {
    const reOrder = valueReceived.map((val: SpartanFieldOption, index: any) => ({...val, order: index + 1}));
    onChange(reOrder.map((opt: any) => opt.field_name));
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 10,
        distance: 100,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onDragEnd = (event: DragEndEvent, values: any) => {
    const {active, over} = event;
    if (active.id !== over?.id) {
      const oldIndex = values.findIndex((itemAdded: any) => itemAdded.value === active.id);
      const newIndex = values.findIndex((itemAdded: any) => itemAdded.value === over?.id);

      const getNewArray: any = arrayMove(values, oldIndex, newIndex);
      onChange(getNewArray.map((itemAdded: any) => itemAdded.field_name));
    }
    setActiveId(null);
  };
  function handleDragStart(event: DragStartEvent, values: any) {
    const value = values.find((value: any) => value.value === event.active.id);
    setActiveId(value.label);
  }
  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(event: DragEndEvent) => onDragEnd(event, value)}
        onDragStart={(event: DragEndEvent) => handleDragStart(event, value)}
      >
        <Select
          {...propsWithoutOnChange}
          styles={GetStyleForSortableMultiSelect()}
          components={components}
          onChange={(valueReceived: OnChangeValue<SpartanFieldOption, true>) => handleOnChange(valueReceived, onChange)}
          onDragEnd={(event: DragEndEvent) => onDragEnd(event, value)}
          isMulti
          closeMenuOnSelect={true}
          menuPosition={'fixed'}
        />
        <DragOverlay>
          <Chip
            label={activeId}
            style={{
              backgroundColor: theme.palette.mode === paletteModeDark ? colors.secondary.main : colors.primary.dark,
              color:
                theme.palette.mode === paletteModeDark ? colors.secondary.contrastText : colors.primary.contrastText,
            }}
          />
        </DragOverlay>
      </DndContext>
    </>
  );
}

export default memo(SortableMultiSelectInput);
