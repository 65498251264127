import * as React from 'react';
import {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import UserGuideEditorStep from './UserGuideEditorStep';
import Grid from '@mui/material/Grid';
import {CampaignScript} from '../../../model/CampaignScript';
import ControlledInput from '../../shared/ControlledInput';
import {useTranslation} from 'react-i18next';

interface ScriptManagerProps {
  script?: CampaignScript;
  onChange?: Function;
}

export default function UserGuideEditor({script, onChange}: ScriptManagerProps) {
  const {t} = useTranslation();

  const {reset, watch} = useFormContext();

  useEffect(() => {
    if (script) {
      reset(script);
    }
  }, [reset, script]);

  useEffect(() => {
    const subscription = watch((value) => {
      if (onChange) {
        onChange(value);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ControlledInput
          id={`user-guides-name`}
          name={`guide_name`}
          label={t('user-guide.editor.name')}
          isRequired={true}
        ></ControlledInput>
      </Grid>

      <Grid item xs={12}>
        <UserGuideEditorStep />
      </Grid>
    </Grid>
  );
}
