import React, {useEffect, useState} from 'react';
import {GridCellParams, GridColDef, GridValueFormatterParams} from '@mui/x-data-grid';
import {NextgenFormColumn, NextgenFormSchema} from '../form/api/middleware/NextgenFormSchema';
import {Link} from 'react-router-dom';
import {IconButton} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useTranslation} from 'react-i18next';
import {ColumnSizes} from '../components/shared/ColumnSize';
import {Path} from '../../paths';
import {useAxiosContext} from '../context/AxiosContext';
import {useLoading} from '../context/LoadingContext';

export interface UseTableColumnsProps {
  defaultActionColumn?: boolean;
  actionColumn?: GridColDef;
  featureName: string;
  actionLink?: string;
}

function toColumn(column: NextgenFormColumn): GridColDef {
  return {
    field: column.field_name,
    headerName: column.header,
    width: column?.width,
    hide: !column.is_default_column,
    valueFormatter: (params: GridValueFormatterParams) => {
      return params?.value?.toString()?.replace(/\n/g, ' ');
    },
  };
}

function getIndex(column: NextgenFormColumn) {
  return column.index || 0;
}

function buildColumns(columns: NextgenFormColumn[]): GridColDef[] {
  return columns.sort((a, b) => getIndex(a) - getIndex(b)).map(toColumn);
}

export function useTableColumns(props: UseTableColumnsProps): GridColDef[] {
  const {defaultActionColumn, actionColumn, featureName, actionLink} = props;
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const {setLoading} = useLoading();
  const [columns, setColumns] = useState<GridColDef[]>([]);

  const [{data: getFormSchemaData, loading: getFormSchemaLoading}] = useAxiosBFF<NextgenFormSchema>(
    `${Path.FEATURE}/${featureName}/table_columns`
  );

  useEffect(() => {
    setLoading(getFormSchemaLoading, 'useTableColumns');
  }, [getFormSchemaLoading]);

  useEffect(() => {
    let newActionColumn: GridColDef | null = null;
    if (actionColumn !== undefined) {
      newActionColumn = {...actionColumn, field: 'actions'}; // overwrite field to assure filtering always work
    } else if (defaultActionColumn) {
      newActionColumn = {
        field: 'actions',
        filterable: false,
        disableExport: true,
        headerName: t('table-columns.actions'),
        width: ColumnSizes.MD,
        renderCell: (params: GridCellParams) => {
          return (
            <Link to={actionLink ? `/${actionLink}/${params.value}` : `/${featureName}/${params.value}`}>
              <IconButton size="large">
                <VisibilityIcon />
              </IconButton>
            </Link>
          );
        },
      };
    }
    setColumns((prevColumns: GridColDef[]) => {
      const prevColumnsWithoutAction = prevColumns.filter((column) => column.field !== 'actions') || [];
      return newActionColumn ? [...prevColumnsWithoutAction, newActionColumn] : prevColumnsWithoutAction;
    });
  }, [defaultActionColumn, actionColumn]);

  useEffect(() => {
    const newColumns: GridColDef[] = buildColumns(getFormSchemaData?.feature_table_columns || []);
    newColumns.forEach(
      (c) =>
        (c.headerName = t(
          `feature.${featureName}.field.${c.field}.table_column`,
          t(`feature.${featureName}.field.${c.field}`, c.headerName)
        ))
    );
    setColumns((prevColumns: GridColDef[]) => {
      const prevActionColumn = prevColumns.find((column) => column.field === 'actions');
      return prevActionColumn ? [...newColumns, prevActionColumn] : newColumns;
    });
  }, [getFormSchemaData, t]);

  return columns;
}
