import * as React from 'react';
import {PropsWithChildren} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {SpartanFieldOption, SpartanTextFieldProps} from '../../form/fields/model';
import {toControlledComponentSimpleValue} from '../../form/logic/utils';
import {SpartanTextField} from '../../form/fields/SpartanTextField';
import {FormFieldType} from '../../form/logic/FormSchema';
import {SpartanRichTextField} from '../../form/fields/SpartanRichTextField';
import {SpartanSelectField} from '../../form/fields/SpartanSelectField';
import {SpartanCheckboxField} from '../../form/fields/SpartanCheckboxField';
import {SpartanMultiSelectField} from '../../form/fields/SpartanMultiSelectField';

interface ScriptControlledInputProps {
  id: string;
  type?: FormFieldType;
  name: string;
  disabled?: boolean;
  label: string;
  isRequired?: boolean;
  toolTipText?: string;
  selectOptions?: SpartanFieldOption[];
}

export default function ControlledInput({
  id,
  type,
  name,
  disabled,
  label,
  isRequired,
  toolTipText,
  selectOptions,
}: PropsWithChildren<ScriptControlledInputProps>) {
  const {control} = useFormContext();

  return (
    <Controller
      render={({field: controllerField, fieldState}) => {
        const error = fieldState.error ? fieldState.error.message : undefined;
        const hasError = !!error;

        function onFocus(e: any): void {}

        function onChange(e: any): void {
          controllerField.onChange(e);
        }

        const spartanFieldProps: SpartanTextFieldProps = {
          onChange: onChange,
          onFocus: onFocus,
          onBlur: controllerField.onBlur,
          id: id,
          label: label,
          disabled: disabled,
          name: id,
          value: toControlledComponentSimpleValue(controllerField.value),
          hasError: hasError,
          error: error,
          tooltip: toolTipText,
        };

        switch (type) {
          case FormFieldType.RICH_TEXT_EDITOR:
            return <SpartanRichTextField {...spartanFieldProps} />;
          case FormFieldType.CHECKBOX:
            return <SpartanCheckboxField {...spartanFieldProps} />;
          case FormFieldType.SELECT:
            return (
              <SpartanSelectField
                {...spartanFieldProps}
                options={selectOptions ? selectOptions : []}
              ></SpartanSelectField>
            );
          case FormFieldType.SELECTMULTIPLE:
            return (
              <SpartanMultiSelectField
                {...spartanFieldProps}
                options={selectOptions ? selectOptions : []}
              ></SpartanMultiSelectField>
            );
          case FormFieldType.TEXT:
          default:
            return <SpartanTextField {...spartanFieldProps} />;
        }
      }}
      name={name}
      control={control}
      rules={{required: isRequired ? isRequired : false}}
    />
  );
}
