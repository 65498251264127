import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AudioPlayer, {AudioHandle} from '../../components/AudioPlayer';
import {Transcription, dynamicDisplayFieldInfo} from '../../model/CallTranscriptions';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import ToggleSwitch from '../../components/shared/ToggleSwitch';
import ConversationHistory from './components/ConversationHistory';
import {ConversationMessage} from '../../model/ConversationHistory';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

interface CallTranscriptionPopUpViewProps {
  isOpen: boolean;
  handleClose: Function;
  transcription: Transcription;
  userKeywords: string[];
  leadKeywords: string[];
  displayConfidenceScores: boolean;
  fieldsToDisplay: dynamicDisplayFieldInfo[];
  setDisplayConfidenceScoresHandler: () => void;
}

const supportedAudioExtensions = {
  mp3: 'audio/mpeg',
  wav: 'audio/wav',
};

function hasExtension(filename: string): boolean {
  const extension = filename.split('.').pop();
  return extension && extension.length > 0 ? true : false;
}

export function getNameFromRelativePath(relativePath: string): string | null {
  let fileName = relativePath.split('/').pop();
  if (fileName) {
    return hasExtension(relativePath) ? fileName.substring(0, fileName.length - 4) : fileName;
  }
  return null;
}

function CallTranscriptionPopUpView({
  isOpen,
  handleClose,
  transcription,
  userKeywords,
  leadKeywords,
  displayConfidenceScores,
  fieldsToDisplay,
  setDisplayConfidenceScoresHandler,
}: CallTranscriptionPopUpViewProps) {
  const {useAxiosBFF} = useAxiosContext();
  const {t} = useTranslation();
  const [callDuration, setCallDuration] = useState<string>('00:00');
  const [audioUrl, setAudioUrl] = useState<string>();
  const [fileName, setFileName] = useState<string | null>();
  const [showNotFileFound, setShowNotFileFound] = useState<boolean>(false);
  const [chat, setChat] = useState<Array<ConversationMessage>>([]);
  const audioHandleRef = useRef<AudioHandle>(null);

  let formattedFieldsToDisplay = fieldsToDisplay.map((fieldToDisplayInfo: dynamicDisplayFieldInfo) => {
    return {field_label: fieldToDisplayInfo.field_label, value: transcription[fieldToDisplayInfo.field_name]};
  });
  const [
    {response: transcriptionAudioResponse, error: hasTranscriptionAudioError, loading: getTranscriptionAudioLoading},
    getTranscriptionAudio,
  ] = useAxiosBFF<ArrayBuffer>(
    {
      url: `/transcription-results/${transcription.transcription_id}`,
      params: {formatted: true},
      responseType: 'arraybuffer',
    },
    {manual: true}
  );

  useEffect(() => {
    setShowNotFileFound(false);
    setFileName(getNameFromRelativePath(transcription.relative_call_recording_file_path));
    const mimeType = resolveMimeType(transcription.relative_call_recording_file_path);

    if (transcription.transcription_id && mimeType) {
      getTranscriptionAudio();
    }
    if (transcription.transcription) {
      setChat(transcription.transcription);
    }
  }, [transcription]);

  useEffect(() => {
    if (transcriptionAudioResponse) {
      const mimeType = resolveMimeType(transcription.relative_call_recording_file_path);
      const transcriptionAudio = transcriptionAudioResponse.data;
      if (transcriptionAudio) {
        let url = '';
        const blob = new Blob([transcriptionAudio], {type: mimeType});
        url = window.URL.createObjectURL(blob);
        setAudioUrl(url);
        setShowNotFileFound(false);
      } else {
        setShowNotFileFound(true);
      }
    }
  }, [transcriptionAudioResponse]);

  useEffect(() => {
    if (hasTranscriptionAudioError && isOpen) {
      setAudioUrl('');
      setCallDuration('00:00');
      setShowNotFileFound(true);
      NotificationService.getInstance().sendNotification(
        t('recording-analytics.audio-not-found'),
        NotificationType.ERROR
      );
    }
  }, [hasTranscriptionAudioError]);

  function resolveMimeType(relativeCallRecordingPath: string): string {
    const fileExtension = relativeCallRecordingPath.split('.').pop();
    if (fileExtension && supportedAudioExtensions[fileExtension]) {
      return supportedAudioExtensions[fileExtension];
    }
    throw new Error(t('file.not-supported-file-extension'));
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={isOpen}
      onClose={() => {
        setAudioUrl('');
        setCallDuration('00:00');
        handleClose();
      }}
      aria-labelledby="transcription-dialog-title"
    >
      <DialogTitle id="transcription-dialog-title">
        <IconButton
          aria-label="close"
          onClick={() => {
            setAudioUrl('');
            setCallDuration('00:00');
            handleClose();
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{overflowY: 'auto'}}>
        <Grid item container spacing={3} xs={12}>
          <Grid item xs={12}>
            <Typography component={'div'} sx={{mb: 1, wordBreak: 'break-all'}}>
              <b>{t('file.name')}: </b>
              {fileName}
            </Typography>
            <Typography sx={{mb: 1}}>
              <b>{t('recording-analytics.call-duration')}:</b> {callDuration}
            </Typography>
            {transcription.call_start_timestamp && (
              <Typography sx={{mb: 1}}>
                <b>{t('recording-analytics.call-start-timestamp')}:</b> {transcription.call_start_timestamp}
              </Typography>
            )}
          </Grid>

          {userKeywords.length > 0 && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography sx={{mb: 1}}>
                  <b>user keywords:</b> {userKeywords.join(', ')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{mb: 1}}>
                  <b>user matched keywords:</b> {transcription.user_keywords_matched}
                </Typography>
              </Grid>
            </>
          )}

          {leadKeywords.length > 0 && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography sx={{mb: 1}}>
                  <b>lead keywords:</b> {leadKeywords.join(', ')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{mb: 1}}>
                  <b>lead matched keywords:</b> {transcription.lead_keywords_matched}
                </Typography>
              </Grid>
            </>
          )}

          {formattedFieldsToDisplay.map((field) => {
            if (field.value || field.value === 0) {
              return (
                <Grid item xs={12} sm={6}>
                  <Typography sx={{mb: 1}}>
                    <b>{field.field_label}:</b> {field.value}
                  </Typography>
                </Grid>
              );
            }
            return null;
          })}
          <Grid container item sm={12} sx={{my: 2}} spacing={1}>
            {showNotFileFound ? (
              <Typography color={'red'}>{t('file.file-not-found')}</Typography>
            ) : (
              <AudioPlayer audioLink={audioUrl} setCallDuration={setCallDuration} ref={audioHandleRef} />
            )}
            <Grid item sm={true} />
            <Grid container item sm={4} justifyContent="flex-end">
              <ToggleSwitch
                checkedStatus={displayConfidenceScores}
                onCheckedBehavior={setDisplayConfidenceScoresHandler}
                label={t('recording-analytics.toggle-display-confidence-label')}
                tooltip={t('recording-analytics.toggle-display-confidence-tooltip')}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ConversationHistory
              chat={chat}
              audioHandle={audioHandleRef.current}
              displayConfidenceScores={displayConfidenceScores}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default CallTranscriptionPopUpView;
