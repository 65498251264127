import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Controller, useFieldArray, useFormContext} from 'react-hook-form';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import {IconButton, InputLabel, MenuItem, Select, Tooltip, Grid, Typography} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import ControlledInput from '../../shared/ControlledInput';
import {SpartanFieldOption} from '../../../form/fields/model';
import {UserGuideControls} from './UserGuideConsts';
import {UserGuideStep} from '../model/UserGuideStep';
import {TestAttributes} from '../../../TestAttributes';

interface ScriptStepOptionProps {
  stepIndex: number;
}

export default function UserGuideEditorStepOption({stepIndex}: ScriptStepOptionProps) {
  const {t} = useTranslation();
  const {control, getValues} = useFormContext();
  const [allStepsAsOption, setAllStepsAsOption] = useState<Array<SpartanFieldOption>>([]);
  const [colorOptions] = useState<Array<SpartanFieldOption>>([
    {value: 'primary', label: 'dark'},
    {value: 'secondary', label: 'light'},
  ]);

  const {fields, insert, remove, swap} = useFieldArray({
    control,
    name: `${UserGuideControls.FIELD_ARRAY_STEPS}.${stepIndex}.options`,
  });

  useEffect(() => {
    updateStepNames();
  }, []);

  function updateStepNames(): void {
    const steps: Array<UserGuideStep> = getValues(UserGuideControls.FIELD_ARRAY_STEPS);
    const stepsAsOptions = steps?.map((step: UserGuideStep) => stepToOption(step));
    setAllStepsAsOption(stepsAsOptions);
  }

  function stepToOption(step: UserGuideStep): SpartanFieldOption {
    return {
      label: step?.name || '',
      value: step?.identifier || '0',
    };
  }

  return (
    <Grid container>
      <Grid container item spacing={3} xs={11}>
        {fields.length === 0 && (
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Typography variant={'body1'}> {t('user-guide.editor.step.no-options')} </Typography>
          </Grid>
        )}

        {fields.map((currentField, index) => (
          <Grid
            container
            item
            spacing={3}
            key={currentField?.id}
            className={`call-script-step-${stepIndex}-option-wrapper`}
          >
            <Grid item xs={3}>
              <ControlledInput
                id={`step-${stepIndex}-options-${index}-name`}
                name={`${UserGuideControls.FIELD_ARRAY_STEPS}.${stepIndex}.options.${index}.name`}
                label={`${t('user-guide.editor.option.name')} ${index + 1}`}
                isRequired={true}
                toolTipText={t('user-guide.editor.option.name-tooltip')}
              ></ControlledInput>
            </Grid>
            <Tooltip title={t('user-guide.editor.option.color-tooltip') || ''}>
              <Grid item xs={3}>
                <Controller
                  render={({field: controllerField, fieldState}) => {
                    return (
                      <>
                        <InputLabel htmlFor={controllerField.name + '-options'}>
                          {t('user-guide.editor.option.color')}
                        </InputLabel>
                        <Select fullWidth id={`step-${stepIndex}-options-${index}-color`} {...controllerField}>
                          {colorOptions?.map((option: SpartanFieldOption) => (
                            <MenuItem
                              id={controllerField.name + '-option-' + option.value}
                              key={controllerField.name + '-option-' + option.value}
                              value={option.value}
                            >
                              {t(`mode.${option.label}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    );
                  }}
                  name={`${UserGuideControls.FIELD_ARRAY_STEPS}.${stepIndex}.options.${index}.color`}
                  control={control}
                  rules={{required: true}}
                />
              </Grid>
            </Tooltip>
            <Tooltip title={t('user-guide.editor.option.target-tooltip') || ''}>
              <Grid item xs={3}>
                <Controller
                  render={({field: controllerField, fieldState}) => {
                    return (
                      <>
                        <InputLabel htmlFor={controllerField.name + '-options'}>
                          {t('user-guide.editor.option.target')}
                        </InputLabel>
                        <Select
                          fullWidth
                          id={`step-${stepIndex}-options-${index}-target`}
                          {...controllerField}
                          onMouseEnter={() => updateStepNames()}
                        >
                          {allStepsAsOption?.map((option: SpartanFieldOption) => (
                            <MenuItem
                              className={`step-${stepIndex}-options-${index}-target`}
                              key={controllerField.name + '-option-' + option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    );
                  }}
                  name={`${UserGuideControls.FIELD_ARRAY_STEPS}.${stepIndex}.options.${index}.target`}
                  control={control}
                  rules={{required: true}}
                />
              </Grid>
            </Tooltip>
            <Grid item xs={2} display={'flex'} alignSelf={'flex-end'}>
              <IconButton
                id={`remove-step-${stepIndex}-option-${index}-btn`}
                color="primary"
                aria-label="remove step option"
                // disabled={fields.length <= 1}
                component="button"
                onClick={() => remove(index)}
                size="large"
              >
                <IndeterminateCheckBoxIcon />
              </IconButton>
              <IconButton
                id={`add-condition-${stepIndex}-option-${index}-btn`}
                color={'primary'}
                aria-label="add condition"
                component="button"
                onClick={() => {
                  insert(index + 1, {name: "Button's text", color: 'primary', target: 1});
                }}
                size="large"
              >
                <AddBoxIcon />
              </IconButton>
              {index !== 0 && (
                <IconButton
                  {...{[TestAttributes.BUTTON_NAME]: `swap-up-choice-${index}-btn`}}
                  id={`swap-up-choice-${index}-btn`}
                  color={'secondary'}
                  aria-label="swap up step"
                  component="button"
                  onClick={() => swap(index, index - 1)}
                  size="large"
                >
                  <ArrowUpwardRoundedIcon />
                </IconButton>
              )}
              {index !== fields.length - 1 && (
                <IconButton
                  {...{[TestAttributes.BUTTON_NAME]: `swap-down-choice-${index}-btn`}}
                  id={`swap-down-choice-${index}-btn`}
                  color={'secondary'}
                  aria-label="swap down step"
                  component="button"
                  onClick={() => swap(index, index + 1)}
                  size="large"
                >
                  <ArrowDownwardRoundedIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
