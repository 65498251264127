import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {CampaignScript} from '../../../model/CampaignScript';

interface CopyUserGuideDialogProps {
  isOpen: boolean;
  handleClose: Function;
  copyUserGuide: Function;
  selectedUserGuide: CampaignScript | null;
}

function CopyUserGuideDialog({isOpen, handleClose, selectedUserGuide, copyUserGuide}: CopyUserGuideDialogProps) {
  const {t} = useTranslation();
  const [copiedUserGuide, setCopiedUserGuide] = useState<string>('');
  function handleOnClose() {
    setCopiedUserGuide('');
    handleClose();
  }
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={isOpen}
      onClose={() => handleOnClose()}
      aria-labelledby="copy-guide-dialog-title"
    >
      <DialogTitle id="task-dialog-title">
        {t('user-guide.actions.copy-guide-label')} {selectedUserGuide?.guide_name} ?
        <IconButton
          aria-label="close"
          onClick={() => handleOnClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <InputLabel>{t('user-guide.actions.new-name')}</InputLabel>
        <TextField
          fullWidth
          id={'copy-guide-field'}
          value={copiedUserGuide}
          placeholder={t('user-guide.guide-name')}
          onChange={(event) => setCopiedUserGuide(event.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button id={'copy-guide-cancel-btn'} color="secondary" onClick={() => handleOnClose()}>
          {t('shared.cancel')}
        </Button>
        <Button
          id={'copy-guide-btn'}
          disabled={!copiedUserGuide}
          onClick={() => {
            handleOnClose();
            copyUserGuide(copiedUserGuide);
          }}
        >
          {t('user-guide.actions.copy-guide')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CopyUserGuideDialog;
