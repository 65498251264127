import {Subject} from 'rxjs';
import {useRef} from 'react';

export interface FormControllerEvent {
  type: 'reset' | 'submit';
}

export class FormAPI {
  $events = new Subject<FormControllerEvent>();

  reset() {
    this.$events.next({type: 'reset'});
  }

  submit() {
    this.$events.next({type: 'submit'});
  }
}

export function useFormAPI(): FormAPI {
  return useRef(new FormAPI()).current;
}
