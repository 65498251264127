import {FormFieldSchema} from '../FormSchema';
import {evalCondition, FormFieldConditionOrBool, getConditionDependencies} from '../conditions/Condition';
import {evalExpression} from '../eval/Eval';
import {pushAllIfAbsent} from '../../utils';
import {ComputeContext} from '../ComputeContext';

export interface FormFieldTrigger {
  action: FormFieldTriggerAction;
  condition: FormFieldConditionOrBool;
}

export interface FormFieldTriggerAction {
  type: FormFieldTriggerActionType;
}

export type FormFieldTriggerActionType = 'eval' | 'set_value' | 'set_timestamp';

export interface FormFieldTriggerActionEval extends FormFieldTriggerAction {
  type: 'eval';
  expression: string;
}

export interface FormFieldTriggerActionSetValue extends FormFieldTriggerAction {
  type: 'set_value';
  value: any;
}

export interface FormFieldTriggerActionSetTimestamp extends FormFieldTriggerAction {
  type: 'set_timestamp';
}

function _evalAction(action: FormFieldTriggerAction, values: any, ctx?: ComputeContext) {
  switch (action.type) {
    case 'set_value':
      return (action as FormFieldTriggerActionSetValue).value;
    case 'set_timestamp':
      return new Date().toISOString();
    case 'eval':
      return evalExpression((action as FormFieldTriggerActionEval).expression, values, ctx);
  }
  throw new Error(`Unknown action ${action.type}`);
}

function _buildTrigger(fieldName: string, triggers: FormFieldTrigger[], defaultCtx?: ComputeContext): Trigger {
  let dependencies: string[] = [];
  let compute: TriggerFunType = DummyTrigger.compute;
  if (triggers?.length) {
    for (let trigger of triggers) {
      pushAllIfAbsent(dependencies, getConditionDependencies(trigger.condition));
    }
    compute = (allValues: any, ctx?: ComputeContext) => {
      for (const trigger of triggers) {
        const result = evalCondition(trigger.condition, allValues, {...defaultCtx, ...ctx});
        if (result) {
          const result = _evalAction(trigger.action, allValues, {...defaultCtx, ...ctx});
          return {[fieldName]: result}; // return after first trigger applied
        }
      }
      return undefined;
    };
  }
  return {dependencies, compute};
}

export function buildTrigger(field: FormFieldSchema, defaultCtx?: ComputeContext): Trigger | undefined {
  if (field?.triggers?.length) {
    return _buildTrigger(field.field_name, field.triggers, defaultCtx);
  }
}

export const DummyTriggerFun: TriggerFunType = (values: any) => undefined;
export const DummyTrigger: Trigger = {
  dependencies: [],
  compute: DummyTriggerFun,
};
export type TriggerFunType = (values: any, ctx?: ComputeContext) => TriggerResult | undefined;

export interface TriggerResult {
  [key: string]: any;
}
export interface Trigger {
  dependencies: string[]; // possible other fields mentioned in the condition
  compute: TriggerFunType;
}
