import {Link, matchPath, useLocation} from 'react-router-dom';
import {Button, ListItemButton, ListItemIcon, ListItemText, Tooltip, useTheme} from '@mui/material';
import Icon from '@mui/material/Icon';
import React, {MouseEventHandler, PropsWithChildren, useState} from 'react';
import {useTranslation} from 'react-i18next';
import iconMap from './SideNavIcons';
import ConfirmationDialog from './dialogs/ConfirmationDialog';
import MaterialUiDynamicIcon from './MaterialUiDynamicIcon';
import DynamicIcon from './DynamicIcon';
import {MaterialUiIcons} from './MaterialUiIcons';

interface NavItemProps {
  href?: string;
  icon?: string;
  title: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  isCollapse: boolean;
  actionMessage?: string;
  tooltip: string;
}

export function NavItem({
  href,
  icon,
  title,
  onClick,
  children,
  isCollapse,
  actionMessage,
  tooltip,
}: PropsWithChildren<NavItemProps>) {
  const {t} = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const titleKey = t(title) ?? '';
  const active = href
    ? !!matchPath(
        {
          path: href,
          end: false,
        },
        location.pathname
      )
    : false;

  const isUrlExternal = (href?: string) => {
    const searchProtocol = href?.startsWith('//');
    let isExternal = false;
    if (searchProtocol) {
      isExternal = true;
    }
    return isExternal;
  };

  const handleExternalUrl = (event: React.MouseEvent, href?: string) => {
    const urlExternal = isUrlExternal(href);

    if (urlExternal || titleKey.toLowerCase() === 'sparta tools') {
      event.preventDefault();
    }

    if (urlExternal) {
      if (title.includes('phone')) {
        setIsConfirmationDialogOpen(true);
      } else {
        window.open(href, '_blank');
      }
    }
  };
  const dynamicIconName = title.split('.')?.pop()?.toLowerCase() || '';
  const iconSrc =
    icon && iconMap.has(icon) ? (
      <Icon>
        <img
          src={iconMap.get(icon)}
          alt={titleKey}
          style={{height: '100%', width: '100%', objectFit: 'contain', color: theme.palette.secondary.light}}
        />
      </Icon>
    ) : icon && MaterialUiIcons[icon] ? (
      <MaterialUiDynamicIcon
        iconName={icon}
        color={active ? theme.palette.primary.main : theme.palette.secondary.light}
        fontSize="medium"
      />
    ) : (
      <DynamicIcon
        iconName={dynamicIconName}
        color={active ? theme.palette.primary.main : theme.palette.secondary.light}
        fontSize="medium"
        hidden={isCollapse}
      />
    );
  return (
    <>
      <Tooltip title={t(tooltip)} placement="right">
        <Link
          to={href && !isUrlExternal(href) ? href : ''}
          onClick={(event: React.MouseEvent) => handleExternalUrl(event, href)}
          id={'sidenav-item-' + title.split('.').pop()?.toLowerCase()}
        >
          <ListItemButton onClick={onClick} sx={{paddingY: theme.spacing(1)}}>
            <ListItemIcon>{iconSrc}</ListItemIcon>
            <ListItemText primary={titleKey} />
            {children && children}
          </ListItemButton>
        </Link>
      </Tooltip>
      {actionMessage && (
        <ConfirmationDialog
          message={t(actionMessage)}
          headline={titleKey}
          isDialogOpen={isConfirmationDialogOpen}
          handleClose={() => {
            setIsConfirmationDialogOpen(false);
          }}
        >
          <Button
            onClick={() => {
              window.open(href, '_blank');
              setIsConfirmationDialogOpen(false);
            }}
            id="confirmation-dialog-action-btn"
          >
            {t('shared.accept')}
          </Button>
        </ConfirmationDialog>
      )}
    </>
  );
}
