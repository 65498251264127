import InputAdornment from '@mui/material/InputAdornment';
import {IconButton, Tooltip, Typography} from '@mui/material';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import {SpartanTextFieldProps} from './model';
import {SpartanTextAreaField} from './SpartanTextAreaField';
import {useTranslation} from 'react-i18next';

export function SpartanSubmitTextAreaField({InputProps, ...props}: SpartanTextFieldProps) {
  const {t} = useTranslation();

  return (
    <SpartanTextAreaField
      {...props}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment sx={{cursor: 'pointer'}} position="end">
            <Tooltip
              title={
                <>
                  <Typography variant={'body1'} sx={{fontWeight: 'normal', fontSize: '15px'}}>
                    {t('shared.text-area-submit-tooltip')}
                  </Typography>
                </>
              }
              placement="top"
            >
              <IconButton type="submit">
                <SendRoundedIcon color={props.value?.trim() && !props.hasError ? 'primary' : 'disabled'} />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
}
