import {useEffect, useRef} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {Box, Button, Grid} from '@mui/material';
import Container from '@mui/material/Container';
import PageHeadline from '../../components/PageHeadline';
import {FormBuilder} from '../../form/FormBuilder';
import {useTranslation} from 'react-i18next';
import {FeatureName} from '../../../paths';
import {Auth0User, getUserEmail} from '../../model/Auth0User';
import {PageTopActions} from '../PageTopActions';
import {PageStickyHeader} from '../PageStickyHeader';
import {usePermissions} from '../UsePermissions';
import {DefaultResponse} from '../../services/model/DefaultResponse';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {TestAttributes} from '../../TestAttributes';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

export interface SmsLogParams {
  date_from: string;
  date_to?: string;
  emails: string;
  resource: string;
}

function SmsLogPage() {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const {setLoading} = useLoading();
  const auth0 = useAuth0<Auth0User>();
  const submitEl = useRef<HTMLButtonElement>(null);
  usePermissions(FeatureName.SMS_LOG);

  const resource = 'twilio';
  const [{response: sendExportResponse, error: sendExportError, loading: sendExportLoading}, sendExport] =
    useAxiosBFF<DefaultResponse>(
      {
        url: `exports`,
        method: 'GET',
      },
      {manual: true}
    );

  useEffect(() => {
    setLoading(sendExportLoading, 'SmsLogPage');
  }, [sendExportLoading]);

  useEffect(() => {
    if (sendExportResponse) {
      if (sendExportResponse?.status === 200) {
        NotificationService.getInstance().sendNotification(sendExportResponse?.data?.message, NotificationType.SUCCESS);
      }
    }
  }, [sendExportResponse]);
  useEffect(() => {
    if (sendExportError) {
      NotificationService.getInstance().sendNotification(
        sendExportError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [sendExportError]);
  function submitForm(data: any) {
    const params: SmsLogParams = {
      date_from: data?.date_from,
      date_to: data?.date_to,
      resource,
      emails: getUserEmail(auth0.user) || '',
    };

    if (!params?.date_to) {
      delete params['date_to'];
    }
    sendExport({
      params,
    });
  }
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('sms-log.headline')}</PageHeadline>
              </Grid>
              <Grid item xs={12} md={6}>
                <PageTopActions>
                  <Button
                    {...{[TestAttributes.BUTTON_NAME]: 'export-btn'}}
                    id={'export-btn'}
                    color="primary"
                    onClick={() => {
                      submitEl?.current?.click();
                    }}
                  >
                    {t('sms-log.export-btn')}
                  </Button>
                </PageTopActions>
              </Grid>
            </Grid>
          </PageStickyHeader>
          <Grid item xs={12} md={5}>
            <FormBuilder formId={FeatureName.SMS_LOG} onSubmit={submitForm}>
              <Button color="primary" type="submit" ref={submitEl} sx={{display: 'none'}} />
            </FormBuilder>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default SmsLogPage;
