import React, {PropsWithChildren, ReactNode, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AxiosResponse} from 'axios';
import {useAuth0} from '@auth0/auth0-react';
import {IFileWithMeta} from 'react-dropzone-uploader';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Transition} from 'history';
import PageHeadline from '../../components/PageHeadline';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {Lead} from '../../model/Lead';
import {Status} from '../../model/Feature';
import {FeatureName} from '../../../paths';
import {PageTopActions} from '../PageTopActions';
import {DefaultResponse} from '../../services/model/DefaultResponse';
import {Auth0User, getUserOrganizationRoles} from '../../model/Auth0User';
import {FormBuilder} from '../../form/FormBuilder';
import {PageStickyHeader} from '../PageStickyHeader';
import {difference} from '../../form/utils';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {BaseFormSectionSchemaDecorator} from '../../form/logic/FormDecorator';
import {OrganizationRoleName} from '../../model/Organization';
import LeadService from '../../services/LeadService';
import {FolderPath} from '../../model/Files';
import {ResourceTypes} from '../../model/ResourceTypes';
import {DropzoneWrapper} from '../../form/components/DropzoneWrapper';
import {FileExtensions} from '../../model/FileExtensions';
import {FormConfig} from '../../form/FormConfig';
import {FormAPI} from '../../form/FormAPI';
import FilesService from '../../services/FilesService';
import {onRejectSubmit} from '../../form/errorHandler';
import {MetaFile} from '../../model/MetaFiles';
import UserRoleService from '../../services/UserRoleService';
import {TestAttributes} from '../../TestAttributes';
import {useFiles} from '../../UseFiles';
import DeletePaymentInfoDialog from './dialogs/DeletePaymentInfoDialog';
import {HttpMethods} from '../../model/HttpMethods';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import LeadActions from './components/LeadActions';
import {useNavigationBlocker} from './../../../routes';
import {hasChangedFields, useStateSubject} from '../../form/state/FormState';

interface LeadEditorProps {
  lead: Lead | null;
  setLead: (lead: Lead) => void;
  onSubmitSucceeded: (response: AxiosResponse<DefaultResponse>, formValues: any) => void;
  formAPI: FormAPI;
  campaignScript?: ReactNode;
  userPermissions: Array<UserPermissions> | null;
  headline: string;
  subHeadline: string | null;
}

function LeadEditor({
  lead,
  setLead,
  formAPI,
  campaignScript,
  onSubmitSucceeded,
  userPermissions,
  subHeadline,
  headline,
  children,
}: PropsWithChildren<LeadEditorProps>) {
  const {useAxiosBFF} = useAxiosContext();
  const {t} = useTranslation();
  const auth0 = useAuth0<Auth0User>();
  const {user} = auth0;
  const {getMetaFiles, uploadFiles} = useFiles();
  const [leadId, setLeadId] = useState<string | undefined>();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [isDeactivatedModalOpen, setIsDeactivatedModalOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [files, setFiles] = useState<MetaFile[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<FormData[] | []>([]);
  const [config, setConfig] = useState<FormConfig>();
  const [resetDropzoneState, setResetDropzoneState] = useState<boolean>();
  const [selectedRole, setSelectedRole] = useState<string | null>();
  const [isNotAvailableToSeeSections, setIsNotAvailableToSeeSections] = useState<boolean | null>();
  const [enablePaymentBtn, setEnablePaymentBtn] = useState<boolean>(false);
  const {setLoading} = useLoading();
  const [isConfirmNavigationDialogOpen, setIsConfirmNavigationDialogOpen] = useState<boolean>(false);
  const [isNavigationblocked] = useState<boolean>(true);
  const [confirmNavigation, setconfirmNavigation] = useState<boolean>(false);
  const [routerTransition, setRouterTansition] = useState<Transition>();
  const $state = useStateSubject();

  const [{error: hasUpdateLeadError, loading: isUpdateLeadLoading}, updateLead] = useAxiosBFF<DefaultResponse>(
    {
      method: HttpMethods.PATCH,
    },
    {manual: true}
  );

  const [{error: hasCreateLeadError, loading: isCreateLeadLoading}, createLead] = useAxiosBFF<DefaultResponse>(
    {
      method: HttpMethods.POST,
      url: `${FeatureName.LEADS}`,
    },
    {manual: true}
  );

  const [{error: hasFindLeadByIdError, loading: isFindLeadByIdLoading}, findLeadById] = useAxiosBFF<Lead>(
    {
      method: HttpMethods.GET,
      params: {formatted: true},
    },
    {manual: true}
  );

  const [
    {error: hasDeletePaymentInformationError, loading: isDeletePaymentInformationLoading},
    deletePaymentInformation,
  ] = useAxiosBFF<DefaultResponse>(
    {
      method: HttpMethods.DELETE,
    },
    {manual: true}
  );

  useEffect(() => {
    const loading =
      isCreateLeadLoading || isUpdateLeadLoading || isDeletePaymentInformationLoading || isFindLeadByIdLoading;
    setLoading(loading, 'LeadEditor');
  }, [isCreateLeadLoading, isUpdateLeadLoading]);

  useEffect(() => {
    if (hasFindLeadByIdError) {
      NotificationService.getInstance().sendNotification(
        hasFindLeadByIdError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasFindLeadByIdError]);

  useEffect(() => {
    if (hasDeletePaymentInformationError) {
      NotificationService.getInstance().sendNotification(
        hasDeletePaymentInformationError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasDeletePaymentInformationError]);

  useEffect(() => {
    if (hasUpdateLeadError) {
      NotificationService.getInstance().sendNotification(
        hasUpdateLeadError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasUpdateLeadError]);

  useEffect(() => {
    if (hasCreateLeadError) {
      NotificationService.getInstance().sendNotification(
        hasCreateLeadError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasCreateLeadError]);

  useEffect(() => {
    const sub = UserRoleService.getInstance()
      .getSelectedRole()
      .subscribe((role) => setSelectedRole(role));
    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    setIsNotAvailableToSeeSections(
      getUserOrganizationRoles(user).some(
        (role: any) =>
          (role.role_name === OrganizationRoleName.TELEMARKETER || role.role_name === OrganizationRoleName.SALES) &&
          role.role_id === selectedRole
      )
    );
  }, [user, selectedRole]);

  useEffect(() => {
    return () => {
      LeadService.getInstance().clearUnsavedComments();
    };
  }, []);

  useEffect(() => {
    setLeadId(lead?.lead_id);
    LeadService.getInstance().clearUnsavedComments();
  }, [lead]);

  useEffect(() => {
    console.debug('Current lead', leadId);
    setResetDropzoneState(true);
  }, [leadId]);

  useEffect(() => {
    if (leadId && userPermissions?.some((permission) => permission === UserPermissions.ENABLE_PAYMENT_INFO)) {
      setEnablePaymentBtn(true);
    }
  }, [leadId, userPermissions]);

  useEffect(() => {
    setConfig({
      ...config,
      decorators: [
        new BaseFormSectionSchemaDecorator((section) => {
          if (
            section.section_name &&
            ['communication', 'insx', 'history'].includes(section.section_name.toLowerCase())
          ) {
            if (isNotAvailableToSeeSections) {
              section.hidden = true;
            } else {
              section.hidden = !leadId;
            }
          }
        }),
      ],
    });
  }, [leadId, isNotAvailableToSeeSections]);

  function canViewAttachments(
    userPermissions: UserPermissions[] | null,
    lead: Lead | null,
    leadId?: string,
    hasAttachments?: boolean
  ): boolean {
    if (leadId && userPermissions && (lead?.has_attachments || hasAttachments)) {
      return userPermissions?.some((permission) => permission === UserPermissions.VIEW_UPLOAD_ATTACHMENTS);
    }
    return false;
  }

  useEffect(() => {
    if (canViewAttachments(userPermissions, lead, leadId)) {
      getMetaFiles(`${FolderPath.LEADS_ATTACHMENTS}${leadId}/`).then((_files: MetaFile[]) => setFiles(_files));
    } else {
      setFiles([]);
    }
  }, [leadId, userPermissions]);

  function saveLead(id: string | undefined, data: any): Promise<AxiosResponse> | undefined {
    if (id) {
      return updateLead({url: `${FeatureName.LEADS}/${id}`, data: data}).then(
        (response: AxiosResponse<DefaultResponse>) => response
      );
    } else {
      return createLead({data: data}).then((response: AxiosResponse<DefaultResponse>) => response);
    }
  }

  useEffect(() => {
    setConfig({...config, $state: $state});
  }, [$state]);

  useEffect(() => {
    if (confirmNavigation) {
      setconfirmNavigation(!confirmNavigation);
      routerTransition?.retry();
    }
  }, [confirmNavigation, routerTransition]);

  const blockNavigation = (tx: Transition) => {
    const state = $state.getValue();
    const hasChanges = hasChangedFields(state);
    const hasComments = LeadService.getInstance().getUnsavedCommentsValue().length > 0;
    const hasAttachments = filesToUpload?.length > 0;
    const hasUpdates = hasChanges || hasComments || hasAttachments;
    const uuid4LeadRoutePattern = new RegExp('^/leads/[\\da-zA-Z-]{36}$');
    const isOnSave = uuid4LeadRoutePattern.test(tx.location.pathname);
    if (!hasUpdates || isOnSave) return tx.retry();
    setIsConfirmNavigationDialogOpen(hasUpdates);
    setRouterTansition(tx);
  };
  useNavigationBlocker(blockNavigation, isNavigationblocked);

  function onSubmit(formValues: any) {
    const comments = LeadService.getInstance()
      .getUnsavedCommentsValue()
      .map((c) => {
        return {section: c.section, comment: c.comment};
      });

    if (comments?.length > 0) {
      formValues['comments'] = comments;
    }

    if (filesToUpload?.length) {
      formValues['has_attachments'] = true;
    }

    // This is temp workaround, refer to ticket: CRM1-784 may delete later
    if (formValues['last_updated_at']) {
      delete formValues['last_updated_at'];
    }

    if (leadId) {
      // if update, prepare values for PATCH
      formValues = difference(formValues, lead);
    }

    saveLead(leadId, formValues)?.then((response: AxiosResponse<DefaultResponse>) => {
      if (response?.status === 201 || (response?.status === 200 && leadId)) {
        NotificationService.getInstance().sendNotification(response?.data.message, NotificationType.SUCCESS);
      }

      const _leadId = leadId ?? response.data.id;
      if (!_leadId) {
        throw Error('Missing lead ID'); // this should never happen
      }
      uploadFiles(_leadId, filesToUpload).then((uploadResponse: string) => {
        onSubmitSucceeded(response, formValues);
        setFilesToUpload([]);
        setResetDropzoneState(true);
        let hasAttachments = false;
        if (uploadResponse === FilesService.with(auth0).filesUploadedSuccessMessage) {
          hasAttachments = true;
        }
        if (canViewAttachments(userPermissions, lead, leadId, hasAttachments)) {
          if (getMetaFiles) {
            getMetaFiles(`${FolderPath.LEADS_ATTACHMENTS}${leadId}/`).then((_files: MetaFile[]) => setFiles(_files));
          }
        }
      });
    });
  }

  function handleLeadChangeStatus() {
    const targetState = lead?.status === Status.ACTIVE ? Status.INACTIVE : Status.ACTIVE;
    if (leadId) {
      const data = {status: targetState};
      saveLead(leadId, data)?.then((response: AxiosResponse<DefaultResponse>) => {
        if (response?.status === 201 || (response?.status === 200 && leadId)) {
          NotificationService.getInstance().sendNotification(response?.data.message, NotificationType.SUCCESS);
        }
        if (response?.status === 200) {
          setLead({...lead, ...data});
        }
      });
    }
  }

  function handleDeletePaymentInfo(_leadId: string): void {
    deletePaymentInformation({url: `${FeatureName.LEADS}/${_leadId}/payment_information`}).then(
      (response: AxiosResponse<DefaultResponse>) => {
        NotificationService.getInstance().sendNotification(response.data.message, NotificationType.SUCCESS);
        getLead(_leadId);
      }
    );
  }

  function getLead(_leadId: string): void {
    findLeadById({url: `${FeatureName.LEADS}/${_leadId}`}).then((response: AxiosResponse<Lead>) => {
      setLead(response.data);
    });
  }

  return (
    <>
      <PageStickyHeader>
        <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
          <Grid item xs={6} md={4} order={{xs: 1, md: 1}}>
            <PageHeadline>
              <div>
                {headline}
                <Typography variant={'h4'}>{`${subHeadline}`}</Typography>
              </div>
            </PageHeadline>
          </Grid>
          <Grid item xs={12} md={8} order={{xs: 3, md: 2}}>
            <PageTopActions>
              {leadId && (
                <>
                  <AccessControl
                    userPermissions={userPermissions}
                    allowedPermissions={[UserPermissions.VIEW_DELETE_PAYMENT]}
                  >
                    <DeletePaymentInfoDialog
                      isDialogOpen={isDeleteDialogOpen}
                      handleClose={() => setIsDeleteDialogOpen(false)}
                      handleConfirm={() => handleDeletePaymentInfo(leadId)}
                    />
                    <Button
                      color="secondary"
                      disabled={!enablePaymentBtn}
                      onClick={() => setIsDeleteDialogOpen(true)}
                      id={'open-delete-payment-dialog-btn'}
                      {...{[TestAttributes.BUTTON_NAME]: 'open-delete-payment-dialog-btn'}}
                    >
                      {t('leads.dialogs.delete-payment-info.label-btn')}
                    </Button>
                  </AccessControl>
                  <AccessControl
                    userPermissions={userPermissions}
                    allowedPermissions={[UserPermissions.DEACTIVATE_FEATURE_ITEM]}
                  >
                    <ConfirmationDialog
                      message={
                        lead?.status === Status.ACTIVE ? t('leads.deactivate-message') : t('leads.activate-message')
                      }
                      headline={lead?.status === Status.ACTIVE ? t('leads.deactivate') : t('leads.activate')}
                      isDialogOpen={isDeactivatedModalOpen}
                      handleClose={() => setIsDeactivatedModalOpen(false)}
                    >
                      <Button
                        onClick={() => {
                          handleLeadChangeStatus();
                          setIsDeactivatedModalOpen(false);
                        }}
                        id="confirmation-dialog-action-btn"
                        {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
                      >
                        {lead?.status === Status.ACTIVE ? t('leads.deactivate') : t('leads.activate')}
                      </Button>
                    </ConfirmationDialog>
                    <Button
                      id={'confirmation-dialog-btn'}
                      {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                      color="secondary"
                      onClick={() => setIsDeactivatedModalOpen(true)}
                    >
                      {lead?.status === Status.ACTIVE ? t('leads.deactivate') : t('leads.activate')}
                    </Button>
                  </AccessControl>
                </>
              )}

              {children}

              <Button
                id={'confirmation-dialog-btn'}
                {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                color="secondary"
                onClick={() => setIsConfirmationDialogOpen(true)}
              >
                {t('shared.clear')}
              </Button>
              <AccessControl
                userPermissions={userPermissions}
                allowedPermissions={[leadId ? UserPermissions.MODIFY : UserPermissions.CREATE]}
              >
                <Button
                  id={'save-btn'}
                  {...{[TestAttributes.BUTTON_NAME]: 'save-btn'}}
                  onClick={() => formAPI.submit()}
                >
                  {leadId ? t('shared.update') : t('shared.save')}
                </Button>
              </AccessControl>
            </PageTopActions>
          </Grid>
        </Grid>
        <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW_CLICK_TO_ACTIONS]}>
          <Grid container item xs={6} md={4}>
            <LeadActions
              lead={lead}
              editMode={!!leadId}
              userPermissions={userPermissions ?? []}
              formAPI={formAPI}
            ></LeadActions>
          </Grid>
        </AccessControl>
      </PageStickyHeader>
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={12} md={campaignScript ? 8 : 12}>
          {/*Auto-layout no size on purpose The Auto-layout makes the items equitably share the available space on*/}
          <Grid item>
            <FormBuilder
              formId={FeatureName.LEADS}
              api={formAPI}
              onSubmit={onSubmit}
              initialValues={lead}
              config={config}
              onRejectSubmit={onRejectSubmit}
            />
          </Grid>
          {lead?.lead_id && (
            <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW_LEAD_SUMMARY]}>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="lead-summary-panel"
                    id="lead-summary-panel"
                  >
                    <Typography variant="h2">{`${t('roles.processor')}`}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormBuilder formId={FeatureName.LEAD_SUMMARY} initialValues={lead} config={config}></FormBuilder>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </AccessControl>
          )}
          <AccessControl
            userPermissions={userPermissions}
            allowedPermissions={[UserPermissions.VIEW_UPLOAD_ATTACHMENTS]}
          >
            <Grid item container alignItems="center" justifyContent="center" display="flex">
              <Grid item xs={12} sm={8} md={6}>
                <Card>
                  <CardContent>
                    <DropzoneWrapper
                      filesToShow={files}
                      acceptedExtensions={[
                        FileExtensions.TSV,
                        FileExtensions.PDF,
                        FileExtensions.DOC,
                        FileExtensions.DOCX,
                        FileExtensions.XLSX,
                        FileExtensions.PPTX,
                        FileExtensions.JPEG,
                        FileExtensions.JPG,
                        FileExtensions.PNG,
                        FileExtensions.MP3,
                        FileExtensions.MP4,
                        FileExtensions.WAV,
                        FileExtensions.M4A,
                        FileExtensions.WMA,
                      ]}
                      onChangeFunction={(file: IFileWithMeta, statusValue: string, allFiles: IFileWithMeta[]) => {
                        setResetDropzoneState(false);
                        setFilesToUpload(
                          FilesService.with(auth0).buildFormData(allFiles, leadId, FolderPath.LEADS_ATTACHMENTS)
                        );
                      }}
                      disableUploadButton
                      footerLabel={t('uploader.upload-conditions-leads')}
                      userPermissions={userPermissions}
                      resourceType={ResourceTypes.LEADS}
                      resetDropzone={resetDropzoneState}
                      resetDropzoneFunction={setResetDropzoneState}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </AccessControl>
        </Grid>

        {campaignScript && (
          <Grid item xs={12} md={4}>
            {campaignScript}
          </Grid>
        )}
      </Grid>

      <ConfirmationDialog
        message={t('shared.clear-form-modal-content')}
        headline={t('shared.clear-form-modal-headline')}
        isDialogOpen={isConfirmationDialogOpen}
        handleClose={() => setIsConfirmationDialogOpen(false)}
      >
        <Button
          onClick={() => {
            formAPI.reset();
            LeadService.getInstance().clearUnsavedComments();
            setIsConfirmationDialogOpen(false);
          }}
          {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
          id="confirmation-dialog-action-btn"
        >
          {t('shared.accept')}
        </Button>
      </ConfirmationDialog>

      <ConfirmationDialog
        message={t('shared.unsaved-changes.message')}
        headline={t('shared.unsaved-changes.headline')}
        isDialogOpen={isConfirmNavigationDialogOpen}
        handleClose={() => setIsConfirmNavigationDialogOpen(false)}
      >
        <Button
          onClick={() => {
            setconfirmNavigation(true);
            setIsConfirmNavigationDialogOpen(false);
          }}
          {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
          id="confirmation-dialog-action-btn"
        >
          {t('shared.discard')}
        </Button>
      </ConfirmationDialog>
    </>
  );
}

export default LeadEditor;
