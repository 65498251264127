import React, {PropsWithChildren, useState} from 'react';
import {Button, Grid, Typography} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ModalWrapper from './ModalWrapper';

interface Props extends PropsWithChildren {
  label: string;
  editor?: React.ReactNode;
  disabled?: boolean;
}

export default function SingleLayoutComponent({label, children, editor, disabled}: Props) {
  const [expand, setExpand] = useState(false);
  const [open, setOpen] = useState(false);

  const Icon = expand ? KeyboardArrowDownIcon : KeyboardArrowRightIcon;

  return (
    <Grid container spacing={1} sx={{marginBottom: 1}}>
      <Grid item>
        <Button disabled={!children} onClick={() => setExpand((prev) => !prev)} variant={'text'}>
          <Icon color={disabled ? 'disabled' : undefined} />
        </Button>
      </Grid>
      <Grid item>
        <Button disabled={!editor} onClick={() => setOpen((prev) => !prev)} variant={'text'}>
          <Typography color={disabled ? (t) => t.palette.text.disabled : undefined} variant={'h4'}>
            {label}
          </Typography>
        </Button>
      </Grid>
      <ModalWrapper open={open} handleClose={() => setOpen(false)}>
        {editor}
      </ModalWrapper>
      {expand && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
    </Grid>
  );
}
