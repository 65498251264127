import {FormFieldOption, FormFieldSchema, FormFieldType, FormSchema, FormSectionSchema} from './FormSchema';

function isDefined(value: any): boolean {
  return !(value === undefined || value === null);
}

export function toControlledComponentMultipleValue(value: any): any[] {
  return isDefined(value) ? (Array.isArray(value) ? value : [value]) : [];
}

export function toControlledComponentSimpleValue(value: any): any {
  return isDefined(value) ? value : '';
}

export function toControlledComponentValue(type: FormFieldType, value: any) {
  switch (type) {
    case FormFieldType.COMMENTS:
    case FormFieldType.CHECKBOXES:
    case FormFieldType.SELECTMULTIPLE: {
      return toControlledComponentMultipleValue(value);
    }
    default:
      return toControlledComponentSimpleValue(value);
  }
}

export function fromControlledComponentMultipleValue(value: any): any[] {
  return value === [] ? null : value;
}

export function fromControlledComponentSimpleValue(value: any): any {
  return value === '' ? null : value;
}

export function fromControlledComponentValue(type: FormFieldType, value: any) {
  switch (type) {
    case FormFieldType.CHECKBOXES:
    case FormFieldType.SELECTMULTIPLE: {
      return fromControlledComponentMultipleValue(value);
    }
    default:
      return fromControlledComponentSimpleValue(value);
  }
}

export function collectDefaultValues(formSchema: FormSchema) {
  const fieldValues = {};

  function processField(field: FormFieldSchema) {
    if (isDefined(field.default_value)) {
      fieldValues[field.field_name] = toControlledComponentValue(field.field_type, field.default_value);
    }
  }

  traverseSchemaFields(formSchema, (field) => processField(field));

  return fieldValues;
}

export function traverseSchemaFields(schema: FormSchema, consumer: (field: FormFieldSchema) => void) {
  traverseSchemaSections(schema, (section) => section.fields?.forEach(consumer));
}

export function traverseSchemaSections(schema: FormSchema, consumer: (section: FormSectionSchema) => void) {
  function consumeSection(section: FormSectionSchema) {
    consumer(section);
    for (let innerSection of section.sections || []) {
      consumeSection(innerSection);
    }
  }

  for (let section of schema?.sections || []) {
    consumeSection(section);
  }
}

function isErrorWithContent<T>(error: any): error is ErrorWithContent<T> {
  return error?.whoami === 'ErrorWithContent';
}

class ErrorWithContent<T> extends Error {
  whoami = 'ErrorWithContent';
  content: T;

  constructor(message: string, obj: T) {
    super(message);
    this.content = obj;
  }
}

export function findSection(
  schema: FormSchema,
  findBy: (section: FormSectionSchema) => boolean
): FormSectionSchema | undefined {
  try {
    traverseSchemaSections(schema, (section: FormSectionSchema) => {
      if (findBy(section)) {
        // use throwable to shortcut traverse
        throw new ErrorWithContent('Stop traverse: section found', section);
      }
    });
  } catch (e: any) {
    if (isErrorWithContent<FormSectionSchema>(e)) return e.content;
  }
}

export function findField(
  schema: FormSchema,
  findBy: (field: FormFieldSchema) => boolean
): FormFieldSchema | undefined {
  try {
    traverseSchemaFields(schema, (field: FormFieldSchema) => {
      if (findBy(field)) {
        // use throwable to shortcut traverse
        throw new ErrorWithContent('Stop traverse: field found', field);
      }
    });
  } catch (e: any) {
    if (isErrorWithContent<FormFieldSchema>(e)) return e.content;
  }
}

export function extractFieldOptionValues(options: FormFieldOption[]): any[] {
  return options.map((opt) => opt.value);
}

export function equal(source: any, target: any): boolean {
  return Array.isArray(source) ? source?.includes(target) : source === target;
}

export function isBlank(value: any): boolean {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'string' && !hasText(value)) ||
    (typeof value === 'number' && Number.isNaN(value))
  );
}

// Same as isBlank with additional support for arrays
export function isBlankOrEmpty(value: any): boolean {
  return isBlank(value) || (Array.isArray(value) && value.length === 0);
}

// Matches any whitespace chars. Whitespace chars are whitespaces, new-lines, tabs, carriage-return, etc..
const WHITESPACE_CHARS = /^\s*$/;

export function hasText(value: any): boolean {
  return !!(value && typeof value === 'string' && !WHITESPACE_CHARS.test(value));
}

export function testRegex(regex: RegExp, value: any): boolean {
  return regex.test(value === undefined || value === null ? '' : value);
}
