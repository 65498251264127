import {Tab} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {Auth0User} from '../../../model/Auth0User';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {useLoading} from '../../../context/LoadingContext';
import {AvailablePermissions} from './AvailablePermissions';
import {UserPermissions} from './UserPermissions';
import {useTranslation} from 'react-i18next';

function UserPermissionsPageComponent() {
  const [value, setValue] = useState<string>('0');
  const [permissions, setPermissions] = useState<string[]>([]);
  const auth0 = useAuth0<Auth0User>();
  const {setLoading} = useLoading();
  const {t} = useTranslation();

  const refreshPermission = useMemo(
    () => () =>
      auth0
        .getAccessTokenSilently()
        .then((token) =>
          fetch('/api/user/v2/user-permissions-available', {headers: {authorization: `bearer ${token}`}})
        )
        .then((response) => response.json())
        .then((data) => setPermissions(data.filter((p: string) => !p.startsWith('integration-test:')))),
    []
  );

  useEffect(() => {
    setLoading(true, 'AvailablePermissions');
    refreshPermission().finally(() => setLoading(false, 'AvailablePermissions'));
  }, []);

  return (
    <>
      <TabContext value={value}>
        <TabList
          aria-label="settings tabs"
          onChange={(event: React.SyntheticEvent, newValue: string) => setValue(newValue)}
        >
          <Tab label="User Permissions" value="0" />
          <Tab label="Available Permissions" value="1" />
        </TabList>

        <TabPanel value="0">
          <UserPermissions permissions={permissions}></UserPermissions>
        </TabPanel>
        <TabPanel value="1">
          <AvailablePermissions permissions={permissions} refreshPermissions={refreshPermission}></AvailablePermissions>
        </TabPanel>
      </TabContext>
    </>
  );
}

export default UserPermissionsPageComponent;
