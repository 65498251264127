import {FormControl, FormControlLabel, FormGroup, FormLabel} from '@mui/material';
import React from 'react';
import {buildSetValue, propsWithTestAttributes, SpartanFieldOption, SpartanFieldWithOptionProps} from './model';
import Checkbox from '@mui/material/Checkbox';
import {ErrorMessage} from './components/ErrorMessage';
import {removeFromArray} from '../utils';
import {SpartanLabelWithTooltip} from './SpartanLabelWithTooltip';

export function SpartanCheckboxesField(_props: SpartanFieldWithOptionProps) {
  const props = propsWithTestAttributes(_props);
  const {id, name, value, label, disabled, onBlur, onFocus, hasError, error, options, inputRef, inputProps} = props;
  const array = value || [];
  const setValue = buildSetValue(props);
  return (
    <>
      <FormControl error={hasError}>
        <FormLabel error={hasError} htmlFor={id}>
          <SpartanLabelWithTooltip label={label} />
        </FormLabel>
        <FormGroup onBlur={onBlur} onFocus={onFocus}>
          {options.map((item: SpartanFieldOption, idx: number) => (
            <FormControlLabel
              key={idx}
              label={item.label}
              control={
                <Checkbox
                  color={'primary'}
                  name={name}
                  disabled={disabled}
                  value={item.value}
                  checked={array.includes(item.value)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      array.push(e.target.value);
                    } else {
                      removeFromArray(array, e.target.value);
                    }
                    setValue(array);
                  }}
                  inputRef={inputRef}
                  inputProps={inputProps}
                />
              }
            />
          ))}
        </FormGroup>

        <ErrorMessage hasError={hasError} error={error} />
      </FormControl>
    </>
  );
}
