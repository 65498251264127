import React from 'react';
import PermissionAction from './PermissionAction';
import {Grid, Typography} from '@mui/material';
import {PermissionRoleProps} from './models/models';

function PermissionRole({resourceId, label, roleId, actions}: PermissionRoleProps) {
  return (
    <Grid container item direction="column" alignItems="center" xs={1} key={`papper-${resourceId}-${roleId}`}>
      <Grid item xs={1}>
        <Typography>{label}</Typography>
      </Grid>
      {actions?.map((action) => {
        return (
          <Grid item xs={1}>
            <PermissionAction
              roleId={roleId}
              actionId={action.actionId}
              label={action.label}
              resourceId={resourceId}
              checked={action.checked}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default PermissionRole;
