import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import {Box, Button, Container, Grid, IconButton, Menu, MenuItem} from '@mui/material';
import {GridCellParams, GridColDef, GridRowModel} from '@mui/x-data-grid';
import {Skeleton} from '@mui/lab';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PageHeadline from '../../components/PageHeadline';
import {PageTopActions} from '../PageTopActions';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName, Path} from '../../../paths';
import {PageStickyHeader} from '../PageStickyHeader';
import {useTableColumns} from '../UseTableColumns';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import DataGridCustom from '../../components/DataGridCustom';
import {CampaignScript} from '../../model/CampaignScript';
import {TestAttributes} from '../../TestAttributes';
import {ColumnSizes} from '../../components/shared/ColumnSize';
import CopyUserGuideDialog from './dialogs/CopyUserGuideDialog';
import SpartanSnackbar from '../../components/SpartanSnackbar';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

function CallScriptManagerListPage() {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const {setLoading} = useLoading();
  const navigate = useNavigate();
  const [campaignScripts, setCampaignScripts] = useState<Array<CampaignScript> | null>(null);
  const [actionColumn, setActionColumn] = useState<GridColDef>();
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isCopyUserGuideDialogOpen, setIsCopyUserGuideDialogOpen] = useState<boolean>(false);
  const [selectedUserGuide, setSelectedUserGuide] = useState<CampaignScript | null>(null);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.USER_GUIDE);
  const [{data: entityList, loading: isEntityListLoading, error: hasEntityListError}, getCampaignScriptList] =
    useAxiosBFF<ResponseListWrapper<CampaignScript>>(`/${FeatureName.USER_GUIDE}`);

  const [
    {response: postCampaignScriptResponse, loading: postCampaignScriptLoading, error: postCampaignScriptError},
    postCampaignScriptData,
  ] = useAxiosBFF<CampaignScript>(
    {url: `/${FeatureName.USER_GUIDE}/copy/${selectedUserGuide?.guide_id}`, method: 'POST'},
    {manual: true}
  );

  useEffect(() => {
    setLoading(isEntityListLoading || postCampaignScriptLoading, 'CallScriptManagerListPage');
  }, [isEntityListLoading, postCampaignScriptLoading]);

  useEffect(() => {
    if (postCampaignScriptError) {
      NotificationService.getInstance().sendNotification(
        postCampaignScriptError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [postCampaignScriptError]);
  useEffect(() => {
    setActionColumn({
      field: 'actions',
      headerName: t('table-columns.actions'),
      width: ColumnSizes.MD,
      filterable: false,
      disableExport: true,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <IconButton
              {...{[TestAttributes.BUTTON_NAME]: 'guide-list-more-actions-btn'}}
              id="guide-list-more-actions-btn"
              aria-haspopup="true"
              onClick={(event: React.MouseEvent<HTMLElement>) => handleActionsMenuIconClick(event, params)}
              size="large"
            >
              <MoreHorizIcon />
            </IconButton>
          </>
        );
      },
    });
  }, []);
  const tableColumns = useTableColumns({
    featureName: FeatureName.USER_GUIDE,
    actionColumn,
  });
  useEffect(() => {
    if (postCampaignScriptResponse) {
      NotificationService.getInstance().sendNotification(
        postCampaignScriptResponse?.data?.['message'],
        NotificationType.SUCCESS
      );
      getCampaignScriptList();
    }
  }, [postCampaignScriptResponse]);
  useEffect(() => {
    if (entityList) {
      setCampaignScripts(entityList?.results || []);
    }
  }, [entityList]);

  function handleCopyUserGuide(copiedGuideName: string): void {
    postCampaignScriptData({
      data: {guide_name: copiedGuideName},
    });
  }
  function handleMoreActionsMenuClose(): void {
    setActionsMenuAnchorEl(null);
  }

  function handleQuerySelected(): void {
    handleMoreActionsMenuClose();
    setIsCopyUserGuideDialogOpen(true);
  }
  function handleActionsMenuIconClick(event: React.MouseEvent<HTMLElement>, cell: GridCellParams): void {
    const {row}: GridRowModel = cell;
    setActionsMenuAnchorEl(event.currentTarget);
    setSelectedUserGuide(row);
  }
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {hasEntityListError && (
            <SpartanSnackbar type={NotificationType.ERROR} message={hasEntityListError.message}></SpartanSnackbar>
          )}
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('call-script-page.headline')}</PageHeadline>
              </Grid>
              <Grid item xs={12} md={6}>
                <PageTopActions>
                  <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.CREATE]}>
                    <Link to={`/${Path.USER_GUIDE}/create`}>
                      <Button fullWidth color="secondary" id={'add-btn'}>
                        {t('globalActions.addButton')}
                      </Button>
                    </Link>
                  </AccessControl>
                </PageTopActions>
              </Grid>
            </Grid>
          </PageStickyHeader>
          <Grid item xs={12} sx={{height: '500px'}} {...{[TestAttributes.TABLE_NAME]: 'table-results'}}>
            {isEntityListLoading ? (
              <Skeleton variant="rectangular" height={500} />
            ) : (
              <DataGridCustom
                tableColumns={tableColumns}
                rows={
                  campaignScripts?.map((campaignScript: CampaignScript, index: number) => {
                    return {
                      ...campaignScript,
                      // created_at: formatDateTime(team.created_at),
                      // last_updated_at: formatDateTime(team.last_updated_at),
                      actions: campaignScript.guide_id,
                      id: index,
                    };
                  }) || []
                }
                userPermissions={userPermissions}
              />
            )}
            <Menu
              id="guide-list-more-actions-menu"
              keepMounted
              anchorEl={actionsMenuAnchorEl}
              open={Boolean(actionsMenuAnchorEl)}
              onClose={handleMoreActionsMenuClose}
            >
              {selectedUserGuide && (
                <MenuItem
                  id={'guide-list-more-actions-menu-item-view'}
                  {...{[TestAttributes.FIELD_OPTION_NAME]: 'guide-list-more-actions-menu-item-view'}}
                  onClick={() => navigate(`/${FeatureName.USER_GUIDE}/${selectedUserGuide.guide_id}`)}
                >
                  {t('shared.view')}
                </MenuItem>
              )}
              <MenuItem
                id={'guide-list-more-actions-menu-item-copy'}
                {...{[TestAttributes.FIELD_OPTION_NAME]: 'guide-list-more-actions-menu-item-copy'}}
                onClick={() => handleQuerySelected()}
              >
                {t('shared.copy')}
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Container>
      <CopyUserGuideDialog
        isOpen={isCopyUserGuideDialogOpen}
        handleClose={() => setIsCopyUserGuideDialogOpen(false)}
        selectedUserGuide={selectedUserGuide}
        copyUserGuide={handleCopyUserGuide}
      />
    </Box>
  );
}

export default CallScriptManagerListPage;
