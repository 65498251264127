import {useEffect, useState} from 'react';
import {Controller, FormProvider, useFieldArray, useForm, useWatch} from 'react-hook-form';
import {useAuth0} from '@auth0/auth0-react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {pick} from 'lodash';
import {GridRowId} from '@mui/x-data-grid';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PageHeadline from '../../components/PageHeadline';
import {PageTopActions} from '../PageTopActions';
import {
  Condition,
  QueryDetail,
  QueryDetailLead,
  QueryFieldSchema,
  QueryFormSchemaResponseWrapper,
  QueryListItem,
  QueryMode,
} from '../../model/Query';
import {FeatureName} from '../../../paths';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import VirtualFieldList from '../query/components/VirtualFieldList';
import {MassiveUpdateFieldToUpdate, MassiveUpdateFormModel} from './MassiveUpdateFormModel';
import {generateId} from '../../utils/Utils';
import {ErrorMessage} from '../../form/fields/components/ErrorMessage';
import MassiveUpdateFieldValue from './components/MassiveUpdateFieldValue';
import {Auth0User, getUserEmail} from '../../model/Auth0User';
import {PageStickyHeader} from '../PageStickyHeader';
import {FormFieldName} from '../../form/logic/FormSchema';
import {DefaultResponse} from '../../services/model/DefaultResponse';
import {formatDateTime} from '../../utils/DateUtils';
import {PaginatedResponse} from '../../services/model/PaginatedResponse';
import {useTableColumns} from '../UseTableColumns';
import DataGridCustom from '../../components/DataGridCustom';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import {TestAttributes} from '../../TestAttributes';
import {DynamicOptionsService} from '../../services/DynamicOptionsService';
import FieldsApiClient from '../../services/api/FieldsApiClient';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {LeadListItem} from '../../model/Lead';
import {AxiosResponse} from 'axios';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

function MassiveUpdatePage() {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const {setLoading} = useLoading();
  const auth0 = useAuth0<Auth0User>();
  const tableColumns = useTableColumns({
    featureName: FeatureName.LEADS,
    defaultActionColumn: false,
  });
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [loadingQueryLeads, setLoadingQueryLeads] = useState<boolean>(false);
  const [searchFieldQuery, setSearchFieldQuery] = useState<string>('');
  const [updateAllLeads, setUpdateAllLeads] = useState<boolean>(true);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.LEADS);
  const [allFields, setAllFields] = useState<Array<QueryFieldSchema> | null>(null);
  const [filteredFields, setFilteredFields] = useState<Array<QueryFieldSchema>>([]);

  const [queryLeads, setQueryLeads] = useState<Array<QueryDetailLead>>([]);
  const [queryList, setQueryList] = useState<Array<QueryListItem>>([]);

  const [page, setPage] = useState<number>(0);
  const [pageToRequest, setPageToRequest] = useState<number>(0);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [trueTime, setTrueTime] = useState<string>();
  const [query, setQuery] = useState<any>();
  const [pageCount, setPageCount] = useState<number>(0);

  const navigate = useNavigate();
  const pageSize: number = 100;
  const methods = useForm<MassiveUpdateFormModel>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {control, handleSubmit, setValue, formState, reset} = methods;

  const queryId = useWatch({control, name: `query_id`});

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'fields_update',
  });

  const [{loading: getQueriesLoading}, getAllQueries] = useAxiosBFF<ResponseListWrapper<QueryListItem>>(
    {
      url: `/${FeatureName.QUERIES}`,
      method: 'GET',
      params: {type: QueryMode.PUBLIC},
    },
    {manual: true}
  );
  const [{error: getMetadataForQueriesError}, getMetadataForQueries] = useAxiosBFF<QueryFormSchemaResponseWrapper>(
    {
      url: `/${FeatureName.QUERIES}`,
      method: 'GET',
      params: {metadata_from: FeatureName.LEADS, metadata_for: FeatureName.MASSIVE_UPDATE},
    },
    {manual: true}
  );
  const [{error: getQueryDetailError, loading: getQueryDetailLoading}, getQueryDetail] = useAxiosBFF<QueryDetail>(
    {
      url: `/${FeatureName.QUERIES}/${queryId}`,
      method: 'GET',
      params: {
        skip_search: false,
      },
    },
    {manual: true}
  );
  const [{error: runQueryError, loading: isRunEntityLoading}, runQuery] = useAxiosBFF<PaginatedResponse<LeadListItem>>(
    {url: `/${FeatureName.QUERIES}`, method: 'POST'},
    {manual: true}
  );

  const [{error: hasMassiveUpdateError, loading: isMassiveUpdateLoading}, patchMassiveUpdate] =
    useAxiosBFF<DefaultResponse>({url: `massupdate`, method: 'PATCH'}, {manual: true});

  useEffect(() => {
    const loading = getQueryDetailLoading || isRunEntityLoading || getQueriesLoading || isMassiveUpdateLoading;
    setLoading(loading, 'MassiveUpdatePage');
  }, [getQueryDetailLoading, isRunEntityLoading, getQueriesLoading]);

  useEffect(() => {
    if (runQueryError) {
      NotificationService.getInstance().sendNotification(
        runQueryError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [runQueryError]);

  useEffect(() => {
    if (hasMassiveUpdateError) {
      NotificationService.getInstance().sendNotification(
        hasMassiveUpdateError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasMassiveUpdateError]);

  useEffect(() => {
    if (getQueryDetailError) {
      NotificationService.getInstance().sendNotification(
        getQueryDetailError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [getQueryDetailError]);
  useEffect(() => {
    if (getMetadataForQueriesError) {
      NotificationService.getInstance().sendNotification(
        getMetadataForQueriesError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [getMetadataForQueriesError]);
  useEffect(() => {
    getMetadataForQueries()
      .then((response: any) => {
        const data: QueryFormSchemaResponseWrapper = response?.data;
        if (data?.query_condition_fields) {
          return new DynamicOptionsService(FieldsApiClient.with(auth0))
            .resolveDynamicOptions(data.query_condition_fields)
            .then((query_condition_fields) => ({...data, query_condition_fields}));
        }
        return data;
      })
      .then((response: QueryFormSchemaResponseWrapper) => {
        if (response?.query_condition_fields) {
          const searchableFields = response.query_condition_fields;
          searchableFields?.forEach(
            (f) =>
              (f.label = t(
                `feature.${FeatureName.LEADS}.field.${f.field_name}.query_condition_field`,
                t(`feature${FeatureName.LEADS}.field.${f.field_name}`, f.label)
              ))
          );
          setAllFields(searchableFields);
          setFilteredFields(searchableFields.sort((a, b) => a.field_name.localeCompare(b.field_name)));
        }
      });
  }, [auth0]);

  useEffect(() => {
    getAllQueries().then((response: any) => {
      const responseWrapper: ResponseListWrapper<QueryListItem> = response?.data;
      setQueryList(responseWrapper?.results);
    });
  }, []);
  useEffect(() => {
    if (count) {
      setPageCount(count / pageSize - 1);
    }
  }, [count]);

  useEffect(() => {
    if (page === pageCount && count < totalResults) {
      setPageToRequest(pageToRequest + 1);
    }
  }, [page]);
  useEffect(() => {
    if (queryId && !updateAllLeads) {
      setLoadingQueryLeads(true);
      getQueryDetail()
        .then((response: any) => {
          const queryDetails: QueryDetail = response?.data;
          setQuery(queryDetails?.query);
          initialicePaginationState();
        })
        .finally(() => setLoadingQueryLeads(false));
    }
  }, [queryId, updateAllLeads]);

  useEffect(() => {
    if (allFields) {
      const result = allFields.filter((filter: QueryFieldSchema) => {
        const searchTextInLowerCase = searchFieldQuery.toLowerCase();
        return (
          filter.label?.toLowerCase().includes(searchTextInLowerCase) ||
          filter.label_search?.toLowerCase().includes(searchTextInLowerCase)
        );
      });
      setFilteredFields(result);
    }
  }, [searchFieldQuery]);

  useEffect(() => {
    setValue('leads', selectionModel);
  }, [selectionModel]);

  function handleOnFieldClick(field: QueryFieldSchema): void {
    if (!fields.some((fieldToUpdate: MassiveUpdateFieldToUpdate) => fieldToUpdate.field_name === field.field_name)) {
      append({
        field_name: field.field_name,
        field_type: field.field_type,
        data_type: field.data_type,
        label: field?.label || '',
        id: field.field_id,
        select_options: field?.['select_options'] || [],
        value: field.field_name === FormFieldName.TINY_URL ? '{0}autogenerated' : '',
      });
    }
  }

  function findOptionsByValues(options: QueryListItem[], value: string): QueryListItem | null {
    const option = options.find((opt: QueryListItem) => opt.query_id === value);
    return option ?? null;
  }

  function initialicePaginationState() {
    setPageToRequest(1);
    setPage(0);
  }

  function getNextPage() {
    const queryData = {
      ...pick(query, ['order', 'order_by', 'query_conditions', 'query_fields']),
    };
    const params = {
      action: 'run',
      feature: FeatureName.LEADS,
      page: pageToRequest,
    };

    if (queryData.query_conditions) {
      queryData.query_conditions.forEach((condition: Condition) => {
        condition['values'] = condition['values'] === undefined ? [] : condition['values'];
      });
    }

    if (trueTime) {
      params['true_time'] = trueTime;
    }
    runQuery({
      data: queryData,
      params,
    }).then((response: any) => {
      const paginationResponse: PaginatedResponse<any> = response?.data;
      setTotalResults(paginationResponse?.count_total);
      setQueryLeads([...queryLeads, ...(paginationResponse?.results || [])]);
      setCount(count + paginationResponse?.count);
      setTrueTime(paginationResponse?.true_time);
    });
  }

  useEffect(() => {
    if (pageToRequest >= 1) {
      getNextPage();
    }
  }, [pageToRequest]);

  function sendMassiveUpdate(formValues: MassiveUpdateFormModel): void {
    const payload = parsePayload(formValues);
    patchMassiveUpdate({data: payload}).then((response: AxiosResponse<DefaultResponse>) => {
      if (response?.status === 200) {
        NotificationService.getInstance().sendNotification(response?.data?.message, NotificationType.SUCCESS);
        setQueryLeads([]);
        reset();
        setValue('fields_update', []);
      }
    });
  }

  function parsePayload(formValues: MassiveUpdateFormModel): any {
    const emailNotification = getUserEmail(auth0.user) ?? '';

    const fieldsToUpdate = formValues.fields_update
      .map((field) => {
        return {
          [field.field_name]: field.value,
        };
      })
      .reduce(function (
        previousValue: {[field: string]: string},
        currentValue: {[field: string]: string},
        index,
        array
      ) {
        return {...previousValue, ...currentValue};
      });

    const parsedFormValues = {
      leads: formValues.leads,
      query_id: formValues.query_id,
      fields_update: fieldsToUpdate,
      email_notification: emailNotification,
    };

    if (parsedFormValues?.leads?.length === 0) {
      delete parsedFormValues.leads;
    }

    return parsedFormValues;
  }

  function onCancel() {
    navigate(`/${FeatureName.LEADS}`);
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <PageStickyHeader>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('massive-update.headline')}</PageHeadline>
              </Grid>
              <Grid item xs={12} md={6}>
                <form onSubmit={handleSubmit(sendMassiveUpdate)}>
                  <PageTopActions>
                    <Button
                      color="secondary"
                      id={'cancel-btn'}
                      {...{[TestAttributes.BUTTON_NAME]: 'cancel-btn'}}
                      onClick={() => onCancel()}
                    >
                      {t('shared.cancel')}
                    </Button>
                    <Button
                      id={'save-btn'}
                      {...{[TestAttributes.BUTTON_NAME]: 'save-btn'}}
                      disabled={
                        !formState.isValid || !(fields.length > 0) || (!updateAllLeads && selectionModel?.length === 0)
                      }
                      type={'submit'}
                    >
                      {t('shared.update')}
                    </Button>
                  </PageTopActions>
                </form>
              </Grid>
            </Grid>
          </PageStickyHeader>

          <Grid item xs={12} />
          <Grid container item xs={12}>
            <Grid item xs={12} sm={12} md={7}>
              {queryList && (
                <Controller
                  control={control}
                  name="query_id"
                  rules={{
                    required: true,
                  }}
                  render={({field: {onChange, value}, fieldState: {invalid, error}}) => (
                    <>
                      <InputLabel htmlFor={'selected-query-select'}>{t('massive-update.query')}</InputLabel>
                      <Autocomplete
                        id={'selected-query-select'}
                        options={queryList}
                        filterSelectedOptions={true}
                        value={findOptionsByValues(queryList, value)}
                        getOptionLabel={(option: QueryListItem) => option.name ?? ''}
                        isOptionEqualToValue={(option: QueryListItem, value: QueryListItem) =>
                          option.query_id === value.query_id
                        }
                        renderOption={(props, option: QueryListItem) => (
                          <li {...props} key={`query_fields-` + generateId(4)}>
                            {option.name}
                          </li>
                        )}
                        onChange={(event, selectedOptions, reason, details) => {
                          onChange(selectedOptions?.query_id);
                        }}
                        renderInput={(params) => <TextField {...params} error={invalid} />}
                      />
                      <ErrorMessage hasError={invalid} error={error?.message} />
                    </>
                  )}
                />
              )}
            </Grid>
            <Grid item xs={7}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={updateAllLeads} />}
                  label={`${t('massive-update.updated-all-label')}`}
                  onChange={() => setUpdateAllLeads(!updateAllLeads)}
                  {...{[TestAttributes.BUTTON_NAME]: 'update-all-checkbox'}}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <FormProvider {...methods}>
              <Grid container item xs={12} sm={12} md={4}>
                <Grid item xs={12}>
                  <>
                    <InputLabel htmlFor="filter-field">{t('massive-update.filter')}</InputLabel>
                    <TextField
                      fullWidth
                      id="filter-field"
                      placeholder={t('query.filter')}
                      onChange={(event) => setSearchFieldQuery(event.target.value)}
                      value={searchFieldQuery}
                    />
                  </>
                  {filteredFields.length > 0 ? (
                    <VirtualFieldList
                      fields={filteredFields}
                      handleOnClick={handleOnFieldClick}
                      disabled={formState.isDirty && !formState.isValid && fields.length !== 0}
                    ></VirtualFieldList>
                  ) : (
                    <Skeleton animation="wave" width={'100%'} height={200} />
                  )}
                </Grid>
              </Grid>
            </FormProvider>

            <Grid item xs={12} sm={12} md={8}>
              <FormProvider {...methods}>
                {fields.map((fieldToUpdate: MassiveUpdateFieldToUpdate, index: number) => (
                  <MassiveUpdateFieldValue key={fieldToUpdate.id} field={fieldToUpdate} index={index}>
                    <IconButton
                      color={'secondary'}
                      aria-label="remove field"
                      component="button"
                      onClick={() => remove(index)}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </MassiveUpdateFieldValue>
                ))}
              </FormProvider>

              {fields.length === 0 && (
                <Box display={'flex'} justifyContent={'center'}>
                  <Typography variant={'h4'}>{t('massive-update.choose-field-msg')}</Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} justifyContent={'center'}>
            {loadingQueryLeads && <Skeleton animation="wave" width={'100%'} height={500} />}
            {queryLeads && queryLeads?.length > 0 && !updateAllLeads && (
              <Grid
                item
                xs={12}
                sx={{height: '500px', marginBottom: '70px'}}
                {...{[TestAttributes.TABLE_NAME]: 'table-results'}}
              >
                <DataGridCustom
                  tableColumns={tableColumns}
                  rows={
                    queryLeads?.map((item: QueryDetailLead) => {
                      return {
                        actions: item.lead_id,
                        ...item,
                        created_at: formatDateTime(item.created_at),

                        id: item.lead_id,
                      };
                    }) || []
                  }
                  userPermissions={userPermissions}
                  checkboxSelection={true}
                  onSelectionModelChange={(newSelection: Array<GridRowId>) => {
                    setSelectionModel(newSelection);
                  }}
                  selectionModel={selectionModel}
                  onPageChange={(newPage: any) => setPage(newPage)}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default MassiveUpdatePage;
