import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid, IconButton, Tooltip} from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import CallIcon from '@mui/icons-material/Call';
import TaskIcon from '@mui/icons-material/Task';
import LinkIcon from '@mui/icons-material/Link';
import {UserPermissions} from '../../../components/shared/AccessControl';
import SmsDialog from '../dialogs/SmsDialog';
import {Lead} from '../../../model/Lead';
import CallDialog from '../dialogs/CallDialog';
import EmailDialog from '../dialogs/EmailDialog';
import TasksDialog from '../dialogs/TasksDialog';
import CommentDialog from '../dialogs/CommentDialog';
import {FormAPI} from '../../../form/FormAPI';
import {TestAttributes} from '../../../TestAttributes';
import {HttpMethods} from '../../../model/HttpMethods';
import {GenerateTinyUrlResponse} from '../../../services/model/GenerateTinyUrlResponse';
import {AxiosResponse} from 'axios';
import {useAxiosContext} from '../../../context/AxiosContext';

enum LeadFormMode {
  CREATE = 'create',
  EDIT = 'edit',
}

interface LeadSpeedDialAction {
  icon: JSX.Element;
  name: string;
  onClick: () => void;
  modes: Array<LeadFormMode>;
  permission: UserPermissions;
}

interface LeadSpeedDialProps {
  lead: Lead | null;
  editMode: boolean;
  userPermissions: Array<UserPermissions>;
  formAPI: FormAPI;
}

function LeadSpeedDial({lead, editMode, userPermissions, formAPI}: LeadSpeedDialProps) {
  const {useAxiosBFF} = useAxiosContext();
  const [, getTinyUrl] = useAxiosBFF<GenerateTinyUrlResponse>(
    {
      url: 'tinyurl',
      method: HttpMethods.PATCH,
    },
    {manual: true}
  );

  function generateTinyUrl(_leadId: string): void {
    getTinyUrl({data: {lead_id: _leadId}}).then((response: AxiosResponse<GenerateTinyUrlResponse>) => {
      if (response?.data?.short_url) {
        window.open(response?.data?.short_url, '_blank');
      }
    });
  }
  const {t} = useTranslation();

  const handleComments = () => {
    setIsCommentDialogOpen(true);
  };
  const handleSms = () => {
    setIsSmsDialogOpen(true);
  };
  const handleCall = () => {
    setIsCallDialogOpen(true);
  };
  const handleEmail = () => {
    setIsEmailDialogOpen(true);
  };
  const handleTasks = () => {
    setIsTasksDialogOpen(true);
  };

  const handleLink = () => {
    generateTinyUrl(lead?.lead_id ?? '');
  };

  const actions: Array<LeadSpeedDialAction> = [
    {
      icon: <LinkIcon />,
      name: t('leads.speed-dial.tiny-url'),
      onClick: handleLink,
      modes: [LeadFormMode.EDIT],
      permission: UserPermissions.VIEW_REQUEST_TINY_URL,
    },
    {
      icon: <CommentIcon />,
      name: t('leads.speed-dial.comments'),
      onClick: handleComments,
      modes: [LeadFormMode.CREATE, LeadFormMode.EDIT],
      permission: UserPermissions.VIEW_CLICK_TO_COMMENT,
    },
    {
      icon: <EmailIcon />,
      name: t('leads.speed-dial.email'),
      onClick: handleEmail,
      modes: [LeadFormMode.EDIT],
      permission: UserPermissions.VIEW_CLICK_TO_EMAIL,
    },
    {
      icon: <SmsIcon />,
      name: t('leads.speed-dial.sms'),
      onClick: handleSms,
      modes: [LeadFormMode.EDIT],
      permission: UserPermissions.VIEW_CLICK_TO_SMS,
    },
    {
      icon: <CallIcon />,
      name: t('leads.speed-dial.call'),
      onClick: handleCall,
      modes: [LeadFormMode.EDIT],
      permission: UserPermissions.VIEW_CLICK_TO_CALL,
    },
    {
      icon: <TaskIcon />,
      name: t('leads.speed-dial.tasks'),
      onClick: handleTasks,
      modes: [LeadFormMode.EDIT],
      permission: UserPermissions.VIEW_CLICK_TO_TASK,
    },
  ];

  const [isCallDialogOpen, setIsCallDialogOpen] = useState<boolean>(false);
  const [isSmsDialogOpen, setIsSmsDialogOpen] = useState<boolean>(false);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState<boolean>(false);
  const [isTasksDialogOpen, setIsTasksDialogOpen] = useState<boolean>(false);
  const [isCommentDialogOpen, setIsCommentDialogOpen] = useState<boolean>(false);

  return (
    <>
      <Grid container sx={{paddingTop: '1.5rem'}}>
        {actions
          .filter((action: LeadSpeedDialAction) =>
            action.modes.some((mode: LeadFormMode) => {
              const formMode = editMode ? LeadFormMode.EDIT : LeadFormMode.CREATE;
              return mode === formMode;
            })
          )
          .filter((action: LeadSpeedDialAction) => userPermissions.includes(action.permission))
          .map((action: LeadSpeedDialAction) => (
            <Tooltip title={action.name} key={'tooltip-' + action.name}>
              <IconButton
                id="navbar-user-profile"
                {...{[TestAttributes.BUTTON_NAME]: 'action-btn'}}
                onClick={action.onClick}
                color="primary"
              >
                {action.icon}
              </IconButton>
            </Tooltip>
          ))}
      </Grid>

      {lead && (
        <>
          <SmsDialog isOpen={isSmsDialogOpen} handleClose={() => setIsSmsDialogOpen(false)} lead={lead} />
          <CallDialog isOpen={isCallDialogOpen} handleClose={() => setIsCallDialogOpen(false)} lead={lead} />
          <EmailDialog isOpen={isEmailDialogOpen} handleClose={() => setIsEmailDialogOpen(false)} lead={lead} />
          <TasksDialog isOpen={isTasksDialogOpen} handleClose={() => setIsTasksDialogOpen(false)} lead={lead} />
          <CommentDialog
            isOpen={isCommentDialogOpen}
            handleClose={() => setIsCommentDialogOpen(false)}
            lead={lead}
            formAPI={formAPI}
          />
        </>
      )}
      {!editMode && (
        <>
          <CommentDialog
            isOpen={isCommentDialogOpen}
            handleClose={() => setIsCommentDialogOpen(false)}
            formAPI={formAPI}
          />
        </>
      )}
    </>
  );
}

export default LeadSpeedDial;
