import React from 'react';
import ListLayoutComponent from './shared/ListLayoutComponent';
import SingleMenuItemComponent from './SingleMenuItemComponent';
import MenuItemEditorCreate from './editors/MenuItemEditorCreate';
import {MenuItemsProvider, useMenuItemsContext} from '../contexts/MenuItemsContext';

interface Props {
  featureId: string;
}

function InternalFeatureMenuItemsComponent({featureId}: Props) {
  const {menuItems, loading} = useMenuItemsContext();
  const editor = <MenuItemEditorCreate partial={{feature_id: featureId}} />;
  const children = menuItems?.map((menuItem) => (
    <SingleMenuItemComponent menuItem={menuItem} key={menuItem.menu_item_id} />
  ));
  return <ListLayoutComponent title={'Menu Items'} children={children} editor={editor} loading={loading} />;
}

export default function ListFeatureMenuItemComponent({featureId}: Props) {
  return (
    <MenuItemsProvider featureId={featureId}>
      <InternalFeatureMenuItemsComponent featureId={featureId} />
    </MenuItemsProvider>
  );
}
