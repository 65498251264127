import React from 'react';
import LinearProgress, {LinearProgressProps} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';

interface PercentageBarIndicatorProps {
  label: string;
  tooltip: string;
  linearProgressProps?: LinearProgressProps;
}

function PercentageBarIndicator({
  label,
  tooltip,
  linearProgressProps = {value: 50, sx: {width: '100%'}},
}: PercentageBarIndicatorProps) {
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'inherit'} marginBottom={'5px'}>
      <Grid container direction={'column'} marginBottom={'5px'}>
        <Typography variant="body2">{label}</Typography>
        <LinearProgress variant="determinate" {...linearProgressProps} />
      </Grid>

      <Box marginLeft={'5px'} display={'flex'} alignItems={'end'} height={'100%'}>
        <Typography variant="body2">
          {linearProgressProps?.value ? `${Math.round(linearProgressProps.value)}%` : 'N/A'}
        </Typography>
      </Box>

      <Box marginLeft={'5px'} marginBottom={'5px'} display={'flex'} alignItems={'end'} height={'100%'}>
        <Tooltip
          title={
            <>
              <Typography
                variant={'body1'}
                sx={{fontWeight: 'normal', fontSize: '15px'}}
                dangerouslySetInnerHTML={{__html: tooltip}}
              />
            </>
          }
          placement="top"
        >
          <HelpOutlineIcon sx={{fontSize: 'medium'}} />
        </Tooltip>
      </Box>
    </Box>
  );
}

export default PercentageBarIndicator;
