import {useState, useEffect} from 'react';
import {IFileWithMeta, StatusValue} from 'react-dropzone-uploader';
import {useAuth0} from '@auth0/auth0-react';
import {useTranslation} from 'react-i18next';
import {Button, Dialog, DialogActions, DialogContent} from '@mui/material';
import {ResourceTypes} from '../../../model/ResourceTypes';
import {DropzoneWrapper} from '../../../form/components/DropzoneWrapper';
import {FileExtensions} from '../../../model/FileExtensions';
import {Auth0User, getUserEmail, getUserId} from '../../../model/Auth0User';
import {FeatureName} from '../../../../paths';
import {useFiles} from '../../../UseFiles';
import {AxiosResponse} from 'axios';
import {ResponseFile} from '../../../model/Files';

interface CopyCampaignDialogProps {
  isOpen: boolean;
  handleClose: Function;
  extensions: FileExtensions[];
  filePath: string;
  setFileInformation: Function;
}

function DropzoneDialog({isOpen, handleClose, extensions, filePath, setFileInformation}: CopyCampaignDialogProps) {
  const {t} = useTranslation();
  const auth0 = useAuth0<Auth0User>();
  const [file, setFile] = useState<FormData | null>(null);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [fileInfo, setFIleInfo] = useState<File>();
  const {postFiles} = useFiles();

  useEffect(() => {
    if (file) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [file]);

  function handleOnClose() {
    setFile(null);
    handleClose();
  }
  function filesToUploadHandler(fileWithMeta: IFileWithMeta, statusValue: StatusValue, allFiles: IFileWithMeta[]) {
    if (fileWithMeta?.meta?.status === 'done') {
      let postFilesBody = new FormData();
      const newFile = new File([fileWithMeta.file], `${fileWithMeta.file.name.toLowerCase().trim()}`, {
        type: fileWithMeta.file.type,
      });
      postFilesBody.append('file1', newFile);
      postFilesBody.append('folder', filePath);
      postFilesBody.append('email', getUserEmail(auth0.user) || '');
      postFilesBody.append('user_id', getUserId(auth0.user) || '');
      postFilesBody.append('source', FeatureName.SETTINGS);

      setFile(postFilesBody);
      setFIleInfo(newFile);
    } else if (fileWithMeta?.meta?.status === 'removed') {
      setFile(null);
    }
  }
  function onUpload(): void {
    if (file) {
      postFiles(file).then((response: AxiosResponse<ResponseFile>) => {
        if (response?.status === 201) {
          setFileInformation(fileInfo);
        }
      });
    }
    handleOnClose();
  }
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={isOpen}
      onClose={() => handleOnClose()}
      aria-labelledby="upload-file-dialog-title"
    >
      <DialogContent>
        <DropzoneWrapper
          filesToShow={[]}
          maxFiles={1}
          acceptedExtensions={extensions}
          onChangeFunction={filesToUploadHandler}
          disableUploadButton
          resourceType={ResourceTypes.CAMPAIGNS}
          notSupportedFileMsg={`${t('uploader.file-not-supported')}${extensions}`}
        />
      </DialogContent>

      <DialogActions>
        <Button id={'upload-file-cancel-btn'} color="secondary" onClick={() => handleOnClose()}>
          {t('shared.cancel')}
        </Button>
        <Button id={'upload-file-btn'} disabled={disableButton} onClick={() => onUpload()}>
          {t('uploader.upload-file-btn')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DropzoneDialog;
