import {Grid, useTheme} from '@mui/material';
import Container from '@mui/material/Container';
import React, {PropsWithChildren} from 'react';
import PageHeadline from '../../components/PageHeadline';
import {useTranslation} from 'react-i18next';
import {PageStickyHeader} from '../PageStickyHeader';

interface Props {
  title: string;
}
export default function PageContainer({title, children}: PropsWithChildren<Props>) {
  const {t} = useTranslation();
  const theme = useTheme();

  return (
    <Container maxWidth={false}>
      <PageStickyHeader>
        <Grid container spacing={3} sx={{marginBottom: theme.spacing(3)}}>
          <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
            <PageHeadline>{t(title)}</PageHeadline>
          </Grid>
        </Grid>
      </PageStickyHeader>
      {children}
    </Container>
  );
}
