import React from 'react';
import ListLayoutComponent from './shared/ListLayoutComponent';
import SingleCoRuleComponent from './SingleCoRuleComponent';
import CoRuleEditorCreate from './editors/CoRuleEditorCreate';
import {CoRulesProvider, useCoRulesContext} from '../contexts/CoRulesContext';

interface Props {
  sectionId: string;
}

function InternalCoRuleComponent({sectionId}: Props) {
  const {corules, loading} = useCoRulesContext();
  const editor = <CoRuleEditorCreate partial={{resource_id: sectionId, resource_type: 'section'}} />;
  const children = corules?.map((corule) => <SingleCoRuleComponent corule={corule} key={corule.corule_id} />);
  return <ListLayoutComponent title={'Conditional Rules'} children={children} editor={editor} loading={loading} />;
}

export default function ListSectionCoRuleComponent({sectionId}: Props) {
  return (
    <CoRulesProvider resourceId={sectionId}>
      <InternalCoRuleComponent sectionId={sectionId} />
    </CoRulesProvider>
  );
}
