import React from 'react';
import SingleFieldComponent from './SingleFieldComponent';
import ListLayoutComponent from './shared/ListLayoutComponent';
import FieldEditorCreate from './editors/FieldEditorCreate';
import {useFeatureContext} from '../contexts/FeatureContext';
import {FieldsProvider, useFieldsContext} from '../contexts/FieldsContext';

interface Props {
  sectionId: string;
}

function InternalSectionFieldsComponent({sectionId}: Props) {
  const {feature} = useFeatureContext();
  const {fields, loading} = useFieldsContext();
  const editor = <FieldEditorCreate partial={{feature_id: feature.feature_id, section_id: sectionId}} />;
  const children = fields?.map((field) => <SingleFieldComponent field={field} key={field.field_id} />);
  return <ListLayoutComponent title={'Fields'} children={children} editor={editor} loading={loading} />;
}

export default function ListFieldComponent({sectionId}: Props) {
  return (
    <FieldsProvider sectionId={sectionId}>
      <InternalSectionFieldsComponent sectionId={sectionId} />
    </FieldsProvider>
  );
}
