import {PermissionFeatureProps, Resource} from '../models/models';

const isChecked = (roleId: string, actionId: string, permissions: any) => {
  const permission = permissions.find((permission: any) => permission.role_id === roleId);
  return permission?.action_ids.some((action_id: string) => action_id === actionId) ?? false;
};
export const getPermissions = (
  resources: Array<Resource>,
  resourceType: string,
  config: any
): Array<PermissionFeatureProps> => {
  return resources.map((field) => {
    return {
      resourceId: field.resource_id,
      label: field.resource_metadata_name,
      type: field.resource_metadata_type,
      roles: config
        .find((conf: any) => conf.resource_type === resourceType)
        .roles.map((role: any) => {
          return {
            roleId: role.role_id,
            label: role.role_name,
            actions: role.actions.map((action: any) => {
              return {
                label: action.action_name,
                actionId: action.action_id,
                checked: isChecked(role.role_id, action.action_id, field.permissions),
              };
            }),
          };
        }),
    };
  });
};
