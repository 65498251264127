import {ListItemBase} from './BaseItems';
interface LeadBase {
  lead_id?: string;
  first_name?: string;
  lead_type?: Array<string>;
  health_lead_status?: string;
  health_lead_status_details?: string;
}
export interface LeadListItem extends LeadBase, ListItemBase {}

export interface Lead extends LeadBase {
  birthdate?: Date;
  chat_open?: boolean;
  county?: string;
  email?: string;
  last_name?: string;
  gender?: string;
  has_attachments?: boolean;
  has_insx_quote?: boolean;
  life_tobacco_user?: boolean;
  marital_status?: string;
  number_of_dependent_children_in_house?: number;
  primary_phone?: string;
  owner_1_cell_phone?: string;
  owner_2_cell_phone?: string;
  secondary_phone?: string;
  spouse_dob?: Date;
  spouse_first_name?: string;
  spouse_gender?: string;
  spouse_last_name?: string;
  spouse_social?: string;
  state?: string;
  status?: string;
  zip_code?: string;
}
export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
}

export enum MaritalStatus {
  MARRIED = 'married',
  DIVORCED = 'divorced',
  WIDOWED = 'widowed',
  SINGLE = 'single',
}

export interface SpartanHistoryItem {
  history_id: string;
  created_at: string;
  created_by: {user_id: string; username: string};
  edit_source?: string;
  change: string;
}

export interface SpartanHistoryItemChange {
  key: string;
  before?: string;
  current?: string;
  comments?: Array<string>;
}

export interface SpartanCommunicationItem {
  created_at: string;
  campaign_type: string;
  campaign_name: string;
  direction: string;
  from_number: string;
  to_number: string;
  sms_text: string;
  sms_status: string;
  rvm_id?: string;
  ai_call_id?: string;
  status?: string;
  type: CommunicationType;
}

export enum CommunicationType {
  SMS = 'sms',
  CALL = 'call',
  RVM = 'rvm',
  AI_CALL = 'ai_call',
}

export interface CampaignLeadParams {
  campaign_id: string;
  duplicates?: boolean;
  campaign_type?: string;
  dial_attempts?: number;
}

export enum LeadListSearchSortOption {
  CREATED_AT = 'created_at',
  LAST_UPDATED_AT = 'last_updated_at',
}

export interface LeadListSearchParams {
  search?: string;
  order_by?: LeadListSearchSortOption;
  last_edited_by_me?: boolean;
  limit?: number;
}

export enum SpeedDialActions {
  POST = 'post',
  PATCH = 'patch',
}
