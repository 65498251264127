import React from 'react';
import SingleLayoutComponent from './shared/SingleLayoutComponent';
import {FormMenuItem} from '../model/FormMenuItem';
import ListMenuItemMenuItemComponent from './ListMenuItemMenuItemComponent';
import MenuItemEditorUpdate from './editors/MenuItemEditorUpdate';
import {useTranslation} from 'react-i18next';
import {useFeatureContext} from '../contexts/FeatureContext';

interface Props {
  menuItem: FormMenuItem;
}

export default function SingleMenuItemComponent({menuItem}: Props) {
  const {t} = useTranslation();
  const {feature} = useFeatureContext();
  const editor = <MenuItemEditorUpdate menuItem={menuItem} />;
  let label = menuItem.label ? t(menuItem.label) : menuItem.menu_item_id;
  if (menuItem.type !== 'item') {
    label += ` [${menuItem.type}]`;
  }
  return (
    <SingleLayoutComponent label={label} editor={editor} disabled={menuItem.disabled}>
      <ListMenuItemMenuItemComponent menuItemId={menuItem.menu_item_id} featureId={feature.feature_id} />
    </SingleLayoutComponent>
  );
}
