import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {env} from '../../env';
import {buildConfig, Config, ConfigFromBackend} from '../Config';
import {Backdrop, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import NotificationService, {NotificationType} from '../services/NotificationService';
import {BackdropCircularProgress} from '../components/shared/Loading';


export const ConfigProvider = ({children}: PropsWithChildren) => {
  const {t} = useTranslation();
  const [config, setConfig] = useState<Config>();
  const [error, setError] = useState<string>();
  const [refresh, setRefresh] = useState<number>(0);

  function refreshConfig() {
    setRefresh((prev) => prev + 1);
  }

  useEffect(() => {
    fetch(env.CONFIG_URL)
      .then((r) => r.json())
      .then((data: ConfigFromBackend) => setConfig(buildConfig(data)))
      .catch((error) => {
        NotificationService.getInstance().sendNotification(error.message, NotificationType.ERROR);
        console.error(error.message);
        setError('errors.configuration-error');
      });
  }, [refresh]);

  return config ? (
    <ConfigContext.Provider value={{config, refreshConfig}}>{children}</ConfigContext.Provider>
  ) : error ? (
    <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
      <Typography>{t(error)}</Typography>
    </Backdrop>
  ) : (
    <BackdropCircularProgress />
  );
};

interface ConfigContextInterface {
  config: Config;
  refreshConfig: () => void;
}

const ConfigContext = createContext<ConfigContextInterface | undefined>(undefined);

export function useConfigContext(): ConfigContextInterface {
  const ctx = useContext(ConfigContext);
  if (ctx === undefined) throw Error('Invalid use of useConfigContext()');
  return ctx;
}

export function useConfig(): Config {
  return useConfigContext().config;
}
