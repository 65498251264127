import {PropsWithChildren, useEffect, useState} from 'react';
import {Form} from './Form';
import {FormSchema} from './logic/FormSchema';
import {FeatureName} from '../../paths';
import {parseNextgenFormSchema} from './api/middleware/parseNextgenFormSchema';
import {useAuth0} from '@auth0/auth0-react';
import {Auth0User} from '../model/Auth0User';
import {Skeleton} from '@mui/material';
import {FormConfig} from './FormConfig';
import {FormHelper} from './FormHelper';
import {OnRejectSubmitHandler, OnSubmitHandler} from './model';
import {FormAPI} from './FormAPI';
import {DynamicHelper} from './DynamicHelper';
import FieldsApiClient from '../services/api/FieldsApiClient';
import {NextgenFormSchema} from './api/middleware/NextgenFormSchema';
import NotificationService, {NotificationType} from '../services/NotificationService';
import {useTranslation} from 'react-i18next';
import {applyTranslations} from './api/middleware/applyTranslations';
import {useAxiosContext} from '../context/AxiosContext';
import {useLoading} from '../context/LoadingContext';

const _defaultOnSubmit = async (values: any) => {
  console.debug('Submitting...');
  await setTimeout(() => {
    window.alert(JSON.stringify(values, null, 2));
  }, 300);
};

const _defaultOnRejectSubmit = async (errors: any) => {
  console.warn('Submit rejected due to errors', errors);
};

export interface FormBuilderProps {
  formId?: FeatureName;
  formSchema?: FormSchema;
  initialValues?: any;
  onSubmit?: OnSubmitHandler;
  onRejectSubmit?: OnRejectSubmitHandler;
  api?: FormAPI;
  config?: FormConfig;
  refresh?: number | string;
}

export function FormBuilder({
  formId,
  formSchema,
  initialValues,
  onSubmit: _onSubmit,
  onRejectSubmit: _onRejectSubmit,
  api,
  config,
  children,
  refresh,
}: PropsWithChildren<FormBuilderProps>) {
  const {useAxiosBFF} = useAxiosContext();
  const auth0 = useAuth0<Auth0User>();
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const onSubmit = _onSubmit || _defaultOnSubmit;
  const onRejectSubmit = _onRejectSubmit || _defaultOnRejectSubmit;
  const [nextgenSchema, setNextgenSchema] = useState<NextgenFormSchema | false>(false);
  const [schema, setFormSchema] = useState<FormSchema | false>(false);
  const [helper, setFormHelper] = useState<FormHelper | false>(false);
  const [{response: getSchemaResponse, error: getSchemaError, loading: getSchemaLoading}, getSchema] =
    useAxiosBFF<NextgenFormSchema>(
      {
        url: `/featurefields`,
        method: 'GET',
        params: {feature_name: formId},
      },
      {manual: true}
    );

  useEffect(() => {
    if (nextgenSchema) {
      let schema = parseNextgenFormSchema(nextgenSchema);
      if (formId) applyTranslations(schema, formId, t);
      setFormSchema(schema);
    }
  }, [nextgenSchema, refresh]);

  useEffect(() => {
    if (getSchemaResponse) {
      setNextgenSchema(getSchemaResponse?.data);
    }
  }, [getSchemaResponse]);

  useEffect(() => {
    setLoading(getSchemaLoading, 'FormBuilder');
  }, [getSchemaLoading]);

  useEffect(() => {
    if (getSchemaError) {
      NotificationService.getInstance().sendNotification(
        getSchemaError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [getSchemaError]);

  useEffect(() => {
    if (formSchema) {
      setFormSchema(formSchema);
    } else if (formId && formId.startsWith(FeatureName.DEMO)) {
      fetch(`/test-api/form-${formId}.json`)
        .then((r) => r.json())
        .then((schema) => setFormSchema(schema));
    } else if (formId) {
      getSchema();
    } else {
      throw new Error('Missing form schema (provide either formId or formSchema)');
    }
  }, [formId, formSchema]);

  useEffect(() => {
    if (schema) {
      // async code needs to go in a separate function block
      const initFormHelper = async () => {
        const _helper = new FormHelper({schema, initialValues, config, formId});
        const _helperResolved = await new DynamicHelper(FieldsApiClient.with(auth0)).resolveDynamicStuff(_helper);
        setFormHelper((_helperOld) => {
          // if exists, destroy old helper
          if (_helperOld) _helperOld.destroy();
          return _helperResolved;
        });
      };

      initFormHelper().catch(console.error);
    }
  }, [schema, initialValues, config]);

  return helper ? (
    <Form helper={helper} api={api} onSubmit={onSubmit} onRejectSubmit={onRejectSubmit}>
      {children}
    </Form>
  ) : (
    <Skeleton variant="rectangular" animation="wave" height={150} />
  );
}
