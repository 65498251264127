import {FormControl, FormControlLabel, Switch} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

interface ToggleSwitchProps {
  checkedStatus: boolean;
  onCheckedBehavior: () => void;
  label: string;
  tooltip: string;
}

export default function ToggleSwitch({checkedStatus, onCheckedBehavior, label, tooltip}: ToggleSwitchProps) {
  return (
    <Grid container item xs={12} alignItems={'center'} spacing={1}>
      <Grid item xs={8} sm={6} md={10}>
        <FormControl sx={{maxWidth: '100%'}}>
          <FormControlLabel
            control={
              <Switch id="toggle-switch-btn" color="primary" checked={checkedStatus} onChange={onCheckedBehavior} />
            }
            label={label}
            labelPlacement="start"
          />
        </FormControl>
      </Grid>
      <Grid item xs={4} sm={6} md={2}>
        <Tooltip
          title={
            <>
              <Typography
                variant={'body1'}
                sx={{fontWeight: 'normal', fontSize: '15px'}}
                dangerouslySetInnerHTML={{__html: tooltip}}
              />
            </>
          }
          placement="top"
        >
          <HelpOutlineIcon sx={{fontSize: 'large', maxWidth: '100%'}} />
        </Tooltip>
      </Grid>
    </Grid>
  );
}
