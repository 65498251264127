import {mergeMap, Observable, Subject} from 'rxjs';
import {env} from '../../env';
import Auth0ContextHolder from './Auth0ContextHolder';

export interface WebSocketMessageData {
  [key: string]: any;
}

export class WebSocketService {
  private static readonly INSTANCE = new WebSocketService();

  private messages$ = new Subject<WebSocketMessageData>();
  private socket: WebSocket | undefined;
  private readonly url: string;
  private atLeastOneMessageReceivedOnCurrentConnection = false;

  constructor() {
    const baseUrl = env.BASE_URL;
    const regex = RegExp(/^(https?|wss?):\/\/(.*)/);
    const results = regex.exec(baseUrl) ?? [];
    if (results.length > 2) {
      const domain = results[2];
      this.url = `wss://${domain}/ws`;
    } else {
      this.url = `wss://${window.location.host}/ws`;
    }
    // expose WebSocketService to console for debug
    window['__WS__'] = this;
  }

  static getInstance(): WebSocketService {
    return WebSocketService.INSTANCE;
  }

  listen(): Observable<WebSocketMessageData> {
    this.connect();
    return this.messages$.asObservable();
  }

  send(data: any, stringifyAsJson = false): void {
    if (!this.isConnected() || !this.socket) {
      console.warn('WebSocket is not connected');
    } else {
      const message = stringifyAsJson ? JSON.stringify(data) : data;
      this.socket.send(message);
    }
  }

  connect(): void {
    if (this.isConnected()) {
      console.debug('WebSocket already connected to', this.url);
      return;
    }
    Auth0ContextHolder.getInstance()
      .getWhenAuthenticated()
      .pipe(mergeMap((auth0) => auth0.getAccessTokenSilently()))
      .subscribe((token) => this._connect(token));
  }

  isConnected(): boolean {
    return this.socket?.readyState === WebSocket.OPEN;
  }

  private _connect(token: string) {
    console.debug('WebSocket connecting to', this.url);
    const authUrl = `${this.url}?token=${token}`;
    this.socket = new WebSocket(authUrl);
    this.socket.onerror = (event) => this._onError(event);
    this.socket.onclose = (event) => this._onClose(event);
    this.socket.onopen = (event) => this._onOpen(event);
    this.socket.onmessage = (messageEvent) => this._onMessage(messageEvent);
  }

  private _onMessage(event: MessageEvent) {
    console.debug(`WebSocket Event`, event.type, event);

    if (!this.atLeastOneMessageReceivedOnCurrentConnection) {
      this.atLeastOneMessageReceivedOnCurrentConnection = true;
      this.resetBackoffInterval();
      console.debug('WebSocket connected to', this.url);
    }

    // ignore ping/pong and welcome messages
    if (event.data === 'pong' || event.data === 'Welcome!') {
      return;
    }

    try {
      const parsedData = JSON.parse(event.data);
      this.messages$.next(parsedData);
    } catch (e) {
      console.warn('WebSocket malformed message data (JSON expected)', event.data);
    }
  }

  private _onOpen(event: Event) {
    console.debug(`WebSocket Event`, event.type, event);
  }

  private _onError(event: Event) {
    console.debug(`WebSocket Event`, event.type, event);
    console.error(`WebSocket error`);
    // No need to handle re-connection on error
  }

  private _onClose(event: CloseEvent) {
    console.debug(`WebSocket Event`, event.type, event);
    this.atLeastOneMessageReceivedOnCurrentConnection = false;
    const interval = this.calcNextBackoffInterval();
    console.warn(
      `WebSocket closed with code`,
      event.code,
      `(see https://www.rfc-editor.org/rfc/rfc6455#section-7.4.1).`,
      `Reconnecting in ${Math.ceil(interval / 1000)} second(s) ...`
    );
    setTimeout(() => this.connect(), interval);
  }

  // backoff interval
  private readonly RECONNECT_INTERVALS = [1, 2, 5, 10, 20];
  private lastIntervalIndex = -1;

  private resetBackoffInterval(): void {
    this.lastIntervalIndex = -1;
  }

  private calcNextBackoffInterval(): number {
    this.lastIntervalIndex = Math.min(this.RECONNECT_INTERVALS.length - 1, this.lastIntervalIndex + 1);
    return this.RECONNECT_INTERVALS[this.lastIntervalIndex] * 1000;
  }
}
