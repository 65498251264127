import {Box, Grid} from '@mui/material';
import Container from '@mui/material/Container';
import React from 'react';
import PageHeadline from '../../components/PageHeadline';
import QuerySummary from './components/QuerySummary';

function QueryVisualisationPage() {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <PageHeadline>Query Visualization</PageHeadline>
          </Grid>
          <QuerySummary></QuerySummary>
        </Grid>
      </Container>
    </Box>
  );
}
export default QueryVisualisationPage;
