import {useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';
import {Box, Grid, Container, Typography, Card, CardContent} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {PageStickyHeader} from '../PageStickyHeader';
import PageHeadline from '../../components/PageHeadline';
import {FeatureName} from '../../../paths';
import {Auth0User, getUserOrganizationRoles} from '../../model/Auth0User';
import {OrganizationRoleName} from '../../model/Organization';
import {Path} from '../../../paths';
import UserRoleService from '../../services/UserRoleService';
import {DropzoneWrapper} from '../../form/components/DropzoneWrapper';
import {FileExtensions} from '../../model/FileExtensions';
import {HttpMethods} from '../../model/HttpMethods';
import {ResourceTypes} from '../../model/ResourceTypes';
import DropzoneWrapperConfirmationDialog from '../../form/components/DropzoneWrapperConfirmationDialog';
import {IFileWithMeta, StatusValue} from 'react-dropzone-uploader';
import {AxiosResponse} from 'axios';
import {PaymentInfo, PaymentResponse} from '../../model/Payment';
import {useAxiosContext} from '../../context/AxiosContext';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {useLoading} from '../../context/LoadingContext';
import {useFiles} from '../../UseFiles';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {UsePermissionState, usePermissions} from '../UsePermissions';
import PaymentInformationSettings from './PaymentInformationSettings';
import PaymentInformationExport from './PaymentInformationExport';
function PaymentClearancePage() {
  const {t} = useTranslation();
  const auth0 = useAuth0<Auth0User>();
  const {useAxiosBFF} = useAxiosContext();
  const {setLoading} = useLoading();
  const {user} = auth0;
  const navigate = useNavigate();
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.PAYMENT_CLEARANCE);
  const [selectedRoleName, setSelectedRoleName] = useState<string | null>();
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>();
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [file, setFile] = useState<FormData | null>(null);
  const [resetDropzoneState, setResetDropzoneState] = useState<boolean>();
  const [fieldsToBeClear, setFieldsToBeClear] = useState<Array<string>>([]);
  const [paymentPermissions, setPaymentPermissions] = useState<PaymentInfo>();
  const {postFilesAPIv2} = useFiles();

  const [{error: hasFieldsToBeClearError, loading: isFieldsToBeClearLoading}, getFieldsToBeClear] =
    useAxiosBFF<PaymentResponse>(
      {
        method: HttpMethods.GET,
      },
      {manual: true}
    );

  useEffect(() => {
    const error = hasFieldsToBeClearError;
    if (error) {
      NotificationService.getInstance().sendNotification(error?.response?.data?.message, NotificationType.ERROR);
    }
  }, [hasFieldsToBeClearError]);

  useEffect(() => {
    const loading = isFieldsToBeClearLoading;
    setLoading(loading, 'PaymentClearance');
  }, [isFieldsToBeClearLoading]);

  useEffect(() => {
    const sub = UserRoleService.getInstance()
      .getSelectedRole()
      .subscribe((role) => setSelectedRoleId(role));
    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    getFieldsToBeClear({url: `${FeatureName.LEADS}/payment-clearance/settings`}).then(
      (response: AxiosResponse<PaymentResponse>) => {
        setFieldsToBeClear(response.data.fields);
        setPaymentPermissions(response.data.payment_permission);
      }
    );
  }, []);

  useEffect(() => {
    if (user && selectedRoleId) {
      const role = getUserOrganizationRoles(user).find((role: any) => role.role_id === selectedRoleId);
      setSelectedRoleName(role?.role_name);
    }
  }, [selectedRoleId, user]);

  useEffect(() => {
    if (selectedRoleName) {
      const isAdmin = selectedRoleName === OrganizationRoleName.ADMIN;
      if (!isAdmin) {
        navigate('/' + Path.DASHBOARD);
      }
    }
  }, [selectedRoleName]);

  function filesToUploadHandler(fileWithMeta: IFileWithMeta, statusValue: StatusValue, allFiles: IFileWithMeta[]) {
    if (fileWithMeta?.meta?.status === 'done') {
      let postFilesBody = new FormData();
      postFilesBody.append('file', fileWithMeta.file);
      setFile(postFilesBody);
    } else if (fileWithMeta?.meta?.status === 'removed') {
      setFile(null);
    }
  }

  function handleUpload() {
    if (file) {
      postFilesAPIv2(file, `/api/v1/${FeatureName.LEADS}/payment-clearance/clear`).then(() => {
        setFile(null);
        setResetDropzoneState(true);
      });
    }
  }
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('payment-clearance.headline')}</PageHeadline>
              </Grid>
            </Grid>
          </PageStickyHeader>
          <Grid
            container
            item
            xs={12}
            spacing={3}
            sx={{
              marginBottom: (theme) => theme.spacing(6),
            }}
          >
            <Grid item xs={12} md={9}>
              <Typography>{t('payment-clearance.payment-header-1')}</Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography>{t('payment-clearance.payment-header-2')}</Typography>
            </Grid>
            <AccessControl
              userPermissions={userPermissions}
              allowedPermissions={[UserPermissions.VIEW_EXPORT_PAYMENT_INFORMATION]}
            >
              <PaymentInformationExport />
            </AccessControl>
            <AccessControl
              userPermissions={userPermissions}
              allowedPermissions={[UserPermissions.VIEW_SETTINGS_PAYMENT]}
            >
              <PaymentInformationSettings paymentPermissions={paymentPermissions} />
            </AccessControl>
            <AccessControl
              userPermissions={userPermissions}
              allowedPermissions={[UserPermissions.CLEAR_LEADS_PAYMENT_INFO_FROM_FILE]}
            >
              <Grid item xs={12}>
                <Typography variant="h2" id="clear-process">
                  {t('payment-clearance.payment-process')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid>
                  <Typography>
                    {t('payment-clearance.payment-process-info-1')}
                    <b>{t('payment-clearance.payment-process-info-2')}</b>
                    {t('payment-clearance.payment-process-info-3')}
                    <br /> <br />
                    {`(${fieldsToBeClear?.toString()?.replaceAll(',', ', ')}).`}
                    <br /> <br />
                    <b>{t('payment-clearance.payment-process-info-4')}</b>
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    marginTop: (theme) => theme.spacing(6),
                  }}
                >
                  <Card>
                    <CardContent>
                      <DropzoneWrapper
                        acceptedExtensions={[FileExtensions.TSV, FileExtensions.CSV]}
                        maxFiles={1}
                        method={HttpMethods.POST}
                        resourceType={ResourceTypes.PAYMENT_CLEARANCE}
                        handleOpenConfirmationDialog={() => setIsOpenDialog(true)}
                        isConfirmationNeeded={true}
                        onChangeFunction={filesToUploadHandler}
                        notSupportedFileMsg={'This file is not supported, please upload a TSV or CSV file'}
                        resetDropzone={resetDropzoneState}
                        resetDropzoneFunction={setResetDropzoneState}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid>
                  <DropzoneWrapperConfirmationDialog
                    isDialogOpen={isOpenDialog}
                    handleClose={() => setIsOpenDialog(false)}
                    handleConfirm={handleUpload}
                    confirmationTitle={t('payment-clearance.confirmation-title') ?? ''}
                    confirmationBody={t('payment-clearance.confirmation-body') ?? ''}
                  />
                </Grid>
              </Grid>
            </AccessControl>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default PaymentClearancePage;
