import React, {PropsWithChildren, useEffect, useState} from 'react';
import {FormFieldSchema, FormSectionSchema, FormSectionType} from './logic/FormSchema';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {FormField} from './FormField';
import {Box, Grid, Typography} from '@mui/material';
import {extractSectionState, FormState} from './state/FormState';
import {WithFormDrillDownProps} from './model';
import {TestAttributes} from '../TestAttributes';
import {useSearchParams} from 'react-router-dom';

interface TabPanelProps {
  dir?: string;
  tab: any;
  currentTab: any;
}

function TabPanel(props: PropsWithChildren<TabPanelProps>) {
  const {children, currentTab, tab, ...other} = props;
  return (
    <div
      role="tabpanel"
      hidden={currentTab !== tab}
      id={`full-width-tabpanel-${tab}`}
      aria-labelledby={`full-width-tab-${tab}`}
      {...other}
    >
      {currentTab === tab && <Box p={3}>{children}</Box>}
    </div>
  );
}

function toSectionTestId(section: FormSectionSchema) {
  return (section.section_name || section.section_id).replace(/\s+/g, '-').toLowerCase();
}

function BasicSection(props: FormSectionProps) {
  const [searchParams] = useSearchParams();
  const {section, drillDownProps, nextSection} = props;
  const anchor = section.section_name?.replace(/\s+/g, '-').toLowerCase();
  const [isEditorModalOpen, setEditorModalOpen] = useState<boolean>(false);

  return (
    <Grid
      container
      spacing={section?.section_label && section?.section_label?.length > 1 ? 3 : 0}
      sx={{
        marginBottom:
          nextSection?.section_label && nextSection?.section_label?.length > 1
            ? (theme) => theme.spacing(6)
            : (theme) => theme.spacing(2),
      }}
      {...{[TestAttributes.SECTION_NAME]: toSectionTestId(section)}}
    >
      <Grid item xs={12}>
        <Typography variant="h2" id={anchor}>
          {section.section_label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {section.fields?.map((field: FormFieldSchema, index: number) => (
            <FormField key={index + '-' + field.field_id} field={field} drillDownProps={drillDownProps} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

function isSectionHidden(state: FormState, sectionId: string): boolean {
  return extractSectionState(state, sectionId).hidden || false;
}

function TabContainerSection(props: FormSectionProps) {
  const {
    section,
    drillDownProps: {state},
  } = props;

  // const tabSections = section.sections?.filter((s) => !isSectionHidden(state, s.section_id)) || [];

  const [tabSections, setTabSections] = useState<FormSectionSchema[]>([]);
  const [currentTab, setCurrentTab] = useState<string | false>(false);

  useEffect(() => {
    const _tabSections = section.sections?.filter((s) => !isSectionHidden(state, s.section_id)) || [];
    const _currentTab = _tabSections.length
      ? (_tabSections.find((s) => s.section_id === currentTab) || _tabSections[0]).section_id
      : false;
    setTabSections(_tabSections);
    setCurrentTab(_currentTab);
  }, [section, state]);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: any) => {
    setCurrentTab(newValue);
  };

  return (
    <section>
      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
      >
        {tabSections.map((section: FormSectionSchema, i: number) => (
          <Tab
            {...{[TestAttributes.TAB_NAME]: toSectionTestId(section)}}
            value={section.section_id}
            label={section.section_label}
            key={`tab-button-${section.section_id}-${i}`}
            id={section.section_name}
          />
        ))}
      </Tabs>

      {tabSections.map((section: FormSectionSchema, i: number) => (
        <TabPanel
          {...{[TestAttributes.TAB_NAME]: toSectionTestId(section)}}
          key={`tab-panel-${section.section_id}-${i}`}
          currentTab={currentTab}
          tab={section.section_id}
        >
          <FormSection {...props} section={section} />
        </TabPanel>
      ))}
    </section>
  );
}

function toTabContent(props: FormSectionProps) {
  const {section} = props;
  if (section.sections && section.sections?.length > 0) {
    return section.sections?.map((tabSection, index, elements) => (
      <FormSection key={index} {...props} section={tabSection} nextSection={elements[index + 1]} />
    ));
  } else {
    return <BasicSection {...props} />;
  }
}

function toSection(props: FormSectionProps) {
  const {section} = props;
  let sectionTemplate;

  switch (section.section_type) {
    case FormSectionType.COLLAPSABLE: // ATM no special behaviour for collapsable sections
    case FormSectionType.BASIC:
      sectionTemplate = <BasicSection {...props} />;
      break;
    case FormSectionType.TAB_CONTAINER:
      sectionTemplate = <TabContainerSection {...props} />;
      break;
    case FormSectionType.TAB:
      sectionTemplate = toTabContent(props);
      break;
  }
  return sectionTemplate;
}

export interface FormSectionProps extends WithFormDrillDownProps {
  section: FormSectionSchema;
  nextSection: FormSectionSchema;
}

export function FormSection(props: FormSectionProps) {
  const {
    section,
    drillDownProps: {state},
  } = props;
  const hidden = extractSectionState(state, section.section_id).hidden;
  return <>{hidden || toSection(props)}</>;
}
