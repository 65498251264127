import {createContext, PropsWithChildren, useContext, useState} from 'react';

const Context = createContext<ImportContext | null>(null);

interface Props extends PropsWithChildren {}

export const ImportProvider = ({children}: Props) => {
  const [completed, setCompleted] = useState(0);

  function onCompleted() {
    setCompleted((prev) => prev + 1);
  }

  return (
    <Context.Provider
      value={{
        completed,
        onCompleted,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export interface ImportContext {
  completed: number;
  onCompleted: () => void;
}

export function useImportContext(): ImportContext {
  const value = useContext(Context);
  if (!value) throw Error('invalid context');
  return value;
}
