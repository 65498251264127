import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Box, Button, Container, Grid} from '@mui/material';
import PageHeadline from '../../components/PageHeadline';
import {PageTopActions} from '../PageTopActions';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName} from '../../../paths';
import {UserListItem} from '../../model/User';
import {PageStickyHeader} from '../PageStickyHeader';
import {useTableColumns} from '../UseTableColumns';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import DataGridCustom from '../../components/DataGridCustom';
import {TestAttributes} from '../../TestAttributes';
import {Skeleton} from '@mui/lab';
import SpartanSnackbar from '../../components/SpartanSnackbar';
import {NotificationType} from '../../services/NotificationService';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

function UserListPage() {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const {setLoading} = useLoading();
  const [users, setUsers] = useState<Array<UserListItem> | null>(null);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.USERS);
  const featureName = FeatureName.USERS;

  const [{data: usersList, loading: isEntityListLoading, error: hasUsersListError}] = useAxiosBFF<
    ResponseListWrapper<UserListItem>
  >(
    {url: `/${FeatureName.USERS}`, params: {formatted: true}},
    {
      manual: false,
      useCache: false,
    }
  );

  const tableColumns = useTableColumns({
    featureName,
    defaultActionColumn: true,
  });

  useEffect(() => {
    if (usersList) {
      setUsers(usersList.results);
    }
  }, [usersList]);

  useEffect(() => {
    setLoading(isEntityListLoading, 'UserPageList');
  }, [isEntityListLoading]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {hasUsersListError && (
            <SpartanSnackbar type={NotificationType.ERROR} message={hasUsersListError.message}></SpartanSnackbar>
          )}
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('users.headline')}</PageHeadline>
              </Grid>
              <Grid item xs={12} md={6}>
                <PageTopActions>
                  <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.CREATE]}>
                    <Link to={`/${FeatureName.USERS}/create`}>
                      <Button fullWidth color="secondary" id={'add-btn'} {...{[TestAttributes.BUTTON_NAME]: 'add-btn'}}>
                        {t('globalActions.addButton')}
                      </Button>
                    </Link>
                  </AccessControl>
                </PageTopActions>
              </Grid>
            </Grid>
          </PageStickyHeader>

          <Grid item xs={12} sx={{height: '500px'}} {...{[TestAttributes.TABLE_NAME]: 'table-results'}}>
            {isEntityListLoading ? (
              <Skeleton variant="rectangular" height={500} />
            ) : (
              <DataGridCustom
                tableColumns={tableColumns}
                rows={
                  users?.map((item: UserListItem, index: number) => {
                    return {
                      ...item,
                      actions: item.user_id,
                      id: index,
                    };
                  }) || []
                }
                userPermissions={userPermissions}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default UserListPage;
