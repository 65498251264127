import * as React from 'react';
import uuid from 'react-uuid';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Button, Grid, IconButton, styled, Typography} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import UserGuideEditorStepOption from './UserGuideEditorStepOption';
import ControlledInput from '../../shared/ControlledInput';
import {FormFieldType} from '../../../form/logic/FormSchema';
import {UserGuideControls} from './UserGuideConsts';
import UserGuideAction from './UserGuideAction';

import {TestAttributes} from '../../../TestAttributes';
import {UserGuideStep} from '../model/UserGuideStep';
import {UserGuideStepOption} from '../model/UserGuideStepOption';
import NotificationService, {NotificationType} from '../../../services/NotificationService';

const StepWrapper = styled('div')(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '5px',
  padding: '12px',
  marginBottom: '16px',
}));

export default function UserGuideEditorStep() {
  const {t} = useTranslation();
  const {control, getValues} = useFormContext();

  const {fields, append, remove, swap} = useFieldArray({
    control,
    name: UserGuideControls.FIELD_ARRAY_STEPS,
  });

  function removeStep(index: number): void {
    const steps: Array<UserGuideStep> = getValues(UserGuideControls.FIELD_ARRAY_STEPS);
    const stepReferenceFounded = steps
      .map((step: UserGuideStep) => step?.options?.map((option: UserGuideStepOption) => option.target))
      .reduce((acc, val) => acc?.concat(val ?? ''), [])
      ?.some((target: string) => target === steps[index].identifier?.toString());

    if (!stepReferenceFounded || steps.length === 1) {
      remove(index);
    } else {
      NotificationService.getInstance().sendNotification(
        t('user-guide.editor.step.delete-error-msg'),
        NotificationType.ERROR
      );
    }
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={'h2'}> {t('user-guide.editor.steps')}</Typography>
      </Grid>
      <Grid item xs={12}>
        {fields.map((currentField, index) => (
          <StepWrapper key={currentField.id} className={'call-script-step-wrapper'}>
            <Grid container item spacing={3}>
              <Grid item xs={6}>
                <ControlledInput
                  id={`step-${index}-name`}
                  name={`${UserGuideControls.FIELD_ARRAY_STEPS}.${index}.name`}
                  label={t('user-guide.editor.step.name')}
                  isRequired={true}
                  toolTipText={t('user-guide.editor.step.name-tooltip')}
                ></ControlledInput>
              </Grid>
              <Grid item xs={12}>
                <ControlledInput
                  id={`step-${index}-content`}
                  type={FormFieldType.RICH_TEXT_EDITOR}
                  name={`${UserGuideControls.FIELD_ARRAY_STEPS}.${index}.content`}
                  label={t('user-guide.editor.step.content')}
                  isRequired={true}
                  toolTipText={t('user-guide.editor.step.content-tooltip')}
                ></ControlledInput>
              </Grid>
              <Grid item xs={12}>
                <ControlledInput
                  id={`step-${index}-hint`}
                  name={`${UserGuideControls.FIELD_ARRAY_STEPS}.${index}.hint`}
                  label={t('user-guide.editor.step.hint')}
                  toolTipText={t('user-guide.editor.step.hint-tooltip')}
                ></ControlledInput>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'h4'}>{t('user-guide.editor.step.options')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <UserGuideEditorStepOption stepIndex={index}></UserGuideEditorStepOption>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'h4'}>{t('user-guide.editor.action.title')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <UserGuideAction stepIndex={index}></UserGuideAction>
              </Grid>
              {/*<Grid item xs={12}>*/}
              {/*  <ConditionOrBool*/}
              {/*    name={`${UserGuideControls.FIELD_ARRAY_STEPS}.${index}.hidden`}*/}
              {/*    label={'Hidden'}*/}
              {/*  ></ConditionOrBool>*/}
              {/*</Grid>*/}

              <Grid item xs={12} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                <Grid item>
                  <Button id={'delete-step-btn'} color={'error'} onClick={() => removeStep(index)}>
                    {t('user-guide.editor.delete')}
                  </Button>
                </Grid>
                <Grid item>
                  {index !== 0 && (
                    <IconButton
                      {...{[TestAttributes.BUTTON_NAME]: `swap-up-step-${index}-btn`}}
                      id={`swap-up-step-${index}-btn`}
                      color={'secondary'}
                      aria-label="swap up step"
                      component="button"
                      onClick={() => swap(index, index - 1)}
                      size="large"
                    >
                      <ArrowUpwardRoundedIcon />
                    </IconButton>
                  )}
                  {index !== fields.length - 1 && (
                    <IconButton
                      {...{[TestAttributes.BUTTON_NAME]: `swap-down-step-${index}-btn`}}
                      id={`swap-down-step-${index}-btn`}
                      color={'secondary'}
                      aria-label="swap down step"
                      component="button"
                      onClick={() => swap(index, index + 1)}
                      size="large"
                    >
                      <ArrowDownwardRoundedIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </StepWrapper>
        ))}
      </Grid>

      <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
        <Button
          id={'add-step-btn'}
          color={'success'}
          startIcon={<AddBoxIcon />}
          onClick={() =>
            append({
              identifier: uuid(),
              name: t('user-guide.editor.step.name'),
              content: t('user-guide.editor.steps-content'),
              hint: t('user-guide.editor.step.optional-hint'),
              options: [
                {
                  name: t('user-guide.editor.step.button-text'),
                  color: 'primary',
                  target: '0',
                },
              ],
            })
          }
        >
          {t('user-guide.editor.add')}
        </Button>
      </Grid>
    </Grid>
  );
}
