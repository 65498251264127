import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {Dialog, DialogTitle, IconButton, DialogContent, Button, DialogActions} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {FormBuilder} from '../../../form/FormBuilder';
import {FeatureName} from '../../../../paths';
import LeadService from '../../../services/LeadService';
import NotificationService, {NotificationType} from '../../../services/NotificationService';
import {Lead} from '../../../model/Lead';
import {FormAPI} from '../../../form/FormAPI';
import {useLoading} from '../../../context/LoadingContext';
import {TestAttributes} from '../../../TestAttributes';

interface CommentDialogProps {
  isOpen: boolean;
  handleClose: Function;
  lead?: Lead;
  formAPI: FormAPI;
}

function CommentDialog({isOpen, handleClose, lead, formAPI}: CommentDialogProps) {
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const submitEl = useRef<HTMLButtonElement>(null);
  const location: any = useLocation();
  const isCampaignViewComment =
    location.pathname.includes('/campaigns/view/') && !location.pathname.includes('call_now_');
  const [isSave, setIsSave] = useState<boolean>(false);
  const [isSaveNext, setIsSaveNext] = useState<boolean>(false);

  function onSubmit(data: any) {
    const type = data['comments_type'];
    const text = data['comments_text'];
    const comments = LeadService.getInstance().getUnsavedCommentsValue();
    const id = type + text;
    const isCommentAdded = comments.some((comment) => comment.comment_id === id);
    if (!isCommentAdded) {
      LeadService.getInstance().addUnsavedComments({
        section: type,
        comment: text,
        comment_id: id,
      });
      if (isSave) {
        setIsSave(false);
        LeadService.getInstance().setSaveAndNext(false);
        formAPI.submit();
      } else if (isSaveNext) {
        setIsSaveNext(false);
        LeadService.getInstance().setSaveAndNext(true);
        formAPI.submit();
      } else {
        NotificationService.getInstance().sendNotification(t('leads.send-unsaved-message'), NotificationType.SUCCESS);
      }
    }
    handleClose();
  }

  useEffect(() => {
    setLoading(isOpen, 'CommentDialog');
  }, [isOpen]);

  useEffect(() => {
    if (isSave || isSaveNext) {
      submitEl?.current?.click();
      setIsSaveNext(false);
      setIsSave(false);
    }
  }, [isSave, isSaveNext]);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={isOpen}
        onClose={() => handleClose()}
        aria-labelledby="sms-dialog-title"
      >
        <DialogTitle id="sms-dialog-title">
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: 'absolute',
              right: 1,
              top: 1,
              color: (theme) => theme.palette.grey[500],
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{marginLeft: '3rem', marginRight: '3rem'}}>
          <FormBuilder onSubmit={onSubmit} formId={FeatureName.COMMENTS} initialValues={lead}>
            <Button ref={submitEl} type="submit" sx={{display: 'none'}}>
              {t('leads.dialogs.add-comments')}
            </Button>
          </FormBuilder>
        </DialogContent>
        <DialogActions>
          {isCampaignViewComment ? (
            <>
              <Button
                sx={{margin: '0.5rem'}}
                color="secondary"
                onClick={() => {
                  submitEl?.current?.click();
                }}
                id={'open-delete-payment-dialog-btn'}
                {...{[TestAttributes.BUTTON_NAME]: 'comment-btn'}}
              >
                {t('leads.dialogs.add-comments')}
              </Button>

              <Button
                sx={{margin: '0.5rem'}}
                color="secondary"
                onClick={() => setIsSaveNext(true)}
                id={'open-delete-payment-dialog-btn'}
                {...{[TestAttributes.BUTTON_NAME]: 'comment-next-btn'}}
              >
                {t('leads.save-next')}
              </Button>
              <Button
                sx={{margin: '1rem 4rem'}}
                color="primary"
                onClick={() => setIsSave(true)}
                id={'open-delete-payment-dialog-btn'}
                {...{[TestAttributes.BUTTON_NAME]: 'comment-save-btn'}}
              >
                {lead?.lead_id ? t('shared.update') : t('shared.save')}
              </Button>
            </>
          ) : (
            <Button
              color="primary"
              id={'comment-btn'}
              sx={{margin: '1rem 4rem'}}
              onClick={() => {
                submitEl?.current?.click();
              }}
            >
              {t('leads.dialogs.add-comments')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CommentDialog;
