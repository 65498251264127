import {InputLabel, TextField} from '@mui/material';
import React from 'react';
import {buildSetValue, propsWithTestAttributes, SpartanInputFieldProps, wrapInTooltipIfNecessary} from './model';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DateTimePicker} from '@mui/x-date-pickers-pro';
import {ErrorMessage} from './components/ErrorMessage';
import {SpartanLabelWithTooltip} from './SpartanLabelWithTooltip';
import {useTranslation} from 'react-i18next';
import {enUS, es} from 'date-fns/locale';
import {formatDateTimeWithTz, parseDateTimeWithTz} from '../../utils/DateWithTzUtils';

export interface SpartanDateTimeWithTzFieldProps extends SpartanInputFieldProps {
  minDateTime?: any;
  maxDateTime?: any;
}

function toValue(pickerValue: any) {
  if (pickerValue) {
    const year = parseDateTimeWithTz(pickerValue).getFullYear();
    if (isNaN(year) || year < 1000) {
      return 'Invalid Date'; // same output of formatDateTime() when input is not a valid date
    }
    return formatDateTimeWithTz(pickerValue);
  }
  return null;
}

export function SpartanDateTimeWithTzField(_props: SpartanDateTimeWithTzFieldProps) {
  const {i18n} = useTranslation();

  const props = propsWithTestAttributes(_props);
  const {
    id,
    name,
    value,
    label,
    disabled,
    onBlur,
    onFocus,
    hasError,
    error,
    inputRef,
    inputProps,
    minDateTime,
    maxDateTime,
  } = props;
  const setValue = buildSetValue(props);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n?.language === 'es' ? es : enUS}>
      <DateTimePicker
        value={value ? parseDateTimeWithTz(value) : null}
        disableIgnoringDatePartForTimeValidation={true}
        minDate={minDateTime}
        maxDate={maxDateTime}
        minTime={minDateTime}
        maxTime={maxDateTime}
        disabled={disabled}
        onChange={(date: any) => setValue(toValue(date))}
        inputRef={inputRef}
        renderInput={(params: any) => {
          const {
            error: errorToIgnore,
            helperText: helperTextToIgnore,
            label: labelToIgnore,
            inputProps: inputPropsParam,
            ...restOfParams
          } = params;
          const _inputProps = {...inputPropsParam, ...inputProps};
          return (
            <>
              <InputLabel error={hasError} htmlFor={id}>
                <SpartanLabelWithTooltip label={label} />
              </InputLabel>
              {wrapInTooltipIfNecessary(
                props,
                <TextField
                  fullWidth
                  id={id}
                  name={name}
                  {...restOfParams}
                  error={hasError}
                  inputProps={_inputProps}
                  onBlur={onBlur}
                  onFocus={onFocus}
                />
              )}
              <ErrorMessage hasError={hasError} error={error} />
            </>
          );
        }}
      />
    </LocalizationProvider>
  );
}
