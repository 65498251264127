import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, CircularProgress, Grid} from '@mui/material';
import {FormBuilder} from '../../../../../form/FormBuilder';
import {onRejectSubmit} from '../../../../../form/errorHandler';
import schema from './schema/field_update.json';
import {FormSchema} from '../../../../../form/logic/FormSchema';
import {FormField} from '../../model/FormField';
import {useNotifications} from '../../hooks/useNodifications';
import {FormState} from '../../../../../form/state/FormState';
import ExpandableWrapper from '../shared/ExpandableWrapper';
import ResourcePermissionsComponent from '../ResourcePermissionsComponent';
import {useFieldsContext} from '../../contexts/FieldsContext';
import {difference} from '../../../../../form/utils';

interface Props {
  field: FormField;
}

export default function FieldEditorUpdate({field}: Props) {
  const {t} = useTranslation();
  const {sendSuccess} = useNotifications();
  const {handlePatch, handleDelete, handleExport, handleCopy, locked} = useFieldsContext();

  function onSubmit(formData: any, state: FormState) {
    handlePatch(field.field_id, difference(formData, field)).then(() => sendSuccess(`Field updated`));
  }

  function onDelete() {
    handleDelete(field.field_id).then(() => sendSuccess(`Field deleted`));
  }

  function onExport() {
    handleExport(field.field_id).then(() => sendSuccess(`Export completed`));
  }

  function onCopy() {
    handleCopy(field.field_id).then(() => sendSuccess(`Copy completed`));
  }

  return (
    <Grid container spacing={1} rowSpacing={5}>
      <Grid item xs={12}>
        <FormBuilder
          onRejectSubmit={onRejectSubmit}
          formSchema={schema as FormSchema}
          initialValues={field}
          onSubmit={onSubmit}
        >
          <Button disabled={locked} type={'submit'}>
            {t('shared.update')}
          </Button>
          <Button disabled={locked} onClick={onDelete} color={'error'}>
            {t('shared.delete')}
          </Button>
          <Button disabled={locked} onClick={onExport} color={'secondary'}>
            {t('shared.export')}
          </Button>
          <Button disabled={locked} onClick={onCopy} color={'secondary'}>
            {t('shared.copy')}
          </Button>
          {locked && <CircularProgress size={30} sx={{marginLeft: 1}} />}
        </FormBuilder>
      </Grid>
      <Grid item xs={12}>
        <ExpandableWrapper label={'Permissions'}>
          <ResourcePermissionsComponent resourceId={field.field_id} />
        </ExpandableWrapper>
      </Grid>
    </Grid>
  );
}
