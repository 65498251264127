import {OrganizationRole, OrganizationRoleName} from './Organization';

// Do not export (keep internals of the token encapsulated)
enum Auth0UserProperty {
  USER_ROLES = 'https://spartanapproach.com/user_roles',
  USER_PERMISSIONS = 'https://spartanapproach.com/user_permissions',
  NEXTGEN_USER = 'https://spartanapproach.com/nextgen_user',
  ORG_ID = 'https://spartanapproach.com/org_id',
  ORG_NAME = 'https://spartanapproach.com/org_name',
  IS_POWER = 'https://spartanapproach.com/is_power',
}

export interface Auth0User {
  nickname: string;
  name: string;
  [Auth0UserProperty.ORG_ID]: string;
  [Auth0UserProperty.ORG_NAME]: string;
  [Auth0UserProperty.IS_POWER]: boolean;
  [Auth0UserProperty.USER_ROLES]: Array<Auth0UserRole>;
  [Auth0UserProperty.USER_PERMISSIONS]: Array<Auth0UserPermission>;
  [Auth0UserProperty.NEXTGEN_USER]: NextgenUser;
}

export enum Auth0UserPermission {
  VIEW_SYSTEM_SETTINGS = 'view:system-settings',
  IMPERSONATE_ANY = 'impersonate:any',
}

export interface Auth0UserRole {
  role_id: string;
  role_name: string;
  role_priority: number;
  can_impersonate?: boolean;
}

// Do not export (keep internals of the token encapsulated)
interface NextgenUser {
  user_id: string;
  username: string;
  email: string;
  role_id: string;
  role_name: string;
  role_priority: number;
  extension: string;
  phone_number: string;
  sms_did: string;
}

export function isPowerOrg(user: Auth0User | undefined): boolean {
  return user?.[Auth0UserProperty.IS_POWER] || false;
}

export function getOrgName(user: Auth0User | undefined): string | undefined {
  return user?.[Auth0UserProperty.ORG_NAME];
}

export function getOrgId(user: Auth0User | undefined): string | undefined {
  return user?.[Auth0UserProperty.ORG_ID];
}

export function getUserId(user: Auth0User | undefined): string | undefined {
  return user?.[Auth0UserProperty.NEXTGEN_USER]?.user_id;
}

export function getUserEmail(user: Auth0User | undefined): string | undefined {
  return user?.[Auth0UserProperty.NEXTGEN_USER]?.email;
}

export function getUserRoleId(user: Auth0User | undefined): string | undefined {
  return user?.[Auth0UserProperty.NEXTGEN_USER]?.role_id;
}

export function getUserRoleName(user: Auth0User | undefined): string | undefined {
  return user?.[Auth0UserProperty.NEXTGEN_USER]?.role_name;
}

export function getUsername(user: Auth0User | undefined): string | undefined {
  return user?.[Auth0UserProperty.NEXTGEN_USER]?.username;
}

export function getUserSmsDid(user: Auth0User | undefined): string | undefined {
  return user?.[Auth0UserProperty.NEXTGEN_USER]?.sms_did;
}

export function getUserPhoneNumber(user: Auth0User | undefined): string | undefined {
  return user?.[Auth0UserProperty.NEXTGEN_USER]?.phone_number;
}

export function getUserExtension(user: Auth0User | undefined): string | undefined {
  return user?.[Auth0UserProperty.NEXTGEN_USER]?.extension;
}

export function getUserAuth0Roles(user: Auth0User | undefined): Array<Auth0UserRole> {
  return user?.[Auth0UserProperty.USER_ROLES] || [];
}

export function getUserPermissions(user: Auth0User | undefined): Array<Auth0UserPermission | string> {
  return user?.[Auth0UserProperty.USER_PERMISSIONS] ?? [];
}

export function getUserOrganizationRoles(user: Auth0User | undefined): Array<OrganizationRole> {
  return getUserAuth0Roles(user).map(({role_id, role_name}) => ({role_id, role_name}));
}

export function getCanImpersonateUserRoles(user: Auth0User | undefined): Array<OrganizationRole> {
  return (
    getUserAuth0Roles(user)
      // accept undefined as true for backward compatibility, can be removed once can_impersonate is always defined in auth0 token
      .filter((r) => r.can_impersonate === undefined || r.can_impersonate)
      .map(({role_id, role_name}) => ({role_id, role_name}))
  );
}

export function hasOrganizationRole(user: Auth0User | undefined, role: OrganizationRoleName | string): boolean {
  return getUserOrganizationRoles(user)
    .map((r) => r.role_name)
    .includes(role);
}

export function hasUserPermission(user: Auth0User | undefined, permission: Auth0UserPermission | string): boolean {
  return getUserPermissions(user).includes(permission);
}
