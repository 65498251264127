import {useState, useEffect} from 'react';
import {IFileWithMeta, StatusValue} from 'react-dropzone-uploader';
import {useAuth0} from '@auth0/auth0-react';
import {useTranslation} from 'react-i18next';
import {
  Grid,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {CampaignDataSource, CampaignListItem} from '../../../model/Campaign';
import {ResourceTypes} from '../../../model/ResourceTypes';
import {DropzoneWrapper} from '../../../form/components/DropzoneWrapper';
import {FileExtensions} from '../../../model/FileExtensions';
import {Auth0User, getUserEmail, getUserId} from '../../../model/Auth0User';
import {FeatureName} from '../../../../paths';

interface CopyCampaignDialogProps {
  isOpen: boolean;
  handleClose: Function;
  copyCampaign: Function;
  selectedCampaign: CampaignListItem;
}

function CopyCampaignDialog({isOpen, handleClose, selectedCampaign, copyCampaign}: CopyCampaignDialogProps) {
  const {t} = useTranslation();
  const auth0 = useAuth0<Auth0User>();
  const [copiedCampaignName, setCopiedCampaignName] = useState<string>('');
  const [file, setFile] = useState<FormData | null>(null);
  const [fileName, setFileName] = useState<string>();
  const [disableButton, setDisableButton] = useState<boolean>(true);

  useEffect(() => {
    if (
      copiedCampaignName &&
      ((selectedCampaign?.source_data === CampaignDataSource.TSV && file) ||
        selectedCampaign?.source_data !== CampaignDataSource.TSV)
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [copiedCampaignName, selectedCampaign, file]);

  function handleOnClose() {
    setCopiedCampaignName('');
    setFile(null);
    handleClose();
  }

  function filesToUploadHandler(fileWithMeta: IFileWithMeta, statusValue: StatusValue, allFiles: IFileWithMeta[]) {
    if (fileWithMeta?.meta?.status === 'done') {
      let postFilesBody = new FormData();
      postFilesBody.append('file1', fileWithMeta.file);
      postFilesBody.append('folder', FeatureName.CAMPAIGNS);
      postFilesBody.append('email', getUserEmail(auth0.user) || '');
      postFilesBody.append('user_id', getUserId(auth0.user) || '');
      postFilesBody.append('source', FeatureName.CAMPAIGNS);
      setFile(postFilesBody);
      setFileName(fileWithMeta?.meta?.name);
    } else if (fileWithMeta?.meta?.status === 'removed') {
      setFile(null);
      setFileName('');
    }
  }
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={isOpen}
      onClose={() => handleOnClose()}
      aria-labelledby="copy-campaign-dialog-title"
    >
      <DialogTitle id="task-dialog-title">
        {t('campaign.copy-label')} {selectedCampaign?.name} ?
        <IconButton
          aria-label="close"
          onClick={() => handleOnClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <InputLabel>{t('campaign.new-name')}</InputLabel>
        <TextField
          fullWidth
          id={'copy-campaign-field'}
          value={copiedCampaignName}
          placeholder={t('campaign.campaign-name')}
          onChange={(event) => setCopiedCampaignName(event.target.value)}
        />

        {selectedCampaign?.source_data === CampaignDataSource.TSV && (
          <Grid item container alignItems="center" justifyContent="center" display="flex">
            <Grid item xs={12}>
              <Card sx={{width: '100%'}}>
                <CardContent>
                  <DropzoneWrapper
                    filesToShow={[]}
                    maxFiles={1}
                    acceptedExtensions={[FileExtensions.TSV]}
                    onChangeFunction={filesToUploadHandler}
                    disableUploadButton
                    resourceType={ResourceTypes.CAMPAIGNS}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button id={'copy-campaign-cancel-btn'} color="secondary" onClick={() => handleOnClose()}>
          {t('shared.cancel')}
        </Button>
        <Button
          id={'copy-campaign-btn'}
          disabled={disableButton}
          onClick={() => {
            copyCampaign(copiedCampaignName, file, fileName);
            handleOnClose();
          }}
        >
          {t('campaign.copy-campaign')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CopyCampaignDialog;
