import {useCallback, useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';
import {Box, Container, Grid, MenuItem, Select, SelectChangeEvent, Typography} from '@mui/material';
import {styled} from '@mui/system';
import {IDashboard, IEmbedSsoParams, IEmbedUrlResponse, IFolder, Looker40SDK} from '@looker/sdk';
import {LookerEmbedSDK} from '@looker/embed-sdk';
import {useTranslation} from 'react-i18next';
import PageHeadline from '../../components/PageHeadline';
import {Path} from '../../../paths';
import {Auth0User, getOrgName, getUserOrganizationRoles} from '../../model/Auth0User';
import {PageStickyHeader} from '../PageStickyHeader';
import UserRoleService from '../../services/UserRoleService';
import {OrganizationName, OrganizationRoleName} from '../../model/Organization';
import useLookerSdk from '../../hooks/useLookerSdk';
import {PageTopActions} from '../PageTopActions';
import {TestAttributes} from '../../TestAttributes';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {sdkOk} from '@looker/sdk-rtl/lib/transport';
import {useUserSettingsContext} from '../../context/UserSettingsContext';

function resolveDefaultDashboardId(orgName: string | undefined): string {
  switch (orgName) {
    case OrganizationName.FIORELLA:
      return '31';
    case OrganizationName.FIORELLA_OOS:
      return '60';
    case OrganizationName.KASPER:
      return '69';
    case OrganizationName.BRINKS:
      return '83';
    default:
      return 'no_selection';
  }
}

function ReportingAnalyticsPage() {
  const sdk = useLookerSdk();
  return sdk ? <InnerPage sdk={sdk}></InnerPage> : null;
}

interface InnerPageProps {
  sdk: Looker40SDK;
}

function InnerPage({sdk}: InnerPageProps) {
  const {t} = useTranslation();
  const auth0 = useAuth0<Auth0User>();
  const {user} = auth0;
  const orgName = getOrgName(user);
  const defaultDashboardId = resolveDefaultDashboardId(orgName);
  const navigate = useNavigate();
  const {userSettings} = useUserSettingsContext();
  const theme_palette_mode = userSettings.theme_palette_mode;
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>();
  const [selectedRoleName, setSelectedRoleName] = useState<string | null>();
  const [ssoUrl, setSsoUrl] = useState<string>();
  const [selectedDashboardId, setSelectedDashboardId] = useState<string>(defaultDashboardId);
  const [folders, setFolders] = useState<Array<IFolder> | undefined>();
  const [dashboards, setDashboards] = useState<Array<IDashboard> | undefined>();

  const EmbedContainer = styled(Box)(() => ({
    width: '100%',
    height: '99vh',
    '& > iframe': {
      width: '100%',
      height: '100%',
    },
  }));

  useEffect(() => {
    const sub = UserRoleService.getInstance()
      .getSelectedRole()
      .subscribe((role) => setSelectedRoleId(role));
    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    if (user && selectedRoleId) {
      const role = getUserOrganizationRoles(user).find((role: any) => role.role_id === selectedRoleId);
      setSelectedRoleName(role?.role_name);
    }
  }, [selectedRoleId, user]);

  useEffect(() => {
    if (selectedRoleName) {
      const allowedRoles: string[] = [OrganizationRoleName.ADMIN, OrganizationRoleName.OPS_ADMIN];
      const canSeeFeature = allowedRoles.includes(selectedRoleName);
      if (!canSeeFeature) {
        navigate('/' + Path.DASHBOARD);
      }
    }
  }, [selectedRoleName]);

  useEffect(() => {
    if (orgName) {
      sdkOk(sdk.search_folders({name: orgName}))
        .then((data) => sdkOk(sdk.search_folders({parent_id: data[0].id})))
        .then((data) => setFolders(data));
    }
  }, [orgName]);

  useEffect(() => {
    if (folders && selectedRoleName) {
      const folder = folders.find((f) => f.name.toLowerCase().includes(selectedRoleName));
      setDashboards(folder?.dashboards ?? []);
    }
  }, [folders, selectedRoleName]);

  useEffect(() => {
    if (selectedDashboardId && orgName && folders) {
      const currentPage: string = window.location.href.split('/reporting-analytics')[0];
      const cdrModelName: string = `cdr_${orgName.replace('-', '_')}`;
      const ssoParams: IEmbedSsoParams = {
        target_url: `https://spartanapproach.cloud.looker.com/dashboards/${selectedDashboardId}?theme=${theme_palette_mode}?embed_domain=${currentPage}`,
        models: [orgName, cdrModelName],
        permissions: ['access_data', 'see_lookml_dashboards', 'see_looks', 'see_user_dashboards'],
        external_user_id: 'looker@spartanapproach.com',
        session_length: 3600,
        force_logout_login: true,
      };
      sdkOk(sdk.create_sso_embed_url(ssoParams))
        .then((response: IEmbedUrlResponse) => {
          setSsoUrl(response.url);
        })
        .catch((error) =>
          NotificationService.getInstance().sendNotification(JSON.stringify(error, null, 2), NotificationType.ERROR)
        );
    }
  }, [selectedDashboardId, orgName, folders]);

  const embedCtrRef = useCallback(
    (el: HTMLDivElement) => {
      if (el && ssoUrl) {
        el.innerHTML = '';
        LookerEmbedSDK.createDashboardWithUrl(ssoUrl)
          .withId('dashboard')
          .appendTo(el)
          .build()
          .connect()
          .catch((error: Error) => {
            console.error('An unexpected error occurred.', error);
          });
      }
    },
    [ssoUrl]
  );

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
            <PageStickyHeader>
              <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
                <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                  <PageHeadline>{t('reporting-dashboard.headline')}</PageHeadline>
                </Grid>
              </Grid>
            </PageStickyHeader>
          </Grid>
          {dashboards?.length ? (
            <Grid item xs={12} md={6}>
              <PageTopActions>
                <Select
                  sx={{
                    width: '450px',
                  }}
                  displayEmpty
                  {...{[TestAttributes.FIELD_NAME]: 'selected-mode'}}
                  id="selected-query-type"
                  value={selectedDashboardId}
                  onChange={(e: SelectChangeEvent) => setSelectedDashboardId(e.target.value)}
                >
                  {dashboards.map((dash: IDashboard) => (
                    <MenuItem
                      key={'selected-item-' + dash.id}
                      {...{[TestAttributes.FIELD_OPTION_NAME]: dash.id}}
                      id={'menu-item-selected-mode-' + dash.id}
                      value={dash.id}
                    >
                      {dash.title}
                    </MenuItem>
                  ))}
                </Select>
              </PageTopActions>
            </Grid>
          ) : null}
          {ssoUrl ? (
            <Grid item xs={12}>
              <EmbedContainer id="dashboard" ref={embedCtrRef} />
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                justifyItems: 'center',
                minHeight: '350px',
              }}
            >
              <Typography variant="h3">
                {t(
                  dashboards
                    ? dashboards.length
                      ? ''
                      : 'reporting-dashboard.no-dashboards'
                    : 'reporting-dashboard.loading-dashboards'
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
}

export default ReportingAnalyticsPage;
