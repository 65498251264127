import {Autocomplete, InputLabel, TextField} from '@mui/material';
import {
  buildSetValue,
  propsWithTestAttributes,
  SpartanFieldOption,
  SpartanFieldWithOptionProps,
  wrapInTooltipIfNecessary,
} from './model';
import {ErrorMessage} from './components/ErrorMessage';
import {SpartanLabelWithTooltip} from './SpartanLabelWithTooltip';

function findOptionsByValues(options: SpartanFieldOption[], values: any[]): SpartanFieldOption[] {
  return options.filter((opt) => values.indexOf(opt.value) >= 0);
}

export function SpartanMultiSelectField(_props: SpartanFieldWithOptionProps) {
  const props = propsWithTestAttributes(_props);
  const {
    id,
    name,
    value,
    label,
    placeholder,
    disabled,
    onBlur,
    onFocus,
    hasError,
    error,
    options,
    inputRef,
    inputProps,
    isNestedLabel = false,
  } = props;
  const setValue = buildSetValue(props);
  return (
    <>
      {!isNestedLabel && (
        <InputLabel error={hasError} htmlFor={id}>
          <SpartanLabelWithTooltip label={label} />
        </InputLabel>
      )}
      <Autocomplete
        id={id}
        multiple={true}
        filterSelectedOptions={true}
        disableCloseOnSelect={true}
        fullWidth={true}
        placeholder={placeholder || label}
        value={findOptionsByValues(options, value || [])}
        disabled={disabled}
        onChange={(event, selectedOptions, reason, details) => {
          setValue(selectedOptions.map((opt) => opt.value));
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        options={options}
        getOptionDisabled={(option) => (typeof option?.selectable !== 'undefined' ? !option?.selectable : false)}
        isOptionEqualToValue={(option: SpartanFieldOption, value: SpartanFieldOption) => option.value === value.value}
        getOptionLabel={(option: SpartanFieldOption) => option.label}
        renderInput={(params) => {
          const {inputProps: inputPropsFromParams} = params;
          const _inputProps = {...inputPropsFromParams, ...inputProps};
          return wrapInTooltipIfNecessary(
            props,
            <TextField
              {...params}
              name={name}
              error={hasError}
              variant="outlined"
              inputRef={inputRef}
              inputProps={_inputProps}
              label={isNestedLabel ? label : undefined}
            />
          );
        }}
      />
      <ErrorMessage hasError={hasError} error={error} />
    </>
  );
}
