import {createContext, PropsWithChildren, useContext, useMemo, useState} from 'react';

interface LoadingContextInterface {
  loading: boolean;
  setLoading: (value: boolean, name: string) => void;
}

const LoadingContext = createContext<LoadingContextInterface>({
  loading: false,
  setLoading: (value: boolean, name?: string) => {},
});

interface LoadingMap {
  [key: string]: boolean;
}

export function LoadingProvider({children}: PropsWithChildren) {
  const [loadingMap, setLoadingMap] = useState<LoadingMap>({});

  const setLoading = useMemo(
    () => (loading: boolean, name?: string) =>
      setLoadingMap((prev) => {
        return {...prev, [name ?? '']: loading};
      }),
    [setLoadingMap]
  );

  const loading = useMemo(() => Object.values(loadingMap).some((it) => it), [loadingMap]);

  const value = useMemo(() => ({loading, setLoading}), [loading, setLoading]);

  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider');
  }
  return context;
}
