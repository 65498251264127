import {ConditionOperator} from '../ConditionOperator';
import {Controller, useFormContext} from 'react-hook-form';
import {Alert, MenuItem, Select} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Condition, FormDataType, QueryFieldSchema} from '../../../model/Query';
import {FormFieldType} from '../../../form/logic/FormSchema';
import {QueryContext} from '../../../context/QueryContextProvider';

interface QueryOperatorProps {
  conditionField: Condition;
  conditionIndex: number;
}

function QueryOperator({conditionField, conditionIndex}: QueryOperatorProps) {
  const {t} = useTranslation();
  const {control} = useFormContext();
  const queryContextValue = useContext(QueryContext);
  const [conditionFieldSchema, setConditionFieldSchema] = useState<QueryFieldSchema | undefined>(undefined);
  let optionsTemplate;

  useEffect(() => {
    const currentConditionField = queryContextValue?.fields?.find(
      (field) => field.field_id === conditionField.field_id
    );
    setConditionFieldSchema(currentConditionField);
  }, [queryContextValue]);

  const operators = getConditionOperators(conditionFieldSchema?.data_type, conditionFieldSchema?.field_type);
  if (operators.length > 0) {
    optionsTemplate = (
      <Controller
        render={({field}) => (
          <>
            <Select label={t('query.operator')} fullWidth id={field.name + '-operator'} {...field}>
              {operators.map((operator: ConditionOperator) => (
                <MenuItem
                  id={field.name + '-operator-' + operator}
                  key={field.name + '-operator-' + operator}
                  value={operator}
                >
                  {t('query.operators.' + operator)}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        name={`query_conditions.${conditionIndex}.condition_type`}
        control={control}
        defaultValue={conditionField.condition_type}
      />
    );
  } else if (conditionFieldSchema) {
    optionsTemplate = (
      <Alert severity="warning">
        {t('query.error-messages.unsupported-data-type')} {conditionFieldSchema?.data_type}
      </Alert>
    );
  } else {
    optionsTemplate = <></>;
  }

  return optionsTemplate;
}

function getConditionOperators(
  dataType: FormDataType | undefined,
  fieldType: FormFieldType | undefined
): Array<ConditionOperator> {
  let conditionsTypes: Array<ConditionOperator>;

  switch (dataType) {
    case FormDataType.TEXT:
      conditionsTypes = [
        ConditionOperator.EQUALS,
        ConditionOperator.NOT_EQUAL,
        ConditionOperator.NULL,
        ConditionOperator.NOT_NULL,
      ];
      if (fieldType !== FormFieldType.SELECT) {
        conditionsTypes = [...conditionsTypes, ConditionOperator.STARTS_WITH, ConditionOperator.ENDS_WITH];
      }
      break;
    case FormDataType.NUMBER:
      conditionsTypes = [
        ConditionOperator.EQUALS,
        ConditionOperator.NOT_EQUAL,
        ConditionOperator.LESS_THAN,
        ConditionOperator.GREATER_THAN,
        ConditionOperator.LESS_THAN_OR_EQUAL,
        ConditionOperator.GREATER_THAN_OR_EQUAL,
        ConditionOperator.NULL,
        ConditionOperator.NOT_NULL,
      ];
      break;
    case FormDataType.DATE:
    case FormDataType.TIMESTAMP:
      conditionsTypes = [
        ConditionOperator.DATE_TO,
        ConditionOperator.DATE_FROM,
        ConditionOperator.DATE_BETWEEN,
        ConditionOperator.NULL,
        ConditionOperator.DATE_YESTERDAY,
        ConditionOperator.LAST_N_DAYS,
        ConditionOperator.NOT_NULL,
        ConditionOperator.TO_YESTERDAY,
        ConditionOperator.TODAY,
        ConditionOperator.N_DAYS_AGO,
      ];
      break;
    case FormDataType.SELECT:
      conditionsTypes = [
        ConditionOperator.EQUALS,
        ConditionOperator.NOT_EQUAL,
        ConditionOperator.NULL,
        ConditionOperator.NOT_NULL,
      ];
      break;
    case FormDataType.PHONE:
      conditionsTypes = [
        ConditionOperator.EQUALS,
        ConditionOperator.NOT_EQUAL,
        ConditionOperator.STARTS_WITH,
        ConditionOperator.ENDS_WITH,
        ConditionOperator.NULL,
      ];
      break;
    case FormDataType.LIST:
      conditionsTypes = [ConditionOperator.CONTAINS, ConditionOperator.NULL, ConditionOperator.NOT_NULL];
      break;
    case FormDataType.BOOLEAN:
      conditionsTypes = [
        ConditionOperator.EQUALS_BOOLEAN,
        ConditionOperator.NOT_EQUAL_BOOLEAN,
        ConditionOperator.NULL,
        ConditionOperator.NOT_NULL,
      ];
      break;
    default:
      conditionsTypes = [];
  }
  return conditionsTypes;
}

const memoizedQueryOperator = React.memo(QueryOperator);
export default memoizedQueryOperator;
