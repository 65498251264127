import React, {useMemo} from 'react';
import {useConfig} from '../context/ConfigContext';

export default function useAwsBucket(): string {
  const {ENV_NAME} = useConfig();

  return useMemo(() => {
    if (ENV_NAME === 'development') return 'dev';
    if (ENV_NAME === 'testing') return 'testing';
    if (ENV_NAME === 'staging') return 'stage';
    if (ENV_NAME === 'production') return 'prod';
    throw Error('unknown env ' + ENV_NAME);
  }, [ENV_NAME]);
}
