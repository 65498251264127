export const Modes = ['sql', 'dml', 'ddl'];
export type Mode = typeof Modes[number];

export interface SpannerResult {
  database_id: string;
  status: 'ok' | 'error';
  mode: Mode;
  result?: unknown;
  error?: string;
}

export interface SpannerSqlResult extends SpannerResult {
  mode: 'sql';
  result: any[][];
}

export interface SpannerDmlResult extends SpannerResult {
  mode: 'dml';
  result: {
    statement: string;
    status: 'ok' | 'error' | 'skipped';
    error?: string | null;
    affected_rows?: number | null;
  }[];
}

export interface SpannerDdlResult extends SpannerResult {
  mode: 'ddl';
  result: string;
}

export function isSpannerSqlResult(result: SpannerResult): result is SpannerSqlResult {
  return result.mode === 'sql';
}

export function isSpannerDmlResult(result: SpannerResult): result is SpannerDmlResult {
  return result.mode === 'dml';
}

export function isSpannerDdlResult(result: SpannerResult): result is SpannerDdlResult {
  return result.mode === 'ddl';
}

export interface Template {
  mode: Mode;
  content: string;
  name?: string;
}

export const TEMPLATES: Template[] = [
  {
    mode: 'sql',
    name: 'select information_schema',
    content:
      "SELECT table_name, column_name \nFROM information_schema.columns \nWHERE table_name LIKE 'test%' \nORDER BY 1, 2",
  },
  {
    mode: 'sql',
    name: 'select leads',
    content:
      'SELECT lead_id, email, first_name, last_name, primary_phone, created_by, created_at, last_updated_by, last_updated_at \nFROM Leads \nORDER BY created_at DESC \nLIMIT 100',
  },
  {
    mode: 'dml',
    name: 'insert',
    content: "INSERT INTO test_table(test_id) VALUES('00000000-0000-0000-0000-000000000000')",
  },
  {
    mode: 'dml',
    name: 'update',
    content:
      "UPDATE test_table SET \n  last_updated_by = 'spartaadmin', \n  last_updated_at = pending_commit_timestamp() \nWHERE test_id in ('00000000-0000-0000-0000-000000000000')",
  },
  {
    mode: 'ddl',
    name: 'create table',
    content:
      'CREATE TABLE test_table (\n  test_id STRING(50),\n  last_updated_by STRING(50),\n  last_updated_at TIMESTAMP OPTIONS(allow_commit_timestamp=true),\n) PRIMARY KEY (test_id)',
  },
  {
    mode: 'ddl',
    name: 'add table column',
    content: 'ALTER TABLE test_table ADD COLUMN test_column STRING(50)',
  },
  {
    mode: 'ddl',
    name: 'drop table',
    content: 'DROP TABLE IF EXISTS test_table',
  },
];
