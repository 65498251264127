import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useAuth0} from '@auth0/auth0-react';
import {Button, Grid, Typography} from '@mui/material';
import {TestAttributes} from '../../TestAttributes';
import {useAxiosContext} from '../../context/AxiosContext';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {useLoading} from '../../context/LoadingContext';
import {Auth0User, getUserEmail} from '../../model/Auth0User';

function PaymentInformationExport() {
  const {t} = useTranslation();
  const {useAxiosBFF} = useAxiosContext();
  const {setLoading} = useLoading();
  const auth0 = useAuth0<Auth0User>();
  const SETTING_URL = 'exports/export_payment_info';
  const [{error: patchError, response, loading: patchLoading}, exportPayment] = useAxiosBFF<boolean>(
    {
      url: `${SETTING_URL}`,
      method: 'POST',
    },
    {manual: true}
  );

  useEffect(() => {
    if (patchError) {
      NotificationService.getInstance().sendNotification(patchError?.response?.data?.message, NotificationType.ERROR);
    }
  }, [patchError]);

  useEffect(() => {
    setLoading(patchLoading, 'PaymentInformationExport');
  }, [patchLoading]);

  useEffect(() => {
    if (response?.status === 200) {
      NotificationService.getInstance().sendNotification(
        t('settings.payment-export-success'),
        NotificationType.SUCCESS
      );
    }
  }, [response]);
  function exportPaymentInfo() {
    exportPayment({
      data: {email_notification: getUserEmail(auth0.user) ?? ''},
    });
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h2">{t('settings.payment-export-label')}</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography>{t('settings.payment-export-info')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          id={'export-btn'}
          {...{[TestAttributes.BUTTON_NAME]: 'export-settings-btn'}}
          onClick={exportPaymentInfo}
        >
          {t('shared.export')}
        </Button>
      </Grid>
    </>
  );
}

export default PaymentInformationExport;
