import {BehaviorSubject, Observable} from 'rxjs';
import {Auth0ContextInterface} from '@auth0/auth0-react';
import {Auth0User} from '../model/Auth0User';
import {filter, map, take} from 'rxjs/operators';

export default class Auth0ContextHolder {
  private static INSTANCE = new Auth0ContextHolder();
  private auth0$ = new BehaviorSubject<Auth0ContextInterface<Auth0User> | undefined>(undefined);

  static getInstance(): Auth0ContextHolder {
    return Auth0ContextHolder.INSTANCE;
  }

  static init(auth0: Auth0ContextInterface<Auth0User>) {
    Auth0ContextHolder.INSTANCE.auth0$.next(auth0);
  }

  /**
   * @returns observable with first authenticated Auth0ContextInterface.
   * Such observable completes after delivering the value, therefore there is no need to unsubscribe.
   */
  getWhenAuthenticated(): Observable<Auth0ContextInterface<Auth0User>> {
    return this.auth0$
      .asObservable()
      .pipe(filter((auth0) => auth0 !== undefined && auth0.isAuthenticated))
      .pipe(map((auth0) => auth0 as Auth0ContextInterface<Auth0User>))
      .pipe(take(1));
  }

  /**
   * @returns observable with first not undefined Auth0ContextInterface.
   * Such observable completes after delivering the value, therefore there is no need to unsubscribe.
   */
  getWhenAvailable(): Observable<Auth0ContextInterface<Auth0User>> {
    return this.auth0$
      .asObservable()
      .pipe(filter((auth0) => auth0 !== undefined))
      .pipe(map((auth0) => auth0 as Auth0ContextInterface<Auth0User>))
      .pipe(take(1));
  }

  /**
   * @returns current Auth0ContextInterface. It may be undefined.
   */
  get(): Auth0ContextInterface<Auth0User> | undefined {
    return this.auth0$.getValue()
  }
}
