import {Query} from '@testing-library/react';
import {FormState} from 'react-hook-form';
import {Condition} from '../../../model/Query';

export const invalidDate = 'Invalid Date';
export const invalidTime = 'Invalid Time';

export function isMissingOrEmpty(value: any) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'number' && isNaN(value)) ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (Array.isArray(value) && value.length === 0)
  );
}

export function isQueryLastConditionValid(formState: FormState<Query>, queryConditions: Array<Condition>): boolean {
  const {isValid, errors} = formState;
  const isFormInvalidWithoutError = !isValid && isMissingOrEmpty(errors);
  const isOnlyOneCondition = queryConditions?.length === 1;

  const isConditionValuesNotEmpty =
    isOnlyOneCondition && queryConditions[0]?.values?.every((val: any) => !isMissingOrEmpty(val?.value));
  const isQueryLastConditionValid = isFormInvalidWithoutError && isOnlyOneCondition && isConditionValuesNotEmpty;
  return isQueryLastConditionValid;
}
