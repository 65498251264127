import React from 'react';
import PageContainer from '../PageContainer';
import SpannerPageComponent from './SpannerPageComponent';

export default function SpannerPage() {
  return (
    <PageContainer title={'Spanner'}>
      <SpannerPageComponent />
    </PageContainer>
  );
}
