import {Autocomplete, InputLabel, TextField} from '@mui/material';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {ErrorMessage} from '../../../form/fields/components/ErrorMessage';

interface KeywordsProps {
  controllerName: string;
  tooltip: string;
}

function Keywords({controllerName, tooltip}: KeywordsProps) {
  const {control} = useFormContext();
  const autoCompleteId = 'autocomplete-keywords';

  return (
    <Controller
      control={control}
      name={controllerName}
      render={({field: {onChange}, fieldState: {error}}) => {
        const hasErrorMessage = error ? error.message : undefined;
        const hasError = !!hasErrorMessage;

        return (
          <>
            <InputLabel htmlFor={autoCompleteId} error={hasError}>
              {tooltip}
            </InputLabel>
            <Autocomplete
              id={autoCompleteId}
              multiple
              freeSolo
              options={[]}
              onChange={(event, selectedOptions, reason, details) => {
                onChange(selectedOptions);
              }}
              renderInput={(params) => <TextField {...params} error={hasError} />}
            />
            <ErrorMessage hasError={hasError} error={hasErrorMessage} />
          </>
        );
      }}
    />
  );
}

export default Keywords;
