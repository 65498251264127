import {useTranslation} from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  experimentalStyled,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {UserPermissions} from '../../../components/shared/AccessControl';
import CampaignCard from './CampaignCard';
import {useEffect, useState} from 'react';
import {WebSocketCampaign} from '../../../model/WebSocketCampaign';
import {TestAttributes} from '../../../TestAttributes';

export const CardListWrapper = experimentalStyled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
});

interface CampaignsCardProps {
  userPermissions: UserPermissions[];
  campaigns: WebSocketCampaign[] | undefined;
  title: string;
}

function CampaignsCard({userPermissions, title, campaigns}: CampaignsCardProps) {
  const theme = useTheme();
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // whenever loading set to true, kill after 10 secs
    if (loading) {
      const suppressLoadingTask = setTimeout(() => {
        setLoading(false);
      }, 10000);
      return () => clearTimeout(suppressLoadingTask);
    }
  }, [loading]);

  // if campaigns is undefined, then it's loading
  const titleSuffix = campaigns && !loading ? `(${campaigns.length})` : '...';

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="campaigns-panel"
          id="campaigns-panel"
          {...{[TestAttributes.ACCORDION_NAME]: 'campaigns-panel'}}
        >
          <Typography variant={'body1'} sx={{textTransform: 'capitalize'}}>
            {title} {titleSuffix}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <Box
              sx={{
                margin: theme.spacing(1),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : !campaigns || campaigns.length === 0 ? (
            <Box
              sx={{
                margin: theme.spacing(1),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant={'body1'}>{t('dashboard.campaign.no-active-msg')}</Typography>
            </Box>
          ) : (
            <Stack spacing={2} divider={<Divider flexItem />}>
              {campaigns.map((campaign: WebSocketCampaign) => (
                <CampaignCard campaign={campaign} userPermissions={userPermissions} key={campaign.campaign_id} />
              ))}
            </Stack>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default CampaignsCard;
