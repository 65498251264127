import {InputLabel, TextField} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';

interface SearchTextFieldProps {
  id: string;
  value?: string;
  translationKey: string;
  handleOnChange: any;
  handleKeyDown?: Function;
  handleOnSearch: Function;
}

function SearchTextField({id, value, handleOnChange, handleOnSearch, translationKey}: SearchTextFieldProps) {
  const {t} = useTranslation();

  function handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      handleOnSearch();
    }
  }

  return (
    <>
      <InputLabel htmlFor={id}>{t(translationKey)}</InputLabel>
      <TextField
        onChange={handleOnChange}
        fullWidth
        id={id}
        value={value}
        placeholder={`${t(translationKey)}`}
        onKeyDown={(e) => handleKeyDown(e)}
        InputProps={{
          endAdornment: (
            <InputAdornment
              sx={{cursor: 'pointer'}}
              onClick={() => handleOnChange({target: {id: id, value: ''}})}
              position="end"
            >
              {value && <CloseIcon />}
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default SearchTextField;
