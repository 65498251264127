import {styled} from '@mui/material';

export const PageStickyHeader = styled('div')(({theme}) => ({
  top: '0',
  width: '100%',
  padding: theme.spacing(3),
  zIndex: 5,
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('md')]: {
    position: 'sticky',
  },
}));
