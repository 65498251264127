import React from 'react';
import ListLayoutComponent from './shared/ListLayoutComponent';
import SingleSectionComponent from './SingleSectionComponent';
import SectionEditorCreate from './editors/SectionEditorCreate';
import {SectionsProvider, useSectionsContext} from '../contexts/SectionsContext';

interface Props {
  featureId: string;
}

function InternalFeatureSectionsComponent({featureId}: Props) {
  const {sections, loading} = useSectionsContext();
  const editor = <SectionEditorCreate partial={{feature_id: featureId}} />;
  const children = sections?.map((section) => <SingleSectionComponent section={section} key={section.section_id} />);
  return <ListLayoutComponent title={'Sections'} children={children} editor={editor} loading={loading} />;
}

export default function ListFeatureSectionComponent({featureId}: Props) {
  return (
    <SectionsProvider featureId={featureId}>
      <InternalFeatureSectionsComponent featureId={featureId} />
    </SectionsProvider>
  );
}
