import React, {useEffect, useRef, useState} from 'react';
import {Tooltip} from '@mui/material';

interface SpartanLabelWithTooltipProps {
  label: string;
}

export function SpartanLabelWithTooltip({label}: SpartanLabelWithTooltipProps) {
  const divElementRef = useRef<HTMLInputElement>(null);
  const [disabledHover, setDisabledHover] = useState<boolean>(false);

  function setDisableHoverListener() {
    if (divElementRef !== null) {
      const currentElement = divElementRef.current;
      const elementScrollWidth = currentElement ? currentElement.scrollWidth : null;
      const elementClientWidth = currentElement ? currentElement.clientWidth : null;
      let isElementScrollWidthBigger: boolean = false;

      if (elementScrollWidth && elementClientWidth) {
        isElementScrollWidthBigger = elementScrollWidth > elementClientWidth;
      }
      setDisabledHover(isElementScrollWidthBigger);
    }
  }

  useEffect(() => {
    setDisableHoverListener();
    window.addEventListener('resize', setDisableHoverListener);

    return () => {
      window.removeEventListener('resize', setDisableHoverListener);
    };
  }, []);

  return (
    <Tooltip title={disabledHover ? label : ''} placement="bottom-start" disableHoverListener={!disabledHover}>
      <div
        ref={divElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {label}
      </div>
    </Tooltip>
  );
}
