import {styled} from '@mui/material';

export const PageTopActions = styled('div')(({theme}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  alignItems: 'center',

  'a, div, form, >button': {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(0),
      width: '100%',
    },
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));
