import {
  DataGridPro as DataGrid,
  enUS,
  esES,
  GridCallbackDetails,
  GridColumns,
  GridDensity,
  GridInputSelectionModel,
  GridRowModel,
  GridRowParams,
  GridSelectionModel,
  GridSlotsComponentsProps,
} from '@mui/x-data-grid-pro';
import React, {JSXElementConstructor, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {UserPermissions} from './shared/AccessControl';
import GridToolbar from './shared/GridToolbar';
import GridRowCustom from './shared/GridRowCustom';
import {useUserSettingsContext} from '../context/UserSettingsContext';

interface DataGridCustomProps {
  rows: GridRowModel[];
  tableColumns: GridColumns;
  userPermissions?: Array<UserPermissions> | null;
  page?: number;
  pageSize?: number;
  rowsPerPageOptions?: Array<number>;
  checkboxSelection?: boolean;
  selectionModel?: GridInputSelectionModel;
  customToolbar?: JSXElementConstructor<any>;
  componentsProps?: GridSlotsComponentsProps;
  onPageChange?: (param: any) => void;
  onRowClick?: (param: GridRowParams, event: React.MouseEvent<Element, MouseEvent>) => void;
  onSelectionModelChange?: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => void;
}

function DataGridCustom({
  rows,
  tableColumns,
  userPermissions,
  page,
  pageSize,
  rowsPerPageOptions,
  checkboxSelection,
  selectionModel,
  customToolbar,
  componentsProps,
  onPageChange,
  onRowClick,
  onSelectionModelChange,
}: DataGridCustomProps) {
  const {i18n} = useTranslation();
  const {userSettings} = useUserSettingsContext();
  const {grid_density} = userSettings;
  const [densityValue, setDensityValue] = useState<GridDensity>();
  const [shouldShowDataGrid, setShouldShowDataGrid] = useState<boolean>(false);

  useEffect(() => {
    if (tableColumns?.length > 0) {
      setShouldShowDataGrid(!(tableColumns?.length === 1 && tableColumns[0]?.field === 'actions'));
    }
  }, [tableColumns]);

  useEffect(() => {
    setDensityValue(grid_density ?? 'standard');
  }, [grid_density]);

  const localeText =
    i18n.language === 'es'
      ? esES.components.MuiDataGrid.defaultProps.localeText
      : enUS.components.MuiDataGrid.defaultProps.localeText;
  return (
    <>
      {shouldShowDataGrid && (
        <DataGrid
          rows={rows}
          columns={tableColumns}
          components={{
            Toolbar: customToolbar ?? GridToolbar,
            Row: GridRowCustom,
          }}
          density={densityValue}
          componentsProps={componentsProps ?? {toolbar: {userPermissions}}}
          page={page}
          pageSize={pageSize}
          rowsPerPageOptions={rowsPerPageOptions ?? [100]}
          onPageChange={onPageChange}
          onRowClick={onRowClick}
          checkboxSelection={checkboxSelection}
          pagination
          initialState={{pinnedColumns: {left: ['actions']}}}
          onSelectionModelChange={onSelectionModelChange}
          selectionModel={selectionModel}
          localeText={localeText}
        />
      )}
    </>
  );
}

export default DataGridCustom;
