import {ChangeEvent, useEffect, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import {GridRowModel} from '@mui/x-data-grid';
import {propsWithTestAttributes, SpartanTextFieldProps, wrapInTooltipIfNecessary} from './model';
import SearchIcon from '@mui/icons-material/Search';
import SearchTextField from '../../components/shared/SearchTextField';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {ErrorMessage} from './components/ErrorMessage';
import {Lead, LeadListItem, LeadListSearchParams, LeadListSearchSortOption} from '../../model/Lead';
import {Close} from '@mui/icons-material';
import OrganizationService from '../../services/OrganizationService';
import {Organization} from '../../model/Organization';
import DataGridCustom from '../../components/DataGridCustom';
import {FeatureName} from '../../../paths';

import {useTableColumns} from '../../pages/UseTableColumns';
import {SpartanLabelWithTooltip} from './SpartanLabelWithTooltip';
import {TestAttributes} from '../../TestAttributes';
import {AxiosResponse} from 'axios';
import {useAxiosContext} from '../../context/AxiosContext';

export function SpartanSearchField(_props: SpartanTextFieldProps) {
  const {useAxiosBFF} = useAxiosContext();
  const props = propsWithTestAttributes(_props);
  const {id, name, value, label, placeholder, disabled, onChange, onBlur, onFocus, hasError, error, inputProps} = props;
  const {t} = useTranslation();

  const [organization, setOrganization] = useState<Organization | null>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [leads, setLeads] = useState<Array<LeadListItem> | null>(null);
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);

  const [search, setSearch] = useState<string>('');
  const [formattedDisplayName, setFormattedDisplayName] = useState<string>('');
  const [sort, setSort] = useState<LeadListSearchSortOption>(LeadListSearchSortOption.CREATED_AT);

  const [{loading: isGetLeadsLoading}, getLeads] = useAxiosBFF<ResponseListWrapper<LeadListItem>>(
    {url: `/${FeatureName.LEADS}`, method: 'GET'},
    {manual: true}
  );

  const [{loading: isGetLeadsByIdLoading}, getLeadsById] = useAxiosBFF<Lead>({method: 'GET'}, {manual: true});

  const tableColumns = useTableColumns({
    featureName: FeatureName.LEADS,
    defaultActionColumn: false,
  });

  useEffect(() => {
    const leadName = `${selectedLead?.[organization?.first_name_key!] || ''} ${
      selectedLead?.[organization?.last_name_key!] || ''
    } `;
    const formattedValue = leadName?.length > 1 ? leadName : '';
    setFormattedDisplayName(formattedValue);
  }, [selectedLead, organization]);

  useEffect(() => {
    if (value) {
      searchLeadById(value);
    }
  }, [value]);

  useEffect(() => {
    const subscription = OrganizationService.getInstance()
      .getOrganization()
      .subscribe((org) => {
        setOrganization(org);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  function searchLeadById(value: string): void {
    getLeadsById({url: `/${FeatureName.LEADS}/${value}`, params: {formatted: true}}).then(
      (leadResponse: AxiosResponse<Lead>) => {
        setSelectedLead(leadResponse.data);
      }
    );
  }

  function handleSearchChange(e: ChangeEvent<HTMLInputElement>): void {
    setSearch(e.target.value);
  }

  function handleSortByChange(e: any) {
    setSort(e.target.value);
  }

  function handleLeadSearch() {
    const searchParams: LeadListSearchParams = {
      search: search,
      order_by: sort,
    };
    getLeads({params: {...searchParams, formatted: false}}).then(
      (responseListWrapper: AxiosResponse<ResponseListWrapper<LeadListItem>>) => {
        setLeads(responseListWrapper.data.results);
      }
    );
  }

  function handleModalOpen(isModalOpen: boolean): void {
    setLeads(null);
    setSearch('');
    setIsModalOpen(isModalOpen);
  }

  function handleModalClose(isModalOpen: boolean): void {
    setIsModalOpen(isModalOpen);
  }

  function handleOnChange(name: string, value: string): void {
    onChange({
      target: {
        name,
        value,
      },
    });
  }

  function handleRowSelected(rowSelected: GridRowModel): void {
    const {row} = rowSelected;
    setSelectedLead(row);
    handleOnChange(name, row?.lead_id);

    setIsModalOpen(false);
  }

  function handleClearField() {
    handleOnChange(name, '');
    setSelectedLead(null);
  }

  return (
    <>
      <Grid item container xs={12} spacing={2} sx={{alignItems: 'flex-end'}}>
        <Grid item xs={12} sm={10}>
          <InputLabel error={hasError} htmlFor={id}>
            <SpartanLabelWithTooltip label={label} />
          </InputLabel>
          {wrapInTooltipIfNecessary(
            props,
            <TextField
              id={id}
              value={formattedDisplayName}
              disabled
              fullWidth
              placeholder={placeholder || label}
              inputProps={inputProps}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{cursor: 'pointer'}}
                    onClick={handleClearField}
                    position="end"
                    {...{[TestAttributes.BUTTON_NAME]: 'delete-button'}}
                  >
                    <CloseIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            disabled={disabled}
            onClick={() => handleModalOpen(true)}
            onBlur={onBlur}
            onFocus={onFocus}
            {...{[TestAttributes.BUTTON_NAME]: 'search-btn'}}
            id={'search-btn'}
          >
            <SearchIcon />
          </Button>
        </Grid>
      </Grid>

      <ErrorMessage hasError={hasError} error={error} />

      <Modal open={isModalOpen} onClose={() => handleModalClose(false)}>
        <Box
          sx={{
            overflow: 'auto',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '65%',
            height: '65%',
            bgcolor: 'background.paper',
            p: (theme) => ` ${theme.spacing(3)}`,
          }}
        >
          <Grid item container xs={12} spacing={3} sx={{alignItems: 'flex-end', mb: (theme) => ` ${theme.spacing(1)}`}}>
            <Grid item xs={12} sx={{textAlign: 'right'}}>
              <IconButton aria-label="Close" onClick={() => handleModalClose(false)} size="large">
                <Close {...{[TestAttributes.BUTTON_NAME]: 'close-btn'}} />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h5'}>{t('leads.search-message')}</Typography>
            </Grid>
            <Grid item container xs={12} spacing={3} sx={{alignItems: 'flex-end'}}>
              <Grid item xs={12} sm={5}>
                <SearchTextField
                  id={'lead_search'}
                  value={search}
                  translationKey={'leads.search'}
                  handleOnChange={handleSearchChange}
                  handleOnSearch={handleLeadSearch}
                ></SearchTextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel htmlFor="lead-sort-by">{t('leads.sort')}</InputLabel>
                <Select
                  id="lead_sort_by"
                  value={sort}
                  onChange={handleSortByChange}
                  labelId="lead-sort-by"
                  fullWidth
                  label={t('leads.sort')}
                >
                  <MenuItem value={LeadListSearchSortOption.CREATED_AT}>{t('leads.created-date')}</MenuItem>
                  <MenuItem value={LeadListSearchSortOption.LAST_UPDATED_AT}>{t('leads.last-update')}</MenuItem>
                </Select>
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                sx={{
                  my: 1,
                }}
              >
                <Button onClick={handleLeadSearch} fullWidth id={'search-btn'}>
                  <SearchIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {isGetLeadsLoading || isGetLeadsByIdLoading ? (
            <Skeleton variant="rectangular" animation="wave" height={150} />
          ) : (
            <Grid
              item
              xs={12}
              sx={{height: '500px', mt: (theme) => ` ${theme.spacing(2)}`}}
              justifyContent={'center'}
              {...{[TestAttributes.TABLE_NAME]: 'table-results'}}
            >
              {leads && leads?.length > 0 ? (
                <DataGridCustom
                  tableColumns={tableColumns}
                  rows={
                    leads?.map((item: LeadListItem, index: number) => {
                      return {
                        actions: item.lead_id,
                        ...item,
                        id: index,
                      };
                    }) || []
                  }
                  onRowClick={handleRowSelected}
                />
              ) : (
                <Box display={'flex'} justifyContent={'center'} sx={{mt: (theme) => ` ${theme.spacing(8)}`}}>
                  <Typography variant={'h4'}>{t('leads.search-modal-message')}</Typography>
                </Box>
              )}
            </Grid>
          )}
        </Box>
      </Modal>
    </>
  );
}
