import {createContext, PropsWithChildren, useContext} from 'react';
import {FormSection} from '../model/FormSection';

const Context = createContext<SectionContext | null>(null);

interface Props extends PropsWithChildren {
  section: FormSection;
}

export const SectionProvider = ({section, children}: Props) => {
  return <Context.Provider value={{section}}>{children}</Context.Provider>;
};

export interface SectionContext {
  section: FormSection;
}

export function useSectionContext(): SectionContext {
  const value = useContext(Context);
  if (!value) throw Error('invalid context');
  return value;
}
