import React from 'react';
import ListLayoutComponent from './shared/ListLayoutComponent';
import SingleMenuItemComponent from './SingleMenuItemComponent';
import MenuItemEditorCreate from './editors/MenuItemEditorCreate';
import {MenuItemsProvider, useMenuItemsContext} from '../contexts/MenuItemsContext';

interface Props {
  featureId: string;
  menuItemId: string;
}

function InternalMenuItemMenuItemsComponent({menuItemId, featureId}: Props) {
  const {menuItems, loading} = useMenuItemsContext();
  const editor = <MenuItemEditorCreate partial={{feature_id: featureId, parent_menu_item_id: menuItemId}} />;
  const children = menuItems?.map((menuItem) => (
    <SingleMenuItemComponent menuItem={menuItem} key={menuItem.menu_item_id} />
  ));
  return <ListLayoutComponent title={'Menu Items'} children={children} editor={editor} loading={loading} />;
}

export default function ListMenuItemMenuItemComponent({menuItemId, featureId}: Props) {
  return (
    <MenuItemsProvider menuItemId={menuItemId} featureId={featureId}>
      <InternalMenuItemMenuItemsComponent menuItemId={menuItemId} featureId={featureId} />
    </MenuItemsProvider>
  );
}
