export const baseTypography = {
  fontSize: 16,
  fontFamily: ['Quicksand'].join(','),
  h1: {
    fontSize: '2rem',
    fontWeight: 400,
  },
  h2: {
    fontSize: '1.5rem',
    fontWeight: 400,
  },
  h3: {
    fontSize: '1.375rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    marginBottom: '10px',
  },
  h4: {
    fontSize: '1.23rem',
    fontWeight: 400,
  },
  h5: {
    fontSize: '1.125rem',
    fontWeight: 300,
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 300,
  },
  subtitle2: {
    fontSize: '1rem',
    fontWeight: 300,
  },
  body1: {
    fontSize: '1rem',
    fontFamily: 'Quicksand',
    fontWeight: 300,
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 300,
  },
  caption: {
    fontSize: '0.725rem',
    fontWeight: 400,
  },
  overline: {
    fontSize: '1rem',
    fontWeight: 500,
  },
};
