import React, {useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import Typography from '@mui/material/Typography';
import {Box, Button, Grid, LinearProgress} from '@mui/material';
import Container from '@mui/material/Container';
import {useTranslation} from 'react-i18next';
import {Auth0Error} from '../../Auth0Error';

function LoginPage() {
  const [auth0Error, setAuth0Error] = React.useState<Auth0Error>();
  const {loginWithRedirect, error, logout} = useAuth0();
  const {t} = useTranslation();
  const [progress, setProgress] = React.useState(0);

  function reLogin() {
    logout({returnTo: window.location.origin + '/login'});
  }

  useEffect(() => {
    if (!error) {
      loginWithRedirect().then();
    } else {
      setAuth0Error(new Auth0Error(error));
    }
  }, []);

  useEffect(() => {
    if (auth0Error?.allowReLogin) {
      const timer = setInterval(() => setProgress((prev) => Math.min(100, prev + 5)), 150);
      return () => clearInterval(timer);
    }
  }, [auth0Error]);

  useEffect(() => {
    if (progress >= 100) reLogin();
  }, [progress]);

  return (
    <>
      {auth0Error ? (
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3,
          }}
        >
          <Container maxWidth={false}>
            <Grid container spacing={3}>
              {auth0Error.allowReLogin && (
                <Grid item xs={12}>
                  <LinearProgress variant="determinate" value={progress} />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant={'h2'}>{t(auth0Error.translationKey)}</Typography>
              </Grid>
              {auth0Error.allowReLogin && (
                <Grid item xs={12}>
                  <Button id="re-login-btn" onClick={reLogin}>
                    {t('login.re-login-btn')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Container>
        </Box>
      ) : null}
    </>
  );
}

export default LoginPage;
