import {FormFieldOption, FormFieldSize, FormFieldType, FormSchema, FormSectionType} from '../../form/logic/FormSchema';
import {QueryFieldSchema} from '../../model/Query';
import {dynamicDisplayFieldInfo} from '../../model/CallTranscriptions';

const getSchemaConf = (
  orderByOptions: Array<QueryFieldSchema>,
  extraOptions: Array<dynamicDisplayFieldInfo>
): FormSchema => {
  let formattedOrderByOptions = orderByOptions
    .filter((field) => field.field_name !== 'org')
    .map((field): FormFieldOption => {
      return {label: field.label ? field.label : '', value: field.field_name};
    });
  let formattedExtraOptions = extraOptions.map((field) => {
    return {label: field.field_label, value: field.field_name};
  });
  formattedOrderByOptions.splice(0, 0, ...formattedExtraOptions);

  return {
    sections: [
      {
        section_id: '',
        section_name: 'Transcription Details',
        section_type: FormSectionType.BASIC,
        index: 1,
        fields: [
          {
            field_id: 'order_by',
            field_name: 'order_by',
            field_type: FormFieldType.SELECT,
            size: FormFieldSize.MD,
            label: 'order by',
            index: 2,
            in_pdf: false,
            searchable: false,
            default_value: 'call_start_timestamp',
            options: formattedOrderByOptions,
            required: 'required field',
          },
          {
            field_id: 'order',
            field_name: 'order',
            field_type: FormFieldType.SELECT,
            size: FormFieldSize.MD,
            label: 'order',
            index: 3,
            searchable: false,
            default_value: 'desc',
            options: [
              {
                label: 'asc',
                value: 'asc',
              },
              {
                label: 'desc',
                value: 'desc',
              },
            ],
            required: 'required field',
          },
        ],
      },
    ],
  };
};

export default getSchemaConf;
