import {Divider, Grid, MenuItem, Select, TextField, Tooltip} from '@mui/material';
import {useTheme} from '@mui/material';
import {Controller, useFormContext} from 'react-hook-form';
import {LogicOperator} from '../ConditionOperator';
import React, {PropsWithChildren, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Condition, QueryFieldSchema} from '../../../model/Query';
import QueryConditionValue from './QueryConditionValue';
import QueryOperator from './QueryOperator';
import {QueryContext} from '../../../context/QueryContextProvider';
import {paletteModeDark} from '../../../context/LightDarkThemeProvider';
interface QueryConditionProps {
  conditionField: Condition;
  conditionIndex: number;
  fieldNameRequiredCondition?: string;
}

function QueryCondition({
  conditionField,
  conditionIndex,
  fieldNameRequiredCondition = 'status',
  children,
}: PropsWithChildren<QueryConditionProps>) {
  const {t} = useTranslation();
  const theme = useTheme();
  const {control, getValues} = useFormContext();
  const [conditionFieldSchema, setConditionFieldSchema] = useState<QueryFieldSchema | undefined>(undefined);
  const queryContextValue = useContext(QueryContext);
  const fieldsLength = getValues('query_conditions')?.length - 1;

  useEffect(() => {
    const currentConditionField = queryContextValue?.fields?.find(
      (field) => field.field_id === conditionField.field_id
    );
    setConditionFieldSchema(currentConditionField);
  }, [queryContextValue]);

  return (
    <Grid container item xs={12} spacing={1} sx={{marginBottom: (theme) => theme.spacing(1)}}>
      <Grid container item xs={12} spacing={1}>
        {conditionIndex !== 0 && (
          <Grid item xs={10}>
            <Divider sx={{marginBottom: (theme) => theme.spacing(2), marginTop: (theme) => theme.spacing(2)}} />
          </Grid>
        )}
      </Grid>

      <Grid container item xs={12} spacing={3} alignItems={'center'}>
        <Grid item xs={3} sm={2} md={1}>
          {conditionIndex !== 0 && (
            <Controller
              render={({field}) => (
                <>
                  <Select
                    fullWidth
                    id={field.name}
                    {...field}
                    disabled={
                      conditionFieldSchema?.field_name === fieldNameRequiredCondition && conditionIndex === fieldsLength
                    }
                  >
                    {Object.keys(LogicOperator).map((key) => (
                      <MenuItem
                        id={field.name + '-sequence-operator-' + LogicOperator[key]}
                        key={field.name + '-sequence-operator-' + LogicOperator[key]}
                        value={LogicOperator[key]}
                      >
                        {t('query.logical-operators.' + LogicOperator[key])}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
              name={`query_conditions.${conditionIndex}.sequence_operator`}
              control={control}
              rules={{required: true}}
              defaultValue={LogicOperator.AND}
            />
          )}
        </Grid>
        <Grid container item xs={12} sm={2}>
          <Tooltip title={conditionFieldSchema?.label ?? ''}>
            <TextField
              value={conditionFieldSchema?.label}
              variant="outlined"
              disabled
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor:
                    theme.palette.mode === paletteModeDark
                      ? theme.palette.secondary.main
                      : theme.palette.secondary.contrastText,
                },
              }}
            />
          </Tooltip>
        </Grid>
        <Grid container item xs={12} sm={2}>
          <QueryOperator conditionField={conditionField} conditionIndex={conditionIndex} />
        </Grid>
        <Grid container item xs={12} sm={6}>
          <QueryConditionValue conditionIndex={conditionIndex} conditionField={conditionField} />
          <Grid item xs={2} display={'flex'}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const memoizedQueryCondition = React.memo(QueryCondition);
export default memoizedQueryCondition;
