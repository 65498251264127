import {createContext, PropsWithChildren, useEffect, useState} from 'react';
import {QueryFieldSchema} from '../model/Query';

interface QueryContextValue {
  fields: Array<QueryFieldSchema>;
}

interface QueryContextProviderProps {
  defaultValue: Array<QueryFieldSchema>;
}

export const QueryContext = createContext<QueryContextValue | null>(null);

export default function QueryContextProvider({children, defaultValue}: PropsWithChildren<QueryContextProviderProps>) {
  const [fields, setFields] = useState<Array<QueryFieldSchema>>(defaultValue);

  useEffect(() => {
    setFields(defaultValue);
  }, [defaultValue]);

  const value: QueryContextValue = {
    fields: fields,
  };

  // useMemo(() => ({value}), value)
  return <QueryContext.Provider value={value}>{children}</QueryContext.Provider>;
}
