import {withIMask} from './components/withIMask';
import {SpartanTextField} from './SpartanTextField';

function preProcessValue(value: any): string {
  return value === undefined ? '' : `${value}`;
}

function postProcessValue(unmaskedValue: string) {
  const parsed = parseInt(unmaskedValue);
  return isNaN(parsed) ? null : parsed.toString();
}

export const SpartanExtensionField = withIMask(
  SpartanTextField,
  {
    mask: [{mask: '000'}, {mask: '0000'}, {mask: ''}],
  },
  {preProcessValue, postProcessValue}
);
