import {useEffect} from 'react';
import {MetaFile, MetaFilesResponse} from './model/MetaFiles';
import {FeatureName} from '../paths';
import {AxiosResponse, Method} from 'axios';
import {DefaultResponse} from './services/model/DefaultResponse';
import {FileContent, ResponseFile} from './model/Files';
import NotificationService, {NotificationType} from './services/NotificationService';
import {Auth0User} from './model/Auth0User';
import FilesService from './services/FilesService';
import {useAuth0} from '@auth0/auth0-react';
import {useAxiosContext} from './context/AxiosContext';
import {useLoading} from './context/LoadingContext';

export interface UseFilesState {
  getMetaFiles: (folder: string) => Promise<Array<MetaFile>>;
  deleteFile: (file_name: string, folder: string) => Promise<DefaultResponse>;
  postFiles: (formData: FormData, redirects?: number | undefined) => Promise<AxiosResponse<ResponseFile>>;
  postFilesAPIv2: (formData: FormData, url?: string, redirects?: number | undefined) => Promise<AxiosResponse<ResponseFile>>;
  uploadFiles: (leadId: string, filesToUpload: Array<FormData>) => Promise<string>;
  getFileContent: (params: FileContent) => Promise<string>;
  getSignature: (folder: string) => Promise<string | null>;
  executeImport: (method: Method, data: any, params: any) => Promise<DefaultResponse>;
  getFile: (metaFile: MetaFile) => Promise<ResponseFile>;
}

export function useFiles(): UseFilesState {
  const auth0 = useAuth0<Auth0User>();
  const {useAxiosBFF, useAxiosAPIv2} = useAxiosContext();
  const {setLoading} = useLoading();

  const [{loading: isMetaFilesLoading}, getMetaFilesData] = useAxiosBFF<MetaFilesResponse>(
    `/${FeatureName.METAFILES}/`,
    {manual: true}
  );

  const [{loading: isDeleteLoading, response: deleteFileResponse}, deleteFileRequest] = useAxiosBFF<DefaultResponse>(
    {url: `/${FeatureName.FILES}`, method: 'delete'},
    {manual: true}
  );

  const [{loading: isPostFilesLoading, response: postFilesResponse, error: hasPostFilesError}, postFilesRequest] =
    useAxiosBFF<ResponseFile>({url: `${FeatureName.FILES}/`, method: 'post'}, {manual: true, autoCancel: false});

  const [{loading: isPostFilesAPIv2, response: postFilesResponseAPIv2, error: hasPostFilesErrorAPIv2}, postFilesRequestAPIv2] =
    useAxiosAPIv2<ResponseFile>({method: 'post'}, {manual: true, autoCancel: false});

  const [{loading: isFileContentLoading}, getFileContentRequest] = useAxiosBFF<ArrayBuffer>(
    {url: `${FeatureName.FILES}`, headers: {'Content-Type': 'image/jpeg'}, responseType: 'arraybuffer'},
    {manual: true, autoCancel: false}
  );

  const [{loading: isExecuteImportLoading}, executeImportRequest] = useAxiosBFF<DefaultResponse>(
    {url: `/${FeatureName.IMPORTS}`},
    {manual: true}
  );

  const [{loading: isGetFileLoading}, getFileRequest] = useAxiosBFF<ResponseFile>(
    {url: `${FeatureName.FILES}`},
    {manual: true}
  );

  useEffect(() => {
    const response = postFilesResponse || postFilesResponseAPIv2;
    if (response?.status) {
      if (response?.status >= 200 && response?.status <= 299) {
        NotificationService.getInstance().sendNotification(response.data.message, NotificationType.SUCCESS);
      }
    }
  }, [postFilesResponse, postFilesResponseAPIv2]);

  useEffect(() => {
    const loading =
      isMetaFilesLoading ||
      isDeleteLoading ||
      isPostFilesLoading ||
      isPostFilesAPIv2 ||
      isFileContentLoading ||
      isExecuteImportLoading ||
      isGetFileLoading;
    setLoading(loading, 'useFiles');
  }, [
    isMetaFilesLoading,
    isDeleteLoading,
    isPostFilesLoading,
    isPostFilesAPIv2,
    isFileContentLoading,
    isExecuteImportLoading,
    isGetFileLoading,
  ]);

  useEffect(() => {
    const error = hasPostFilesError || hasPostFilesErrorAPIv2;
    if (error) {
      NotificationService.getInstance().sendNotification(
        error?.response?.data?.message || error?.response?.data?.detail,
        NotificationType.ERROR
      );
    }
  }, [hasPostFilesError, hasPostFilesErrorAPIv2]);

  useEffect(() => {
    if (deleteFileResponse?.status === 200) {
      NotificationService.getInstance().sendNotification(deleteFileResponse.data.message, NotificationType.SUCCESS);
    }
  }, [deleteFileResponse]);

  function getMetaFiles(folder: string): Promise<Array<MetaFile>> {
    const result = getMetaFilesData({params: {folder}}).then(
      (response: AxiosResponse<MetaFilesResponse>) => response.data.files || []
    );

    return result;
  }

  function getFile(metaFile: MetaFile): Promise<ResponseFile> {
    return resolveMetaFile(metaFile);
  }

  function resolveMetaFile(metaFile: MetaFile): Promise<ResponseFile> {
    const params = {
      folder: metaFile?.folder_name,
      filename: metaFile?.name,
    };
    return getFileRequest({params: params}).then((response: AxiosResponse<ResponseFile>) => {
      if (response.status === 200) {
        return {...response.data, filename: params.filename} as ResponseFile;
      }
      throw new Error(`${response.data.message} (code ${response.data.statusCode})`);
    });
  }

  function postFiles(formData: FormData): Promise<AxiosResponse<ResponseFile>> {
    const data = Object.fromEntries(formData);
    const result = postFilesRequest({
      headers: {'Content-Type': 'multipart/form-data'},
      data: data,
    }).then((response: AxiosResponse<ResponseFile>) => {
      return response;
    });
    return result;
  }

  function postFilesAPIv2(formData: FormData, url?: string): Promise<AxiosResponse<ResponseFile>> {
    const data = Object.fromEntries(formData);
    const result = postFilesRequestAPIv2({
      headers: {'Content-Type': 'multipart/form-data'},
      data: data,
      url
    }).then((response: AxiosResponse<ResponseFile>) => {
      return response;
    });
    return result;
  }

  function deleteFile(file_name: string, folder: string): Promise<DefaultResponse> {
    const result = deleteFileRequest({params: {file_name, folder}}).then(
      (response: AxiosResponse<DefaultResponse>) => response.data
    );

    return result;
  }

  function executeImport(method: Method = 'post', data: any, params: any): Promise<DefaultResponse> {
    const result = executeImportRequest({data: data, params: params, method: method}).then(
      (response: AxiosResponse<DefaultResponse>) => response.data
    );

    return result;
  }

  function getFileContent(params: FileContent): Promise<string> {
    const result = getFileContentRequest({params: params}).then((response: AxiosResponse<ArrayBuffer>) =>
      toImage(response.data)
    );

    return result;
  }

  function getSignature(folder: string): Promise<string | null> {
    return getMetaFiles(folder).then((metaFiles: Array<MetaFile>) => {
      const signatureMetaFile = metaFiles?.[0];

      const params = {
        folder: signatureMetaFile?.folder_name || '',
        filename: signatureMetaFile?.name || '',
        type: 'download',
      };
      if (signatureMetaFile?.folder_name && signatureMetaFile?.name) {
        return getFileContent(params);
      }
      return null;
    });
  }

  function uploadFiles(leadId: string, filesToUpload: Array<FormData>): Promise<string> {
    return new Promise((resolve, reject) => {
      let filesPromise = null;
      if (filesToUpload?.length) {
        const filesToUploadClone: Array<FormData> = [...filesToUpload];
        const postFilesPromises: Array<Promise<AxiosResponse<ResponseFile>>> = filesToUploadClone.map((file) => {
          const folder = file?.get('folder')?.toString()?.replace('substitudeId', leadId);
          file.set('folder', folder ? folder : '');
          return postFiles(file);
        });
        Promise.all(postFilesPromises)
          .then((results) => {
            const hasError = results?.some((result) => result?.status !== 201);
            if (hasError) {
              reject('failed to upload');
            } else {
              resolve(FilesService.with(auth0).filesUploadedSuccessMessage);
            }
          })
          .catch(() => {
            reject('failed to upload files');
          });
      } else {
        resolve('No files to upload');
      }
      return filesPromise;
    });
  }

  function toImage(response: ArrayBuffer): string {
    return btoa(new Uint8Array(response).reduce((data, byte) => data + String.fromCharCode(byte), ''));
  }

  return {
    getMetaFiles,
    deleteFile,
    postFiles,
    postFilesAPIv2,
    uploadFiles,
    getFileContent,
    getSignature,
    executeImport,
    getFile,
  };
}
