import {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useAuth0} from '@auth0/auth0-react';
import {useTranslation} from 'react-i18next';
import {Box, Button, Card, CardContent, Divider, Grid, Link, styled, Tooltip, Typography} from '@mui/material';
import {
  Assignment,
  Badge,
  Cake,
  Face,
  Female,
  Home,
  Mail,
  Male,
  Payments,
  Security,
  SmokeFree,
  SmokingRooms,
} from '@mui/icons-material';

import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {Gender, Lead, MaritalStatus} from '../../model/Lead';
import {Auth0User, getUserId} from '../../model/Auth0User';
import {formatDateTime} from '../../utils/DateUtils';
import {FeatureName} from '../../../paths';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {HttpMethods} from '../../model/HttpMethods';
import {AxiosResponse} from 'axios';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

interface INSXMessageResponse {
  URL: string;
  QuoteId: string;
}
export interface INSXResponse {
  statusCode: number;
  message: string;
  results: INSXMessageResponse;
}

export interface LeadCoveredPersonItem {
  lead_id: string;
  coverred_person_id: string;
  address_line_1: string;
  address_line_2: string;
  alternate_phone_number: string;
  city: string;
  county: string;
  created_at: Date;
  created_by: {
    username: string;
  };
  csr_amount: string;
  dob: string;
  email: string;
  first_name: string;
  gender: string;
  last_name: string;
  marital_status: string;
  member_id: string;
  tobacco_use: boolean;
  zip_code: string;
  line_of_coverage: string;
}

interface LeadInsxIndividualDependentPostInfo {
  relationship?: string;
  gender?: string;
  birthDate?: string;
  tobacco?: boolean;
  firstName?: string;
}

interface LeadInsxIndividualPostInfo {
  relationship?: string;
  gender?: string;
  birthDate?: string;
  applicantLast?: string;
  applicantFirst?: string;
  phoneNumber?: string;
  emailAddress?: string;
  tobacco?: boolean;
  dependents?: Array<LeadInsxIndividualDependentPostInfo>;
}
export interface LeadInsxPostInfo {
  agentId?: string;
  leadId?: string;
  zipCode?: string;
  county?: string;
  individual?: LeadInsxIndividualPostInfo;
}

enum Relationship {
  SPOUSE = 'spouse',
  CHILD = 'child',
}

interface SpartanINSXFieldProps {
  lead: Lead;
}

export function SpartanINSXField({lead}: SpartanINSXFieldProps) {
  const {useAxiosBFF} = useAxiosContext();
  const {t} = useTranslation();
  const {id} = useParams();
  const auth0 = useAuth0<Auth0User>();
  const {user} = auth0;
  const {setLoading} = useLoading();
  const [disableQuoteButton, setDisableQuoteButton] = useState<boolean>(false);
  const [quoteButtonTooltipMsg, setQuoteButtonTooltipMsg] = useState<string>('');
  const [INSXCoveredPeople, setINSXCoveredPeople] = useState<Array<LeadCoveredPersonItem>>([]);
  const [insxUrl, setInsxtUrl] = useState<string | null>(null);
  const [missingFields, setMissingFields] = useState<Array<string>>([]);

  const [{loading: getINSXLoading, response: getINSXResponse}] = useAxiosBFF<
    ResponseListWrapper<LeadCoveredPersonItem>
  >(
    {
      method: HttpMethods.GET,
      url: `${FeatureName.LEADS}/${id}/insx`,
    },
    {useCache: false}
  );

  const [{loading: isPostINSXLoading, error: hasPostINSXError}, postINSX] = useAxiosBFF<INSXResponse>(
    {
      method: HttpMethods.POST,
      url: `insx`,
      params: {send_insx: true},
    },
    {manual: true}
  );

  const [{loading: isUpdateLeadLoading}, updateLead] = useAxiosBFF(
    {
      method: HttpMethods.PATCH,
    },
    {manual: true}
  );

  useEffect(() => {
    const loading = isUpdateLeadLoading || isPostINSXLoading || getINSXLoading;
    setLoading(loading, `SpartanINSXField/${id}`);
  }, [isUpdateLeadLoading, isPostINSXLoading, getINSXLoading, id]);

  useEffect(() => {
    if (getINSXResponse) {
      setINSXCoveredPeople(getINSXResponse.data.results);
    }
  }, [getINSXResponse]);

  useEffect(() => {
    if (hasPostINSXError) {
      NotificationService.getInstance().sendNotification(
        hasPostINSXError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasPostINSXError]);

  useEffect(() => {
    if (missingFields) {
      setQuoteButtonTooltipMsg(`${t('insx.missing-fields')}: ${missingFields?.toString()}`);
      setDisableQuoteButton(true);
    }
  }, [missingFields]);

  useEffect(() => {
    if (lead) {
      setDisableQuoteButton(lead?.has_insx_quote || false);
      setQuoteButtonTooltipMsg(`${t('insx.sent-msg')}`);
      validateRequiredPostLeads();
    }
  }, [lead]);

  useEffect(() => {
    if (insxUrl && user && id) {
      const updatedLead: Lead = {
        has_insx_quote: true,
      };
      updateLead({url: `${FeatureName.LEADS}/${id}`, data: updatedLead}).then((response: AxiosResponse) => {
        if (response?.status === 200) {
          NotificationService.getInstance().sendNotification(response?.data?.message, NotificationType.SUCCESS);
        }
      });
      setDisableQuoteButton(true);
      setQuoteButtonTooltipMsg(`${t('insx.sent-msg')}`);
    }
  }, [insxUrl, id]);

  function validateRequiredPostLeads() {
    const requiredFields = {
      'zip code': lead?.zip_code,
      'marital status': lead?.marital_status,
      gender: lead?.gender,
      birthdate: lead.birthdate,
    };
    const missingFieldList: Array<string> = [];
    for (const [key, value] of Object.entries(requiredFields)) {
      if (!value) missingFieldList.push(key);
    }
    if (missingFieldList.length > 0) setMissingFields(missingFieldList);
  }
  function getLeadDependents(): Array<LeadInsxIndividualDependentPostInfo> {
    const dependents: Array<LeadInsxIndividualDependentPostInfo> = [];

    if (lead.marital_status === MaritalStatus.MARRIED) {
      const spouse: LeadInsxIndividualDependentPostInfo = {
        relationship: Relationship?.SPOUSE,
        gender: lead?.spouse_gender,
        firstName: lead?.spouse_first_name,
        birthDate: lead?.spouse_dob?.toString(),
      };
      dependents.push(spouse);
    }
    const children: number = lead?.number_of_dependent_children_in_house || 0;
    for (let i = 1; i <= children; i += 1) {
      const child: LeadInsxIndividualDependentPostInfo = {
        relationship: Relationship.CHILD,
        gender: lead[`child_${i}_gender`],
        firstName: lead[`child_${i}_first_name`],
        birthDate: lead[`child_${i}_dob`],
      };
      dependents.push(child);
    }
    return dependents;
  }
  function postQuote() {
    if (user) {
      const leadInsxInfo: LeadInsxPostInfo = {
        agentId: getUserId(user),
        leadId: lead.lead_id,
        zipCode: lead.zip_code,
        county: lead.county,
        individual: {
          relationship: lead?.marital_status,
          gender: lead?.gender,
          birthDate: lead?.birthdate?.toString(),
          applicantFirst: lead?.first_name,
          applicantLast: lead?.last_name,
          phoneNumber: lead?.primary_phone,
          emailAddress: lead?.email,
          tobacco: lead?.life_tobacco_user,
          dependents: getLeadDependents(),
        },
      };

      postINSX({data: leadInsxInfo}).then((response: AxiosResponse<INSXResponse>) => {
        if (response?.status === 200) {
          NotificationService.getInstance().sendNotification(response?.data.message, NotificationType.SUCCESS);
        }
        setInsxtUrl(response?.data?.results?.URL);
      });
    }
  }

  const INSXContainer = styled(Grid)(({theme}) => ({
    [theme.breakpoints.down('md')]: {
      width: 'max-content',
      maxWidth: '100%',
      overflowWrap: 'break-word',
      overflowX: 'auto',
      padding: theme.spacing(0),
    },
  }));

  const INSXCardContent = styled(CardContent)(({theme}) => ({
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  }));

  return (
    <>
      <Tooltip title={disableQuoteButton ? quoteButtonTooltipMsg : ''} placement="top-start">
        <span>
          <Button color="primary" disabled={disableQuoteButton} onClick={postQuote} id={'insx-btn'}>
            {t('insx.post-insx')}
          </Button>
        </span>
      </Tooltip>
      {insxUrl && (
        <Typography>
          <Link href={insxUrl} target="_blank">
            {insxUrl}
          </Link>
        </Typography>
      )}

      <Box
        sx={{
          overflowY: 'scroll',
          overflowX: 'visible',
          maxHeight: 500,
          width: '100%',
        }}
      >
        <>
          <INSXContainer container spacing={3}>
            {INSXCoveredPeople &&
              INSXCoveredPeople?.map((covergagePerson: LeadCoveredPersonItem, index: number) => (
                <Grid item lg={4} sm={12}>
                  <Card>
                    <INSXCardContent>
                      <Typography variant="h6">
                        {covergagePerson?.gender === Gender.FEMALE && <Female color="primary" />}
                        {covergagePerson?.gender === Gender.MALE && <Male color="primary" />}
                        {`${covergagePerson?.first_name}, ${covergagePerson?.last_name}`}
                      </Typography>
                      <Grid container spacing={3} key={`LeadCoveredPersonItem_${index}`}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h6">
                            {<Cake color="primary" />}
                            {`${covergagePerson?.dob}`}
                          </Typography>
                          <Typography variant="h6">
                            {<Assignment color="primary" />}
                            {`${covergagePerson?.marital_status}`}
                          </Typography>
                          {covergagePerson?.tobacco_use && <SmokingRooms color="primary" />}
                          {!covergagePerson?.tobacco_use && <SmokeFree color="primary" />}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h6">
                            {<Badge color="primary" />}
                            {`${covergagePerson?.member_id}`}
                          </Typography>

                          <Typography variant="h6">
                            {<Security color="primary" />}
                            {`${covergagePerson?.line_of_coverage}`}
                          </Typography>
                          <Typography variant="h6">
                            {<Payments color="primary" />}
                            {`${covergagePerson?.csr_amount}`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography variant="h6">
                        {<Mail color="primary" />}
                        {`${covergagePerson?.email}`}
                      </Typography>
                      <Typography variant="h6">
                        {<Home color="primary" />}
                        {`${covergagePerson?.address_line_1}, ${covergagePerson?.address_line_2}, ${covergagePerson?.city}, ${covergagePerson?.county}, ${covergagePerson?.zip_code}`}
                      </Typography>
                    </INSXCardContent>
                    <Divider />
                    <INSXCardContent>
                      <Typography variant="h6">
                        {<Face color="primary" />}
                        {`${covergagePerson?.created_by?.username} ${formatDateTime(covergagePerson?.created_at)}`}
                      </Typography>
                    </INSXCardContent>
                  </Card>
                </Grid>
              ))}
          </INSXContainer>
          {!INSXCoveredPeople && <Typography variant="h6">{t('insx.no-covered-msg')}</Typography>}
        </>
      </Box>
    </>
  );
}
